import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Popover,
  Select,
  Slider,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const TasselSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class GpaScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],

      newGoalName: '',
      newGoalCategoryId: 0,
      newGradeId: 0,
      newGoalDescription: '',
      newDateModified: '',
      newGoalAchieved: 0,

      editName: '',
      editCategoryId: '',
      editDescription: '',

      newNameValid: true,
      newCategoryIdValid: true,
      newDescriptionValid: true,

      editNameValid: true,
      editCategoryIdValid: true,
      editDescriptionValid: true,

      selectedItem: {},
      categories: [],
      gpaValue: 0,
      gpaValueTotal: 0,
      editButton: false,
      isExpanded: false,
      gradeId: 0,
      gpaId: 0,
      act: [],
      actScore: '',
      actMoney: '',
      kees: [],
      isDefinitionOpen: false
    }

    this.createGpa = this.createGpa.bind(this);
    this.editGpa = this.editGpa.bind(this);

    this.onNewClose = this.onNewClose.bind(this);
    this.onNewClick = this.onNewClick.bind(this);

    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.onDefinitionClose = this.onDefinitionClose.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {
    this.getData(this.props.profileId);
  }

  getData(personId) {

    var gpa = 0

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_gpa/' + personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })

          responseJson.results.map((item, key) => {
            gpa = item.gpa + gpa
          })
          var gpaValue = gpa / responseJson.results.length
          this.setState({
            gpaValueTotal: Math.round(gpaValue * 10) / 10
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch("https://api.tasselpathway.com/profile_assessment/person/" + personId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {
        responseJson.results.map((item, key) => {
          if (item.assessment_type_name === 'ACT') {
            this.setState({
              actScore: item.composite_score
            })
          }
        })
        this.setState({
          act: responseJson.results
        })
      })

  }

  createGpa() {

    fetch('https://api.tasselpathway.com/profile_gpa', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId,
        gpa: this.state.gpaValue,
        grade_id: this.state.gradeId
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isNewOpen: false
        })
        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          editButton: false
        })
      });
  }

  editGpa() {

    this.setState({
      isAchievedOpen: false
    })

    fetch('https://api.tasselpathway.com/profile_gpa', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        gpa_id: this.state.gpaId,
        gpa: this.state.gpaValue
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isEditOpen: false
        })
        this.getData(this.props.personId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  deleteGoal() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_goal', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        goal_id: this.state.selectedItem.goal_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.personId);

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }


  onNewOpen() {
    this.setState({
      newDescription: '',
      newCategoryId: 0,
      newDate: Date(),
      isNewOpen: true
    })
  }

  onAchievedOpen(item) {
    this.setState({
      isAchievedOpen: true, selectedItem: item
    })
  }

  onAchievedClose() {
    this.setState({
      isAchievedOpen: false
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onNewClick() {
    this.createGoal();

    this.setState({
      isNewOpen: false
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onEditOpen(item) {
    this.setState({
      selectedItem: item,
      isEditOpen: true
    })
  }

  onEditClick() {
    this.saveGoal();

    this.setState({
      isEditOpen: false
    })
  }

  handleChange(newDate) {
    this.setState({
      date: newDate
    });
  }

  handleInputChange = name => event => {
    this.setState({ ...this.state, [name]: event.target.value });
  }

  handleGpaSliderChange = (event, newValue) => {
    if (this.state.dataSource.length > 0) {
      this.setState({ gpaValue: newValue, editButton: true });
    } else {
      this.setState({ gpaValue: newValue });
    }
  };

  renderGpaFormFields = (itemValue, label, unitOfMeasure) => {

    return (
      <>
        <Grid container spacing={2} style={styles.itemContainerStyle} alignItems="center">
          <Grid item>
            <SchoolIcon />
          </Grid>
          <Grid item xs={10}>
            <TasselSlider
              value={itemValue}
              onChange={this.handleGpaSliderChange}
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              max={4.0}
            />
          </Grid>
          <Grid item>
            <Input
              style={{ width: 40 }}
              value={itemValue}
              margin="dense"
              onChange={this.handleInputChange("gpaValue")}
              onBlur={this.handleGpaBlur}
              inputProps={{
                step: 0.1,
                min: 0,
                max: 4.00,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
            {unitOfMeasure}
          </Grid>
        </Grid>
      </>
    )
  }


  renderKees = (gpaValue) => {
    if (gpaValue === 2.5) {
      if (!this.state.kees.includes(125)) {
        this.state.kees.push(125)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$125 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 2.6) {
      if (!this.state.kees.includes(150)) {
        this.state.kees.push(150)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$150 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 2.7) {
      if (!this.state.kees.includes(175)) {
        this.state.kees.push(175)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$175 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 2.75) {
      if (!this.state.kees.includes(187)) {
        this.state.kees.push(187)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$187 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 2.8) {
      if (!this.state.kees.includes(200)) {
        this.state.kees.push(200)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$200 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 2.9) {
      if (!this.state.kees.includes(225)) {
        this.state.kees.push(225)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$225 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3) {
      if (!this.state.kees.includes(250)) {
        this.state.kees.push(250)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$250 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.1) {
      if (!this.state.kees.includes(275)) {
        this.state.kees.push(275)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$275 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.2) {
      if (!this.state.kees.includes(300)) {
        this.state.kees.push(300)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$300 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.25) {
      if (!this.state.kees.includes(312)) {
        this.state.kees.push(312)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$312 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.3) {
      if (!this.state.kees.includes(325)) {
        this.state.kees.push(325)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$325 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.4) {
      if (!this.state.kees.includes(350)) {
        this.state.kees.push(350)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$350 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.5) {
      if (!this.state.kees.includes(375)) {
        this.state.kees.push(375)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$375 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.6) {
      if (!this.state.kees.includes(400)) {
        this.state.kees.push(400)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$400 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.7) {
      if (!this.state.kees.includes(425)) {
        this.state.kees.push(425)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$425 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.75) {
      if (!this.state.kees.includes(437)) {
        this.state.kees.push(437)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$437 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.8) {
      if (!this.state.kees.includes(450)) {
        this.state.kees.push(450)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$450 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 3.9) {
      if (!this.state.kees.includes(475)) {
        this.state.kees.push(475)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$475 per year of college</Typography>
        </>
      )
    }
    if (gpaValue === 4) {
      if (!this.state.kees.includes(500)) {
        this.state.kees.push(500)
      }
      return (
        <>
          <Typography style={styles.keesTotal}>$500 per year of college</Typography>
        </>
      )
    }
  }

  onGpaClick(item) {
    this.setState({
      isEditOpen: true,
      gpaId: item.gpa_id,
      gradeId: item.grade_id,
      gpaValue: item.gpa
    })
  }

  renderGpa() {
    var content = this.state.dataSource.map((item, key) => {
      return (
        <div>
          <ListItem key={key} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#25D192', marginTop: 15 }} button alignItems="flex-start"
            onClick={() => this.onGpaClick(item)}>
            <Grid item xs={3}>
              <Typography style={{ margin: 10, textAlign: 'center', fontSize: 16, color: '#FFF' }}>{item.grade_name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{ margin: 10, textAlign: 'center', fontSize: 16, color: '#FFF' }}>{item.gpa} gpa</Typography>
            </Grid>
            <Grid item xs={6}>
              {this.renderKees(item.gpa)}
            </Grid>
          </ListItem>
        </div>
      )
    })
    return content
  }

  renderAct() {
    if (this.state.actScore === 15) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT  will give you a $36 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 16) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $71 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 17) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $107 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 18) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $143 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 19) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $179 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 20) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $214 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 21) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $250 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 22) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $286 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 23) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $321 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 24) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $357 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 25) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $393 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 26) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT will give you a $428 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 27) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore} on the ACT give you a $464 BONUS from KEES for each year of college</Typography>
      )
    }
    if (this.state.actScore === 28) {
      return (
        <Typography style={{ fontSize: 10 }}>A composite score of {this.state.actScore}+ on the ACT will give you a $500 BONUS from KEES for each year of college</Typography>
      )
    }
  }

  onDefinitionOpen() {
    this.setState({
      isDefinitionOpen: true
    })
  }

  onDefinitionClose() {
    this.setState({
      isDefinitionOpen: false
    })
  }

  render() {

    var keesTotalPerYear = 0

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            Current GPA
          </DialogTitle>

          <DialogContent>

            <div>

              <Grid container>

                <Input
                  style={{ width: 40 }}
                  value={this.state.gpaValue}
                  margin="dense"
                  onChange={this.handleInputChange("gpaValue")}
                  onBlur={this.handleGpaBlur}
                  inputProps={{
                    step: 0.1,
                    min: 0,
                    max: 4.00,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}
                />

                <FormControl className={classes.formControl}
                  fullWidth>
                  <InputLabel htmlFor="assessment_type_id">Grade</InputLabel>
                  <Select variant='standard'
                    value={this.state.teacherId}
                    onChange={(event) => this.setState({ gradeId: event.target.value })}
                    inputProps={{
                      name: 'assessment_type_id',
                      id: 'assessment_type_id',
                    }}>
                    <MenuItem value={'10'}>Freshman</MenuItem>
                    <MenuItem value={'11'}>Sophmore</MenuItem>
                    <MenuItem value={'12'}>Junior</MenuItem>
                    <MenuItem value={'13'}>Senior</MenuItem>
                  </Select>
                </FormControl>

              </Grid>

            </div>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
              onClick={this.createGpa} color="primary">
              {i18n.t("ADD")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onEditClose}>
            Edit GPA
          </DialogTitle>

          <DialogContent>

            <div>

              <Grid container>

                <Input
                  style={{ width: 40 }}
                  value={this.state.gpaValue}
                  margin="dense"
                  onChange={this.handleInputChange("gpaValue")}
                  onBlur={this.handleGpaBlur}
                  inputProps={{
                    step: 0.1,
                    min: 0,
                    max: 4.00,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}
                />

              </Grid>

            </div>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
              onClick={this.editGpa} color="primary">
              {i18n.t("EDIT")}
            </Button>
          </DialogActions>
        </Dialog>

        <Popover
          id={'simple-popover'}
          open={this.state.isDefinitionOpen}
          anchorEl={this.state.definitionAnchor}
          onClose={this.onDefinitionClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <Typography style={{ padding: 20 }}>For each year you earn a 2.5 or better GPA, you can earn the base amount listed below. For instance, a high school freshman who earns a 3.5 GPA would have a $375 scholarship for each year of college. Amounts may change based on available funds. For more information check out the <a href="https://www.kheaa.com/website/kheaa/kees?main=1">KHEAA Website</a></Typography>
        </Popover>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={3}>
                  <div style={{ display: 'flex' }}>
                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>KEES</Typography>
                    <Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event)}>
                      <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /></Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <List style={{ height: 200, overflow: 'auto' }}>
                {
                  this.state.kees.map((item, key) => {
                    keesTotalPerYear = item + keesTotalPerYear
                  })}

                <Card style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: '#a6a6a6', marginTop: 15 }}>
                  <Grid item xs={12}>
                    <Typography style={{ margin: 10, fontWeight: 'bold', color: '#FFF', fontSize: 16 }}>Total KEES scholarship money per year: ${keesTotalPerYear}</Typography>
                  </Grid>
                </Card>
                {this.renderGpa()}
              </List>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

GpaScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GpaScreen));