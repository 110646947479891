import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody, TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import { logout } from '../../redux/actions/auth';
import { setProgress } from '../../redux/actions/progress';
import TestLibraryScreen from './library/index';
import styles from './style';

class AssessmentScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      value: 0,
      activeTests: [],
      studentFilter: [],
      gradeList: [],
      customGroups: [],
      customGroupsStudents: [],
      grades: [],
      pageNumber: 0,
      perPage: 25,
      sortBy: '',
      sortDirection: 'desc'
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

    fetch('https://api.tasselpathway.com/profile_tests/active/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            activeTests: responseJson.results
          });
        }
      })
      .catch((error) => {
        // this.props.onLogout();
      });
  }

  updateDimensions() {
    let update_height = window.innerHeight - 280;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidUpdate(prevProps) {

  }

  getData(gradeList, studentFilter, taskId, perPage, pageNumber, sortBy, sortDirection) {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.setState({
      isLoading: true
    })

    if (this.props.userRoleId < 8) {

      fetch('https://api.tasselpathway.com/custom_groups/' + this.props.personId + '/' + + this.props.personId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              customGroups: responseJson.results
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });

      fetch('https://api.tasselpathway.com/custom_groups/students/' + this.props.personId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              customGroupsStudents: responseJson.results
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });

      fetch('https://api.tasselpathway.com/grades', {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              grades: responseJson.results
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });

    }
  }

  onGradeFilterClick(event, gradeId) {

    if (event.target.checked !== undefined) {

      var gradeList = this.state.gradeList;

      if (event.target.checked) {
        gradeList.push(gradeId);
      } else {
        for (var i = 0; i < gradeList.length; i++) {
          if (gradeList[i] === gradeId) {
            gradeList.splice(i, 1);
          }
        }
      }

      this.setState({
        gradeList: gradeList,
        pageNumber: 0
      })
      if (this.state.sortBy !== '' && this.state.sortDirection !== '') {
        this.getData(gradeList, this.state.studentFilter, this.state.taskId, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
      } else {
        this.getData(gradeList, this.state.studentFilter, this.state.taskId, this.state.perPage, this.state.pageNumber, 'last_name', 'asc');
      }
    }
  }

  onStudentClick(event, personId, groupId) {

    if (event.target.checked !== undefined) {

      var student = []

      if (event.target.checked) {
        for (var i = 0; i < this.state.customGroupsStudents.length; i++) {
          if (this.state.customGroupsStudents[i].custom_group_id === groupId) {
            student.push(this.state.customGroupsStudents[i].person_id)
          }
        }
      } else {
        for (var j = 0; j < this.state.customGroupsStudents.length; j++) {
          if (student[j] === personId) {
            student.splice(j, 1);
          }
        }
      }
      this.setState({
        studentFilter: student,
        pageNumber: 0
      })
      if (this.state.sortBy !== '' && this.state.sortDirection !== '') {
        this.getData(this.state.gradeList, student, this.state.taskId, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
      } else {
        this.getData(this.state.gradeList, student, this.state.taskId, this.state.perPage, this.state.pageNumber, 'last_name', 'asc');
      }
    }
  }

  handleChange = (event, value) => {
    this.setState({
      value
    });
    if (value === 0) {
      //this.getData(this.state.gradeList, this.state.studentFilter, 0, this.state.perPage, 0, this.state.sortBy, this.state.sortDirection);
    } else if (value === 1) {
      //this.getPassedData(this.state.gradeList, this.state.studentFilter, 0, this.state.perPage, 0, 'last_name', 'asc');
    }
    else if (value === 2) {
      //this.getFailedData(this.state.gradeList, this.state.studentFilter, 0, this.state.perPage, 0, 'last_name', 'asc');
    }
    else if (value === 3) {
      //this.getAllTasks();
    }
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Grid container style={styles.itemContainerStyle}>
          <Grid item xs={12} style={{ marginLeft: 100, marginRight: 100 }}>

            <Grid container style={{ marginBottom: 10, marginTop: 20 }}>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 24, color: '#333', fontWeight: 'bold' }}>{i18n.t("TESTS_TITLE")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Tabs value={this.state.value} onChange={this.handleChange}>
                  <Tab label="ACTIVE TESTS" />
                  <Tab label="COMPLETED TESTS" />
                  <Tab label="TEST LIBRARY" />
                </Tabs>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 30 }}>

              </Grid>
            </Grid>

            <Grid container>

              {this.state.value === 0 &&
                <Grid item xs={12}>
                  <Grid container style={{ overflow: 'auto' }}>

                    <Grid item xs={12}>
                      <Paper square style={{ marginLeft: 20, marginRight: 20, marginTop: 20, borderRadius: 0 }}>
                        <div className={classes.tableWrapper} style={{ maxHeight: this.state.height, }}>
                          <Table className={classes.table}>
                            <TableHead >
                              <TableRow>
                                <TableCell className={classes.head}>&nbsp;</TableCell>

                                <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("test_name")}>
                                    {this.state.sortBy === "test_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                        <Typography style={{ fontSize: 12 }}>Test Name</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10 }}>
                                        <Typography style={{ fontSize: 12 }}>Test Name</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy === "test_name" ? (
                                      this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                    ) : null}
                                  </div>
                                </TableCell>

                                <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("date_created")}>
                                    {this.state.sortBy === "date_created" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                        <Typography style={{ fontSize: 12 }}>Date Created</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10 }}>
                                        <Typography style={{ fontSize: 12 }}>Date Created</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy === "date_created" ? (
                                      this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                    ) : null}
                                  </div>
                                </TableCell>

                                <TableCell style={{ zIndex: 1, }} className={classes.head}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                              {this.state.activeTests.map((row, index) => {

                                return (
                                  <TableRow style={{ cursor: 'pointer' }} hover
                                    key={index}>
                                    <TableCell>
                                      <IconButton variant="contained"><ExpandLessIcon />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell style={{ width: 450 }}><Typography style={{ fontSize: 12 }}>{row.test_name}</Typography></TableCell>
                                    <TableCell><Typography style={{ fontSize: 12 }}>{row.date_created}</Typography></TableCell>
                                    <TableCell >

                                    </TableCell>
                                  </TableRow>
                                );
                              })}

                            </TableBody>
                          </Table>

                        </div>

                        <TablePagination
                          rowsPerPageOptions={[25]}
                          component="div"
                          rowsPerPage={this.state.perPage}
                          page={this.state.pageNumber}
                          count={0}
                          backIconButtonProps={{
                            'aria-label': 'Previous Page',
                          }}
                          nextIconButtonProps={{
                            'aria-label': 'Next Page',
                          }}
                          onPageChange={this.onPageClick}
                        />

                      </Paper>
                    </Grid>

                  </Grid>
                </Grid>
              }
              {this.state.value === 1 &&

                <Grid item xs={12}>

                  <Grid container style={{ overflow: 'auto' }}>

                    <Grid item xs={12}>
                      <Typography>Completed Tests</Typography>
                    </Grid>

                  </Grid>


                </Grid>
              }
              {this.state.value === 2 &&
                <Grid item xs={12}>
                  <Grid container style={{ overflow: 'auto' }}>
                    <Grid item xs={12}>
                      <TestLibraryScreen props={this.props} />
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

AssessmentScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssessmentScreen)));