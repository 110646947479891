import { GET_PROFILE, SET_PROFILE } from "../actions";

export const getPerson = () => {
    return {
        type: GET_PROFILE
    }
};

export const setProfile = (profileId, firstName, lastName, gradeId, gradeName,
    districtId, districtName, schoolId, schoolName, profileRoleId, earlyGraduationProgram) => {
    return {
        type: SET_PROFILE,
        profileId: profileId,
        firstName: firstName,
        lastName: lastName,
        gradeId: gradeId,
        gradeName: gradeName,
        districtId: districtId,
        districtName: districtName,
        schoolId: schoolId,
        schoolName: schoolName,
        profileRoleId: profileRoleId,
        earlyGraduationProgram: earlyGraduationProgram
    }
};
