import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, Grid, IconButton, Input, InputBase, InputLabel, ListItemText, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';
const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class UserScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      showInviteSent: false,
      value: 0,
      showNew: false,
      schoolId: 0,
      height: 100,
      roleId: 0,
      dataSource: [],
      schoolList: [],
      selectedSchools: [],
      roleList: [],
      firstName: '',
      lastName: '',
      emailAddress: '',
      firstNameValid: true,
      lastNameValid: true,
      emailAddressValid: true,
      schoolValid: true,
      roleValid: true,
      duplicateEmailAddress: false,
      filteredDataSource: [],
      selectedItem: {},
      isNewOpen: false,
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc'
    }

    this.onSearch = this.onSearch.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.hideInviteSent = this.hideInviteSent.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.renderSelectedSchools = this.renderSelectedSchools.bind(this);

  }

  updateDimensions() {

    let update_height = 0;

    if (this.props.nnouncementAlert !== '') {
      update_height = window.innerHeight - 350;
    } else {
      update_height = window.innerHeight - 260;
    }

    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.setState({
      isLoading: true
    })

    this.getData();
    this.updateDimensions();

    fetch('https://api.tasselpathway.com/schools', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          schoolList: responseJson.results
        });

      })
      .catch((error) => {

      });

    fetch('https://api.tasselpathway.com/roles', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          roleList: responseJson.roles
        });

      })
      .catch((error) => {

      });
  }

  getData() {

    fetch('https://api.tasselpathway.com/persons', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          dataSource: responseJson.results,
          filteredDataSource: responseJson.results
        });

        this.props.setProgress(100);

      })
      .catch((error) => {

      });
  }

  onAddClick() {

    var formValid = this.validateNewForm();

    if (formValid === true) {

      fetch('https://api.tasselpathway.com/persons', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          user_role_id: this.state.roleId,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          email_address: this.state.emailAddress,
          school_id: this.state.selectedSchools
        })
      })
        .then(response => {
          return response.json()
        })
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isNewOpen: false
            })
          } else if (responseJson.message === 'Person Email Already Registered!') {
            this.setState({
              duplicateEmailAddress: true
            })
          }

          this.getData();
        })
        .catch((e) => {
          console.warn(e);
        });
    }
  }

  onSearch(event) {

    const newData = this.state.dataSource.filter(function (item) {
      const itemData = item.first_name.toUpperCase() + " " + item.last_name.toUpperCase()
      const textData = event.target.value.toUpperCase()
      return itemData.indexOf(textData) > -1
    })
    this.setState({
      filteredDataSource: newData,
      searchText: event.target.value
    })
  }

  onNewRoleChange = event => {

    if (event.target.value === 1) {

      var list = [];

      this.state.schoolList.map((item, key) => {
        list.push(item.school_id);
      })

      this.setState({
        schoolSelectList: list,
        [event.target.name]: event.target.value
      });

    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleChange = event => {

    var selectedItem = this.state.selectedItem;
    selectedItem.role_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    });
  };

  onNewSchoolChange(event) {

    var list = event.target.value.filter(function (el) {
      return el != null;
    });

    this.setState({ [event.target.name]: list });
  }

  onSchoolChange(event) {

    var list = event.target.value.filter(function (el) {
      return el != null;
    });

    this.setState({ [event.target.name]: list });
  }

  onResendInvite(item) {

    fetch("https://api.tasselpathway.com/persons/invite", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        person_id: item.person_id,
        email_address: item.email_address
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            showInviteSent: true
          })
        }
      })
      .catch((error) => {

      });
  }

  onSaveSchool() {

    var formValid = this.validateForm();

    if (formValid === true) {

      fetch('https://api.tasselpathway.com/persons', {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          person_id: this.state.selectedItem.person_id,
          user_role_id: this.state.selectedItem.role_id,
          first_name: this.state.selectedItem.first_name,
          last_name: this.state.selectedItem.last_name,
          email_address: this.state.selectedItem.email_address,
          school_id: this.state.selectedSchools
        })
      })
        .then(response => {
          return response.json()
        })
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isEditOpen: false
            })
          } else if (responseJson.message === 'Person Email Already Registered!') {
            this.setState({
              duplicateEmailAddress: true
            })
          }

          this.getData();
        })
        .catch((e) => {
          console.warn(e);
        });
    }
  }

  onNewClick = () => {
    this.setState({
      isNewOpen: true,
      duplicateEmailAddress: false,
      selectedSchools: []
    })
  };

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onEditClick(event, item) {

    var selectedSchools = []

    item.schools.map((item, key) => {
      return selectedSchools.push(item.school_id);
    });

    this.setState({
      isEditOpen: true,
      selectedItem: item,
      selectedSchools: selectedSchools
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  hideInviteSent() {
    this.setState({
      showInviteSent: false
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  renderSelectedSchools() {

    if (this.state.selectedSchools === undefined || this.state.selectedSchools === []) return null;

    if (this.state.schoolList === undefined || this.state.schoolList === []) return null;

    const content = this.state.schoolList.map((item, key) => {

      if (this.state.selectedSchools.map(function (e) { return e; }).indexOf(item.school_id) > -1) {

        return (
          <Chip key={item.school_id} label={item.school_name}
            style={{ backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', margin: 5, height: 30 }}
            className={this.props.classes.chip} />
        )

      }
    })
    return content;

  }

  renderRoles() {
    if (this.state.roleList === undefined) return null;

    const content = this.state.roleList.map((item, key) => {
      return (
        <MenuItem key={key} value={item.user_role_id}>{item.role_name}</MenuItem>
      )
    })
    return content;
  }

  renderSchools() {

    if (this.state.schoolList === undefined) return null;

    const content = this.state.schoolList.map((item, key) => {
      return (
        <MenuItem key={key} value={item.school_id}>
          <Checkbox checked={this.state.selectedSchools.indexOf(item.school_id) > -1} />
          <ListItemText primary={item.school_name} />
        </MenuItem>
      )
    })
    return content;
  }

  validateForm() {

    var result = true;
    var fnValid = true;
    var lnValid = true;
    var eValid = true;
    var sValid = true;
    var rValid = true;

    if (this.state.selectedItem.first_name === "") {
      fnValid = false;
      result = false;
    }

    if (this.state.selectedItem.last_name === "") {
      lnValid = false;
      result = false;
    }

    if (this.isEmailValid(this.state.selectedItem.email_address) === false) {
      eValid = false;
      result = false;
    }

    if (this.state.selectedSchools === []) {
      sValid = false;
      result = false;
    }

    if (this.state.selectedItem.role_id === 0) {
      rValid = false;
      result = false;
    }

    this.setState({
      firstNameValid: fnValid,
      lastNameValid: lnValid,
      emailAddressValid: eValid,
      schoolValid: sValid,
      roleValid: rValid
    })

    return result;
  }

  isEmailValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateNewForm() {

    var result = true;
    var fnValid = true;
    var lnValid = true;
    var eValid = true;
    var sValid = true;
    var rValid = true;

    if (this.state.firstName === "") {
      fnValid = false;
      result = false;
    }

    if (this.state.lastName === "") {
      lnValid = false;
      result = false;
    }

    if (this.isEmailValid(this.state.emailAddress) === false) {
      eValid = false;
      result = false;
    }

    if (this.state.selectedSchools === []) {
      sValid = false;
      result = false;
    }

    if (this.state.roleId === 0) {
      rValid = false;
      result = false;
    }

    this.setState({
      firstNameValid: fnValid,
      lastNameValid: lnValid,
      emailAddressValid: eValid,
      schoolValid: sValid,
      roleValid: rValid
    })

    return result;
  }

  onFirstNameChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.first_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    });
  }

  onLastNameChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.last_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    });
  }

  onEmailChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.email_address = event.target.value;

    this.setState({
      selectedItem: selectedItem
    });
  }

  onReactivate() {
    fetch("https://api.tasselpathway.com/users/reactivate", {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        person_id: this.state.selectedItem.person_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            showMessage: true,
            message: 'User Reactivated!'
          })

          this.getData();
        }
      })
      .catch((error) => {

      });
  }

  onDeactivate() {

    fetch("https://api.tasselpathway.com/users/deactivate", {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        person_id: this.state.selectedItem.person_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            showMessage: true,
            message: 'User Deactivated!'
          })

          this.getData();
        }
      })
      .catch((error) => {

      });
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage,
      this.state.filteredDataSource.length - this.state.currentPage * this.state.rowsPerPage);

    return (

      <div className={classes.root}>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onNewClose}>
            {i18n.t("ADMIN_USERS_NEW_PERSON")}
          </DialogTitle>

          <DialogContent>

            {this.state.duplicateEmailAddress ?
              <Grid container style={{ backgroundColor: '#D55648', padding: 10, color: '#FFF' }}>
                <Grid item xs={1}>
                  <WarningIcon style={{ marginTop: 5 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography style={{ fontWeight: 'bold', color: '#FFF', marginTop: 7, textAlign: 'start' }}>
                    {i18n.t("ADMIN_USERS_ALREADY")}
                  </Typography>
                </Grid>
              </Grid>

              : null
            }
            <Grid container>
              {this.props.selectedLanguage === "esp" ?
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}
                      error={this.state.firstNameValid}>
                      <TextField autoFocus style={{ marginRight: 5 }} margin="dense"
                        id="firstName" error={!this.state.firstNameValid}
                        onChange={(event) => this.setState({ firstName: event.target.value })}
                        label="Nombre de Pila" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth
                      className={classes.formControl} error={this.state.lastNameValid}>
                      <TextField style={{ marginLeft: 5 }} margin="dense" id="lastName"
                        error={!this.state.lastNameValid}
                        onChange={(event) => this.setState({ lastName: event.target.value })}
                        label="Apellido" type="text" />
                    </FormControl>
                  </Grid>
                </>
                :
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}
                      error={this.state.firstNameValid}>
                      <TextField autoFocus style={{ marginRight: 5 }} margin="dense"
                        id="firstName" error={!this.state.firstNameValid}
                        onChange={(event) => this.setState({ firstName: event.target.value })}
                        label="First Name" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth
                      className={classes.formControl} error={this.state.lastNameValid}>
                      <TextField style={{ marginLeft: 5 }} margin="dense" id="lastName"
                        error={!this.state.lastNameValid}
                        onChange={(event) => this.setState({ lastName: event.target.value })}
                        label="Last Name" type="text" />
                    </FormControl>
                  </Grid>
                </>
              }
            </Grid>

            <Grid>
              {this.props.selectedLanguage === "esp" ?
                <FormControl fullWidth className={classes.formControl} error={this.state.emailAddressValid ||
                  this.state.duplicateEmailAddress}>
                  <TextField margin="dense" id="email" error={!this.state.emailAddressValid ||
                    this.state.duplicateEmailAddress}
                    onChange={(event) => this.setState({ emailAddress: event.target.value })}
                    label="E-mail" type="email" />
                </FormControl>

                :
                <FormControl fullWidth className={classes.formControl} error={this.state.emailAddressValid ||
                  this.state.duplicateEmailAddress}>
                  <TextField margin="dense" id="email" error={!this.state.emailAddressValid ||
                    this.state.duplicateEmailAddress}
                    onChange={(event) => this.setState({ emailAddress: event.target.value })}
                    label="Email" type="email" />
                </FormControl>
              }
            </Grid>
            <br />
            <Grid>
              <FormControl fullWidth className={classes.formControl}>
                <Select
                  value={this.state.roleId}
                  onChange={(evt) => this.onNewRoleChange(evt)}
                  error={!this.state.roleValid}
                  input={<Input name="roleId" id="roleId" />}>
                  <MenuItem value="0" disabled>{i18n.t("ADMIN_USERS_SELECT_ROLE")}</MenuItem>
                  {this.renderRoles()}
                </Select>
              </FormControl>
            </Grid>
            <br />
            {this.state.roleId > 1 ?
              <FormControl fullWidth style={{ marginBottom: 40 }} className={classes.formControl}>
                <InputLabel>{i18n.t("ADMIN_USERS_SCHOOL")}</InputLabel>
                <Select
                  multiple
                  value={this.state.selectedSchools}
                  onChange={(evt) => this.onNewSchoolChange(evt)}
                  error={!this.state.schoolValid}
                  input={<Input name="selectedSchools" id="selectedSchools" />}
                  renderValue={this.renderSelectedSchools}>
                  {this.renderSchools()}
                </Select>
              </FormControl> : null}
          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onAddClick()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_USERS_ADD_BUTTON")}
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            {i18n.t("ADMIN_USERS_EDIT_PERSON")}
          </DialogTitle>

          <DialogContent>

            {this.state.duplicateEmailAddress ?
              <Grid container style={{ backgroundColor: '#D55648', padding: 10, color: '#FFF' }}>
                <Grid item xs={1}>
                  <WarningIcon style={{ marginTop: 5 }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography style={{ fontWeight: 'bold', color: '#FFF', marginTop: 7, textAlign: 'start' }}>
                    {i18n.t("ADMIN_USERS_RESENT_CONF")}</Typography>
                </Grid>
              </Grid>
              : null
            }

            <Grid container>
              {this.props.selectedLanguage === "esp" ?
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}
                      error={this.state.firstNameValid}>
                      <TextField autoFocus style={{ marginRight: 5 }} margin="dense"
                        id="firstName" error={!this.state.firstNameValid}
                        onChange={(event) => this.onFirstNameChange(event)}
                        value={this.state.selectedItem.first_name}
                        label="Nombre de pila" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth
                      className={classes.formControl} error={this.state.lastNameValid}>
                      <TextField style={{ marginLeft: 5 }} margin="dense"
                        id="lastName" error={!this.state.lastNameValid}
                        onChange={(event) => this.onLastNameChange(event)}
                        value={this.state.selectedItem.last_name}
                        label="Apellido" type="text" />
                    </FormControl>
                  </Grid>
                </>
                :
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}
                      error={this.state.firstNameValid}>
                      <TextField autoFocus style={{ marginRight: 5 }} margin="dense"
                        id="firstName" error={!this.state.firstNameValid}
                        onChange={(event) => this.onFirstNameChange(event)}
                        value={this.state.selectedItem.first_name}
                        label="First Name" type="text" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth
                      className={classes.formControl} error={this.state.lastNameValid}>
                      <TextField style={{ marginLeft: 5 }} margin="dense"
                        id="lastName" error={!this.state.lastNameValid}
                        onChange={(event) => this.onLastNameChange(event)}
                        value={this.state.selectedItem.last_name}
                        label="Last Name" type="text" />
                    </FormControl>
                  </Grid>
                </>
              }
            </Grid>
            <br />
            <Grid>
              {this.props.selectedLanguage === "esp" ?
                <FormControl fullWidth className={classes.formControl} error={this.state.emailAddressValid ||
                  this.state.duplicateEmailAddress}>
                  <TextField margin="dense" id="email" error={!this.state.emailAddressValid ||
                    this.state.duplicateEmailAddress}
                    onChange={(event) => this.onEmailChange(event)}
                    value={this.state.selectedItem.email_address}
                    label="E-mail" type="email" />
                </FormControl>
                :
                <FormControl fullWidth className={classes.formControl} error={this.state.emailAddressValid ||
                  this.state.duplicateEmailAddress}>
                  <TextField margin="dense" id="email" error={!this.state.emailAddressValid ||
                    this.state.duplicateEmailAddress}
                    onChange={(event) => this.onEmailChange(event)}
                    value={this.state.selectedItem.email_address}
                    label="Email" type="email" />
                </FormControl>
              }
            </Grid>
            <br />
            <Grid>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="roleId">{i18n.t("ADMIN_USERS_ROLE")}</InputLabel>
                <Select
                  value={this.state.selectedItem.role_id}
                  onChange={this.handleChange} error={!this.state.roleValid}
                  input={<Input name="roleId" id="roleId" />}>
                  {this.renderRoles()}
                </Select>
              </FormControl>
            </Grid>
            <br />
            {this.state.selectedItem.role_id !== 1 ?
              <FormControl style={{ marginBottom: 20 }} fullWidth className={classes.formControl}>
                <InputLabel htmlFor="selectedSchools">{i18n.t("ADMIN_USERS_SCHOOLS")}</InputLabel>
                <Select
                  multiple
                  value={this.state.selectedSchools}
                  onChange={(evt) => this.onSchoolChange(evt)}
                  error={!this.state.schoolValid}
                  input={<Input name="selectedSchools" id="selectedSchools" />}
                  renderValue={this.renderSelectedSchools}>
                  {this.renderSchools()}
                </Select>
              </FormControl>
              : null}

          </DialogContent>

          <DialogActions>
            <Grid container>
              <Grid item xs={8}>
                {this.state.isActivated === 0 ?
                  <Button variant="contained" color="primary"
                    style={{ color: '#FFF', marginLeft: 20, borderRadius: 3, fontWeight: 'bold' }} onClick={() => this.onResendInvite()}>
                    {i18n.t("ADMIN_USERS_RESEND_INVITE")}
                  </Button>
                  :
                  this.state.isActivated === 1 ?
                    <Button variant="contained" color="primary" onClick={() => this.onReactivate()}
                      style={{ color: '#FFF', marginLeft: 20, borderRadius: 3, fontWeight: 'bold' }} className={classes.button}>
                      {i18n.t("ADMIN_USERS_REACTIVATE")}
                    </Button>
                    :
                    <Button variant="contained" color="primary" onClick={() => this.onDeactivate()}
                      style={{ color: '#FFF', marginLeft: 20, borderRadius: 3, fontWeight: 'bold' }} className={classes.button}>
                      {i18n.t("ADMIN_USERS_DEACTIVATE")}
                    </Button>
                }
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Button variant="contained" onClick={() => this.onSaveSchool()}
                  style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
                  {i18n.t("ADMIN_USERS_SAVE_BUTTON")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.showInviteSent}
          onClose={this.hideInviteSent}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{i18n.t("ADMIN_USERS_CONFIRMATION")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {i18n.t("ADMIN_USERS_RESENT_CONF")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hideInviteSent} color="primary" autoFocus>
              {i18n.t("ADMIN_USERS_OK")}
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container>
          <Grid item xs={2}>
            {(this.props.userRoleId <= 4 || this.props.userRoleId === 7) ?
              <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 3 }}
                onClick={() => this.onNewClick()}>
                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>{i18n.t("ADMIN_USERS_NEW_USER")}</Typography>
              </Button>
              : null}
          </Grid>
          <Grid item xs={6}>

          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            {this.props.selectedLanguage === "esp" ?
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={this.onSearch} style={{ width: '100%' }}
                  placeholder="Buscar"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              :
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={this.onSearch} style={{ width: '100%' }}
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            }
          </Grid>
        </Grid>

        <Paper style={{ marginTop: 10, orderRadius: 0, width: '100%' }}>
          <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
            <Table className={classes.table} padding="dense" style={{ height: this.state.height }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head}>
                    <Grid container style={{ width: 175 }}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("first_name")}>
                        {this.state.sortBy === "first_name" ? (
                          <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_FIRST_NAME")}</Typography>
                          </div>
                        ) :
                          <div style={{ marginTop: 10, width: 60 }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_FIRST_NAME")}</Typography>
                          </div>
                        }
                        {this.state.sortBy === "first_name" ? (
                          this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                        ) : null}
                      </div>
                      {this.state.sortBy === "first_name" ?
                        <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                        : (this.state.sortBy === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                          : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                      }
                      <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("last_name")}>
                        {this.state.sortBy === "last_name" ? (
                          <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_LAST_NAME")}</Typography>
                          </div>
                        ) :
                          <div style={{ marginTop: 10 }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_LAST_NAME")}</Typography>
                          </div>
                        }
                        {this.state.sortBy === "last_name" ? (
                          this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                        ) : null}
                      </div>
                    </Grid>
                  </TableCell>
                  <TableCell style={{ width: 400 }} className={classes.head}>
                    <Typography style={{ fontSize: 12 }}>Schools</Typography>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("email_address")}>
                      {this.state.sortBy === "email_address" ? (
                        <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                          <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_EMAIL_ADDRESS")}</Typography>
                        </div>
                      ) :
                        <div style={{ marginTop: 10 }}>
                          <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_EMAIL_ADDRESS")}</Typography>
                        </div>
                      }
                      {this.state.sortBy === "email_address" ? (
                        this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("role_name")}>
                      {this.state.sortBy === "role_name" ? (
                        <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                          <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_ROLE")}</Typography>
                        </div>
                      ) :
                        <div style={{ marginTop: 10 }}>
                          <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_ROLE")}</Typography>
                        </div>
                      }
                      {this.state.sortBy === "role_name" ? (
                        this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("is_activated")}>
                      {this.state.sortBy === "is_activated" ? (
                        <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                          <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_STATUS")}</Typography>
                        </div>
                      ) :
                        <div style={{ marginTop: 10 }}>
                          <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_USERS_STATUS")}</Typography>
                        </div>
                      }
                      {this.state.sortBy === "is_activated" ? (
                        this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                      ) : null}
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                  .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow key={index} hover style={{ cursor: 'pointer' }}>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}>
                          <Typography style={{ fontSize: 12 }}>{row.first_name} {row.last_name}</Typography>
                        </TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}>
                          {
                            row.schools.map((row2, index2) => {
                              return (
                                <Chip key={row2.school_id} label={row2.school_name}
                                  style={{ backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', margin: 5, height: 30 }}
                                  className={this.props.classes.chip} />
                              )
                            })
                          }
                        </TableCell>

                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.email_address}</Typography></TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.role_name}</Typography></TableCell>
                        <TableCell>
                          {
                            row.is_activated === 0 ?
                              <Button variant="contained" color="primary"
                                style={{ color: '#FFF', fontSize: 12, borderRadius: 3, fontWeight: 'bold' }}
                                onClick={() => this.onResendInvite(row)}>{i18n.t("ADMIN_USERS_RESEND")}</Button>
                              : row.is_activated === 1 ? 'In Active'
                                : 'Active'
                          }
                        </TableCell>
                      </TableRow>
                    )
                  }
                  )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 50 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[25]}
            component="div"
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.currentPage}
            count={this.state.filteredDataSource.length}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>

        {/*

        <React.Fragment>
          <Grid container>
            <Grid item xs={4}>
                <Typography style={{fontSize:28, marginTop:20}}>People</Typography>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={this.onSearch}
                  placeholder="Search People" style={{width:'100%'}}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            </Grid>
            <Grid item xs={4} style={{textAlign:'right'}}>
              {(this.props.userRoleId < 4 || this.props.userRoleId === 7) ?
                <Button variant="contained" style={{marginTop:20, marginLeft:10}} 
                  color="primary" aria-label="new" className={this.props.classes.margin} 
                  onClick={this.onNewClick}>
                  <AddIcon className={this.props.classes.extendedIcon} style={{marginRight:10, color:'#FFF'}} />
                  <Typography style={{fontWeight:'bold', color:'#FFF'}}>NEW</Typography>
                </Button> : null }
            </Grid>
          </Grid>
        </React.Fragment>

       
              */}

      </div>

    );
  }
}

UserScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    personId: state.auth.personId,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage,
    announcementAlert: state.alert.announcementAlert
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(UserScreen))));