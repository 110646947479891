import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid, IconButton,
    List, ListItem, ListItemText,
    MenuItem, TextField,
    Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class StudentClassrooms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isNewOpen: false,
            dataSource: [],
            colleges: [],
            extracurricular_activity_id: 0,
            selectedItem: {},
            isEditOpen: false,
            activity_name: '',
            groups: [],
            role: '',
            logoImage: "https://s3.amazonaws.com/tassel.com/pathway/person/41875534378004400.png?AWSAccessKeyId=AKIARE5YHMQIUQP6NVXT&Expires=1576162101&Signature=I0btgHJM2i6%2BAtCjJHqJKL5ScL8%3D",
            invite_token: ''
        }
    }

    componentWillReceiveProps(newProps) {
        this.getData(newProps.profileId);
    }

    componentDidMount() {

        this.getData(this.props.profileId);

    }

    getData(profileId) {

        this.setState({
            isLoading: true
        })
        fetch("https://api.tasselpathway.com/allStudentGroups/" + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        dataSource: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    getClassroom() {
        fetch("https://api.tasselpathway.com/custom_groups/join/" + this.state.invite_token, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                    })
                    this.onJoinClassroom(responseJson.results[0].custom_group_id)
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onJoinClassroom(custom_group_id) {
        fetch("https://api.tasselpathway.com/custom_groups/student", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            credentials: 'omit',
            body: JSON.stringify({
                custom_group_id: custom_group_id,
                person_id: this.props.personId
            })
        })
            .then(res => res.json())
            .then(responseJson => {
                this.setState({
                    isNewOpen: false,
                    invite_token: ''
                })
                this.componentDidMount();
            })
    }

    onEditExtracurricularClick() {

        fetch("https://api.tasselpathway.com/extracurricular_person", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            body: JSON.stringify({
                extracurricular_activity_id: this.state.selectedItem.extracurricular_activity_id,
                extracurricular_person_id: this.state.selectedItem.extracurricular_person_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isEditOpen: false,
                        isLoading: false,
                    })
                }
                this.getData(this.props.profileId);
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onDeleteExtracurricular() {

        this.setState({
            isLoading: true
        })

        fetch('https://api.tasselpathway.com/extracurricular_person', {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                extracurricular_person_id: this.state.selectedItem.extracurricular_person_id
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.getData(this.props.personId);
                    this.setState({
                        isLoading: false,
                        isEditOpen: false,
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onNewOpen() {
        this.setState({
            isNewOpen: true
        })
    }

    onNewClose = () => {
        this.setState({
            isNewOpen: false,
            isEditOpen: false,
        })
    }

    onEditOpen(item) {
        this.setState({
            isEditOpen: true,
            selectedItem: item
        })
    }

    onEditActivityChange(event) {
        var selectedItem = this.state.selectedItem;
        selectedItem.extracurricular_activity_id = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }

    onEditRoleChange(event) {
        var selectedItem = this.state.selectedItem;
        selectedItem.role = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }

    handleRoleChange(event) {
        this.setState({
            role: event.target.value
        })
    }

    onGroupClick(value) {
        this.props.props.history.push('/group/' + value);
    }

    renderGroups() {

        var content = this.state.dataSource.map((item, key) => {
            return (
                <MenuItem key={key} value={item.extracurricular_activity_id}>{item.activity_name}</MenuItem>
            )
        })

        return content;
    }


    renderStudentGroups() {

        var index = 0

        return (

            <Grid item xs={12}>

                <List style={{ overflow: 'auto' }}>

                    {this.state.dataSource.map((item, key) => {
                        return (
                            <ListItem key={key}>
                                <ListItemText style={{ fontSize: 16 }} primary={item.name}
                                    secondary={item.ownerFirstName + " " + item.ownerLastName} />
                            </ListItem>

                        )
                    })}

                </List>

            </Grid>
        );
    }

    render() {
        const { classes } = this.props;
        return (

            <div className={classes.root}>

                <Dialog open={this.state.isNewOpen}
                    onClose={this.onNewClose} fullWidth>

                    <DialogTitle onClose={this.onNewClose}>
                        Join Classroom
                    </DialogTitle>

                    <DialogContent>
                        <Grid container style={{ marginTop: -15 }}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    id="standard-name"
                                    label="Invite Token"
                                    onChange={(event) => this.setState({ invite_token: event.target.value })}
                                    value={this.state.invite_token}
                                    margin="normal" />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                            onClick={() => this.getClassroom()} color="primary">
                            {i18n.t("EXTRA_CURRICULAR_ADD_BUTTON")}
                        </Button>
                    </DialogActions>

                </Dialog>

                <Grid container style={styles.itemContainerStyle}>

                    <Grid item xs={12}>
                        <Grid container>

                            <Grid item xs={10}>
                                <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', marginTop: 20, color: '#4D4D4F' }}>
                                    Classrooms
                                </Typography>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <IconButton style={{ marginTop: 20 }} onClick={() => this.onNewOpen()}>
                                    <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                                </IconButton>
                            </Grid>

                            {this.renderStudentGroups()}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

StudentClassrooms.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        personId: state.auth.personId,
        token: state.auth.token,
        userRoleId: state.auth.userRoleId,
        profileId: state.profile.profileId
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StudentClassrooms));