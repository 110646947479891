import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/configureStore'
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./azure";

const { persistor, store } = configureStore()
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(

    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </I18nextProvider>
    </Provider>,
    
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
