import { GET_CAREER, SET_CAREER  } from "../actions";

export const defaultState = {
     collegeId: 0,
     collegeName: '',
};
 
export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case GET_CAREER:
             return Object.assign({}, state, {
             });
         case SET_CAREER:
             return Object.assign({}, state, {
                careerId: action.careerId,
                careerName: action.careerName
             });
         default:
             return state
     }
 }