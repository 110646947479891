import { useMsal } from "@azure/msal-react";
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { Button, Typography } from '@mui/material';
import React from "react";
import { loginRequest } from "../../azure";

function handleLogin(instance, props) {
    instance.loginPopup(loginRequest).then((res) => {

        if (res.account.username !== undefined) {
            props.onSuccess(res);
        }
        else {
            props.onFailure(res);
        }
    })
}

export const AzureLogin = (props) => {
    const { instance } = useMsal();

    return (
        <Button fullWidth variant="outlined"
            startIcon={<MicrosoftIcon />}
            onClick={() => handleLogin(instance, props)}
            style={{ color: '#FFFFFF', backgroundColor: '#55BF96', borderRadius: '50px', height: 40 }}>
            <Typography style={{
                color: '#FFFFFF', fontFamily: 'Outfit',
                fontStyle: 'normal',
                fontWeight: 500,
                textTransform: 'none',
                fontSize: '15px',
                lineHeight: '20px'
            }}>Azure Login</Typography>
        </Button>


    );
}