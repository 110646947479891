import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';
class TuitionScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: []
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/college_tuition/' + this.props.collegeId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            tuitionId: responseJson.results[0].tuition_id,
            collegeId: responseJson.results[0].college_id,
            collegeName: responseJson.results[0].college_name,
            inStateTuition: responseJson.results[0].in_state_tuition,
            outOfStateTuition: responseJson.results[0].out_of_state_tuition,
            roomAndBoard: responseJson.results[0].room_and_board,
            bookAndSupply: responseJson.results[0].book_and_supply
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  render() {

    const { classes } = this.props;

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })

    const collegePriceInStateFormated = formatter.format(this.state.inStateTuition)
    const collegePriceOutOfStateFormated = formatter.format(this.state.outOfStateTuition)

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#1F3545', marginBottom: 10 }}>{i18n.t("COLLEGE_TUITION")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                      <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_TUITION_ESTIMATED_COST")}</Typography>
                      <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>N/A</Typography>
                    </Grid>
                    {this.state.inStateTuition !== 0 ?
                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_TUITION_IN")}</Typography>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{collegePriceInStateFormated}</Typography>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Per school year</Typography>
                      </Grid>
                      :
                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_TUITION_OUT")}</Typography>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>N/A</Typography>
                      </Grid>
                    }

                    {this.state.outOfStateTuition !== 0 ?
                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_TUITION_OUT")}</Typography>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{collegePriceOutOfStateFormated}</Typography>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Per school year</Typography>
                      </Grid>
                      :
                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_TUITION_OUT")}</Typography>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>N/A</Typography>
                      </Grid>
                    }
                  </Grid>
                  <Grid container style={{ marginTop: 50 }}>

                    {this.state.roomAndBoard !== 0 ?
                      <Grid item xs={6} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_TUITION_ROOM")}</Typography>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{this.state.roomAndBoard}</Typography>
                      </Grid>
                      :
                      <Grid item xs={6} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_TUITION_ROOM")}</Typography>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>N/A</Typography>
                      </Grid>
                    }

                    {this.state.bookAndSupply !== 0 ?
                      <Grid item xs={6} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_TUITION_BOOKS")}</Typography>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{this.state.bookAndSupply}</Typography>
                      </Grid>
                      :
                      <Grid item xs={6} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_TUITION_BOOKS")}</Typography>
                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>N/A</Typography>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

      </div>
    );
  }
}

TuitionScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TuitionScreen));