import { GET_PERSON, SET_PERSON } from "../actions";

export const getPerson = () => {
   return {
       type: GET_PERSON
   }
};

export const setPerson = (personId, firstName, lastName) => {
    return {
        type: SET_PERSON,
        personId: personId,
        firstName: firstName,
        lastName: lastName
    }
 };
