import { faHandshake, faStar, faTrophy, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Card, CardContent,
  CircularProgress, Grid,
  List, ListItem, ListItemAvatar, ListItemText,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import styles from './style';

class ScoreScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      currentProgress: 0,
      serviceProgress: 0,
    }
  }

  componentDidMount() {
    let that = this;

    setTimeout(function () {
      that.setState({
        currentProgress: 50,
        serviceProgress: 75
      })

    }, 250);
  }

  onCardClick() {

  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <React.Fragment>
          <AppBar position="sticky" style={{ backgroundColor: '#FFF' }} elevation={0} className={classes.topBar}>
            <Toolbar>
              <Grid container>
                <Grid item xs={10}>
                  <Typography style={{ fontSize: 28, marginTop: 20 }}>Dashboard</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </React.Fragment>

        <br />

        <Grid container style={styles.itemContainerStyle}>

          <Grid item xs={4}>
            <Card style={{ marginLeft: 10 }}>
              <CardContent>
                <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#499CB3', marginBottom: 20 }}>Dashboard</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>

                  </Grid>
                </Grid>

                <br />
                <Grid style={{ textAlign: "center" }}>
                  <CircularProgress size={100} value={this.state.currentProgress}
                    className={classes.progress}
                    variant="static" />
                  <FontAwesomeIcon icon={faStar} style={{ position: 'absolute', fontSize: 38, marginLeft: -72, marginTop: 27, color: '#25D192' }} />
                  <Typography style={{ textAlign: 'center', fontSize: 22, fontWeight: 'bold' }}>10,526</Typography>
                  <Typography style={{ fontSize: 14, textAlign: 'center' }}>Points</Typography>
                </Grid>
                <Grid container style={{ marginTop: 30 }}>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <CircularProgress
                      size={75}
                      className={classes.progress}
                      variant="static"
                      value={this.state.serviceProgress} />
                    <FontAwesomeIcon icon={faUniversity} style={{ position: 'absolute', fontSize: 28, marginLeft: -52, marginTop: 25, color: '#25D192' }} />
                    <Typography style={{ marginTop: 10, fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>30 / 128</Typography>
                    <Typography style={{ fontSize: 12, textAlign: 'center' }}>Credit Hours</Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <CircularProgress
                      size={75}
                      className={classes.progress}
                      variant="static"
                      value={this.state.serviceProgress} />
                    <FontAwesomeIcon icon={faHandshake} style={{ position: 'absolute', fontSize: 28, marginLeft: -52, marginTop: 25, color: '#25D192' }} />
                    <Typography style={{ marginTop: 10, fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>15 / 20</Typography>
                    <Typography style={{ fontSize: 12, textAlign: 'center' }}>Service Hours</Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <CircularProgress
                      size={75}
                      className={classes.progress}
                      variant="static"
                      value={this.state.serviceProgress} />
                    <FontAwesomeIcon icon={faUniversity} style={{ position: 'absolute', fontSize: 28, marginLeft: -52, marginTop: 25, color: '#25D192' }} />
                    <Typography style={{ marginTop: 10, fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>24</Typography>
                    <Typography style={{ fontSize: 12, textAlign: 'center' }}>Dual-Credit</Typography>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 30 }}>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <CircularProgress
                      size={75}
                      className={classes.progress}
                      variant="static"
                      value={this.state.serviceProgress} />
                    <FontAwesomeIcon icon={faTrophy} style={{ position: 'absolute', fontSize: 28, marginLeft: -52, marginTop: 25, color: '#25D192' }} />
                    <Typography style={{ marginTop: 10, fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>10</Typography>
                    <Typography style={{ fontSize: 12, textAlign: 'center' }}>Activities</Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <CircularProgress
                      size={75}
                      className={classes.progress}
                      variant="static"
                      value={this.state.serviceProgress} />
                    <FontAwesomeIcon icon={faUniversity} style={{ position: 'absolute', fontSize: 28, marginLeft: -52, marginTop: 25, color: '#25D192' }} />
                    <Typography style={{ marginTop: 10, fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>15 / 20</Typography>
                    <Typography style={{ fontSize: 12, textAlign: 'center' }}>Service Hours</Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <CircularProgress
                      size={75}

                      className={classes.progress}
                      variant="static"
                      value={this.state.serviceProgress} />
                    <FontAwesomeIcon icon={faUniversity} style={{ position: 'absolute', fontSize: 28, marginLeft: -55, marginTop: 25, color: '#25D192' }} />
                    <Typography style={{ marginTop: 10, fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>24</Typography>
                    <Typography style={{ fontSize: 12, textAlign: 'center' }}>Dual-Credit</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

          </Grid>

          <Grid item xs={4}>

            <Card>
              <CardContent>
                <List className={classes.root}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <FontAwesomeIcon icon={faUniversity} style={{ color: '#25D192', fontSize: 24 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 16, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                            Service Learning Badge
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 12 }} component="span" className={classes.inline} color="textPrimary">
                            Description of How You Earn The Badge
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <FontAwesomeIcon icon={faUniversity} style={{ color: '#25D192', fontSize: 24 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 16, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                            Service Learning Badge
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 12 }} component="span" className={classes.inline} color="textPrimary">
                            Description of How You Earn The Badge
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <FontAwesomeIcon icon={faUniversity} style={{ color: '#25D192', fontSize: 24 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 16, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                            Service Learning Badge
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 12 }} component="span" className={classes.inline} color="textPrimary">
                            Description of How You Earn The Badge
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <FontAwesomeIcon icon={faUniversity} style={{ color: '#25D192', fontSize: 24 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 16, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                            Service Learning Badge
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 12 }} component="span" className={classes.inline} color="textPrimary">
                            Description of How You Earn The Badge
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <FontAwesomeIcon icon={faUniversity} style={{ color: '#25D192', fontSize: 24 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 16, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                            Service Learning Badge
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 12 }} component="span" className={classes.inline} color="textPrimary">
                            Description of How You Earn The Badge
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <FontAwesomeIcon icon={faUniversity} style={{ color: '#25D192', fontSize: 24 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 16, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                            Service Learning Badge
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 12 }} component="span" className={classes.inline} color="textPrimary">
                            Description of How You Earn The Badge
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <FontAwesomeIcon icon={faUniversity} style={{ color: '#25D192', fontSize: 24 }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 16, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                            Service Learning Badge
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography style={{ fontSize: 12 }} component="span" className={classes.inline} color="textPrimary">
                            Description of How You Earn The Badge
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>

              </CardContent>
            </Card>

          </Grid>

          <Grid item xs={4}>

            <Card style={{ marginTop: 10, marginLeft: 10 }}>
              <CardContent>
                <Table className={classes.table} height={300}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Rank</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Student</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }} align="center">Points</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">1</TableCell>
                      <TableCell align="right">Student 1</TableCell>
                      <TableCell align="right">1,000,000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">2</TableCell>
                      <TableCell align="right">Student 2</TableCell>
                      <TableCell align="right">900,000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">3</TableCell>
                      <TableCell align="right">Student 3</TableCell>
                      <TableCell align="right">800,000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">4</TableCell>
                      <TableCell align="right">Student 4</TableCell>
                      <TableCell align="right">700,000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">5</TableCell>
                      <TableCell align="right">Student 5</TableCell>
                      <TableCell align="right">600,000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">6</TableCell>
                      <TableCell align="right">Student 6</TableCell>
                      <TableCell align="right">500,000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">7</TableCell>
                      <TableCell align="right">Student 7</TableCell>
                      <TableCell align="right">400,000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">8</TableCell>
                      <TableCell align="right">Student 8</TableCell>
                      <TableCell align="right">300,000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">9</TableCell>
                      <TableCell align="right">Student 9</TableCell>
                      <TableCell align="right">200,000</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">10</TableCell>
                      <TableCell align="right">Student 10</TableCell>
                      <TableCell align="right">100,000</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ScoreScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScoreScreen));