import { GET_CAREER, SET_CAREER } from "../actions";

export const getCareer = () => {
   return {
       type: GET_CAREER
   }
};

export const setCareer = (careerId, careerName) => {
    return {
        type: SET_CAREER,
        careerId: careerId,
        careerName: careerName
    }
 };
