export default {

    container: {
        margin: 'auto',
        backgroundColor: 'white',
        padding: 25,
        height: '80vh',
        width: '80vw',
        overflow: 'scroll',
    },

    messageField: {
        marginTop: 20,
        minWidth: 600
    },

    select: {
        minWidth: 300,
    },

    headerStyle: {
        backgroundColor: '#25D192'
    },
    tableWrapper: {
        overflowX: 'auto'
    },
    iconStyle: {
        backgroundColor: '#25D192'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    },
    search: {
        position: 'relative',
        borderRadius: 5,
        backgroundColor: '#25D192',
        '&:hover': {
            backgroundColor: '#78E5C9',
        },
        marginTop: 18,
        marginRight: 30,
        width: '100%',
    },
    searchIcon: {
        width: 10,
        marginLeft: 15,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: 10,
        width: '100%',
        marginLeft: 35
    },
    headingText: {
        color: '#FFF',
        fontSize: 13
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    activeHeadingText: {
        color: '#FFF',
        fontSize: 13,
        fontWeight: 'bold'
    },
    mainContent: {
        flex: 1,
        padding: '10px 36px 0',
        background: '#FBFDFE',
    },
    topBar: {
        backgroundColor: '#FFF',
        paddingBottom: 15,
        borderBottom: '1px solid #eaeaea'
    }

}