import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText, Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class KnowledgeScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      newName: '',
      newProject: '',
      newProjectDescription: '',
      editName: '',
      editDescription: '',
      newNameValid: true,
      editNameValid: true,
      selectedItem: {},
      suggestions: [],
      suggestionList: [],
      searchText: ""
    }

    this.onNewOpen = this.onNewOpen.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onNewClick = this.onNewClick.bind(this);
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentDidMount() {
    this.getData(this.props.profileId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(profileId) {
    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_knowledge/person/' + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
          this.props.onProjectsLoaded(responseJson.results)
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  createProject = () => {

    fetch('https://api.tasselpathway.com/profile_project', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId,
        project_name: this.state.newProject,
        project_description: this.state.newProjectDescription
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          var obj = {
            project_id: responseJson.project_id,
            project_name: this.state.newProject,
            project_description: this.state.newProjectDescription
          }

          var items = this.state.dataSource;
          items.push(obj);

          this.setState({
            isLoading: false,
            dataSource: items
          })

          this.onNewClose()

        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  saveProject() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_project', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        project_id: this.state.selectedItem.project_id,
        person_id: this.props.personId,
        project_name: this.state.selectedItem.project_name,
        project_description: this.state.selectedItem.project_description,
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.profileId);

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  deleteProject() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_project', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        project_id: this.state.selectedItem.project_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.profileId);

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderProjects() {

    var content = this.state.dataSource.map((item, key) => {
      return (
        <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
          onClick={() => this.onEditOpen(item)}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                  className={this.props.classes.inline} color="textPrimary">
                  {item.project_name}
                </Typography>
                <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.project_description}</Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    })

    return content;
  }

  onNewOpen() {
    this.setState({
      newName: '',
      newDescription: '',
      isNewOpen: true
    })
  }

  onNewClose() {

    this.setState({
      isNewOpen: false
    })
  }

  onNewAdded() {
    this.componentDidMount()

    this.setState({
      isNewOpen: false
    })
  }

  onNewClick() {

    var nameValid = true;

    if (this.state.newName === "") {
      nameValid = false;
    }

    this.setState({
      newNameValid: nameValid
    })

    if (nameValid) {
      this.createActivity();

      this.setState({
        isNewOpen: false
      })
    }
  }

  onEditClose() {

    this.setState({
      isEditOpen: false
    })
  }

  onEditOpen(item) {
    if (this.props.personId === this.props.profileId) {

      this.setState({
        selectedItem: item,
        isEditOpen: true
      })
    }
  }

  onEditClick() {

    if (this.state.selectedItem.project_name !== "" &&
      this.state.selectedItem.project_description !== "") {
      this.saveProject();

      this.setState({
        isEditOpen: false
      })
    }
  }

  onDeleteClick() {
    this.deleteProject();

    this.setState({
      isEditOpen: false
    })
  }

  onNameChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.project_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDescriptionChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.project_description = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };

  render() {
    const { i18n } = this.props;
    const { classes } = this.props;

    return (
      <div>

        <div className={classes.root}>

          <Dialog open={this.state.isNewOpen}
            onClose={this.onNewClose} maxWidth="sm"
            fullWidth={true}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
              {i18n.t("PROJECT_NEW")}
            </DialogTitle>

            <DialogContent>

              <div>

                <FormControl fullWidth>
                  <InputLabel htmlFor="role">{i18n.t("PROJECT_NAME")}</InputLabel>
                  <Input fullWidth id="role"
                    onChange={(event) => this.setState({ newProject: event.target.value })}
                    aria-describedby="my-helper-text" />
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="description">{i18n.t("PROJECT_DESCRIPTION")}</InputLabel>
                  <Input variant="outline" multiline={true} rows={4} fullWidth
                    onChange={(event) => this.setState({ newProjectDescription: event.target.value })}
                    id="description" aria-describedby="my-helper-text" />
                </FormControl>

              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.createProject} color="primary"
                style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }}>
                {i18n.t("PROJECT_ADD_BUTTON")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isEditOpen} className={classes.popup}
            onClose={this.onEditClose} maxWidth="sm"
            fullWidth={true}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title"
              onClose={this.onEditClose}>
              {i18n.t("PROJECT_EDIT")}
            </DialogTitle>

            <DialogContent>

              <div>

                <FormControl fullWidth>
                  <InputLabel htmlFor="role">{i18n.t("PROJECT_NAME")}</InputLabel>
                  <Input fullWidth id="role" value={this.state.selectedItem.project_name}
                    onChange={(event) => this.onNameChange(event)}
                    aria-describedby="my-helper-text"> </Input>
                </FormControl>

                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="description">{i18n.t("PROJECT_DESCRIPTION")}</InputLabel>
                  <Input multiline={true} rows={4} fullWidth
                    value={this.state.selectedItem.project_description}
                    onChange={(event) => this.onDescriptionChange(event)}
                    id="description" aria-describedby="my-helper-text" />
                </FormControl>

              </div>

            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => this.onDeleteClick()} color="secondary">
                <Typography style={{ color: '#FFF', fontWeight: 'bold' }} >{i18n.t("PROJECT_DELETE_BUTTON")}</Typography>
              </Button>
              <Button variant="contained"
                style={{ marginRight: 20 }}
                onClick={() => this.onEditClick()} color="primary">
                <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>{i18n.t("PROJECT_SAVE_BUTTON")}</Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("PROFILE_KNOWLEDGE_TILE")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right', marginTop: 5, alignItems: 'right' }}>
                  {this.props.profileId === this.props.personId ?
                    <Button color="primary" variant="contained" onClick={() => this.onNewClick()}>
                      <Typography style={{ fontWeight: 'bold', fontSize: 16, color: '#FFF' }}>Start</Typography>
                    </Button>
                    : null}
                </Grid>
              </Grid>

              {this.state.dataSource.length === 0 ?
                <Grid container spacing={0}
                  direction="column" alignItems="center" justify="center" style={{ height: 200 }}>

                  <Typography style={{ fontSize: 14 }}>
                    Knowledge includes understanding the skills needed to complete work related tasks and the
                    ability to apply that understanding and skills to a job. Students should be able to exhibit
                    explaining or demonstrating that knowledge to others so that they can explain learned skills
                    to other colleagues using reading, writing, mathematics, science and technology skills as
                    required by the job.
                  </Typography>

                </Grid>
                :

                <Grid container>
                  <Grid item xs={12}>
                    <List style={{ height: 200, overflow: 'auto' }}>
                      {this.renderKnowledge()}
                    </List>
                  </Grid>
                </Grid>
              }

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

KnowledgeScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    profileId: state.profile.profileId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(KnowledgeScreen)));