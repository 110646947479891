import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "../actions";
import { setPerson } from './person';
import { setTask } from './task';

export const loginRequest = () => {
    return {
        type: LOGIN_REQUEST
    };
};

export const loginSuccess = (username, password, personId, gradeId, gradeName, firstName,
    lastName, token, userRoleId, userRightId, hasMultiDistrict) => {
    return {
        type: LOGIN_SUCCESS,
        username: username,
        password: password,
        personId: personId,
        gradeId: gradeId,
        gradeName: gradeName,
        firstName: firstName,
        lastName: lastName,
        token: token,
        userRoleId: userRoleId,
        userRightId: userRightId,
        hasMultiDistrict: hasMultiDistrict
    }
};

export const login = (username, password, language) => {
    return (dispatch) => {

        if (!username || !password) {
            dispatch(loginFailure(true));
            return;
        }

        fetch('https://api.tasselpathway.com/authenticate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_name: username,
                password: password,
            })
        })
            .then(response => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.authenticated === true) {
                    fetch(`https://api.tasselpathway.com/user_settings/${responseJson.person_id}/${language}`, {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "token": responseJson.token
                        },
                        credentials: 'omit'
                    })
                        .then(res => res.json())
                        .then(responseJson2 => {
                            if (responseJson2.error === false) {
                                dispatch(loginSuccess(username, password, responseJson.person_id, responseJson.grade_id,
                                    responseJson.grade_name, responseJson.first_name, responseJson.last_name, responseJson.token,
                                    responseJson.user_role_id, responseJson.user_right_id, responseJson.has_multi_district));
                                dispatch(setTask(0, '', '', '', 0, 0, ''));
                                dispatch(setPerson(responseJson.person_person_id, responseJson.person_first_name, responseJson.person_last_name));
                            }
                        })
                } else {
                    dispatch(loginFailure(true));
                }
            })
            .catch((e) => {
                console.warn(e);
                dispatch(loginFailure(true));
            });
    };
};

export const loginFailure = (hasError) => {
    return {
        type: LOGIN_FAILURE,
        hasError: hasError
    };
};

export const logout = () => {
    return {
        type: LOGOUT
    };
};