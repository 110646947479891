import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import LinkIcon from '@mui/icons-material/Link';
import { Avatar, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Input, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';


const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class LinksTeacherScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isNewOpen: false,
            isEditOpen: false,
            dataSource: [],
            grades: [],
            gradeList: [],
            gradeNameList: [],
            checkList: [],
            newDetails: [],
            schools: [],
            schoolCheckList: [],
            newSchoolDetails: [],
            schoolNameList: [],
            isEditLinkOpen: false,
            selectedLink: [],
            editedLinkName: '',
            editedLinkUrl: '',
            gradeLength: 0,
            schoolLength: 0,
            linkTitleToPost: " ",
            linkToPost: " ",
            isExpanded: true
        }

        this.onNewClose = this.onNewClose.bind(this);
        this.onNewClick = this.onNewClick.bind(this);

        this.onEditClose = this.onEditClose.bind(this);

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(newProps) {
        this.getData(newProps.personId);
    }

    componentDidMount() {
        this.getData(this.props.personId);
    }

    getData(personId) {

        fetch('https://api.tasselpathway.com/grades', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        grades: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch('https://api.tasselpathway.com/schools/person/' + personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        schools: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch('https://api.tasselpathway.com/links', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        dataSource: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onSaveLink() {

        fetch('https://api.tasselpathway.com/links', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                link_name: this.state.selectedLink.link_name,
                link_url: this.state.selectedLink.link_url,
                link_id: this.state.selectedLink.link_id,
                schoolDetails: this.state.newSchoolDetails,
                gradeDetails: this.state.newDetails
            })
        })
            .then(response => { return response.json() })
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        isEditLinkOpen: false,
                        newSchoolDetails: [],
                        newDetails: []
                    })

                    this.getData(this.props.personId);
                }
            })
            .catch((e) => {
                console.warn(e);
            });
    }

    onNewClose() {
        this.setState({
            isNewOpen: false
        })
    }

    onNewClick() {
        this.setState({
            isNewOpen: true,
            checkList: [],
            schoolCheckList: [],
            selectedTarget: ''
        })
    }

    onEditClose() {
        this.setState({
            isEditOpen: false
        })
    }

    onEditOpen(item) {
        this.setState({
            selectedItem: item,
            isEditOpen: true
        })
    }

    handleChange(newDate) {
        this.setState({
            date: newDate
        });
    }

    handleInputChange = name => event => {
        if (this.state.dataSource.length > 0) {
            this.setState({ ...this.state, [name]: event.target.value, editButton: true });
        } else {
            this.setState({ ...this.state, [name]: event.target.value });
        }
    }

    onNewSchoolChange(event, schoolName) {

        var newSchoolDetails = this.state.newSchoolDetails;
        var schoolCheckList = this.state.schoolCheckList;
        var schoolId = event.target.value;
        var i = 0;
        var schoolNameArray = this.state.schoolNameList

        if (event.target.checked) {
            newSchoolDetails.push({ school_id: schoolId })
            schoolCheckList['checked_' + schoolId] = true;
            schoolNameArray.push(schoolName)
        } else {
            newSchoolDetails.map((item, key) => {
                if (item.school_id === schoolId) {
                    newSchoolDetails.splice(i, 1);
                    schoolCheckList['checked_' + schoolId] = false
                    const index = schoolNameArray.indexOf(schoolName);
                    if (index > -1) {
                        schoolNameArray.splice(index, 1);
                    }
                }
                i++;
            })
        }
        this.setState({
            checkList: schoolCheckList,
            newSchoolDetails: newSchoolDetails,
            schoolNameList: this.state.schoolNameList
        })
    }

    onAddLink() {

        fetch('https://api.tasselpathway.com/links', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                link_name: this.state.linkTitleToPost,
                link_url: this.state.linkToPost,
                schoolDetails: this.state.newSchoolDetails,
                gradeDetails: this.state.newDetails
            })
        })
            .then(response => { return response.json() })
            .then((responseJson) => {
                this.setState({
                    isEditLinkOpen: false,
                    linkTitleToPost: '',
                    linkToPost: '',
                    newSchoolDetails: [],
                    newDetails: [],
                    isNewOpen: false
                })
                this.getData(this.props.profileId)
            })
    }

    selectAudience = (event) => {
        this.setState({
            selectedTarget: event.target.value,
            gradeNameList: [],
            newDetails: [],
            schoolNameList: [],
            newSchoolDetails: [],
            checkList: [],
            schoolCheckList: []
        })
    }

    editLinkOpen = (item) => {
        this.setState({
            isEditLinkOpen: true,
            selectedLink: item,
            gradeLength: item.gradeDetails.length,
            schoolLength: item.schoolDetails.length
        })

        if (item.gradeDetails.length > 0) {
            this.setState({
                isEditLinkOpen: true,
                selectedTarget: 'Grades'
            })

            var details = this.state.newDetails;
            var checkList = this.state.checkList;
            var gradeNameArray = this.state.gradeNameList

            item.gradeDetails.map((item, key) => {
                details.push({ grade_id: item.grade_id })
                checkList['checked_' + item.grade_id] = true;
                gradeNameArray.push(item.grade_name)
            })
            this.setState({
                checkList: checkList,
                newDetails: details,
                gradeNameList: gradeNameArray
            })

        }

        if (item.schoolDetails.length > 0) {
            this.setState({
                isEditLinkOpen: true,
                selectedTarget: 'School'
            })

            var newSchoolDetails = this.state.newSchoolDetails;
            var schoolCheckList = this.state.schoolCheckList;
            var schoolNameArray = this.state.schoolNameList

            item.schoolDetails.map((item, key) => {
                newSchoolDetails.push({ school_id: item.school_id })
                schoolCheckList['checked_' + item.school_id] = true;
                schoolNameArray.push(item.school_name)
            })
            this.setState({
                schoolCheckList: schoolCheckList,
                newSchoolDetails: newSchoolDetails,
                schoolNameList: this.state.schoolNameList
            })
        }

        if (item.gradeDetails.length === 0 && item.schoolDetails.length === 0) {
            this.setState({
                isEditLinkOpen: true,
                selectedTarget: 'District'
            })
        }
    }


    editLinkClose = () => {
        this.setState({
            isEditLinkOpen: false
        })
    }

    onDeleteLink() {
        fetch('https://api.tasselpathway.com/links', {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                link_id: this.state.selectedLink.link_id
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isEditLinkOpen: false,
                        selectedItem: {},
                        newSchoolDetails: [],
                        newDetails: []
                    })

                    this.getData(this.props.personId);

                    this.setState({
                        isLoading: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onLinkNameChange(event) {
        var selectedLink = this.state.selectedLink;
        selectedLink.link_name = event.target.value;

        this.setState({
            selectedLink: selectedLink
        })
    }

    onLinkUrlChange(event) {
        var selectedLink = this.state.selectedLink;
        selectedLink.link_url = event.target.value;

        this.setState({
            selectedLink: selectedLink
        })
    }

    onNewGradeChange(event, gradeName) {
        var details = this.state.newDetails;
        var checkList = this.state.checkList;
        var gradeId = event.target.value;
        var i = 0;
        var gradeNameArray = this.state.gradeNameList

        if (event.target.checked) {
            details.push({ grade_id: gradeId })
            checkList['checked_' + gradeId] = true;
            gradeNameArray.push(gradeName)
        } else {
            details.map((item, key) => {
                if (item.grade_id === gradeId) {
                    details.splice(i, 1);
                    checkList['checked_' + gradeId] = false
                    const index = gradeNameArray.indexOf(gradeName);
                    if (index > -1) {
                        gradeNameArray.splice(index, 1);
                    }
                }
                i++;
            })
        }
        this.setState({
            checkList: checkList,
            newDetails: details,
            gradeNameList: gradeNameArray
        })
    }

    renderLinks() {

        return (

            <Grid item xs={12}>

                <List style={{ overflow: 'auto', height: 350 }}>

                    {this.state.dataSource.map((item, key) => {
                        return (
                            <ListItem key={key} button onClick={() => this.onLinkClick(item.link_url)}>
                                <Avatar style={{ marginLeft: -15, backgroundColor: '#25D192' }}>
                                    <LinkIcon />
                                </Avatar>
                                <ListItemText style={{ marginLeft: 10 }} primary={item.link_name} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" onClick={() => this.editLinkOpen(item)}
                                        aria-label="comments">
                                        <CreateIcon style={{ fontSize: '18' }} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })}

                </List>

            </Grid>
        );
    }

    onLinkClick(url) {
        window.open(url);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>

                <Dialog open={this.state.isEditLinkOpen} className={classes.popup}
                    onClose={this.editLinkClose} size="md" fullWidth
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.editLinkClose}>
                        Edit Link
                    </DialogTitle>


                    <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>

                        <FormControl style={{ paddingBottom: 10 }}>
                            <InputLabel htmlFor="linkName">Link Name</InputLabel>
                            <Input id="linkName" fullWidth value={this.state.selectedLink.link_name}
                                onChange={(event) => this.onLinkNameChange(event)} />
                        </FormControl>

                        <FormControl style={{ paddingBottom: 10 }}>
                            <InputLabel htmlFor="link">Link URL</InputLabel>
                            <Input id="link" fullWidth value={this.state.selectedLink.link_url}
                                onChange={(event) => this.onLinkUrlChange(event)} />
                        </FormControl>


                        <FormControl>

                            <Grid container>

                                <Grid item xs={12}>

                                    <FormControl style={{ width: '100%', marginTop: 10 }}>
                                        <InputLabel htmlFor="newFrequencyId">Choose Audience</InputLabel>
                                        <Select fullWidth
                                            value={this.state.selectedTarget}

                                            onChange={(event) => this.selectAudience(event)}
                                            inputProps={{
                                                name: 'newFrequencyId',
                                                id: 'newFrequencyId',
                                            }}>
                                            <MenuItem disabled value={0}>Choose</MenuItem>
                                            <MenuItem value={'District'}>District</MenuItem>
                                            <MenuItem value={'School'}>School</MenuItem>
                                            <MenuItem value={'Grades'}>Grades</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                            </Grid>

                            {this.state.selectedTarget === 'Grades' ?

                                <>
                                    <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select Grade(s)</Typography>

                                    <FormGroup row style={{ marginLeft: 20 }}>

                                        {this.state.grades.map((item, key) => {
                                            return (
                                                <FormControlLabel key={key} style={{ fontSize: 12, }} onChange={(event) => this.onNewGradeChange(event, item.grade_name)}
                                                    control={
                                                        <Checkbox value={item.grade_id} style={{ fontSize: 12, }} checked={this.state.checkList['checked_' + item.grade_id] ? true : false} />
                                                    }
                                                    label={item.grade_name}
                                                />
                                            )
                                        })}

                                    </FormGroup>
                                </>

                                : null}

                            {this.state.selectedTarget === 'School' ?

                                <>
                                    <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select School(s)</Typography>

                                    <FormGroup row style={{ marginLeft: 20 }}>

                                        {this.state.schools.map((item, key) => {
                                            return (
                                                <FormControlLabel key={key} onChange={(event) => this.onNewSchoolChange(event, item.school_name)}
                                                    control={
                                                        <Checkbox value={item.school_id} checked={this.state.schoolCheckList['checked_' + item.school_id] ? true : false} />
                                                    }
                                                    label={item.school_name}
                                                />
                                            )
                                        })}

                                    </FormGroup>
                                </>

                                : null}

                        </FormControl>


                    </DialogContent>

                    <DialogActions>

                        <Button size='small' variant="contained" onClick={() => this.onDeleteLink()}
                            style={{ backgroundColor: '#EA605B', color: '#FFF', fontWeight: 'bold', marginRight: 10 }}
                            color="primary">
                            Delete
                        </Button>
                        <Button size='small' variant="contained" onClick={() => this.onSaveLink()}
                            style={{ color: '#FFF', fontWeight: 'bold', marginRight: 10 }}
                            color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.isNewOpen} className={classes.popup}
                    onClose={this.onNewClose} size="md" fullWidth
                    aria-labelledby="form-dialog-title">

                    <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
                        New Link
                    </DialogTitle>

                    <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>

                        <FormControl style={{ paddingBottom: 10 }}>
                            <InputLabel htmlFor="linkName">Link Name</InputLabel>
                            <Input id="linkName" fullWidth value={this.state.linkTitleToPost}
                                onChange={(e) => this.setState({ linkTitleToPost: e.target.value })} />
                        </FormControl>

                        <FormControl style={{ paddingBottom: 10 }}>
                            <InputLabel placeholder="eg. https://www.tasselpathway.com" htmlFor="link">Link URL</InputLabel>
                            <Input id="link" fullWidth value={this.state.linkToPost}
                                onChange={(e) => this.setState({ linkToPost: e.target.value })} />
                        </FormControl>

                        <FormControl>

                            <Grid container>

                                <Grid item xs={12}>

                                    <FormControl style={{ width: '100%', marginTop: 10 }}>
                                        <InputLabel htmlFor="newFrequencyId">Choose Audience</InputLabel>
                                        <Select fullWidth
                                            value={this.state.selectedTarget}

                                            onChange={(event) => this.selectAudience(event)}
                                            inputProps={{
                                                name: 'newFrequencyId',
                                                id: 'newFrequencyId',
                                            }}>
                                            <MenuItem disabled value={0}>Choose</MenuItem>
                                            <MenuItem value={'District'}>District</MenuItem>
                                            <MenuItem value={'School'}>School</MenuItem>
                                            <MenuItem value={'Grades'}>Grades</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                            </Grid>


                            {this.state.selectedTarget === 'Grades' ?

                                <>
                                    <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select Grade(s)</Typography>

                                    <FormGroup row style={{ marginLeft: 20 }}>

                                        {this.state.grades.map((item, key) => {
                                            return (
                                                <FormControlLabel key={key} style={{ fontSize: 12, }}
                                                    onChange={(event) => this.onNewGradeChange(event, item.grade_name)}
                                                    control={
                                                        <Checkbox value={item.grade_id} style={{ fontSize: 12, }} checked={this.state.checkList['checked_' + item.grade_id] ? true : false} />
                                                    }
                                                    label={item.grade_name}
                                                />
                                            )
                                        })}

                                    </FormGroup>
                                </>

                                : null}

                            {this.state.selectedTarget === 'School' ?

                                <>
                                    <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select School(s)</Typography>

                                    <FormGroup row style={{ marginLeft: 20 }}>

                                        {this.state.schools.map((item, key) => {
                                            return (
                                                <FormControlLabel key={key} onChange={(event) => this.onNewSchoolChange(event, item.school_name)}
                                                    control={
                                                        <Checkbox value={item.school_id} checked={this.state.schoolCheckList['checked_' + item.school_id] ? true : false} />
                                                    }
                                                    label={item.school_name}
                                                />
                                            )
                                        })}

                                    </FormGroup>
                                </>

                                : null}

                        </FormControl>

                    </DialogContent>

                    <DialogActions>
                        <Button size='small' variant="contained" onClick={() => this.onAddLink()}
                            style={{ color: '#FFF', fontWeight: 'bold', marginBottom: 10, marginRight: 20 }}
                            color="primary">
                            Add
                        </Button>
                    </DialogActions>

                </Dialog>

                <Grid container style={styles.itemContainerStyle}>

                    <Grid item xs={12}>
                        <Grid container>

                            <Grid item xs={10}>
                                <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', marginTop: 20, color: '#4D4D4F' }}>
                                    Links
                                </Typography>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <IconButton style={{ marginTop: 20 }} onClick={() => this.onNewClick()}>
                                    <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                                </IconButton>
                            </Grid>

                            {this.renderLinks()}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

LinksTeacherScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        personId: state.auth.personId,
        token: state.auth.token,
        userRoleId: state.auth.userRoleId,
        profileId: state.profile.profileId,
        selectedLanguage: state.language.selectedLanguage,
        schoolId: state.profile.schoolId,
        gradeId: state.profile.gradeId
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LinksTeacherScreen));