export default {

    containerStyle: {
        backgroundColor:'#eaeaea',
        height:'100%'
    },
    itemContainerStyle: {
        padding:10,
        height:'100%'
    },
    headerStyle: {
      backgroundColor: '#25D192'  
    },
    avatar: {
        width:50,
        height:50
    },
    search: {
        position: 'relative',
        borderRadius: 5,
        backgroundColor: '#25D192',
        '&:hover': {
            backgroundColor: '#78E5C9',
        },
        marginTop:18,
        marginRight: 30,
        marginLeft: 10,
        width: '100%',
    },
    searchIcon: {
        width: 10,
        marginLeft:15,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: 10,
        width: '100%',
        marginLeft:35
    },

    optionsView: {
        flexDirection: 'row',
        width: '100%',
        paddingBottom: 10,
    },
    optionsFlex: {
        flex: 9
    },
    optionsText: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#499CB3'
    },
    borderStyle: {
        flexDirection: 'row',
        paddingBottom: 10,
        marginTop: 10,
        width: '100%',
        borderBottomColor: '#eaeaea',
        borderBottomWidth: 1,
    },
    dateFlex: {
        flex: 5,
    },
    dateText: {
        textAlign: 'right',
    },
    emptyView: {
        flex: 1,
    },
    editText: {
        fontWeight: 'bold',
        color: '#E68846',
    },
    tableWrapper: {
        overflow: 'auto'

    },
    expanded: {
        margin: '0 auto',
        padding: 0
    },
    topBar: {
      backgroundColor: '#FFF',
      paddingBottom:15,
      borderBottom:'1px solid #eaeaea'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    inputLogo: {
        color: '#33CB89',
        display: 'inline-block',
        cursor: 'pointer',
        padding:10,
        borderRadius:5,
        marginBottom:10
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#F7F7F7'
      },
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
      },
      section2: {
        top:10,
        right:-80,
        margin: 10,
        padding: 10,
        height:20,
      },
      headerText:{
          fontFamily: 'Lato',
          color:'#4fdaa5',
          fontSize:18,
          marginTop:20,
          marginLeft:20,
      },
      subHeaderText:{
          fontFamily: 'Heebo',
          color:'#000',
          fontSize:10,
          marginLeft:20,
          // letterSpacing:2
      },
      pathwayText:{
          fontFamily: 'Heebo',
          color:'#000',
          fontSize:11,
          textOverflow: 'breakWord',
          marginLeft:20,
      },
      sectionHeaderTextLeft:{
          fontFamily: 'Lato',
          backgroundColor:'#4fdaa5',
          borderRadius:2,
          fontSize:8,
          marginTop:50,
          marginLeft:30,
          padding:7,
          color:'#FFF',
          letterSpacing:1.2,
          width:180
      },
      sectionHeaderTextRight:{
          fontFamily: 'Lato',
          backgroundColor:'#4fdaa5',
          borderRadius:2,
          fontSize:8,
          marginTop:107,
          marginRight:30,
          padding:7,
          color:'#FFF',
          letterSpacing:1.2,
          width:180
      },
      sectionHeaderTextRightPage2:{
          fontFamily: 'Lato',
          backgroundColor:'#4fdaa5',
          borderRadius:2,
          fontSize:8,
          marginTop:21,
          marginRight:30,
          padding:7,
          color:'#FFF',
          letterSpacing:1.2,
          width:180
      },
      sectionHeaderTextRightSecond:{
          fontFamily: 'Lato',
          backgroundColor:'#4fdaa5',
          borderRadius:2,
          fontSize:8,
          marginTop:50,
          marginRight:30,
          padding:7,
          color:'#FFF',
          letterSpacing:1.2,
          width:180
      },
      sectionHeaderTextRightSecondTopCareers:{
          fontFamily: 'Lato',
          backgroundColor:'#4fdaa5',
          borderRadius:2,
          fontSize:8,
          marginTop:50,
          marginRight:30,
          padding:7,
          color:'#FFF',
          letterSpacing:1.2,
          width:180,
          marginBottom:25
      },
      bulletPointTextLeft:{
          fontFamily: 'Heebo',
          color:'#000',
          fontSize:8,
          marginLeft:35,
          paddingTop:5,
      },
      bulletPointHeaderTextLeft:{
          fontFamily: 'Lato',
          color:'#000',
          fontSize:8,
          marginLeft:35,
          paddingTop:10,
      },
      bulletPointTextRight:{
          fontFamily: 'Heebo',
          color:'#000',
          fontSize:8,
          marginLeft:5,
          paddingTop:5,
          maxWidth:180
      },
      bulletPointHeaderTextRight:{
          fontFamily: 'Lato',
          color:'#000',
          fontSize:8,
          marginLeft:5,
          paddingTop:10,
      },
      bulletPointHeaderTextRightCareers:{
          fontFamily: 'Lato',
          color:'#000',
          fontSize:6,
          marginLeft:5,
          paddingTop:10,
          maxWidth:200
      }
  };