export default {
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 8
    },
    linkTag:{
        color:'blue'
    }
}
