import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton, List,
  ListItem, ListItemText,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class BioScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      grade_id: 0,
      selectedItem: {}
    }

    this.onNewClose = this.onNewClose.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {

    this.getData(this.props.profileId);

  }

  getData(profileId) {

    this.setState({
      isLoading: true
    })

    fetch("https://api.tasselpathway.com/profile_bio/" + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onAddBio() {

    fetch("https://api.tasselpathway.com/profile_bio", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        person_id: this.props.personId,
        bio: this.state.bio
      })
    })
      .then(res => res.json())
      .then(responseJson => {
        if (responseJson.error === false) {
          this.setState({
            isNewOpen: false
          })
        }

        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onSaveBio() {
    fetch("https://api.tasselpathway.com/profile_bio", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        bio: this.state.selectedItem.bio,
        profile_bio_id: this.state.selectedItem.profile_bio_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isEditOpen: false,
            isLoading: false,
          })
        }
        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onNewClick() {
    this.setState({
      isNewOpen: true
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onEditOpen(item) {
    this.setState({
      isEditOpen: true,
      selectedItem: item,
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onEditBioChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.bio = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  renderBio() {

    var content = this.state.dataSource.map((item, key) => {
      return (
        <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
          onClick={() => this.onEditOpen(item)}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                  className={this.props.classes.inline} color="textPrimary">
                  {item.bio}
                </Typography>
                <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.project_description}</Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    })

    return content;
  }

  render() {

    const { i18n } = this.props;
    const { classes } = this.props;
    return (
      <div>

        <div className={classes.root}>

          <Dialog open={this.state.isNewOpen}
            onClose={this.onNewClose} fullWidth
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
              {i18n.t("BIO_TILE_NEW")}
            </DialogTitle>

            <DialogContent>
              {this.props.language === "esp" ?
                <TextField
                  id="standard-name" fullWidth
                  label="Biografía" multiline rows={6}
                  onChange={(e) => this.setState({ bio: e.target.value })}
                  value={this.state.bio} />
                :
                <TextField
                  id="standard-name" fullWidth
                  label="Bio" multiline rows={6}
                  onChange={(e) => this.setState({ bio: e.target.value })}
                  value={this.state.bio} />
              }
            </DialogContent>
            <DialogActions>
              <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                onClick={() => this.onAddBio()} color="primary">
                {i18n.t("BIO_TILE_ADD")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isEditOpen}
            onClose={this.onEditClose} fullWidth
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
              {i18n.t("BIO_TILE_EDIT")}
            </DialogTitle>

            <DialogContent>

              <Grid container>

                <Grid item xs={12}>
                  {this.props.language === "esp" ?
                    <TextField fullWidth multiline rows={6}
                      id="standard-name"
                      label="Biografía"
                      onChange={(event) => this.onEditBioChange(event)}
                      value={this.state.selectedItem.bio}
                      margin="normal"
                    />
                    :
                    <TextField fullWidth multiline rows={6}
                      id="standard-name"
                      label="Bio"
                      onChange={(event) => this.onEditBioChange(event)}
                      value={this.state.selectedItem.bio}
                      margin="normal"
                    />
                  }
                </Grid>

              </Grid>

            </DialogContent>
            <DialogActions>
              <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                onClick={() => this.onSaveBio(this.state.selectedItem.profile_bio_id)} color="primary">
                {i18n.t("BIO_TILE_SAVE")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                    {i18n.t("TEACHER_PROFILE_BIO_TILE")}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewClick()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.renderBio()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

BioScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BioScreen)));