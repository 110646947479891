
 import { GET_PERSON, SET_PERSON  } from "../actions";

 export const defaultState = {
     personId: 0,
     firstName: 'All',
     lastName: 'Personnel'
 };
 
 export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case GET_PERSON:
             return Object.assign({}, state, {
             });
         case SET_PERSON:
             return Object.assign({}, state, {
                 personId: action.personId,
                 firstName: action.firstName,
                 lastName: action.lastName
             });
         default:
             return state
     }
 }