import { Grid, Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { setProgress } from '../../redux/actions/progress';
import styles from './style';

import AnnouncementsScreen from './announcements/index';
import CommunicationsScreen from './communications/index';
import CourseScreen from './course/index';
import DiplomaScreen from './diploma/index';
import PathwayScreen from './pathways/index';
import ProgressScreen from './progress/index';
import SchoolScreen from './school/index';
import UserScreen from './users/index';

class AdminScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      value: 0
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

  }

  handleChange = (event, value) => {
    this.setState({
      value
    });
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Grid container style={{ marginTop: 20 }}>

          <Grid item xs={1}></Grid>

          <Grid item xs={10}>

            {this.props.selectedLanguage === "esp" ?
              <Tabs value={this.state.value} onChange={this.handleChange}>
                <Tab label="DIPLOMAS" />
                <Tab label="PATHWAYS" />
                <Tab label="CURSOS" />
                <Tab label="ESCUELAS" />
                <Tab label="USARIOS" />
                <Tab label="COMUNICACIÓNES" />
                <Tab label="PROGRESS" />
                <Tab label="ANNOUNCEMENTS" />
              </Tabs>
              :
              <Tabs value={this.state.value} onChange={this.handleChange}>
                <Tab label="DIPLOMAS" />
                <Tab label="PATHWAYS" />
                <Tab label="COURSES" />
                <Tab label="SCHOOLS" />
                <Tab label="USERS" />
                <Tab label="PROGRESS" />
                <Tab label="COMMUNICATIONS" />
                <Tab label="ANNOUNCEMENTS" />
              </Tabs>
            }

            {this.state.value === 0 && <DiplomaScreen onSearch={callable => this.setState({ onSearch: callable })} />}
            {this.state.value === 1 && <PathwayScreen onSearch={callable => this.setState({ onSearch: callable })} />}
            {this.state.value === 2 && <CourseScreen onSearch={callable => this.setState({ onSearch: callable })} />}
            {this.state.value === 3 && <SchoolScreen onSearch={callable => this.setState({ onSearch: callable })} />}
            {this.state.value === 4 && <UserScreen onSearch={callable => this.setState({ onSearch: callable })} />}
            {this.state.value === 5 && <ProgressScreen onSearch={callable => this.setState({ onSearch: callable })} />}
            {this.state.value === 6 && <CommunicationsScreen onSearch={callable => this.setState({ onSearch: callable })} />}
            {this.state.value === 7 && <AnnouncementsScreen onSearch={callable => this.setState({ onSearch: callable })} />}

          </Grid>

          <Grid item xs={1}></Grid>
        </Grid>

      </div>
    );
  }
}

AdminScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminScreen));