import { GET_LANGUAGE, SET_LANGUAGE } from "../actions";

export const getLanguage = () => {
    return {
        type: GET_LANGUAGE
    }
};

export const setLanguage = (selectedLanguage) => {
    return {
        type: SET_LANGUAGE,
        selectedLanguage: selectedLanguage
    }
};

export const updateUserSetting = (personId, selectedLanguage) => {

    fetch("https://api.tasselpathway.com/user_settings/" + personId + "/" + selectedLanguage, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(res => res.json())
        .then(responseJson => {

        })
}