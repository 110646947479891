import {
  Grid,
  Paper, Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

class CompleteScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      schoolYear: 2020,
      dataSource: [],
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc'
    }

    this.handleChangePage = this.handleChangePage.bind(this);

  }

  updateDimensions() {
    let update_height = window.innerHeight - 300;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }


  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.getData();

    this.updateDimensions();
  }

  getData() {

    fetch('https://api.tasselpathway.com/profile_tasks/complete/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Grid container style={styles.itemContainerStyle}>

          <Paper square style={{ marginTop: 20, borderRadius: 0, width: '100%' }}>
            <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
              <Table className={classes.table} padding="dense">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head}></TableCell>
                    <TableCell className={classes.head}>{i18n.t("TASKS_LESSON_NAME")}</TableCell>
                    <TableCell className={classes.head}>{i18n.t("TASKS_INSTRUCTIONS")}</TableCell>
                    <TableCell className={classes.head}>{i18n.t("TASKS_ESTIMATED_TIME")}</TableCell>
                    <TableCell className={classes.head}>{i18n.t("TASKS_POINTS")}</TableCell>
                    <TableCell className={classes.head}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderBy(this.state.dataSource, this.state.sortBy, this.state.sortDirection)
                    .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      if (this.props.selectedLanguage === "esp") {
                        return (
                          <>
                            <TableRow style={index % 2 ? { cursor: 'pointer', background: "#fdffe0" } : { cursor: 'pointer', background: "white" }} hover key={index}>
                              <TableCell><Typography>{row.task_name_spanish}</Typography></TableCell>
                              <TableCell>{row.teacher_first_name + ' ' + row.teacher_last_name}</TableCell>
                              <TableCell>{row.estimated_time}</TableCell>
                              <TableCell>{row.total_points}</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                            <TableRow style={index % 2 ? { cursor: 'pointer', background: "#fdffe0" } : { cursor: 'pointer', background: "white" }}>
                              <TableCell colSpan="5"><b>Description: </b><div dangerouslySetInnerHTML={{ __html: row.task_description_spanish }} /></TableCell>
                            </TableRow>
                            <TableRow style={index % 2 ? { cursor: 'pointer', background: "#fdffe0" } : { cursor: 'pointer', background: "white" }}>
                              <TableCell colSpan="5" style={{ textAlign: 'left' }}><b>Answer: </b>{row.answer_text}</TableCell>
                            </TableRow>
                            <TableRow style={index % 2 ? { cursor: 'pointer', background: "#fdffe0" } : { cursor: 'pointer', background: "white" }}>
                              <TableCell colSpan="5" style={{ textAlign: 'left' }}><b>Response: </b>{row.educator_text}</TableCell>
                            </TableRow>
                          </>
                        );
                      } else {
                        return (

                          <>

                            <TableRow style={index % 2 ? { cursor: 'pointer', background: "#fdffe0" } : { cursor: 'pointer', background: "white" }} hover key={index}>
                              <TableCell style={{ width: 450 }}><Typography>{row.task_name}</Typography></TableCell>
                              <TableCell style={{ width: 150 }}>{row.teacher_first_name + ' ' + row.teacher_last_name}</TableCell>
                              <TableCell>{row.estimated_time}</TableCell>
                              <TableCell>{row.total_points}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                              </TableCell>
                            </TableRow>
                            <TableRow style={index % 2 ? { cursor: 'pointer', background: "#fdffe0" } : { cursor: 'pointer', background: "white" }}>
                              <TableCell colSpan="5"><b>Description: </b><div dangerouslySetInnerHTML={{ __html: row.task_description }} /></TableCell>
                            </TableRow>
                            <TableRow style={index % 2 ? { cursor: 'pointer', background: "#fdffe0" } : { cursor: 'pointer', background: "white" }}>
                              <TableCell colSpan="5" style={{ textAlign: 'left' }}><b>Answer: </b>{row.answer_text}</TableCell>
                            </TableRow>
                            <TableRow style={index % 2 ? { cursor: 'pointer', background: "#fdffe0" } : { cursor: 'pointer', background: "white" }}>
                              <TableCell colSpan="5" style={{ textAlign: 'left' }}><b>Response: </b>{row.educator_text}</TableCell>
                            </TableRow>
                          </>
                        );
                      }
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.currentPage}
              count={this.state.dataSource.length}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>

        </Grid>
      </div>
    );
  }
}

CompleteScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage,
    profileId: state.profile.profileId,
    schoolId: state.profile.schoolId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompleteScreen)));
