import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class AchievementScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      suggestions: [],
      newName: '',
      newAchievementType: '',
      newSponsor: '',
      newDescription: '',
      newNameValid: true,
      editNameValid: true,
      selectedItem: {},
      suggestionList: [],
      aws_key: '',
      aws_bucket: '',
      content_type: ''
    }

    this.createAchievement = this.createAchievement.bind(this);
    this.saveAchievement = this.saveAchievement.bind(this);
    this.onNewOpen = this.onNewOpen.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onNewClick = this.onNewClick.bind(this);
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
  }

  componentDidMount() {
    this.getData(this.props.profileId);

    fetch('https://api.tasselpathway.com/profile_suggest/1', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            suggestions: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(personId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_achievement/person/' + personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
        this.props.onAwardsLoaded(responseJson.results)
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  createAchievement() {
    fetch('https://api.tasselpathway.com/profile_achievement', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId,
        achievement_name: this.state.newName,
        achievement_description: this.state.newDescription,
        achievement_type: this.state.newAchievementType,
        sponsor_coach: this.state.newSponsor,
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          var obj = {
            achievement_id: responseJson.achievement_id,
            achievement_name: this.state.newName,
            achievement_role: this.state.newRole,
            achievement_description: this.state.newDescription,
            achievement_type: this.state.newAchievementType,
            sponsor_coach: this.state.newSponsor,
          }

          var items = this.state.dataSource;
          items.push(obj);

          this.setState({
            isLoading: false
          })

          this.getData(this.props.personId);
        }
      })
      .catch((error) => {

        this.setState({
          isLoading: false
        })
      });
  }

  saveAchievement() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_achievement', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        achievement_id: this.state.selectedItem.achievement_id,
        achievement_name: this.state.selectedItem.achievement_name,
        achievement_type: this.state.selectedItem.achievement_type,
        sponsor_coach: this.state.selectedItem.sponsor_coach,
        achievement_description: this.state.selectedItem.achievement_description
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  deleteAchievement() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_achievement', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        achievement_id: this.state.selectedItem.achievement_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.personId);

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderAchievements() {

    var content = this.state.dataSource.map((item, key) => {
      return (
        <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
          onClick={() => this.onEditOpen(item)}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                  className={this.props.classes.inline} color="textPrimary">
                  {item.achievement_name}
                </Typography>
                <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.achievement_type}</Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    })

    return content;
  }

  onNewOpen() {
    this.setState({
      newName: '',
      newDescription: '',
      newAchievementType: '',
      newSponsor: '',
      isNewOpen: true
    })
  }

  onNewClose() {

    this.setState({
      isNewOpen: false
    })
  }

  onNewClick() {

    var nameValid = true;

    if (this.state.newName === "") {
      nameValid = false;
    }

    this.setState({
      newNameValid: nameValid
    })

    if (nameValid) {
      this.createAchievement();

      this.setState({
        isNewOpen: false
      })
    }
  }

  onEditClose() {

    this.setState({
      isEditOpen: false
    })
  }

  onEditOpen(item) {
    if (this.props.personId === this.props.profileId) {

      this.setState({
        selectedItem: item,
        isEditOpen: true
      })
    }
  }

  onEditClick() {

    var nameValid = true;

    if (this.state.selectedItem.achievement_name === "") {
      nameValid = false;
    }

    this.setState({
      editNameValid: nameValid
    })

    if (nameValid) {
      this.saveAchievement();

      this.setState({
        isEditOpen: false
      })
    }
  }

  onDeleteClick() {
    this.deleteAchievement();

    this.setState({
      isEditOpen: false
    })
  }

  onTypeChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.achievement_type = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onSponsorChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.sponsor_coach = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDescriptionChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.achievement_description = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onNewNameChange(event) {

    this.setState({
      newName: event.target.value
    })

    fetch('https://api.tasselpathway.com/profile_suggest/1?search=' + event.target.value, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            suggestions: responseJson.results,
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onAutoNameChange(value) {

    var selectedItem = this.state.selectedItem;
    selectedItem.achievement_name = value;

    this.setState({
      selectedItem: selectedItem
    })

  }

  onNameChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.achievement_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })

    fetch('https://api.tasselpathway.com/profile_suggest/1?search=' + event.target.value, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            suggestions: responseJson.results,
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderInputComponent(inputProps) {
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;

    return (
      <TextField
        fullWidth
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    );
  }

  getSuggestionValue(suggestion) {
    return suggestion.suggest_name;
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestionList: this.getSuggestions(value)
    })
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestionList: []
    })
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };

  renderTranslatedAutoSuggest() {
    const { classes } = this.props
    if (this.props.selectedLanguage === 'esp') {
      return (
        <Autocomplete
          options={this.state.suggestions}
          classes={{
            option: classes.option
          }}
          freeSolo
          style={{ width: '100%' }}
          value={this.state.newName}
          error={!this.state.newNameValid}
          disableClearable
          onChange={(e) => this.setState({ newName: e.target.textContent })}
          getOptionLabel={option => this.state.newName}
          renderOption={option => (
            <React.Fragment>
              <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
            </React.Fragment>
          )}
          renderInput={params => (
            <TextField variant='standard'
              {...params} onChange={(event) => this.onNewNameChange(event)}
              placeholder="Ingrese premio u honor"
              error={!this.state.newNameValid}
              style={{
                paddingTop: 5, width: '100%', paddingRight: 5,
                backgroundColor: '#FFF', fontSize: 15
              }}
              margin="normal"

              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      )
    } else {
      return (
        <Autocomplete
          options={this.state.suggestions}
          classes={{
            option: classes.option
          }}
          freeSolo
          style={{ width: '100%' }}
          value={this.state.newName}
          error={!this.state.newNameValid}
          disableClearable
          onChange={(e) => this.setState({ newName: e.target.textContent })}
          getOptionLabel={option => this.state.newName}
          renderOption={option => (
            <React.Fragment>
              <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
            </React.Fragment>
          )}
          renderInput={params => (
            <TextField variant='standard'
              {...params} onChange={(event) => this.onNewNameChange(event)}
              placeholder="Enter Your Award or Honor"
              error={!this.state.newNameValid}
              style={{
                paddingTop: 5, width: '100%', paddingRight: 5,
                backgroundColor: '#FFF', fontSize: 15
              }}
              margin="normal"

              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      )
    }
  }
  renderEditModal() {
    const { classes } = this.props;
    if (this.props.selectedLanguage === "esp") {
      return (
        <Autocomplete
          options={this.state.suggestions}
          classes={{
            option: classes.option
          }}
          freeSolo
          style={{ width: '100%' }}
          value={this.state.selectedItem.achievement_name}
          disableClearable
          error={!this.state.editNameValid}
          onChange={(e) => this.onAutoNameChange(e.target.textContent)}
          getOptionLabel={option => this.state.selectedItem.achievement_name}
          renderOption={option => (
            <React.Fragment>
              <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
            </React.Fragment>
          )}
          renderInput={params => (
            <TextField variant='standard'
              {...params} onChange={(event) => this.onNameChange(event)}
              placeholder="Ingrese premio u honor"
              error={!this.state.editNameValid}
              style={{
                paddingTop: 5, width: '100%', paddingRight: 5,
                backgroundColor: '#FFF', fontSize: 15
              }}
              margin="normal"

              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      )
    } else {
      return (
        <Autocomplete
          options={this.state.suggestions}
          classes={{
            option: classes.option
          }}
          freeSolo
          style={{ width: '100%' }}
          value={this.state.selectedItem.achievement_name}
          disableClearable
          error={!this.state.editNameValid}
          onChange={(e) => this.onAutoNameChange(e.target.textContent)}
          getOptionLabel={option => this.state.selectedItem.achievement_name}
          renderOption={option => (
            <React.Fragment>
              <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
            </React.Fragment>
          )}
          renderInput={params => (
            <TextField variant='standard'
              {...params} onChange={(event) => this.onNameChange(event)}
              placeholder="Enter your honor or award"
              error={!this.state.editNameValid}
              style={{
                paddingTop: 5, width: '100%', paddingRight: 5,
                backgroundColor: '#FFF', fontSize: 15
              }}
              margin="normal"

              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      )
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { classes } = this.props;

    return (
      <div>

        <div className={classes.root}>

          <Dialog open={this.state.isNewOpen}
            onClose={this.onNewClose} maxWidth="lg"
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
              {i18n.t("HONORS_AND_AWARDS_NEW")}
            </DialogTitle>

            <DialogContent>

              <div>

                {this.renderTranslatedAutoSuggest()}

                <FormControl fullWidth>
                  <InputLabel htmlFor="type">{i18n.t("HONORS_AND_AWARDS_AWARD_TYPE")}</InputLabel>
                  <Input fullWidth id="type" value={this.state.newAchievementType}
                    onChange={(event) => this.setState({ newAchievementType: event.target.value })}
                    aria-describedby="my-helper-text" />
                </FormControl>

                <br />

                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="sponsor">{i18n.t("HONORS_AND_AWARDS_SPONSOR")}</InputLabel>
                  <Input fullWidth id="sponsor" value={this.state.newSponsor}
                    onChange={(event) => this.setState({ newSponsor: event.target.value })}
                    aria-describedby="my-helper-text" />
                </FormControl>

                <br />

                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="description">{i18n.t("HONORS_AND_AWARDS_COMMENT")}</InputLabel>
                  <Input variant="outline" multiline={true} rows={4} fullWidth
                    value={this.state.newDescription}
                    onChange={(event) => this.setState({ newDescription: event.target.value })}
                    id="description" aria-describedby="my-helper-text" />
                </FormControl>

                <br />

              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.onNewClick}
                style={{ color: '#FFF', fontWeight: 'bold', marginBottom: 20, marginRight: 20 }}
                color="primary">
                {i18n.t("HONORS_AND_AWARDS_ADD_BUTTON")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isEditOpen} onClose={this.onEditClose} maxWidth="lg"
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title"
              onClose={this.onEditClose}>
              {i18n.t("HONORS_AND_AWARDS_EDIT_TITLE")}
            </DialogTitle>

            <DialogContent>

              <div>

                <Autocomplete
                  options={this.state.suggestions}
                  classes={{
                    option: classes.option
                  }}
                  freeSolo
                  style={{ width: '100%' }}
                  value={this.state.selectedItem.achievement_name}
                  disableClearable
                  error={!this.state.editNameValid}
                  onChange={(e) => this.onAutoNameChange(e.target.textContent)}
                  getOptionLabel={option => this.state.selectedItem.achievement_name}
                  renderOption={option => (
                    <React.Fragment>
                      <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField variant='standard'
                      {...params} onChange={(event) => this.onNameChange(event)}
                      placeholder="Enter Your Award or Honor"
                      error={!this.state.editNameValid}
                      style={{
                        paddingTop: 5, width: '100%', paddingRight: 5,
                        backgroundColor: '#FFF', fontSize: 15
                      }}
                      margin="normal"

                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />

                <FormControl fullWidth>
                  <InputLabel htmlFor="type">{i18n.t("HONORS_AND_AWARDS_AWARD_TYPE")}</InputLabel>
                  <Input fullWidth id="type" value={this.state.selectedItem.achievement_type}
                    onChange={(event) => this.onTypeChange(event)}
                    aria-describedby="my-helper-text" />
                </FormControl>

                <br />

                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="sponsor">{i18n.t("HONORS_AND_AWARDS_SPONSOR")}}</InputLabel>
                  <Input fullWidth id="sponsor" value={this.state.selectedItem.sponsor_coach}
                    onChange={(event) => this.onSponsorChange(event)}
                    aria-describedby="my-helper-text" />
                </FormControl>

                <br />

                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="description">{i18n.t("HONORS_AND_AWARDS_COMMENT")}</InputLabel>
                  <Input variant="outline" multiline={true} rows={4} fullWidth
                    value={this.state.selectedItem.achievement_description}
                    onChange={(event) => this.onDescriptionChange(event)}
                    id="description" aria-describedby="my-helper-text" />
                </FormControl>

                <br />

              </div>

            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.onDeleteClick}
                style={{ color: '#FFF', fontWeight: 'bold' }} color="secondary">
                {i18n.t("HONORS_AND_AWARDS_DELETE")}
              </Button>
              <Button variant="contained" onClick={this.onEditClick}
                style={{ marginRight: 20, color: '#FFF', fontWeight: 'bold' }} color="primary">
                {i18n.t("HONORS_AND_AWARDS_SAVE")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={10}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                    {i18n.t("PROFILE_HONORS_AND_AWARDS_TILE")}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.renderAchievements()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

AchievementScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    profileId: state.profile.profileId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AchievementScreen)));