import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, Snackbar, SnackbarContent, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './style';

class ProgressScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showSaveMessage: false,
      hasPathwayRequired: false,
      interestInventoryRequired: false,
      learningStyleRequired: false,
      collegesRequired: false,
      careersRequired: false,
      skillsRequired: false,
      goalsRequired: false,
      clubsRequired: false,
      hasResumeRequired: false,
      assessmentsRequired: false,
      workBasedLearningRequired: false,
      saveMessage: ''
    }

    this.onCloseMessage = this.onCloseMessage.bind(this);
  }


  componentDidMount() {
    this.getData()
  }

  getData() {

    fetch('https://api.tasselpathway.com/ilp', {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": 'application/json',
        "token": this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.setState({
            hasPathwayRequired: responseJson.results[0].has_pathway_required,
            interestInventoryRequired: responseJson.results[0].interest_inventory_required,
            learningStyleRequired: responseJson.results[0].learning_style_required,
            collegesRequired: responseJson.results[0].colleges_required,
            careersRequired: responseJson.results[0].careers_required,
            skillsRequired: responseJson.results[0].skills_required,
            goalsRequired: responseJson.results[0].goals_required,
            clubsRequired: responseJson.results[0].clubs_required,
            honorsRequired: responseJson.results[0].honors_required,
            hasResumeRequired: responseJson.results[0].has_resume_required,
            assessmentsRequired: responseJson.results[0].assessments_required,
            workBasedLearningRequired: responseJson.results[0].work_based_learning_required
          })
        }
      })

  }

  saveProgress() {

    fetch('https://api.tasselpathway.com/ilp', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        has_pathway_required: this.state.hasPathwayRequired,
        interest_inventory_required: this.state.interestInventoryRequired,
        learning_style_required: this.state.learningStyleRequired,
        colleges_required: this.state.collegesRequired,
        careers_required: this.state.careersRequired,
        skills_required: this.state.skillsRequired,
        goals_required: this.state.goalsRequired,
        clubs_required: this.state.clubsRequired,
        honors_required: this.state.honorsRequired,
        has_resume_required: this.state.hasResumeRequired,
        assessments_required: this.state.assessmentsRequired,
        work_based_learning_required: this.state.workBasedLearningRequired

      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            showSaveMessage: true,
            saveMessage: 'ILP Progress Saved Successfully!',
          })
        }

        this.getData();
      })
  }

  onCloseMessage() {
    this.setState({
      showSaveMessage: false
    })
  }

  render() {

    const { classes } = this.props;
    return (

      <div className={classes.root}>

        <Grid container>
          <Grid item xs={2}>

          </Grid>
          <Grid item xs={6}>

          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 3 }}
              onClick={() => this.saveProgress()}>
              <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>Save Changes</Typography>
            </Button>
          </Grid>
        </Grid>

        <Snackbar
          classes={{
            root: classes.message
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          autoHideDuration={5000}
          onClose={this.onCloseMessage}
          open={this.state.showSaveMessage}>
          <SnackbarContent
            message={
              <span style={styles.message}>
                {this.state.saveMessage}
              </span>
            }
            classes={{
              root: classes.error
            }}
            action={
              <IconButton key="close" onClick={this.onCloseMessage} className={classes.content}>
                <CloseIcon style={{ color: '#FFF' }} />
              </IconButton>
            }>
          </SnackbarContent>
        </Snackbar>


        <Paper style={{ marginTop: 15, height: '100%', }}>
          <Grid container style={{ padding: 30 }}>
            <Grid item xs={4}>

              <Grid container>

                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ marginBottom: 10 }}
                    control={<Checkbox checked={this.state.hasPathwayRequired} color="primary"
                      onChange={() => this.setState({ hasPathwayRequired: !this.state.hasPathwayRequired })}
                      value="hasPathwayRequired" />}
                    label="Pathway Required"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ marginBottom: 10 }}
                    control={<Checkbox checked={this.state.interestInventoryRequired} color="primary"
                      onChange={() => this.setState({ interestInventoryRequired: !this.state.interestInventoryRequired })}
                      value="interestInventoryRequired" />}
                    label="Interest Inventory Required"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ marginBottom: 10 }}
                    control={<Checkbox checked={this.state.learningStyleRequired} color="primary"
                      onChange={() => this.setState({ learningStyleRequired: !this.state.learningStyleRequired })}
                      value="learningStyleRequired" />}
                    label="Learning Style Required"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ marginBottom: 10 }}
                    control={<Checkbox checked={this.state.collegesRequired} color="primary"
                      onChange={() => this.setState({ collegesRequired: !this.state.collegesRequired })}
                      value="collegesRequired" />}
                    label="Colleges Required"
                  />
                </Grid>



              </Grid>

            </Grid>

            <Grid item xs={4}>

              <Grid container>

                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ marginBottom: 10 }}
                    control={<Checkbox checked={this.state.careersRequired} color="primary"
                      onChange={() => this.setState({ careersRequired: !this.state.careersRequired })}
                      value="careersRequired" />}
                    label="Careers Required"
                  />
                </Grid>

                <Grid item xs={12}>

                  <FormControlLabel
                    control={<Checkbox checked={this.state.skillsRequired} color="primary"
                      onChange={() => this.setState({ skillsRequired: !this.state.skillsRequired })}
                      value="skillsRequired" />}
                    label="Skills Required"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.goalsRequired} color="primary"
                      onChange={() => this.setState({ goalsRequired: !this.state.goalsRequired })}
                      value="goalsRequired" />}
                    label="Goals Required"
                  />
                </Grid>

                <Grid item xs={12}>

                  <FormControlLabel
                    control={<Checkbox checked={this.state.clubsRequired} color="primary"
                      onChange={() => this.setState({ clubsRequired: !this.state.clubsRequired })}
                      value="clubsRequired" />}
                    label="Clubs Required"
                  />

                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={4}>

              <Grid container>

                <Grid item xs={12}>

                  <FormControlLabel
                    control={<Checkbox checked={this.state.honorsRequired} color="primary"
                      onChange={() => this.setState({ honorsRequired: !this.state.honorsRequired })}
                      value="honorsRequired" />}
                    label="Honors Required"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={this.state.hasResumeRequired} color="primary"
                      onChange={() => this.setState({ hasResumeRequired: !this.state.hasResumeRequired })}
                      value="hasResumeRequired" />}
                    label="Resume Required"
                  />

                </Grid>

                <Grid item xs={12}>

                  <FormControlLabel
                    control={<Checkbox checked={this.state.assessmentsRequired} color="primary"
                      onChange={() => this.setState({ assessmentsRequired: !this.state.assessmentsRequired })}
                      value="assessmentsRequired" />}
                    label="Assessments Required"
                  />

                </Grid>

                <Grid item xs={12}>

                  <FormControlLabel
                    control={<Checkbox checked={this.state.workBasedLearningRequired} color="primary"
                      onChange={() => this.setState({ workBasedLearningRequired: !this.state.workBasedLearningRequired })}
                      value="workBasedLearningRequired" />}
                    label="Work Based Learning Required"
                  />

                </Grid>

              </Grid>
            </Grid>

          </Grid>

        </Paper>

      </div>
    )
  }
}

ProgressScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    personId: state.auth.personId,
    token: state.auth.token,
    districtId: state.profile.districtId,
    schoolId: state.profile.schoolId,
  }
}
const mapDispatchToProps = (dispatch) => {

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProgressScreen));