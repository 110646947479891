import {
    Button,
    Typography
} from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Stepper from 'react-stepper-horizontal';
import { clearResumeData } from '../../../../redux/actions/resume_builder';


class DownloadScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skillsDataSource: [],
            workExperienceDataSource: [],
            achievementsDataSource: [],
            workBasedLearningDataSource: [],
            serviceLearningDataSource: [],
            resumeRecord: {}

        }
    }
    componentDidMount() {
        this.getData()
    }

    getData = () => {
        if (this.props.skillsSelected) {
            fetch('https://api.tasselpathway.com/profile_skill/person/' + this.props.personId, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then(resJson => {
                    this.setState({
                        skillsDataSource: resJson.results
                    })
                })
        }
        if (this.props.workExperienceSelected) {
            fetch('https://api.tasselpathway.com/work_experience/' + this.props.personId, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then(resJson => {
                    this.setState({
                        workExperienceDataSource: resJson.results
                    })
                })
        }
        if (this.props.honorsSelected) {
            fetch('https://api.tasselpathway.com/profile_achievement/person/' + this.props.personId, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then(resJson => {
                    this.setState({
                        achievementsDataSource: resJson.results
                    })
                })
        }
        if (this.props.workSelected) {
            fetch('https://api.tasselpathway.com/profile_work_learning/person/' + this.props.personId, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then(resJson => {
                    this.setState({
                        workBasedLearningDataSource: resJson.results
                    })
                })
        }
        if (this.props.serviceSelected) {
            fetch('https://api.tasselpathway.com/profile_service/person/' + this.props.personId, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then(resJson => {
                    this.setState({
                        serviceLearningDataSource: resJson.results
                    })
                })
        }
        if (this.props.isEditing) {

            fetch('https://api.tasselpathway.com/get_resume/' + this.props.personId, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then(responseJson => {
                    if (responseJson.error === false && responseJson.rows.length > 0) {
                        this.setState({
                            resumeRecord: responseJson.rows[0]
                        })
                    }
                })
        }
    }

    onReturnToProfile = () => {

        this.props.history.push('/profile/' + this.props.personId);

    }

    getResumeData = () => {
        let state = this.state
        let fullName = `${this.props.firstName} ${this.props.lastName}`

        let obj = {};
        obj.personId = this.props.personId
        obj.studentName = fullName
        obj.schoolName = this.props.schoolName
        obj.objectiveText = this.props.objectiveText
        obj.objective = this.props.objectiveSelected
        obj.skills = this.props.skillsSelected
        obj.work_experience = this.props.workExperienceSelected
        obj.honors_and_awards = this.props.honorsSelected
        obj.work_based_learning = this.props.workSelected
        obj.service_learning = this.props.serviceSelected
        obj.template = this.props.resumeTemplate
        obj.date_created = new Date()
        obj.template = this.props.selectedResumeTemplate
        for (var property in state) {
            obj[property] = state[property]
        }
        return obj
    }

    createResumeAndReturnToProfile = () => {

        let req = this.getResumeData();

        if (req) {

            fetch("https://api.tasselpathway.com/resume", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-type": "application/json",
                    'token': this.props.token
                },
                credentials: "omit",
                body: JSON.stringify(req)
            })
                .then(res => res.json())
                .then(resJson => {

                    if (resJson.error === false) {
                        this.props.onClearResumeData()
                        this.onReturnToProfile()
                    }
                })
        }
    }

    updateResume = () => {

        this.removeResumeData(this.state.resumeRecord)
        let req = this.getResumeData()

        req.resume_id = this.props.resume_id

        if (req) {

            fetch("https://api.tasselpathway.com/resume/resume_update", {
                method: "PATCH",
                headers: {
                    "Accept": "application/json",
                    "Content-type": "application/json",
                    'token': this.props.token
                },
                body: JSON.stringify(req)
            })
                .then(res => res.json())
                .then(resJson => {
                    if (resJson.error === false) {
                        this.props.onClearResumeData()
                        this.onReturnToProfile()
                    }
                })
        }
    }

    removeResumeData = (resumeRecord) => {

        if (resumeRecord.objective) {

            fetch("https://api.tasselpathway.com/resume/resume_objective/delete/" + this.props.resume_id, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'token': this.props.token
                },
                credentials: "omit"
            })
                .then(res => res.json())
                .then(responseJson => {
                })
        }
        if (resumeRecord.skills) {

            fetch("https://api.tasselpathway.com/resume/resume_skills/delete/" + this.props.resume_id, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'token': this.props.token
                },
                credentials: "omit"
            })
                .then(res => res.json())
                .then(responseJson => {

                })
        }
        if (resumeRecord.work_experience) {

            fetch("https://api.tasselpathway.com/resume/resume_work_experience/delete/" + this.props.resume_id, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'token': this.props.token
                },
                credentials: "omit"
            })
                .then(res => res.json())
                .then(responseJson => {
                    console.log(responseJson)

                })
        }
        if (resumeRecord.honors_and_awards) {

            fetch("https://api.tasselpathway.com/resume/resume_honors_and_awards/delete/" + this.props.resume_id, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'token': this.props.token
                },
                credentials: "omit"
            })
                .then(res => res.json())
                .then(responseJson => {
                    console.log(responseJson)

                })
        }
        if (resumeRecord.service_learning) {

            fetch("https://api.tasselpathway.com/resume/resume_service_learning/delete/" + this.props.resume_id, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'token': this.props.token
                },
                credentials: "omit"
            })
                .then(res => res.json())
                .then(responseJson => {
                    console.log(responseJson)

                })
        }
        if (resumeRecord.work_based_learning) {

            fetch("https://api.tasselpathway.com/resume/resume_work_based_learning/delete/" + this.props.resume_id, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'token': this.props.token
                },
                credentials: "omit"
            })
                .then(res => res.json())
                .then(responseJson => {
                    console.log(responseJson)

                })
        }
    }

    render() {
        let steps = []
        this.props.resumeSections.forEach(item => {
            let obj = {
                title: item.title
            }
            steps.push(obj)
        })
        steps.push({ title: 'Download' })

        return (
            <div>
                <Stepper
                    steps={steps}
                    activeColor={"#203446"}
                    completeColor={"#203446"}
                    activeStep={steps.findIndex((obj) => obj.title === "Download")}
                />

                <div style={{ marginTop: '7%' }}>
                    <Typography style={{ textAlign: 'center', fontSize: 50, marginBottom: 25 }}>
                        You're all set!
                    </Typography>

                    <Typography style={{ textAlign: 'center' }}>
                        You'll find your new resume in the resume tile on your profile. Click done to go view it!
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%', margin: 'auto', marginTop: '5%' }}>
                        {this.props.isEditing ?
                            <Button style={{ backgroundColor: '#395976', color: '#fff' }} onClick={this.updateResume}>
                                <Typography>Done</Typography>
                            </Button>
                            :
                            <Button style={{ backgroundColor: '#395976', color: '#fff' }} onClick={this.createResumeAndReturnToProfile}>
                                <Typography>Done</Typography>
                            </Button>
                        }
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        resumeTemplate: state.resume_builder.resumeTemplate,
        firstName: state.auth.firstName,
        lastName: state.auth.lastName,
        objectiveText: state.resume_builder.objectiveText,
        skills: state.resume_builder.skills,
        workBasedLearning: state.resume_builder.workBasedLearning,
        serviceLearning: state.resume_builder.serviceLearning,
        honorsAndAwards: state.resume_builder.honorsAndAwards,
        workExperience: state.resume_builder.workExperience,
        token: state.auth.token,
        schoolName: state.profile.schoolName,
        selectedResumeTemplate: state.resume_builder.resumeTemplate,
        personId: state.auth.personId,
        resumeSections: state.resume_builder.resumeSections,
        objectiveSelected: state.resume_builder.objectiveSelected,
        skillsSelected: state.resume_builder.skillsSelected,
        workExperienceSelected: state.resume_builder.workExperienceSelected,
        honorsSelected: state.resume_builder.honorsSelected,
        workSelected: state.resume_builder.workSelected,
        serviceSelected: state.resume_builder.serviceSelected,
        resume_id: state.resume_builder.resume_id,
        isEditing: state.resume_builder.isEditing
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClearResumeData: () => { dispatch(clearResumeData()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DownloadScreen))
