
 import { GET_TASK, SET_TASK  } from "../actions";

 export const defaultState = {
     taskId:0,
     taskName: '',
     taskDescription: '',
     estimatedTime: '',
     totalPoints: 0,
     autoGrade: 0,
     answerText: '',
     taskUpdated: 0
 };
 
 export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case GET_TASK:
             return Object.assign({}, state, {
             });
         case SET_TASK:
             return Object.assign({}, state, {
                taskId: action.taskId,
                taskName: action.taskName,
                taskDescription: action.taskDescription, 
                estimatedTime: action.estimatedTime, 
                totalPoints: action.totalPoints,
                autoGrade: action.autoGrade,
                answerText: action.answerText,
                taskUpdated: action.taskUpdated
             });
         default:
             return state
     }
 }