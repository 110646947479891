import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Checkbox,
  Dialog, DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';
const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class CourseScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isEditOpen: false,
      isNewOpen: false,
      selectedSchool: 0,
      schools: [],
      selectedItem: {},
      subjects: [],
      dataSource: [],
      filteredDataSource: [],
      height: 400,
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc',
      pathwayDistricts: [],
      newSubjectId: 0,
      newCourseCode: '',
      newCourseName: '',
      newCreditHour: 0,
      newDualCreditHour: 0,
      newIsAP: '',
      newIsCTE: '',
      pathway_id: 0,
      isPathwayOpen: 0
    }

    this.onNewClose = this.onNewClose.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  updateDimensions() {

    let update_height = 0;

    if (this.props.announcementAlert !== '' && this.props.announcementAlert !== undefined) {
      update_height = window.innerHeight - 270;
    } else {
      update_height = window.innerHeight - 270;
    }

    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.setState({
      isLoading: true
    })

    this.getData();
    this.getSchool(this.state.selectedSchool);
    this.updateDimensions();
  }

  getData() {

    fetch('https://api.tasselpathway.com/diploma_subject', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            subjects: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/pathway_district', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            pathwayDistricts: responseJson.results
          })
        }
      })

    fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          schools: responseJson.results
        });
      })
      .catch((error) => {

      });
  }

  getSchool(schoolId) {

    if (schoolId !== 0 && schoolId !== undefined) {

      fetch('https://api.tasselpathway.com/diploma_course/' + schoolId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              filteredDataSource: responseJson.results
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });
    }
  }

  onAddCourse() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/diploma_course/', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        school_id: this.state.selectedSchool,
        subject_id: this.state.newSubjectId,
        course_code: this.state.newCourseCode,
        course_name: this.state.newCourseName,
        credit_hour: this.state.newCreditHour,
        dual_credit_hour: this.state.newDualCreditHour,
        is_ap: this.state.newIsAP,
        is_cte: this.state.newIsCTE,
        pathway_id: this.state.pathway_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.getSchool(this.state.selectedSchool);

        this.setState({
          isLoading: false,
          isNewOpen: false,
          isPathwayOpen: 0
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isNewOpen: false
        })
      });
  }

  onSaveCourse() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/diploma_course/', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        school_id: this.state.selectedSchool,
        course_id: this.state.selectedItem.course_id,
        subject_id: this.state.selectedItem.subject_id,
        course_code: this.state.selectedItem.course_code,
        course_name: this.state.selectedItem.course_name,
        credit_hour: this.state.selectedItem.credit_hour,
        dual_credit_hour: this.state.selectedItem.dual_credit_hour,
        is_ap: this.state.selectedItem.is_ap,
        is_cte: this.state.selectedItem.is_cte,
        pathway_id: this.state.selectedItem.pathway_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          isEditOpen: false
        })

        this.getSchool(this.state.selectedSchool);

      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isEditOpen: false
        })
      });
  }

  onNewClick() {
    this.setState({
      isNewOpen: true
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onEditClick(event, item) {
    this.setState({
      isEditOpen: true,
      selectedItem: item
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  onRowClick(event, item) {

    this.setState({
      selectedCourse: item,
      isEditOpen: false
    })
  }

  onCourseCodeChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.course_code = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onCourseNameChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.course_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onSubjectChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.subject_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onCteNew(event) {
    if (event.target.checked) {
      this.setState({
        newIsCTE: 1,
        isPathwayOpen: 1
      })
    } else {
      this.setState({
        newIsCTE: 0,
        isPathwayOpen: 0
      })
    }
  }

  onApNew(event) {
    if (event.target.checked) {
      this.setState({
        newIsAP: 1
      })
    } else {
      this.setState({
        newIsAP: 0
      })
    }
  }

  onPathwayChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.pathway_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onCreditHourChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.credit_hour = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDualCreditHourChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.dual_credit_hour = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onAPChange(event) {

    var selectedItem = this.state.selectedItem;

    if (event.target.checked) {
      selectedItem.is_ap = 1;
    } else {
      selectedItem.is_ap = 0;
    }

    this.setState({
      selectedItem: selectedItem
    })
  }

  onCTEChange(event) {

    var selectedItem = this.state.selectedItem;

    if (event.target.checked) {
      selectedItem.is_cte = 1;
    } else {
      selectedItem.is_cte = 0;
    }

    this.setState({
      selectedItem: selectedItem
    })
  }

  onSchoolChange(event) {
    this.getSchool(event.target.value);
    this.setState({ selectedSchool: event.target.value })
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.filteredDataSource.length - this.state.currentPage * this.state.rowsPerPage);

    return (
      <div className={classes.root}>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            {i18n.t("ADMIN_COURSES_NEW_COURSE_BUTTON")}
          </DialogTitle>

          <DialogContent>

            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="newSchoolId">{i18n.t("ADMIN_COURSES_SCHOOL_NAME")}</InputLabel>

              <Select style={{ fontSize: 16, marginTop: 25 }}
                value={this.state.selectedSchool} disabled
                placeholder="Select School"
                inputProps={{
                  name: 'newSchoolId',
                  id: 'newSchoolId',
                }}>

                <MenuItem disabled value={0}>{i18n.t("ADMIN_COURSES_SELECT_SCHOOL")}</MenuItem>

                {this.state.schools.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
                  )
                })
                }
              </Select>
            </FormControl>

            <FormControl className={classes.formControl} fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="newSubjectId">{i18n.t("ADMIN_COURSES_TABLE_SUBJECT_NAME")}</InputLabel>
              <Select
                value={this.state.newSubjectId}
                onChange={(event) => this.setState({ newSubjectId: event.target.value })}
                inputProps={{
                  name: 'newSubjectId',
                  id: 'newSubjectId',
                }}>
                <MenuItem disabled value={0}>{i18n.t("ADMIN_COURSE_SELECT_SUBJECT")}</MenuItem>

                {this.state.subjects.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.subject_id}>{item.subject_name}</MenuItem>
                  )
                })}

              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="courseCode">{i18n.t("ADMIN_COURSES_TABLE_COURSE_CODE")}</InputLabel>
              <Input variant="outline" fullWidth
                onChange={(event) => this.setState({ newCourseCode: event.target.value })}
                id="courseCode" aria-describedby="my-helper-text" />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="courseName">{i18n.t("ADMIN_COURSES_TABLE_COURSE_NAME")}</InputLabel>
              <Input variant="outline" fullWidth
                onChange={(event) => this.setState({ newCourseName: event.target.value })}
                id="courseName" aria-describedby="my-helper-text" />
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="creditHour">{i18n.t("ADMIN_COURSES_TABLE_CREDIT_HOURS")}</InputLabel>
                  <Input variant="outline" fullWidth
                    onChange={(event) => this.setState({ newCreditHour: event.target.value })}
                    id="creditHour" aria-describedby="my-helper-text" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="dualCreditHour">{i18n.t("ADMIN_COURSES_TABLE_DUAL_CREDIT_HOURS")}</InputLabel>
                  <Input variant="outline" fullWidth
                    onChange={(event) => this.setState({ newDualCreditHour: event.target.value })}
                    id="dualCreditHour" aria-describedby="my-helper-text" />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={5}>
                {this.props.selectedLanguage === "esp" ?
                  <>
                    <FormControlLabel style={{ marginTop: 10 }}
                      control={
                        <Checkbox
                          onChange={(event) => this.onApNew(event)}
                          value={this.state.newIsAP}
                          color="primary"
                        />
                      }
                      label="AP Course"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => this.onCteNew(event)}
                          value={this.state.newIsCTE}
                          color="primary"
                        />
                      }
                      label="CTE Course"
                    />
                  </>
                  :
                  <>
                    <FormControlLabel style={{ marginTop: 10 }}
                      control={
                        <Checkbox
                          onChange={(event) => this.onApNew(event)}
                          value={this.state.newIsAP}
                          color="primary"
                        />
                      }
                      label="AP Course"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => this.onCteNew(event)}
                          value={this.state.newIsCTE}
                          color="primary"
                        />
                      }
                      label="CTE Course"
                    />
                  </>
                }

                {this.state.isPathwayOpen ?
                  <FormControl className={classes.formControl} fullWidth style={{ marginTop: 10 }}>
                    <InputLabel htmlFor="editSubjectId">Pathway</InputLabel>
                    <Select
                      value={this.state.pathway_id}
                      onChange={(event) => this.setState({ pathway_id: event.target.value })}
                      inputProps={{
                        name: 'editSubjectId',
                        id: 'editSubjectId',
                      }}>
                      <MenuItem value={0}>No Pathway</MenuItem>

                      {this.state.pathwayDistricts.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.pathway_id}>{item.pathway_name}</MenuItem>
                        )
                      })}

                    </Select>
                  </FormControl>
                  : null}

              </Grid>
              <Grid item xs={7}>

              </Grid>
            </Grid>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onAddCourse()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 0, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_ADD_BUTTON")}
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            {i18n.t("ADMIN_EDIT_COURSE")}
          </DialogTitle>

          <DialogContent>

            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="editSchoolId">{i18n.t("ADMIN_COURSE_SCHOOL_NAME")}</InputLabel>

              <Select style={{ fontSize: 16, marginTop: 25 }}
                value={this.state.selectedSchool} disabled
                placeholder="Select School"
                inputProps={{
                  name: 'editSchoolId',
                  id: 'editSchoolId',
                }}>

                <MenuItem disabled value={0}>{i18n.t("ADMIN_COURSE_SELECT_SCHOOL")}</MenuItem>

                {this.state.schools.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
                  )
                })
                }
              </Select>
            </FormControl>

            <FormControl className={classes.formControl} fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="editSubjectId">{i18n.t("ADMIN_COURSES_TABLE_SUBJECT_NAME")}</InputLabel>
              <Select
                value={this.state.selectedItem.subject_id}
                onChange={(event) => this.onSubjectChange(event)}
                inputProps={{
                  name: 'editSubjectId',
                  id: 'editSubjectId',
                }}>
                <MenuItem disabled value={0}>{i18n.t("ADMIN_COURSE_SELECT_SUBJECT")}</MenuItem>

                {this.state.subjects.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.subject_id}>{item.subject_name}</MenuItem>
                  )
                })}

              </Select>
            </FormControl>

            {this.state.selectedItem.is_cte === 1 ?

              <FormControl className={classes.formControl} fullWidth style={{ marginTop: 10 }}>
                <InputLabel htmlFor="editSubjectId">Pathway</InputLabel>
                <Select
                  value={this.state.selectedItem.pathway_id}
                  onChange={(event) => this.onPathwayChange(event)}
                  inputProps={{
                    name: 'editSubjectId',
                    id: 'editSubjectId',
                  }}>
                  <MenuItem value={0}>No Pathway</MenuItem>

                  {this.state.pathwayDistricts.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.pathway_id}>{item.pathway_name}</MenuItem>
                    )
                  })}

                </Select>
              </FormControl>
              : null}

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="courseCode">{i18n.t("ADMIN_COURSES_TABLE_COURSE_CODE")}</InputLabel>
              <Input variant="outline" fullWidth
                value={this.state.selectedItem.course_code}
                onChange={(event) => this.onCourseCodeChange(event)}
                id="courseCode" aria-describedby="my-helper-text" />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel htmlFor="courseName">{i18n.t("ADMIN_COURSES_TABLE_COURSE_NAME")}</InputLabel>
              <Input variant="outline" fullWidth
                value={this.state.selectedItem.course_name}
                onChange={(event) => this.onCourseNameChange(event)}
                id="courseName" aria-describedby="my-helper-text" />
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="creditHour">{i18n.t("ADMIN_COURSES_TABLE_CREDIT_HOURS")}</InputLabel>
                  <Input variant="outline" fullWidth
                    value={this.state.selectedItem.credit_hour}
                    onChange={(event) => this.onCreditHourChange(event)}
                    id="creditHour" aria-describedby="my-helper-text" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="dualCreditHour">{i18n.t("ADMIN_COURSES_TABLE_DUAL_CREDIT_HOURS")}</InputLabel>
                  <Input variant="outline" fullWidth
                    value={this.state.selectedItem.dual_credit_hour}
                    onChange={(event) => this.onDualCreditHourChange(event)}
                    id="dualCreditHour" aria-describedby="my-helper-text" />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={5}>
                {this.props.selectedLanguage === "esp" ?
                  <>
                    <FormControlLabel style={{ marginTop: 10 }} onChange={(event) => this.onAPChange(event)}
                      control={
                        <Checkbox
                          checked={this.state.selectedItem.is_ap}
                          color="primary"
                        />
                      }
                      label="Curso AP"
                    />

                    <FormControlLabel onChange={(event) => this.onCTEChange(event)}
                      control={
                        <Checkbox
                          checked={this.state.selectedItem.is_cte}

                          color="primary"
                        />
                      }
                      label="Curso CTE"
                    />
                  </>
                  :
                  <>
                    <FormControlLabel style={{ marginTop: 10 }} onChange={(event) => this.onAPChange(event)}
                      control={
                        <Checkbox
                          checked={this.state.selectedItem.is_ap}
                          color="primary"
                        />
                      }
                      label="AP Course"
                    />

                    <FormControlLabel onChange={(event) => this.onCTEChange(event)}
                      control={
                        <Checkbox
                          checked={this.state.selectedItem.is_cte}

                          color="primary"
                        />
                      }
                      label="CTE Course"
                    />
                  </>
                }
              </Grid>
            </Grid>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onSaveCourse()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 0, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_COURSE_SAVE")}
            </Button>
          </DialogActions>

        </Dialog>

        <Grid container>
          <Grid item xs={2}>
            {this.state.selectedSchool !== 0 ?
              <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 0 }}
                onClick={() => this.onNewClick()}>
                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>{i18n.t("ADMIN_COURSES_NEW_COURSE_BUTTON")}</Typography>
              </Button> : null}
          </Grid>
          <Grid item xs={2}>

          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            {this.props.selectedLanguage === "esp" ?
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={this.onSearch} style={{ width: '100%' }}
                  placeholder="Buscar"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              :
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={this.onSearch} style={{ width: '100%' }}
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            }
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            {this.props.seleteLanguage === "esp" ?
              <Select style={{ fontSize: 16, marginTop: 25 }} disableUnderline={true}
                value={this.state.selectedSchool}
                placeholder="Elige Escuela"
                onChange={(event) => this.onSchoolChange(event)}
                inputProps={{
                  name: 'year',
                  id: 'year',
                }}>

                <MenuItem disabled value={0}>{i18n.t("ADMIN_COURSE_SELECT_SCHOOL")}</MenuItem>

                {this.state.schools.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
                  )
                })
                }
              </Select>
              :

              <Select style={{ fontSize: 16, marginTop: 25 }} disableUnderline={true}
                value={this.state.selectedSchool}
                placeholder="Select School"
                onChange={(event) => this.onSchoolChange(event)}
                inputProps={{
                  name: 'year',
                  id: 'year',
                }}>

                <MenuItem disabled value={0}>Select School</MenuItem>

                {this.state.schools.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
                  )
                })
                }
              </Select>
            }

          </Grid>
        </Grid>

        <Paper style={{ marginTop: 10, borderRadius: 0 }}>
          <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
            <Table className={classes.table} style={{ height: this.state.height }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("subject_name")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_COURSES_TABLE_SUBJECT_NAME")}</Typography>
                      {this.state.sortBy === "subject_name" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("course_code")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_COURSES_TABLE_COURSE_CODE")}</Typography>
                      {this.state.sortBy === "course_code" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("course_name")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_COURSES_TABLE_COURSE_NAME")}</Typography>
                      {this.state.sortBy === "course_name" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("credit_hour")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_COURSES_TABLE_CREDIT_HOURS")}</Typography>
                      {this.state.sortBy === "credit_hour" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("dual_credit_hour")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_COURSES_TABLE_DUAL_CREDIT_HOURS")}</Typography>
                      {this.state.sortBy === "dual_credit_hour" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("is_ap")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_COURSE_TABLE_AP_COURSE")}</Typography>
                      {this.state.sortBy === "is_ap" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("is_cte")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_COURSE_TABLE_CTE_COURSE")}</Typography>
                      {this.state.sortBy === "is_cte" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <Typography style={{ fontSize: 12 }}>Pathway</Typography>
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("date_modified")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_COURSE_TABLE_DATE_MODIFIED")}</Typography>
                      {this.state.sortBy === "date_modified" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                  .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow key={index} classes={classes.tableRow}
                        hover style={{ cursor: 'pointer' }}>
                        <TableCell onClick={event => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.subject_name}</Typography></TableCell>
                        <TableCell onClick={event => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.course_code}</Typography></TableCell>
                        <TableCell onClick={event => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.course_name}</Typography></TableCell>
                        <TableCell onClick={event => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.credit_hour}</Typography></TableCell>
                        <TableCell onClick={event => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.dual_credit_hour}</Typography></TableCell>
                        <TableCell onClick={event => this.onEditClick(event, row)}>
                          <Typography style={{ fontSize: 12 }}>
                            {row.is_ap === 1 ? 'Yes' : 'No'}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={event => this.onEditClick(event, row)}>
                          <Typography style={{ fontSize: 12 }}>
                            {row.is_cte === 1 ? 'Yes' : 'No'}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={event => this.onEditClick(event, row)}>
                          <Typography style={{ fontSize: 12 }}>
                            {row.pathway_id === 0 ? 'N/A' : row.pathway_name}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={event => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{moment(row.date_modified).format('MM/DD/YYYY')}</Typography></TableCell>
                      </TableRow>
                    )
                  }
                  )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 50 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[25]}
            component="div"
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.currentPage}
            count={this.state.filteredDataSource.length}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>

      </div>
    );
  }
}

CourseScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    announcementAlert: state.alert.announcementAlert,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CourseScreen));