import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton, List,
  ListItem, ListItemText,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class PublicationScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      grade_id: 0,
      selectedItem: {}

    }

    this.onNewClose = this.onNewClose.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {

    this.getData(this.props.profileId);

  }

  getData(profileId) {

    this.setState({
      isLoading: true
    })

    fetch("https://api.tasselpathway.com/profile_publication/" + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onEditOpen(item) {
    this.setState({
      isEditOpen: true,
      selectedItem: item,
    })
  }

  onAddClick() {

    fetch("https://api.tasselpathway.com/profile_publication", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        person_id: this.props.personId,
        publication_name: this.state.publication_name,
        publication_url: this.state.publication_url
      })
    })
      .then(res => res.json())
      .then(responseJson => {

        this.setState({
          isNewOpen: false
        })

        this.getData(this.props.profileId);
      })
  }


  onEditPublicationClick(publicationId) {
    fetch("https://api.tasselpathway.com/profile_publication", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        publication_name: this.state.selectedItem.publication_name,
        publication_url: this.state.selectedItem.publication_url,
        profile_publications_id: publicationId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isEditOpen: false,
            isLoading: false,
          })
        }
        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onNewOpen() {
    this.setState({
      isNewOpen: true
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false,
      isEditOpen: false
    })
  }

  onEditPublicationNameChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.publication_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onEditPublicationUrlChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.publication_url = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  renderPublications() {

    var content = this.state.dataSource.map((item, key) => {
      return (
        <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
          onClick={() => this.onEditOpen(item)}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                  className={this.props.classes.inline} color="textPrimary">
                  {item.publication_name}
                  <br />
                  <Linkify>{item.publication_url}</Linkify>
                </Typography>
                <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.project_description}</Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    })

    return content;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <div className={classes.root}>

          <Dialog open={this.state.isNewOpen}
            onClose={this.onNewClose} fullWidth
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
              {i18n.t("PUBLICATION_TILE_ADD")}
            </DialogTitle>

            <DialogContent>

              <Grid container>

                <Grid item xs={12}>
                  {this.props.selectedLanguage === "esp" ?
                    <>
                      <TextField
                        fullWidth
                        id="standard-name"
                        label="Titulo de la Publicacion"
                        onChange={(e) => this.setState({ publication_name: e.target.value })}
                        value={this.state.publication_name}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        id="standard-name"
                        label="URL de la Publicacion"
                        onChange={(e) => this.setState({ publication_url: e.target.value })}
                        value={this.state.publication_url}
                        margin="normal"
                      />
                    </>
                    :
                    <>
                      <TextField
                        fullWidth
                        id="standard-name"
                        label="Publication Title"
                        onChange={(e) => this.setState({ publication_name: e.target.value })}
                        value={this.state.publication_name}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        id="standard-name"
                        label="Publication URL"
                        onChange={(e) => this.setState({ publication_url: e.target.value })}
                        value={this.state.publication_url}
                        margin="normal"
                      />
                    </>
                  }
                </Grid>

              </Grid>

            </DialogContent>
            <DialogActions>
              <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                onClick={() => this.onAddClick()} color="primary">
                {i18n.t("PUBLICATION_TILE_ADD_BUTTON")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isEditOpen}
            onClose={this.onEditClose} fullWidth
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
              {i18n.t("PUBLICATION_TILE_EDIT")}
            </DialogTitle>

            <DialogContent>

              <Grid container>

                <Grid item xs={8}>
                  {this.props.selectedLanguage === "esp" ?
                    <>
                      <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Titulo de la Publicacion"
                        onChange={(event) => this.onEditPublicationNameChange(event)}
                        value={this.state.selectedItem.publication_name}
                        margin="normal"
                      />

                      <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="URL de la Publicacion"
                        onChange={(event) => this.onEditPublicationUrlChange(event)}
                        value={this.state.selectedItem.publication_url}
                        margin="normal"
                      />
                    </>
                    :
                    <>
                      <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Publication Title"
                        onChange={(event) => this.onEditPublicationNameChange(event)}
                        value={this.state.selectedItem.publication_name}
                        margin="normal"
                      />

                      <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Publication URL"
                        onChange={(event) => this.onEditPublicationUrlChange(event)}
                        value={this.state.selectedItem.publication_url}
                        margin="normal"
                      />
                    </>
                  }

                </Grid>

              </Grid>

            </DialogContent>
            <DialogActions>
              <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                onClick={() => this.onEditPublicationClick(this.state.selectedItem.profile_publications_id)} color="primary">
                {i18n.t("PUBLICATION_TILE_EDIT")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>

              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{
                    fontSize: 18, fontWeight: 'bold', marginTop: 10,
                    color: '#4D4D4F', marginBottom: 20
                  }}>{i18n.t("TEACHER_PROFILE_PUBLICATIONS_TILE")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.renderPublications()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

PublicationScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PublicationScreen)));