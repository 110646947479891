import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Card, CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem, Select, Tab, Tabs,
    Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class RecommendationScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isNewOpen: false,
            dataSource: [],
            newName: '',
            newDescription: '',
            newNameValid: true,
            selectedItem: {},
            teachers: [],
            teacherId: 0,
            value: 0,
            isRequestOpen: false,
            recommendations: [],
            recommendationStudents: [],
            isEditOpen: false,
            recommendationsPending: []
        }

        this.onNewOpen = this.onNewOpen.bind(this);
        this.onNewClose = this.onNewClose.bind(this);
        this.onNewClick = this.onNewClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentDidMount() {
        fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        schools: responseJson.results,
                        schoolId: responseJson.results[0].school_id
                    })
                }
                this.getData(this.props.profileId, responseJson.results[0].school_id);
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    componentWillReceiveProps(newProps) {
        this.getData(newProps.profileId);
    }

    getData(profileId, schoolId) {
        this.setState({
            isLoading: true
        })
        fetch('https://api.tasselpathway.com/allTeachers?school=' + schoolId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        teachers: responseJson.results
                    })
                    this.props.onProjectsLoaded(responseJson.results)
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch('https://api.tasselpathway.com/recommendation_requests/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        dataSource: responseJson.results
                    })
                    this.props.onProjectsLoaded(responseJson.results)
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch('https://api.tasselpathway.com/recommendation_requests/student/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        recommendationsPending: responseJson.results
                    })
                    this.props.onProjectsLoaded(responseJson.results)
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch('https://api.tasselpathway.com/recommendations/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        recommendations: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch('https://api.tasselpathway.com/recommendations/student/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        recommendationStudents: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    createProject = () => {
        fetch('https://api.tasselpathway.com/recommendation_requests', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                student_id: this.props.personId,
                teacher_id: this.state.teacherId,
                description: this.state.newDescription,
                completed: 0
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.onNewClose()
                    this.getData()
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    createRecommendation = () => {

        fetch('https://api.tasselpathway.com/recommendation', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                student_id: this.state.selectedItem.student_id,
                teacher_id: this.props.personId,
                recommendation_details: this.state.newDescription
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.onRecommendationUpdate()
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onRecommendationUpdate() {

        fetch('https://api.tasselpathway.com/recommendation_requests/patch', {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": 'application/json',
                "token": this.props.token
            },
            credentials: "omit",
            body: JSON.stringify({
                completed: 1,
                recommendation_request_id: this.state.selectedItem.recommendation_request_id
            })
        })
            .then(res => res.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.onNewClose()
                    this.getData()
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    editRecommendation = () => {

        fetch('https://api.tasselpathway.com/recommendation/patch', {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": 'application/json',
                "token": this.props.token
            },
            credentials: "omit",
            body: JSON.stringify({
                recommendation_details: this.state.selectedItem.recommendation_details,
                recommendation_id: this.state.selectedItem.recommendation_id
            })
        })
            .then(res => res.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.onNewClose()
                    this.getData()
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    renderRequests() {
        var content = this.state.dataSource.map((item, key) => {
            return (
                <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
                    onClick={() => this.onEditOpen(item)}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                                    className={this.props.classes.inline} color="textPrimary">
                                    {item.first_name + " " + item.last_name}
                                </Typography>
                                <Typography>{item.description}</Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            )
        })

        return content;
    }

    renderCompleted() {

        var content = this.state.recommendations.map((item, key) => {
            return (
                <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
                    onClick={() => this.onEditRecommendationOpen(item)}>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                                    className={this.props.classes.inline} color="textPrimary">
                                    {item.first_name + " " + item.last_name}
                                </Typography>
                                <Typography>{item.recommendation_details}</Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            )
        })

        return content;
    }

    renderCompletedStudent() {

        var content = this.state.recommendationStudents.map((item, key) => {
            return (
                <ListItem>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                                    className={this.props.classes.inline} color="textPrimary">
                                    {item.first_name + " " + item.last_name}
                                </Typography>
                                <Typography>{item.recommendation_details}</Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            )
        })

        return content;
    }

    renderRequestStudent() {

        var content = this.state.recommendationsPending.map((item, key) => {
            return (
                <ListItem key={key} alignItems="flex-start">
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}
                                    className={this.props.classes.inline} color="textPrimary">
                                    {item.first_name + " " + item.last_name}
                                </Typography>
                                <Typography>{item.description}</Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            )
        })

        return content;
    }

    onEditOpen(item) {
        this.setState({
            isRequestOpen: true,
            selectedItem: item
        })
    }

    onEditRecommendationOpen(item) {
        this.setState({
            isEditOpen: true,
            selectedItem: item
        })
    }


    onNewOpen() {
        this.setState({
            newName: '',
            newDescription: '',
            isNewOpen: true
        })
    }

    onNewClose = () => {

        this.setState({
            isNewOpen: false,
            isRequestOpen: false,
            isEditOpen: false
        })
    }

    onNewAdded() {
        this.componentDidMount()

        this.setState({
            isNewOpen: false
        })
    }

    onNewClick() {

        var nameValid = true;

        if (this.state.newName === "") {
            nameValid = false;
        }

        this.setState({
            newNameValid: nameValid
        })

        if (nameValid) {
            this.createActivity();

            this.setState({
                isNewOpen: false
            })
        }
    }

    onDeleteClick() {
        this.deleteProject();

        this.setState({
            isEditOpen: false
        })
    }

    onNameChange(event) {

        var selectedItem = this.state.selectedItem;
        selectedItem.project_name = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }

    onDescriptionChange(event) {
        var selectedItem = this.state.selectedItem;
        selectedItem.project_description = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }

    handleChange = name => (event, { newValue }) => {
        this.setState({
            [name]: newValue,
        });
    };

    onRequestClick = (event, value) => {
        this.setState({
            value
        });
    }

    onRecommendationChange(event) {

        var selectedItem = this.state.selectedItem;
        selectedItem.recommendation_details = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }

    renderTeachers() {

        var content = this.state.teachers.map((item, key) => {
            return (
                <MenuItem key={key} value={item.person_id}>{item.first_name + " " + item.last_name}</MenuItem>
            )
        })

        return content;
    }


    render() {
        const { t, i18n } = this.props;
        const { classes } = this.props;

        return (
            <div>

                <div className={classes.root}>

                    <Dialog open={this.state.isNewOpen}
                        onClose={this.onNewClose} maxWidth="sm"
                        fullWidth={true}
                        aria-labelledby="form-dialog-title">

                        <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
                            Request Recommendation
                        </DialogTitle>

                        <DialogContent>

                            <div>

                                <FormControl className={classes.formControl}
                                    fullWidth>
                                    <InputLabel htmlFor="assessment_type_id">Teacher</InputLabel>
                                    <Select variant='standard'
                                        value={this.state.teacherId}
                                        onChange={(event) => this.setState({ teacherId: event.target.value })}
                                        inputProps={{
                                            name: 'assessment_type_id',
                                            id: 'assessment_type_id',
                                        }}>
                                        <MenuItem disabled value={0}>Select Teacher</MenuItem>
                                        {this.renderTeachers()}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth style={{ marginTop: 10 }}>
                                    <InputLabel htmlFor="description">What is the recommendation for?</InputLabel>
                                    <Input variant="outline" multiline={true} rows={4} fullWidth
                                        onChange={(event) => this.setState({ newDescription: event.target.value })}
                                        id="description" aria-describedby="my-helper-text" />
                                </FormControl>

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={this.createProject} color="primary"
                                style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }}>
                                {i18n.t("PROJECT_ADD_BUTTON")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <Grid container style={styles.itemContainerStyle}>

                    {this.props.userRoleId < 8 && this.props.personId !== this.props.profileId ?

                        <Card style={{ height: 298, width: '100%', borderRadius: 3 }}>
                            <CardContent>
                                <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                                    <Grid item xs={6}>
                                        <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>Recommendations</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <Tabs value={this.state.value} onChange={this.onRequestClick} style={{ paddingBottom: 50 }}>
                                            <Tab label="REQUESTS" />
                                            <Tab label="COMPLETED" />
                                        </Tabs>
                                        {this.state.value === 0 &&
                                            <List style={{ height: 200, overflow: 'auto' }}>
                                                {this.renderRequests()}
                                            </List>
                                        }
                                        {this.state.value === 1 &&
                                            <List style={{ height: 200, overflow: 'auto' }}>
                                                {this.renderCompleted()}
                                            </List>
                                        }
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>

                        :

                        <Card style={{ height: 298, width: '100%', borderRadius: 3 }}>
                            <CardContent>
                                <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                                    <Grid item xs={6}>
                                        <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>Recommendations</Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                        {this.props.personId === this.props.profileId ?
                                            <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                                                <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                                            </IconButton>
                                            : null}
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <Tabs value={this.state.value} onChange={this.onRequestClick}>
                                            <Tab label="COMPLETED" />
                                            <Tab label="REQUESTS" />
                                        </Tabs>
                                        {this.state.value === 0 && this.props.personId === this.props.profileId &&
                                            <List style={{ height: 200, overflow: 'auto' }}>
                                                {this.renderCompletedStudent()}
                                            </List>
                                        }
                                        {this.state.value === 1 && this.props.personId === this.props.profileId &&
                                            <List style={{ height: 200, overflow: 'auto' }}>
                                                {this.renderRequestStudent()}
                                            </List>
                                        }
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    }
                </Grid>

                <Dialog open={this.state.isRequestOpen}
                    onClose={this.onNewClose} maxWidth="sm"
                    fullWidth={true}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
                        Write {this.state.selectedItem.first_name + " " + this.state.selectedItem.last_name} a recommendation letter for
                        <DialogContent style={{ fontSize: 16 }}>"{this.state.selectedItem.description}"</DialogContent>
                    </DialogTitle>

                    <DialogContent>

                        <div>

                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                <InputLabel htmlFor="description">Your recommendation letter</InputLabel>
                                <Input variant="outline" multiline={true} rows={4} fullWidth
                                    onChange={(event) => this.setState({ newDescription: event.target.value })}
                                    id="description" aria-describedby="my-helper-text" />
                            </FormControl>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.createRecommendation} color="primary"
                            style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }}>
                            {i18n.t("PROJECT_ADD_BUTTON")}
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={this.state.isEditOpen}
                    onClose={this.onNewClose} maxWidth="sm"
                    fullWidth={true}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
                        Edit your recommendation
                    </DialogTitle>

                    <DialogContent>

                        <div>

                            <FormControl fullWidth style={{ marginTop: 10 }}>
                                <InputLabel htmlFor="description">Your recommendation letter</InputLabel>
                                <Input variant="outline" multiline={true} rows={4} fullWidth
                                    value={this.state.selectedItem.recommendation_details}
                                    onChange={(event) => this.onRecommendationChange(event)}
                                    id="description" aria-describedby="my-helper-text" />
                            </FormControl>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.editRecommendation} color="primary"
                            style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }}>
                            {i18n.t("PROJECT_ADD_BUTTON")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

RecommendationScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        personId: state.auth.personId,
        profileId: state.profile.profileId,
        schoolId: state.profile.schoolId,
        token: state.auth.token,
        userRoleId: state.auth.userRoleId
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); }
    }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RecommendationScreen)));