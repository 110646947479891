import {
  Avatar,
  Grid,
  LinearProgress,
  List,
  ListItem, ListItemAvatar, ListItemText,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

class WorkReadyScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      completionPercent: 10
    }
  }

  componentDidMount() {
    this.getData(this.props.profileId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(profileId) {
    this.setState({
      isLoading: true
    })

    /*

    fetch('https://api.tasselpathway.com/profile_favorite_career/person/' + profileId, {
        method: "GET",
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
        },
        credentials: 'omit'
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if(responseJson.error === false) {
        this.setState({
          isLoading: false,
          dataSource: responseJson.results
        })
        this.props.onCareersLoaded(responseJson.results)
      }
    })
    .catch((error) =>{
      this.setState({
        isLoading: false
      })
    }); */
  }

  onCareerClick(value) {
    this.props.props.history.push('/career/' + value);
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Grid item xs={12}>

            <Grid container>
              <Grid item xs={10}>
                <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', color: '#4D4D4F' }}>
                  {i18n.t("HOME_WR_PROGRESS_BOARD")}
                </Typography>
              </Grid>
            </Grid>
            <LinearProgress variant="determinate" value={this.state.completionPercent} />

            <List style={{ overflow: 'auto' }}>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30 }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Evidence of a Problem Solving Activity"
                  secondary="Minimum of 1 in 4 years" />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30 }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Evidence of Completing a Task or Project"
                  secondary="Minimum of 3 in 4 years" />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30 }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Evidence of Self Motived Activity"
                  secondary="Minimum of 1 in 4 years"
                />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30 }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Evidence of Proficiency in Knowledge Attainment"
                  secondary="Must have a minimum of 3.0 overall GPA or a P/D in On Demand Writing during Junior year KAS assessment" />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30 }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Evidence of Attendance"
                  secondary="above 80%" />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30 }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Evidence of Ethical Behavior"
                  secondary="Must have 5 or less infractions per year for 4 years" />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30 }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Drug Free"
                  secondary="Minimum of 2 tasks in 4 years" />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30 }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Evidence of Communication or Leadership Skills"
                  secondary="Minimum of 2 in 4 years" />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30 }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Evidence of Teamwork"
                  secondary="Minimum of 2 in 4 years" />
              </ListItem>

              <ListItem>
                <ListItemAvatar>
                  <Avatar alt="MSU" style={{ height: 30, width: 30, backgroundColor: '#25D192' }}>
                    <CheckIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 16 }} primary="Must Complete ILP Profile"
                  secondary="Each year for 4 years" />
              </ListItem>

            </List>

          </Grid>
        </Grid>

      </div>
    );
  }
}

WorkReadyScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    personId: state.auth.personId,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorkReadyScreen)));