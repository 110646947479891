import { GET_EMPLOYER, SET_EMPLOYER  } from "../actions";

export const defaultState = {
     employerId: 0,
     employerName: '',
};
 
export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case GET_EMPLOYER:
             return Object.assign({}, state, {
             });
         case SET_EMPLOYER:
             return Object.assign({}, state, {
                employerId: action.employerId,
                employerName: action.employerName
             });
         default:
             return state
     }
 }