export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const GET_PERSON = "GET_PERSON";
export const SET_PERSON = "SET_PERSON";
export const GET_PROGRESS = "GET_PROGRESS";
export const SET_PROGRESS = "SET_PROGRESS";
export const GET_PROFILE = "GET_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const GET_CAREER = "GET_CAREER";
export const SET_CAREER = "SET_CAREER";
export const GET_COLLEGE = "GET_COLLEGE";
export const SET_COLLEGE = "SET_COLLEGE";
export const GET_MAJOR = "GET_MAJOR";
export const SET_MAJOR = "SET_MAJOR";
export const GET_EMPLOYER = "GET_EMPLOYER";
export const SET_EMPLOYER = "SET_EMPLOYER";
export const GET_TASK = "GET_TASK";
export const SET_TASK = "SET_TASK";
export const GET_GROUP = "GET_GROUP";
export const SET_GROUP = "SET_GROUP";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const GET_ALERT = "GET_ALERT";
export const SET_ALERT = "SET_ALERT";
export const SET_OBJECTIVE = "SET_OBJECTIVE";
export const SET_RESUME = "SET_RESUME";
export const SET_RESUME_TEMPLATE = "SET_RESUME_TEMPLATE";
export const SET_SECTIONS = "SET_SECTIONS";
export const SET_IS_EDITING_RESUME = "SET_IS_EDITING_RESUME";
export const CLEAR_RESUME_DATA = "CLEAR_RESUME_DATA";