import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  Snackbar, SnackbarContent,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import { logout } from '../../redux/actions/auth';
import { setProfile } from '../../redux/actions/profile';
import { setProgress } from '../../redux/actions/progress';
import styles from './style';
class ConnectionScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      allConnections: [],
      allPending: [],
      filteredConnections: [],
      grades: [],
      allSearch: [],
      filteredSearch: [],
      card: [],
      value: 0,
      showMessage: false,
      alertMessage: '',
      gradeList: []
    }

    this.onSearch = this.onSearch.bind(this);
    this.onCloseMessage = this.onCloseMessage.bind(this);
  }

  componentDidMount() {
    this.getData(this.state.gradeList);
  }

  getData(gradeList) {

    this.setState({
      isLoading: true
    })

    var progress = 0;
    this.props.setProgress(progress);

    fetch('https://api.tasselpathway.com/profile_connection/person/' + this.props.personId + '/' + '?grade=' + gradeList.join(), {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            allConnections: responseJson.results,
            filteredConnections: responseJson.results
          })
        }

        progress = progress + 25;
        this.props.setProgress(progress);
      })
      .catch((error) => {

        progress = progress + 25;
        this.props.setProgress(progress);

        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/profile_connection/pending/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            allPending: responseJson.results
          })
        }

        progress = progress + 25;
        this.props.setProgress(progress);

      })
      .catch((error) => {

        progress = progress + 25;
        this.props.setProgress(progress);

        this.setState({
          isLoading: false
        })
      });
    fetch('https://api.tasselpathway.com/profile_connection/search/' + this.props.personId + '/' + this.props.schoolId + '/' + '?grade=' + gradeList.join(), {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            allSearch: responseJson.results,
            filteredSearch: responseJson.results
          })
        }

        progress = progress + 50;
        this.props.setProgress(progress);

      })
      .catch((error) => {

        progress = progress + 50;
        this.props.setProgress(progress);

        this.setState({
          isLoading: false
        })

      });

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  onMouseOut(key) {

    var card = this.state.card;
    card['card_' + key] = false;

    this.setState({
      card: card
    })
  }

  onMouseOver(key) {

    var card = this.state.card;
    card['card_' + key] = true;

    this.setState({
      card: card
    })
  }

  onSearch(event) {

    if (this.state.value === 0) {

      const newData = this.state.allConnections.filter(function (item) {
        const itemData = item.first_name.toUpperCase() + " " + item.last_name.toUpperCase()
        const textData = event.target.value.toUpperCase()
        return itemData.indexOf(textData) > -1
      })
      this.setState({
        filteredConnections: newData,
        searchText: event.target.value
      })

    } else {

      const newData = this.state.allSearch.filter(function (item) {
        const itemData = item.first_name.toUpperCase() + " " + item.last_name.toUpperCase()
        const textData = event.target.value.toUpperCase()
        return itemData.indexOf(textData) > -1
      })
      this.setState({
        filteredSearch: newData,
        searchText: event.target.value
      })

    }
  }

  onGridClick(person) {

    this.props.onSetProfile(
      person.person_id,
      person.first_name,
      person.last_name,
      person.grade_id,
      person.grade_name,
      person.district_id,
      person.district_name,
      person.school_id,
      person.school_name,
      person.role_id,
      person.early_graduation_program
    );

    this.props.history.push('/profile/' + person.person_id);

  }

  createConnection(studentId) {
    fetch("https://api.tasselpathway.com/profile_connection", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId,
        student_id: studentId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            showMessage: true,
            alertMessage: 'Connection Request Sent'
          })
        }

        this.getData();

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  acceptConnection(connectionId) {

    fetch("https://api.tasselpathway.com/profile_connection", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        connection_id: connectionId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            showMessage: true,
            alertMessage: 'Connection Request Accepted'
          })
        }

        this.getData();

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  rejectConnection(connectionId) {

    fetch("https://api.tasselpathway.com/profile_connection", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        connection_id: connectionId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            showMessage: true,
            alertMessage: 'Connection Request Rejected'
          })
        }

        this.getData();

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onCloseMessage() {
    this.setState({
      showMessage: false
    })
  }

  handleChange = (event, value) => {
    this.setState({
      value
    });
  }

  onGradeFilterClick(event, gradeId) {

    if (event.target.checked !== undefined) {

      var gradeList = this.state.gradeList;

      if (event.target.checked) {
        gradeList.push(gradeId);
      } else {
        for (var i = 0; i < gradeList.length; i++) {
          if (gradeList[i] === gradeId) {
            gradeList.splice(i, 1);
          }
        }
      }

      this.setState({
        gradeList: gradeList,
        pageNumber: 0
      })
      this.getData(gradeList);
    }
  }

  render() {

    const { classes } = this.props;
    return (
      <div className={classes.root}>

        <Snackbar
          classes={{
            root: classes.message
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          autoHideDuration={5000}
          onClose={this.onCloseMessage}
          open={this.state.showMessage}>
          <SnackbarContent
            message={
              <span style={styles.message}>
                {this.state.alertMessage}
              </span>
            }
            classes={{
              root: classes.error
            }}
            action={
              <IconButton key="close" onClick={this.onCloseMessage} className={classes.content}>
                <CloseIcon style={{ color: '#FFF' }} />
              </IconButton>
            }>
          </SnackbarContent>
        </Snackbar>

        <Grid container style={styles.itemContainerStyle}>
          <Grid item xs={12} style={{ marginLeft: 100, marginRight: 100 }}>

            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 24, color: '#333', fontWeight: 'bold' }}>{i18n.t("CONNECTIONS")}</Typography>
              </Grid>
              <Grid item xs={6}>
                {this.state.value === 0 ?
                  <Typography style={{ fontSize: 14, color: '#333', marginTop: 30, fontWeight: 'bold' }}>
                    {i18n.t("SHOWING")} {this.state.allConnections.length} {i18n.t("RESULTS")}
                  </Typography>
                  :
                  <Typography style={{ fontSize: 14, color: '#333', marginTop: 30, fontWeight: 'bold' }}>
                    {i18n.t("SHOWING")} {this.state.allSearch.length} {i18n.t("RESULTS")}
                  </Typography>
                }
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                {this.props.selectedLanguage === "esp" ?
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={this.onSearch} style={{ width: '100%' }}
                      placeholder="Buscar"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>


                  :
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={this.onSearch} style={{ width: '100%' }}
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                }
              </Grid>
            </Grid>

            <Grid container>

              <Grid item xs={2}>

                <div style={{ marginRight: 40 }}>

                  <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>Grades</Typography>
                  <hr />
                  <FormControl component="fieldset" className={classes.formControl}
                    style={{
                      height: 300, borderBottom: '1px solid #eaeaea', marginLeft: 5, marginBottom: 20,
                      paddingLeft: 10, width: '100%', overflow: 'auto'
                    }}>
                    {this.props.selectedLanguage === "esp" ?
                      <FormGroup>
                        {this.state.grades.map((item, key) => {
                          return (
                            <FormControlLabel key={key} onClick={(event) => this.onGradeFilterClick(event, item.grade_id)}
                              control={
                                <Checkbox />
                              }
                              label={item.grade_name_spanish}
                            />
                          )
                        })}


                      </FormGroup>

                      :
                      <FormGroup>
                        {this.state.grades.map((item, key) => {
                          return (
                            <FormControlLabel key={key} onClick={(event) => this.onGradeFilterClick(event, item.grade_id)}
                              control={
                                <Checkbox value={13} />
                              }
                              label={item.grade_name}
                            />
                          )
                        })}


                      </FormGroup>
                    }

                  </FormControl>


                </div>
              </Grid>

              <Grid item xs={10}>

                {this.state.allPending.length > 0 ?

                  <div>

                    <Typography style={{ fontSize: 13, marginLeft: 20 }}>PENDING CONNECTIONS</Typography>

                    <Grid container style={{ marginBottom: 20, marginTop: 20 }}>

                      {this.state.allPending.map((item, key) => {
                        return (
                          <Grid item xs={2}>

                            <Card style={{ margin: 5, height: 228 }} >

                              <Grid container style={{ flexDirection: 'column' }}>

                                {item.logo_image_url === "" ?
                                  <Avatar style={{ height: 100, width: 100, marginTop: 20, marginBottom: 10, alignSelf: 'center' }}
                                    src={require('../../assets/default_avatar.png')} />
                                  :
                                  <Avatar style={{ height: 100, width: 100, marginTop: 20, marginBottom: 10, alignSelf: 'center' }}
                                    src={item.logo_image_url} />
                                }

                                <Typography style={{ textAlign: 'center', fontSize: 16 }}>{item.first_name} {item.last_name}</Typography>
                                <Typography style={{ textAlign: 'center', fontSize: 14 }}>{item.grade_name}</Typography>

                                <Grid container style={{ marginTop: 20 }}>
                                  <Grid item xs={6}>
                                    <Button fullWidth variant="contained" color="primary"
                                      onClick={() => this.acceptConnection(item.connection_id)}
                                      style={{ borderRadius: 0 }}>
                                      <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Accept</Typography>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button fullWidth variant="contained" color="secondary"
                                      onClick={() => this.rejectConnection(item.connection_id)}
                                      style={{ borderRadius: 0 }}>
                                      <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Reject</Typography>
                                    </Button>
                                  </Grid>
                                </Grid>



                              </Grid>

                            </Card>

                          </Grid>
                        )
                      })}

                    </Grid>
                  </div>
                  : null}

                {this.props.selectedLanguage === "esp" ?
                  <Tabs value={this.state.value} onChange={this.handleChange}>
                    <Tab label="CONEXIONES" />
                    <Tab label="ENCUENTRE CONEXIONES" />
                  </Tabs>
                  :
                  <Tabs value={this.state.value} onChange={this.handleChange}>
                    <Tab label="CONNECTIONS" />
                    <Tab label="FIND CONNECTIONS" />
                  </Tabs>
                }

                <Grid container style={{ overflow: 'auto', marginTop: 20 }}>

                  {this.state.value === 0 &&

                    this.state.allConnections
                      .map((item, key) => {

                        return (

                          <Grid item xs={2} key={key}>

                            <Card style={{ margin: 5, height: 205, cursor: 'pointer' }}
                              raised={this.state.card['card_' + key]}
                              onClick={() => this.onGridClick(item)}
                              onMouseOut={() => this.onMouseOut(key)}
                              onMouseOver={() => this.onMouseOver(key)} >

                              <Grid container style={{ flexDirection: 'column' }}>

                                {item.logo_image_url === "" ?
                                  <Avatar style={{ height: 100, width: 100, marginTop: 20, marginBottom: 10, alignSelf: 'center' }}
                                    src={require('../../assets/default_avatar.png')} />
                                  :
                                  <Avatar style={{ height: 100, width: 100, marginTop: 20, marginBottom: 10, alignSelf: 'center' }}
                                    src={item.logo_image_url} />
                                }

                                <Typography style={{ textAlign: 'center', fontSize: 16 }}>{item.first_name} {item.last_name}</Typography>
                                <Typography style={{ textAlign: 'center', fontSize: 14 }}>{item.grade_name}</Typography>

                              </Grid>

                            </Card>

                          </Grid>
                        )
                      })
                  }

                  {this.state.value === 1 &&

                    orderBy(this.state.filteredSearch, this.state.sortBy, this.state.sortDirection)
                      .map((item, key) => {

                        return (

                          <Grid item xs={2} key={key}>

                            <Card style={{ margin: 5, height: 205 }}>

                              <Grid container style={{ flexDirection: 'column' }}>

                                <Avatar style={{ height: 100, width: 100, marginTop: 20, marginBottom: 10, alignSelf: 'center' }} src={item.logo_image_url} />

                                <Typography style={{ textAlign: 'center', fontSize: 16 }}>{item.first_name} {item.last_name}</Typography>
                                <Typography style={{ textAlign: 'center', fontSize: 14 }}>{item.grade_name}</Typography>

                                {item.is_requested === 1 ?

                                  <Button fullWidth variant="contained" color="secondary"
                                    style={{ borderRadius: 0 }}>
                                    <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Requested</Typography>
                                  </Button>
                                  :

                                  <Button fullWidth variant="contained" color="primary"
                                    onClick={() => this.createConnection(item.person_id)}
                                    style={{ borderRadius: 0 }}>
                                    <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Connect</Typography>
                                  </Button>
                                }

                              </Grid>

                            </Card>

                          </Grid>
                        )
                      })
                  }

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ConnectionScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage,
    schoolId: state.profile.schoolId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetProfile: (profileId, firstName, lastName, gradeId, gradeName, districtId, districtName, schoolId, schoolName, profileRoleId, earlyGraduationProgram) => { dispatch(setProfile(profileId, firstName, lastName, gradeId, gradeName, districtId, districtName, schoolId, schoolName, profileRoleId, earlyGraduationProgram)); },
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectionScreen));