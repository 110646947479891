export default {

    categoryHeader: {
      paddingTop: 16,
      paddingBottom: 16,
    },
    item: {
      color: '#FFF',
      height: 40
    },
    itemPrimary: {
      color:'#FFF'
    },
    itemCategory: {
      backgroundColor: '#1F3545',
      fontSize: 21,

      color:'#FFF',
      fontWeight:'bold'
    },
    itemActionable: {
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    searchInput: {
      height:10,
      width:140
    },
    search: {
      position: 'absolute',
      backgroundColor: '#eaeaea',
      '&:hover': {
          backgroundColor: '#e0e0e0',
      },
      borderRadius:2,
      marginTop:20,
      marginRight: 30,
      marginLeft: 75,
      width: '15vw',
      minWidth:91
    },
    searchIcon: {
        width: 10,
        marginLeft:15,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemActiveItem: {
      color: '#FFF',
    },
    topDivider: {
      backgroundColor: '#FFF',
      marginBottom: 10
    },
    closeButton: {
      position: 'absolute',
      right: 10,
      top: 20
    },
    divider: {
      backgroundColor: '#FFF',
      marginBottom: 20,
      marginTop:20
    },
    profileHover:{
      cursor:'pointer'
    }
}