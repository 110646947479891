import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  IconButton,
  List,
  ListItem, ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

class ConnectionScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
    }
  }

  componentDidMount() {
    this.getData(this.props.profileId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(profileId) {
    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_connection/person/' + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onPersonClick(person) {

    this.props.history.push('/profile/' + person.person_id);
  }

  onNewClick() {
    this.props.history.push('/connections');
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Grid container style={styles.itemContainerStyle}>

          <Grid item xs={12}>

            <Grid container>

              <Grid item xs={10}>
                <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', marginTop: 20, color: '#4D4D4F' }}>
                  {i18n.t("CONNECTIONS")}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                {this.props.profileId === this.props.personId ?
                  <IconButton style={{ marginTop: 20 }} onClick={() => this.onNewClick()}>
                    <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                  </IconButton>
                  : null}
              </Grid>
            </Grid>

            <List style={{ overflow: 'auto' }}>

              {this.state.dataSource.map((item, key) => {

                return (

                  <ListItem key={key} button onClick={() => this.onPersonClick(item)}>
                    <ListItemAvatar>
                      <Avatar alt="MSU" style={{ height: 55, width: 55 }} src={item.logo_image_url} />
                    </ListItemAvatar>
                    <ListItemText style={{ fontSize: 16, marginLeft: 15 }} primary={item.first_name + " " + item.last_name}
                      secondary={item.grade_name} />
                  </ListItem>

                )

              })}

            </List>

          </Grid>

        </Grid>

      </div>
    );
  }
}

ConnectionScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    districtId: state.profile.districtId,
    profileId: state.profile.profileId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConnectionScreen)));

