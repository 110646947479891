import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputBase, Tab, Tabs, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import { logout } from '../../redux/actions/auth';
import { setProgress } from '../../redux/actions/progress';
import Active from './active/index';
import Complete from './complete/index';
import MyAssignment from './myassignments/index';
import MyGradeBook from './mygradebook/index';
import MyTask from './mytasks/index';
import styles from './style';

class TaskScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      value: 0,
      searchText: ""
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

  }

  handleChange = (event, value) => {
    this.setState({
      value
    });
  }

  onSearch(searchText) {
    this.setState({
      searchText: searchText
    })
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Grid container style={styles.itemContainerStyle}>

          <Grid item xs={12} style={{ marginLeft: 100, marginRight: 100 }}>

            <Grid container style={{ marginBottom: 10, marginTop: 20 }}>

              <Grid item xs={2}>
                <Typography style={{ fontSize: 24, color: '#333', fontWeight: 'bold' }}>{i18n.t("TASKS_TITLE")}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Tabs value={this.state.value} onChange={this.handleChange}>

                  {this.props.userRoleId < 8 ?
                    <Tab label="MY TASKS" />
                    : null}
                  {this.props.userRoleId < 8 ?
                    <Tab label="MY ASSIGNMENTS" />
                    : null}
                  {this.props.userRoleId < 8 ?
                    <Tab label="MY GRADEBOOK" />
                    : null}
                  {this.props.userRoleId === 8 ?
                    <Tab label="ACTIVE" />
                    : null}
                  {this.props.userRoleId === 8 ?
                    <Tab label="COMPLETE" search />
                    : null}
                </Tabs>
              </Grid>

              {this.props.userRoleId === 8 ?
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    onChange={(evt) => this.onSearch(evt.target.value)} style={{ width: '100%' }}
                    placeholder="Search"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
                : null
              }

              {this.props.userRoleId < 8 && this.state.value === 0 ?
                <MyTask />
                : null}

              {this.props.userRoleId < 8 && this.state.value === 1 ?
                <MyAssignment />
                : null}

              {this.props.userRoleId < 8 && this.state.value === 2 ?
                <MyGradeBook />
                : null}

              {this.props.userRoleId === 8 && this.state.value === 0 ?
                <Active />
                : null}

              {this.props.userRoleId === 8 && this.state.value === 1 ?
                <Complete />
                : null}

            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TaskScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage,
    profileId: state.profile.profileId,
    schoolId: state.profile.schoolId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskScreen)));
