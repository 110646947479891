import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchIcon from '@mui/icons-material/Search';
import {
  Grid,
  InputBase,
  Paper,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

var timeout = null;

class MajorListScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      card: [],
      searchText: '',
      totalRows: 0,
      perPage: 50,
      pageNumber: 0,
      height: 100
    }

    this.onSearch = this.onSearch.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
  }

  updateDimensions() {

    let update_height = 0;
    update_height = window.innerHeight - 270;

    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.setState({
      isLoading: true
    })

    this.getData(this.state.searchText, this.state.perPage, this.state.pageNumber);
    this.updateDimensions();
  }

  getData(searchText, perPage, pageNumber) {

    fetch('https://api.tasselpathway.com/majors?search=' + searchText + '&per_page=' + perPage + '&page_number=' + pageNumber, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
            totalRows: responseJson.total_results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  onMajorClick(value) {
    this.props.history.push('/major/' + value);
  }

  renderMajors() {

    var majors = this.state.dataSource;

    if (majors !== undefined) {

      var content = majors.map((item, key) => {
        return (
          <TableRow hover key={key} style={{ cursor: 'pointer' }} onClick={() => this.onMajorClick(item.major_id)}>
            <TableCell>{item.major_name}</TableCell>
            <TableCell style={{ textAlign: 'center' }}>{this.hasDegree(item.degrees, 1) || this.hasDegree(item.degrees, 2)
              || this.hasDegree(item.degrees, 4) === true ? <FontAwesomeIcon icon={faCheck}
                style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
            <TableCell style={{ textAlign: 'center' }}>{this.hasDegree(item.degrees, 3) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
            <TableCell style={{ textAlign: 'center' }}>{this.hasDegree(item.degrees, 5) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
            <TableCell style={{ textAlign: 'center' }}>{this.hasDegree(item.degrees, 6) || this.hasDegree(item.degrees, 7) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
          </TableRow>
        )
      })

      return content;

    } else {
      return null;
    }

  }

  hasDegree(degrees, degreeId) {

    var result = false;

    if (degrees !== undefined) {

      degrees.map((item, key) => {
        if (item.degree_id === degreeId) {
          result = true;
        }
      })

    }

    return result;
  }

  onSearch(event) {

    var searchText = event.target.value;

    this.setState({
      searchText: searchText,
      pageNumber: 0
    })

    clearTimeout(timeout);

    var scope = this;
    timeout = setTimeout(function () {
      scope.getData(searchText, scope.state.perPage, scope.state.pageNumber)
    }, 1000);
  }

  onPageClick(event, newPage) {

    if (newPage > this.state.pageNumber) {
      this.onNextClick();
    } else {
      this.onPreviousClick();
    }
  }

  onPreviousClick() {
    var currentPage = this.state.pageNumber;

    if (currentPage > 0) {

      currentPage = currentPage - 1;

      this.setState({
        pageNumber: currentPage
      })
    }

    this.getData(this.state.searchText, this.state.perPage, currentPage);
  }

  onNextClick() {
    var currentPage = this.state.pageNumber;

    currentPage = currentPage + 1;

    this.setState({
      pageNumber: currentPage
    })

    this.getData(this.state.searchText, this.state.perPage, currentPage);
  }


  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Grid container style={styles.itemContainerStyle}>
          <Grid item xs={12} style={{ marginLeft: 100, marginRight: 100 }}>

            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
              <Grid item xs={6}>
                <Typography style={{ fontSize: 24, color: '#333', fontWeight: 'bold' }}>Majors</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 14, color: '#333', marginTop: 30, fontWeight: 'bold' }}>
                  Showing {this.state.totalRows} Results</Typography>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    onChange={this.onSearch} style={{ width: '100%' }}
                    placeholder="Search"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container>

              <Grid item xs={12}>

                <Grid container style={{ overflow: 'auto' }}>
                  <Paper style={{ marginTop: 10, orderRadius: 0, width: '100%' }}>
                    <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                      <Table className={classes.table} padding="dense" style={{ height: this.state.height }}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.head}></TableCell>
                            <TableCell style={{ textAlign: 'center' }} className={classes.head}><Typography style={{ fontSize: 14 }}>Certificate</Typography></TableCell>
                            <TableCell style={{ textAlign: 'center' }} className={classes.head}><Typography style={{ fontSize: 14 }}>Associate</Typography></TableCell>
                            <TableCell style={{ textAlign: 'center' }} className={classes.head}><Typography style={{ fontSize: 14 }}>Bachelor's</Typography></TableCell>
                            <TableCell style={{ textAlign: 'center' }} className={classes.head}><Typography style={{ fontSize: 14 }}>Graduate</Typography></TableCell>
                          </TableRow>
                        </TableHead>
                        {this.renderMajors()}
                      </Table>
                    </div>

                    <TablePagination
                      rowsPerPageOptions={[25]}
                      component="div"
                      rowsPerPage={this.state.perPage}
                      page={this.state.pageNumber}
                      count={this.state.totalRows}
                      backIconButtonProps={{
                        'aria-label': 'Previous Page',
                      }}
                      nextIconButtonProps={{
                        'aria-label': 'Next Page',
                      }}
                      onPageChange={this.onPageClick}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MajorListScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MajorListScreen));