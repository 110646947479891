
 import { GET_PROGRESS, SET_PROGRESS  } from "../actions";

 export const defaultState = {
     progress:0
 };
 
 export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case GET_PROGRESS:
             return Object.assign({}, state, {
             });
         case SET_PROGRESS:
             return Object.assign({}, state, {
                progress: action.progress
             });
         default:
             return state
     }
 }