import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import { setCareer } from '../../../redux/actions/career';

import Ability from "./ability/index";
import Activity from "./activity/index";
import DetailedActivity from "./detailed_activity/index";
import Knowledge from "./knowledge/index";
import Majors from "./majors/index";
import Related from "./related/index";
import Skill from './skills/index';
import Tasks from "./tasks/index";
import Video from "./video/index";
import Wage from "./wage/index";

import styles from './style';

class CareerScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dataSource: [],
            isFavorite: 0,
            collegeId: 0,
            careerName: '',
            value: 1
        }
    }

    componentDidMount() {
        this.getData(this.props.match.params.careerId);
    }

    componentWillReceiveProps(newProps) {
        this.getData(newProps.match.params.careerId);
    }

    getData(careerId) {

        fetch('https://api.tasselpathway.com/careers/' + careerId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        careerId: responseJson.results[0].career_id,
                        careerName: responseJson.results[0].career_name,
                        clusterId: responseJson.results[0].cluster_id,
                        clusterName: responseJson.results[0].cluster_name,
                        pathwayId: responseJson.results[0].pathway_id,
                        pathwayName: responseJson.results[0].pathway_name,
                        isFavorite: responseJson.results[0].is_favorite,
                        education: responseJson.results[0].education,
                        jobDescription: responseJson.results[0].job_description,
                        entryWage: responseJson.results[0].entry_wage,
                        medianWage: responseJson.results[0].median_wage,
                        experiencedWage: responseJson.results[0].experienced_wage,
                        logoImageUrl: responseJson.results[0].logo_image_url,
                        bannerImageUrl: responseJson.results[0].banner_image_url,
                        hasTasks: responseJson.results[0].has_tasks,
                        hasAbilities: responseJson.results[0].has_abilities,
                        hasSkills: responseJson.results[0].has_skills,
                        hasActivities: responseJson.results[0].has_activities,
                        hasKnowledge: responseJson.results[0].has_knowledge,
                        hasWage: responseJson.results[0].has_wage,
                    })

                    this.props.setCareer(
                        responseJson.results[0].career_id,
                        responseJson.results[0].career_name
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onFavoriteClick() {

        if (this.state.isFavorite === 0) {

            fetch('https://api.tasselpathway.com/profile_favorite_career', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                body: JSON.stringify({
                    career_id: this.props.match.params.careerId,
                    person_id: this.props.personId,
                }),
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then((responseJson) => {

                    this.setState({
                        isFavorite: 1
                    })

                })
                .catch((error) => {

                });

        } else {
            fetch('https://api.tasselpathway.com/profile_favorite_career', {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                body: JSON.stringify({
                    career_id: this.props.match.params.careerId,
                    person_id: this.props.personId,
                }),
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    this.setState({
                        isFavorite: 0
                    })
                })
                .catch((error) => {

                });
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>

                <Grid container style={styles.itemContainerStyle}>

                    <Grid item xs={1}></Grid>

                    <Grid item xs={10}>

                        <div style={{ height: 200, backgroundColor: '#e0e0e0' }}>
                            <img src={this.state.bannerImageUrl} style={{ width: '100%', height: 200, objectFit: 'cover' }} alt="" />
                        </div>

                        <Card>
                            <CardContent>

                                {this.props.taskId !== 0 && this.props.taskId !== undefined ?
                                    <div style={{ position: 'absolute', top: 210, height: 150, width: 150, borderRadius: 100, border: '2px solid #FFF' }}>
                                        <img src={this.state.logoImageUrl} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                    </div>
                                    :
                                    <div style={{ position: 'absolute', top: 200, height: 150, width: 150, borderRadius: 100, border: '2px solid #FFF' }}>
                                        <img src={this.state.logoImageUrl} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                    </div>
                                }

                                <div style={{ marginLeft: 160 }}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Grid container style={{ width: '100%' }}>
                                                <div style={{ float: 'left' }}>

                                                    {this.state.isFavorite === 1 ?
                                                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                                            <Typography style={{ fontSize: 22, fontWeight: 'bold', marginTop: 5 }}>{this.state.careerName}</Typography>
                                                            <IconButton style={{ marginTop: 5 }} color="primary" onClick={() => this.onFavoriteClick()}>
                                                                <FontAwesomeIcon icon={faHeart} />
                                                            </IconButton>
                                                        </div>
                                                        :

                                                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                                            <Typography style={{ fontSize: 22, fontWeight: 'bold', marginTop: 5 }}>{this.state.careerName}</Typography>
                                                            <IconButton style={{ marginTop: 5 }} onClick={() => this.onFavoriteClick()}>
                                                                <FontAwesomeIcon icon={faHeart} style={{ color: '#777' }} />
                                                            </IconButton>
                                                        </div>
                                                    }


                                                    <Typography style={{ fontSize: 16 }}>{this.state.jobDescription}</Typography>
                                                </div>
                                                <div style={{ float: 'right' }}>

                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid container style={{ width: '100%' }}>
                                                <Grid item xs={12}>
                                                    {this.state.entryWage !== 0 && this.state.experiencedWage !== 0 ?
                                                        <Typography style={{ fontSize: 22, fontWeight: 'bold', textAlign: 'right', marginTop: 10 }}>
                                                            <NumberFormat value={this.state.entryWage} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                            &nbsp;-&nbsp;
                                                            <NumberFormat value={this.state.experiencedWage} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                        </Typography>
                                                        : null}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {this.state.education !== "" ?
                                                        <Typography style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'right', marginTop: 10 }}>{this.state.education}</Typography>
                                                        : null}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <br style={{ clear: 'both' }} />
                                <div>
                                    <Typography style={{ marginTop: 5, fontSize: 12 }}></Typography>
                                </div>
                            </CardContent>
                        </Card>

                        <br />

                        <Grid container spacing={2}>

                            <Grid item xs={8}>

                                <Grid container spacing={2}>

                                    {/*
                                <Grid item md={12}>
                                    <Overview props={this.props} />
                                </Grid> */}

                                    {this.state.hasActivities === 1 ?
                                        <Grid item xs={12}>
                                            <Activity props={this.props} />
                                        </Grid>
                                        : null
                                    }
                                    {this.state.hasTasks === 1 ?
                                        <Grid item md={6}>
                                            <Tasks props={this.props} />
                                        </Grid>
                                        : null
                                    }

                                    <Grid item md={6}>
                                        <DetailedActivity props={this.props} />
                                    </Grid>

                                    {this.state.hasSkills === 1 ?
                                        <Grid item xs={6}>
                                            <Skill props={this.props} />
                                        </Grid>
                                        : null
                                    }

                                    {this.state.hasWage === 1 ?
                                        <Grid item xs={12}>
                                            <Wage props={this.props} />
                                        </Grid>
                                        : null
                                    }

                                    {this.state.hasAbilities === 1 ?
                                        <Grid item md={6}>
                                            <Ability props={this.props} />
                                        </Grid>
                                        : null
                                    }

                                    {this.state.hasKnowledge === 1 ?
                                        <Grid item md={6}>
                                            <Knowledge props={this.props} />
                                        </Grid>
                                        : null
                                    }

                                </Grid>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4}>

                                <Video props={this.props} />

                                <Related props={this.props} />

                                <Majors props={this.props} />

                            </Grid>

                        </Grid>

                        <Typography style={{ textAlign: 'center', marginTop: 20, fontSize: 8 }}>
                            This page includes information from <a href="https://www.onetcenter.org/">O*NET Resource Center</a>
                            by the U.S. Department of Labor, Employment and Training Administration (USDOL/ETA). Used under the
                            <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a> license.
                        </Typography>

                    </Grid>

                    <Grid item xs={1}></Grid>



                </Grid>

            </div>
        );
    }
}

CareerScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        token: state.auth.token,
        personId: state.auth.personId,
        taskId: state.task.taskId,
        userRoleId: state.auth.userRoleId,
        careerId: state.career.careerId
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCareer: (careerId, careerName) => { dispatch(setCareer(careerId, careerName)); },
        onLogout: () => { dispatch(logout()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerScreen));