
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BallotIcon from '@mui/icons-material/Ballot';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListIcon from '@mui/icons-material/List';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import SearchIcon from '@mui/icons-material/Search';
import WorkIcon from '@mui/icons-material/Work';
import {
  Alert,
  AppBar, Autocomplete,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu, MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import i18n from '../../../i18n';
import { setAlert } from '../../../redux/actions/alert';
import { login, logout } from '../../../redux/actions/auth';
import { setProfile } from '../../../redux/actions/profile';
import styles from './style';
import './styles.css';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const StyledMenu = withStyles({
  paper: {
    borderRadius: 3,
    marginTop: -10
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
  },
}))(MenuItem);

class HeaderBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logoImageUrl: '',
      researchOpen: false,
      isSwitchOpen: false,
      isSavingOpen: false,
      toProfile: false,
      districts: [],
      searchResults: [],
      allAlerts: [],
      alerts: 0,
      announcementAlerts: 0,
      open: true,
      alertArray: [],
      schoolId: '',
      gradeId: ''
    }

    this.onSwitchClose = this.onSwitchClose.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    this.getData()
  }

  getData() {

    this.setState({
      districtId: this.props.districtId
    })

    fetch('https://api.tasselpathway.com/persons/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            bannerImageUrl: responseJson.results[0].banner_image_url,
            logoImageUrl: responseJson.results[0].logo_image_url,
            gradeId: responseJson.results[0].grade_id,
            gradeName: responseJson.results[0].grade_name,
            districtId: responseJson.results[0].district_id,
            districtName: responseJson.results[0].district_name,
            schoolId: responseJson.results[0].schools[0].school_id,
            schoolName: responseJson.results[0].schools[0].school_name
          })
        }

        this.getMessages()
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      })


  }

  getMessages() {

    var alertType = []
    if (this.props.userRoleId !== 8) {

      var gradeId = null

      fetch('https://api.tasselpathway.com/alert/' + this.props.schoolId + '/' + gradeId + '/' + this.props.userRoleId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              allAlerts: responseJson.results
            })
            responseJson.results.map((item, key) => {
              alertType.push(item.alert_type)
              if (alertType.includes('Announcement') && alertType.includes('Urgent Alert')) {
                this.setState({
                  announcementAlert: 1,
                  alerts: 1
                })
                this.props.onAlert(1, 1)
              }
              else if (item.alert_type === 'Announcement') {
                this.setState({
                  announcementAlerts: 1
                })
                this.props.onAlert(1, 0)
              }
              else if (item.alert_type === 'Urgent Alert') {
                this.setState({
                  alerts: 1
                })
                this.props.onAlert(0, 1)
              }
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });
    } else {
      fetch('https://api.tasselpathway.com/alert/' + this.props.schoolId + '/' + this.props.gradeId + '/' + this.props.userRoleId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              allAlerts: responseJson.results
            })
            responseJson.results.map((item, key) => {
              alertType.push(item.alert_type)
              if (alertType.includes('Announcement') && alertType.includes('Urgent Alert')) {
                this.setState({
                  announcementAlert: 1,
                  alerts: 1
                })
                this.props.onAlert(1, 1)
              }
              else if (item.alert_type === 'Announcement') {
                this.setState({
                  announcementAlerts: 1
                })
                this.props.onAlert(1, 0)
              }
              else if (item.alert_type === 'Urgent Alert') {
                this.setState({
                  alerts: 1
                })
                this.props.onAlert(0, 1)
              }
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });
    }
  }

  getDistricts() {
    fetch('https://api.tasselpathway.com/districts/multi/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            districts: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      })
  }

  onDistrictClick() {

    this.getDistricts();

    this.setState({
      isSwitchOpen: true
    })
  }

  isReloading = () => {
    this.props.history.push('/')
    setTimeout(function () { window.location.reload() }, 4000);
    setTimeout(function () { this.setState({ isSavingOpen: false }) }, 5000);
  }


  onSwitchClick() {
    fetch('https://api.tasselpathway.com/districts/switch', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        district_id: this.state.districtId,
        person_id: this.props.personId,
      })
    })
      .then(response => { return response.json() })
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.props.onLogin(this.props.username, this.props.password, this.props.selectedLanguage)
          this.setState({
            isSwitchOpen: false,
            isSavingOpen: true
          })
        }
      })
      .catch((e) => {
        console.warn(e);
      });

    this.isReloading()
  }

  onSwitchClose() {
    this.setState({
      isSwitchOpen: false
    })
  }

  onSearch(event) {

    this.setState({
      searchText: event.target.value
    })

    if (event.target.value === "") {

      this.setState({
        isLoading: false,
        searchResults: []
      })
    } else {

      fetch('https://api.tasselpathway.com/search?search=' + event.target.value, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              searchResults: responseJson.results
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        })
    }
  }

  onSelect(item) {
    if (item.description === "Career") {
      this.props.history.push('/career/' + item.id);
    } else if (item.description === "School") {
      this.props.history.push('/college/' + item.id);
    } else if (item.description === "Person") {
      this.props.history.push('/profile/' + item.id);
    }

    this.setState({
      searchText: ''
    })
  }

  onLogoutClick() {
    this.props.onLogout();
    // this.props.onSetProfile(0, '', '', 0, '', 0, '', 0, '', 0);
    this.props.history.push('/');
    localStorage.clear();
  }

  handleClick = () => {
    this.setState(state => ({ researchOpen: !state.researchOpen }));
  };

  openAccountMenu(event) {
    this.setState({
      anchor: event.currentTarget
    })
  }

  closeAccountMenu() {
    this.setState({
      anchor: null
    })
  }

  onCloseAlert = () => {
    this.setState({
      alerts: 0
    })
  }

  setOpen = (alertId) => {
    var alertArray = this.state.alertArray
    alertArray.push(alertId)
    this.setState({
      alertArray: alertArray
    })
  }

  renderDistricts() {
    var content = this.state.districts.map((item, key) => {
      return (
        <MenuItem key={key} value={item.district_id}>{item.district_name}</MenuItem>
      )
    })

    return content;
  }

  renderTranslatedSearch() {
    const { classes } = this.props;
    if (this.props.selectedLanguage === "esp") {
      return (
        <Autocomplete
          options={this.state.searchResults}
          classes={{
            option: classes.option,
            popper: classes.popper
          }}
          freeSolo
          style={{
            marginLeft: 70, width: '100%', paddingRight: 10, marginTop: 3
          }} value={this.state.searchText}
          disableClearable
          getOptionLabel={option => option.name}
          renderOption={option => (

            <React.Fragment>

              <ListItem style={{ padding: 0, margin: 0 }} onClick={() => this.onSelect(option)}>
                <ListItemAvatar style={{ padding: 0, margin: 0 }}>
                  {option.logo_image_url === "" ?
                    <Avatar alt="MSU" style={{ height: 35, width: 35 }}
                      src={require('../../../assets/default_avatar.png')} />
                    :
                    <Avatar alt="MSU" style={{ height: 35, width: 35 }} src={option.logo_image_url} />
                  }
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 11 }} primary={option.name} secondary={option.description} />

              </ListItem>
            </React.Fragment>

          )}
          renderInput={params => (

            <TextField variant='standard'
              {...params} onChange={(event) => this.onSearch(event)}
              placeholder="Buscar"
              style={{
                width: '100%', paddingRight: 5,
                backgroundColor: '#FFF', fontSize: 15
              }}
              margin="normal"

              InputProps={{
                ...params.InputProps, disableUnderline: true, type: 'search', startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

          )}
        />
      )
    } else {
      return (
        <Autocomplete
          options={this.state.searchResults}
          classes={{
            option: classes.option,
            popper: classes.popper
          }}
          freeSolo
          style={{
            marginLeft: 70, width: '100%', paddingRight: 10, marginTop: 3
          }} value={this.state.searchText}
          disableClearable
          getOptionLabel={option => option.name}
          renderOption={option => (

            <React.Fragment>

              <ListItem style={{ padding: 0, margin: 0 }} onClick={() => this.onSelect(option)}>
                <ListItemAvatar style={{ padding: 0, margin: 0 }}>
                  {option.logo_image_url === "" ?
                    <Avatar alt="MSU" style={{ height: 35, width: 35 }}
                      src={require('../../../assets/default_avatar.png')} />
                    :
                    <Avatar alt="MSU" style={{ height: 35, width: 35 }} src={option.logo_image_url} />
                  }
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 11 }} primary={option.name} secondary={option.description} />

              </ListItem>
            </React.Fragment>

          )}
          renderInput={params => (
            <TextField size='small' variant='standard'
              {...params} onChange={(event) => this.onSearch(event)}
              placeholder="Search"

              style={{
                width: '100%', paddingRight: 5,
                backgroundColor: '#FFF', fontSize: 8, borderRadius: 2
              }}
              margin="normal"

              InputProps={{
                ...params.InputProps, disableUnderline: true, type: 'search', startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon style={{ fontSize: 18, color: '#23272E' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

          )}
        />
      )
    }
  }
  render() {
    const { classes, ...other } = this.props
    return (
      <div>

        <Dialog open={this.state.isSwitchOpen} fullWidth
          onClose={this.onSwitchClose} maxWidth="sm"
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onSwitchClose}>
            Switch Districts
          </DialogTitle>

          <DialogContent>

            <div>
              <FormControl className={classes.formControl}
                fullWidth>
                <InputLabel htmlFor="workType">Select District</InputLabel>
                <Select
                  value={this.state.districtId}
                  onChange={(event) => this.setState({ districtId: event.target.value })}
                  inputProps={{
                    name: 'workType',
                    id: 'workType',
                  }}>
                  <MenuItem disabled value={0}>Select District</MenuItem>
                  {this.renderDistricts()}
                </Select>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => this.onSwitchClick()}
              style={{ color: '#FFF', fontWeight: 'bold', marginBottom: 20, marginRight: 20 }}
              color="primary">
              Switch
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isSavingOpen}
          onClose={this.onSavingClose}
          maxWidth="sm"
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          style={{ borderRadius: 4 }}
        >

          <DialogTitle id="customized-dialog-title" onClose={this.onSwitchClose}>
            One second...
          </DialogTitle>

          <DialogContent>
            <Typography>
              We're reloading the app with new district information.
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ReactLoading type={"bubbles"} color='#CBE8DA' height={'30%'} width={'30%'} />
            </div>
          </DialogContent>
        </Dialog>

        <AppBar className="appBar" style={{ backgroundColor: '#1F3545', }}>
          <Toolbar>
            <Grid container>

              <Grid item xs={1}></Grid>

              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={2} style={{ position: 'absolute' }}>
                    <img style={{ height: 65, marginTop: 7, paddingBottom: 7 }}
                      src={require('../../../assets/logo_vertical_white.png')} alt="" />
                  </Grid>
                  <Grid item xs={10}>
                    {this.renderTranslatedSearch()}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row', marginTop: 13, flexWrap: 'nowrap', }}>

                <Button color="inherit" style={{ color: '#FFF', marginLeft: 20 }}
                  component={props => <Link to="/" {...props} />} >
                  <div className='navIcon'>
                    <Tooltip title="Home" aria-label="home" placement="bottom">
                      <DashboardIcon style={{ fontSize: 14 }} />
                    </Tooltip>
                  </div>
                  <div className='navText'>
                    <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>{i18n.t("NAVBAR_HOME")}</Typography>
                  </div>
                </Button>

                <Button color="inherit" style={{ color: '#FFF' }} component={props => <Link to={"/profile/" + this.props.personId} {...props} />}>
                  <div className='navIcon'>
                    <Tooltip title="Profile" aria-label="profile" placement="bottom">
                      <PersonIcon style={{ fontSize: 14 }} />
                    </Tooltip>
                  </div>
                  <div className='navText'>
                    <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>{i18n.t("NAVBAR_PROFILE")}</Typography>
                  </div>
                </Button>

                {this.props.userRoleId < 8 ?

                  <Button color="inherit" style={{ color: '#FFF' }}
                    component={props => <Link to="/students" {...props} />}>
                    <div className='navIcon'>
                      <Tooltip title="Students" aria-label="students" placement="bottom">
                        <PeopleIcon style={{ fontSize: 14 }} />
                      </Tooltip>
                    </div>
                    <div className='navText'>
                      <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>{i18n.t("NAVBAR_STUDENTS")}</Typography>
                    </div>
                  </Button>

                  : null}

                <Button color="inherit" style={{ color: '#FFF' }} component={props => <Link to="/tasks" {...props} />} >
                  <div className='navIcon'>
                    <Tooltip title="Tasks" aria-label="tasks" placement="bottom">
                      <ListIcon style={{ fontSize: 14 }} />
                    </Tooltip>
                  </div>
                  <div className='navText'>
                    <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>{i18n.t("NAVBAR_TASKS")}</Typography>
                  </div>
                </Button>

                {/* <Button color="inherit" style={{ color: '#FFF' }} component={props => <Link to="/tests" {...props} />} >
                  <div className='navIcon'>
                    <Tooltip title="Tests" aria-label="tests" placement="bottom">
                      <ListIcon style={{ fontSize: 14 }} />
                    </Tooltip>
                  </div>
                  <div className='navText'>
                    <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>{i18n.t("NAVBAR_TESTS")}</Typography>
                  </div>
                </Button> */}

                <Button color="inherit" style={{ color: '#FFF' }} component={props => <Link to="/colleges" {...props} />} >
                  <div className='navIcon'>
                    <Tooltip title="Colleges" aria-label="colleges" placement="bottom">
                      <AccountBalanceIcon style={{ fontSize: 14 }} />
                    </Tooltip>
                  </div>
                  <div className='navText'>
                    <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>{i18n.t("NAVBAR_COLLEGES")}</Typography>
                  </div>
                </Button>


                <Button color="inherit" style={{ color: '#FFF' }} component={props => <Link to="/careers" {...props} />} >
                  <div className='navIcon'>
                    <Tooltip title="Careers" aria-label="careers" placement="bottom">
                      <WorkIcon style={{ fontSize: 14 }} />
                    </Tooltip>
                  </div>
                  <div className='navText'>
                    <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>{i18n.t("NAVBAR_CAREERS")}</Typography>
                  </div>
                </Button>

                {/*
                <Button color="inherit" style={{ color: '#FFF' }} component={props => <Link to="/majors" {...props} />} >
                  <div className='navIcon'>
                    <Tooltip title="Majors" aria-label="majors" placement="bottom">
                      <WorkIcon style={{ fontSize: 14 }} />
                    </Tooltip>
                  </div>
                  <div className='navText'>
                    <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>{i18n.t("NAVBAR_MAJORS")}</Typography>
                  </div>
                </Button> */}

                {this.props.userRoleId < 8 ?

                  <Button color="inherit" style={{ color: '#FFF' }} component={props => <Link to="/reports" {...props} />}>
                    <div className='navIcon'>
                      <Tooltip title="Reports" aria-label="reports" placement="bottom">
                        <BarChartIcon style={{ fontSize: 14 }} />
                      </Tooltip>
                    </div>
                    <div className='navText'>
                      <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>Reports</Typography>
                    </div>
                  </Button>

                  : null}

                {this.props.userRoleId < 6 || this.props.userRoleId === 7 ?

                  <Button color="inherit" style={{ color: '#FFF' }} component={props => <Link to="/admin" {...props} />}>
                    <div className='navIcon'>
                      <Tooltip title="Admin" aria-label="admin" placement="bottom">
                        <BallotIcon style={{ fontSize: 14 }} />
                      </Tooltip>
                    </div>
                    <div className='navText'>
                      <Typography style={{ fontSize: 12, marginLeft: 10, color: '#FFF' }}>{i18n.t("NAVBAR_ADMIN")}</Typography>
                    </div>
                  </Button>

                  : null}

              </Grid>

              <Grid item xs={2} onClick={(event) => this.openAccountMenu(event)} style={{ marginTop: 2 }} className={classes.profileHover}>
                <Grid container>
                  <Grid item xs={2}>
                    {this.state.logoImageUrl === "" ?
                      <img className="navAvatar" style={{ height: 35, marginTop: 18, borderRadius: 50 }} src={require('../../../assets/default_avatar.png')} alt="" />
                      :
                      <img className="navAvatar" style={{ height: 35, marginTop: 18, borderRadius: 50 }} src={this.state.logoImageUrl} alt="" />
                    }
                  </Grid>
                  <Grid item xs={10}>
                    <h5 className="navNameText" style={{ color: '#FFF', fontSize: 13, letterSpacing: .8, marginLeft: 8, paddingTop: 5 }}>{this.props.firstName} {this.props.lastName}</h5>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1}></Grid>

              <StyledMenu

                id="customized-menu"
                anchorEl={this.state.anchor}
                keepMounted style={{ marginLeft: -45 }}
                open={Boolean(this.state.anchor)}
                onClose={() => this.closeAccountMenu()}>
                {this.props.hasMultiDistrict === 1 ?
                  <StyledMenuItem onClick={() => this.onDistrictClick()}>
                    <ListItemIcon>
                      <SchoolIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Switch Districts" />
                  </StyledMenuItem>
                  : null}
                <StyledMenuItem onClick={() => this.onLogoutClick()}>
                  <ListItemIcon>
                    <LockIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </StyledMenuItem>
              </StyledMenu>

            </Grid>

          </Toolbar>

        </AppBar>
        {this.state.allAlerts.length > 0 ?
          <div style={{ marginTop: 65, backgroundColor: '#EAEAEA', marginBottom: -60 }}>
            {this.state.allAlerts.map((item, key) => {
              if (item.alert_type === 'Announcement' && this.state.alertArray.includes(item.alert_id)) {
                return (
                  <Collapse key={key} in={false}>
                    <Alert style={{ margin: 10 }} severity="info"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => this.setOpen(item.alert_id)}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {item.alert_message}
                    </Alert>
                  </Collapse>
                )
              } else if (item.alert_type === 'Announcement' && !this.state.alertArray.includes(item.alert_id)) {
                return (
                  <Collapse key={key} in={true}>
                    <Alert style={{ margin: 10 }} severity="info"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => this.setOpen(item.alert_id)}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {item.alert_message}
                    </Alert>
                  </Collapse>
                )
              } else {
                return null
              }
            })
            }
            {this.state.allAlerts.map((item, key) => {

              if (item.alert_type === 'Urgent Alert' && this.state.alertArray.includes(item.alert_id)) {
                return (
                  <Collapse key={key} in={false}>
                    <Alert style={{ margin: 10 }} severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => this.setOpen(item.alert_id)}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {item.alert_message}
                    </Alert>
                  </Collapse>
                )
              } else if (item.alert_type === 'Urgent Alert' && !this.state.alertArray.includes(item.alert_id)) {
                return (
                  <Collapse key={key} in={true}>
                    <Alert style={{ margin: 10 }} severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => this.setOpen(item.alert_id)}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {item.alert_message}
                    </Alert>
                  </Collapse>
                )
              } else {
                return null
              }
            })
            }
          </div> : null}


      </div>
    )
  }
}
HeaderBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    password: state.auth.password,
    personId: state.auth.personId,
    profileId: state.profile.profileId,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    districtId: state.profile.districtId,
    districtName: state.profile.districtName,
    hasMultiDistrict: state.auth.hasMultiDistrict,
    selectedLanguage: state.language.selectedLanguage,
    gradeId: state.profile.gradeId,
    schoolId: state.profile.schoolId,
    announcementAlert: state.alert.announcementAlert
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAlert: (announcementAlert, urgentAlert) => { dispatch(setAlert(announcementAlert, urgentAlert)); },
    onLogin: (username, password, language) => { dispatch(login(username, password, language)); },
    onLogout: () => { dispatch(logout()); },
    onSetProfile: (profileId, firstName, lastName, gradeId, gradeName,
      districtId, districtName, schoolId, schoolName, profileRoleId, earlyGraduationProgram) => {
      dispatch(setProfile(profileId, firstName, lastName, gradeId, gradeName,
        districtId, districtName, schoolId, schoolName, profileRoleId, earlyGraduationProgram));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(HeaderBar)));