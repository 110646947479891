
  export default {

    containerStyle: {
        backgroundColor:'#eaeaea',
        height:'100%'
    },
    headerStyle: {
      backgroundColor: '#25D192'  
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: 10,
      marginLeft:10,
      marginRight:10,
      marginBottom:10,
      left: 0,
      right: 0,
    },
    root: {
      width:700
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: 10,
    }
  };