export default {
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    },

    deleteButton: {
        position: 'absolute',
        right: 80,
        top: 5,
    },
    dropzone: {
        width:150,
        backgroundColor:'#000'
    }
}

