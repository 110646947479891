//#region imports
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Card,
    CardActions,
    CardMedia, Chip,
    Dialog, DialogContent,
    DialogContentText,
    Grid,
    IconButton,
    ListItem,
    ListItemText,
    Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import Stepper from 'react-stepper-horizontal';
import { logout } from '../../../../redux/actions/auth';
import { setObjective, setResume, setResumeSections } from '../../../../redux/actions/resume_builder';
import styles from './style';

const steps = [
    { title: "Select Template" },
    { title: "Objective" },
    { title: "Customize" },
    { title: "Download" }
]

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class ResumeRequirementScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activeStep: 2,
            objectiveOpen: false,
            skillsOpen: false,
            addSkillsDialog: false,
            workExperienceOpen: false,
            honorsAndAwardsOpen: false,
            workBasedLearningOpen: false,
            serviceLearningOpen: false,
            openSkillsAdd: false,
            skillsDataSource: [],
            workExperienceDataSource: [],
            honorsAndAwardsDataSource: [],
            workBasedLearningDataSource: [],
            serviceLearningDataSource: [],
            allSuggestions: [],
            selectedResumeSections: [],
            resumeRecord: [],
            newSkill: '',
            objectiveSelected: false,
            skillsSelected: false,
            workExperienceSelected: false,
            honorsSelected: false,
            workSelected: false,
            serviceSelected: false
        }
    }

    componentDidMount() {

        if (this.props.isEditing) {
            this.getData()
            this.fetchResumeRecord()
                .then(resJson => {

                    if (resJson.rows.length > 0) {

                        this.setState({
                            resumeRecord: resJson.rows[0]
                        })

                        if (this.state.resumeRecord.skills) {
                            this.setState({
                                skillsSelected: true
                            })
                        }
                        if (this.state.resumeRecord.work_experience) {
                            this.setState({
                                workExperienceSelected: true
                            })
                        }
                        if (this.state.resumeRecord.honors_and_awards) {
                            this.setState({
                                honorsSelected: true
                            })
                        }
                        if (this.state.resumeRecord.work_based_learning) {
                            this.setState({
                                workSelected: true
                            })
                        }
                        if (this.state.resumeRecord.service_learning) {
                            this.setState({
                                serviceSelected: true
                            })
                        }
                    }
                })

        } else {
            this.getData()
        }
    }
    getData = () => {
        Promise.all([
            this.fetchSkills(),
            this.fetchWorkExperience(),
            this.fetchHonorsAndAwards(),
            this.fetchWorkBasedLearning(),
            this.fetchServiceLearning(),

        ]).then(res => {
            this.setState({
                skillsDataSource: res[0].results,
                workExperienceDataSource: res[1].results,
                honorsAndAwardsDataSource: res[2].results,
                workBasedLearningDataSource: res[3].results,
                serviceLearningDataSource: res[4].results,
                objectiveSelected: true
            })

        })
    }
    fetchResumeRecord = () => {
        return fetch('https://api.tasselpathway.com/resume/' + this.props.profileId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
    }

    fetchSuggestions = () => {
        fetch('https://api.tasselpathway.com/profile_suggest/2', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        allSuggestions: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    fetchSkills = () => {
        return fetch('https://api.tasselpathway.com/profile_skill/person/' + this.props.profileId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
    }

    fetchWorkExperience = () => {
        return fetch('https://api.tasselpathway.com/work_experience/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
    }

    fetchHonorsAndAwards = () => {
        return fetch('https://api.tasselpathway.com/profile_achievement/person/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
    }

    fetchWorkBasedLearning = () => {
        return fetch('https://api.tasselpathway.com/profile_work_learning/person/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
    }

    fetchServiceLearning = () => {
        return fetch('https://api.tasselpathway.com/profile_service/person/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
    }
    //#endregion
    //#region navigation
    handleStepChange = () => {
        let arr = []
        if (this.state.skillsSelected) {
            arr.push({ title: "Skills", component: 'SkillsScreen' })
        } else {
            if (arr.indexOf('SkillsScreen') < 0) {
                arr.filter((val) => val.title !== 'SkillsScreen')
            }
        }
        if (this.state.workExperienceSelected) {
            arr.push({ title: "Work Experience", component: 'WorkExperienceScreen' })
        } else {
            if (arr.indexOf('WorkExperienceScreen') < 0) {
                arr.filter((val) => val.title !== 'WorkExperienceScreen')
            }
        }
        if (this.state.honorsSelected) {
            arr.push({ title: "Achievements", component: 'AchievementScreen' })
        } else {
            if (arr.indexOf('AchievementsScreen') < 0) {
                arr.filter((val) => val.title !== 'AchievementsScreen')
            }
        }
        if (this.state.workSelected) {
            arr.push({ title: "Work", component: 'Work Based Learning' })
        } else {
            if (arr.indexOf('WorkScreen') < 0) {
                arr.filter((val) => val.title !== 'WorkScreen')
            }
        }
        if (this.state.serviceSelected) {
            arr.push({ title: "Service Learning", component: 'ServiceScreen' })
        } else {
            if (arr.indexOf('ServiceScreen') < 0) {
                arr.filter((val) => val.title !== 'ServiceScreen')
            }
        }
        this.props.onSetSections(
            arr,
            this.state.objectiveSelected,
            this.state.skillsSelected,
            this.state.workExperienceSelected,
            this.state.honorsSelected,
            this.state.workSelected,
            this.state.serviceSelected
        )

        this.props.history.push("/resume_builder_customize")
    }

    handleStepChangeBack = () => {

        var activeStep = this.state.activeStep;
        activeStep -= 1;

        this.setState({
            activeStep: activeStep
        })
    }

    addSectionToResume = (e) => {

        let sectionName = e.target.name
        this.setState({
            objectiveSelected: true,
            selectedResumeSections: this.state.sectionName.push(sectionName)
        })
    }
    openObjective = () => {
        this.setState({
            objectiveOpen: true
        })
    }
    closeObjective = () => {
        this.setState({
            objectiveOpen: false
        })
    }

    openSkills = () => {
        this.setState({
            skillsOpen: true
        })
    }

    closeSkills = () => {
        this.setState({
            skillsOpen: false
        }, this.getData())
    }
    openWorkExperience = () => {
        this.setState({
            workExperienceOpen: true
        })
    }
    closeWorkExperience = () => {
        this.setState({
            workExperienceOpen: false
        }, this.getData())
    }
    openHonorsAndAwards = () => {
        this.setState({
            honorsAndAwardsOpen: true
        })
    }
    closeHonorsAndAwards = () => {
        this.setState({
            honorsAndAwardsOpen: false
        }, this.getData())
    }
    openWorkBasedLearning = () => {
        this.setState({
            workBasedLearningOpen: true
        })
    }
    closeWorkBasedLearning = () => {
        this.setState({
            workBasedLearningOpen: false
        }, this.getData())
    }
    openServiceLearning = () => {
        this.setState({
            serviceLearningOpen: true
        })
    }
    closeServiceLearning = () => {
        this.setState({
            serviceLearningOpen: false
        }, this.getData())
    }

    renderSkills = () => {
        if (this.state.skillsDataSource.length > 0) {
            var content = this.state.skillsDataSource.map((item, key) => {

                return (
                    <Chip key={key} label={item.skill_name} onClick={this.onEditSkill}
                        style={{ margin: 5 }} />
                )
            })

            return content;

        } else {
            return (
                <Typography>
                    We couldn't find any data to satisfy this requirement please return to your profile to add Skills
                    otherwise this section will be blank on your resume.
                </Typography>
            )
        }
    }

    renderServices() {
        if (this.state.serviceLearningDataSource.length > 0) {
            var content = this.state.serviceLearningDataSource.map((item, key) => {
                return (
                    <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
                    >
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                                        className={this.props.classes.inline} color="textPrimary">
                                        {item.service_location} - {item.credit_hours} hours
                                    </Typography>
                                    <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.leadership_role_name} - {item.service_type_name}</Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                )
            })

            return content;

        } else {
            return (
                <Typography>We couldn't find any data to satisfy this requirement please return to your profile to add a Service Learning
                    otherwise this section will be blank on your resume.
                </Typography>
            )
        }
    }

    renderWorkExperience() {
        if (this.state.workExperienceDataSource.length > 0) {

            var content = this.state.workExperienceDataSource.map((item, key) => {

                return (
                    <ListItem key={key} button alignItems="flex-start"
                    >
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span" className={this.props.classes.inline} color="textPrimary">
                                        {item.place_of_work}
                                    </Typography>
                                    <Typography style={{ fontSize: 12, color: '#1F3545' }}>
                                        {item.position} - {moment(item.start_date).format('MM/DD/YYYY')} to {moment(item.end_date).format('MM/DD/YYYY')}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                )
            })

            return content;
        } else {
            return (
                <Typography>We couldn't find any data to satisfy this requirement please return to your profile to add a Work Experience or Work Based Learning
                    otherwise this section will be blank on your resume.
                </Typography>
            )
        }
    }

    renderAchievements() {
        if (this.state.honorsAndAwardsDataSource.length > 0) {
            var content = this.state.honorsAndAwardsDataSource.map((item, key) => {
                return (
                    <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
                    >
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                                        className={this.props.classes.inline} color="textPrimary">
                                        {item.achievement_name}
                                    </Typography>
                                    <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.achievement_type}</Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                )
            })

            return content;

        } else {
            return (
                <Typography>We couldn't find any data to satisfy this requirement please return to your profile to add Honors and Awards
                    otherwise this section will be blank on your resume.
                </Typography>
            )
        }
    }

    renderWorkBasedLearning() {
        if (this.state.workBasedLearningDataSource.length > 0) {
            var content = this.state.workBasedLearningDataSource.map((item, key) => {

                return (
                    <ListItem key={key} button alignItems="flex-start"
                    >
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                                        className={this.props.classes.inline} color="textPrimary">
                                        {item.work_learning_location} - {moment(item.work_learning_date).format('MM/DD/YYYY')}
                                    </Typography>
                                    <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.work_learning_type} - {item.pathway_name}</Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                )
            })

            return content;

        } else {
            return (
                <Typography>We couldn't find any data to satisfy this requirement please return to your profile to add a Work Experience / Work Based Learning
                    otherwise this section will be blank on your resume.
                </Typography>
            )
        }
    }
    //#endregion
    renderSkillsButton = () => {
        if (this.props.isEditing) {
            if (this.state.skillsSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ skillsSelected: false })}>
                            <Typography style={{ marginLeft: 55, color: 'white' }}>Skills</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ skillsSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 55, color: 'white' }}>Skills</Typography>
                        </Button>
                    </CardActions>
                )
            }
        } else {

            if (this.state.skillsSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ skillsSelected: false })}>
                            <Typography style={{ marginLeft: 55, color: 'white' }}>Skills</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ skillsSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 55, color: 'white' }}>Skills</Typography>
                        </Button>
                    </CardActions>
                )
            }
        }
    }

    renderWorkExperienceButtons = () => {
        if (this.props.isEditing) {
            if (this.state.workExperienceSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ workExperienceSelected: false })}>
                            <Typography style={{ marginLeft: 20, color: 'white' }}>Work Experience</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ workExperienceSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 20, color: 'white' }}>Work Experience</Typography>
                        </Button>
                    </CardActions>
                )
            }
        } else {

            if (this.state.workExperienceSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ workExperienceSelected: false })}>
                            <Typography style={{ marginLeft: 20, color: 'white' }}>Work Experience</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ workExperienceSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 20, color: 'white' }}>Work Experience</Typography>
                        </Button>
                    </CardActions>
                )
            }
        }
    }
    renderAchievementsButtons = () => {
        if (this.props.isEditing) {
            if (this.state.honorsSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ honorsSelected: false })}>
                            <Typography style={{ marginLeft: 20, color: 'white' }}>Honors & Awards</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ honorsSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 20, color: 'white' }}>Honors & Awards</Typography>
                        </Button>
                    </CardActions>
                )
            }
        } else {

            if (this.state.honorsSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ honorsSelected: false })}>
                            <Typography style={{ marginLeft: 20, color: 'white' }}>Honors & Awards</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ honorsSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 20, color: 'white' }}>Honors & Awards</Typography>
                        </Button>
                    </CardActions>
                )
            }
        }
    }
    renderWorkButtons = () => {
        if (this.props.isEditing) {
            if (this.state.workSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ workSelected: false })}>
                            <Typography style={{ marginLeft: 20, color: 'white' }}>Work Based Learning</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ workSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 20, color: 'white' }}>Work Based Learning</Typography>
                        </Button>
                    </CardActions>
                )
            }
        } else {

            if (this.state.workSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ workSelected: false })}>
                            <Typography style={{ marginLeft: 20, color: 'white' }}>Work Based Learning</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ workSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 20, color: 'white' }}>Work Based Learning</Typography>
                        </Button>
                    </CardActions>
                )
            }
        }
    }
    renderServiceLearningButtons = () => {
        if (this.props.isEditing) {
            if (this.state.serviceSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ serviceSelected: false })}>
                            <Typography style={{ marginLeft: 20, color: 'white' }}>Service Learning</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ serviceSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 20, color: 'white' }}>Service Learning</Typography>
                        </Button>
                    </CardActions>
                )
            }
        } else {

            if (this.state.serviceSelected) {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ serviceSelected: false })}>
                            <Typography style={{ marginLeft: 20, color: 'white' }}>Service Learning</Typography>
                        </Button>
                    </CardActions>
                )
            } else {
                return (
                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                        <Button size="small" name="objective" onClick={() => this.setState({ serviceSelected: true })}>
                            <Typography name="objective" style={{ marginLeft: 20, color: 'white' }}>Service Learning</Typography>
                        </Button>
                    </CardActions>
                )
            }
        }
    }
    render() {

        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    open={this.state.objectiveOpen}
                    onClose={this.closeObjective}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    maxWidth='sm'
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title" onClose={this.closeObjective} titleStyle={{ textAlign: "center" }}>Objective</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div style={{ display: 'flex', justifyContent: 'space-around', padding: 20 }}>
                                <Typography>{this.props.objectiveText}</Typography>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>


                <Dialog
                    open={this.state.skillsOpen}
                    onClose={this.closeSkills}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    maxWidth='sm'
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title" onClose={this.closeSkills} titleStyle={{ textAlign: "center" }}>Skills</DialogTitle>
                    <DialogContent style={{ marginTop: -10 }}>
                        {/* <SkillScreen /> */}
                        {this.renderSkills()}
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.serviceLearningOpen}
                    onClose={this.closeServiceLearning}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    maxWidth='sm'
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title" onClose={this.closeServiceLearning} titleStyle={{ textAlign: "center" }}>Service Learning</DialogTitle>
                    <DialogContent style={{ marginTop: -10 }}>
                        {/* <ServiceScreen /> */}
                        {this.renderServices()}
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.workExperienceOpen}
                    onClose={this.closeWorkExperience}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    maxWidth='sm'
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title" onClose={this.closeWorkExperience} titleStyle={{ textAlign: "center" }}>Work Experience</DialogTitle>
                    <DialogContent style={{ marginTop: -10 }}>
                        {/* <WorkExperienceScreen /> */}
                        {this.renderWorkExperience()}
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.honorsAndAwardsOpen}
                    onClose={this.closeHonorsAndAwards}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    maxWidth='sm'
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title" onClose={this.closeHonorsAndAwards} titleStyle={{ textAlign: "center" }}>Honors & awards</DialogTitle>
                    <DialogContent style={{ marginTop: -10 }}>
                        {/* <AchievementScreen /> */}
                        {this.renderAchievements()}
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.workBasedLearningOpen}
                    onClose={this.closeWorkBasedLearning}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    maxWidth='sm'
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title" onClose={this.closeWorkBasedLearning} titleStyle={{ textAlign: "center" }}>Work Based Learning</DialogTitle>
                    <DialogContent>
                        {/* <WorkScreen /> */}
                        {this.renderWorkBasedLearning()}
                    </DialogContent>
                </Dialog>


                <Grid container>

                    <Grid item xs={10}>
                        <Stepper
                            steps={steps}
                            activeColor={"#203446"}
                            completeColor={"#203446"}
                            activeStep={this.state.activeStep}
                        />
                    </Grid>

                    <Grid item xs={2}>


                        <Button variant={"contained"} style={styles.buttonStyle}
                            onClick={() => this.props.history.push("/resume_builder_objectives")}>
                            <Typography style={styles.buttonTextColor}>Back</Typography>
                        </Button>
                        <Button variant={"contained"} style={styles.buttonStyle} onClick={this.handleStepChange}>
                            <Typography style={styles.buttonTextColor}>Next</Typography>
                        </Button>


                    </Grid>

                </Grid>

                <div style={{ textAlign: 'center', marginTop: 35 }}>
                    <Typography>Great News! Here's what we gathered from your profile. Select the sections you wish to have on your resume</Typography>
                </div>
                <div style={{ width: '40%', marginTop: '3%', marginLeft: '31%', marginBottom: 20 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Card style={{ borderRadius: 3, marginTop: '5%', height: 180, width: 180, cursor: 'pointer' }} >

                                <CardMedia
                                    onClick={this.openObjective}
                                    image={require('../../../../assets/notes.png')}
                                    style={{ height: 100, width: 100, margin: 'auto', marginTop: 30 }}
                                />
                                {this.state.objectiveSelected ?
                                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#25D192' }}>
                                        <Button size="small" name="objective" onClick={() => this.setState({ objectiveSelected: true })}>
                                            <Typography style={{ marginLeft: 45, color: 'white' }}>Objective</Typography>
                                        </Button>
                                    </CardActions>
                                    :
                                    <CardActions style={{ width: '100%', marginTop: 10, backgroundColor: '#203446' }}>
                                        <Button size="small" name="objective" onClick={() => this.setState({ objectiveSelected: true })}>
                                            <Typography name="objective" style={{ marginLeft: 45, color: 'white' }}>Objective</Typography>
                                        </Button>
                                    </CardActions>
                                }
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card style={{ borderRadius: 3, marginTop: '5%', height: 180, width: 180, cursor: 'pointer' }} >
                                <CardMedia
                                    onClick={this.openSkills}
                                    image={require('../../../../assets/skills.png')}
                                    style={{ height: 100, width: 100, margin: 'auto', marginTop: 30 }}
                                />
                                {this.renderSkillsButton()}
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card style={{ borderRadius: 3, marginTop: '5%', height: 180, width: 180, cursor: 'pointer' }} >
                                <CardMedia
                                    onClick={this.openWorkExperience}
                                    image={require('../../../../assets/toolsnew.png')}
                                    style={{ height: 100, width: 100, margin: 'auto', marginTop: 30 }}
                                />

                                {this.renderWorkExperienceButtons()}
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Card style={{ borderRadius: 3, marginTop: '5%', height: 180, width: 180, cursor: 'pointer' }} >
                                <CardMedia
                                    onClick={this.openHonorsAndAwards}
                                    image={require('../../../../assets/trophy.png')}
                                    style={{ height: 100, width: 100, margin: 'auto', marginTop: 30 }}
                                />

                                {this.renderAchievementsButtons()}
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card style={{ borderRadius: 3, marginTop: '5%', height: 180, width: 180, cursor: 'pointer' }} >
                                <CardMedia
                                    onClick={this.openWorkBasedLearning}
                                    image={require('../../../../assets/suitcase.png')}
                                    style={{ height: 100, width: 100, margin: 'auto', marginTop: 30 }}
                                />
                                {this.renderWorkButtons()}
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card style={{ borderRadius: 3, marginTop: '5%', height: 180, width: 180, cursor: 'pointer' }} >
                                <CardMedia
                                    onClick={this.openServiceLearning}
                                    image={require('../../../../assets/help.png')}
                                    style={{ height: 100, width: 100, margin: 'auto', marginTop: 30 }}
                                />
                                {this.renderServiceLearningButtons()}

                            </Card>
                        </Grid>
                    </Grid>


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        objectiveText: state.resume_builder.objectiveText,
        profileId: state.profile.profileId,
        personId: state.auth.personId,
        token: state.auth.token,
        selectedLanguage: state.auth.selectedLanguage,
        resume_id: state.resume_builder.resume_id,
        isEditing: state.resume_builder.isEditing
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetResume: (objectiveText, skills, workExperience, honorsAndAwards, workBasedLearning, serviceLearning) => { dispatch(setResume(objectiveText, skills, workExperience, honorsAndAwards, workBasedLearning, serviceLearning)) },
        onSetObjectiveText: (objectiveText) => { dispatch(setObjective(objectiveText)) },
        onSetSections: (sections, objectiveSelected, skillsSelected, workExperienceSelected, achievementsSelected, workSelected, serviceSelected) => { dispatch(setResumeSections(sections, objectiveSelected, skillsSelected, workExperienceSelected, achievementsSelected, workSelected, serviceSelected)) },
        onLogout: () => { dispatch(logout()) }
    }
}

export default (connect(mapStateToProps, mapDispatchToProps))(withStyles(styles)(withRouter(ResumeRequirementScreen)))
