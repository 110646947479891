import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';

import Career from "./career/index";
import Info from "./info/index";
import Internship from "./internship/index";
import Opening from "./opening/index";

import styles from './style';

class EmployerScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dataSource: [],
            value: 1
        }
    }

    componentDidMount() {
        this.getData(this.props.match.params.employerId);
    }

    componentWillReceiveProps(newProps) {
        this.getData(newProps.match.params.employerId);
    }

    getData(employerId) {

        fetch('https://api.tasselpathway.com/employers/' + employerId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        employerId: responseJson.results[0].employer_id,
                        employerName: responseJson.results[0].employer_name,
                        address1: responseJson.results[0].address1,
                        city: responseJson.results[0].city,
                        stateName: responseJson.results[0].state_name,
                        webAddress: responseJson.results[0].web_address,
                        logoImageUrl: responseJson.results[0].logo_image_url,
                        bannerImageUrl: responseJson.results[0].banner_image_url,
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>


                <Grid container style={styles.itemContainerStyle}>

                    <Grid item xs={12} style={{ marginLeft: 100, marginRight: 100 }}>

                        <img src={this.state.bannerImageUrl} style={{ width: '100%', height: 200 }} alt="" />

                        <Card>
                            <CardContent>

                                <div style={{ position: 'absolute', top: 0, marginTop: 140, height: 150, width: 150, borderRadius: 100, border: '2px solid #FFF' }}>
                                    <img src={this.state.logoImageUrl} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                </div>
                                <div style={{ marginLeft: 160 }}>
                                    <div style={{ float: 'left' }}>
                                        <Typography style={{ fontSize: 26 }}>Ingram Barge Company</Typography>
                                        <Typography style={{ fontSize: 16 }}>Hickman, KY</Typography>
                                    </div>
                                    <div style={{ float: 'right' }}>

                                    </div>
                                </div>
                                <br style={{ clear: 'both' }} />
                                <div>
                                    <Typography style={{ marginTop: 5, fontSize: 12 }}></Typography>
                                </div>
                            </CardContent>
                        </Card>

                        <br />

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6} md={4}>
                                <Info props={this.props} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Career props={this.props} />
                            </Grid>


                            <Grid item xs={12} sm={6} md={4}>
                                <Internship props={this.props} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Opening props={this.props} />
                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>
            </div>
        );
    }
}

EmployerScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        token: state.auth.token,
        userRoleId: state.auth.userRoleId,
        employerId: state.employer.employerId
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmployerScreen));