import { faPlus, faPrint, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {
  Avatar,
  Button,
  Card, CardContent,
  Checkbox, Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { Document, Image, PDFViewer, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class InterestScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      value: 0,
      inventoryToDelete: 0,
      firstName: '',
      lastName: '',
      clusters: [],
      traits: [],
      recentTraits: [],
      subjects: [],
      recentSubjects: [],
      interests: [],
      dataSource: [],
      currentQuestion: 0,
      viewCurrentQuestion: 0,
      choices: [],
      totals: [],
      inventory: [],
      isNewOpen: false,
      isPrintOpen: false,
      isViewOpen: false,
      definition: '',
      isDefinitionOpen: false,
      onDeleteOpen: false,
      isEditOpen: false,
      hasIncompleteInventory: false,
      openPreviousPrompt: false,
      checkedAnswers: []
    }

    this.renderAnswers = this.renderAnswers.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onViewClose = this.onViewClose.bind(this);
    this.onPrintOpen = this.onPrintOpen.bind(this);
    this.onPrintClose = this.onPrintClose.bind(this);
    this.onClusterClick = this.onClusterClick.bind(this);
    this.onDefinitionOpen = this.onDefinitionOpen.bind(this);
    this.onDefinitionClose = this.onDefinitionClose.bind(this);
  }

  componentDidMount() {
    this.getData(this.props.profileId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(profileId) {

    this.setState({
      isLoading: true
    })

    if (profileId !== undefined) {

      fetch('https://api.tasselpathway.com/profile_interest_subject/recent/' + profileId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              recentSubjects: responseJson.results
            })
            this.props.onSubjectsLoaded(responseJson.results)
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });


      fetch('https://api.tasselpathway.com/profile_interest/person/' + this.props.profileId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              firstName: responseJson.results[0].first_name,
              lastName: responseJson.results[0].last_name,
              checkedAnswers: responseJson.results[0].details
            })

            if (responseJson.results[0].complete === 0) {
              this.setState({
                hasIncompleteInventory: true,
              })
            }
            else {
              this.setState({
                hasIncompleteInventory: false,
              })
            }
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });

      fetch('https://api.tasselpathway.com/profile_interest_trait/recent/' + profileId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              recentTraits: responseJson.results
            })
            this.props.onTraitsLoaded(this.state.recentTraits)
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });
    }
  }

  getInventory(inventoryId) {

    fetch('https://api.tasselpathway.com/profile_interest/' + inventoryId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            inventory: responseJson.results,
            viewCurrentQuestion: 0
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/profile_interest_cluster/' + inventoryId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            clusters: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/profile_interest_subject/' + inventoryId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            subjects: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/profile_interest_trait/' + inventoryId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            traits: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onPreviousClick() {
    if (this.state.currentQuestion > 0) {
      this.setState({
        currentQuestion: this.state.currentQuestion - 1
      })
    }
  }

  onNextClick() {
    if (this.state.currentQuestion + 1 < this.state.interests.length) {
      this.setState({
        currentQuestion: this.state.currentQuestion + 1
      })
    }
  }

  onViewPreviousClick() {
    if (this.state.viewCurrentQuestion > 0) {
      this.setState({
        viewCurrentQuestion: this.state.viewCurrentQuestion - 1
      })
    }
  }

  onViewNextClick() {
    if (this.state.viewCurrentQuestion + 1 < this.state.inventory.length) {
      this.setState({
        viewCurrentQuestion: this.state.viewCurrentQuestion + 1
      })
    }
  }

  onNewClick() {

    if (this.state.hasIncompleteInventory !== true) {
      var interestTypeId = 0;

      if (this.props.gradeId < 10) {
        interestTypeId = 2;
      } else {
        interestTypeId = 1;
      }

      fetch('https://api.tasselpathway.com/interests/' + interestTypeId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              interests: responseJson.results
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });

      this.setState({
        isNewOpen: true
      })

      // inputs new empty record

      var questions = [];
      var clusters = [];
      var i = 0;

      this.state.interests.map((group, key) => {
        group.questionTypes.map((type, key2) => {
          type.questions.map((question, key3) => {

            if (this.state.choices['question_' + question.question_id] === 1) {

              if (clusters[question.cluster_id] === undefined || clusters[question.cluster_id].value === 0) {
                clusters[question.cluster_id] = { cluster_id: question.cluster_id, cluster_name: question.cluster_name, value: 1 }
              } else {
                clusters[question.cluster_id].value = clusters[question.cluster_id].value + 1;
              }

              questions[i] = { cluster_id: question.cluster_id, question_id: question.question_id, question_name: question.question_name }
              i++;
            }
          })
        })
      })



      var clusterSorted = clusters.sort(function (a, b) {
        return b.value - a.value;
      }).filter(function () { return true });

      fetch('https://api.tasselpathway.com/profile_interest', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          person_id: this.props.profileId,
          interest_type_id: interestTypeId,
          is_complete: 0,
          grade_id: this.props.gradeId,
          questions: questions,
          clusters: clusterSorted

        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.getData(this.props.profileId);

          this.setState({
            isLoading: false,
            currentInterestId: responseJson.interest_id
          })

        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });
      // 

      // Gets newly inputed record
      fetch('https://api.tasselpathway.com/profile_interest/person/' + this.props.profileId, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });
      // 

    }
    else {
      this.setState({
        openPreviousPrompt: true
      })
    }
  }

  onEditClick = (item) => {

    var interestTypeId = 0;

    if (this.props.gradeId < 10) {
      interestTypeId = 2;
    } else {
      interestTypeId = 1;
    }

    fetch('https://api.tasselpathway.com/interests/' + interestTypeId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            interests: responseJson.results,
            openPreviousPrompt: false,
            currentInterestId: item.interest_id,
            isEditOpen: true
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onEditClose = () => {
    this.setState({
      isEditOpen: false
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  handleChange = (event, value) => {
    this.setState({
      value
    });
  }

  onResult(interest_id) {

    this.getInventory(interest_id);

    this.setState({
      isViewOpen: true
    })
  }

  handleDeleteOpen = (interest_id) => {
    this.setState({
      onDeleteOpen: true,
      inventoryToDelete: interest_id
    })
  }

  onViewClick(item) {

    this.getInventory(item.interest_id);

    this.setState({
      isViewOpen: true,
      currentInterestId: item.interest_id
    })
  }

  onViewClose() {
    this.setState({
      isViewOpen: false
    })
  }

  onFinishClick() {

    var questions = [];
    var clusters = [];
    var i = 0;

    this.state.interests.map((group, key) => {
      group.questionTypes.map((type, key2) => {
        type.questions.map((question, key3) => {

          if (this.state.choices['question_' + question.question_id] === 1) {

            if (clusters[question.cluster_id] === undefined || clusters[question.cluster_id].value === 0) {
              clusters[question.cluster_id] = { cluster_id: question.cluster_id, cluster_name: question.cluster_name, value: 1 }
            } else {
              clusters[question.cluster_id].value = clusters[question.cluster_id].value + 1;
            }

            questions[i] = { cluster_id: question.cluster_id, question_id: question.question_id, question_name: question.question_name }
            i++;
          }
        })
      })
    })

    var clusterSorted = clusters.sort(function (a, b) {
      return b.value - a.value;
    }).filter(function () { return true });


    fetch('https://api.tasselpathway.com/profile_interest', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        is_complete: 1,
        interest_id: this.state.currentInterestId
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isNewOpen: false,
          isEditOpen: false,
          isLoading: false,
          currentQuestion: 0,
          questions: [],
          clusters: [],
          choices: []
        })
        this.getData(this.props.profileId);
        if (responseJson.error === false) {
          this.onResult(responseJson.interest_id);
        }

      })
      .catch((error) => {
        this.setState({
          isNewOpen: false,
          isLoading: false,
          isEditOpen: false
        })
      });
  }

  onPostAnswer(choices, question_id) {

    var questions = [];
    var clusters = [];
    var i = 0;

    this.state.interests.map((group, key) => {
      group.questionTypes.map((type, key2) => {
        type.questions.map((question, key3) => {

          if (choices['question_' + question.question_id] === 1) {

            if (clusters[question.cluster_id] === undefined || clusters[question.cluster_id].value === 0) {
              clusters[question.cluster_id] = { cluster_id: question.cluster_id, cluster_name: question.cluster_name, value: 1 }
            } else {
              clusters[question.cluster_id].value = clusters[question.cluster_id].value + 1;
            }
            questions[i] = { cluster_id: question.cluster_id, question_id: question_id, question_name: question.question_name }
            i++;
          }
        })
      })
    })


    var singleQuestionPost = []
    var lastQuestionAnswered = questions[questions.length - 1]
    singleQuestionPost.push(lastQuestionAnswered)

    var clusterSorted = clusters.sort(function (a, b) {
      return b.value - a.value;
    }).filter(function () { return true });

    fetch('https://api.tasselpathway.com/profile_interest_click', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        interest_id: this.state.currentInterestId,
        questions: singleQuestionPost,
        clusters: clusterSorted
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.getData(this.props.profileId);
      })
  }

  onDeleteAnswer(choices, question_id) {

    fetch('https://api.tasselpathway.com/profile_interest_answer/' + question_id + '/' + this.state.currentInterestId, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      }
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.getData(this.props.profileId)
      })
  }

  onChoiceSelect(question, index, storedAnswersArray) {

    var questions = this.state.interests[this.state.currentQuestion];
    var choices = this.state.choices;

    questions.questionTypes[index].questions.map((item, key) => {

      if (item.question_id === question.question_id) {
        if (choices['question_' + item.question_id] === 1) {
          choices['question_' + item.question_id] = 0;
          this.onDeleteAnswer(choices, item.question_id)
        } else {
          choices['question_' + item.question_id] = 1;
          this.onPostAnswer(choices, item.question_id)
        }
      }
    })

    if (storedAnswersArray !== 0) {
      if (storedAnswersArray.includes(question.question_id)) {
        const index = storedAnswersArray.indexOf(question.question_id);
        if (index > -1) {
          storedAnswersArray.splice(index, 1);
        }
        this.setState({
          storedAnswersArray: storedAnswersArray
        })
        this.onDeleteAnswer(choices, question.question_id)
      }
    }

    this.setState({
      choices: choices,
      storedAnswersArray: storedAnswersArray
    })
  }

  onDefinitionOpen(event, item) {
    event.preventDefault();

    if (item.definition !== "") {

      this.setState({
        definitionAnchor: event.currentTarget,
        isDefinitionOpen: true,
        definition: item.definition
      })
    }
  }

  onDefinitionClose() {
    this.setState({
      isDefinitionOpen: false,
      definitionAnchor: null,
      definition: ''
    })
  }

  onPrintOpen(interest_id) {

    this.getInventory(interest_id);

    this.setState({
      isPrintOpen: true,
      currentInterestId: interest_id
    })
  }

  onPrintClose = () => {
    this.setState({
      isPrintOpen: false
    })
  }

  deleteInterestInventory(interest_id) {
    fetch('https://api.tasselpathway.com/profile_interest/' + interest_id, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": 'application/json',
        "token": this.props.token
      },
      credentials: 'omit'
    })
      .then(res => res.json())
      .then(responseJson => {
        if (responseJson.error === false) {
          this.getData(this.props.profileId)
          this.setState({ onDeleteOpen: false, inventoryToDelete: 0, openPreviousPrompt: false, hasIncompleteInventory: false, dataSource: [], choices: [] })
        }
      })
  }

  renderNewQuestions() {
    var question = this.state.interests[this.state.currentQuestion];

    if (question === undefined) return null;

    return (

      <div>

        <Grid container spacing={8}>

          <Grid item xs={12} style={{ marginBottom: 10, borderBottom: '1px solid #eaeaea' }}>
            {this.props.selectedLanguage === "esp" ?
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[0].question_type_name_spanish}
              </Typography>
              :
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[0].question_type_name}
              </Typography>
            }

            <Grid container style={{ padding: 20 }}>

              {
                question.questionTypes[0].questions.map((item, key) => {
                  if (this.props.selectedLanguage === "esp") {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 0, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name_spanish}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  } else {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 0, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  }
                })
              }

            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={8} style={{ marginTop: 10 }}>

          <Grid item xs={6} style={{ borderRight: '1px solid #eaeaea' }}>
            {this.props.selectedLanguage === "esp" ?
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[1].question_type_name_spanish}
              </Typography>
              :
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[1].question_type_name}
              </Typography>
            }

            <Grid container style={{ marginBottom: 10, padding: 20 }}>

              {
                question.questionTypes[1].questions.map((item, key) => {
                  if (this.props.selectedLanguage === "esp") {
                    return (
                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 1, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name_spanish}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 1, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  }
                })
              }

              <Popover
                id={'simple-popover'}
                open={this.state.isDefinitionOpen}
                anchorEl={this.state.definitionAnchor}
                onClose={this.onDefinitionClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <Typography style={{ padding: 20 }}>{this.state.definition}</Typography>
              </Popover>

            </Grid>

          </Grid>

          <Grid item xs={6}>
            {this.props.selectedLanguage === "esp" ?
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[2].question_type_name_spanish}
              </Typography>
              :
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[2].question_type_name}
              </Typography>
            }

            <Grid container style={{ marginBottom: 10, padding: 20 }}>

              {
                question.questionTypes[2].questions.map((item, key) => {
                  if (this.props.selectedLanguage === "esp") {
                    return (
                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 2, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name_spanish}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 2, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  }
                })
              }

            </Grid>

          </Grid>

        </Grid>

      </div>
    )
  }

  renderQuestions() {

    var question = this.state.interests[this.state.currentQuestion];
    var storedAnswers = this.state.checkedAnswers

    var i;
    var questionId = {}
    var storedAnswersArray = []
    for (i in storedAnswers) {
      questionId = storedAnswers[i]
      storedAnswersArray.push(Object.values(questionId)[0])
    }

    if (question === undefined) return null;
    return (
      <div>

        <Grid container spacing={8}>

          <Grid item xs={12} style={{ marginBottom: 10, borderBottom: '1px solid #eaeaea' }}>
            {this.props.selectedLanguage === "esp" ?
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[0].question_type_name_spanish}
              </Typography>
              :
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[0].question_type_name}
              </Typography>
            }

            <Grid container style={{ padding: 20 }}>

              {storedAnswersArray !== null ?
                question.questionTypes[0].questions.map((item, key) => {

                  if (this.props.selectedLanguage === "esp") {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 0, storedAnswersArray)}
                              checked={storedAnswersArray.includes(item.question_id)} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name_spanish}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  } else {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 0, storedAnswersArray)}
                              checked={storedAnswersArray.includes(item.question_id)} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  }
                })


                :
                question.questionTypes[0].questions.map((item, key) => {

                  if (this.props.selectedLanguage === "esp") {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 0, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name_spanish}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  } else {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 0, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  }
                })
              }

            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={8} style={{ marginTop: 10 }}>

          <Grid item xs={6} style={{ borderRight: '1px solid #eaeaea' }}>
            {this.props.selectedLanguage === "esp" ?
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[1].question_type_name_spanish}
              </Typography>
              :
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[1].question_type_name}
              </Typography>
            }

            <Grid container style={{ marginBottom: 10, padding: 20 }}>

              {storedAnswersArray !== null ?
                question.questionTypes[1].questions.map((item, key) => {

                  if (this.props.selectedLanguage === "esp") {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 1, storedAnswersArray)}
                              checked={storedAnswersArray.includes(item.question_id)} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name_spanish}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  } else {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 1, storedAnswersArray)}
                              checked={storedAnswersArray.includes(item.question_id)} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  }
                })


                :
                question.questionTypes[1].questions.map((item, key) => {

                  if (this.props.selectedLanguage === "esp") {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 1, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name_spanish}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  } else {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 1, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  }
                })
              }

              <Popover
                id={'simple-popover'}
                open={this.state.isDefinitionOpen}
                anchorEl={this.state.definitionAnchor}
                onClose={this.onDefinitionClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <Typography style={{ padding: 20 }}>{this.state.definition}</Typography>
              </Popover>

            </Grid>

          </Grid>

          <Grid item xs={6}>
            {this.props.selectedLanguage === "esp" ?
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[2].question_type_name_spanish}
              </Typography>
              :
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[2].question_type_name}
              </Typography>


            }

            <Grid container style={{ marginBottom: 10, padding: 20 }}>

              {storedAnswersArray !== null ?
                question.questionTypes[2].questions.map((item, key) => {

                  if (this.props.selectedLanguage === "esp") {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 2, storedAnswersArray)}
                              checked={storedAnswersArray.includes(item.question_id)} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name_spanish}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  } else {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 2, storedAnswersArray)}
                              checked={storedAnswersArray.includes(item.question_id)} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  }
                })


                :
                question.questionTypes[2].questions.map((item, key) => {

                  if (this.props.selectedLanguage === "esp") {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 2, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name_spanish}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  } else {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={() => this.onChoiceSelect(item, 2, 0)}
                              checked={this.state.choices['question_' + item.question_id] === 1 ? true : false} />
                          }

                          label={<Typography style={{ verticalAlign: 'middle', marginTop: 3 }} onClick={(event) => this.onDefinitionOpen(event, item)}>{item.question_name}
                            {item.definition !== "" ? <InfoIcon style={{ color: '#25D192', marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} /> : null}</Typography>}
                        />
                      </Grid>
                    )
                  }
                })
              }

            </Grid>

          </Grid>

        </Grid>

      </div>
    )
  }

  onClusterClick(item) {
    this.props.props.history.push({
      pathname: '/careers',
      state: { clusterId: item.cluster_id }
    })
  }

  renderClusters() {
    var rank = 0;

    var content = this.state.clusters.map((item, key) => {
      rank++;
      if (this.props.selectedLanguage === "esp") {
        return (

          <ListItem key={key} button style={{ height: 65, padding: 10 }}
            onClick={() => this.onClusterClick(item)}>
            <ListItemAvatar>
              <Avatar alt="MSU" style={{ height: 55, width: 55 }} src={item.logo_image_url} />
            </ListItemAvatar>
            <ListItemText style={{ fontSize: 16, marginLeft: 20, fontWeight: 'bold' }} primary={"#" + rank + " - " + item.cluster_name_spanish} />
          </ListItem>

        )
      } else {
        return (

          <ListItem key={key} button style={{ height: 65, padding: 10 }}
            onClick={() => this.onClusterClick(item)}>
            <ListItemAvatar>
              <Avatar alt="MSU" style={{ height: 55, width: 55 }} src={item.logo_image_url} />
            </ListItemAvatar>
            <ListItemText style={{ fontSize: 16, marginLeft: 20, fontWeight: 'bold' }} primary={"#" + rank + " - " + item.cluster_name} />
          </ListItem>

        )
      }
    })

    return content;
  }

  printClusters() {
    var rank = 0;

    var content = this.state.clusters.map((item, key) => {
      rank++;
      if (this.props.selectedLanguage === "esp") {
        return (
          <View key={key} style={{ flex: 1, display: 'flex', flexDirection: 'row', flexBasis: '40%' }}>
            <Text style={{ marginLeft: 5, fontSize: 11 }}>{"#" + rank + " - " + item.cluster_name_spanish}</Text>
          </View>
        )
      } else {
        return (
          <View key={key} style={{ flex: 1, display: 'flex', flexDirection: 'row', flexBasis: '40%' }}>
            <Text style={{ marginLeft: 5, fontSize: 11 }}>{"#" + rank + " - " + item.cluster_name}</Text>
          </View>
        )
      }
    })

    return content;
  }

  renderTraits() {
    var content = this.state.traits.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {
        return (
          <Chip key={key} label={item.cluster_name_spanish} style={{ margin: 5 }} />
        )
      } else {
        return (
          <Chip key={key} label={item.cluster_name} style={{ margin: 5 }} />
        )
      }
    })

    return content;
  }

  printTraits() {
    var content = this.state.traits.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {
        return (
          <Text style={{ fontSize: 12 }} key={key}>{item.cluster_name_spanish}</Text>
        )
      } else {
        return (
          <Text style={{ fontSize: 12 }} key={key}>{item.cluster_name}</Text>
        )
      }
    })

    return content;
  }

  renderSubjects() {
    var content = this.state.subjects.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {
        return (
          <Grid key={key} item xs={12} style={{ marginBottom: 20 }}>

            <div style={{ backgroundColor: '#25D192', padding: 10, color: '#FFF' }}>

              <Grid container >
                <Grid item xs={2}>
                  <b style={{ fontSize: 18 }}>{key + 1}</b>
                </Grid>
                <Grid item xs={10}>
                  <b>{item.cluster_name_spanish}</b>
                </Grid>
              </Grid>

            </div>

          </Grid>
        )
      } else {
        return (
          <Grid key={key} item xs={12} style={{ marginBottom: 20 }}>

            <div style={{ backgroundColor: '#25D192', padding: 10, color: '#FFF' }}>

              <Grid container >
                <Grid item xs={2}>
                  <b style={{ fontSize: 18 }}>{key + 1}</b>
                </Grid>
                <Grid item xs={10}>
                  <b>{item.cluster_name}</b>
                </Grid>
              </Grid>

            </div>

          </Grid>
        )
      }
    })

    return content;
  }

  printSubjects() {
    var content = this.state.subjects.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {
        return (
          <View key={key} style={{ marginBottom: 20 }}>

            <View style={{ backgroundColor: '#25D192', padding: 10, color: '#FFF' }}>

              <Text style={{ fontSize: 12 }}>{key + 1} {item.cluster_name_spanish}</Text>

            </View>

          </View>
        )
      } else {
        return (
          <View key={key} style={{ marginBottom: 20 }}>

            <View style={{ backgroundColor: '#25D192', padding: 10, color: '#FFF' }}>

              <Text style={{ fontSize: 12 }}>{key + 1} {item.cluster_name}</Text>

            </View>

          </View>
        )
      }
    })

    return content;
  }

  renderResults() {
    return (

      <div>

        <Grid container spacing={8}>

          <Grid item xs={12} style={{ borderBottom: '1px solid #eaeaea' }}>

            <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', fontWeight: 'bold' }}>
              {i18n.t("CAREER_CLUSTERS")}
            </Typography>

            <Grid container style={{ padding: 20 }}>
              <ImageList style={{ overflow: 'auto', height: 150 }}>
                {this.renderClusters()}
              </ImageList>

            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={8} style={{ marginTop: 10 }}>

          <Grid item xs={6} style={{ borderRight: '1px solid #eaeaea' }}>

            <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
              {i18n.t("PERSONALITY_TRAITS")}
            </Typography>

            <Grid container style={{ marginBottom: 10, padding: 20, overflow: 'auto', height: 200 }}>
              {this.renderTraits()}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
              {i18n.t("FAV_SUBJECTS")}
            </Typography>

            <Grid container style={{ marginBottom: 10, padding: 20, overflow: 'auto', height: 200 }}>
              {this.renderSubjects()}
            </Grid>
          </Grid>

        </Grid>

      </div>

    )
  }

  printResults() {

    return (

      <View style={{ marginTop: 30 }}>
        <View spacing={8}>
          <View>

            <Text style={{ fontSize: 14, color: '#25D192', textAlign: 'center', fontWeight: 'bold' }}>
              {i18n.t("CAREER_CLUSTERS")}
            </Text>

            <View style={{ padding: 20 }}>
              {this.printClusters()}
            </View>
          </View>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>

          <View style={{ flex: 1, flexBasis: '50%', borderRight: '1px solid #eaeaea' }}>

            <Text style={{ fontSize: 14, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
              {i18n.t("PERSONALITY_TRAITS")}
            </Text>

            <View container style={{ marginBottom: 10, padding: 20, overflow: 'auto', height: 200 }}>
              {this.printTraits()}
            </View>
          </View>

          <View style={{ flex: 1, flexBasis: '50%' }}>
            <Text style={{ fontSize: 14, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
              {i18n.t("FAV_SUBJECTS")}
            </Text>

            <View style={{ marginBottom: 10, padding: 20, overflow: 'auto', height: 200 }}>
              {this.printSubjects()}
            </View>
          </View>
        </View>
      </View>
    )
  }

  renderAnswers() {
    var question = this.state.inventory[this.state.viewCurrentQuestion];

    if (question === undefined) return null;

    return (

      <div>

        <Grid container spacing={8}>

          <Grid item xs={12} style={{ marginBottom: 10, borderBottom: '1px solid #eaeaea' }}>
            {this.props.selectedLanguage === "esp" ?
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[0].question_type_name_spanish}
              </Typography>
              :
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[0].question_type_name}
              </Typography>

            }

            <Grid container style={{ padding: 20 }}>

              {
                question.questionTypes[0].questions.map((item, key) => {
                  if (this.props.selectedLanguage === "esp") {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel key={key} disabled

                          control={
                            <Checkbox checked={item.is_checked === 1 ? true : false} />
                          }
                          label={item.question_name_spanish}
                        />
                      </Grid>
                    )
                  } else {
                    return (

                      <Grid key={key} item xs={6}>
                        <FormControlLabel key={key} disabled

                          control={
                            <Checkbox checked={item.is_checked === 1 ? true : false} />
                          }
                          label={item.question_name}
                        />
                      </Grid>
                    )
                  }

                })
              }

            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={8} style={{ marginTop: 10 }}>

          <Grid item xs={6} style={{ borderRight: '1px solid #eaeaea' }}>
            {this.props.selectedLanguage === "esp" ?
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[1].question_type_name_spanish}
              </Typography>
              :
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[1].question_type_name}
              </Typography>
            }
            <Grid container style={{ marginBottom: 10, padding: 20 }}>

              {
                question.questionTypes[1].questions.map((item, key) => {
                  if (this.props.selectedLanguage === "esp") {
                    return (
                      <Grid key={key} item xs={6}>
                        <FormControlLabel key={key} disabled
                          control={
                            <Checkbox checked={item.is_checked === 1 ? true : false} />
                          }
                          label={item.question_name_spanish}
                        />
                      </Grid>
                    )
                  } else {
                    return (
                      <Grid key={key} item xs={6}>
                        <FormControlLabel key={key} disabled
                          control={
                            <Checkbox checked={item.is_checked === 1 ? true : false} />
                          }
                          label={item.question_name}
                        />
                      </Grid>
                    )
                  }
                })
              }

            </Grid>

          </Grid>

          <Grid item xs={6}>
            {this.props.selectedLanguage === "esp" ?
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[2].question_type_name_spanish}
              </Typography>
              :
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.questionTypes[2].question_type_name}
              </Typography>
            }
            <Grid container style={{ marginBottom: 10, padding: 20 }}>

              {
                question.questionTypes[2].questions.map((item, key) => {
                  if (this.props.selectedLanguage === "esp") {
                    return (
                      <Grid key={key} item xs={6}>
                        <FormControlLabel key={key} disabled
                          control={
                            <Checkbox checked={item.is_checked === 1 ? true : false} />
                          }
                          label={item.question_name_spanish}
                        />
                      </Grid>
                    )
                  } else {

                    return (
                      <Grid key={key} item xs={6}>
                        <FormControlLabel key={key} disabled
                          control={
                            <Checkbox checked={item.is_checked === 1 ? true : false} />
                          }
                          label={item.question_name}
                        />
                      </Grid>
                    )
                  }
                })
              }

            </Grid>

          </Grid>


        </Grid>

      </div>

    )
  }

  printAnswers() {

    var content = this.state.inventory.map((question, key) => {

      return (

        <View key={key} break={(((key + 1) % 3 === 0 && key !== 0) || key === 2) ? true : false}>

          <View style={{ marginTop: 30 }}>

            <View item xs={12} style={{ borderBottom: '1px solid #eaeaea' }}>
              {this.props.selectedLanguage === "esp" ?
                <Text style={{ fontSize: 14, color: '#25D192', textAlign: 'center', fontWeight: 'bold' }}>
                  {question.questionTypes[0].question_type_name_spanish}
                </Text>
                :
                <Text style={{ fontSize: 14, color: '#25D192', textAlign: 'center', fontWeight: 'bold' }}>
                  {question.questionTypes[0].question_type_name}
                </Text>

              }

              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 20 }}>

                {
                  question.questionTypes[0].questions.map((item, key) => {
                    if (this.props.selectedLanguage === "esp") {
                      return (

                        <View key={key} style={{ flex: 1, display: 'flex', flexDirection: 'row', flexBasis: '40%' }}>
                          {item.is_checked === 1 ?
                            <Image src={require('../../../assets/checked.png')} style={{ width: 10, height: 10 }} />
                            :
                            <Image src={require('../../../assets/unchecked.png')} style={{ width: 10, height: 10 }} />
                          }
                          <Text style={{ marginLeft: 5, fontSize: 11 }}>{item.question_name_spanish}</Text>
                        </View>
                      )
                    } else {
                      return (

                        <View key={key} style={{ flex: 1, display: 'flex', flexDirection: 'row', flexBasis: '40%' }}>
                          {item.is_checked === 1 ?
                            <Image src={require('../../../assets/checked.png')} style={{ width: 10, height: 10 }} />
                            :
                            <Image src={require('../../../assets/unchecked.png')} style={{ width: 10, height: 10 }} />
                          }
                          <Text style={{ marginLeft: 5, fontSize: 11 }}>{item.question_name}</Text>
                        </View>
                      )
                    }

                  })
                }

              </View>
            </View>
          </View>

          <View spacing={8} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>

            <View item style={{ flex: 1, borderRight: '1px solid #eaeaea' }}>
              {this.props.selectedLanguage === "esp" ?
                <Text style={{ fontSize: 14, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                  {question.questionTypes[1].question_type_name_spanish}
                </Text>
                :
                <Text style={{ fontSize: 14, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                  {question.questionTypes[1].question_type_name}
                </Text>
              }
              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10, padding: 20 }}>

                {
                  question.questionTypes[1].questions.map((item, key) => {
                    if (this.props.selectedLanguage === "esp") {
                      return (
                        <View key={key} style={{ flex: 1, display: 'flex', flexDirection: 'row', flexBasis: '45%' }}>
                          {item.is_checked === 1 ?
                            <Image src={require('../../../assets/checked.png')} style={{ width: 10, height: 10 }} />
                            :
                            <Image src={require('../../../assets/unchecked.png')} style={{ width: 10, height: 10 }} />
                          }
                          <Text style={{ marginLeft: 5, fontSize: 11 }}>{item.question_name_spanish}</Text>
                        </View>
                      )
                    } else {
                      return (
                        <View key={key} style={{ flex: 1, display: 'flex', flexDirection: 'row', flexBasis: '45%' }}>
                          {item.is_checked === 1 ?
                            <Image src={require('../../../assets/checked.png')} style={{ width: 10, height: 10 }} />
                            :
                            <Image src={require('../../../assets/unchecked.png')} style={{ width: 10, height: 10 }} />
                          }
                          <Text style={{ marginLeft: 5, fontSize: 11 }}>{item.question_name}</Text>
                        </View>
                      )
                    }
                  })
                }

              </View>

            </View>

            <View style={{ flex: 1 }}>
              {this.props.selectedLanguage === "esp" ?
                <Text style={{ fontSize: 12, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                  {question.questionTypes[2].question_type_name_spanish}
                </Text>
                :
                <Text style={{ fontSize: 12, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                  {question.questionTypes[2].question_type_name}
                </Text>
              }
              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10, padding: 20 }}>

                {
                  question.questionTypes[2].questions.map((item, key) => {
                    if (this.props.selectedLanguage === "esp") {
                      return (
                        <View key={key} style={{ flex: 1, display: 'flex', flexDirection: 'row', flexBasis: '45%' }}>
                          {item.is_checked === 1 ?
                            <Image src={require('../../../assets/checked.png')} style={{ width: 10, height: 10 }} />
                            :
                            <Image src={require('../../../assets/unchecked.png')} style={{ width: 10, height: 10 }} />
                          }
                          <Text style={{ marginLeft: 5, fontSize: 11 }}>{item.question_name_spanish}</Text>
                        </View>
                      )
                    } else {

                      return (
                        <View key={key} style={{ flex: 1, display: 'flex', flexDirection: 'row', flexBasis: '45%' }}>
                          {item.is_checked === 1 ?
                            <Image src={require('../../../assets/checked.png')} style={{ width: 10, height: 10 }} />
                            :
                            <Image src={require('../../../assets/unchecked.png')} style={{ width: 10, height: 10 }} />
                          }
                          <Text style={{ marginLeft: 5, fontSize: 11 }}>{item.question_name}</Text>
                        </View>
                      )
                    }
                  })
                }

              </View>

            </View>

          </View>

        </View>
      )
    })

    return content;
  }

  printInventory() {
    return (
      <Document>

        <Page size="A4" style={styles.page}>

          <Text style={{ fontSize: 18, marginTop: 30, textAlign: 'center', fontWeight: 'bold' }} >
            {this.state.firstName + ' ' + this.state.lastName} - Interest Inventory Summary
          </Text>

          {this.printResults()}

        </Page>

        <Page size="A4" style={styles.page}>

          <Text style={{ fontSize: 18, marginTop: 30, textAlign: 'center', fontWeight: 'bold' }} >
            Interest Inventory Answers
          </Text>

          {this.printAnswers()}

        </Page>

      </Document>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>

        <Dialog
          fullScreen
          open={this.state.isPrintOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle onClose={this.onPrintClose} id="alert-dialog-title"
            titleStyle={{ textAlign: "center", marginBottom: 10 }}>Print or Download Below</DialogTitle>
          <PDFViewer style={{ minHeight: '100%' }}>
            {this.printInventory()}
          </PDFViewer>
        </Dialog>

        <Dialog onClose={this.onDeleteClose} aria-labelledby="customized-dialog-title" open={this.state.onDeleteOpen}>
          <DialogTitle id="customized-dialog-title" onClose={this.onDeleteClose}>
            <Typography style={{ fontSize: 18 }}>
              Delete Interest Inventory?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              If you wish to delete this you can do so by clicking delete
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ onDeleteOpen: false, inventoryToDelete: 0 })}>
              Cancel
            </Button>
            <Button onClick={() => this.deleteInterestInventory(this.state.inventoryToDelete)} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog onClose={this.onPromptClose} aria-labelledby="customized-dialog-title" open={this.state.openPreviousPrompt}>
          <DialogTitle id="customized-dialog-title" onClose={this.onPromptClose}>
            <Typography style={{ fontSize: 18 }}>
              Looks like you've started an interest inventory.
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              Please complete your incomplete interest inventory before starting a new one.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openPreviousPrompt: false, inventoryToDelete: 0 })}>
              Close
            </Button>
            {this.state.dataSource.map((item, key) => {
              return (
                <Button key={key} onClick={() => this.onEditClick(item)}>
                  Finish
                </Button>
              )
            })}
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} maxWidth="md"
          fullWidth={true}
          disableBackdropClick={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            {i18n.t("INTEREST_INVENTORY")}
          </DialogTitle>

          <DialogContent>
            {this.renderNewQuestions()}
          </DialogContent>

          <DialogActions>

            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>

              <Grid item xs={2}>

                {this.state.currentQuestion === 0 ? null :
                  <Button onClick={() => this.onPreviousClick()}
                    style={{ backgroundColor: '#25D192', marginLeft: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("BACK")}</Typography>
                  </Button>
                }

              </Grid>

              <Grid item xs={8} style={{ textAlign: 'center' }}>
                <Typography style={{ fontSize: 16, marginTop: 10, fontWeight: 'bold' }}>{this.state.currentQuestion + 1} / {this.state.interests.length}</Typography>
              </Grid>

              <Grid item xs={2} style={{ textAlign: 'right' }}>

                {this.state.currentQuestion + 1 === this.state.interests.length ?
                  <Button onClick={() => this.onFinishClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("FINISH")}</Typography>
                  </Button>
                  :
                  <Button onClick={() => this.onNextClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("NEXT")}</Typography>
                  </Button>
                }

              </Grid>

            </Grid>

          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} maxWidth="md"
          fullWidth={true}
          disableBackdropClick={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            {i18n.t("INTEREST_INVENTORY")}
          </DialogTitle>

          <DialogContent>
            {this.renderQuestions()}
          </DialogContent>

          <DialogActions>

            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>

              <Grid item xs={2}>

                {this.state.currentQuestion === 0 ? null :
                  <Button onClick={() => this.onPreviousClick()}
                    style={{ backgroundColor: '#25D192', marginLeft: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("BACK")}</Typography>
                  </Button>
                }

              </Grid>

              <Grid item xs={8} style={{ textAlign: 'center' }}>
                <Typography style={{ fontSize: 16, marginTop: 10, fontWeight: 'bold' }}>{this.state.currentQuestion + 1} / {this.state.interests.length}</Typography>
              </Grid>

              <Grid item xs={2} style={{ textAlign: 'right' }}>

                {this.state.currentQuestion + 1 === this.state.interests.length ?
                  <Button onClick={() => this.onFinishClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("FINISH")}</Typography>
                  </Button>
                  :
                  <Button onClick={() => this.onNextClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("NEXT")}</Typography>
                  </Button>
                }

              </Grid>

            </Grid>

          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isViewOpen}
          onClose={this.onViewClose} maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onViewClose}>
            {i18n.t("INTEREST_RESULTS")}
          </DialogTitle>

          <DialogContent>
            {this.props.selectedLanguage === "esp" ?
              <Tabs value={this.state.value} onChange={this.handleChange}>
                <Tab label="SUMARIO" />
                <Tab label="RESPUESTAS" />
              </Tabs>
              :
              <Tabs value={this.state.value} onChange={this.handleChange}>
                <Tab label="SUMMARY" />
                <Tab label="ANSWERS" />
              </Tabs>
            }

            {this.state.value === 0 && this.renderResults()}
            {this.state.value === 1 && this.renderAnswers()}
          </DialogContent>

          <DialogActions>

            {this.state.value === 1 ?

              <Grid container style={{ marginBottom: 20, marginTop: 20 }}>

                <Grid item xs={2}>

                  {this.state.viewCurrentQuestion === 0 ? null :
                    <Button onClick={() => this.onViewPreviousClick()}
                      style={{ backgroundColor: '#25D192', marginLeft: 10 }}>
                      <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("BACK")}</Typography>
                    </Button>
                  }

                </Grid>

                <Grid item xs={8} style={{ textAlign: 'center' }}>
                  <Typography style={{ fontSize: 16, marginTop: 10, fontWeight: 'bold' }}>{this.state.viewCurrentQuestion + 1} / {this.state.inventory.length}</Typography>
                </Grid>

                <Grid item xs={2} style={{ textAlign: 'right' }}>

                  {this.state.viewCurrentQuestion + 1 === this.state.inventory.length ?
                    null
                    :
                    <Button onClick={() => this.onViewNextClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                      <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("NEXT")}</Typography>
                    </Button>
                  }

                </Grid>

              </Grid>
              : null}

          </DialogActions>

        </Dialog>

        <Grid container spacing={2}>

          <Grid item xs={6}>

            <Card style={{ width: '100%', height: 375, overflow: 'scroll', borderRadius: 3 }}>
              <CardContent>

                <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("PERSONALITY_TRAITS")}</Typography>
                  </Grid>
                </Grid>

                <br />

                {this.state.recentTraits.length === 0 && this.props.profileId === this.props.personId ?
                  <Grid container direction="column" alignItems="center" justify="center" style={{ marginTop: 95 }}>
                    <Button color="primary" variant="contained" onClick={() => this.onNewClick()}>
                      <Typography style={{ fontWeight: 'bold', fontSize: 16, color: '#FFF' }}>{i18n.t("START_INTEREST")}</Typography>
                    </Button>
                  </Grid>
                  : null
                }

                {this.state.recentTraits.map((item, key) => {
                  if (this.props.selectedLanguage === "esp") {
                    return (
                      <Chip key={key} label={item.cluster_name_spanish} className={classes.chip} style={{ margin: 5 }} />
                    )
                  } else {
                    return (
                      <Chip key={key} label={item.cluster_name} className={classes.chip} style={{ margin: 5 }} />
                    )
                  }

                })}

              </CardContent>
            </Card>

          </Grid>

          <Grid item xs={6}>
            <Card style={{ width: '100%', height: 375, borderRadius: 3 }}>
              <CardContent>

                <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("FAV_SUBJECTS")}</Typography>
                  </Grid>
                </Grid>

                {this.state.recentSubjects.length === 0 && this.props.profileId === this.props.personId ?
                  <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ marginTop: 95 }}>
                    <Button color="primary" variant="contained" onClick={() => this.onNewClick()}>
                      <Typography style={{ fontWeight: 'bold', fontSize: 16, color: '#FFF' }}>{i18n.t("START_INTEREST")}</Typography>
                    </Button>
                  </Grid>
                  : null
                }

                <Grid container spacing={2}>

                  {this.state.recentSubjects.map((item, key) => {
                    if (this.props.selectedLanguage === "esp") {
                      return (
                        <Grid key={key} item xs={12}>

                          <div style={{ backgroundColor: '#25D192', padding: 10, color: '#FFF' }}>

                            <Grid container >
                              <Grid item xs={2}>
                                <b style={{ fontSize: 18 }}>{key + 1}</b>
                              </Grid>
                              <Grid item xs={10}>
                                <b>{item.group_name_spanish}</b>
                              </Grid>
                            </Grid>

                          </div>

                        </Grid>
                      )
                    } else {
                      return (
                        <Grid key={key} item xs={12}>

                          <div style={{ backgroundColor: '#25D192', padding: 10, color: '#FFF' }}>

                            <Grid container >
                              <Grid item xs={2}>
                                <b style={{ fontSize: 18 }}>{key + 1}</b>
                              </Grid>
                              <Grid item xs={10}>
                                <b>{item.group_name}</b>
                              </Grid>
                            </Grid>

                          </div>

                        </Grid>
                      )
                    }

                  })}

                </Grid>

              </CardContent>
            </Card>
          </Grid>

          {this.props.userRoleId !== 8 || this.props.profileId === this.props.personId ?

            <Grid item xs={12}>

              <Card style={{ width: '100%', height: 300, borderRadius: 3 }}>
                <CardContent>

                  <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                        {i18n.t("INTEREST_INVENTORY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      {this.props.profileId === this.props.personId ?
                        <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewClick()}>
                          <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                        </IconButton>
                        : null
                      }
                    </Grid>
                  </Grid>

                  {this.state.dataSource.length === 0 ?

                    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ marginTop: 75 }}>
                      {this.state.recentSubjects.length === 0 && this.props.profileId === this.props.personId ?
                        <Button color="primary" variant="contained" onClick={() => this.onNewClick()}>
                          <Typography style={{ fontWeight: 'bold', fontSize: 16, color: '#FFF' }}>{i18n.t("START_INTEREST")}</Typography>
                        </Button>
                        : null}
                    </Grid>

                    :

                    <ImageList cellHeight={60} style={{ maxHeight: 200, marginTop: 10, overflow: 'auto' }}>

                      {this.state.dataSource.map((item, key) => {
                        if (this.props.selectedLanguage === "esp") {
                          return (
                            <ListItem key={key} alignItems="flex-start" button onClick={() => this.onViewClick(item)}>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography style={{ fontSize: 14, marginTop: 5, fontWeight: 'bold' }} component="span"
                                      className={this.props.classes.inline} color="textPrimary">
                                      {item.interest_type_name_spanish}
                                    </Typography>
                                  </React.Fragment>
                                } secondary={
                                  <React.Fragment>
                                    <Typography style={{ fontSize: 12, marginTop: 5 }} component="span"
                                      className={this.props.classes.inline} color="textPrimary">
                                      {item.grade_name_spanish} {i18n.t("YEAR")} - {moment(item.date_created).format('MM/DD/YYYY')}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          )
                        } else {

                          if (item.complete === 1) {
                            return (
                              <ListItem key={key} alignItems="flex-start" style={{ width: 400 }} button onClick={() => this.onViewClick(item)}>
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography style={{ fontSize: 14, marginTop: 5, fontWeight: 'bold' }} component="span"
                                        className={this.props.classes.inline} color="textPrimary">
                                        {item.interest_type_name}
                                      </Typography>
                                    </React.Fragment>
                                  } secondary={
                                    <React.Fragment>

                                      <Typography style={{ fontSize: 12, marginTop: 5 }} component="span"
                                        className={this.props.classes.inline} color="textPrimary">
                                        {item.grade_name} {i18n.t("YEAR")} - {moment(item.date_created).format('MM/DD/YYYY')}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemSecondaryAction alignItems="flex-end" style={{ marginLeft: 20 }}>

                                  <IconButton onClick={() => this.onPrintOpen(item.interest_id)}>
                                    <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPrint} />
                                  </IconButton>

                                  <IconButton onClick={() => this.handleDeleteOpen(item.interest_id)}>
                                    <FontAwesomeIcon style={{ fontSize: 16 }} icon={faTrash} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          }
                          else {
                            return (
                              <ListItem key={key} alignItems="flex-start" style={{ width: 400 }} button onClick={() => this.onEditClick(item)}>
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography style={{ fontSize: 14, marginTop: 5, fontWeight: 'bold' }} component="span"
                                        className={this.props.classes.inline} color="textPrimary">
                                        {item.interest_type_name}
                                      </Typography>
                                    </React.Fragment>
                                  } secondary={
                                    <React.Fragment>

                                      <Typography style={{ fontSize: 12, color: '#64CD97', marginTop: 5 }} component="span"
                                        className={this.props.classes.inline}>
                                        Incomplete
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemSecondaryAction alignItems="flex-end" style={{ marginLeft: 20 }}>

                                  <IconButton onClick={() => this.onPrintOpen(item.interest_id)}>
                                    <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPrint} />
                                  </IconButton>

                                  <IconButton onClick={() => this.handleDeleteOpen(item.interest_id)}>
                                    <FontAwesomeIcon style={{ fontSize: 16 }} icon={faTrash} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>

                            )
                          }
                        }
                      })
                      }

                    </ImageList>

                  }
                </CardContent>
              </Card>
            </Grid>
            : null}
        </Grid>

      </div>
    );
  }
}

InterestScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    profileId: state.profile.profileId,
    gradeId: state.profile.gradeId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InterestScreen));