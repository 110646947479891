import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  Dialog, DialogActions, DialogContent, FormControl,
  FormControlLabel, FormGroup,
  Grid,
  IconButton,
  Input, InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead, TableRow,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class AnnouncementsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      activeStep: 0,
      allAlerts: [],
      isActive: 0,
      isEditOpen: false,
      selectedItem: {},
      schoolLength: 0,
      gradeLength: 0,
      schools: [],
      grades: [],
      schoolCheckList: [],
      gradeList: [],
      newSchoolDetails: [],
      schoolNameList: [],
      newDetails: [],
      checkList: [],
      gradeNameList: []
    }
  }

  componentDidMount() {
    this.setState({
      activeStep: 0
    })
    this.getData()
  }

  getData() {
    fetch('https://api.tasselpathway.com/allAlerts', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            allAlerts: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            schools: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  deleteAlert() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/alert', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        alert_id: this.state.selectedItem.alert_id,
        schoolLength: this.state.schoolLength,
        gradeLength: this.state.gradeLength,
        userRoleId: this.state.selectedItem.user_role_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isEditOpen: false,
          selectedItem: {},
          newSchoolDetails: [],
          newDetails: []
        })
        this.getData(this.props.personId);

        this.setState({
          isLoading: false
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  saveAlert(selectedItem) {

    fetch('https://api.tasselpathway.com/alert', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        alert_message: selectedItem.alert_message,
        is_active: selectedItem.is_active,
        alert_id: selectedItem.alert_id,
        schoolDetails: this.state.newSchoolDetails,
        details: this.state.newDetails
      })
    })
      .then(response => { return response.json() })
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          isEditOpen: false,
          newSchoolDetails: [],
          newDetails: []
        })
        this.getData();
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  onActiveChange(event, row) {

    var selectedItem = row;
    selectedItem.is_active = !selectedItem.is_active;

    this.setState({
      selectedItem: selectedItem
    })

    this.saveAlert(selectedItem);
  }

  onEditClick = (event, row) => {

    var newSchoolDetails = this.state.newSchoolDetails;
    var schoolCheckList = this.state.schoolCheckList;
    var gradeCheckList = this.state.checkList;
    var details = this.state.newDetails;

    row.schoolDetails.map((item, key) => {
      schoolCheckList['checked_' + item.school_id] = true
      newSchoolDetails.push({ school_id: item.school_id })
    })

    row.details.map((item, key) => {
      gradeCheckList['checked_' + item.grade_id] = true
      details.push({ grade_id: item.grade_id })
    })

    this.setState({
      isEditOpen: true,
      selectedItem: row,
      checkList: gradeCheckList,
      schoolCheckList: schoolCheckList,
      newSchoolDetails: newSchoolDetails,
      newDetails: details,
      schoolLength: row.schoolDetails.length,
      gradeLength: row.details.length
    })

  }

  onNewSchoolChange(event, schoolName) {

    var newSchoolDetails = this.state.newSchoolDetails;
    var schoolCheckList = this.state.schoolCheckList;
    var schoolId = event.target.value;
    var i = 0;
    var schoolNameArray = this.state.schoolNameList

    if (event.target.checked) {
      newSchoolDetails.push({ school_id: schoolId })
      schoolCheckList['checked_' + schoolId] = true;
      schoolNameArray.push(schoolName)
    } else {
      newSchoolDetails.map((item, key) => {
        if (item.school_id == schoolId) {
          newSchoolDetails.splice(i, 1);
          schoolCheckList['checked_' + schoolId] = false
          const index = schoolNameArray.indexOf(schoolName);
          if (index > -1) {
            schoolNameArray.splice(index, 1);
          }
        }
        i++;
      })
    }
    this.setState({
      checkList: schoolCheckList,
      newSchoolDetails: newSchoolDetails,
      schoolCheckList: schoolCheckList,
      schoolNameList: this.state.schoolNameList
    })
  }
  onNewGradeChange(event, gradeName) {

    var details = this.state.newDetails;
    var checkList = this.state.checkList;
    var gradeId = event.target.value;
    var i = 0;
    var gradeNameArray = this.state.gradeNameList


    if (event.target.checked) {
      details.push({ grade_id: gradeId })
      checkList['checked_' + gradeId] = true;
      gradeNameArray.push(gradeName)
    } else {
      details.map((item, key) => {
        if (item.grade_id == gradeId) {
          details.splice(i, 1);
          checkList['checked_' + gradeId] = false
          const index = gradeNameArray.indexOf(gradeName);
          if (index > -1) {
            gradeNameArray.splice(index, 1);
          }
        }
        i++;
      })
    }

    this.setState({
      checkList: checkList,
      newDetails: details,
      gradeNameList: gradeNameArray
    })
  }

  onEditClose = () => {
    this.setState({
      isEditOpen: false
    })
  }


  handleBackToCommunications = () => {
    this.props.history.push('/admin');
  }

  handleRedirectToCreate = () => {
    this.props.history.push('/createAnnouncementOrAlertScreen')
  }

  onActiveTypeChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.is_active = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onMessageTypeChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.alert_message = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  render() {

    const { classes } = this.props;

    return (

      <div className={classes.root}>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            Edit
          </DialogTitle>

          <DialogContent>

            <FormControl fullWidth className={classes.formControl}>
              <TextField autoFocus margin="dense" id="school_name"
                value={this.state.selectedItem.alert_message}
                onChange={(event) => this.onMessageTypeChange(event)}
                label="Message" type="text" />
            </FormControl>

            {this.state.schoolLength > 0 ?
              <>
                <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select School(s)</Typography>

                <FormGroup row style={{ marginLeft: 20 }}>

                  {this.state.schools.map((item, key) => {
                    return (
                      <FormControlLabel key={key} onChange={(event) => this.onNewSchoolChange(event, item.school_name)}
                        control={
                          <Checkbox value={item.school_id} checked={this.state.schoolCheckList['checked_' + item.school_id] ? true : false} />
                        }
                        label={item.school_name}
                      />
                    )
                  })}

                </FormGroup>
              </>
              : null}

            {this.state.gradeLength > 0 ?
              <>
                <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Grades</Typography>

                <FormGroup row style={{ marginLeft: 20 }}>

                  {this.state.grades.map((item, key) => {
                    return (
                      <FormControlLabel key={key} onChange={(event) => this.onNewGradeChange(event, item.grade_name)}
                        control={
                          <Checkbox value={item.grade_id} checked={this.state.checkList['checked_' + item.grade_id] ? true : false} />
                        }
                        label={item.grade_name}
                      />
                    )
                  })}

                </FormGroup>
              </>
              : null}

            <FormControl fullWidth style={{ marginTop: 5 }}
              className={classes.formControl}>
              <InputLabel>Message activation</InputLabel>
              <Select
                value={this.state.selectedItem.is_active} margin="dense"
                onChange={(event) => this.onActiveTypeChange(event)}
                input={<Input name="schoolTypeId" id="schoolTypeId" />}>
                <MenuItem value="0" disabled>Select if you want alert to be activated</MenuItem>
                <MenuItem value={0}>No</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
              </Select>
            </FormControl>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.deleteAlert()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 0, fontWeight: 'bold' }} color="primary">
              Delete
            </Button>
            <Button variant="contained" onClick={() => this.saveAlert(this.state.selectedItem)}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 0, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_SCHOOLS_SAVE_BUTTON")}
            </Button>
          </DialogActions>

        </Dialog>

        <div>

          <Grid container>

            <Grid item xs={2}>

              <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 3 }}
                onClick={() => this.handleRedirectToCreate()}>
                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>New Announcement</Typography>
              </Button>

            </Grid>
            <Grid item xs={6}>

            </Grid>

            <Grid item xs={4} style={{ textAlign: 'right' }}>

            </Grid>

          </Grid>

          <Paper style={{ borderRadius: 0, marginTop: 10 }}>

            <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
              <Table className={classes.table} style={{ height: this.state.height }}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Messages</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Alert Type</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Grades</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Schools</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>District</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>Active</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.allAlerts.map((row, index) => {
                    return (
                      <TableRow key={index} hover style={{ cursor: 'pointer' }}>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.alert_message}</Typography></TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.alert_type}</Typography></TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}>
                          {row.details.length > 0 ?
                            row.details.map((item) => {
                              return (
                                <Typography style={{ fontSize: 12 }}>{item.grade_name}</Typography>
                              )
                            })
                            : <Typography style={{ fontSize: 12 }}>N/A</Typography>}</TableCell>

                        <TableCell onClick={(event) => this.onEditClick(event, row)}>
                          {row.schoolDetails.length > 0 ?
                            row.schoolDetails.map((item) => {
                              return (
                                <Typography style={{ fontSize: 12 }}>{item.school_name}</Typography>
                              )
                            })
                            : <Typography style={{ fontSize: 12 }}>N/A</Typography>}</TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.district_name}</Typography></TableCell>
                        <TableCell>
                          <FormControlLabel
                            style={{ marginBottom: 10 }}
                            control={<Switch onChange={(event) => this.onActiveChange(event, row)} checked={row.is_active} color="primary" />}
                            label=""
                          /></TableCell>
                      </TableRow>
                    )
                  })
                  }
                </TableBody>
              </Table>
            </div>
          </Paper>
        </div>

      </div>
    );
  }
}

AnnouncementsScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage,
    personId: state.auth.personId,
    schoolId: state.profile.schoolId,
    schoolName: state.profile.schoolName,
    gradeId: state.profile.gradeId,
    gradeName: state.profile.gradeName,
    announcementAlert: state.alert.announcementAlert
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(AnnouncementsScreen))));