import { Button, Card, CardContent, TextField, Typography } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Stepper from 'react-stepper-horizontal';
import { logout } from '../../../../redux/actions/auth';
import { clearResumeData, setObjective } from '../../../../redux/actions/resume_builder';
import styles from './style';
const steps = [
    { title: "Select Template" },
    { title: "Objective" },
    { title: "Requirements" },
    { title: "Download" }
]

class ResumeBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            objectiveText: ''
        }
        this.handleStepChange = this.handleStepChange.bind(this)
    }

    componentDidMount() {


        if (this.props.isEditing) {
            if (this.props.objectiveText !== "") {
                this.setState({
                    objectiveText: this.props.objectiveText
                })
            } else {
                fetch("https://api.tasselpathway.com/resume/resume_objective/" + this.props.resumeId, {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "token": this.props.token
                    },
                    credentials: "omit",
                })
                    .then(res => res.json())
                    .then(responseJson => {

                        if (responseJson.error === false) {

                            if (responseJson.rows.length > 0) {
                                this.setState({
                                    objectiveText: responseJson.rows[0].objective_text
                                })
                            }
                        }
                    })
            }
        } else {
            this.setState({
                objectiveText: this.props.objectiveText !== '' ? this.props.objectiveText : ''
            })
        }
    }
    getResumeObjective = () => {

    }

    handleStepChange = () => {
        if (this.props.isEditing) {
            this.props.onSetObjectiveText(this.state.objectiveText)
            this.props.history.push({
                pathname: "/resume_builder_requirements",
                // state: {isEditing:true}
            })
        }
        this.props.onSetObjectiveText(this.state.objectiveText)
        this.props.history.push("/resume_builder_requirements")

    }

    onReturnToProfile = () => {

        this.props.history.push('/profile/' + this.props.personId);

    }

    handleObjectiveTextChange = (e) => {
        this.setState({
            objectiveText: e.target.value
        })
    }
    render() {

        return (
            <div>
                <Stepper
                    steps={steps}
                    activeColor={"#203446"}
                    completeColor={"#203446"}
                    activeStep={this.state.activeStep}
                />
                <div>
                    <Card style={{ borderRadius: 3, width: '60%', margin: 'auto', marginTop: '5%', height: 450 }}>
                        <CardContent style={{ cursor: 'pointer' }}>
                            <Typography>Objective</Typography>
                            <div style={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#c1c1c1', marginTop: 20 }}></div>
                            <TextField
                                id="standard-textarea"
                                placeholder="Type your objective here..."
                                multiline
                                InputProps={{ disableUnderline: true }}
                                margin="normal"
                                style={{ width: '97%', marginLeft: 5, marginTop: 30 }}
                                onChange={(e) => this.handleObjectiveTextChange(e)}
                                value={this.state.objectiveText}
                            />
                        </CardContent>
                    </Card>
                </div>

                <div style={styles.buttonContainer}>

                    <Button variant={"contained"} style={styles.buttonStyle} onClick={this.onReturnToProfile}>
                        <Typography style={styles.buttonTextColor}>Back</Typography>
                    </Button>
                    <Button variant={"contained"} style={styles.buttonStyle} onClick={this.handleStepChange}>
                        <Typography style={styles.buttonTextColor}>Next</Typography>
                    </Button>
                </div>
            </div>

        )
    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        token: state.auth.token,
        objectiveText: state.resume_builder.objectiveText,
        personId: state.auth.personId,
        isEditing: state.resume_builder.isEditing,
        resumeId: state.resume_builder.resumeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetObjectiveText: (objectiveText) => { dispatch(setObjective(objectiveText)) },
        onClearResumeData: () => { dispatch(clearResumeData()) },
        onLogout: () => { dispatch(logout()) }
    }
}

export default (connect(mapStateToProps, mapDispatchToProps))(withRouter(ResumeBuilder))
