import { GET_MAJOR, SET_MAJOR } from "../actions";

export const getMajor = () => {
   return {
       type: GET_MAJOR
   }
};

export const setMajor = (majorId, majorName) => {
    return {
        type: SET_MAJOR,
        majorId: majorId,
        majorName: majorName
    }
 };
