import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Avatar,
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List, ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class EducationScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      dataSource: [],
      colleges: [],
      grade_id: 0,
      selectedItem: {},
      isEditOpen: false,
      college_id: 0,
      degree: '',
      major: '',
      highlights: '',
      college_name: ''
    }

    this.onNewClose = this.onNewClose.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {

    this.getData(this.props.profileId);

  }

  getData(profileId) {

    this.setState({
      isLoading: true
    })

    fetch("https://api.tasselpathway.com/profile_education/" + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onSearch(event) {

    fetch('https://api.tasselpathway.com/colleges/profile?search=' + event.target.value, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            colleges: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  onNewClick() {

    fetch("https://api.tasselpathway.com/profile_education", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        person_id: this.props.personId,
        college_id: this.state.college_id,
        degree: this.state.degree,
        major: this.state.major,
        highlights: this.state.highlights
      })
    })
      .then(res => res.json())
      .then(responseJson => {

        if (responseJson.error === false) {
          this.setState({
            isNewOpen: false
          })
        }

        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onEditClick() {
    fetch("https://api.tasselpathway.com/profile_education", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        college_id: this.state.selectedItem.college_id,
        degree: this.state.selectedItem.degree,
        major: this.state.selectedItem.major,
        highlights: this.state.selectedItem.highlights,
        profile_education_id: this.state.selectedItem.profile_education_id

      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isEditOpen: false,
            isLoading: false,
          })
        }
        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onDeleteClick() {
    fetch("https://api.tasselpathway.com/profile_education", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        profile_education_id: this.state.selectedItem.profile_education_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isEditOpen: false,
            isLoading: false,
          })
        }
        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onNewOpen() {
    this.setState({
      isNewOpen: true
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onEditOpen(item) {
    this.setState({
      isEditOpen: true,
      selectedItem: item,
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onEditEducationChange(college_name) {
    var selectedItem = this.state.selectedItem;
    selectedItem.college_name = college_name;

    this.state.colleges.map((item, key) => {
      if (item.college_name === college_name) {
        this.setState({
          college_id: item.college_id,
          selectedItem: selectedItem
        })
      }
    })
  }

  onEditDegreeChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.degree = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onEditMajorChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.major = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onEditHighlightChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.highlights = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onCollegeChange(college_name) {

    this.state.colleges.map((item, key) => {
      if (item.college_name === college_name) {
        this.setState({
          college_id: item.college_id
        })
      }
    })
  }

  renderEducation() {

    var content = this.state.dataSource.map((item, key) => {
      return (

        <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false}
          onClick={() => this.onEditOpen(item)}>
          <ListItemAvatar>
            <Avatar alt="MSU" style={{ height: 55, width: 55 }} src={item.logo_image_url} />
          </ListItemAvatar>
          <ListItemText style={{ fontSize: 16, marginLeft: 15 }} primary={item.college_name} secondary={item.degree + " " + item.major} />
        </ListItem>
      )
    })

    return content;
  }

  renderTranslatedAddEducationFrom() {
    const { classes } = this.props
    if (this.props.selectedLanguage === "esp") {
      return (
        <>
          <Autocomplete
            options={this.state.colleges}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={option => option.college_name}
            renderOption={option => (
              <React.Fragment>
                <Avatar size="medium" style={{ marginRight: 15 }}
                  src={option.logo_aws_key} />
                {option.college_name}
              </React.Fragment>
            )}
            renderInput={(params, option) => (
              <TextField variant='standard' onChange={(event) => this.onSearch(event)}
                {...params}
                label="Elige una universidad"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled',
                }}
              />
            )}

            onChange={(e) => this.onCollegeChange(e.target.textContent)}
          />
          <TextField
            id="standard-name"
            label="Grado" fullWidth
            onChange={(e) => this.setState({ degree: e.target.value })}
            value={this.state.degree}
            margin="normal"
          />

          <TextField
            id="standard-name"
            label="Etudio de enfoque" fullWidth
            onChange={(e) => this.setState({ major: e.target.value })}
            value={this.state.major}
            margin="normal"
          />

          <TextField
            id="standard-name" multiline rows={6}
            label="Reflejos" fullWidth
            onChange={(e) => this.setState({ highlights: e.target.value })}
            value={this.state.highlights}
            margin="normal"
          />
        </>
      )
    } else {
      return (
        <>
          <Autocomplete
            options={this.state.colleges}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={option => option.college_name}
            renderOption={option => (
              <React.Fragment>
                <Avatar size="medium" style={{ marginRight: 15 }}
                  src={option.logo_aws_key} />
                {option.college_name}
              </React.Fragment>
            )}
            renderInput={(params, option) => (
              <TextField variant='standard' onChange={(event) => this.onSearch(event)}
                {...params}
                label="Choose a University"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled',
                }}
              />
            )}

            onChange={(e) => this.onCollegeChange(e.target.textContent)}
          />
          <TextField
            id="standard-name"
            label="Degree" fullWidth
            onChange={(e) => this.setState({ degree: e.target.value })}
            value={this.state.degree}
            margin="normal"
          />

          <TextField
            id="standard-name"
            label="Major" fullWidth
            onChange={(e) => this.setState({ major: e.target.value })}
            value={this.state.major}
            margin="normal"
          />

          <TextField
            id="standard-name" multiline rows={6}
            label="Highlights" fullWidth
            onChange={(e) => this.setState({ highlights: e.target.value })}
            value={this.state.highlights}
            margin="normal"
          />
        </>
      )
    }
  }

  renderTranslatedEditForm() {
    const { classes } = this.props
    if (this.props.selectedLanguage === "esp") {
      return (
        <>
          <Autocomplete
            options={this.state.colleges}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            value={this.state.selectedItem.college_name}
            getOptionLabel={option => this.state.selectedItem.college_name}
            renderOption={option => (
              <React.Fragment>
                <Avatar size="medium" style={{ marginRight: 15 }}
                  src={option.logo_aws_key} />
                {option.college_name}
              </React.Fragment>
            )}
            renderInput={(params, option) => (
              <TextField variant='standard' onChange={(event) => this.onSearch(event)}
                {...params}
                label="Elige una universidad"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled',
                }}
              />
            )}

            onChange={(e) => this.onCollegeChange(e.target.textContent)}
          />
          <TextField
            id="standard-name"
            label="Grado" fullWidth
            onChange={(event) => this.onEditDegreeChange(event)}
            value={this.state.selectedItem.degree}
            margin="normal"
          />

          <TextField
            id="standard-name"
            label="Etudio de enfoque" fullWidth
            onChange={(event) => this.onEditMajorChange(event)}
            value={this.state.selectedItem.major}
            margin="normal"
          />

          <TextField
            id="standard-name" multiline rows={6}
            label="Reflejos" fullWidth
            onChange={(event) => this.onEditHighlightChange(event)}
            value={this.state.selectedItem.highlights}
            margin="normal"
          />
        </>
      )
    } else {
      return (
        <>
          <Autocomplete
            options={this.state.colleges}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            value={this.state.selectedItem.college_name}
            getOptionLabel={option => this.state.selectedItem.college_name}
            renderOption={option => (
              <React.Fragment>
                <Avatar size="medium" style={{ marginRight: 15 }}
                  src={option.logo_image_url} />
                {option.college_name}
              </React.Fragment>
            )}
            renderInput={(params, option) => (
              <TextField variant='standard'
                {...params} onChange={(event) => this.onSearch(event)}
                label="Choose a University"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled',
                }}
              />
            )}

            onChange={(e) => this.onEditEducationChange(e.target.textContent)}
          />

          <TextField fullWidth
            id="standard-name"
            label="Degree"
            onChange={(event) => this.onEditDegreeChange(event)}
            value={this.state.selectedItem.degree}
            margin="normal"
          />

          <TextField fullWidth
            id="standard-name"
            label="Major"
            onChange={(event) => this.onEditMajorChange(event)}
            value={this.state.selectedItem.major}
            margin="normal"
          />

          <TextField fullWidth multiline rows={6}
            id="standard-name"
            label="Highlights"
            onChange={(event) => this.onEditHighlightChange(event)}
            value={this.state.selectedItem.highlights}
            margin="normal"
          />
        </>
      )
    }
  }


  render() {
    const { classes } = this.props;
    return (

      <div className={classes.root}>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            {i18n.t("ED_TILE_ADD_TITLE")}
          </DialogTitle>

          <DialogContent>

            <Grid container>

              <Grid item xs={12}>
                {this.renderTranslatedAddEducationFrom()}
              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 20, borderRadius: 4, fontWeight: 'bold', color: '#FFF' }}
              onClick={() => this.onNewClick()} color="primary">
              {i18n.t("ED_TILE_ADD")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            {i18n.t("ED_EDIT_EDUCATION")}
          </DialogTitle>

          <DialogContent>

            <Grid container>

              <Grid item xs={12}>
                {this.renderTranslatedEditForm()}
              </Grid>

            </Grid>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 5, borderRadius: 0, fontWeight: 'bold', color: '#FFF' }}
              onClick={() => this.onDeleteClick()} color="secondary">
              {i18n.t("ED_EDIT_DELETE")}
            </Button>
            <Button variant="contained" style={{ marginRight: 20, borderRadius: 0, fontWeight: 'bold', color: '#FFF' }}
              onClick={() => this.onEditClick()} color="primary">
              {i18n.t("ED_EDIT_SAVE")}
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>

              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{
                    fontSize: 18, fontWeight: 'bold', marginTop: 10,
                    color: '#4D4D4F', marginBottom: 20
                  }}>{i18n.t('TEACHER_PROFILE_EDUCATION_TILE')}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.renderEducation()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

EducationScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EducationScreen)));