export default {

  containerStyle: {
      backgroundColor:'#eaeaea',
      height:'100%'
  },
  itemContainerStyle: {
      width:'100%',
      height:'100%'
  },
  headerStyle: {
    backgroundColor: '#25D192'  
  },
  optionsView: {
      flexDirection: 'row',
      width: '100%',
      paddingBottom: 10,
  },
  optionsFlex: {
      flex: 9
  },
  optionsText: {
      fontWeight: 'bold',
      fontSize: 16,
      color: '#499CB3'
  },
  borderStyle: {
      flexDirection: 'row',
      paddingBottom: 10,
      marginTop: 10,
      width: '100%',
      borderBottomColor: '#eaeaea',
      borderBottomWidth: 1,
  },
  dateFlex: {
      flex: 5,
  },
  dateText: {
      textAlign: 'right',
  },
  emptyView: {
      flex: 1,
  },
  editText: {
      fontWeight: 'bold',
      color: '#E68846',
  },
  topBar: {
    backgroundColor: '#FFF',
    paddingBottom:15,
    borderBottom:'1px solid #eaeaea'
  },
  closeButton: {
      position: 'absolute',
      right: 10,
      top: 20
  },
};