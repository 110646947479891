import { GET_MAJOR, SET_MAJOR } from "../actions";

export const defaultState = {
     majorId: 0,
     majorName: '',
};
 
export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case GET_MAJOR:
             return Object.assign({}, state, {
             });
         case SET_MAJOR:
             return Object.assign({}, state, {
                majorId: action.majorId,
                majorName: action.majorName
             });
         default:
             return state
     }
 }