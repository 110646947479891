import WarningIcon from '@mui/icons-material/Warning';
import { Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './style.js';

class ForgotScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            isSent: false,
            invalidUserName: false
        };
    }

    forgotPassword() {

        fetch('https://api.tasselpathway.com/forgotPassword', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_name: this.state.username
            })
        })
            .then(response => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        invalidUserName: false,
                        isSent: true
                    })
                } else {
                    this.setState({
                        invalidUserName: true,
                        isSent: false
                    })
                }
            })
            .catch((e) => {
                console.warn(e);
            });

    }

    getBackgroundImage() {

    }

    renderLogoImage() {
        return null;
    }

    handleChange = prop => event => {
        this.setState({
            [prop]: event.target.value,
            invalidUserName: false
        });
    };

    render() {

        const { classes } = this.props;

        if (this.state.isSent === true) {
            return (
                <Grid container spacing={0}
                    direction="column" alignItems="center"
                    justify="center" style={{ backgroundColor: '#1F3545' }}>

                    <Grid style={styles.container}>

                        <Grid style={{ justifyContent: 'center', alignItems: 'center' }}>
                            {this.renderLogoImage()}
                        </Grid>

                        <h2 style={{ textAlign: 'center', fontSize: 39, color: '#25D192' }}><img width="120" src={require('../../assets/logo_icon.png')} alt="Tassel" /></h2>

                        <h3 style={{ color: '#2A2042', fontSize: 24 }}>Check Your Email</h3>

                        <h4>We've sent your password reset link to</h4>
                        <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>{this.state.username}</h4>

                        <p>If you don't receive it in the next few minutes, check your email junk folder or <br /><br />
                            <Link style={{ color: '#2A2042', fontWeight: 'bold' }} to={"/forgot"}>enter your email again.</Link>
                        </p>

                        <Grid style={{ marginTop: 30, padding: 10 }}>
                            <Link style={{ color: '#2A2042', fontWeight: 'bold', textAlign: 'center' }} to={"/"}>Back to Sign In</Link>
                        </Grid>

                    </Grid>

                    <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, color: '#FFF' }}>
                        © 2019 Tassel, LLC | <a style={{ color: '#FFF' }} target="_blank" without rel="noopener noreferrer" href="https://www.tasselpathway.com/terms">Terms of Use</a>
                    </Grid>

                </Grid>
            )
        } else {

            return (
                <Grid container spacing={2}
                    direction="column" alignItems="center"
                    justify="center" style={{ backgroundColor: '#1F3545' }}>

                    <Grid style={styles.container}>

                        <Grid style={{ justifyContent: 'center', alignItems: 'center' }}>
                            {this.renderLogoImage()}
                        </Grid>

                        <h2 style={{ textAlign: 'center', fontSize: 39, color: '#25D192' }}><img width="120" src={require('../../assets/logo_icon.png')} alt="Tassel" /></h2>

                        <h3 style={{ color: '#2A2042', fontSize: 24 }}>Forgot your password?</h3>

                        <h4>We'll send you an email with instructions to reset it.</h4>

                        <form style={{ marginTop: 40 }}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField id="input-with-icon-adornment" label="Email Address"
                                    style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                    autoFocus={true}
                                    variant="outlined"
                                    error={this.state.invalidUserName}
                                    className={classes.textField}
                                    onChange={this.handleChange('username')} />
                            </FormControl>

                            {this.state.invalidUserName ?

                                <Grid container style={{ backgroundColor: '#D55648', padding: 10, color: '#FFF' }}>
                                    <Grid item xs={1}>
                                        <WarningIcon style={{ marginTop: 10 }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>It looks like there's no account with that email.<br />
                                            Would you like to <Link style={{ color: "#FFF" }} to={"/register"}>create a new account?</Link></Typography>
                                    </Grid>
                                </Grid>

                                : null
                            }

                            <Grid style={{ marginTop: 20, textAlign: 'center' }} >
                                <Button disabled={this.state.username !== "" ? false : true}
                                    style={styles.resetButton} onClick={(e) => this.forgotPassword(e)}>
                                    Send Password Reset Link
                                </Button>
                            </Grid>

                            <Grid style={{ marginTop: 30, padding: 10 }}>
                                <Link style={{ color: '#2A2042', fontWeight: 'bold', textAlign: 'center' }} to={"/"}>Back to Sign In</Link>
                            </Grid>

                        </form>

                    </Grid>

                    <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, fontWeight: 'bold', color: '#FFF' }}>
                        © 2019 Tassel, LLC | <a style={{ color: '#FFF' }} target="_blank" without rel="noopener noreferrer" href="https://www.tasselpathway.com/terms">Terms of Use</a>
                    </Grid>

                </Grid>

            )
        }
    }
}

ForgotScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        hasError: state.auth.hasError,
        errorMessage: state.auth.errorMessage
    };
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ForgotScreen)));