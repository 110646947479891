export default {

    containerStyle: {
        backgroundColor:'#eaeaea',
        height:'100%'
    },
    itemContainerStyle: {
        padding:10,
        height:'100%'
    },
    headerStyle: {
      backgroundColor: '#25D192'  
    },
    avatar: {
        width:50,
        height:50
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    },
    expanded: {
        margin: '0 auto',
        padding: 0
    },
    search: {
        position: 'relative',
        borderRadius: 5,
        backgroundColor: '#25D192',
        '&:hover': {
            backgroundColor: '#78E5C9',
        },
        marginTop:18,
        marginRight: 30,
        marginLeft: 10,
        width: '100%',
    },
    searchIcon: {
        width: 10,
        marginLeft:15,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: 10,
        width: '100%',
        marginLeft:35
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
    optionsView: {
        flexDirection: 'row',
        width: '100%',
        paddingBottom: 10,
    },
    optionsFlex: {
        flex: 9
    },
    optionsText: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#499CB3'
    },
    borderStyle: {
        flexDirection: 'row',
        paddingBottom: 10,
        marginTop: 10,
        width: '100%',
        borderBottomColor: '#eaeaea',
        borderBottomWidth: 1,
    },
    dateFlex: {
        flex: 5,
    },
    dateText: {
        textAlign: 'right',
    },
    emptyView: {
        flex: 1,
    },
    editText: {
        fontWeight: 'bold',
        color: '#E68846',
    },
    topBar: {
      backgroundColor: '#FFF',
      paddingBottom:15,
      borderBottom:'1px solid #eaeaea'
    }
  };