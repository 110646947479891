import { GET_ALERT, SET_ALERT } from "../actions";

export const getAlert = () => {
  return {
    type: GET_ALERT
  };
};

export const setAlert = (announcementAlert, urgentAlert) => {
  return {
    type: SET_ALERT,
    announcementAlert: announcementAlert,
    urgentAlert: urgentAlert
  }
};

export const alert = (announcementAlert, urgentAlert) => {
  return (dispatch) => {

    fetch('https://api.tasselpathway.com/allAlerts', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            allAlerts: responseJson.results
          })

          responseJson.results.map((item, key) => {
            if (item.alert_type === 'Announcement' && item.alert_type === 'Urgent Alert') {
              dispatch(setAlert(1, 1));
            }
            else if (item.alert_type === 'Announcement') {
              dispatch(setAlert(1, 0));
            }
            else if (item.alert_type === 'Urgent Alert') {
              dispatch(setAlert(0, 1));
            }
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  };
};