import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        LOGIN_MESSAGE: "Log In To Your Tassel Account",
        LOGIN_TILE_EMAIL_ADDRESS: "Email Address",
        LOGIN_TILE_PASSWORD: "Password",
        LOGIN_TILE_LOGIN_BUTTON: "Log In",
        LOGIN_TILE_FORGOT_PASSWORD: "Forgot Password",
        LOGIN_TILE_LANGUAGE: "Language",
        REGISTER_TILE_HAVE_INVITE_CODE: "Do you have an invite code?",
        REGISTER_TILE_REGISTER_NEW_ACCOUNT: "Register A New Student Account",
        REGISTER_TILE_REGISTER_BUTTON: "Register",
        LOGIN_ERROR: "That email or password combo isn't correct.",
        LOGIN_ERROR_TRY_AGAIN: "Try again or",
        RESET_PASSWORD: "reset your password.",

        NAVBAR_SEARCH: "Search",
        NAVBAR_HOME: "Home",
        NAVBAR_PROFILE: "Profile",
        NAVBAR_STUDENTS: "Students",
        NAVBAR_TASKS: "Tasks",
        NAVBAR_TESTS: "Tests",
        NAVBAR_COLLEGES: "Colleges",
        NAVBAR_CAREERS: "Careers",
        NAVBAR_MAJORS: "Majors",
        NAVBAR_ADMIN: "Admin",
        NAVBAR_REPORTS: "Reports",

        HOME_UPLOAD_PHOTO: "Photo",
        HOME_POST_BUTTON: "Post",
        HOME_DASHBOARD_TILE_TITLE: "Dashboard",
        HOME_DASHBOARD_TILE_POINTS: "Points",
        HOME_DASHBOARD_TILE_PERFORMANCE: "Performance",
        HOME_DASHBOARD_TILE_PROFILE: "Profile",
        HOME_DASHBOARD_TILE_ATTENDANCE: "Attendance",
        HOME_DASHBOARD_TILE_ACTIVITIES: "Activities",
        HOME_DASHBOARD_TILE_SERVICE_HOURS: "Service Hours",
        HOME_DASHBOARD_TILE_PROJECTS: "Projects",
        HOME_LEADER_BOARD: "Leader Board",

        HOME_GRADUATION_CHECKLIST: "Early Graduation Program Requirements",
        HOME_ILP_PROGRESS_BOARD: "Progress Tracker",
        HOME_WR_PROGRESS_BOARD: "Progress Tracker",

        TEACHER_PROFILE_BIO_TILE: "Bio",
        BIO_TILE_SAVE: "Save",
        BIO_TILE_EDIT: "Edit Personal Bio",
        BIO_TILE_ADD: "Add",
        BIO_TILE_NEW: "New",

        PROFILE_ADAPTABILITY_TILE: "Adaptability",
        PROFILE_DILIGENCE_TILE: "Diligence",
        PROFILE_INITIATIVE_TILE: "Initiative",
        PROFILE_KNOWLEDGE_TILE: "Knowledge",
        PROFILE_RELIABILITY_TILE: "Reliability",
        PROFILE_DRUGFREE_TILE: "Drug Free",
        PROFILE_COMMUNICATION_TILE: "Communication",

        EDIT: "Edit",

        TEACHER_PROFILE_CAREERS_TILE: "Careers",
        TEACHER_PROFILE_COLLEGES_TILE: "Colleges",

        TEACHER_PROFILE_EDUCATION_TILE: "Education",
        ED_TILE_ADD_TITLE: "Add Education",
        ED_TILE_CHOOSE_UNI: "Choose a University",
        ED_TILE_DEGREE: "Degree",
        ED_TILE_MAJOR: "Major",
        ED_TILE_HIGHLIGHTS: "Highlights",
        ED_TILE_ADD: "Add",
        ED_EDIT_EDUCATION: "Edit Education",
        ED_EDIT_DELETE: "Delete",
        ED_EDIT_SAVE: "Done",

        TEACHER_PROFILE_PUBLICATIONS_TILE: "Publications",
        PUBLICATION_TILE_ADD: "Add Publication",
        PUBLICATION_TILE_TITLE: "Publication Title",
        PUBLICATION_TILE_URL: "Publication URL",
        PUBLICATION_TILE_ADD_BUTTON: "Add",
        PUBLICATION_TILE_EDIT: "Edit Publication",

        TEACHER_PROFILE_LEARNING_STYLE_TILE: "Learning Style",
        TEACHER_PROFILE_LEARNING_STYLE_VISUAL: "Visual",
        TEACHER_PROFILE_LEARNING_STYLE_AUDITORY: "Auditory",
        TEACHER_PROFILE_LEARNING_STYLE_TACTILE: "Tactile",

        TEACHER_PROFILE_SKILLS_TILE: "Skills",
        SKILLS_TILE_NEW: "New Skill",
        SKILLS_TILE_INPUT: "Skill Name",
        SKILLS_ADD_BUTTON: "Add",

        TEACHER_PROFILE_CLUBS_SPORTS_TILE: "Clubs & Sports",
        ACTIVITY_NEW: "New Club or Sport",
        ACTIVITY_ENTER_NEW: "Enter you club or sport",
        ACTIVITY_ROLE_POSITION: " Role/Position",
        ACTIVITY_YEARS_ACTIVE: "Year(s) Active",
        ACTIVITY_HIGHLIGHTS: "Highlights",
        ACTIVITY_ADD_BUTTON: "Add",
        ACTIVITIES_NEW_ACTIVITY: "New Club or Sport",
        ACTIVITY_EDIT_TITLE: "Edit Club or Sport",
        ACTIVITY_DELETE_BUTTON: "Delete",
        ACTIVITY_EDIT_SAVE: "Save",

        PROFILE_HONORS_AND_AWARDS_TILE: "Honors & Awards",
        HONORS_AND_AWARDS_DELETE: "Delete",
        HONORS_AND_AWARDS_SAVE: "Save",
        HONORS_AND_AWARDS_COMMENT: "Comments",
        HONORS_AND_AWARDS_SPONSOR: "Sponsor / Coach",
        HONORS_AND_AWARDS_AWARD_TYPE: "Type of Award / Honor",
        HONORS_AND_AWARDS_ENTER_AWARD: "Enter your award or honor",
        HONORS_AND_AWARDS_EDIT_TITLE: "Edit Honors & Awards",
        HONORS_AND_AWARDS_ADD_BUTTON: "Add",
        HONORS_AND_AWARDS_NEW: "New honors & awards",

        PROFILE_SERVICE_LEARNING_TILE: "Service Learning",
        SERVICE_LEARNING_NEW: "New Service Learning",
        SERVICE_LEARNING_SERVICE_TYPE: "Select Service Type",
        SERVICE_LEARNING_LEADERSHIP: "Select Leadership Role",
        SERVICE_LEARNING_CREDIT_HOURS: "Credit Hours",
        SERVICE_LEARNING_PLACE: "Place of Service Learning",
        SERVICE_LEARNING_SPONSOR: "Sponsor / Coach / Supervisor",
        SERVICE_LEARNING_REFLECTION: "Reflection",
        SERVICE_LEARNING_ADD_BUTTON: "Add",
        SERVICE_LEARNING_EDIT: "Edit Service Learning",
        SERVICE_LEARNING_DELETE: "Delete",
        SERVICE_LEARNING_SAVE: "Save",

        PROFILE_PROJECTS_TILE: "Projects",
        PROJECT_NEW: "New Project",
        PROJECT_NAME: "Project Name",
        PROJECT_DESCRIPTION: "Project Description",
        PROJECT_ADD_BUTTON: "Add",
        PROJECT_EDIT: "Edit Project",
        PROJECT_DELETE_BUTTON: "Delete",
        PROJECT_SAVE_BUTTON: "Save",

        CIVICS_EXAM_TILE: "Civics Exam",
        CIVICS_EXAM_START: "Start Civics Exam",
        CIVICS_NEXT: "Next",
        CIVICS_BACK: "Back",
        CIVICS_FINISH: "Finish",
        CIVICS_EXAM_RESULTS: "Civics Exam Results",
        CIVICS_PASSED: "Passed On",
        CIVICS_AWESOME: "Awesome, you passed!",
        CIVICS_OK: "Ok",
        CIVICS_YOU_SCORED: "You scored",
        CIVICS_SORRY: "Sorry, you didn't pass.",
        CIVICS_OF_TOTAL: "of total questions correctly",
        CIVICS_RETRY: "Please remember to retake the Civics Exam again until you pass!",

        STUDENTS_TITLE: "Students",
        STUDENTS_SHOWING: "Showing",
        STUDENTS_OF: "of",
        STUDENTS_RESULTS: "Results",
        STUDENTS_SEARCH: "Search",
        STUDENTS_GRADES: "Grades",
        STUDENTS_SELECT_GRADE: "Grade",
        STUDENTS_TABLE_CURRENT: "Current",
        STUDENTS_TABLE_REQUESTS: "Requests",
        STUDENTS_TABLE_STUDENT_COLUMN: "Student Name",
        STUDENTS_TABLE_GRADE_COLUMN: "Grade",
        STUDENTS_TABLE_EMAIL_ADDRESS: "Email Address",
        STUDENTS_TABLE_SCHOOL_COLUMN: "School",
        STUDENTS_TABLE_PROGRESS: "Progress",
        STUDENTS_GENERATE_INVITE: "Generate Invite",
        STUDENTS_CHOOSE_SCHOOL: "Choose School",
        STUDENTS_GENERATE_BUTTON: "Generate",
        STUDENTS_SELECT_SCHOOL: "Select School",
        STUDENTS_ACCEPT_BUTTON: "Accept",
        STUDENTS_REJECT_BUTTON: "Reject",
        STUDENTS_SIDEBAR_GRADES: "Grades",
        STUDENTS_FIRST_NAME: "First Name",
        STUDENTS_LAST_NAME: "Last Name",
        STUDENTS_NEW_INVITE_TOKEN: "New Invite Token",

        TASKS_TITLE: "Tasks",
        TASKS_TABLE_STUDENT: "Student",
        TASKS_TABLE_TASK_NAME: "Task Name",
        TASKS_TABLE_TOTAL_POINTS: "Total Points",
        TASKS_TABLE_TASK_DESCRIPTION: "Description",
        TASKS_TABLE_TASK_CATEGORY: "Category",
        TASKS_TABLE_QUESTION: "Question",
        TASKS_TABLE_ANSWER: "Answer",
        TASKS_TABLE_GRADE_BUTTON: "Grade",
        TASKS_GRADE_QUESTION: "Grade Question",
        TASKS_STUDENT_NAME: "Student Name",
        TASKS_STUDENT_ANSWER: "Student Answer",
        TASKS_EDUCATOR_RESPONSE: "Educator Response",
        TASKS_INCORRECT: "Incorrect",
        TASKS_CORRECT: "Correct",
        TASKS_LESSON_NAME: "Lesson Name",
        TASKS_INSTRUCTIONS: "Instructions",
        TASKS_ESTIMATED_TIME: "Estimated Time",
        TASKS_POINTS: "Points",
        TASKS_START: "Start",
        TASKS_RETRY: "Retry",
        TASKS_EDIT: "Edit",

        TESTS_TITLE: "Tests",
        ADMIN_TESTS_NEW_TEST: "New Test",
        ADMIN_TESTS_ASSIGN_TEST: "Assign Test",
        ADMIN_TESTS_TEST_NAME: "Test Name",
        ADMIN_TESTS_TEST_TYPE: "Test Type",
        ADMIN_TESTS_TEST_VISIBILITY: "Visibility",
        ADMIN_TESTS_TEST_OWNER: "Owner",
        ADMIN_TESTS_TEST_DATE_MODIFIED: "Date Modified",

        LEARNING_STYLE_START: "Start Learning Style",
        LEARNING_STYLE_NEXT: "Next",
        LEARNING_STYLE_BACK: "Back",
        LEARNING_STYLE_FINISH: "Finish",
        LEARNING_STYLE_RESULTS: "Learning Style Results",

        LEARNING_STYLE_TACTILE_1: "Highlight passages as you read them",
        LEARNING_STYLE_TACTILE_2: "Take notes; transfer to a journal",
        LEARNING_STYLE_TACTILE_3: "Randomly draw as you read",
        LEARNING_STYLE_TACTILE_4: "Hold what you are reading",
        LEARNING_STYLE_TACTILE_5: "Walk around as you read if possible",
        LEARNING_STYLE_TACTILE_6: "Actively participate in class",
        LEARNING_STYLE_TACTILE_7: "Choose classes that are hands-on learning",
        LEARNING_STYLE_TACTILE_8: "Teach material to another student",
        LEARNING_STYLE_TACTILE_9: "Act out material to be learned",
        LEARNING_STYLE_TACTILE_10: "Take breaks and move around while studying",

        LEARNING_STYLE_AUDITORY_1: "Read out loud",
        LEARNING_STYLE_AUDITORY_2: "Use mnemonics, jingles, and rhymes",
        LEARNING_STYLE_AUDITORY_3: "Use songs to remember things",
        LEARNING_STYLE_AUDITORY_4: "Study with someone who says the notes",
        LEARNING_STYLE_AUDITORY_5: "Record lectures and reading materials to play back",
        LEARNING_STYLE_AUDITORY_6: "Listen to teachers' instructions as they say them",
        LEARNING_STYLE_AUDITORY_7: "Talk through information",
        LEARNING_STYLE_AUDITORY_8: "Work in a quiet area",
        LEARNING_STYLE_AUDITORY_9: "Read directions out loud if possible",
        LEARNING_STYLE_AUDITORY_10: "Turn graphs and illustrations in verbal descriptions",

        LEARNING_STYLE_VISUAL_1: "Take several notes",
        LEARNING_STYLE_VISUAL_2: "Sit in the front of the room",
        LEARNING_STYLE_VISUAL_3: "Organize study space",
        LEARNING_STYLE_VISUAL_4: "Manage time for planning",
        LEARNING_STYLE_VISUAL_5: "Use colors, graphs, and pictures in notes",
        LEARNING_STYLE_VISUAL_6: "Use different colored highlighters",
        LEARNING_STYLE_VISUAL_7: "Use visual clues",
        LEARNING_STYLE_VISUAL_8: "Draw pictures in the margins",
        LEARNING_STYLE_VISUAL_9: "Read the text",
        LEARNING_STYLE_VISUAL_10: "Participate in class",

        COLLEGE_PAGE_TITLE: "Colleges",
        COLLEGE_SHOWING: "Showing",
        COLLEGE_OF: "of",
        COLLEGE_RESULTS: "Results",
        COLLEGE_SEARCH: "Search",
        COLLEGE_LOCATION_STATE: "Location/State",
        COLLEGE_INSTITUTION_TYPE: "Type of Institution",
        COLLEGE_LEVEL_OF_INSTITUTION: "Level of Institution",
        COLLEGE_LEVEL_OF_DEGREE: "Level of Degree",
        COLLEGE_PROGRAM: "College Program",
        COLLEGE_MILES_AWAY: "miles away",
        COLLEGE_EXPLORE: "Explore College",

        COLLEGE_PROFILE_OVERVIEW: "Overview",
        COLLEGE_PROFILE_ADMISSIONS: "Admissions",
        COLLEGE_ADMISSIONS_TYPE: "Institution Type",
        COLLEGE_ADMISSIONS_INSTITUTION_LEVEL: "Institution Level",
        COLLEGE_ADMISSIONS_CAMPUS: "Campus Setting",
        COLLEGE_ADMISSIONS_STUDENTS: "Students Applied",
        COLLEGE_ADMISSIONS_ACCEPTANCE: "Acceptance Rate",
        COLLEGE_PROFILE_WEBSITE_BUTTON: "Open Website",

        COLLEGE_STUDENT_STATS: "Student Stats",

        COLLEGE_ACADEMICS: "Academics",
        COLLEGE_ACADEMICS_HIGHEST: "Highest Degree Offered",
        COLLEGE_ACADEMICS_TOTAL_STUDENTS: "Total Number of Students",
        COLLEGE_ACADEMICS_NUMBER_GRADS: "Total Number of Undergrads",
        COLLEGE_ACADEMICS_FOUR_YEAR: "Graduate in 4 Years",
        COLLEGE_ACADEMICS_GRAD_SIX: "Graduate in 6 Years",

        COLLEGE_TUITION: "Tuition",
        COLLEGE_TUITION_ESTIMATED_COST: "Your Estimated Cost",
        COLLEGE_TUITION_IN: "In State Tuition",
        COLLEGE_TUITION_OUT: "Out of State Tuition",
        COLLEGE_TUITION_ROOM: "Room and Board",
        COLLEGE_TUITION_BOOKS: "Books and Supplies",

        COLLEGE_PROGRAMS: "Programs",

        CAREERS_TITLE: "Careers",
        CAREERS_SHOWING: "Showing",
        CAREERS_OF: "of",
        CAREERS_RESULTS: "Results",
        CAREERS_SEARCH: "Search",
        CAREER_CLUSTERS: "Career Clusters",
        CAREER_PATHWAYS: "Career Pathways",

        CAREER_PROFILE_DESCRIPTION: "Job Description",
        CAREER_PROFILE_AVERAGE_WAGES: "Wages",
        CAREER_PROFILE_TASKS: "Core Tasks",
        CAREER_PROFILE_DETAILED_ACTIVITIES: "Detailed Activities",
        CAREER_SKILLS: "Skills",
        CAREER_ABILITIES: "Abilities",
        CAREER_KNOWLEDGE: "Knowledge",
        CAREER_ACTIVITIES: "Activities",
        CAREER_VIDEO_TITLE: "Career Video",
        CAREERS_RELATED: "Related Careers",
        MAJORS_RELATED: "Related Majors",
        CAREERS_MEDIAN_WAGE: "Median Wage",
        CAREERS_EDUCATION: "Education",

        EC_TILE: "Extracurricular Groups",
        EC_SELECT: "Select Extracurricular",
        EC_EDIT: "Edit Extracurricular Activity",
        EC_JOIN: "Join an Extracurricular Organization",
        EXTRA_CURRICULAR_NEW_TITLE: "Add Group",
        EXTRA_CURRICULAR_NAME: "Name of club/sport/other",
        EXTRA_CURRICULAR_EDIT_TITLE: "Edit Extracurricular Activity",
        EXTRA_CURRICULAR_ROLE: "Role",
        EXTRA_CURRICULAR_ADD_BUTTON: "Add",
        EXTRA_CURRICULAR_DELETE_BUTTON: "Delete",
        EXTRA_CURRICULAR_SAVE_BUTTON: "Save",

        ADMIN_DIPLOMA_TAB: "Diploma",
        ADMIN_TABLE_DIPLOMA_NAME: "Diploma Name",
        ADMIN_TABLE_DIPLOMA_YEAR: "Diploma Year",
        ADMIN_TABLE_TOTAL_CREDIT: "Total Credit",
        ADMIN_TABLE_DATE_MODIFIED: "Date Modified",
        ADMIN_NEW_DIPLOMA: "New Diploma",
        ADMIN_SAVE_DIPLOMA: "Save",
        ADMIN_SELECT_YEAR: "Select Year",
        ADMIN_EDIT_DIPLOMA: "Edit Diploma",
        ADMIN_ADD_DIPLOMA: "Add",


        ADMIN_COURSES_TAB: "Courses",
        ADMIN_COURSES_NEW_COURSE_BUTTON: "New Course",
        ADMIN_COURSES_SELECT_SCHOOL: "Select School",
        ADMIN_COURSES_SCHOOL_NAME: "School Name",
        ADMIN_COURSES_TABLE_SUBJECT_NAME: "Subject Name",
        ADMIN_COURSES_TABLE_COURSE_CODE: "Course Code",
        ADMIN_COURSES_TABLE_COURSE_NAME: "Course Name",
        ADMIN_COURSES_TABLE_CREDIT_HOURS: "Credit Hour",
        ADMIN_COURSES_TABLE_DUAL_CREDIT_HOURS: "Dual Credit Hours",
        ADMIN_COURSE_TABLE_AP_COURSE: "AP Course",
        ADMIN_COURSE_TABLE_CTE_COURSE: "CTE Course",
        ADMIN_COURSE_TABLE_DATE_MODIFIED: "Date Modified",
        ADMIN_COURSE_SAVE: "Save",
        ADMIN_COURSE_SELECT_SUBJECT: "Select Subject",
        ADMIN_COURSE_SELECT_SCHOOL: "Select School",
        ADMIN_COURSE_SCHOOL_NAME: "School Name",
        ADMIN_EDIT_COURSE: "Edit Course",
        ADMIN_ADD_BUTTON: "Add",

        ADMIN_TASKS_TAB: "Tasks",
        ADMIN_TASKS_NEW_TASK_BUTTON: "New Task",
        ADMIN_TASKS_ARCHIVE_TASK: "Archive Task",
        ADMIN_TASKS_SEARCH: "Search",
        ADMIN_TASKS_CATEGORY_NAME: "Category Name",
        ADMIN_TASKS_TASKS_NAME: "Tasks Name",
        ADMIN_TASKS_TYPE: "Type",
        ADMIN_TASKS_FREQUENCY: "Frequency",
        ADMIN_TASKS_TIME: "Time",
        ADMIN_TASKS_POINTS: "Points",
        ADMIN_TASKS_ACTIVATE_BUTTON: "Activate",
        ADMIN_TASKS_DEACTIVATE_BUTTON: "Deactivate",
        ADMIN_TASKS_SAVE: "Save",
        ADMIN_TASKS_ARCHIVE: "Archive",
        ADMIN_TASKS_ASSIGN_DETAIL: "Assignment Detail",
        ADMIN_TASKS_OK: "Ok",
        ADMIN_TASKS_TOTAL_POINT: "Total Points",
        ADMIN_TASKS_ESTIMATED_TIME: "Estimated Time",
        ADMIN_TASKS_TASK_DESCRIPTION: "Task Description",
        ADMIN_TASKS_YEARS_ACTIVE: "Year(s) Active",
        ADMIN_TASKS_SELECT_CATEGORY: "Select Category",
        ADMIN_TASKS_SELECT_VISIBILITY: "Select Visibility",
        ADMIN_TASKS_SELECT_FREQUENCY: "Select Frequency",
        ADMIN_TASKS_EDIT_TASK: "Edit Task",
        ADMIN_TASKS_ADD: "Add",
        ADMIN_TASKS_ASSIGN_TASK: "Assign Task",
        ADMIN_TASKS_TARGET_TYPE: "Target Type",
        ADMIN_TASKS_TARGET_NAME: "Target Name",
        ADMIN_TASKS_TOTAL_STUDENTS: "Total Students",

        ADMIN_SCHOOLS_TAB: "Schools",
        ADMIN_SCHOOLS_SCHOOL_NAME: "School Name",
        ADMIN_SCHOOLS_SCHOOL_TYPE: "School Type",
        ADMIN_SCHOOLS_ADDRESS_1: "Address 1",
        ADMIN_SCHOOLS_CITY: "City",
        ADMIN_SCHOOLS_STATE: "State",
        ADMIN_SCHOOLS_ZIP_CODE: "Zip Code",
        ADMIN_SCHOOLS_NEW_SCHOOL: "New School",
        ADMIN_SCHOOLS_SAVE_BUTTON: "Save",
        ADMIN_SCHOOLS_SELECT_STATE: "Select State",
        ADMIN_SCHOOLS_SELECT_SCHOOL_TYPE: "Select School Type",
        ADMIN_SCHOOLS_EDIT_SCHOOL: "Edit School",
        ADMIN_SCHOOLS_ADD_BUTTON: "Add",

        ADMIN_USERS_TAB: "Users",
        ADMIN_USERS_FIRST_LAST_NAM: "First Name / Last Name",
        ADMIN_USERS_SCHOOLS: "School(s)",
        ADMIN_USERS_EMAIL_ADDRESS: "Email Address",
        ADMIN_USERS_ROLE: "Role",
        ADMIN_USERS_STATUS: "Status",
        ADMIN_USERS_RESEND: "Re-send",
        ADMIN_USERS_ACTIVE: "Active",
        ADMIN_TASKS_ARCHIVE_ASSIGNMENT: "Archive Assignment",
        ADMIN_USERS_NEW_PERSON: "New Person",
        ADMIN_USERS_ALREADY: "Email Address Already Used By Another Person!",
        ADMIN_USERS_SELECT_ROLE: "Select Role",
        ADMIN_USERS_SCHOOL: "School",
        ADMIN_USERS_ADD_BUTTON: "Add",
        ADMIN_USERS_EDIT_PERSON: "Edit Person",
        ADMIN_USERS_RESEND_INVITE: "Re-Send Invite",
        ADMIN_USERS_REACTIVATE: "Reactivate",
        ADMIN_USERS_DEACTIVATE: "Deactivate",
        ADMIN_USERS_SAVE_BUTTON: "Save",
        ADMIN_USERS_CONFIRMATION: "Confirmation",
        ADMIN_USERS_RESENT_CONF: "An invitation email has been re-sent successfully to this user!",
        ADMIN_USERS_OK: "Ok",
        ADMIN_USERS_NEW_USER: "New User",
        ADMIN_USERS_FIRST_NAME: "First Name",
        ADMIN_USERS_LAST_NAME: "Last Name",

        PATHWAY_CREDIT_EARNED: "credit earned",
        PATHWAY_DUAL_CRED_EARNED: "dual-credit earned",
        PATHWAY_ADD_COURSE: "Add Course",
        PATHWAY_NEW: "New Pathway",
        PATHWAY_DIPLOMA: "Diploma",
        PATHWAY_SELECT_DIPLOMA: "Select Diploma",
        PATHWAY: "Pathway",
        PATHWAY_SELECT: "Select Pathway",
        EDIT_PATHWAY: "Edit Pathway",
        PATHWAY_SAVE: "Save",
        PATHWAY_JOIN_SCHOOL: "Join School",
        PATHWAY_SCHOOL_NAME: "School Name",
        PATHWAY_JOIN: "Join",
        PATHWAY_COURSE: "Course",
        PATHWAY_SELECT_COURSE: "Select Course",
        PATHWAY_CREDITS: "Credits",
        PATHWAY_DUAL_CREDITS: "Dual Credits",
        PATHWAY_ADD: "Add",
        EDIT_COURSE: "Edit Course",
        PATHWAY_NAME: "Pathway",
        PATHWAY_REQUEST_1: "Request To Join",
        REQUEST_2: "Pending",
        PATHWAY_CREATE: "Create Pathway",
        PATHWAY_DUAL_CREDIT_HOURS: "dual-credit hours",
        PATHWAY_CRED_HRS: "Credit Hours",

        PERSONALITY_TRAITS: "Personality Traits",
        FAV_SUBJECTS: "Favorite Subjects",
        INTEREST_INVENTORY: "Interest Inventory",
        BACK: "Back",
        FINISH: "Finish",
        NEXT: "Next",
        INTEREST_RESULTS: "Interest Inventory Results",
        START_INTEREST: "Start Interest Inventory",
        YEAR: "Year",

        ASSESSMENT_NEW: "New Assessment",
        ASSESSMENT_TYPE: "Select Type",
        ASSESSMENT_YEAR: "Select Year",
        ASSESSMENT_NAME: "Assessment Name",
        ASSESSMENT_READING: "Select Reading",
        ASSESSMENT_SELECT_MATH: "Select Math",
        ASSESSMENT_WRITING: "Select Writing",
        ASSESSMENT_SCIENCE: "Select Science",
        ASSESSMENT_SOCIAL: "Select Social Studies",
        ASSESSMENT_COMPOSITE: "Composite Score",
        ASSESSMENT_MATH: "Math Score",
        ASSESSMENT_TECHNICAL: "Technical Math Score",
        ASSESSMENT_SCIENCE: "Science Score",
        ASSESSMENT_ALGEBRA: "Algebra Score",
        ASSESSMENT_ENGLISH: "English Score",
        ASSESSMENT_READING: "Reading Score",
        ASSESSMENT_WRITING_SCORE: "Writing Score",
        ASSESSMENT_ADD_BUTTON: "Add",
        ASSESSMENT_EDIT: "Edit Assessment",
        ASSESSMENT_DELETE: "Delete",
        ASSESSMENT_SAVE: "Save",
        ASSESSMENTS: "Assessments",
        NONE: "No recorded assessments",

        GOAL_NEW: "New Goal",
        GOAL_NAME: "Goal Name",
        GOAL_GRADE: "Grade",
        GOAL_SELECT_GRADE: "Select Grade",
        GOAL_TYPE: "Goal Type",
        GOAL_SELECT_GOAL_TYPE: "Select Goal Type",
        GOAL_ACHIEVED: "Goal Achieved?",
        YES: "Yes",
        NO: "No",
        GOAL_DESCRIPTION: "Goal Description",
        ADD: "Add",
        GOAL_EDIT: "Edit Goal",
        DELETE: "Delete",
        SAVE: "Save",
        DID_YOU: "Did you complete this Goal?",
        GOAL_MARK_DONE: "Click yes to mark this goal as complete",
        CANCEL: "Cancel",
        GOALS: "Goals",

        WBL_NEW: "New Work Learning",
        WBL_SELECT_TYPE: "Select work type",
        WBL_PATHWAY: "Select Pathway",
        WBL_PLACE: "Place of work",
        WBL_SPONSOR: "School Sponsor",
        WBL_INDUSTRY_SPONSOR: "Business/Industry Sponsor",
        WBL_SKILLS: "Skills learned",
        WBL_PAID: "Were you paid?",
        WBL_YES: "Yes",
        WBL_PERFORMED: "Job Performed",
        WBL: "Work Based Learning",
        WBL_EDIT: "Edit Work Based Learning",

        WORK_EXPERIENCE: "Work Experience",

        NEW_RESUME: "New Resume",
        RESUME_SAVING: "Saving your resume...",
        RESUME_CLICK: "Drag and drop your resume or click file to upload resume",
        RESUME_REMOVE: "Delete",
        RESUME: "Resume",
        RESUME_VIEW: "View",

        CONNECTIONS: "Connections",
        REQUESTED: "Requeted",
        CONNECT: "Connect",
        STUDENTS: "Students",
        SHOWING: "Showing",
        RESULTS: "Results",

        GROUPS_OFFICERS: "Officers",
        GROUPS_MEMBERS: "Members"

      }
    },
    esp: {
      translations: {
        LOGIN_MESSAGE: "Inicie sesión en su cuenta de Tassel",
        LOGIN_TILE_EMAIL_ADDRESS: "El e-mail",
        LOGIN_TILE_PASSWORD: "Contraseña",
        LOGIN_TILE_LOGIN_BUTTON: "Iniciar sesión",
        LOGIN_TILE_FORGOT_PASSWORD: "te olvidó tu contraseña",
        LOGIN_TILE_LANGUAGE: "Idioma",
        LOGIN_ERROR: "Ese combo de correo electrónico y contraseña no es correcto.",
        LOGIN_ERROR_TRY_AGAIN: "Inténtalo de nuevo o",
        RESET_PASSWORD: "restablece tu contraseña.",

        REGISTER_TILE_HAVE_INVITE_CODE: "¿Tienes un código de invitación?",
        REGISTER_TILE_REGISTER_NEW_ACCOUNT: "Registrar una nueva cuenta de estudiante",
        REGISTER_TILE_REGISTER_BUTTON: "Registrar",

        NAVBAR_SEARCH: "Buscar",
        NAVBAR_HOME: "Noticias",
        NAVBAR_PROFILE: "Tu Canal",
        NAVBAR_STUDENTS: "Estudiantes",
        NAVBAR_TASKS: "Tareas",
        NAVBAR_COLLEGES: "Colegios",
        NAVBAR_CAREERS: "Carreras",
        NAVBAR_MAJORS: "Grandes Ligas",
        NAVBAR_ADMIN: "Administración",
        NAVBAR_REPORTS: "Informes",

        HOME_UPLOAD_PHOTO: "Foto",
        HOME_POST_BUTTON: "Enviar",
        HOME_DASHBOARD_TILE_TITLE: "Tablero",
        HOME_DASHBOARD_TILE_POINTS: "Puntos",
        HOME_DASHBOARD_TILE_PERFORMANCE: "Rendimiento",
        HOME_DASHBOARD_TILE_PROFILE: "Tu Canal",
        HOME_DASHBOARD_TILE_ATTENDANCE: "Asistencia",
        HOME_DASHBOARD_TILE_ACTIVITIES: "Actividades",
        HOME_DASHBOARD_TILE_SERVICE_HOURS: "Horas de servicio",
        HOME_DASHBOARD_TILE_PROJECTS: "Proyectos",
        HOME_LEADER_BOARD: "Junta de líderes",
        HOME_GRADUATION_CHECKLIST: "Lista de verificación de graduación",
        HOME_ILP_PROGRESS_BOARD: "Rastreador de progreso",
        HOME_WR_PROGRESS_BOARD: "Rastreador de progreso",

        TEACHER_PROFILE_BIO_TILE: "Biografía",
        BIO_TILE_SAVE: "Salvar",
        BIO_TILE_EDIT: "Editar biografía personal",
        BIO_TILE_ADD: "Añadir",
        BIO_TILE_NEW: "Nuevo",

        PROFILE_ADAPTABILITY_TILE: "Adaptabilidad",
        PROFILE_DILIGENCE_TILE: "Diligencia",
        PROFILE_INITIATIVE_TILE: "Iniciativa",
        PROFILE_KNOWLEDGE_TILE: "Conocimiento",
        PROFILE_RELIABILITY_TILE: "Fiabilidad",
        PROFILE_DRUGFREE_TILE: "Libre de drogas",
        PROFILE_COMMUNICATION_TILE: "Comunicación",

        CIVICS_NEXT: "Continuar",
        CIVICS_BACK: "Atrás",
        CIVICS_FINISH: "Terminar",
        CIVICS_EXAM_RESULTS: "Resultados del Examen de Civismo",
        CIVICS_PASSED: "Pasó en",
        CIVICS_AWESOME: "Impresionante que pasaste",
        CIVICS_OK: "Ok",
        CIVICS_YOU_SCORED: "Has anotado",
        CIVICS_SORRY: "Lo siento, no pasaste.",
        CIVICS_OF_TOTAL: " Del total de preguntas correctamente",
        CIVICS_RETRY: "¡Recuerde volver a tomar el examen de educación cívica hasta que apruebe!",

        TEACHER_PROFILE_CAREERS_TILE: "Carreras",
        TEACHER_PROFILE_COLLEGES_TILE: "Colegios",

        TEACHER_PROFILE_EDUCATION_TILE: "Educación",
        ED_TILE_ADD_TITLE: "Agregar educación",
        ED_TILE_CHOOSE_UNI: "Elige una universidad",
        ED_TILE_DEGREE: "Grado",
        ED_TILE_MAJOR: "Estudio de enfoque",
        ED_TILE_HIGHLIGHTS: "Reflejos",
        ED_TILE_ADD: "Añadir",
        ED_EDIT_EDUCATION: "Editar educación",
        ED_EDIT_DELETE: "Borrar",
        ED_EDIT_SAVE: "Hecho",

        TEACHER_PROFILE_PUBLICATIONS_TILE: "Publicaciones",
        PUBLICATION_TILE_ADD: "Añadir Publicaciones",
        PUBLICATION_TILE_TITLE: "Titulo de la Publicacion",
        PUBLICATION_TILE_URL: "URL de la Publicacion",
        PUBLICATION_TILE_ADD_BUTTON: "Añadir",
        PUBLICATION_TILE_EDIT: "Editar Publicación",

        TEACHER_PROFILE_LEARNING_STYLE_TILE: "Aprendiendo estilo",
        TEACHER_PROFILE_LEARNING_STYLE_VISUAL: "Visual",
        TEACHER_PROFILE_LEARNING_STYLE_AUDITORY: "Auditivo",
        TEACHER_PROFILE_LEARNING_STYLE_TACTILE: "Táctil",

        TEACHER_PROFILE_SKILLS_TILE: "Destreza",
        SKILLS_TILE_NEW: "Destreza Nuevo",
        SKILLS_TILE_INPUT: "Nombre de la Destreza",
        SKILLS_ADD_BUTTON: "Añadir",

        TEACHER_PROFILE_CLUBS_SPORTS_TILE: "Clubes y deportes",
        ACTIVITY_NEW: "Nuevo Clube o Deporte",
        ACTIVITY_ENTER_NEW: "Ingrese a su club o deporte",
        ACTIVITY_ROLE_POSITION: "Rol / Posición",
        ACTIVITY_YEARS_ACTIVE: "Año(s) Activo",
        ACTIVITY_HIGHLIGHTS: "Reflejos",
        ACTIVITY_ADD_BUTTON: "Añadir",
        ACTIVITY_EDIT_TITLE: "Editar club o deporte",
        ACTIVITY_DELETE_BUTTON: "Borrar",
        ACTIVITY_EDIT_SAVE: "Hecho",

        PROFILE_HONORS_AND_AWARDS_TILE: "Honores y Reconocimientos",
        HONORS_AND_AWARDS_DELETE: "Borrar",
        HONORS_AND_AWARDS_SAVE: "Hecho",
        HONORS_AND_AWARDS_COMMENT: "Comentario",
        HONORS_AND_AWARDS_SPONSOR: "Patrocinador / entrenador",
        HONORS_AND_AWARDS_AWARD_TYPE: "Tido de Honores o Reconocimientos",
        HONORS_AND_AWARDS_ENTER_AWARD: "Ingrese premio u honor",
        HONORS_AND_AWARDS_EDIT_TITLE: "Editar Honores y Reconocimientos",
        HONORS_AND_AWARDS_ADD_BUTTON: "Añadir",
        HONORS_AND_AWARDS_NEW: "Nuevo Honores y Reconocimientos",

        PROFILE_SERVICE_LEARNING_TILE: "Sevicio de aprendizaje",
        SERVICE_LEARNING_NEW: "Nuevo Sevicio de aprendizaje",
        SERVICE_LEARNING_SERVICE_TYPE: "Seleccionar tipo de servicio",
        SERVICE_LEARNING_LEADERSHIP: "Elige proyecto de liderazgo",
        SERVICE_LEARNING_CREDIT_HOURS: "Horas de crédito",
        SERVICE_LEARNING_PLACE: "Lugar de Sevicio de aprendizaje",
        SERVICE_LEARNING_SPONSOR: "Patrocinador / Entrenador / Supervisor",
        SERVICE_LEARNING_REFLECTION: "Reflexión",
        SERVICE_LEARNING_ADD_BUTTON: "Añadir",
        SERVICE_LEARNING_EDIT: "Editar Sevicio de aprendizaje",
        SERVICE_LEARNING_DELETE: "Borrar",
        SERVICE_LEARNING_SAVE: "Hecho",

        PROFILE_PROJECTS_TILE: "Proyectos",
        PROJECTS_NEW: "Nuevo Proyecto",
        PROJECT_NAME: "Nombre del Proyecto",
        PROJECT_DESCRIPTION: "Descripcion del proyecto",
        PROJECT_ADD_BUTTON: "Añadir",
        PROJECT_EDIT: "Editar el Proyecto",
        PROJECT_DELETE_BUTTON: "Borrar",
        PROJECT_SAVE_BUTTON: "Hecho",

        CIVICS_EXAM_TILE: "Examen de civismo",
        CIVICS_EXAM_START: "Iniciar examen de civismo",

        STUDENTS_TITLE: "Estudiantes",
        STUDENTS_SHOWING: "Mostrando",
        STUDENTS_OF: "de",
        STUDENTS_RESULTS: "Resultados",
        STUDENTS_SEARCH: "Buscar",
        STUDENTS_GRADES: "Grados",
        STUDENTS_SELECT_GRADE: "Grado",
        STUDENTS_TABLE_CURRENT: "De Hoy en Día",
        STUDENTS_TABLE_REQUESTS: "Solicitudes",
        STUDENTS_TABLE_STUDENT_COLUMN: "Nombre del estudiante",
        STUDENTS_TABLE_GRADE_COLUMN: "Grado",
        STUDENTS_TABLE_EMAIL_ADDRESS: "El e-mail",
        STUDENTS_TABLE_SCHOOL_COLUMN: "Escuela",
        STUDENTS_TABLE_PROGRESS: "Progreso",
        STUDENTS_GENERATE_INVITE: "Generar invitación",
        STUDENTS_CHOOSE_SCHOOL: "Elige escuela",
        STUDENTS_GENERATE_BUTTON: "Generar",
        STUDENTS_SELECT_SCHOOL: "Elige escuela",
        STUDENTS_ACCEPT_BUTTON: "Aceptar",
        STUDENTS_REJECT_BUTTON: "Rechazar",
        STUDENTS_FIRST_NAME: "Nombre",
        STUDENTS_LAST_NAME: "Apellido",
        STUDENTS_NEW_INVITE_TOKEN: "Nuevo token de invitación",

        TASKS_TITLE: "Tareas",
        TASKS_TABLE_STUDENT: "Estudiante",
        TASKS_TABLE_TASK_NAME: "Nombre de la tarea",
        TASKS_TABLE_TOTAL_POINTS: "Puntos Total",
        TASKS_TABLE_TASK_DESCRIPTION: "Descripción",
        TASKS_TABLE_TASK_CATEGORY: "Categoría",
        TASKS_TABLE_QUESTION: "Pregunta",
        TASKS_TABLE_ANSWER: "Respuesta",
        TASKS_TABLE_GRADE_BUTTON: "Calificar",
        TASKS_GRADE_QUESTION: "Grade Question",
        TASKS_STUDENT_NAME: "Nombre del estudiante",
        TASKS_STUDENT_ANSWER: "Respuesta del estudiante",
        TASKS_EDUCATOR_RESPONSE: "Respuesta del educador",
        TASKS_INCORRECT: "Incorrecto",
        ADMIN_TASKS_OK: "Bueno",
        ADMIN_TASKS_ARCHIVE_TASK: "Tarea de archivo",
        TASKS_CORRECT: "Correcto",
        TASKS_LESSON_NAME: "Nombre de la Lección",
        TASKS_INSTRUCTIONS: "Instrucciones",
        TASKS_ESTIMATED_TIME: "Hora Prevista",
        TASKS_POINTS: "Puntos",
        TASKS_START: "Iniciar",
        TASKS_RETRY: "Procesar de nuevo",
        TASKS_EDIT: "Editar",

        LEARNING_STYLE_START: "Comience a aprender estilo",
        LEARNING_STYLE_NEXT: "Continuar",
        LEARNING_STYLE_BACK: "Atrás",
        LEARNING_STYLE_FINISH: "Terminar",
        LEARNING_STYLE_RESULTS: " Resultados del estilo de aprendizaje",

        LEARNING_STYLE_TACTILE_1: "Resalte pasajes medida que las lee",
        LEARNING_STYLE_TACTILE_2: "Toma nota; transferir a una revista",
        LEARNING_STYLE_TACTILE_3: "Al azar dibujar a medida que lee",
        LEARNING_STYLE_TACTILE_4: "Mantiene lo que está leyendo",
        LEARNING_STYLE_TACTILE_5: "Camine a medida que se lee, si es posible",
        LEARNING_STYLE_TACTILE_6: "Participar activamente en clase",
        LEARNING_STYLE_TACTILE_7: "Elegir las clases que son el aprendizaje práctico",
        LEARNING_STYLE_TACTILE_8: "material de enseñar a otro estudiante",
        LEARNING_STYLE_TACTILE_9: "Actuar material a ser aprendido",
        LEARNING_STYLE_TACTILE_10: "Tome descansos y moverse mientras se estudia",

        LEARNING_STYLE_AUDITORY_1: "Leer en voz alta",
        LEARNING_STYLE_AUDITORY_2: "Use mnemotécnicos, jingles, y ryhmes",
        LEARNING_STYLE_AUDITORY_3: "Utilizar canciones para recordar cosas",
        LEARNING_STYLE_AUDITORY_4: "Estudio con alguien que dice que las notas",
        LEARNING_STYLE_AUDITORY_5: "Grabar conferencias y material de lectura para reproducir",
        LEARNING_STYLE_AUDITORY_6: "Escuchar a los maestros de instucciones, ya que ellos dicen",
        LEARNING_STYLE_AUDITORY_7: "Hablar a través de información",
        LEARNING_STYLE_AUDITORY_8: "Trabajo en una zona tranquila",
        LEARNING_STYLE_AUDITORY_9: "Dirección de lectura en voz alta si es posible",
        LEARNING_STYLE_AUDITORY_10: "Girar el gráfico y las ilustraciones en descripciones verbales",

        LEARNING_STYLE_VISUAL_1: "Tome varias notas",
        LEARNING_STYLE_VISUAL_2: "Sentarse en la parte delantera de la sala",
        LEARNING_STYLE_VISUAL_3: "Organizar el espacio estudio",
        LEARNING_STYLE_VISUAL_4: "Administrar el tiempo para la planificación",
        LEARNING_STYLE_VISUAL_5: "Use colores, gráficos e imágenes en las notas",
        LEARNING_STYLE_VISUAL_6: "Utilizar diferentes marcadores de colores",
        LEARNING_STYLE_VISUAL_7: "Utilizar pistas visuales",
        LEARNING_STYLE_VISUAL_8: "Hacer dibujos en los márgenes",
        LEARNING_STYLE_VISUAL_9: "Lee el texto",
        LEARNING_STYLE_VISUAL_10: "Participar en clase",

        COLLEGE_PAGE_TITLE: "Colegios",
        COLLEGE_SHOWING: "Mostrando",
        COLLEGE_OF: "de",
        COLLEGE_RESULTS: "Resultados",
        COLLEGE_SEARCH: "Buscar",
        COLLEGE_LOCATION_STATE: "Lugar/Estado",
        COLLEGE_INSTITUTION_TYPE: " Tipo de institución",
        COLLEGE_LEVEL_OF_INSTITUTION: "Nivel de institucion",
        COLLEGE_LEVEL_OF_DEGREE: "Nivel de grado",
        COLLEGE_PROGRAM: "Programa de la universidad",
        COLLEGE_MILES_AWAY: "kilómetros de distancia",
        COLLEGE_EXPLORE: "Explora la escuela",
        COLLEGE_PROFILE_WEBSITE_BUTTON: " Sitio web abierto",

        COLLEGE_PROFILE_OVERVIEW: "Descripción",
        COLLEGE_PROFILE_ADMISSIONS: "Admisiones",
        COLLEGE_ADMISSIONS_TYPE: "Tipo de institucion",
        COLLEGE_ADMISSIONS_INSTITUTION_LEVEL: "Nivel de institucion",
        COLLEGE_ADMISSIONS_CAMPUS: "Entorno del campus",
        COLLEGE_ADMISSIONS_STUDENTS: "Estudiantes aplicados",
        COLLEGE_ADMISSIONS_ACCEPTANCE: "Nivel de aceptación",

        COLLEGE_STUDENT_STATS: "Estadísticas del alumno",
        COLLEGE_ACADEMICS: "Académico",
        COLLEGE_ACADEMICS_HIGHEST: "El más alto grado ofrecido",
        COLLEGE_ACADEMICS_TOTAL_STUDENTS: "Numero total de estudiantes",
        COLLEGE_ACADEMICS_NUMBER_GRADS: "Número total de estudiantes de pregrado",
        COLLEGE_ACADEMICS_FOUR_YEAR: "Graduado en 4 años",
        COLLEGE_ACADEMICS_GRAD_SIX: "Graduado en 6 años",

        COLLEGE_TUITION: "Matrícula",
        COLLEGE_TUITION_ESTIMATED_COST: "Su costo estimado",
        COLLEGE_TUITION_IN: "En matrícula estatal",
        COLLEGE_TUITION_OUT: "Matrícula fuera del estado",
        COLLEGE_TUITION_ROOM: "Habitación y comida",
        COLLEGE_TUITION_BOOKS: "Libros y suministros",

        COLLEGE_PROGRAMS: "Programas",

        CAREERS_TITLE: "Carreras",
        CAREERS_SHOWING: "Mostrando",
        CAREERS_OF: "de",
        CAREERS_RESULTS: "Resultados",
        CAREERS_SEARCH: "Buscar",
        CAREER_CLUSTERS: "Grupos de Carreras",
        CAREER_PATHWAYS: "Grupos de Caminos",

        CAREER_PROFILE_DESCRIPTION: "Descripción del trabajo",
        CAREER_PROFILE_AVERAGE_WAGES: "Salario",
        CAREER_PROFILE_TASKS: "Tareas",
        CAREER_SKILLS: "Habilidades",
        CAREER_ABILITIES: "Inteligencia",
        CAREER_KNOWLEDGE: "Conocimiento",
        CAREER_ACTIVITIES: "Actividades",
        CAREER_PROFILE_DETAILED_ACTIVITIES: "Actividades Detalladas",
        CAREER_VIDEO_TITLE: "Video de carrera",
        CAREERS_RELATED: "Carreras relacionadas",
        MAJORS_RELATED: "Majors relacionados",
        CAREERS_MEDIAN_WAGE: "Salario mediano",
        CAREERS_EDUCATION: "Educación",

        EC_TILE: "Grupos extracurriculares",
        EC_SELECT: "Seleccionar Extracurricular",
        EC_EDIT: "Editar Actividad Extracurricular",
        EC_JOIN: "Únase a una organización extracurricular",
        EXTRA_CURRICULAR_TILE: "Actividad Extracurricular",
        EXTRA_CURRICULAR_NEW_TITLE: "Agregar organización extracurricular",
        EXTRA_CURRICULAR_EDIT_TITLE: "Editar actividad extracurricular",
        EXTRA_CURRICULAR_ADD_BUTTON: "Añadir",
        EXTRA_CURRICULAR_DELETE_BUTTON: "Borrar",
        EXTRA_CURRICULAR_SAVE_BUTTON: "Hecho",

        ADMIN_DIPLOMA_TAB: "Diploma",
        ADMIN_TABLE_DIPLOMA_NAME: "Nombre de Diploma",
        ADMIN_TABLE_DIPLOMA_YEAR: "Año de Diploma",
        ADMIN_TABLE_TOTAL_CREDIT: "Crédito total",
        ADMIN_TABLE_DATE_MODIFIED: "Fecha modificada",
        ADMIN_NEW_DIPLOMA: "Nuevo Diploma",
        ADMIN_SAVE_DIPLOMA: "Hecho",
        ADMIN_SELECT_YEAR: "Elige un año",
        ADMIN_EDIT_DIPLOMA: "Editar Diploma",
        ADMIN_ADD_DIPLOMA: "Añadir",


        ADMIN_COURSES_TAB: "Cursos",
        ADMIN_COURSES_NEW_COURSE_BUTTON: "Curso nuevo",
        ADMIN_COURSES_SELECT_SCHOOL: "Seleccionar escuela",
        ADMIN_COURSES_SCHOOL_NAME: "Nombre de la escuela",
        ADMIN_COURSES_TABLE_SUBJECT_NAME: "Nombre del tema",
        ADMIN_COURSES_TABLE_COURSE_CODE: "Código del curso",
        ADMIN_COURSES_TABLE_COURSE_NAME: "Nombre del Curso",
        ADMIN_COURSES_TABLE_CREDIT_HOURS: "Horas de crédito",
        ADMIN_COURSES_TABLE_DUAL_CREDIT_HOURS: "Horas de doble crédito",
        ADMIN_COURSE_TABLE_DATE_MODIFIED: "Fecha modificada",
        ADMIN_COURSE_SAVE: "Hecho",
        ADMIN_COURSE_SELECT_SUBJECT: "Elige tema",
        ADMIN_COURSE_SELECT_SCHOOL: "Elige escuela",
        ADMIN_EDIT_COURSE: "Editar Curso",
        ADMIN_COURSE_SCHOOL_NAME: "Nombre de escuela",
        ADMIN_ADD_BUTTON: "Añadir",

        ADMIN_TASKS_TAB: "Tareas",
        ADMIN_TASKS_NEW_TASK_BUTTON: "Tarea nuevo",
        ADMIN_TASKS_SEARCH: "Buscar",
        ADMIN_TASKS_CATEGORY_NAME: "Nombre de categoria",
        ADMIN_TASKS_TASKS_NAME: "Nombre de tarea",
        ADMIN_TASKS_TYPE: "Tipo",
        ADMIN_TASKS_ARCHIVE: "Archivo",
        ADMIN_TASKS_ARCHIVE_ASSIGNMENT: "Asignación de archivo",
        ADMIN_TASKS_FREQUENCY: "Frecuencia",
        ADMIN_TASKS_TIME: "Tiempo",
        ADMIN_TASKS_POINTS: "Puntos",
        ADMIN_TASKS_ACTIVATE_BUTTON: "Activo",
        ADMIN_TASKS_DEACTIVATE_BUTTON: "Desactivar",
        ADMIN_TASKS_SAVE: "Hecho",
        ADMIN_TASKS_TOTAL_POINT: "Puntos Total",
        ADMIN_TASKS_ESTIMATED_TIME: "Hora prevista",
        ADMIN_TASKS_TASK_DESCRIPTION: "Descripción de la tarea",
        ADMIN_TASKS_YEARS_ACTIVE: "Años Activo",
        ADMIN_TASKS_SELECT_CATEGORY: "Elige Categoría",
        ADMIN_TASKS_SELECT_VISIBILITY: "Select Visibility",
        ADMIN_TASKS_SELECT_FREQUENCY: "Elige Frecuencia",
        ADMIN_TASKS_EDIT_TASK: "Editar Tarea",
        ADMIN_TASKS_ADD: "Añadir",
        ADMIN_TASKS_ASSIGN_TASK: "Asignar Tarea",
        ADMIN_TASKS_ASSIGN_DETAIL: "Detalle de la asignación",
        ADMIN_TASKS_TARGET_TYPE: "Tipo de Objetivo",
        ADMIN_TASKS_TARGET_NAME: "Nombre del Objetivo",
        ADMIN_TASKS_TOTAL_STUDENTS: "Total de Estudiantes",

        ADMIN_SCHOOLS_TAB: "Escuelas",
        ADMIN_SCHOOLS_SCHOOL_NAME: "Nombre de Escuela",
        ADMIN_SCHOOLS_SCHOOL_TYPE: "Tipo de Escuela",
        ADMIN_SCHOOLS_ADDRESS_1: "Dirección 1",
        ADMIN_SCHOOLS_CITY: "Ciudad",
        ADMIN_SCHOOLS_STATE: "Estado",
        ADMIN_SCHOOLS_ZIP_CODE: "Código Postal",
        ADMIN_SCHOOLS_NEW_SCHOOL: "Nueva escuela",
        ADMIN_SCHOOLS_SAVE_BUTTON: "Hecho",
        ADMIN_SCHOOLS_SELECT_STATE: "Elige un estado",
        ADMIN_SCHOOLS_SELECT_SCHOOL_TYPE: "Elige el tipo de escuela",
        ADMIN_SCHOOLS_EDIT_SCHOOL: "Editar escuela",
        ADMIN_SCHOOLS_ADD_BUTTON: "Añadir",

        ADMIN_USERS_TAB: "Usarios",
        ADMIN_USERS_FIRST_LAST_NAM: "Nombre Completo",
        ADMIN_USERS_SCHOOLS: "Escuelas",
        ADMIN_USERS_EMAIL_ADDRESS: "El e-mail",
        ADMIN_USERS_ROLE: "Rol",
        ADMIN_USERS_STATUS: "Status",
        ADMIN_USERS_RESEND: "Enviar otra vez",
        ADMIN_USERS_ACTIVE: "Activo",
        ADMIN_USERS_NEW_PERSON: "Nueva Persona",
        ADMIN_USERS_ALREADY: "El E-mail ya utilizado por otra persona!",
        ADMIN_USERS_SELECT_ROLE: "Elige Rol",
        ADMIN_USERS_SCHOOL: "Escuela",
        ADMIN_USERS_ADD_BUTTON: "Añadir",
        ADMIN_USERS_EDIT_PERSON: "Editar Person",
        ADMIN_USERS_RESEND_INVITE: "Reenviar invitación",
        ADMIN_USERS_REACTIVATE: "Reactivar",
        ADMIN_USERS_DEACTIVATE: " Desactivar",
        ADMIN_USERS_SAVE_BUTTON: "Hecho",
        ADMIN_USERS_CONFIRMATION: "Confirmación",
        ADMIN_USERS_RESENT_CONF: "¡Se ha reenviado un correo electrónico de invitación con éxito a este usuario!",
        ADMIN_USERS_OK: "Ok",
        ADMIN_USERS_NEW_USER: "Nuevo Usario",
        ADMIN_USERS_FIRST_NAME: "Nombre de pila",
        ADMIN_USERS_LAST_NAME: "Apellido",

        PATHWAY_CREDIT_EARNED: "crédito obtenido",
        PATHWAY_DUAL_CRED_EARNED: "doble crédito obtenido",
        PATHWAY_ADD_COURSE: "Añadir curso",
        PATHWAY_NEW: "Nuevo camino",
        PATHWAY_DIPLOMA: "Diploma",
        PATHWAY_SELECT_DIPLOMA: "Elige Diploma",
        PATHWAY: "Camino",
        PATHWAY_SELECT: "Elige Camino",
        EDIT_PATHWAY: "Editar Camino",
        PATHWAY_SAVE: "Hecho",
        PATHWAY_JOIN_SCHOOL: "Unirse a la escuela",
        PATHWAY_SCHOOL_NAME: "Nombre de escuela",
        PATHWAY_JOIN: "Unirse",
        PATHWAY_COURSE: "Curso",
        PATHWAY_SELECT_COURSE: "Elige Curso",
        PATHWAY_CREDITS: "Crédito",
        PATHWAY_DUAL_CREDITS: "Doble Crédito",
        PATHWAY_ADD: "Añair",
        EDIT_COURSE: "Editar Curso",
        PATHWAY_NAME: "Camino",
        PATHWAY_REQUEST_1: "Solicitud para unirse",
        REQUEST_2: "Pendiente",
        PATHWAY_CREATE: "Crearn un Camino",
        PATHWAY_DUAL_CREDIT_HOURS: "Horas de doble crédito",
        PATHWAY_CRED_HRS: "Horas de crédito",

        PERSONALITY_TRAITS: "Rasgos de personalidad",
        FAV_SUBJECTS: " Asignaturas favoritas",
        INTEREST_INVENTORY: "Inventario de intereses",
        BACK: "Atrás",
        FINISH: "Termino",
        NEXT: "Continuar",
        INTEREST_RESULTSL: "Resultados del inventario de intereses",
        START_INTEREST: "Iniciar el inventario de intereses",
        YEAR: "Año",

        ASSESSMENT_NEW: "Exámen Nuevo",
        ASSESSMENT_TYPE: "Elige Tipo",
        ASSESSMENT_YEAR: "Elige Año",
        ASSESSMENT_NAME: "Nombre del exámen",
        ASSESSMENT_SELECT_MATH: "Elige matematicas",
        ASSESSMENT_WRITING: "Elige escritura",
        ASSESSMENT_SOCIAL: "Elige estudios sociales",
        ASSESSMENT_COMPOSITE: "Puntuacion compuesta",
        ASSESSMENT_MATH: "Puntaje Matemático",
        ASSESSMENT_TECHNICAL: "Puntuación técnica matemática",
        ASSESSMENT_SCIENCE: "Puntaje de ciencias",
        ASSESSMENT_ALGEBRA: "Puntuación de álgebra",
        ASSESSMENT_ENGLISH: "Puntaje de inglés",
        ASSESSMENT_READING: "Puntaje de lectura",
        ASSESSMENT_WRITING_SCORE: "Escritura de puntuación",
        ASSESSMENT_ADD_BUTTON: "Añadir",
        ASSESSMENT_EDIT: "Editar Exámen",
        ASSESSMENT_DELETE: "Borrar",
        ASSESSMENT_SAVE: "Hecho",
        ASSESSMENTS: "Exámenes",
        NONE: "No hay exámenes",

        GOAL_NEW: "Nuevo Objetivo",
        GOAL_NAME: "Nombre del Objectivo",
        GOAL_GRADE: "Grado",
        GOAL_SELECT_GRADE: "Elige Grado",
        GOAL_TYPE: "Tipo del Objetivo",
        GOAL_SELECT_GOAL_TYPE: "Elige el Tipo del Objetivo",
        GOAL_ACHIEVED: "Objetivo alcanzado?",
        YES: "Sí",
        NO: "No",
        GOAL_DESCRIPTION: "Descripción del Objectivo",
        ADD: "Añadir",
        GOAL_EDIT: "Editar Objetivo",
        DELETE: "Borrar",
        SAVE: "Hecho",
        DID_YOU: "¿Alcanzaste este objetivo?",
        GOAL_MARK_DONE: "Haga clic en Sí para marcar este objetivo completo",
        CANCEL: "Cancel",
        GOALS: "Objetivos",

        WBL_NEW: "Nuevo aprendizaje basado en trabajo",
        WBL_SELECT_TYPE: "Elige Tipo del Trabajo",
        WBL_PATHWAY: "Elige Camion",
        WBL_PLACE: "Lugar del trabajo",
        WBL_SPONSOR: "Patrocinador de la escuela",
        WBL_INDUSTRY_SPONSOR: "Patrocinador de negocios / industria",
        WBL_SKILLS: "Habilidades aprendidas",
        WBL_PAID: "¿Te pagaron?",
        WBL_YES: "Sí",
        WBL_PERFORMED: "Trabajo realizado",
        WBL: "Aprendizaje basado en trabajo",
        WBL_EDIT: "Editar aprendizaje basado en trabajo",

        NEW_RESUME: "Nuevo currículum",
        RESUME_SAVING: "Guardar su currículum",
        RESUME_CLICK: "Arrastre y suelte archivos soe aquí, o haga clic para seleccionar archivos",
        RESUME_REMOVE: "Borrar",
        RESUME: "Currículum",
        RESUME_VIEW: "Ver to Currículum",

        CONNECTIONS: "Conexiones",
        REQUESTED: "Pedido",
        CONNECT: "Conectar",
        STUDENTS: "Estudiantes",
        SHOWING: "Mostrando",
        RESULTS: "Resultados",

        GROUPS_OFFICERS: "Líderes",
        GROUPS_MEMBERS: "Personas"

      }
    }
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;