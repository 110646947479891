import { faHeart, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Card, CardContent,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import { setMajor } from '../../../redux/actions/major';
import './styles.css';

import { withTranslation } from 'react-i18next';
import styles from './style';

import Overview from "./overview/index";
import Related from "./related/index";

var AWS = require('aws-sdk')

var S3 = new AWS.S3({
    accessKeyId: 'AKIARE5YHMQIUQP6NVXT',
    secretAccessKey: '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',

    region: 'us-east-1'
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class MajorScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dataSource: [],
            logoPreview: null,
            logoPreviewBanner: null,
            setImageUploadOpen: false,
            setBannerUploadOpen: false,
            openEditProfile: false,
            userCurrentImage: "",
            bannerPreview: "",
            src: null,
            logoSrc: null,
            bannerSrc: null,
            crop: {},
            zoom: 8,
            majorName: '',
            currentZoom: 8,
            croppedBanner64: '',
            croppedLogo64: '',
            savingChanges: false,
            majorId: 0,
            value: 1
        }

        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
        this.onSaveChangeClick = this.onSaveChangeClick.bind(this)
    }

    componentDidMount() {
        this.timer = setTimeout(
            () => this.getData(this.props.match.params.majorId),
            3000,
        );
    }

    componentWillReceiveProps(newProps) {
        this.getData(newProps.match.params.majorId);
    }

    savingChangesClose = () => {
        this.setState({
            savingChanges: false
        })
    }

    getData(majorId) {

        fetch('https://api.tasselpathway.com/majors/' + majorId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false && responseJson.results[0].logo_image_url !== "") {

                    if (responseJson.results[0].banner_image_url !== "") {

                        this.setState({
                            isLoading: false,
                            bannerImageUrl: responseJson.results[0].banner_image_url,
                            logoImageUrl: responseJson.results[0].logo_image_url,
                            logoPreview: responseJson.results[0].logo_image_url,
                            bannerPreview: responseJson.results[0].banner_image_url,
                            bannerBase64: "default",
                            logoBase64: "default"
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            bannerImageUrl: require('../../../assets/default_banner.png'),
                            logoImageUrl: responseJson.results[0].logo_image_url,
                            logoPreview: responseJson.results[0].logo_image_url,
                            bannerPreview: require('../../../assets/default_banner.png'),
                            bannerBase64: "default",
                            logoBase64: "default"
                        })
                    }

                    this.setState({
                        isLoading: false,
                        majorId: responseJson.results[0].major_id,
                        majorName: responseJson.results[0].major_name,
                        isFavorite: responseJson.results[0].is_favorite
                    })

                    this.props.setMajor(
                        responseJson.results[0].major_id,
                        responseJson.results[0].major_name
                    );

                } else if (responseJson.error === false && responseJson.results[0].banner_image_url !== "") {

                    if (responseJson.results[0].logo_image_url !== "") {

                        this.setState({
                            isLoading: false,
                            bannerImageUrl: responseJson.results[0].banner_image_url,
                            logoImageUrl: responseJson.results[0].logo_image_url,
                            logoPreview: responseJson.results[0].logo_image_url,
                            bannerPreview: responseJson.results[0].banner_image_url,
                            bannerBase64: "default",
                            logoBase64: "default"
                        })

                    } else {

                        this.setState({
                            isLoading: false,
                            bannerImageUrl: responseJson.results[0].banner_image_url,
                            logoImageUrl: require('../../../assets/default_avatar.png'),
                            logoPreview: require('../../../assets/default_avatar.png'),
                            bannerPreview: responseJson.results[0].banner_image_url,
                            bannerBase64: "default",
                            logoBase64: "default"
                        })
                    }

                    this.setState({
                        isLoading: false,
                        majorId: responseJson.results[0].major_id,
                        majorName: responseJson.results[0].major_name,
                        isFavorite: responseJson.results[0].is_favorite
                    })

                    this.props.setMajor(
                        responseJson.results[0].major_id,
                        responseJson.results[0].major_name
                    );

                } else {
                    this.setState({
                        isLoading: false,
                        bannerImageUrl: require('../../../assets/default_banner.png'),
                        logoImageUrl: require('../../../assets/default_avatar.png'),
                        logoPreview: require('../../../assets/default_avatar.png'),
                        bannerPreview: require('../../../assets/default_banner.png'),
                        bannerBase64: "default",
                        logoBase64: "default"
                    })

                    this.setState({
                        isLoading: false,
                        majorId: responseJson.results[0].major_id,
                        majorName: responseJson.results[0].major_name,
                        isFavorite: responseJson.results[0].is_favorite
                    })

                    this.props.setMajor(
                        responseJson.results[0].major_id,
                        responseJson.results[0].major_name
                    );

                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onFavoriteClick() {

        if (this.state.isFavorite === 0) {

            fetch('https://api.tasselpathway.com/profile_favorite_major', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                body: JSON.stringify({
                    major_id: this.props.match.params.majorId,
                    person_id: this.props.personId,
                }),
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then((responseJson) => {

                    this.setState({
                        isFavorite: 1
                    })

                })
                .catch((error) => {

                });

        } else {
            fetch('https://api.tasselpathway.com/profile_favorite_major', {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                body: JSON.stringify({
                    major_id: this.props.match.params.majorId,
                    person_id: this.props.personId,
                }),
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    this.setState({
                        isFavorite: 0
                    })
                })
                .catch((error) => {

                });
        }
    }

    // Start code for Profile Image Upload 
    handleImageUploadOpen = () => {
        this.setState({
            setImageUploadOpen: true,
            crop: {
                unit: '%',
                width: 100,
                aspect: 1 / 1
            },
        })
    };

    handleImageUploadClose = () => {
        this.setState({
            setImageUploadOpen: false,
            logoSrc: null
        })
    };

    handleBannerUploadOpen = () => {
        this.setState({
            setBannerUploadOpen: true,
            crop: {
                unit: '%',
                width: 100,
                aspect: 5 / 1
            }
        })
    };

    handleBannerUploadClose = () => {
        this.setState({
            setBannerUploadOpen: false,
            bannenrSrc: null
        })
    };

    handleEditProfileClick = () => {
        this.setState({
            openEditProfile: true
        })
    };

    handleEditProfileClose = () => {
        this.setState({
            openEditProfile: false,
            logoPreview: null,
            selectedProfile: {}
        })
        this.timer = setTimeout(
            () => this.getData(this.props.match.params.majorId),
            4500,
        );
    };

    onClose() {
        this.setState({ setImageUploadOpen: false })
    }

    onCrop(logoPreview) {
        this.setState({
            logoPreview,
            userCurrentImage: logoPreview
        })
    }

    onBeforeFileLoad(elem) {
        if (elem.target.files[0].size > 2100000) {
            alert("File is too big!");
            elem.target.value = "";
        };
    }

    async onSaveBanner(base64, filePath) {

        var url = this.state.croppedBanner64;

        const response = await fetch(url)
            .then(res => res.blob())
            .then(blob => {
                const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')

                let params = {
                    Key: filePath,
                    Body: base64Data,
                    ContentType: blob.type,
                    Bucket: 'tassel.com',
                    ContentEncoding: 'base64'
                }

                this.uploadAWS(params);

                return blob.type;
            })

        return response;
    }

    async onSaveLogo(base64, filePath) {

        var url = this.state.croppedLogo64;

        const response = await fetch(url)
            .then(res => res.blob())
            .then(blob => {
                const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')

                let params = {
                    Key: filePath,
                    Body: base64Data,
                    ContentType: blob.type,
                    Bucket: 'tassel.com',
                    ContentEncoding: 'base64'
                }

                this.uploadAWS(params);

                return blob.type;
            })

        return response;
    }

    async uploadAWS(params) {
        await S3.upload(params, function (err, data, token) {
            return ''
        })
    }

    async onDownloadImages(logoBase64, logoFilePath, bannerBase64, bannerFilePath) {
        return Promise.all([this.onSaveLogo(logoBase64, logoFilePath), this.onSaveBanner(bannerBase64, bannerFilePath)]);
    }

    async onDownloadLogo(logoBase64, logoFilePath) {
        return Promise.all([this.onSaveLogo(logoBase64, logoFilePath)])
    }

    async onDownloadBanner(bannerBase64, bannerFilePath) {
        return Promise.all([this.onSaveBanner(bannerBase64, bannerFilePath)])
    }

    onSaveChangeClick(logoBase64, bannerBase64, firstName, lastName) {

        if (logoBase64 !== null && bannerBase64 !== null) {
            if (logoBase64.substring(0, 4) === "data" && bannerBase64.length > 10000) {
                var bannerFileName = Math.floor(Math.random() * 900000000000000000);
                var logoFileName = Math.floor(Math.random() * 900000000000000000);

                const bannerExt = logoBase64.split(';')[0].split('/')[1];
                const logoExt = bannerBase64.split(';')[0].split('/')[1];

                let bannerFilePath = "pathway/major/" + bannerFileName + '.' + bannerExt;
                let logoFilePath = "pathway/major/" + logoFileName + '.' + logoExt;

                this.onDownloadImages(logoBase64, logoFilePath, bannerBase64, bannerFilePath).then((result) => {

                    var component = this;
                    var props = this.props;

                    fetch('https://api.tasselpathway.com/majors', {
                        method: "PUT",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": 'application/json',
                            "token": props.token
                        },
                        credentials: "omit",
                        body: JSON.stringify({
                            aws_bucket: "tassel.com",
                            logo_aws_key: logoFilePath,
                            logo_content_type: result[0],
                            banner_aws_key: bannerFilePath,
                            banner_content_type: result[1],
                            major_id: props.match.params.majorId
                        })
                    })
                        .then(res => res.json())
                        .then(() => component.handleEditProfileClose(),
                            this.setState({
                                savingChanges: true
                            }))
                })
            } else if (logoBase64.length > 10000 && bannerBase64.length < 10000) {

                logoFileName = Math.floor(Math.random() * 900000000000000000);
                const logoExt = logoBase64.split(';')[0].split('/')[1];
                let logoFilePath = "pathway/major/" + logoFileName + '.' + logoExt;

                this.onDownloadLogo(logoBase64, logoFilePath).then((result) => {

                    var component = this;
                    var props = this.props;

                    fetch('https://api.tasselpathway.com/majors', {
                        method: "PATCH",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": 'application/json',
                            "token": props.token
                        },
                        credentials: "omit",
                        body: JSON.stringify({
                            aws_bucket: 'tassel.com',
                            logo_aws_key: logoFilePath,
                            logo_content_type: result[0],
                            major_id: props.match.params.majorId
                        })
                    })
                        .then(res => res.json())
                        .then(() => component.handleEditProfileClose(),
                            this.setState({
                                savingChanges: true
                            }))
                })

            } else if (logoBase64.length < 10000 && bannerBase64.length > 10000) {

                bannerFileName = Math.floor(Math.random() * 900000000000000000);

                const bannerExt = bannerBase64.split(';')[0].split('/')[1];

                let bannerFilePath = "pathway/major/" + bannerFileName + '.' + bannerExt;

                this.onDownloadBanner(bannerBase64, bannerFilePath).then((result) => {

                    var component = this;
                    var props = this.props;

                    fetch('https://api.tasselpathway.com/majors/banner/', {
                        method: "PATCH",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": 'application/json',
                            "token": props.token
                        },
                        credentials: "omit",
                        body: JSON.stringify({
                            aws_bucket: 'tassel.com',
                            banner_aws_key: bannerFilePath,
                            banner_content_type: result[0],
                            major_id: props.match.params.majorId
                        })
                    })
                        .then(res => res.json())
                        .then(() => component.handleEditProfileClose(),
                            this.setState({
                                savingChanges: true
                            }))
                })

            }

            setTimeout(function () {
                this.setState({ savingChanges: false });
            }.bind(this), 16000);  // wait 16 seconds, then reset to false
        }
    }

    onSelectBannerFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ bannerSrc: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onSelectLogoFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ logoSrc: reader.result }),
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onLogoCropComplete = crop => {
        this.makeClientCropLogo(crop);
    };

    onBannerCropComplete = crop => {
        this.makeClientCropBanner(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCropLogo(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const logoPreview = await this.getCroppedLogoImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ logoPreview });
        }
    }

    async makeClientCropBanner(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const bannerPreview = await this.getCroppedBannerImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ bannerPreview });
        }
    }

    getCroppedBannerImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);

                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    var base64data = reader.result;
                    this.setState({
                        croppedBanner64: base64data
                    })
                }
                resolve(this.fileUrl);
            }, "image/png");
        });
    }


    getCroppedLogoImg(image, crop, fileName) {

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);

                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    var base64data = reader.result;
                    this.setState({
                        croppedLogo64: base64data
                    })
                }
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    renderLoader() {
        return (
            <div style={{ position: 'absolute' }} className="lds-dual-ring" id="hideMeComplete"></div>
        )
    }

    render() {
        const { i18n } = this.props;
        const { classes } = this.props;
        const { bannerSrc, logoSrc } = this.state;

        return (
            <div className={classes.root}>

                <Dialog
                    open={this.state.savingChanges}
                    onClose={this.savingChangesClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='sm'
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title" titleStyle={{ textAlign: "center" }}>
                        <strong>Give us a second...</strong><br></br>We're saving your changes.
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div style={{ display: 'flex', justifyContent: 'space-around', padding: 20 }}>
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <Dialog onClose={() => this.handleEditProfileClose()}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openEditProfile}
                    maxWidth='md'
                    style={{ maxWidth: 800, minWidth: 800, margin: 'auto', }}>

                    <DialogTitle id="customized-dialog-title" style={{ paddingRight: 50 }} onClose={this.handleEditProfileClose}>
                        Edit Profile
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid item style={{ width: '100%' }}>

                            <div style={{ height: 125, backgroundColor: '#e0e0e0' }}>
                                {this.state.bannerImageUrl === "" ?
                                    <img src={require('../../../assets/default_banner.png')}
                                        style={{ minWidth: 656, maxHeight: 135, minHeight: 135, objectFit: 'cover' }} alt="" />
                                    : this.state.bannerPreview === null ?
                                        <img src={this.state.bannerImageUrl}
                                            style={{ minWidth: 656, maxHeight: 125, minHeight: 125, objectFit: 'cover' }} alt="" />
                                        : <img src={this.state.bannerPreview}
                                            style={{ minWidth: 656, maxHeight: 125, minHeight: 125, objectFit: 'cover' }} alt="" />
                                }
                            </div>

                            <Card style={{ minHeight: 150, maxHeight: 150 }}>
                                <CardContent>

                                    <div style={{ position: 'absolute', top: 0, marginTop: 165 }}>

                                        {this.state.logoImageUrl === "" ?
                                            <img src={require('../../../assets/default_avatar.png')}
                                                style={{ borderRadius: 100, border: '2px solid #FFF', minWidth: 120, maxWidth: 120, minHeight: 120, maxHeight: 120 }} alt="" />
                                            : this.state.logoPreview === null ?

                                                <img src={this.state.logoImageUrl}
                                                    style={{ borderRadius: 100, border: '2px solid #FFF', minWidth: 120, maxWidth: 120, minHeight: 120, maxHeight: 120 }} alt="" />

                                                : <img src={this.state.logoPreview}
                                                    style={{ borderRadius: 100, border: '2px solid #FFF', minWidth: 120, maxWidth: 120, minHeight: 120, maxHeight: 120 }} alt="" />

                                        }
                                    </div>

                                    <div style={{ marginLeft: 160 }}>
                                        <Grid container>

                                            <Grid item xs={4}>
                                                <Grid container style={{ float: 'right', width: '100%' }}>

                                                    <Dialog onClose={() => this.handleImageUploadClose()}
                                                        aria-labelledby="customized-dialog-title"
                                                        open={this.state.setImageUploadOpen}
                                                        maxWidth='sm'
                                                        fullWidth={true}>
                                                        <DialogTitle id="customized-dialog-title" onClose={this.handleImageUploadClose}>
                                                        </DialogTitle>
                                                        <DialogContent dividers>
                                                            <div>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <input type="file" name="file" id="file" onChange={this.onSelectLogoFile}
                                                                        style={{ width: 0.1, height: 0.1, opacity: 0, overflow: 'hidden', position: "absolute", zIndex: '1' }} />
                                                                    <label className='chooseFile' style={styles.inputLogo} for="file">Click to choose file</label>
                                                                </div>

                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                    {this.state.logoSrc && (
                                                                        <Fragment>
                                                                            <div style={{ maxWidth: 500, margin: 'auto', marginBottom: 0 }}>
                                                                                <ReactCrop
                                                                                    src={logoSrc}
                                                                                    crop={this.state.crop}
                                                                                    onImageLoaded={this.onImageLoaded}
                                                                                    onComplete={this.onLogoCropComplete}
                                                                                    onChange={this.onCropChange}
                                                                                    circularCrop={true}
                                                                                    ruleOfThirds={true}
                                                                                />
                                                                            </div>
                                                                        </Fragment>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button variant="contained" style={{ marginRight: 20 }}
                                                                onClick={this.handleImageUploadClose}
                                                                color="primary">
                                                                <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Save Changes</Typography>
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>

                                                    <Dialog
                                                        onClose={() => this.handleBannerUploadClose()}
                                                        aria-labelledby="customized-dialog-title"
                                                        open={this.state.setBannerUploadOpen}
                                                        maxWidth='xl'
                                                        fullWidth={true}>

                                                        <DialogTitle id="customized-dialog-title" onClose={this.handleBannerUploadClose}>

                                                        </DialogTitle>
                                                        <DialogContent dividers>
                                                            <div>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <input type="file" name="file" id="file"
                                                                        onChange={this.onSelectBannerFile}
                                                                        style={{ width: 0.1, height: 0.1, opacity: 0, overflow: 'hidden', position: "absolute", zIndex: '1' }} />
                                                                    <label className='chooseFile' style={styles.inputLogo}
                                                                        for="file">Click to choose file</label>
                                                                </div>
                                                                {this.state.bannerSrc && (
                                                                    <Fragment>
                                                                        <div style={{ maxWidth: 1100, margin: 'auto' }}>
                                                                            <ReactCrop
                                                                                src={bannerSrc}
                                                                                crop={this.state.crop}
                                                                                onImageLoaded={this.onImageLoaded}
                                                                                onComplete={this.onBannerCropComplete}
                                                                                onChange={this.onCropChange}
                                                                                ruleOfThirds={true}
                                                                            />
                                                                        </div>
                                                                    </Fragment>
                                                                )}
                                                            </div>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button variant="contained"
                                                                onClick={this.handleBannerUploadClose} color="primary">
                                                                <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Save changes</Typography>
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>

                                                    <IconButton style={{ position: 'absolute', right: '72%' }} variant="outlined"
                                                        color="primary" onClick={() => this.handleImageUploadOpen()} >
                                                        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPencilAlt} />
                                                    </IconButton>
                                                    <IconButton style={{ position: 'absolute', right: '5%', }} variant="outlined"
                                                        color="primary" onClick={() => this.handleBannerUploadOpen()} >
                                                        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPencilAlt} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" style={{ marginRight: 20 }}
                            onClick={() => this.onSaveChangeClick(this.state.croppedLogo64,
                                this.state.croppedBanner64, this.state.editedFirstName, this.state.editedLastName)}>
                            <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Save Changes</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>


                <Grid container style={styles.itemContainerStyle}>

                    <Grid item xs={1}></Grid>

                    <Grid item xs={10}>

                        <div style={{ height: 200, backgroundColor: '#e0e0e0' }}>
                            <img src={this.state.bannerImageUrl} style={{ width: '100%', height: 200, objectFit: 'cover' }} alt="" />
                        </div>

                        <Card>
                            <CardContent>

                                {this.props.taskId !== 0 && this.props.taskId !== undefined ?
                                    <div style={{ position: 'absolute', top: 0, marginTop: 210, height: 150, width: 150, borderRadius: 100, border: '2px solid #FFF' }}>
                                        <img src={this.state.logoImageUrl} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                    </div>
                                    :
                                    <div style={{ position: 'absolute', top: 0, marginTop: 250, height: 150, width: 150, borderRadius: 100, border: '2px solid #FFF' }}>
                                        <img src={this.state.logoImageUrl} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                    </div>
                                }

                                <div style={{ marginLeft: 160 }}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Grid container style={{ width: '100%' }}>
                                                <div style={{ float: 'left' }}>

                                                    {this.state.majorName.length > 15 ?
                                                        <Typography style={{ fontSize: 18, marginTop: 5 }}>{this.state.majorName}</Typography>
                                                        :
                                                        <Typography style={{ fontSize: 26, marginTop: 5 }}>{this.state.majorName}</Typography>
                                                    }

                                                </div>
                                                <div style={{ float: 'right' }}>
                                                    {this.state.isFavorite === 1 ?
                                                        <IconButton color="primary" onClick={() => this.onFavoriteClick()}>
                                                            <FontAwesomeIcon icon={faHeart} />
                                                        </IconButton>
                                                        :
                                                        <IconButton onClick={() => this.onFavoriteClick()}>
                                                            <FontAwesomeIcon icon={faHeart} style={{ color: '#777' }} />
                                                        </IconButton>
                                                    }
                                                </div>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                                            {this.props.personId === 69 ?
                                                <IconButton
                                                    variant="outlined" color="primary" onClick={() => this.handleEditProfileClick()} >
                                                    <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPencilAlt} />
                                                </IconButton>
                                                : null}
                                        </Grid>
                                    </Grid>
                                </div>
                                <br style={{ clear: 'both' }} />
                                <div>
                                    <Typography style={{ marginTop: 5, fontSize: 12 }}></Typography>
                                </div>
                            </CardContent>
                        </Card>

                        <br />

                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <Overview props={this.props} majorId={this.props.match.params.majorId} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>

                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={4}>

                                <Related props={this.props} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </div>
        );
    }
}

MajorScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        personId: state.auth.personId,
        taskId: state.task.taskId,
        token: state.auth.token,
        userRoleId: state.auth.userRoleId,
        majorId: state.major.majorId
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMajor: (majorId, majorName) => { dispatch(setMajor(majorId, majorName)); },
        onLogout: () => { dispatch(logout()); }
    }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MajorScreen)));