import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button, Dialog, DialogActions, DialogContent, FormControl,
  Grid,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';
const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class SchoolScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      value: 0,
      height: 100,
      isNewOpen: false,
      isEditOpen: false,
      showNew: false,
      schoolId: 0,
      newSchoolName: '',
      newAddress1: '',
      newAddress2: '',
      newCity: '',
      newStateProvince: 0,
      newSchoolTypeId: 0,
      newZipCode: '',
      selectedItem: {},
      dataSource: [],
      filteredDataSource: [],
      schoolNameValid: true,
      address1Valid: true,
      cityValid: true,
      stateProvinceValid: true,
      zipCodeValid: true,
      schoolTypeIdValid: true,
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc'
    }

    this.onSearch = this.onSearch.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  updateDimensions() {

    let update_height = 0;

    if (this.props.nnouncementAlert !== '') {
      update_height = window.innerHeight - 350;
    } else {
      update_height = window.innerHeight - 260;
    }
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.setState({
      isLoading: true
    })

    this.getData();
    this.updateDimensions();
  }

  getData() {

    fetch('https://api.tasselpathway.com/schools', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          dataSource: responseJson.schools,
          filteredDataSource: responseJson.schools
        });

        this.props.setProgress(100);

      })
      .catch((error) => {

      });

    fetch('https://api.tasselpathway.com/school/types/', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          schoolTypes: responseJson.types
        });

      })
      .catch((error) => {

      });
  }

  onAddSchool() {

    var formValid = this.validateNewForm();

    if (formValid === true) {

      fetch('https://api.tasselpathway.com/schools', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          school_type_id: this.state.newSchoolTypeId,
          school_name: this.state.newSchoolName,
          address1: this.state.newAddress1,
          address2: this.state.newAddress2,
          city: this.state.newCity,
          state_province: this.state.newStateProvince,
          zip_code: this.state.newZipCode
        })
      })
        .then(response => {
          return response.json()
        })
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isNewOpen: false
            })
          }

          this.getData();
        })
        .catch((e) => {
          console.warn(e);
        });
    }
  }

  onSaveSchool() {

    var formValid = this.validateForm();

    if (formValid === true) {

      fetch('https://api.tasselpathway.com/schools', {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          school_name: this.state.selectedItem.school_name,
          school_type_id: this.state.selectedItem.school_type_id,
          address1: this.state.selectedItem.address1,
          address2: this.state.selectedItem.address2,
          city: this.state.selectedItem.city,
          state_province: this.state.selectedItem.state_province,
          zip_code: this.state.selectedItem.zip_code,
          school_id: this.state.selectedItem.school_id
        })
      })
        .then(response => { return response.json() })
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isEditOpen: false
            })

            this.getData();
          }
        })
        .catch((e) => {
          console.warn(e);
        });
    }
  }

  validateForm() {

    var result = true;
    var snValid = true;
    var stValid = true;
    var a1Valid = true;
    var cValid = true;
    var sValid = true;
    var zValid = true;

    if (this.state.selectedItem.school_name === "") {
      snValid = false;
      result = false;
    }

    if (this.state.selectedItem.school_type_id === 0) {
      stValid = false;
      result = false;
    }

    if (this.state.selectedItem.address1 === "") {
      a1Valid = false;
      result = false;
    }

    if (this.state.selectedItem.city === "") {
      cValid = false;
      result = false;
    }

    if (this.state.selectedItem.state_province === 0) {
      sValid = false;
      result = false;
    }

    if (this.state.selectedItem.zip_code === "") {
      zValid = false;
      result = false;
    }

    this.setState({
      schoolNameValid: snValid,
      schoolTypeIdValid: stValid,
      address1Valid: a1Valid,
      cityValid: cValid,
      stateProvinceValid: sValid,
      zipCodeValid: zValid
    })

    return result;
  }

  validateNewForm() {

    var result = true;
    var snValid = true;
    var stValid = true;
    var a1Valid = true;
    var cValid = true;
    var sValid = true;
    var zValid = true;

    if (this.state.newSchoolName === "") {
      snValid = false;
      result = false;
    }

    if (this.state.newSchoolTypeId === 0) {
      stValid = false;
      result = false;
    }

    if (this.state.newAddress1 === "") {
      a1Valid = false;
      result = false;
    }

    if (this.state.newCity === "") {
      cValid = false;
      result = false;
    }

    if (this.state.newStateProvince === 0) {
      sValid = false;
      result = false;
    }

    if (this.state.newZipCode === "") {
      zValid = false;
      result = false;
    }

    this.setState({
      schoolNameValid: snValid,
      schoolTypeIdValid: stValid,
      address1Valid: a1Valid,
      cityValid: cValid,
      stateProvinceValid: sValid,
      zipCodeValid: zValid
    })

    return result;
  }

  onSearch(event) {

    const newData = this.state.dataSource.filter(function (item) {
      const itemData = item.school_name.toUpperCase()
      const textData = event.target.value.toUpperCase()
      return itemData.indexOf(textData) > -1
    })
    this.setState({
      filteredDataSource: newData,
      searchText: event.target.value
    })
  }

  onNewClick = () => {
    this.setState({
      isNewOpen: true
    })
  };

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onEditClick(event, item) {
    this.setState({
      isEditOpen: true,
      selectedItem: item
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onRowClick(event, item) {

    this.setState({
      selectedSchool: item,
      isEditOpen: false
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  renderSchoolTypes() {
    if (this.state.schoolTypes === undefined) return null;

    const content = this.state.schoolTypes.map((item, key) => {
      return (
        <MenuItem key={key} value={item.school_type_id}>{item.school_type_name}</MenuItem>
      )
    })
    return content;
  }

  onSchoolNameChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.school_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onAddress1Change(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.address1 = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onAddress2Change(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.address2 = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onStateChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.state_province = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onStateTypeChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.state_type_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onCityChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.city = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onZipCodeChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.zip_code = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.filteredDataSource.length - this.state.currentPage * this.state.rowsPerPage);

    return (

      <div className={classes.root}>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            {i18n.t("ADMIN_SCHOOLS_NEW_SCHOOL")}
          </DialogTitle>

          <DialogContent>
            {this.props.selectedLanguage === "esp" ?
              <FormControl fullWidth className={classes.formControl}
                error={this.state.schoolNameValid}>
                <TextField autoFocus margin="dense" id="schoolName" error={!this.state.schoolNameValid}
                  onChange={(event) => this.setState({ newSchoolName: event.target.value })} label="Nombre de escuela" type="text" />
              </FormControl>
              :
              <FormControl fullWidth className={classes.formControl}
                error={this.state.schoolNameValid}>
                <TextField autoFocus margin="dense" id="schoolName" error={!this.state.schoolNameValid}
                  onChange={(event) => this.setState({ newSchoolName: event.target.value })} label="School Name" type="text" />
              </FormControl>
            }

            <FormControl className={classes.formControl} style={{ marginTop: 5 }}
              fullWidth error={!this.state.schoolTypeIdValid}>
              <InputLabel htmlFor="newSchoolTypeId">{i18n.t("ADMIN_SCHOOLS_SELECT_SCHOOL_TYPE")}</InputLabel>
              <Select error={!this.state.schoolTypeIdValid}
                value={this.state.newSchoolTypeId}
                onChange={(event) => this.setState({ newSchoolTypeId: event.target.value })}
                inputProps={{
                  name: 'newSchoolTypeId',
                  id: 'newSchoolTypeId',
                }}>
                <MenuItem disabled value={0}>{i18n.t("ADMIN_SCHOOLS_SELECT_SCHOOL_TYPE")}</MenuItem>
                {this.renderSchoolTypes()}
              </Select>
            </FormControl>
            {this.props.selectedLanguage === "esp" ?
              <FormControl fullWidth error={!this.state.address1Valid}
                className={classes.formControl}>
                <TextField margin="dense" id="address1" error={!this.state.address1Valid}
                  onChange={(event) => this.setState({ newAddress1: event.target.value })} label="Dirección 1" type="text" />
              </FormControl>
              :
              <FormControl fullWidth error={!this.state.address1Valid}
                className={classes.formControl}>
                <TextField margin="dense" id="address1" error={!this.state.address1Valid}
                  onChange={(event) => this.setState({ newAddress1: event.target.value })} label="Address 1" type="text" />
              </FormControl>
            }
            {this.props.selectedLanguage === "esp" ?
              <FormControl fullWidth
                className={classes.formControl}>
                <TextField margin="dense" id="address2"
                  onChange={(event) => this.setState({ newAddress2: event.target.value })} label="Dirección 2" type="text" />
              </FormControl>
              :
              <FormControl fullWidth
                className={classes.formControl}>
                <TextField margin="dense" id="address2"
                  onChange={(event) => this.setState({ newAddress2: event.target.value })} label="Address 2" type="text" />
              </FormControl>
            }

            <Grid container>
              <Grid item xs={5}>
                {this.props.selectedLanguage === "esp" ?
                  <FormControl fullWidth error={!this.state.cityValid}
                    className={classes.formControl}>
                    <TextField margin="dense" id="city" error={!this.state.cityValid}
                      onChange={(event) => this.setState({ newCity: event.target.value })} label="Ciudad" type="text" />
                  </FormControl>
                  :
                  <FormControl fullWidth error={!this.state.cityValid}
                    className={classes.formControl}>
                    <TextField margin="dense" id="city" error={!this.state.cityValid}
                      onChange={(event) => this.setState({ newCity: event.target.value })} label="City" type="text" />
                  </FormControl>
                }
              </Grid>
              <Grid item xs={4}>

                <FormControl className={classes.formControl} style={{ marginTop: 5 }}
                  fullWidth error={!this.state.stateProvinceValid}>
                  <InputLabel htmlFor="newStateProvince">{i18n.t("ADMIN_SCHOOLS_SELECT_STATE")}</InputLabel>
                  <Select error={!this.state.stateProvinceValid}
                    value={this.state.newStateProvince}
                    onChange={(event) => this.setState({ newStateProvince: event.target.value })}
                    inputProps={{
                      name: 'newStateProvince',
                      id: 'newStateProvince',
                    }}>
                    <MenuItem disabled value={0}>Select State</MenuItem>
                    <MenuItem value="AL">Alabama</MenuItem>
                    <MenuItem value="AK">Alaska</MenuItem>
                    <MenuItem value="AZ">Arizona</MenuItem>
                    <MenuItem value="AR">Arkansas</MenuItem>
                    <MenuItem value="CA">California</MenuItem>
                    <MenuItem value="CO">Colorado</MenuItem>
                    <MenuItem value="CT">Connecticut</MenuItem>
                    <MenuItem value="DE">Delaware</MenuItem>
                    <MenuItem value="DC">District Of Columbia</MenuItem>
                    <MenuItem value="FL">Florida</MenuItem>
                    <MenuItem value="GA">Georgia</MenuItem>
                    <MenuItem value="HI">Hawaii</MenuItem>
                    <MenuItem value="ID">Idaho</MenuItem>
                    <MenuItem value="IL">Illinois</MenuItem>
                    <MenuItem value="IN">Indiana</MenuItem>
                    <MenuItem value="IA">Iowa</MenuItem>
                    <MenuItem value="KS">Kansas</MenuItem>
                    <MenuItem value="KY">Kentucky</MenuItem>
                    <MenuItem value="LA">Louisiana</MenuItem>
                    <MenuItem value="ME">Maine</MenuItem>
                    <MenuItem value="MD">Maryland</MenuItem>
                    <MenuItem value="MA">Massachusetts</MenuItem>
                    <MenuItem value="MI">Michigan</MenuItem>
                    <MenuItem value="MN">Minnesota</MenuItem>
                    <MenuItem value="MS">Mississippi</MenuItem>
                    <MenuItem value="MO">Missouri</MenuItem>
                    <MenuItem value="MT">Montana</MenuItem>
                    <MenuItem value="NE">Nebraska</MenuItem>
                    <MenuItem value="NV">Nevada</MenuItem>
                    <MenuItem value="NH">New Hampshire</MenuItem>
                    <MenuItem value="NJ">New Jersey</MenuItem>
                    <MenuItem value="NM">New Mexico</MenuItem>
                    <MenuItem value="NY">New York</MenuItem>
                    <MenuItem value="NC">North Carolina</MenuItem>
                    <MenuItem value="ND">North Dakota</MenuItem>
                    <MenuItem value="OH">Ohio</MenuItem>
                    <MenuItem value="OK">Oklahoma</MenuItem>
                    <MenuItem value="OR">Oregon</MenuItem>
                    <MenuItem value="PA">Pennsylvania</MenuItem>
                    <MenuItem value="RI">Rhode Island</MenuItem>
                    <MenuItem value="SC">South Carolina</MenuItem>
                    <MenuItem value="SD">South Dakota</MenuItem>
                    <MenuItem value="TN">Tennessee</MenuItem>
                    <MenuItem value="TX">Texas</MenuItem>
                    <MenuItem value="UT">Utah</MenuItem>
                    <MenuItem value="VT">Vermont</MenuItem>
                    <MenuItem value="VA">Virginia</MenuItem>
                    <MenuItem value="WA">Washington</MenuItem>
                    <MenuItem value="WV">West Virginia</MenuItem>
                    <MenuItem value="WI">Wisconsin</MenuItem>
                    <MenuItem value="WY">Wyoming</MenuItem>
                  </Select>
                </FormControl>

              </Grid>
              <Grid item xs={3} >
                {this.props.selectedLanguage === "esp" ?
                  <FormControl fullWidth error={!this.state.zipCodeValid}
                    className={classes.formControl}>
                    <TextField margin="dense" id="zipCode" error={!this.state.zipCodeValid}
                      onChange={(event) => this.setState({ newZipCode: event.target.value })} label="Código Postal" type="text" />
                  </FormControl>
                  :
                  <FormControl fullWidth error={!this.state.zipCodeValid}
                    className={classes.formControl}>
                    <TextField margin="dense" id="zipCode" error={!this.state.zipCodeValid}
                      onChange={(event) => this.setState({ newZipCode: event.target.value })} label="Zip Code" type="text" />
                  </FormControl>
                }
              </Grid>
            </Grid>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onAddSchool()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 0, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_SCHOOLS_ADD_BUTTON")}
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            {i18n.t("ADMIN_SCHOOLS_EDIT_SCHOOL")}
          </DialogTitle>

          <DialogContent>
            {this.props.selectedLanguage === "esp" ?
              <FormControl fullWidth className={classes.formControl}
                error={this.state.schoolNameValid}>
                <TextField autoFocus margin="dense" id="school_name"
                  value={this.state.selectedItem.school_name}
                  error={!this.state.schoolNameValid}
                  onChange={(event) => this.onSchoolNameChange(event)}
                  label="Nombre de escuela" type="text" />
              </FormControl>
              :
              <FormControl fullWidth className={classes.formControl}
                error={this.state.schoolNameValid}>
                <TextField autoFocus margin="dense" id="school_name"
                  value={this.state.selectedItem.school_name}
                  error={!this.state.schoolNameValid}
                  onChange={(event) => this.onSchoolNameChange(event)}
                  label="School Name" type="text" />
              </FormControl>
            }

            <FormControl fullWidth style={{ marginTop: 5 }}
              error={!this.state.schoolTypeIdValid}
              className={classes.formControl}>
              <InputLabel>{i18n.t("ADMIN_SCHOOLS_SELECT_SCHOOL_TYPE")}</InputLabel>
              <Select
                value={this.state.selectedItem.school_type_id} margin="dense"
                onChange={(event) => this.onStateTypeChange(event)} error={!this.state.schoolTypeIdValid}
                input={<Input name="schoolTypeId" id="schoolTypeId" />}>
                <MenuItem value="0" disabled>{i18n.t("ADMIN_SCHOOLS_SELECT_SCHOOL_TYPE")}</MenuItem>
                {this.renderSchoolTypes()}
              </Select>
            </FormControl>
            {this.props.selectedLanguage === "esp" ?
              <FormControl fullWidth
                className={classes.formControl}
                error={!this.state.address1Valid}>
                <TextField margin="dense" id="address1"
                  value={this.state.selectedItem.address1}
                  error={!this.state.address1Valid}
                  onChange={(event) => this.onAddress1Change(event)}
                  label="Dirección 1" type="text" />
              </FormControl>
              :
              <FormControl fullWidth
                className={classes.formControl}
                error={!this.state.address1Valid}>
                <TextField margin="dense" id="address1"
                  value={this.state.selectedItem.address1}
                  error={!this.state.address1Valid}
                  onChange={(event) => this.onAddress1Change(event)}
                  label="Address 1" type="text" />
              </FormControl>
            }
            {this.props.selectedLanguage === "esp" ?
              <FormControl fullWidth className={classes.formControl}>
                <TextField margin="dense" id="address2"
                  value={this.state.selectedItem.address2}

                  onChange={(event) => this.onAddress2Change(event)}
                  label="Dirección 2" type="text" />
              </FormControl>
              :
              <FormControl fullWidth className={classes.formControl}>
                <TextField margin="dense" id="address2"
                  value={this.state.selectedItem.address2}

                  onChange={(event) => this.onAddress2Change(event)}
                  label="Address 2" type="text" />
              </FormControl>
            }

            <Grid container>
              <Grid item xs={5}>
                {this.props.selectedLanguage === "esp" ?
                  <FormControl fullWidth
                    error={!this.state.cityValid}
                    className={classes.formControl}>
                    <TextField margin="dense" id="city"
                      value={this.state.selectedItem.city}
                      error={!this.state.cityValid}
                      onChange={(event) => this.onCityChange(event)}
                      label="Ciudad" type="text" />
                  </FormControl>
                  :
                  <FormControl fullWidth
                    error={!this.state.cityValid}
                    className={classes.formControl}>
                    <TextField margin="dense" id="city"
                      value={this.state.selectedItem.city}
                      error={!this.state.cityValid}
                      onChange={(event) => this.onCityChange(event)}
                      label="City" type="text" />
                  </FormControl>
                }
              </Grid>

              <Grid item xs={4} style={{ marginTop: 8 }}>
                <FormControl fullWidth className={classes.formControl}
                  error={!this.state.stateProvinceValid}>
                  <InputLabel>{i18n.t("ADMIN_SCHOOLS_SELECT_STATE")}</InputLabel>
                  <Select value={this.state.selectedItem.state_province} margin="dense"
                    error={!this.state.stateProvinceValid}
                    onChange={(event) => this.onStateChange(event)}
                    input={<Input name="state_province" id="state_province" />}>
                    <MenuItem value="" disabled>{i18n.t("ADMIN_SCHOOLS_SELECT_STATE")}</MenuItem>
                    <MenuItem value="AL">Alabama</MenuItem>
                    <MenuItem value="AK">Alaska</MenuItem>
                    <MenuItem value="AZ">Arizona</MenuItem>
                    <MenuItem value="AR">Arkansas</MenuItem>
                    <MenuItem value="CA">California</MenuItem>
                    <MenuItem value="CO">Colorado</MenuItem>
                    <MenuItem value="CT">Connecticut</MenuItem>
                    <MenuItem value="DE">Delaware</MenuItem>
                    <MenuItem value="DC">District Of Columbia</MenuItem>
                    <MenuItem value="FL">Florida</MenuItem>
                    <MenuItem value="GA">Georgia</MenuItem>
                    <MenuItem value="HI">Hawaii</MenuItem>
                    <MenuItem value="ID">Idaho</MenuItem>
                    <MenuItem value="IL">Illinois</MenuItem>
                    <MenuItem value="IN">Indiana</MenuItem>
                    <MenuItem value="IA">Iowa</MenuItem>
                    <MenuItem value="KS">Kansas</MenuItem>
                    <MenuItem value="KY">Kentucky</MenuItem>
                    <MenuItem value="LA">Louisiana</MenuItem>
                    <MenuItem value="ME">Maine</MenuItem>
                    <MenuItem value="MD">Maryland</MenuItem>
                    <MenuItem value="MA">Massachusetts</MenuItem>
                    <MenuItem value="MI">Michigan</MenuItem>
                    <MenuItem value="MN">Minnesota</MenuItem>
                    <MenuItem value="MS">Mississippi</MenuItem>
                    <MenuItem value="MO">Missouri</MenuItem>
                    <MenuItem value="MT">Montana</MenuItem>
                    <MenuItem value="NE">Nebraska</MenuItem>
                    <MenuItem value="NV">Nevada</MenuItem>
                    <MenuItem value="NH">New Hampshire</MenuItem>
                    <MenuItem value="NJ">New Jersey</MenuItem>
                    <MenuItem value="NM">New Mexico</MenuItem>
                    <MenuItem value="NY">New York</MenuItem>
                    <MenuItem value="NC">North Carolina</MenuItem>
                    <MenuItem value="ND">North Dakota</MenuItem>
                    <MenuItem value="OH">Ohio</MenuItem>
                    <MenuItem value="OK">Oklahoma</MenuItem>
                    <MenuItem value="OR">Oregon</MenuItem>
                    <MenuItem value="PA">Pennsylvania</MenuItem>
                    <MenuItem value="RI">Rhode Island</MenuItem>
                    <MenuItem value="SC">South Carolina</MenuItem>
                    <MenuItem value="SD">South Dakota</MenuItem>
                    <MenuItem value="TN">Tennessee</MenuItem>
                    <MenuItem value="TX">Texas</MenuItem>
                    <MenuItem value="UT">Utah</MenuItem>
                    <MenuItem value="VT">Vermont</MenuItem>
                    <MenuItem value="VA">Virginia</MenuItem>
                    <MenuItem value="WA">Washington</MenuItem>
                    <MenuItem value="WV">West Virginia</MenuItem>
                    <MenuItem value="WI">Wisconsin</MenuItem>
                    <MenuItem value="WY">Wyoming</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} >
                {this.props.selectedLanguage === "esp" ?
                  <FormControl fullWidth
                    error={!this.state.zipCodeValid}
                    className={classes.formControl}>
                    <TextField margin="dense" id="zipCode"
                      value={this.state.selectedItem.zip_code}
                      error={!this.state.zipCodeValid}
                      onChange={(event) => this.onZipCodeChange(event)}
                      label="Código Postal" type="text" />
                  </FormControl>
                  :
                  <FormControl fullWidth
                    error={!this.state.zipCodeValid}
                    className={classes.formControl}>
                    <TextField margin="dense" id="zipCode"
                      value={this.state.selectedItem.zip_code}
                      error={!this.state.zipCodeValid}
                      onChange={(event) => this.onZipCodeChange(event)}
                      label="Zip Code" type="text" />
                  </FormControl>
                }
              </Grid>
            </Grid>


          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onSaveSchool()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 0, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_SCHOOLS_SAVE_BUTTON")}
            </Button>
          </DialogActions>

        </Dialog>

        <Grid container>
          <Grid item xs={2}>
            {this.props.userRoleId === 1 ?
              <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 0 }}
                onClick={() => this.onNewClick()}>
                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>{i18n.t("ADMIN_SCHOOLS_NEW_SCHOOL")}</Typography>
              </Button>
              : null}
          </Grid>
          <Grid item xs={6}>

          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            {this.props.selectedLanguage === "esp" ?
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={this.onSearch} style={{ width: '100%' }}
                  placeholder="Buscar"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              :
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={this.onSearch} style={{ width: '100%' }}
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            }
          </Grid>
        </Grid>

        <Paper style={{ marginTop: 10, orderRadius: 0 }}>
          <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
            <Table className={classes.table} style={{ height: this.state.height }}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("school_name")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_SCHOOLS_SCHOOL_NAME")}</Typography>
                      {this.state.sortBy === "school_name" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("school_type_name")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_SCHOOLS_SCHOOL_TYPE")}</Typography>
                      {this.state.sortBy === "school_type_name" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("address1")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_SCHOOLS_ADDRESS_1")}</Typography>
                      {this.state.sortBy === "address1" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("city")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_SCHOOLS_CITY")}</Typography>
                      {this.state.sortBy === "city" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("state_province")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_SCHOOLS_STATE")}</Typography>
                      {this.state.sortBy === "state_province" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("zip_code")}>
                      <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_SCHOOLS_ZIP_CODE")}</Typography>
                      {this.state.sortBy === "zip_code" ? (
                        this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                      ) : null}
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                  .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((row, index) => {

                    return (
                      <TableRow key={index} hover style={{ cursor: 'pointer' }}>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.school_name}</Typography></TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.school_type_name}</Typography></TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.address1}</Typography></TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.city}</Typography></TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.state_province}</Typography></TableCell>
                        <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.zip_code}</Typography></TableCell>
                      </TableRow>
                    )
                  })
                }
                {emptyRows > 0 && (
                  <TableRow style={{ height: 50 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[25]}
            component="div"
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.currentPage}
            count={this.state.filteredDataSource.length}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

SchoolScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(SchoolScreen))));