export default {

    container: {
        margin:'auto',
        backgroundColor:'white',
        padding:25,
        height: '80vh',
        width: '80vw',
        overflow:'scroll',
    },

    messageField:{
        marginTop:20,
        minWidth:600
    },

    select:{
        minWidth:300,
    }

}