import { Card, CardContent, Grid, List, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';


import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class WageScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      chartData: {
        labels: ['Entry-Level', 'Median', 'Experienced'],
        datasets: [{
          data: [
            0, 0, 0
          ],
          backgroundColor: ['#25D192', '#1F3545', '#FEFF78'],
          hoverBackgroundColor: ['#25D192', '#1F3545', '#FEFF78']
        }]
      },

      chartOptions: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              suggestedMax: 120
            }
          }]
        }
      }
    }
  }

  componentDidMount() {
    this.getData(this.props.careerId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.careerId);
  }

  getData(careerId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/careers/' + careerId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          if (this.props.selectedLanguage === "esp") {
            this.setState({
              isLoading: false,
              chartData: {
                labels: [' Nivel de entrada', 'Mediana', 'Experimentado'],
                datasets: [{
                  data: [
                    responseJson.results[0].entry_wage,
                    responseJson.results[0].median_wage,
                    responseJson.results[0].experienced_wage
                  ],
                  backgroundColor: ['#25D192', '#1F3545', '#FEFF78'],
                  hoverBackgroundColor: ['#25D192', '#1F3545', '#FEFF78']
                }]
              },
            })
          } else {
            this.setState({
              isLoading: false,
              chartData: {
                labels: ['Entry-Level', 'Median', 'Experienced'],
                datasets: [{
                  data: [
                    responseJson.results[0].entry_wage,
                    responseJson.results[0].median_wage,
                    responseJson.results[0].experienced_wage
                  ],
                  backgroundColor: ['#25D192', '#1F3545', '#FEFF78'],
                  hoverBackgroundColor: ['#25D192', '#1F3545', '#FEFF78']
                }]
              },
            })
          }
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Grid item xs={12}>

            <Card>
              <CardContent>

                <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#1F3545', marginBottom: 10 }}>
                      {i18n.t('CAREER_PROFILE_AVERAGE_WAGES')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>

                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <List style={{ height: 200 }} >

                      <Bar data={this.state.chartData}
                        options={this.state.chartOptions} />

                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </div>
    );
  }
}

WageScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    careerId: state.career.careerId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WageScreen)));