export default {

    containerStyle: {
        backgroundColor:'#eaeaea',
        height:'100%'
    },
    topBar: {
      backgroundColor: '#FFF',
      paddingBottom:15,
      borderBottom:'1px solid #eaeaea'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    },
  };