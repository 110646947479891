import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  AppBar,
  Button,
  Card, CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

class Report5Screen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      reports: [],
      dataSource: [],
      reportId: 0,
      selectedYear: 0,
      years: [],
      reportName: '',
      rowsPerPage: 10,
      currentPage: 0,
      orderBy: 'evaluationName'
    }
  }

  componentDidMount() {

    fetch('https://api.tasselpathway.com/dashboard/years', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.authenticated) {
          this.setState({
            isLoading: false,
            years: responseJson.years,
            selectedYear: responseJson.selected_year
          });

          this.getData(responseJson.selected_year);
        } else {
        }

      })
      .catch((error) => {
      });

  }

  renderRows() {
    return (
      <TableRow style={{ cursor: 'pointer' }}
        hover
        tabIndex={-1} key={'Test'}>
        <TableCell>Demo County</TableCell>
        <TableCell>January</TableCell>
        <TableCell>500</TableCell>
      </TableRow>
    );
  }

  onPrintClick() {

    fetch('https://api.tasselpathway.com/report1/' + this.state.selectedYear + '/html', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token,
        'selectedYear': this.state.selectedYear
      },
      credentials: 'omit'
    })
      .then((response) => response.text())
      .then((responseJson) => {

        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(responseJson);
        pri.document.close();
        pri.focus();
        pri.print();

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
      });
  }

  onYearChange(event) {

    this.getData(event.target.value);
    this.setState({ selectedYear: event.target.value })
  }

  scrollToTop() {
    const report = this.refs.report;
    if (report) {
      report.scrollTo(300, 900);
    }
  }

  onBackClick() {
    this.props.history.push('/reports');
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.dataSource.length - this.state.currentPage * this.state.rowsPerPage);

    moment('en');

    return (
      <div className={classes.root} ref={`report`}>
        <React.Fragment>
          <AppBar position="sticky" elevation={0} className={classes.topBar}>
            <Toolbar>
              <Grid container>
                <Grid item xs={1}>
                  <IconButton style={{ marginTop: 20, textAlign: 'right', color: '#1F3545', fontSize: 28 }} onClick={() => this.onBackClick()}>
                    <ArrowBackIcon style={{ fontSize: 28 }} />
                  </IconButton>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontSize: 28, marginTop: 20 }}>Reports</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  <FormControl variant="outlined" style={{ marginTop: 20 }} className={classes.formControl}>
                    <Select style={{ fontSize: 22 }}
                      value={this.state.selectedYear}
                      onChange={(event) => this.onYearChange(event)}
                      inputProps={{
                        name: 'year',
                        id: 'year',
                      }}
                    >
                      {this.state.years.map((item, key) => {
                        return (
                          <MenuItem key={key} value={item.year_value}>School Year: {item.year_name}</MenuItem>
                        )
                      })
                      }
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  <div>
                    <Button variant="contained" style={{ marginTop: 20 }}
                      color="primary" aria-label="Create" className={this.props.classes.margin}
                      onClick={() => this.onExportClick()}>
                      <SaveAltIcon className={this.props.classes.extendedIcon} style={{ color: '#FFF' }} />
                    </Button>
                    <Button variant="contained" style={{ marginTop: 20, marginLeft: 10 }}
                      color="primary" aria-label="Create" className={this.props.classes.margin}
                      onClick={() => this.onPrintClick()}>
                      <PrintIcon className={this.props.classes.extendedIcon} style={{ color: '#FFF' }} />
                    </Button>
                  </div>
                </Grid>

              </Grid>
            </Toolbar>
          </AppBar>
        </React.Fragment>

        <Grid id="report_1" style={styles.itemContainerStyle}>

          <Grid item xs={12}>

            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 10, marginBottom: 10 }}>

              <Card style={{ marginTop: 40 }}>
                <CardContent>
                  <Grid container style={{ marginBottom: 10 }}>
                    <Grid item xs={8} style={{ textAlign: 'left', marginTop: 10 }}>
                      <Typography style={{ textAlign: 'left', fontSize: 32 }}>{this.state.reportName}</Typography>
                      <Typography>{moment().format('LLL')}</Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.tableWrapper}>
                    <Table className={classes.table}>

                      <TableHead>
                        <TableRow>
                          <TableCell>School Name</TableCell>
                          <TableCell>Month</TableCell>
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {this.renderRows()}
                        <TableRow style={{ cursor: 'pointer' }}
                          hover tabIndex={-1}>
                        </TableRow>
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 50 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                    </Table>
                  </div>
                </CardContent>
              </Card>

            </div>
          </Grid>


        </Grid>
        <iframe id="ifmcontentstoprint" style={{ display: 'none' }}></iframe>
      </div>
    );
  }
}

Report5Screen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Report5Screen));