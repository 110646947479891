import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List, ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class ProgramScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      isProgramOpen: false,
      selectedProgram: {}
    }

    this.onProgramClose = this.onProgramClose.bind(this);
  }

  componentDidMount() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/college_degree_programs/' + this.props.collegeId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderDegrees(item) {

    var content = item.degrees.map((item, key) => {
      return (
        <Chip key={key} label={item.degree_name}
          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginBottom: 10, marginRight: 5 }}
          className={this.props.classes.chip} />
      )
    })

    return content;
  }

  renderPrograms() {

    var programId = 0;

    var content = this.state.dataSource.map((item, key) => {

      if (item.program_id !== programId) {

        programId = item.program_id;
        if (this.props.selectedLanguage === "esp") {
          return (
            <ListItem key={key} button onClick={() => this.onProgramClick(item)}
              alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="MSU" style={{ height: 55, width: 55, marginRight: 15 }} src={item.logo_image_url} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography style={{ fontSize: 14, marginTop: 5, fontWeight: 'bold' }} component="span"
                      className={this.props.classes.inline} color="textPrimary">
                      {item.program_name_spanish}
                    </Typography>
                  </React.Fragment>
                } secondary={
                  <React.Fragment>
                    <Grid container style={{ marginTop: 10, flexDirection: 'row' }}>
                      {this.renderDegrees(item)}
                    </Grid>
                  </React.Fragment>
                }
              />
            </ListItem>
          )
        } else {
          return (
            <ListItem key={key} button onClick={() => this.onProgramClick(item)}
              alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="MSU" style={{ height: 55, width: 55, marginRight: 15 }} src={item.logo_image_url} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography style={{ fontSize: 14, marginTop: 5, fontWeight: 'bold' }} component="span"
                      className={this.props.classes.inline} color="textPrimary">
                      {item.program_name}
                    </Typography>
                  </React.Fragment>
                } secondary={
                  <React.Fragment>
                    <Grid container style={{ marginTop: 10, flexDirection: 'row' }}>
                      {this.renderDegrees(item)}
                    </Grid>
                  </React.Fragment>
                }
              />
            </ListItem>
          )
        }
      }

    })

    return content;
  }

  renderMajors() {

    var majors = this.state.selectedProgram.majors;

    if (majors !== undefined) {

      var content = majors.map((item, key) => {
        return (
          <TableRow>
            <TableCell>{item.major_name}</TableCell>
            <TableCell>{this.hasDegree(item.major_degrees, 1) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
            <TableCell>{this.hasDegree(item.major_degrees, 2) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
            <TableCell>{this.hasDegree(item.major_degrees, 3) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
            <TableCell>{this.hasDegree(item.major_degrees, 4) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
            <TableCell>{this.hasDegree(item.major_degrees, 5) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
            <TableCell>{this.hasDegree(item.major_degrees, 6) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
            <TableCell>{this.hasDegree(item.major_degrees, 7) === true ? <FontAwesomeIcon icon={faCheck}
              style={{ color: '#25D192', fontSize: 18 }} /> : ""}</TableCell>
          </TableRow>
        )
      })

      return content;

    } else {
      return null;
    }

  }

  hasDegree(degrees, degreeId) {

    var result = false;

    degrees.map((item, key) => {
      if (item.degree_id === degreeId) {
        result = true;
      }
    })

    return result;
  }

  onProgramClick(item) {
    this.setState({
      isProgramOpen: true,
      selectedProgram: item
    })
  }

  onProgramClose() {
    this.setState({
      isProgramOpen: false
    })
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Dialog open={this.state.isProgramOpen}
          onClose={this.onProgramClose} maxWidth="md"
          fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onProgramClose}>
            <Typography style={{ fontSize: 16 }}>{this.state.selectedProgram.program_name}</Typography>
          </DialogTitle>

          <DialogContent>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell><Tooltip title="Certificate - 1 Year" placement="top"><Typography style={{ fontSize: 11 }}>C-1</Typography></Tooltip></TableCell>
                  <TableCell><Tooltip title="Certificate - 2 Year" placement="top"><Typography style={{ fontSize: 11 }}>C-2</Typography></Tooltip></TableCell>
                  <TableCell><Tooltip title="Associate Degree" placement="top"><Typography style={{ fontSize: 11 }}>A</Typography></Tooltip></TableCell>
                  <TableCell><Tooltip title="Certificate - 4 Year" placement="top"><Typography style={{ fontSize: 11 }}>C-4</Typography></Tooltip></TableCell>
                  <TableCell><Tooltip title="Bachelor Degree" placement="top"><Typography style={{ fontSize: 11 }}>B</Typography></Tooltip></TableCell>
                  <TableCell><Tooltip title="Master Degree" placement="top"><Typography style={{ fontSize: 11 }}>M</Typography></Tooltip></TableCell>
                  <TableCell><Tooltip title="Doctoral Degree" placement="top"><Typography style={{ fontSize: 11 }}>D</Typography></Tooltip></TableCell>
                </TableRow>
              </TableHead>
              {this.renderMajors()}
            </Table>

          </DialogContent>
          <DialogActions>
            <Button variant="contained"
              style={{ marginRight: 20, marginBottom: 10, color: '#FFF', borderRadius: 0, fontWeight: 'bold' }}
              onClick={this.onProgramClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container style={styles.itemContainerStyle}>

          <Grid item xs={12}>

            <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', marginTop: 20, color: '#4D4D4F' }}>{i18n.t("COLLEGE_PROGRAMS")}</Typography>

            <List style={{ overflow: 'auto', height: 400 }}>
              {this.renderPrograms()}
            </List>

          </Grid>
        </Grid>

      </div>
    );
  }
}

ProgramScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProgramScreen)));