import { faCamera, faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar, ListItemSecondaryAction,
    ListItemText,
    Menu, MenuItem,
    TextField,
    Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import Linkify from 'react-linkify';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

var AWS = require('aws-sdk');

var s3 = new AWS.S3({
    accessKeyId: 'AKIARE5YHMQIUQP6NVXT',
    secretAccessKey: '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',
    region: 'us-east-1'
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogTitle2 = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} style={{ position: 'absolute', top: '5%', right: '3%' }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class ExtracurricularScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dataSource: [],
            isFavorite: 0,
            collegeId: 0,
            careerName: '',
            value: 1,
            members: [],
            teacherNewFeedData: [],
            activity_name: '',
            extracurricular_activity_id: 0,
            logoSrc: null,
            feed: [],
            feedType: 0,
            message: "",
            postToDelete: 0,
            selectedItem: {},
            isDeleteStudentOpen: false,
            links: [],
            isNewOpen: false,
            link: "",
            isDeleteLinkOpen: false,
            bannerImage: "",
            teacherPhoto: "",
            isEditGroupOpen: false,
            role: '',
            isNewEventOpen: false,
            title: "",
            description: "",
            newDate: "",
            events: [],
            isEditEventOpen: false,
            editDate: ""

        }
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.onDeleteOpen = this.onDeleteOpen.bind(this);
        this.onDeleteClose = this.onDeleteClose.bind(this);
        this.onDeleteStudentOpen = this.onDeleteStudentOpen.bind(this);
        this.onDeleteStudentClose = this.onDeleteStudentClose.bind(this);
        this.onEditOpen = this.onEditOpen.bind(this);
        this.onEditClose = this.onEditClose.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentDidMount() {
        this.getData(this.props.match.params.groupId);
    }

    componentWillReceiveProps(newProps) {
        this.getData(newProps.match.params.groupId);
    }

    getData(groupId) {

        fetch('https://api.tasselpathway.com/persons/' + this.props.personId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        firstName: responseJson.results[0].first_name,
                        lastName: responseJson.results[0].last_name,
                        gradeId: responseJson.results[0].grade_id,
                        gradeName: responseJson.results[0].grade_name,
                        districtId: responseJson.results[0].district_id,
                        districtName: responseJson.results[0].district_name,
                        schoolId: responseJson.results[0].schools[0].school_id,
                        schoolName: responseJson.results[0].schools[0].school_name,
                        teacherPhoto: responseJson.results[0].logo_image_url,

                    })
                } else if (responseJson.authenticated === false) {
                    this.props.onLogout();
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            })

        fetch("https://api.tasselpathway.com/extracurricular_activity/group/" + groupId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        dataSource: responseJson.results,
                    })
                    if (responseJson.results[0].banner_image_url === "") {
                        if (responseJson.results[0].logo_image_url === "") {
                            this.setState({
                                bannerImage: "../../assets/default_banner.png",
                                logoImage: "../../assets/default_avatar.png"
                            })
                        }
                        else {
                            this.setState({
                                bannerImage: "../../assets/default_banner.png",
                                logoImage: responseJson[0].logo_image_url
                            })
                        }
                    } else if (responseJson.results[0].logo_image_url === "") {
                        if (responseJson.results[0].banner_image_url === "") {
                            this.setState({
                                bannerImage: "../../assets/default_banner.png",
                                logoImage: "../../assets/default_avatar.png"
                            })
                        }
                        else {
                            this.setState({
                                bannerImage: responseJson[0].banner_image_url,
                                logoImage: "../../assets/default_avatar.png"
                            })
                        }
                    }
                    else if (responseJson.results[0].banner_image_url !== "") {
                        if (responseJson.results[0].logo_image_url !== "") {
                            this.setState({
                                bannerImage: responseJson.results[0].banner_image_url,
                                logoImage: responseJson.results[0].logo_image_url
                            })

                        }
                        else {
                            this.setState({
                                bannerImage: responseJson.results[0].banner_image_url,
                                logoImage: "../../assets/default_avatar.png"
                            })
                        }
                    } else if (responseJson.results[0].logo_image_url !== "") {
                        if (responseJson.results[0].banner_image_url !== "") {
                            this.setState({
                                bannerImage: responseJson.results[0].banner_image_url,
                                logoImage: responseJson[0].logo_image_url
                            })
                        }
                        else {
                            this.setState({
                                bannerImage: "../../assets/default_banner.png",
                                logoImage: responseJson.results[0].logo_image_url
                            })
                        }
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch("https://api.tasselpathway.com/feed_links/" + groupId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        links: responseJson.results,
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch("https://api.tasselpathway.com/events/" + groupId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        events: responseJson.results,
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch("https://api.tasselpathway.com/group_feed/" + groupId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        feed: responseJson.results.reverse()
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });

        fetch("https://api.tasselpathway.com/extracurricular_person/person/" + groupId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        members: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onPostLink() {

        fetch('https://api.tasselpathway.com/feed_links', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                link: this.state.link,
                extracurricular_activity_id: this.props.match.params.groupId
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                this.getData(this.props.match.params.groupId)

                this.setState({
                    isNewOpen: false
                })

            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onPostEvent() {

        fetch('https://api.tasselpathway.com/events', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                event_title: this.state.title,
                event_description: this.state.description,
                event_date: this.state.newDate,
                extracurricular_activity_id: this.props.match.params.groupId
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                this.getData(this.props.match.params.groupId)

                this.setState({
                    isNewEventOpen: false
                })

            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onEditEventPost() {

        let selectedItem = this.state.selectedItem

        fetch("https://api.tasselpathway.com/events", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            credentials: "omit",
            body: JSON.stringify({
                event_title: selectedItem.event_title,
                event_description: selectedItem.event_description,
                event_date: this.state.editDate,
                event_id: this.state.selectedItem.event_id
            })
        })
            .then(res => res.json())
            .then(responseJson => {
                if (responseJson.error === false) {
                    this.onEditClose()
                }
            })
            .catch((error) => {
            })
    }


    onEditPost() {

        let selectedItem = this.state.selectedItem
        let postId = selectedItem.group_feed_id

        fetch("https://api.tasselpathway.com/group_feed/edit/" + postId, {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            credentials: "omit",
            body: JSON.stringify({
                message: this.state.selectedItem.message
            })
        })
            .then(res => res.json())
            .then(responseJson => {
                if (responseJson.error === false) {
                    this.onEditClose()
                }
            })
            .catch((error) => {
            })
    }

    onDeletePost() {
        let selectedItem = this.state.selectedItem
        let postId = selectedItem.group_feed_id
        fetch("https://api.tasselpathway.com/group_feed/remove/" + postId, {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            credentials: "omit"
        })
            .then(res => res.json())
            .then(responseJson => {
                this.onDeleteClose();
                responseJson.error === false ? this.getData(this.props.match.params.groupId) : console.log("An error has occured");
            })
    }

    deleteLink() {

        fetch("https://api.tasselpathway.com/feed_links/" + this.state.selectedItem.feed_link_id, {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            credentials: "omit"
        })
            .then(res => res.json())
            .then(responseJson => {
                this.onDeleteLinkClose();
                responseJson.error === false ? this.getData(this.props.match.params.groupId) : console.log("An error has occured");
            })
    }

    onPostClick() {

        this.state.dataSource.map((item) => {

            // if message is blank but user wants to upload a image
            if (this.state.message === "" && this.state.logoSrc !== null) {
                this.setState({
                    feedType: 2
                })
                let url = this.state.logoSrc
                fetch(url)
                    .then(res => res.blob())
                    .then(blob => {
                        var props = this.props;
                        var state = this.state
                        var component = this
                        const ext = this.state.logoSrc.split(';')[0].split('/')[1];
                        var random = Math.floor(Math.random() * 900000000000000000);
                        var filename = 'pathway/newsfeed/' + random + '.' + ext;
                        var params = {
                            Key: filename,
                            Body: blob,
                            ContentType: blob.type,
                            Bucket: 'tassel.com'
                        };
                        s3.upload(params, function (err, data, token) {

                            fetch("https://api.tasselpathway.com/group_feed", {
                                method: "POST",
                                headers: {
                                    "Accept": "application/json",
                                    "Content-Type": "application/json",
                                    "token": props.token
                                },
                                credentials: "omit",
                                body: JSON.stringify({
                                    district_id: state.districtId,
                                    feed_type_id: state.feedType,
                                    owner_id: props.personId,
                                    audience_name: item.activity_name,
                                    extracurricular_activity_id: item.extracurricular_activity_id,
                                    message: state.message,
                                    aws_bucket: "tassel.com",
                                    image_aws_key: filename
                                })
                            })
                                .then(res => res.json())
                                .then((responseJson) => {
                                    component.getData(props.match.params.groupId);
                                    component.setState({
                                        logoSrc: null
                                    })
                                })
                        })
                    })
                // if the logoSrc AND message have content we post both 
            } else if (this.state.logoSrc !== null && this.state.message !== '') {
                let url = this.state.logoSrc
                fetch(url)
                    .then(res => res.blob())
                    .then(blob => {
                        var props = this.props;
                        var state = this.state

                        const ext = this.state.logoSrc.split(';')[0].split('/')[1];
                        var random = Math.floor(Math.random() * 900000000000000000);
                        var filename = 'pathway/newsfeed/' + random + '.' + ext;
                        var params = {
                            Key: filename,
                            Body: blob,
                            ContentType: blob.type,
                            Bucket: 'tassel.com'
                        };
                        s3.upload(params, function (err, data, token) {

                            fetch("https://api.tasselpathway.com/group_feed", {
                                method: "POST",
                                headers: {
                                    "Accept": "application/json",
                                    "Content-Type": "application/json",
                                    "token": props.token
                                },
                                credentials: "omit",
                                body: JSON.stringify({
                                    district_id: state.districtId,
                                    feed_type_id: state.feedType,
                                    owner_id: props.personId,
                                    audience_name: item.activity_name,
                                    extracurricular_activity_id: item.extracurricular_activity_id,
                                    message: state.message,
                                    aws_bucket: "tassel.com",
                                    image_aws_key: filename
                                })
                            })
                                .then(res => res.json())
                                .then((responseJson) => {
                                    this.getData(this.props.match.params.groupId);
                                })
                        })
                    })
                // if message has content and logoSrc is null (no attachment) post just the image
            } else if (this.state.message !== "" && this.state.logoSrc === null) {
                fetch("https://api.tasselpathway.com/group_feed", {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "token": this.props.token
                    },
                    credentials: "omit",
                    body: JSON.stringify({
                        district_id: this.state.districtId,
                        feed_type_id: this.state.feedType,
                        owner_id: this.props.personId,
                        audience_name: item.activity_name,
                        extracurricular_activity_id: item.extracurricular_activity_id,
                        message: this.state.message,
                        aws_bucket: "",
                        image_aws_key: ""
                    })
                })
                    .then(res => res.json())
                    .then((responseJson) => {
                        this.getData(this.props.match.params.groupId);
                        this.setState({
                            message: ''
                        })
                    })
            }
        })
    }

    onEditExtracurricularClick() {
        fetch("https://api.tasselpathway.com/extracurricular_activity", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "token": this.props.token
            },
            body: JSON.stringify({
                activity_name: this.state.selectedItem.activity_name,
                role: this.state.selectedItem.role,
                extracurricular_activity_id: this.state.selectedItem.extracurricular_activity_id

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isEditOpen: false,
                        isLoading: false,
                    })
                }
                this.getData(this.props.profileId);
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onDeleteExtracurricular() {

        this.setState({
            isLoading: true
        })

        fetch('https://api.tasselpathway.com/extracurricular_activity', {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                extracurricular_activity_id: this.state.selectedItem.extracurricular_activity_id
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.getData(this.props.personId);
                    this.setState({
                        isLoading: false,
                        isEditGroupOpen: false,
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onDeleteEvent() {

        this.setState({
            isLoading: true
        })

        fetch('https://api.tasselpathway.com/events', {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                event_id: this.state.selectedItem.event_id
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        isEditEventOpen: false,
                    })

                    this.getData(this.props.match.params.groupId)
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onDeleteClick() {

        fetch('https://api.tasselpathway.com/extracurricular_person', {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                extracurricular_person_id: this.state.selectedItem.extracurricular_person_id
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isDeleteStudentOpen: false
                    })
                    this.getData(this.props.match.params.groupId)
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    handleMessageTextChange(e) {
        var expression = "/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi";
        var regex = new RegExp(expression);
        var t = e.target.value;

        if (t.match(regex)) {
            this.setState({
                message: e.target.value,
                feedType: 1
            })
        } else if (this.state.logoSrc !== null) {
            this.setState({
                message: e.target.value,
                feedType: 2
            })
        } else {
            this.setState({
                message: e.target.value,
                feedType: 4
            })
        }
    }

    fileChangedHandler(event) {
        var fileInput = false
        if (event.target.files[0]) {
            fileInput = true
        }
        if (fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                520,
                450,
                'PNG',
                1000,
                0,
                uri => {
                    this.setState({
                        logoSrc: uri
                    })
                },
                'base64'
            );
        }
    }

    onMessageEdit(e) {
        var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        var regex = new RegExp(expression);
        var t = e.target.value;

        if (t.match(regex)) {
            let selectedItem = this.state.selectedItem
            selectedItem.message = e.target.value
            selectedItem.feed_type_id = 1
            this.setState({
                selectedItem: selectedItem,
            })
        } else if (this.state.selectedItem.image_aws_key !== "") {
            let selectedItem = this.state.selectedItem
            selectedItem.message = e.target.value
            selectedItem.feed_type_id = 2
            this.setState({
                selectedItem: selectedItem,
            })
        } else {
            let selectedItem = this.state.selectedItem
            selectedItem.message = e.target.value
            selectedItem.feed_type_id = 4
            this.setState({
                selectedItem: selectedItem,
            })
        }
    }

    openEditDelete(event, item) {
        let postNumber = parseInt(event.target.id)
        this.setState({
            anchor: event.currentTarget,
            postToDelete: postNumber,
            selectedItem: item
        })
    }

    closeEditDeleteLink() {
        this.setState({
            anchor: null,
            postToDelete: 0
        })
    }

    onDeleteStudentOpen(item) {
        this.setState({
            isDeleteStudentOpen: true,
            selectedItem: item
        })
    }

    onDeleteStudentClose() {
        this.setState({
            isDeleteStudentOpen: false
        })
    }

    onDeleteOpen() {
        this.setState({
            onDeleteOpen: true
        })
    }
    onDeleteClose() {
        this.setState({
            onDeleteOpen: false
        })
        this.closeEditDeleteLink()
    }

    onDeleteLink = (item) => {
        this.setState({
            isDeleteLinkOpen: true,
            selectedItem: item
        })
    }

    onDeleteLinkClose = () => {
        this.setState({
            isDeleteLinkOpen: false
        })
    }

    onEditGroupOpen = (item) => {
        this.setState({
            isEditGroupOpen: true,
            selectedItem: item
        })
    }

    onEditActivityChange(event) {
        var selectedItem = this.state.selectedItem;
        selectedItem.activity_name = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }

    onEditRoleChange(event) {
        var selectedItem = this.state.selectedItem;
        selectedItem.role = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }


    onEditOpen() {
        this.setState({
            isEditOpen: true
        })
    }
    onEditClose() {
        this.setState({
            isEditOpen: false,
            isEditEventOpen: false,
        })
        this.getData(this.props.match.params.groupId)
        this.closeEditDeleteLink()
    }

    onNewClick() {
        this.setState({
            isNewOpen: true
        })
    }

    onNewEventClick() {
        this.setState({
            isNewEventOpen: true
        })
    }

    onNewClose = () => {
        this.setState({
            isNewOpen: false,
            isEditGroupOpen: false,
            isNewEventOpen: false
        })
    }

    onEditEventOpen = (item) => {
        this.setState({
            isEditEventOpen: true,
            selectedItem: item,
        })
    }

    onTitleChange(event) {

        var selectedItem = this.state.selectedItem;
        selectedItem.event_title = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }

    onDescriptionChange(event) {

        var selectedItem = this.state.selectedItem;
        selectedItem.event_description = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }

    onDateChange(event) {

        var selectedItem = this.state.selectedItem;
        selectedItem.event_date = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }
    //Navigate to student profile from groups page
    onPersonClick(person) {
        this.props.history.push('/profile/' + person.person_id);
    }

    renderPostBox() {
        const { classes } = this.props
        if (this.props.userRoleId < 8) {
            return (
                <Card className={classes.card} style={{ marginBottom: 10 }}>
                    <CardContent style={{ cursor: 'pointer' }}>
                        {this.state.logoImage !== "" ?
                            <Avatar style={{ height: 50, width: 50 }} aria-label="Recipe" src={this.state.teacherPhoto} alt='user profile image' className={classes.avatar} />
                            :
                            <Avatar style={{ height: 50, width: 50 }} aria-label="Recipe" src={require('../../../assets/default_avatar.png')} alt='user profile image' className={classes.avatar} />

                        }
                        <TextField
                            id="standard-textarea"
                            placeholder="Share a post"
                            multiline
                            InputProps={{ disableUnderline: true }}
                            margin="normal"
                            style={{ width: '85%', float: 'right', marginTop: -35 }}
                            onChange={(e) => this.handleMessageTextChange(e)}
                            value={this.state.message}
                        />
                        <div>
                            {this.state.logoSrc !== null ?
                                <img src={this.state.logoSrc} style={{ width: '55%', marginTop: 12, marginBottom: -7 }} alt="Logo" />
                                :
                                null
                            }
                        </div>

                    </CardContent>

                    <CardContent style={{ marginTop: -10, marginBottom: -5 }}>
                        <hr style={{ width: '95%', margin: 'auto', borderTopWidth: 1, borderTopColor: "#DADADA", borderTopStyle: 'solid', marginBottom: 10 }} />
                        <input type="file" name="file" id="file" onChange={this.fileChangedHandler} style={{ width: 0.1, height: 0.1, opacity: 0, overflow: 'hidden', position: "absolute", zIndex: '1' }} />
                        <label className='chooseFile' for="file">
                            <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<FontAwesomeIcon style={{ fontSize: "10", color: "#25D192" }} icon={faCamera} />}
                                label="Upload Photo"
                            />
                        </label>
                        {this.state.logoSrc !== null ?

                            <Chip
                                variant="outlined"
                                size="medium"
                                avatar={<FontAwesomeIcon style={{ fontSize: "10", color: "#25D192" }} icon={faTrash} />}
                                label="Remove Photo"
                                onClick={() => this.setState({ logoSrc: null })}
                            />
                            :
                            null
                        }
                        <button type='button' style={styles.postButton} onClick={() => this.onPostClick()}>Post</button>
                    </CardContent>
                </Card>
            )
        }
    }

    renderLinks() {

        var content = this.state.links.map((item, key) => {
            return (

                <div>
                    {this.props.userRoleId < 8 ?
                        <ListItem key={key} button>
                            <div style={{ display: 'table-cell', width: '90%', overflow: 'auto', marginRight: 5 }}>
                                <Linkify>{item.link}</Linkify>
                            </div>

                            <div style={{ display: 'table-cell', marginLeft: 5 }}>
                                <IconButton style={styles.memberDelete} edge="end" onClick={() => this.onDeleteLink(item)} >
                                    <FontAwesomeIcon style={{ color: '#DADADA', fontSize: 16 }} icon={faTrash} />
                                </IconButton>
                            </div>
                        </ListItem>
                        : this.props.userRoleId === 8 ?

                            <ListItem key={key} button>
                                <div>
                                    <Linkify>{item.link}</Linkify>
                                </div>
                            </ListItem>
                            : null
                    }

                </div>
            )
        })

        return content;
    }

    renderEvents() {

        var content = this.state.events.map((item, key) => {
            return (

                <div>
                    {this.props.userRoleId < 8 ?
                        <ListItem button onClick={() => this.onEditEventOpen(item)} key={key}>
                            <div>
                                <ListItemText style={{ fontSize: 20, marginTop: 5 }}><strong>{item.event_title}</strong></ListItemText>
                                <ListItemText>{moment(item.event_date).format('MM/DD/YYYY')}</ListItemText>
                                <ListItemText>{item.event_description}</ListItemText>
                            </div>
                        </ListItem>
                        : this.props.userRoleId === 8 ?

                            <ListItem key={key}>
                                <div>
                                    <ListItemText style={{ fontSize: 20, marginTop: 5 }}><strong>{item.event_title}</strong></ListItemText>
                                    <ListItemText>{moment(item.event_date).format('MM/DD/YYYY')}</ListItemText>
                                    <ListItemText>{item.event_description}</ListItemText>
                                </div>
                            </ListItem>
                            : null
                    }

                </div>
            )
        })

        return content;
    }

    renderMembers() {

        const { classes } = this.props;

        var content = this.state.members.map((item, key) => {
            if (item.roleMember === 'Members') {
                return (

                    <div>
                        {this.props.userRoleId < 8 ?
                            <div>
                                <ListItem key={key} alignItems="flex-start" button onClick={() => this.onPersonClick(item)}>
                                    <ListItemAvatar>
                                        {item.logo_image_url === "" ?
                                            <Avatar style={{ height: 50, width: 50, marginRight: 5 }} aria-label="Recipe" src={require('../../../assets/default_avatar.png')} alt='user profile image' className={classes.avatar} />
                                            :
                                            <Avatar style={{ height: 50, width: 50, marginRight: 5 }} aria-label="Recipe" src={item.logo_image_url} alt='user profile image' className={classes.avatar} />
                                        }
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Typography style={{ fontSize: 18, marginLeft: 10, marginTop: 12 }}>{item.first_name} {item.last_name}</Typography>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton style={styles.memberDelete} edge="end" onClick={() => this.onDeleteStudentOpen(item)} >
                                            <FontAwesomeIcon style={{ color: '#DADADA', fontSize: 16 }} icon={faTrash} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>
                            : this.props.userRoleId === 8 ?

                                <ListItem key={key} alignItems="flex-start" button onClick={() => this.onPersonClick(item)}>
                                    {item.logo_image_url === "" ?
                                        <Avatar style={{ height: 50, width: 50, marginRight: 5 }} aria-label="Recipe" src={require('../../../assets/default_avatar.png')} alt='user profile image' className={classes.avatar} />
                                        :
                                        <Avatar style={{ height: 50, width: 50, marginRight: 5 }} aria-label="Recipe" src={item.logo_image_url} alt='user profile image' className={classes.avatar} />
                                    }
                                    <Typography style={{ fontSize: 18, marginTop: 5 }}>{item.first_name} {item.last_name}</Typography>
                                </ListItem>
                                : null
                        }

                    </div>

                )
            }
        })

        return content;
    }

    renderOfficers() {

        const { classes } = this.props;

        var content = this.state.members.map((item, key) => {
            if (item.roleMember === 'Officer') {
                return (
                    <div>
                        {this.props.userRoleId < 8 ?
                            <div>
                                <ListItem key={key}
                                    button onClick={() => this.onPersonClick(item)}>
                                    <ListItemAvatar>
                                        {item.logo_image_url === "" ?
                                            <Avatar style={{ height: 50, width: 50, marginRight: 5 }} aria-label="Recipe" src={require('../../../assets/default_avatar.png')} alt='user profile image' className={classes.avatar} />
                                            :
                                            <Avatar style={{ height: 50, width: 50, marginRight: 5 }} aria-label="Recipe" src={item.logo_image_url} alt='user profile image' className={classes.avatar} />
                                        }
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Typography style={{ fontSize: 18, marginTop: 5, marginLeft: 10 }}>{item.first_name} {item.last_name}</Typography>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton style={styles.memberDelete} edge="end" onClick={() => this.onDeleteStudentOpen(item)}>
                                            <FontAwesomeIcon style={{ color: '#DADADA', fontSize: 16 }} icon={faTrash} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>
                            : this.props.userRoleId === 8 ?

                                <ListItem key={key} alignItems="flex-start" button onClick={() => this.onPersonClick(item)}>
                                    <ListItemAvatar>
                                        {item.logo_image_url === "" ?
                                            <Avatar style={{ height: 50, width: 50, marginRight: 5 }} aria-label="Recipe" src={require('../../../assets/default_avatar.png')} alt='user profile image' className={classes.avatar} />
                                            :
                                            <Avatar style={{ height: 50, width: 50, marginRight: 5 }} aria-label="Recipe" src={item.logo_image_url} alt='user profile image' className={classes.avatar} />
                                        }
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Typography style={{ fontSize: 18, marginTop: 5 }}>{item.first_name} {item.last_name}</Typography>
                                    </ListItemText>
                                </ListItem>
                                : null
                        }

                    </div>
                )
            }
        })

        return content;
    }

    renderTranslatedTextFields() {
        if (this.props.selectedLanguage === "esp") {
            return (
                <>
                    <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Nombre del club/deporte/otro"
                        onChange={(e) => this.setState({ activity_name: e.target.value })}
                        value={this.state.activity_name}
                        margin="normal"
                    />

                    <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Rol"
                        onChange={(e) => this.setState({ role: e.target.value })}
                        value={this.state.role}
                        margin="normal"
                    />
                </>
            )
        } else {
            return (
                <>
                    <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Name of Club/Sport/Other"
                        onChange={(e) => this.setState({ activity_name: e.target.value })}
                        value={this.state.activity_name}
                        margin="normal"
                    />

                    <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Role"
                        onChange={(e) => this.setState({ role: e.target.value })}
                        value={this.state.role}
                        margin="normal"
                    />
                </>
            )
        }
    }

    renderEditModalInputs() {
        if (this.props.selectedLanguage === 'esp') {
            return (
                <>
                    <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Nombre del club/deporte/otro"
                        onChange={(event) => this.onEditActivityChange(event)}
                        value={this.state.selectedItem.activity_name}
                        margin="normal"
                    />

                    <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Rol"
                        onChange={(event) => this.onEditRoleChange(event)}
                        value={this.state.selectedItem.role}
                        margin="normal"
                    />
                </>
            )
        } else {
            return (
                <>
                    <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Club/Sport/Other"
                        onChange={(event) => this.onEditActivityChange(event)}
                        value={this.state.selectedItem.activity_name}
                        margin="normal"
                    />

                    <TextField
                        style={{ width: 220 }}
                        id="standard-name"
                        label="Role"
                        onChange={(event) => this.onEditRoleChange(event)}
                        value={this.state.selectedItem.role}
                        margin="normal"
                    />
                </>
            )
        }
    }

    render() {
        const { i18n } = this.props;
        const { classes } = this.props;
        return (
            <div className={classes.root}>

                <Grid container style={styles.itemContainerStyle}>

                    <Grid item xs={1}></Grid>

                    <Grid item xs={10}>

                        <img src={require('../../../assets/default_banner.png')} style={{ width: '100%', height: 200, objectFit: 'cover' }} alt="" />

                        <Card>
                            <CardContent>

                                <div style={{ position: 'absolute', top: 200, height: 150, width: 150, borderRadius: 100, border: '2px solid #FFF' }}>
                                    <img src={require('../../../assets/default_avatar.png')} style={{ borderRadius: 100, width: '100%', height: '100%' }} alt="" />
                                </div>


                                <div style={{ marginLeft: 160 }}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Grid container style={{ width: '100%' }}>
                                                <div style={{ float: 'left' }}>

                                                    {this.state.dataSource.map((item, key) => {
                                                        return (

                                                            <div>


                                                                <div style={{ display: 'table-cell' }}>
                                                                    <div>
                                                                        <Typography style={{ fontSize: 25, marginTop: 5 }}>{item.activity_name}</Typography>
                                                                        <Typography style={{ fontSize: 18, marginTop: 5 }}>{item.first_name}  {item.last_name}</Typography>
                                                                        <Typography style={{ fontSize: 18, marginTop: 5 }}>{item.district_name}</Typography>
                                                                    </div>
                                                                </div>
                                                                {this.props.userRoleId < 8 ?
                                                                    <div style={{ display: 'table-cell' }}>
                                                                        <IconButton onClick={() => this.onEditGroupOpen(item)}>
                                                                            <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPencilAlt} />
                                                                        </IconButton>
                                                                    </div>
                                                                    : null
                                                                }

                                                            </div>

                                                        )
                                                    })}

                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <br style={{ clear: 'both' }} />
                                <div>
                                    <Typography style={{ marginTop: 5, fontSize: 12 }}></Typography>
                                </div>
                            </CardContent>
                        </Card>

                        <br />

                        <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                            <Grid item xs={3}>
                                <div style={{ position: 'sticky', top: '10%' }}>
                                    <Card style={{ padding: 10 }}>
                                        <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', marginTop: 20, marginLeft: 10, color: '#4D4DF' }}>{i18n.t("GROUPS_OFFICERS")}</Typography>
                                        <List>
                                            {this.renderOfficers()}
                                        </List>
                                        <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', marginTop: 20, marginLeft: 10, color: '#4D4D4F' }}>{i18n.t("GROUPS_MEMBERS")}</Typography>
                                        <List>
                                            {this.renderMembers()}
                                        </List>
                                    </Card>
                                </div>
                            </Grid>

                            <Grid item style={{ width: 625 }}>
                                {this.renderPostBox()}
                                {this.props.userRoleId < 8 ?
                                    this.state.feed.map((item, key) => {
                                        const { classes } = this.props
                                        return (
                                            <>
                                                <Card key={key} className={classes.card}>
                                                    {item.logo_aws_key !== "" ?
                                                        <CardHeader
                                                            avatar={
                                                                <Avatar style={{ height: 50, width: 50 }} src={item.logo_aws_key} aria-label="Recipe" className={classes.avatar} />
                                                            }
                                                            action={
                                                                <IconButton>
                                                                    {item.owner_id === this.props.personId ?
                                                                        <MoreHorizIcon id={item.group_feed_id} onClick={(event) => this.openEditDelete(event, item)} />
                                                                        : null
                                                                    }
                                                                    <Menu
                                                                        id="simple-menu"
                                                                        anchorEl={this.state.anchor}
                                                                        keepMounted
                                                                        open={Boolean(this.state.anchor)}
                                                                        elevation={0}
                                                                        onClose={() => this.closeEditDeleteLink()}>
                                                                        <MenuItem onClick={() => this.onEditOpen()}>Edit</MenuItem>
                                                                        <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                                                    </Menu>
                                                                </IconButton>
                                                            }
                                                            title={item.first_name + " " + item.last_name}
                                                            titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                                                            subheader=""
                                                        />

                                                        :
                                                        <CardHeader
                                                            avatar={
                                                                <Avatar style={{ height: 50, width: 50 }} src={require('../../../assets/default_avatar.png')} aria-label="Recipe" className={classes.avatar} />
                                                            }
                                                            action={
                                                                <IconButton>
                                                                    {item.owner_id === this.props.personId ?
                                                                        <MoreHorizIcon id={item.feed_id} onClick={(event) => this.openEditDeleteLink(event, item)} />
                                                                        : null
                                                                    }
                                                                    <Menu
                                                                        id="simple-menu"
                                                                        anchorEl={this.state.anchor}
                                                                        keepMounted
                                                                        open={Boolean(this.state.anchor)}
                                                                        elevation={1}
                                                                        onClose={() => this.closeEditDeleteLink()}>
                                                                        <MenuItem onClick={() => this.onEditOpen()}>Edit</MenuItem>
                                                                        <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                                                    </Menu>
                                                                </IconButton>
                                                            }
                                                            title={item.first_name + " " + item.last_name}
                                                            titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                                                            subheader=""
                                                        />

                                                    }
                                                    <CardContent style={{ backgroundColor: '#fff', cursor: 'pointer' }}>

                                                        <Typography component="p">
                                                            {item.message !== '' ? item.message : null}
                                                        </Typography>
                                                        <img style={{ height: 520, width: 450, marginLeft: 70, marginTop: 10 }} src={item.image_aws_key} alt="" />
                                                    </CardContent>
                                                    <CardActions style={{ marginTop: -10 }} className={classes.actions}>

                                                    </CardActions>
                                                </Card>
                                                <br />
                                            </>
                                        )
                                    })
                                    :
                                    this.props.userRoleId === 8 ?
                                        this.state.feed.map((item, key) => {
                                            const { classes } = this.props
                                            return (
                                                <>
                                                    <Card key={key} className={classes.card}>
                                                        {item.logo_aws_key !== "" ?
                                                            <CardHeader
                                                                avatar={
                                                                    <Avatar style={{ height: 50, width: 50 }} src={item.logo_aws_key} aria-label="Recipe" className={classes.avatar} />
                                                                }
                                                                action={
                                                                    <IconButton>
                                                                        {item.owner_id === this.props.personId ?
                                                                            <MoreHorizIcon id={item.feed_id} onClick={(event) => this.openEditDeleteLink(event, item)} />
                                                                            : null
                                                                        }
                                                                        <Menu
                                                                            id="simple-menu"
                                                                            anchorEl={this.state.anchor}
                                                                            keepMounted
                                                                            open={Boolean(this.state.anchor)}
                                                                            elevation={0}
                                                                            onClose={() => this.closeEditDeleteLink()}>
                                                                            <MenuItem onClick={() => this.onEditOpen()}>Edit</MenuItem>
                                                                            <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                                                        </Menu>
                                                                    </IconButton>
                                                                }
                                                                title={item.first_name + " " + item.last_name}
                                                                titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                                                                subheader=""
                                                            />

                                                            :
                                                            <CardHeader
                                                                avatar={
                                                                    <Avatar style={{ height: 50, width: 50 }} src={require('../../../assets/default_avatar.png')} aria-label="Recipe" className={classes.avatar} />
                                                                }
                                                                action={
                                                                    <IconButton>
                                                                        {item.owner_id === this.props.personId ?
                                                                            <MoreHorizIcon id={item.feed_id} onClick={(event) => this.openEditDeleteLink(event, item)} />
                                                                            : null
                                                                        }
                                                                        <Menu
                                                                            id="simple-menu"
                                                                            anchorEl={this.state.anchor}
                                                                            keepMounted
                                                                            open={Boolean(this.state.anchor)}
                                                                            elevation={1}
                                                                            onClose={() => this.closeEditDeleteLink()}>
                                                                            <MenuItem onClick={() => this.onEditOpen()}>Edit</MenuItem>
                                                                            <MenuItem onClick={() => this.onDeleteOpen()}>Delete</MenuItem>
                                                                        </Menu>
                                                                    </IconButton>
                                                                }
                                                                title={item.first_name + " " + item.last_name}
                                                                titleTypographyProps={{ style: { fontWeight: 'bold' } }}
                                                                subheader=""
                                                            />

                                                        }
                                                        <CardContent style={{ backgroundColor: '#fff', cursor: 'pointer' }}>

                                                            <Typography component="p">
                                                                {item.message !== '' ? item.message : null}
                                                            </Typography>
                                                            <img style={{ height: 520, width: 450, marginLeft: 70, marginTop: 10 }} src={item.image_aws_key} alt="" />
                                                        </CardContent>
                                                        <CardActions style={{ marginTop: -10 }} className={classes.actions}>

                                                        </CardActions>
                                                    </Card>
                                                    <br />
                                                </>
                                            )
                                        })
                                        : null
                                }
                            </Grid>

                            <Grid item xs={3}>
                                <div style={{ position: 'sticky', top: '10%' }}>
                                    {this.props.userRoleId < 8 ?

                                        <div>
                                            <Grid container style={styles.itemContainerStyle}>

                                                <Card style={{ width: '100%' }}>
                                                    <CardContent>
                                                        <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                                                            <Grid item xs={6}>
                                                                <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                                                                    Links
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                                {this.props.personId === this.props.profileId ?
                                                                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewClick()}>
                                                                        <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                                                                    </IconButton>
                                                                    : null}
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <List style={{ height: 200, overflow: 'auto' }}>
                                                                    {this.renderLinks()}
                                                                </List>
                                                            </Grid>
                                                        </Grid>

                                                    </CardContent>
                                                </Card>
                                            </Grid>


                                            <Grid container style={styles.itemContainerStyle}>

                                                <Card style={{ marginTop: 10, width: '100%' }}>
                                                    <CardContent>
                                                        <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                                                            <Grid item xs={6}>
                                                                <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                                                                    Events
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                                {this.props.personId === this.props.profileId ?
                                                                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewEventClick()}>
                                                                        <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                                                                    </IconButton>
                                                                    : null}
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <List style={{ height: 200, overflow: 'auto' }}>
                                                                    {this.renderEvents()}
                                                                </List>
                                                            </Grid>
                                                        </Grid>

                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </div>
                                        : this.props.userRoleId === 8 ?

                                            <div>
                                                <Grid container style={styles.itemContainerStyle}>

                                                    <Card style={{ width: '100%' }}>
                                                        <CardContent>
                                                            <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                                                                <Grid item xs={6}>
                                                                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                                                                        Links
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <List style={{ height: 200, overflow: 'auto' }}>
                                                                        {this.renderLinks()}
                                                                    </List>
                                                                </Grid>
                                                            </Grid>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>


                                                <Grid container style={styles.itemContainerStyle}>

                                                    <Card style={{ marginTop: 10, width: '100%' }}>
                                                        <CardContent>
                                                            <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                                                                <Grid item xs={6}>
                                                                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                                                                        Events
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <List style={{ height: 200, overflow: 'auto' }}>
                                                                        {this.renderEvents()}
                                                                    </List>
                                                                </Grid>
                                                            </Grid>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </div>
                                            : null
                                    }
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Dialog open={this.state.isEditGroupOpen}
                    onClose={this.onNewClose} fullWidth
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
                        Edit Extracurricular Activity
                    </DialogTitle>

                    <DialogContent>

                        <Grid container>

                            <Grid item xs={8}>
                                <TextField
                                    style={{ width: 220 }}
                                    id="standard-name"
                                    label="Club/Sport/Other"
                                    onChange={(event) => this.onEditActivityChange(event)}
                                    value={this.state.selectedItem.activity_name}
                                    margin="normal"
                                />

                                <TextField
                                    style={{ width: 220 }}
                                    id="standard-name"
                                    label="Role"
                                    onChange={(event) => this.onEditRoleChange(event)}
                                    value={this.state.selectedItem.role}
                                    margin="normal"
                                />

                            </Grid>

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                            onClick={() => this.onDeleteExtracurricular()} color="primary">
                            Delete
                        </Button>
                        <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                            onClick={() => this.onEditExtracurricularClick()} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={this.state.isNewOpen}
                    onClose={this.onNewClose} fullWidth
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
                        {i18n.t("BIO_TILE_NEW")}
                    </DialogTitle>

                    <DialogContent>
                        {this.props.language === "esp" ?
                            <TextField
                                id="standard-name" fullWidth
                                label="Biografía" multiline rows={6}
                                onChange={(e) => this.setState({ link: e.target.value })}
                                value={this.state.link} />
                            :
                            <TextField
                                id="standard-name" fullWidth
                                label="Links" multiline rows={6}
                                onChange={(e) => this.setState({ link: e.target.value })}
                                value={this.state.link} />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                            onClick={() => this.onPostLink()} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.isNewEventOpen}
                    onClose={this.onNewClose} fullWidth
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
                        Event
                    </DialogTitle>

                    <DialogContent>

                        <TextField
                            id="standard-name" fullWidth
                            label="Event Title" multiline rows={3}
                            onChange={(e) => this.setState({ title: e.target.value })}
                            value={this.state.title} />

                        <TextField
                            id="standard-name" fullWidth
                            label="Event Description" multiline rows={3}
                            onChange={(e) => this.setState({ description: e.target.value })}
                            value={this.state.description} />

                        <TextField
                            label="Date"
                            type="date"
                            onChange={(e) => this.setState({ newDate: e.target.value })}
                            value={this.state.newDate}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                            onClick={() => this.onPostEvent()} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.isDeleteLinkOpen}
                    onClose={this.isDeleteLinkClose} fullWidth
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={this.onDeleteLinkClose}>
                        Would you like to delete this link?
                    </DialogTitle>
                    <DialogActions>
                        <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
                            onClick={() => this.deleteLink()} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog onClose={this.onDeleteClose} aria-labelledby="customized-dialog-title" open={this.state.onDeleteOpen}>
                    <DialogTitle id="customized-dialog-title" onClose={this.onDeleteClose}>
                        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Delete Post?
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            You can edit it if you just need to change something.
                            If you wish to delete this post you can do so by clicking delete
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onEditOpen()} color="primary">
                            Edit
                        </Button>
                        <Button onClick={() => this.onDeletePost()} color="primary">
                            Delete post
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog onClose={this.onEditClose} aria-labelledby="customized-dialog-title" open={this.state.isEditOpen} fullWidth={true}>
                    <DialogTitle2 id="customized-dialog-title" onClose={this.onEditClose}>
                        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Edit Post
                        </Typography>
                    </DialogTitle2>
                    <DialogContent>
                        <FormControl style={{ width: '90%', marginLeft: '5%', marginBottom: 10 }} className={classes.formControl}>

                            <img style={{ height: 50, width: 50, borderRadius: 50 }} src={require('../../../assets/default_avatar.png')} alt="user" />

                            <TextField
                                id="standard-textarea"
                                placeholder="Share a post"
                                multiline
                                InputProps={{ disableUnderline: true }}
                                margin="normal"
                                style={{ width: '85%', marginTop: -35, marginLeft: 65 }}
                                onChange={(e) => this.onMessageEdit(e)}
                                value={this.state.selectedItem.message}
                            />
                            {'image_aws_key' in this.state.selectedItem && this.state.selectedItem.image_aws_key !== '' ?
                                <img style={{ height: 520, width: 450, margin: 'auto' }} src={this.state.selectedItem.image_aws_key} alt="uploaded" />
                                : null}
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onEditClose()} color="primary">
                            Discard
                        </Button>
                        <Button onClick={() => this.onEditPost()} color="primary">
                            Done Editing
                        </Button>
                    </DialogActions>
                </Dialog>




                <Dialog onClose={this.onEditClose} aria-labelledby="customized-dialog-title" open={this.state.isEditEventOpen} fullWidth={true}>
                    <DialogTitle2 id="customized-dialog-title" onClose={this.onEditClose}>
                        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Edit Event
                        </Typography>
                    </DialogTitle2>
                    <DialogContent>

                        <TextField
                            id="standard-name" fullWidth
                            label="Event Title" multiline rows={3}
                            onChange={(event) => this.onTitleChange(event)}
                            value={this.state.selectedItem.event_title} />

                        <TextField
                            id="standard-name" fullWidth
                            label="Event Description" multiline rows={3}
                            onChange={(event) => this.onDescriptionChange(event)}
                            value={this.state.selectedItem.event_description} />

                        <TextField
                            label="Date"
                            type="date"
                            onChange={(e) => this.setState({ editDate: e.target.value })}
                            value={this.state.editDate}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.onDeleteEvent(this.state.selectedItem)} color="primary">
                            Delete
                        </Button>
                        <Button onClick={() => this.onEditEventPost()} color="primary">
                            Done Editing
                        </Button>
                    </DialogActions>
                </Dialog>



                <Dialog open={this.state.isDeleteStudentOpen}
                    onClose={this.onDeleteStudentClose} fullWidth
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title"
                        onClose={this.onDeleteStudentClose}>
                        Are you sure you want to remove <b>{this.state.selectedItem.first_name + " " + this.state.selectedItem.last_name}</b> from the group?
                    </DialogTitle>

                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.onDeleteClick}
                            style={{ color: '#FFF', fontWeight: 'bold' }} color="secondary">
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>


            </div>
        );
    }
}

ExtracurricularScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        token: state.auth.token,
        personId: state.auth.personId,
        taskId: state.task.taskId,
        userRoleId: state.auth.userRoleId,
        groupId: state.group.groupId,
        districtId: state.profile.districtId,
        careerId: state.career.careerId,
        selectedLanguage: state.language.selectedLanguage,
        profileId: state.profile.profileId,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); }
    }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExtracurricularScreen)));
