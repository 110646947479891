import CheckIcon from '@mui/icons-material/Check';
import {
  Card, CardContent,
  Grid,
  List, ListItem, ListItemIcon,
  ListItemText, Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';

class TaskScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: []
    }
  }

  componentDidMount() {
    this.getData(this.props.careerId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.careerId);
  }

  getData(careerId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/careers/tasks/' + careerId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderTasks() {

    var content = this.state.dataSource.map((item, key) => {

      return (
        <ListItem key={key} button alignItems="flex-start">
          <ListItemIcon>
            <CheckIcon style={{ color: 'green', fontSize: 32 }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography style={{ fontSize: 16 }} component="span"
                  className={this.props.classes.inline} color="textPrimary">
                  {item.task_name}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    })

    return content;
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#1F3545', marginBottom: 10 }}>{i18n.t("CAREER_PROFILE_TASKS")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 300, overflow: 'auto' }}>
                    {this.renderTasks()}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

TaskScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    careerId: state.career.careerId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskScreen));