import { GET_GROUP, SET_GROUP  } from "../actions";

export const defaultState = {
     groupId: 0,
};
 
export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case GET_GROUP:
             return Object.assign({}, state, {
             });
         case SET_GROUP:
             return Object.assign({}, state, {
                groupId: action.groupId
             });
         default:
             return state
     }
 }