import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { login } from '../../redux/actions/auth';
import styles from './style.js';

class ResetScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            confirmPassword: '',
            updatedPassword: false,
            isLoading: false,
            isSent: false,
            tokenExpired: false,
            tokenInvalid: false,
            passwordMatch: false,
            passwordLength: false,
            passwordCapital: false,
            passwordNumber: false,
            passwordSpecial: false,
            passwordValid: false
        };
    }

    componentDidMount() {

        fetch('https://api.tasselpathway.com/resetPassword', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: this.props.match.params.token
            }),
            credentials: 'omit'
        })
            .then(response => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        username: responseJson.user_name,
                        updatedPassword: false,
                        isLoading: false,
                        tokenExpired: false,
                        tokenInvalid: false
                    });
                } else if (responseJson.message === 'Reset Token Expired!') {
                    this.setState({
                        username: '',
                        updatedPassword: false,
                        isLoading: false,
                        tokenExpired: true,
                        tokenInvalid: false
                    })
                } else {
                    this.setState({
                        username: '',
                        updatedPassword: false,
                        isLoading: false,
                        tokenExpired: false,
                        tokenInvalid: true
                    });
                }
            })
            .catch((e) => {
            });
    }

    changePassword() {

        fetch('https://api.tasselpathway.com/changePassword', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'

            },
            body: JSON.stringify({
                reset_token: this.props.match.params.token,
                user_name: this.state.username,
                password: this.state.password
            })
        })
            .then(response => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        updatedPassword: true,
                        isLoading: false,
                        tokenExpired: false,
                        tokenInvalid: false
                    });
                    this.props.history.replace('/');
                    this.props.onLogin(this.state.username, this.state.password, this.props.selectedLanguage);

                } else if (responseJson.message === 'Reset Token Expired!') {
                    this.setState({
                        username: '',
                        updatedPassword: false,
                        isLoading: false,
                        tokenExpired: true,
                        tokenInvalid: false
                    })
                } else {
                    this.setState({
                        username: '',
                        updatedPassword: false,
                        isLoading: false,
                        tokenExpired: false,
                        tokenInvalid: true
                    })
                }

            })
            .catch((e) => {
                console.warn(e);
            });
    }

    forgotPassword() {

        fetch('https://api.tasselpathway.com/forgotPassword', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_name: this.state.username
            })
        })
            .then(response => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        invalidUserName: false,
                        isSent: true
                    })
                } else {
                    this.setState({
                        invalidUserName: true,
                        isSent: false
                    })
                }
            })
            .catch((e) => {
                console.warn(e);
            });
    }

    getBackgroundImage() {

    }

    renderLogoImage() {
        return null;
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    onChangePassword(event) {

        this.validatePassword(this.state.confirmPassword, event.target.value);

        this.setState({
            password: event.target.value
        })
    }

    onChangeConfirmPassword(event) {

        this.validatePassword(event.target.value, this.state.password)

        this.setState({
            confirmPassword: event.target.value
        })
    }

    validatePassword(confirmPassword, password) {

        var isValid = true;

        if (confirmPassword === password && password !== "" && confirmPassword !== "") {
            this.setState({
                passwordMatch: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordMatch: false
            })
        }

        if (password.length >= 8) {
            this.setState({
                passwordLength: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordLength: false
            })
        }

        var capital = /[A-Z]/;
        var number = /[0-9]/;
        var special = /[!@#$%\^&*)(+=._-]/;

        if (password.match(capital)) {
            this.setState({
                passwordCapital: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordCapital: false
            })
        }

        if (password.match(number)) {
            this.setState({
                passwordNumber: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordNumber: false
            })
        }

        if (password.match(special)) {
            this.setState({
                passwordSpecial: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordSpecial: false
            })
        }

        if (isValid) {
            this.setState({
                passwordValid: true
            })
        } else {
            this.setState({
                passwordValid: false
            })
        }
    }

    render() {
        const { classes } = this.props;
        if (this.state.isSent === true) {
            return (
                <Grid container spacing={0}
                    direction="column" alignItems="center"
                    justify="center" style={{ backgroundColor: '#F3F3F4' }}>

                    <Grid style={styles.container}>

                        <Grid style={{ justifyContent: 'center', alignItems: 'center' }}>
                            {this.renderLogoImage()}
                        </Grid>

                        <h2 style={{ textAlign: 'center', fontSize: 39, color: '#25D192' }}><img width="120" src={require('../../assets/logo_icon.png')} alt="Tassel" /></h2>

                        <h3 style={{ color: '#2A2042', fontSize: 24 }}>Check Your Email</h3>

                        <h4>We've sent your password reset link to</h4>
                        <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>{this.state.username}</h4>

                        <p>If you don't receive it in the next few minutes, check your email junk folder or <br /><br />
                            <Link style={{ color: '#2A2042', fontWeight: 'bold' }} to={"/forgot"}>enter your email again.</Link>
                        </p>

                        <Grid style={{ marginTop: 30, padding: 10 }}>
                            <Link style={{ color: '#2A2042', fontWeight: 'bold', textAlign: 'center' }} to={"/"}>Back to Sign In</Link>
                        </Grid>

                    </Grid>

                    <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, color: '#FFF' }}>
                        © 2019 Tassel, LLC | <a style={{ color: '#FFF' }} target="_blank" without rel="noopener noreferrer" href="https://www.tasselpathway.com/terms">Terms of Use</a>
                    </Grid>

                </Grid>
            )
        } else if (this.state.tokenInvalid === true) {
            return (
                <Grid container spacing={0}
                    direction="column" alignItems="center"
                    justify="center" style={{ backgroundColor: '#F3F3F4' }}>

                    <Grid style={styles.container}>

                        <Grid style={{ justifyContent: 'center', alignItems: 'center' }}>
                            {this.renderLogoImage()}
                        </Grid>

                        <h2 style={{ textAlign: 'center', fontSize: 39, color: '#25D192' }}><img width="120" src={require('../../assets/logo_icon.png')} alt="Tassel" /></h2>

                        <h3 style={{ color: '#2A2042', fontSize: 24 }}>Invalid Token Provided!</h3>

                        <h4>The token you provided is no longer valid. Please request another password reset email.</h4>

                        <form style={{ marginTop: 40 }}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField id="input-with-icon-adornment" label="Email Address"
                                    style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                    autoFocus={true}
                                    variant="outlined"
                                    className={classes.textField}
                                    onChange={this.handleChange('username')} />
                            </FormControl>

                            <Grid style={{ marginTop: 20, textAlign: 'center' }} >
                                <Button style={styles.resetButton} onClick={() => this.forgotPassword()}>
                                    Send Password Reset Link
                                </Button>
                            </Grid>

                            <Grid style={{ marginTop: 30, padding: 10 }}>
                                <Link style={{ color: '#2A2042', fontWeight: 'bold', textAlign: 'center' }} to={"/"}>Back to Sign In</Link>
                            </Grid>

                        </form>

                    </Grid>

                    <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, color: '#FFF' }}>
                        © 2019 Tassel, LLC | <a style={{ color: '#FFF' }} target="_blank" without rel="noopener noreferrer" href="https://www.tasselpathway.com/terms">Terms of Use</a>
                    </Grid>

                </Grid>
            )
        }

        else if (this.state.tokenExpired === true) {
            return (
                <Grid container spacing={0}
                    direction="column" alignItems="center"
                    justify="center" style={{ backgroundColor: '#F3F3F4' }}>

                    <Grid style={styles.container}>

                        <Grid style={{ justifyContent: 'center', alignItems: 'center' }}>
                            {this.renderLogoImage()}
                        </Grid>

                        <h2 style={{ textAlign: 'center', fontSize: 39, color: '#25D192' }}><img width="120" src={require('../../assets/logo_icon.png')} alt="Tassel" /></h2>

                        <h3 style={{ color: '#2A2042', fontSize: 24 }}>Password Link Expired!</h3>

                        <h4>The password reset link is no longer valid. Please request another password reset email.</h4>

                        <form style={{ marginTop: 40 }}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField id="input-with-icon-adornment" label="Email Address"
                                    style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                    autoFocus={true}
                                    variant="outlined"
                                    className={classes.textField}
                                    onChange={this.handleChange('username')} />
                            </FormControl>

                            <Grid style={{ marginTop: 20, textAlign: 'center' }} >
                                <Button style={styles.resetButton} onClick={() => this.forgotPassword()}>
                                    Send Password Reset Link
                                </Button>
                            </Grid>

                            <Grid style={{ marginTop: 30, padding: 10 }}>
                                <Link style={{ color: '#2A2042', fontWeight: 'bold', textAlign: 'center' }} to={"/"}>Back to Sign In</Link>
                            </Grid>

                        </form>

                    </Grid>

                    <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, color: '#FFF' }}>
                        © 2019 Owen Technologies, LLC | <a style={{ color: '#FFF' }} target="_blank" without rel="noopener noreferrer" href="https://www.tasselpathway.com/terms">Terms of Use</a>
                    </Grid>

                </Grid>
            )
        } else {

            return (
                <Grid container spacing={0}
                    direction="column" alignItems="center"
                    justify="center" style={{ backgroundColor: '#F3F3F4' }}>

                    <Grid style={styles.container}>

                        <Grid style={{ justifyContent: 'center', alignItems: 'center' }}>
                            {this.renderLogoImage()}
                        </Grid>

                        <h2 style={{ textAlign: 'center', fontSize: 39, color: '#25D192' }}><img width="120" src={require('../../assets/logo_icon.png')} alt="Tassel" /></h2>

                        <h3 style={{ color: '#2A2042', fontSize: 24 }}>Change your password?</h3>

                        <form style={{ marginTop: 40 }}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField id="input-with-icon-adornment" label="Username"
                                    style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                    value={this.state.username}
                                    variant="outlined"
                                    className={classes.textField} />
                            </FormControl>

                            <FormControl style={{ width: '100%' }}>
                                <TextField id="input-with-icon-adornment" label="Password"
                                    style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                    autoFocus={true}
                                    variant="outlined"
                                    type="password"
                                    className={classes.textField}
                                    onChange={(event) => this.onChangePassword(event)} />
                            </FormControl>

                            <FormControl style={{ width: '100%' }}>
                                <TextField id="input-with-icon-adornment" label="Confirm Password"
                                    style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                    variant="outlined"
                                    type="password"
                                    className={classes.textField}
                                    onChange={(event) => this.onChangeConfirmPassword(event)} />
                            </FormControl>

                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid container>
                                        {this.state.passwordCapital ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>Capital Letter</Typography>
                                    </Grid>
                                    <Grid container>
                                        {this.state.passwordNumber ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>Number</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        {this.state.passwordSpecial ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>Special Character</Typography>
                                    </Grid>
                                    <Grid container>
                                        {this.state.passwordLength ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>8+ Characters</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid container>
                                        {this.state.passwordMatch ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>Passwords Match</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>

                                </Grid>
                            </Grid>

                            <Grid style={{ marginTop: 20, textAlign: 'center' }} >
                                <Button disabled={this.state.passwordValid !== true ? true : false}
                                    style={styles.resetButton} onClick={() => this.changePassword()}>
                                    Change Password
                                </Button>
                            </Grid>

                            <Grid style={{ marginTop: 30, padding: 10 }}>
                                <Link style={{ color: '#2A2042', fontWeight: 'bold', textAlign: 'center' }} to={"/"}>Back to Sign In</Link>
                            </Grid>

                        </form>

                    </Grid>

                    <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, fontWeight: 'bold', color: '#FFF' }}>
                        © 2019 Owen Technologies, LLC | <a style={{ color: '#FFF' }} target="_blank" without rel="noopener noreferrer" href="https://www.tasselpathway.com/terms">Terms of Use</a>
                    </Grid>

                </Grid>

            )
        }
    }
}

ResetScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        language: state.language.selectedLanguage,
        hasError: state.auth.hasError,
        errorMessage: state.auth.errorMessage
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (username, password, language) => { dispatch(login(username, password, language)); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ResetScreen)));