import { combineReducers } from 'redux';
import auth from './auth';
import person from './person';
import progress from './progress';
import profile from './profile';
import career from './career';
import college from './college';
import major from './major';
import employer from './employer';
import task from './task';
import group from './group';
import alert from './alert';
import resume_builder from './resume_builder'
 
import language from './language'
const rootReducer = combineReducers({
    auth,
    person,
    progress,
    profile,
    career,
    college,
    major,
    employer,
    task,
    group,
    language,
    alert,
    resume_builder
});
 
export default rootReducer;