import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch, Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class MyAssignmentScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      onAssignClose: false,
      isDetailOpen: false,
      isArchiveOpen: false,
      dataSource: [],
      tasks: [],
      customGroups: [],
      grades: [],
      gradeCheckList: [],
      filteredDataSource: [],
      schools: [],
      schoolCheckList: [],
      classCheckList: [],

      studentList: [],
      studentCheckList: [],

      notStartedData: [],
      pendingData: [],
      completedData: [],
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc',
      tabValue: 0
    }

    this.onAssignClose = this.onAssignClose.bind(this);
    this.onArchiveClose = this.onArchiveClose.bind(this);
    this.onDetailClose = this.onDetailClose.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);

  }

  updateDimensions() {
    let update_height = window.innerHeight - 300;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.getData();

    this.updateDimensions();
  }

  handleTabChange = (event, tabValue) => {
    this.setState({
      tabValue
    });
  }

  getData() {

    fetch('https://api.tasselpathway.com/tasks/assigned/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
            filteredDataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/tasks/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            tasks: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            schools: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/custom_groups/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            customGroups: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  getStudents(searchText) {

    fetch('http://localhost:3001/tasks/students?searchText=' + searchText, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            studentList: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  getDetail(row) {

    fetch('https://api.tasselpathway.com/tasks/not_started/' + row.task_id + '?task_id=' + row.task_id
      + '&target_type=' + row.target_type + '&target_id=' + row.target_id, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            notStartedData: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/tasks/pending/' + row.task_id + '?task_id=' + row.task_id
      + '&target_type=' + row.target_type + '&target_id=' + row.target_id, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            pendingData: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/tasks/complete/' + row.task_id + '?task_id=' + row.task_id
      + '&target_type=' + row.target_type + '&target_id=' + row.target_id, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            completedData: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  onAssignTask() {
    this.setState({
      isLoading: true
    })

    if (this.state.selectedTarget === 'District') {

      fetch('https://api.tasselpathway.com/tasks/assign/district', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          task_id: this.state.selectedTask,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isAssignOpen: false
            })
            this.getData();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
          this.getData()
        })
    } else if (this.state.selectedTarget === 'School') {

      var schools = [];

      for (let i = 0; i < this.state.schools.length; i++) {
        if (this.state.schoolCheckList['checked_' + this.state.schools[i].school_id] === true) {
          schools.push({ "school_id": this.state.schools[i].school_id });
        }
      }

      fetch('https://api.tasselpathway.com/tasks/assign/school', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          task_id: this.state.selectedTask,
          person_id: this.props.personId,
          schools: schools
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isAssignOpen: false
            })
            this.getData();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
        })

    } else if (this.state.selectedTarget === 'Grades') {

      var grades = [];

      for (let i = 0; i < this.state.grades.length; i++) {
        if (this.state.gradeCheckList['checked_' + this.state.grades[i].grade_id] === true) {
          grades.push({ "grade_id": this.state.grades[i].grade_id });
        }
      }

      fetch('https://api.tasselpathway.com/tasks/assign/grade', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          task_id: this.state.selectedTask,
          person_id: this.props.personId,
          grades: grades
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isAssignOpen: false
            })
            this.getData();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
        })

    } else if (this.state.selectedTarget === 'Classes') {

      var classes = [];

      for (let i = 0; i < this.state.customGroups.length; i++) {
        if (this.state.classCheckList['checked_' + this.state.customGroups[i].custom_group_id] === true) {
          classes.push({ "custom_group_id": this.state.customGroups[i].custom_group_id });
        }
      }

      fetch('https://api.tasselpathway.com/tasks/assign/class', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          task_id: this.state.selectedTask,
          person_id: this.props.personId,
          classes: classes
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isAssignOpen: false
            })
            this.getData();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
        })
    } else if (this.state.selectedTarget === 'Student') {

      fetch('https://api.tasselpathway.com/tasks/assign/student', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          task_id: this.state.selectedTask,
          person_id: this.props.personId,
          students: this.state.studentCheckList
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isAssignOpen: false
            })
            this.getData();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
        })
    }
  }

  onGradeChange(event) {

    var gradeId = event.target.value;
    var gradeCheckList = this.state.gradeCheckList;

    if (event.target.checked) {
      gradeCheckList['checked_' + gradeId] = true;
    } else {
      gradeCheckList['checked_' + gradeId] = false;
    }

    this.setState({
      gradeCheckList: gradeCheckList
    })
  }

  onSchoolChange(event) {

    var schoolId = event.target.value;
    var schoolCheckList = this.state.schoolCheckList;

    if (event.target.checked) {
      schoolCheckList['checked_' + schoolId] = true;
    } else {
      schoolCheckList['checked_' + schoolId] = false;
    }

    this.setState({
      schoolCheckList: schoolCheckList
    })
  }

  onClassChange(event) {

    var classId = event.target.value;
    var classCheckList = this.state.classCheckList;

    if (event.target.checked) {
      classCheckList['checked_' + classId] = true;
    } else {
      classCheckList['checked_' + classId] = false;
    }

    this.setState({
      classCheckList: classCheckList
    })
  }

  onStudentChange = (values) => {

    var studentCheckList = [];

    studentCheckList.push({ "student_id": values.person_id });

    this.setState({
      studentCheckList: studentCheckList
    })
  }

  onAssignClick() {
    this.setState({
      isAssignOpen: true
    })
  }

  onAssignClose() {
    this.setState({
      isAssignOpen: false
    })
  }

  renderTasks() {
    var content = this.state.tasks.map((item, key) => {
      return (
        <MenuItem key={key} value={item.task_id}>{item.task_name}</MenuItem>
      )
    })

    return content;
  }

  onViewClick(event, row) {

  }

  onDetailClick(event, row) {

    this.getDetail(row);

    this.setState({
      isDetailOpen: true
    })
  }

  onDetailClose() {
    this.setState({
      isDetailOpen: false
    })
  }

  onArchiveClick(event, item) {
    this.setState({
      selectedItem: item,
      isArchiveOpen: true
    })
  }

  onArchiveClose() {
    this.setState({
      selectedItem: {},
      isArchiveOpen: false
    })
  }

  onArchiveAssignment() {

    var item = this.state.selectedItem;
    item.is_archive = !item.is_archive;

    if (item.target_type === 'District') {

      fetch('https://api.tasselpathway.com/tasks/assigned/district', {
        method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          is_archive: item.is_archive,
          is_active: item.is_active,
          task_id: item.task_id,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isArchiveOpen: false
            })
          }
          this.getData();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isArchiveOpen: false
          })
        })

    } else if (item.target_type === 'Grade') {

      fetch('https://api.tasselpathway.com/tasks/assigned/grade', {
        method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          is_archive: item.is_archive,
          is_active: item.is_active,
          task_id: item.task_id,
          grade_id: item.target_id,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isArchiveOpen: false
            })
          }
          this.getData();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isArchiveOpen: false
          })
        })
    } else if (item.target_type === 'Class') {

      fetch('https://api.tasselpathway.com/tasks/assigned/class', {
        method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          is_archive: item.is_archive,
          is_active: item.is_active,
          task_id: item.task_id,
          class_id: item.target_id,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isArchiveOpen: false
            })
          }
          this.getData();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isArchiveOpen: false
          })
        })
    } else if (item.target_type === 'School') {

      fetch('https://api.tasselpathway.com/tasks/assigned/school', {
        method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          is_archive: item.is_archive,
          is_active: item.is_active,
          task_id: item.task_id,
          school_id: item.target_id,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isArchiveOpen: false
            })
          }

          this.getData();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isArchiveOpen: false
          })
        })
    }
  }

  onEditClick(event, row) {

    if (row.is_active === 1) {
      row.is_active = 0;
    } else {
      row.is_active = 1;
    }

    if (row.target_type === 'District') {

      fetch('https://api.tasselpathway.com/tasks/assigned/district', {
        method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          is_archive: row.is_archive,
          is_active: row.is_active,
          task_id: row.task_id,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        })

    } else if (row.target_type === 'Grade') {

      fetch('https://api.tasselpathway.com/tasks/assigned/grade', {
        method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          is_archive: row.is_archive,
          is_active: row.is_active,
          task_id: row.task_id,
          grade_id: row.target_id,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        })
    } else if (row.target_type === 'Class') {

      fetch('https://api.tasselpathway.com/tasks/assigned/class', {
        method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          is_archive: row.is_archive,
          is_active: row.is_active,
          task_id: row.task_id,
          class_id: row.target_id,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        })
    } else if (row.target_type === 'School') {

      fetch('https://api.tasselpathway.com/tasks/assigned/school', {
        method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          is_archive: row.is_archive,
          is_active: row.is_active,
          task_id: row.task_id,
          school_id: row.target_id,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        })
    }

  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.filteredDataSource.length - this.state.currentPage * this.state.rowsPerPage);

    return (
      <div className={classes.root}>

        <Dialog open={this.state.isDetailOpen}
          onClose={this.onDetailClose} maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title">

          <DialogTitle id="customized-dialog-title" onClose={this.onDetailClose}>
            {i18n.t("ADMIN_TASKS_ASSIGN_DETAIL")}
          </DialogTitle>

          <DialogContent>

            <Tabs value={this.state.tabValue}
              onChange={this.handleTabChange}>
              <Tab label={"NOT STARTED (" + this.state.notStartedData.length + ")"} />
              <Tab label={"PENDING (" + this.state.pendingData.length + ")"} />
              <Tab label={"COMPLETE (" + this.state.completedData.length + ")"} />
            </Tabs>

            {this.state.tabValue === 0 ?

              <Grid container spacing={3} style={{ overflow: 'auto', margin: 20, height: 300 }}>

                {this.state.notStartedData.map((item, key) => {
                  return (
                    <Grid item xs={3} key={key} style={{ margin: 0, padding: 0 }}>
                      <Typography>{item.first_name + ' ' + item.last_name}</Typography>
                    </Grid>
                  )
                })
                }
              </Grid>

              : null}

            {this.state.tabValue === 1 ?
              <Grid container spacing={3} style={{ overflow: 'auto', margin: 20, height: 300 }}>

                {this.state.pendingData.map((item, key) => {
                  return (
                    <Grid item xs={3} key={key} style={{ margin: 0, padding: 0 }}>
                      <Typography>{item.first_name + ' ' + item.last_name}</Typography>
                    </Grid>
                  )
                })
                }
              </Grid>
              : null}

            {this.state.tabValue === 2 ?
              <Grid container spacing={3} style={{ overflow: 'auto', margin: 20, height: 300 }}>

                {this.state.completedData.map((item, key) => {
                  return (
                    <Grid item xs={3} key={key} style={{ margin: 0, padding: 0 }}>
                      <Typography>{item.first_name + ' ' + item.last_name}</Typography>
                    </Grid>
                  )
                })
                }
              </Grid>
              : null}

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onDetailClose()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_TASKS_OK")}
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isArchiveOpen}
          onClose={this.onArchiveClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onArchiveClose}>
            {i18n.t("ADMIN_TASKS_ARCHIVE_ASSIGNMENT")}
          </DialogTitle>

          <DialogContent>
            <Typography>Are you sure you want to archive this assignment?</Typography>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onArchiveClose()}
              style={{ color: '#FFF', borderRadius: 3, fontWeight: 'bold' }} color="secondary">
              {i18n.t("NO")}
            </Button>
            <Button variant="contained" onClick={() => this.onArchiveAssignment()}
              style={{ color: '#FFF', marginLeft: 10, marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
              {i18n.t("YES")}
            </Button>
          </DialogActions>

        </Dialog>


        <Dialog open={this.state.isAssignOpen}
          onClose={this.onAssignClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">

          <DialogTitle id="customized-dialog-title" onClose={this.onAssignClose}>
            {i18n.t("ADMIN_TASKS_ASSIGN_TASK")}
          </DialogTitle>

          <DialogContent>

            <Grid container>

              <Grid item xs={12}>

                <FormControl style={{ marginTop: 10 }} className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="selectTask">Select Task</InputLabel>
                  <Select fullWidth
                    value={this.state.selectedTask}
                    onChange={(event) => this.setState({ selectedTask: event.target.value })}
                    inputProps={{
                      name: 'selectTask',
                      id: 'selectTask',
                    }}>
                    <MenuItem disabled value={0}>Select Task</MenuItem>
                    {this.renderTasks()}
                  </Select>
                </FormControl>

                <FormControl style={{ marginTop: 10 }} className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="newFrequencyId">Assign to</InputLabel>
                  <Select fullWidth
                    value={this.state.selectedTarget}
                    onChange={(event) => this.setState({ selectedTarget: event.target.value })}
                    inputProps={{
                      name: 'newFrequencyId',
                      id: 'newFrequencyId',
                    }}>
                    <MenuItem disabled value={0}>Assign to</MenuItem>
                    {this.props.userRoleId < 4 ?
                      <MenuItem value={'District'}>District</MenuItem>
                      : null
                    }
                    <MenuItem value={'School'}>School</MenuItem>
                    <MenuItem value={'Grades'}>Grades</MenuItem>
                    <MenuItem value={'Classes'}>Classes</MenuItem>
                    <MenuItem value={'Student'}>Student</MenuItem>
                  </Select>
                </FormControl>

              </Grid>

              {this.state.selectedTarget === 'Grades' ?

                <Grid item xs={12}>
                  <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>{i18n.t("ADMIN_TASKS_YEARS_ACTIVE")}</Typography>

                  <FormGroup row style={{ marginLeft: 20 }}>

                    {this.state.grades.map((item, key) => {
                      return (
                        <FormControlLabel key={key} onChange={(event) => this.onGradeChange(event)}
                          control={
                            <Checkbox value={item.grade_id} checked={this.state.gradeCheckList['checked_' + item.grade_id] ? true : false} />
                          }
                          label={item.grade_name}
                        />
                      )
                    })}

                  </FormGroup>
                </Grid>

                : null}

              {this.state.selectedTarget === 'School' ?

                <Grid item xs={12}>
                  <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select School(s)</Typography>

                  <FormGroup row style={{ marginLeft: 20 }}>

                    {this.state.schools.map((item, key) => {
                      return (
                        <FormControlLabel key={key} onChange={(event) => this.onSchoolChange(event)}
                          control={
                            <Checkbox value={item.school_id} checked={this.state.schoolCheckList['checked_' + item.school_id] ? true : false} />
                          }
                          label={item.school_name}
                        />
                      )
                    })}

                  </FormGroup>
                </Grid>

                : null}

              {this.state.selectedTarget === 'Classes' ?

                <Grid item xs={12}>
                  <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select Classroom(s)</Typography>

                  <FormGroup row style={{ marginLeft: 20 }}>

                    {this.state.customGroups.map((item, key) => {
                      return (
                        <FormControlLabel key={key} onChange={(event) => this.onClassChange(event)}
                          control={
                            <Checkbox value={item.custom_group_id} checked={this.state.classCheckList['checked_' + item.custom_group_id] ? true : false} />
                          }
                          label={item.name}
                        />
                      )
                    })}

                  </FormGroup>
                </Grid>

                : null}

              {this.state.selectedTarget === 'Student' ?

                <Grid item xs={12}>
                  <FormGroup row>
                    <Autocomplete fullWidth
                      style={{ marginTop: 15, }}
                      getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                      disableClearable onChange={(evt, value) => this.onStudentChange(value)}
                      options={this.state.studentList}
                      renderInput={(params) => (
                        <TextField variant='standard'
                          {...params} onChange={(evt) => this.getStudents(evt.currentTarget.value)}
                          label="Student"
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    />

                  </FormGroup>
                </Grid>

                : null}

            </Grid>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onAssignTask()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_TASKS_SAVE")}
            </Button>
          </DialogActions>

        </Dialog>

        <Grid container style={styles.itemContainerStyle}>
          <Grid container>
            <Grid item xs={2}>
              <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 3 }}
                onClick={() => this.onAssignClick()}>
                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>New Assignment</Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>

            </Grid>
          </Grid>
          <Paper style={{ marginTop: 10, borderRadius: 0, width: '100%', }}>
            <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
              <Table className={classes.table} style={{ height: this.state.height }}>
                <TableHead>
                  <TableRow>
                    <TableCell width={200} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("task_name")}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TASKS_TASKS_NAME")}</Typography>
                        {this.state.sortBy === "task_name" ? (
                          this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell width={100} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TASKS_TARGET_TYPE")}</Typography>
                      </div>
                    </TableCell>
                    <TableCell width={125} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TASKS_TARGET_NAME")}</Typography>
                      </div>
                    </TableCell>
                    <TableCell width={100} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TASKS_TOTAL_STUDENTS")}</Typography>
                      </div>
                    </TableCell>
                    <TableCell width={100} className={classes.head}>
                      <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12, textAlign: 'center' }}>Not Started</Typography>
                      </div>
                    </TableCell>
                    <TableCell width={25} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12, textAlign: 'center' }}>Pending</Typography>
                      </div>
                    </TableCell>
                    <TableCell width={25} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12, textAlign: 'center' }}>Complete</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                    <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                    <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                    .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={index} classes={classes.tableRow}
                          hover style={{ cursor: 'pointer' }}>
                          <TableCell width={200} onClick={(event) => this.onViewClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.task_name}</Typography></TableCell>
                          <TableCell width={100} onClick={(event) => this.onViewClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.target_type}</Typography></TableCell>
                          <TableCell width={125} onClick={(event) => this.onViewClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.target_name}</Typography></TableCell>
                          <TableCell width={100} onClick={(event) => this.onViewClick(event, row)}><Typography style={{ fontSize: 12, textAlign: 'center' }}>{row.total_students}</Typography></TableCell>
                          <TableCell width={100} onClick={(event) => this.onViewClick(event, row)}><Typography style={{ fontSize: 12, textAlign: 'center' }}>{(row.total_students - row.total_pending) - row.total_complete}</Typography></TableCell>
                          <TableCell width={25} onClick={(event) => this.onViewClick(event, row)}><Typography style={{ fontSize: 12, textAlign: 'center' }}>{row.total_pending}</Typography></TableCell>
                          <TableCell width={25} onClick={(event) => this.onViewClick(event, row)}><Typography style={{ fontSize: 12, textAlign: 'center' }}>{row.total_complete}</Typography></TableCell>

                          <TableCell width={25}
                            onClick={(event) => this.onEditClick(event, row)}>
                            <FormControlLabel
                              control={<Switch checked={row.is_active} color="primary" />}
                              label=""
                            />
                          </TableCell>

                          <TableCell width={25}>
                            <Button variant="contained" color="secondary"
                              onClick={(event) => this.onDetailClick(event, row)} style={{ borderRadius: 3 }}>
                              <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>Progress</Typography>
                            </Button>
                          </TableCell>

                          <TableCell width={25}>
                            <Button variant="contained" color="secondary"
                              onClick={(event) => this.onArchiveClick(event, row)} style={{ borderRadius: 3 }}>
                              <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>Archive</Typography>
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    }
                    )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 50 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.currentPage}
              count={this.state.filteredDataSource.length}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </div>
    );
  }
}

MyAssignmentScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage,
    profileId: state.profile.profileId,
    schoolId: state.profile.schoolId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyAssignmentScreen)));
