import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase, ListItem, ListItemAvatar, ListItemText,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

class EmployerListScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      card: []
    }
  }

  componentDidMount() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/employers', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  onMouseOut(key) {

    var card = this.state.card;
    card['card_' + key] = false;

    this.setState({
      card: card
    })
  }

  onMouseOver(key) {

    var card = this.state.card;
    card['card_' + key] = true;

    this.setState({
      card: card
    })
  }

  renderEmployers() {

    var content = this.state.dataSource.map((item, key) => {

      var link = "/employer/" + item.employer_id;

      return (
        <Grid item xs={6} key={key}>
          <Card style={{ margin: 5, height: 150 }} raised={this.state.card['card_' + key]}
            onMouseOut={() => this.onMouseOut(key)}
            onMouseOver={() => this.onMouseOver(key)} >

            <ListItem alignItems="flex-start" component={Link} to={link}>
              <ListItemAvatar>
                <Avatar alt="MSU" style={{ height: 75, width: 75, marginTop: 20 }} src={item.logo_image_url} />
              </ListItemAvatar>
              <ListItemText
                style={{ marginTop: 30 }}
                primary={
                  <React.Fragment>
                    <Typography component="span" style={{ fontSize: 18, fontWeight: 'bold', color: '#1F3545' }}>
                      {item.employer_name}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography component="span" color="textPrimary">
                      {item.city}, {item.state_name}
                    </Typography>
                  </React.Fragment>
                }
              />

              <IconButton style={{ marginTop: 40 }}>
                <FontAwesomeIcon style={{ fontSize: 28, color: '#25D192' }} icon={faHeart} />
              </IconButton>

            </ListItem>
          </Card>
        </Grid>
      )
    })

    return content;

  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Grid container style={styles.itemContainerStyle}>
          <Grid item xs={12} style={{ marginLeft: 100, marginRight: 100 }}>

            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 24, color: '#333', fontWeight: 'bold' }}>Employers</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ fontSize: 14, color: '#333', marginTop: 30, fontWeight: 'bold' }}>
                  Showing {this.state.dataSource.length} Results</Typography>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    onChange={this.onSearch} style={{ width: '100%' }}
                    placeholder="Search"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container>

              <Grid item xs={2}>

                <div style={{ marginRight: 40 }}>
                  <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>Location</Typography>
                  <hr />
                  <FormControl component="fieldset" className={classes.formControl}
                    style={{
                      height: 300, borderBottom: '1px solid #eaeaea', marginLeft: 5, marginBottom: 20,
                      paddingLeft: 10, width: '100%', overflow: 'auto'
                    }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Kentucky"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Alabama"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Alaska"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Arizona"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Arkansas"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="California"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Colorado"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Connecticut"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Delaware"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Florida"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Georgia"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Hawaii"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Idaho"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox />
                        }
                        label="Illinois"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={10}>

                <Grid container style={{ overflow: 'auto' }}>

                  {this.renderEmployers()}

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

EmployerListScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmployerListScreen));