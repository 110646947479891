export default {
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    },
    extracurricularTitle:{
        fontSize: 18, 
        fontWeight: 'bold', 
        marginTop: 10,
        color: '#4D4D4F', 
        marginBottom: 20
    },
    activityList:{
        height: 200, 
        overflow: 'auto' 
    },
    deleteButton:{
        marginRight: 20, 
        fontWeight: 'bold', 
        color: '#FFF'
    },
    saveButton:{
        marginRight: 20, 
        fontWeight: 'bold', 
        color: '#FFF'
    },
    inputLogo: {
        color: '#33CB89',
        display: 'inline-block',
        cursor: 'pointer',
        padding:10,
        borderRadius:5,
        marginBottom:10
    },
    memberDelete:{
        marginTop:'7',
        marginLeft:'20%'
    },    
    postButton:{
        width:'99%', 
        padding:5, 
        backgroundColor:'#25D192', 
        fontWeight:'bold', 
        color:"#fff",
        fontSize:15, 
        border:0, 
        borderRadius:5,
        outline:'none',
        cursor:'pointer',
        marginTop:10
    },
  };
