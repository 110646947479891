import { faEye, faHandPaper, faHeadphones, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Radio, RadioGroup,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
);


const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class LearningScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      tipValue: 0,
      value: 0,
      clusters: [],
      recentTopStyle: 0,
      recentStyles: [],
      learningStyles: [],
      learning: [],
      dataSource: [],
      learningChart: {
        labels: ['', '', ''],
        datasets: [{
          data: [
            0, 0, 0
          ],
          backgroundColor: ['#25D192', '#1F3545', '#4D4D50'],
          hoverBackgroundColor: ['#25D192', '#1F3545', '#4D4D50']
        }]
      },
      selectedItem: {},
      currentQuestion: 0,
      viewCurrentQuestion: 0,
      choices: [],
      totals: [],
      recentChart: {
        labels: [
          "Visual",
          "Auditivo",
          "Táctil"],
        datasets: [{
          data: [
            0, 0, 0
          ],
          backgroundColor: ['#25D192', '#1F3545', '#4D4D50'],
          hoverBackgroundColor: ['#25D192', '#1F3545', '#4D4D50']
        }]
      },
      chartOptions: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        responsive: true
      },
      learningAnswers: [],
      isNewOpen: false,
      isTipOpen: false,
      isViewOpen: false
    }

    this.renderAnswers = this.renderAnswers.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onViewClose = this.onViewClose.bind(this);
    this.onTipClose = this.onTipClose.bind(this);
  }

  componentDidMount() {
    this.getData(this.props.profileId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(profileId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_learning/person/' + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/profile_learning_style/recent/' + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          var recentTopStyle = 0;

          if (responseJson.results[0].percentage > responseJson.results[1].percentage && responseJson.results[0].percentage > responseJson.results[2].percentage) {
            recentTopStyle = 0;
          } else if (responseJson.results[1].percentage > responseJson.results[0].percentage && responseJson.results[1].percentage > responseJson.results[2].percentage) {
            recentTopStyle = 1;
          } else {
            recentTopStyle = 2;
          }
          this.props.selectedLanguage === "esp" ?
            this.setState({
              isLoading: false,
              recentTopStyle: recentTopStyle,
              recentStyles: responseJson.results,
              recentChart: {
                labels: [
                  "Visual",
                  "Auditivo",
                  "Táctil"],
                datasets: [{
                  data: [
                    responseJson.results[0].percentage,
                    responseJson.results[1].percentage,
                    responseJson.results[2].percentage
                  ],
                  backgroundColor: ['#25D192', '#1F3545', '#4D4D50'],
                  hoverBackgroundColor: ['#25D192', '#1F3545', '#4D4D50']
                }]
              },
            })
            :
            this.setState({
              isLoading: false,
              recentTopStyle: recentTopStyle,
              recentStyles: responseJson.results,
              recentChart: {
                labels: [
                  "Visual",
                  "Auditivo",
                  "Táctil"],
                datasets: [{
                  data: [
                    responseJson.results[0].percentage,
                    responseJson.results[1].percentage,
                    responseJson.results[2].percentage
                  ],
                  backgroundColor: ['#25D192', '#1F3545', '#4D4D50'],
                  hoverBackgroundColor: ['#25D192', '#1F3545', '#4D4D50']
                }]
              },
            })

          this.props.onLearningLoaded(responseJson.results)

        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  getLearning(learningId) {

    fetch('https://api.tasselpathway.com/profile_learning/' + learningId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            learningAnswers: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/profile_learning_style/' + learningId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          var topStyle = 0;

          if (responseJson.results[0].percentage > responseJson.results[1].percentage && responseJson.results[0].percentage > responseJson.results[2].percentage) {
            topStyle = 0;
          } else if (responseJson.results[1].percentage > responseJson.results[0].percentage && responseJson.results[1].percentage > responseJson.results[2].percentage) {
            topStyle = 1;
          } else {
            topStyle = 2;
          }
          {
            this.props.selectedLanguage === "esp" ?
              this.setState({
                isLoading: false,
                topStyle: topStyle,
                learningStyles: responseJson.results,
                learningChart: {
                  labels: [
                    responseJson.results[0].learning_style_name_spanish,
                    responseJson.results[1].learning_style_name_spanish,
                    responseJson.results[2].learning_style_name_spanish],
                  datasets: [{
                    data: [
                      responseJson.results[0].percentage,
                      responseJson.results[1].percentage,
                      responseJson.results[2].percentage
                    ],
                    backgroundColor: ['#25D192', '#1F3545', '#4D4D50'],
                    hoverBackgroundColor: ['#25D192', '#1F3545', '#4D4D50']
                  }]
                },
              })
              :
              this.setState({
                isLoading: false,
                topStyle: topStyle,
                learningStyles: responseJson.results,
                learningChart: {
                  labels: [
                    responseJson.results[0].learning_style_name,
                    responseJson.results[1].learning_style_name,
                    responseJson.results[2].learning_style_name],
                  datasets: [{
                    data: [
                      responseJson.results[0].percentage,
                      responseJson.results[1].percentage,
                      responseJson.results[2].percentage
                    ],
                    backgroundColor: ['#25D192', '#1F3545', '#4D4D50'],
                    hoverBackgroundColor: ['#25D192', '#1F3545', '#4D4D50']
                  }]
                },
              })
          }
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  onPreviousClick() {
    if (this.state.currentQuestion > 0) {
      this.setState({
        currentQuestion: this.state.currentQuestion - 1
      })
    }
  }

  onNextClick() {
    if (this.state.currentQuestion + 1 < this.state.learning.length) {
      this.setState({
        currentQuestion: this.state.currentQuestion + 1
      })
    }
  }

  onViewPreviousClick() {
    if (this.state.viewCurrentQuestion > 0) {
      this.setState({
        viewCurrentQuestion: this.state.viewCurrentQuestion - 1
      })
    }
  }

  onViewNextClick() {
    if (this.state.viewCurrentQuestion + 1 < this.state.learningAnswers.length) {
      this.setState({
        viewCurrentQuestion: this.state.viewCurrentQuestion + 1
      })
    }
  }

  onNewClick() {

    var learningTypeId = 0;

    if (this.props.gradeId < 10) {
      learningTypeId = 2;
    } else {
      learningTypeId = 1;
    }

    fetch('https://api.tasselpathway.com/learning/' + learningTypeId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            learning: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    this.setState({
      isNewOpen: true
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  handleChange = (event, value) => {
    this.setState({
      value
    });
  }

  onTipClick(tipValue) {
    this.setState({
      tipValue: tipValue,
      isTipOpen: true
    })
  }

  onTipClose() {
    this.setState({
      isTipOpen: false
    })
  }

  handleTipChange = (event, tipValue) => {
    this.setState({
      tipValue
    });
  }

  onResult(learning_id) {

    this.getLearning(learning_id);

    this.setState({
      isViewOpen: true
    })
  }

  onViewClick(item) {

    this.getLearning(item.learning_id);

    this.setState({
      selectedItem: item,
      isViewOpen: true
    })
  }

  onViewClose() {
    this.setState({
      isViewOpen: false
    })
  }

  onFinishClick() {

    var questions = [];
    var visual = 0;
    var audio = 0;
    var tactile = 0;
    var i = 0;

    this.state.learning.map((question, key) => {
      question.choices.map((choice, key2) => {

        if (this.state.choices['choice_' + choice.choice_id] === 1) {

          if (choice.learning_style_id === 1) {
            visual++;
          } else if (choice.learning_style_id === 2) {
            audio++;
          } else {
            tactile++;
          }

          questions[i] = { choice_id: choice.choice_id, question_id: question.question_id, question_name: question.question_name }
          i++;
        }
      })
    })

    var styles = [];

    styles[0] = { learning_style_id: 1, percentage: visual / 10 }
    styles[1] = { learning_style_id: 2, percentage: audio / 10 }
    styles[2] = { learning_style_id: 3, percentage: tactile / 10 }

    fetch('https://api.tasselpathway.com/profile_learning', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.profileId,
        learning_type_id: this.state.learning[0].learning_type_id,
        grade_id: this.props.gradeId,
        questions: questions,
        styles: styles
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.getData(this.props.profileId);

        this.setState({
          isNewOpen: false,
          isLoading: false
        })

        if (responseJson.error === false) {
          this.onResult(responseJson.learning_id);
        }

      })
      .catch((error) => {
        this.setState({
          isNewOpen: false,
          isLoading: false
        })
      });
  }

  onChoiceSelect(e, item, choice) {
    if (e != null)
      e.preventDefault();

    var choices = this.state.choices;

    for (var j = 0; j < item.choices.length; j++) {
      if (item.choices[j].choice_id === choice) {
        if (choices['choice_' + item.choices[j].choice_id] === 1) {
          choices['choice_' + item.choices[j].choice_id] = 0;
        } else {
          choices['choice_' + item.choices[j].choice_id] = 1;
        }
      } else {
        choices['choice_' + item.choices[j].choice_id] = 0;
      }
    }

    this.setState({
      choices: choices
    })

  }

  renderQuestions() {

    var question = this.state.learning[this.state.currentQuestion];
    if (question === undefined) {
      return null;
    } else if (this.props.selectedLanguage === "esp" && question !== undefined) {
      return (
        <div>
          <Grid container spacing={8}>
            <Grid item xs={12} style={{ marginBottom: 10, borderBottom: '1px solid #eaeaea' }}>
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.question_name_spanish}
              </Typography>

              <Grid container style={{ padding: 20 }}>

                <RadioGroup aria-label="position" name="position"
                  value={this.state.choices['choice_' + question.choices[0].choice_id]} row>

                  {
                    question.choices.map((item, key) => {
                      return (

                        <Grid key={key} item xs={12}>
                          <FormControlLabel key={key}
                            onClick={(event) => this.onChoiceSelect(event, question, item.choice_id)}
                            control={
                              <Radio checked={this.state.choices['choice_' + item.choice_id] ? true : false} />
                            }
                            label={item.choice_name_spanish}
                          />
                        </Grid>
                      )
                    })
                  }

                </RadioGroup>

              </Grid>
            </Grid>
          </Grid>
        </div>
      )
    } else if (this.props.selectedLanguage === "en" && question !== undefined) {
      return (
        <div>
          <Grid container spacing={8}>
            <Grid item xs={12} style={{ marginBottom: 10, borderBottom: '1px solid #eaeaea' }}>
              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.question_name}
              </Typography>

              <Grid container style={{ padding: 20 }}>

                <RadioGroup aria-label="position" name="position"
                  value={this.state.choices['choice_' + question.choices[0].choice_id]} row>

                  {
                    question.choices.map((item, key) => {
                      return (

                        <Grid key={key} item xs={12}>
                          <FormControlLabel key={key}
                            onClick={(event) => this.onChoiceSelect(event, question, item.choice_id)}
                            control={
                              <Radio checked={this.state.choices['choice_' + item.choice_id] ? true : false} />
                            }
                            label={item.choice_name}
                          />
                        </Grid>
                      )
                    })
                  }

                </RadioGroup>

              </Grid>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      alert("There seems to be a problem getting the requested data try again shortly")
    }
  }

  renderResults() {
    return (

      <div>

        <Grid container spacing={8}>

          <Grid item xs={12} style={{ borderBottom: '1px solid #eaeaea' }}>

            <Grid container style={{ height: 200, marginTop: 20 }}>
              <Grid item xs={7}>
                {this.state.topStyle === 0 ?
                  <FontAwesomeIcon icon={faEye}
                    style={{ color: '#25D192', position: 'absolute', marginLeft: 125, marginTop: 70, fontSize: 60 }} />
                  : this.state.topStyle === 1 ?
                    <FontAwesomeIcon icon={faHeadphones}
                      style={{ color: '#1F3545', position: 'absolute', marginLeft: 130, marginTop: 67, fontSize: 60 }} />
                    :
                    <FontAwesomeIcon icon={faHandPaper}
                      style={{ color: '#4D4D50', position: 'absolute', marginLeft: 130, marginTop: 65, fontSize: 60 }} />
                }

                <Doughnut data={this.state.learningChart} width={5}
                  height={5} options={this.state.chartOptions} />

              </Grid>
              <Grid item xs={4} style={{ paddingRight: 20, marginTop: 40 }}>
                {this.props.selectedLanguage === "esp" ?
                  <>
                    <Grid container>
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faEye} style={{ color: '#25D192', fontSize: 22, marginTop: 2 }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[0] === undefined ? "" : this.state.learningStyles[0].learning_style_name_spanish}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[0] === undefined ? "" : this.state.learningStyles[0].percentage}%</Typography>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 20 }}>
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faHeadphones} style={{ color: '#1F3545', fontSize: 22, marginTop: 2 }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[1] === undefined ? "" : this.state.learningStyles[1].learning_style_name_spanish}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[1] === undefined ? "" : this.state.learningStyles[1].percentage}%</Typography>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 20 }}>
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faHandPaper} style={{ color: '#4D4D50', fontSize: 22, marginTop: 2 }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[2] === undefined ? "" : this.state.learningStyles[2].learning_style_name_spanish}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[2] === undefined ? "" : this.state.learningStyles[2].percentage}%</Typography>
                      </Grid>
                    </Grid>
                  </>
                  :
                  <>
                    <Grid container>
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faEye} style={{ color: '#25D192', fontSize: 22, marginTop: 2 }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[0] === undefined ? "" : this.state.learningStyles[0].learning_style_name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[0] === undefined ? "" : this.state.learningStyles[0].percentage}%</Typography>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 20 }}>
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faHeadphones} style={{ color: '#1F3545', fontSize: 22, marginTop: 2 }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[1] === undefined ? "" : this.state.learningStyles[1].learning_style_name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[1] === undefined ? "" : this.state.learningStyles[1].percentage}%</Typography>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 20 }}>
                      <Grid item xs={2}>
                        <FontAwesomeIcon icon={faHandPaper} style={{ color: '#4D4D50', fontSize: 22, marginTop: 2 }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[2] === undefined ? "" : this.state.learningStyles[2].learning_style_name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography style={{ fontSize: 18, marginLeft: 15 }}>{this.state.learningStyles[2] === undefined ? "" : this.state.learningStyles[2].percentage}%</Typography>
                      </Grid>
                    </Grid>
                  </>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderAnswers() {
    var question = this.state.learningAnswers[this.state.viewCurrentQuestion];
    if (question === undefined) {
      return null;
    } else if (this.props.selectedLanguage === "esp" && question !== undefined) {
      return (
        <div>
          <Grid container spacing={8}>

            <Grid item xs={12} style={{ marginBottom: 10, borderBottom: '1px solid #eaeaea' }}>

              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.question_name_spanish}
              </Typography>

              <Grid container style={{ padding: 20 }}>

                <RadioGroup aria-label="position" name="position" row>

                  {
                    question.choices.map((item, key) => {
                      return (

                        <Grid key={key} item xs={6}>
                          <FormControlLabel key={key} disabled
                            control={
                              <Radio checked={item.is_style === 1 ? true : false} />
                            }
                            label={item.choice_name_spanish}
                          />
                        </Grid>
                      )
                    })
                  }

                </RadioGroup>

              </Grid>
            </Grid>
          </Grid>

        </div>
      )
    } else if (this.props.selectedLanguage === 'en' && question !== undefined) {
      return (
        <div>

          <Grid container spacing={8}>

            <Grid item xs={12} style={{ marginBottom: 10, borderBottom: '1px solid #eaeaea' }}>

              <Typography style={{ fontSize: 20, color: '#25D192', textAlign: 'center', marginTop: 10, fontWeight: 'bold' }}>
                {question.question_name}
              </Typography>

              <Grid container style={{ padding: 20 }}>

                <RadioGroup aria-label="position" name="position" row>

                  {
                    question.choices.map((item, key) => {
                      return (

                        <Grid key={key} item xs={6}>
                          <FormControlLabel key={key} disabled
                            control={
                              <Radio checked={item.is_style === 1 ? true : false} />
                            }
                            label={item.choice_name}
                          />
                        </Grid>
                      )
                    })
                  }

                </RadioGroup>

              </Grid>
            </Grid>
          </Grid>

        </div>
      )
    }

  }

  renderVisual() {
    return (

      <div>

        <ImageList>
          <ListItem style={{ height: 50, padding: 10 }} >
            <ListItemText>1. {i18n.t("LEARNING_STYLE_VISUAL_1")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>2. {i18n.t("LEARNING_STYLE_VISUAL_2")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>3. {i18n.t("LEARNING_STYLE_VISUAL_3")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>4. {i18n.t("LEARNING_STYLE_VISUAL_4")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>5. {i18n.t("LEARNING_STYLE_VISUAL_5")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>6. {i18n.t("LEARNING_STYLE_VISUAL_6")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>7. {i18n.t("LEARNING_STYLE_VISUAL_7")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>8. {i18n.t("LEARNING_STYLE_VISUAL_8")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>9. {i18n.t("LEARNING_STYLE_VISUAL_9")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>10. {i18n.t("LEARNING_STYLE_VISUAL_10")}</ListItemText>
          </ListItem>
        </ImageList>

      </div>
    )
  }

  renderAuditory() {
    return (

      <div>

        <ImageList>
          <ListItem style={{ height: 50, padding: 10 }} >
            <ListItemText>1. {i18n.t("LEARNING_STYLE_AUDITORY_1")} </ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>2. {i18n.t("LEARNING_STYLE_AUDITORY_2")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>3. {i18n.t("LEARNING_STYLE_AUDITORY_3")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>4. {i18n.t("LEARNING_STYLE_AUDITORY_4")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>5. {i18n.t("LEARNING_STYLE_AUDITORY_5")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>6. {i18n.t("LEARNING_STYLE_AUDITORY_6")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>7. {i18n.t("LEARNING_STYLE_AUDITORY_7")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>8. {i18n.t("LEARNING_STYLE_AUDITORY_8")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>9. {i18n.t("LEARNING_STYLE_AUDITORY_9")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>10.{i18n.t("LEARNING_STYLE_AUDITORY_10")}</ListItemText>
          </ListItem>
        </ImageList>

      </div>
    )
  }

  renderTactile() {
    return (

      <div>

        <ImageList>
          <ListItem style={{ height: 50, padding: 10 }} >
            <ListItemText>1. {i18n.t("LEARNING_STYLE_TACTILE_1")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>2. {i18n.t("LEARNING_STYLE_TACTILE_2")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>3. {i18n.t("LEARNING_STYLE_TACTILE_3")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>4.{i18n.t("LEARNING_STYLE_TACTILE_4")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>5. {i18n.t("LEARNING_STYLE_TACTILE_5")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>6. {i18n.t("LEARNING_STYLE_TACTILE_6")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>7. {i18n.t("LEARNING_STYLE_TACTILE_7")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>8. {i18n.t("LEARNING_STYLE_TACTILE_8")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>9. {i18n.t("LEARNING_STYLE_TACTILE_9")}</ListItemText>
          </ListItem>
          <ListItem style={{ height: 50, padding: 10 }}>
            <ListItemText>10. {i18n.t("LEARNING_STYLE_TACTILE_10")}</ListItemText>
          </ListItem>
        </ImageList>

      </div>
    )
  }

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    return (
      <div className={classes.root}>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            {i18n.t("TEACHER_PROFILE_LEARNING_STYLE_TILE")}
          </DialogTitle>

          <DialogContent>
            {this.renderQuestions()}
          </DialogContent>

          <DialogActions>

            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>

              <Grid item xs={2}>

                {this.state.currentQuestion === 0 ? null :
                  <Button onClick={() => this.onPreviousClick()}
                    style={{ backgroundColor: '#25D192', marginLeft: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("LEARNING_STYLE_BACK")}</Typography>
                  </Button>
                }

              </Grid>

              <Grid item xs={8} style={{ textAlign: 'center' }}>
                <Typography style={{ fontSize: 16, marginTop: 10, fontWeight: 'bold' }}>{this.state.currentQuestion + 1} / {this.state.learning.length}</Typography>
              </Grid>

              <Grid item xs={2} style={{ textAlign: 'right' }}>

                {this.state.currentQuestion + 1 === this.state.learning.length ?
                  <Button onClick={() => this.onFinishClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("LEARNING_STYLE_FINISH")}</Typography>
                  </Button>
                  :
                  <Button onClick={() => this.onNextClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("LEARNING_STYLE_NEXT")}</Typography>
                  </Button>
                }

              </Grid>

            </Grid>

          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isTipOpen}
          onClose={this.onTipClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onTipClose}>
            {this.state.tipValue === 0 ? 'Visual Learning Style Tips' : null}
            {this.state.tipValue === 1 ? 'Auditory Learning Style Tips' : null}
            {this.state.tipValue === 2 ? 'Tactile Learning Style Tips' : null}
          </DialogTitle>

          <DialogContent>
            {this.state.tipValue === 0 && this.renderVisual()}
            {this.state.tipValue === 1 && this.renderAuditory()}
            {this.state.tipValue === 2 && this.renderTactile()}
          </DialogContent>

          <DialogActions>

          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isViewOpen}
          onClose={this.onViewClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onViewClose}>
            {i18n.t("LEARNING_STYLE_RESULTS")}
          </DialogTitle>

          <DialogContent>
            {this.props.selectedLanguage === "esp" ?
              <Tabs value={this.state.value} onChange={this.handleChange}>
                <Tab label="SUMARIO" />
                <Tab label="RESPUESTAS" />
              </Tabs>
              :
              <Tabs value={this.state.value} onChange={this.handleChange}>
                <Tab label="SUMMARY" />
                <Tab label="ANSWERS" />
              </Tabs>
            }
            {this.state.value === 0 && this.renderResults()}
            {this.state.value === 1 && this.renderAnswers()}

          </DialogContent>

          <DialogActions>

            {this.state.value === 1 ?

              <Grid container style={{ marginBottom: 20, marginTop: 20 }}>

                <Grid item xs={2}>

                  {this.state.viewCurrentQuestion === 0 ? null :
                    <Button onClick={() => this.onViewPreviousClick()}
                      style={{ backgroundColor: '#25D192', marginLeft: 10 }}>
                      <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("LEARNING_STYLE_BACK")}</Typography>
                    </Button>
                  }

                </Grid>

                <Grid item xs={8} style={{ textAlign: 'center' }}>
                  <Typography style={{ fontSize: 16, marginTop: 10, fontWeight: 'bold' }}>{this.state.viewCurrentQuestion + 1} / {this.state.learningAnswers.length}</Typography>
                </Grid>

                <Grid item xs={2} style={{ textAlign: 'right' }}>

                  {this.state.viewCurrentQuestion + 1 === this.state.learningAnswers.length ?
                    null
                    :
                    <Button onClick={() => this.onViewNextClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                      <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("LEARNING_STYLE_NEXT")}</Typography>
                    </Button>
                  }

                </Grid>

              </Grid>
              : null}

          </DialogActions>

        </Dialog>

        <Grid container spacing={2}>

          <Grid item xs={12}>

            <Card style={{ width: '100%', height: 400, borderRadius: 3 }}>
              <CardContent>

                <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                  <Grid item xs={10}>
                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                      {i18n.t("TEACHER_PROFILE_LEARNING_STYLE_TILE")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.props.profileId === this.props.personId ?
                      <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewClick()}>
                        <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                      </IconButton>
                      : null
                    }
                  </Grid>
                </Grid>

                {this.state.dataSource.length === 0 ?

                  <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ marginTop: 75 }}>
                    {this.state.recentStyles.length === 0 && this.props.profileId === this.props.personId ?
                      <Button color="primary" variant="contained" onClick={() => this.onNewClick()}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 16, color: '#FFF' }}>{i18n.t("LEARNING_STYLE_START")}</Typography>
                      </Button>
                      : null}
                  </Grid>

                  :

                  <>
                    <Grid container style={{ height: 200, marginTop: 20 }}>
                      <Grid item xs={6}>

                        <Doughnut data={this.state.recentChart} height={80}
                          options={this.state.chartOptions} />

                      </Grid>
                      <Grid item xs={6} style={{ marginTop: 20 }}>

                        <Grid container>
                          {this.props.selectedLanguage === "esp" ?
                            <List>
                              <ListItem button onClick={() => this.onTipClick(0)}>
                                <ListItemIcon>
                                  <FontAwesomeIcon icon={faEye} style={{ color: '#25D192', fontSize: 22, marginTop: 2 }} />
                                </ListItemIcon>
                                <ListItemText style={{ marginLeft: -25 }}>
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[0] === undefined ? "" : "Visual"}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[0] === undefined ? "" : this.state.recentStyles[0].percentage}%</Typography>
                                    </Grid>
                                  </Grid>
                                </ListItemText>
                              </ListItem>
                              <ListItem button onClick={() => this.onTipClick(1)}>
                                <ListItemIcon>
                                  <FontAwesomeIcon icon={faHeadphones} style={{ color: '#1F3545', fontSize: 22, marginTop: 2 }} />
                                </ListItemIcon>
                                <ListItemText style={{ marginLeft: -25 }}>
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[1] === undefined ? "" : "Auditivo"}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[1] === undefined ? "" : this.state.recentStyles[1].percentage}%</Typography>
                                    </Grid>
                                  </Grid>
                                </ListItemText>
                              </ListItem>
                              <ListItem button onClick={() => this.onTipClick(2)}>
                                <ListItemAvatar style={{ margin: 0, padding: 0 }}>
                                  <FontAwesomeIcon icon={faHandPaper} style={{ color: '#4D4D50', fontSize: 22, marginTop: 2 }} />
                                </ListItemAvatar>
                                <ListItemText style={{ marginLeft: -25 }}>
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[2] === undefined ? "" : "Táctil"}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[2] === undefined ? "" : this.state.recentStyles[2].percentage}%</Typography>
                                    </Grid>
                                  </Grid>
                                </ListItemText>
                              </ListItem>
                            </List>
                            :
                            <List>
                              <ListItem button onClick={() => this.onTipClick(0)}>
                                <ListItemIcon>
                                  <FontAwesomeIcon icon={faEye} style={{ color: '#25D192', fontSize: 22, marginTop: 2 }} />
                                </ListItemIcon>
                                <ListItemText style={{ marginLeft: -25 }}>
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[0] === undefined ? "" : this.state.recentStyles[0].learning_style_name}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[0] === undefined ? "" : this.state.recentStyles[0].percentage}%</Typography>
                                    </Grid>
                                  </Grid>
                                </ListItemText>
                              </ListItem>
                              <ListItem button onClick={() => this.onTipClick(1)}>
                                <ListItemIcon>
                                  <FontAwesomeIcon icon={faHeadphones} style={{ color: '#1F3545', fontSize: 22, marginTop: 2 }} />
                                </ListItemIcon>
                                <ListItemText style={{ marginLeft: -25 }}>
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[1] === undefined ? "" : this.state.recentStyles[1].learning_style_name}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[1] === undefined ? "" : this.state.recentStyles[1].percentage}%</Typography>
                                    </Grid>
                                  </Grid>
                                </ListItemText>
                              </ListItem>
                              <ListItem button onClick={() => this.onTipClick(2)}>
                                <ListItemAvatar style={{ margin: 0, padding: 0 }}>
                                  <FontAwesomeIcon icon={faHandPaper} style={{ color: '#4D4D50', fontSize: 22, marginTop: 2 }} />
                                </ListItemAvatar>
                                <ListItemText style={{ marginLeft: -25 }}>
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[2] === undefined ? "" : this.state.recentStyles[2].learning_style_name}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography style={{ fontSize: 15 }}>{this.state.recentStyles[2] === undefined ? "" : this.state.recentStyles[2].percentage}%</Typography>
                                    </Grid>
                                  </Grid>
                                </ListItemText>
                              </ListItem>
                            </List>


                          }
                        </Grid>
                      </Grid>
                    </Grid>

                    <List style={{ maxHeight: 100, marginTop: 10, overflow: 'auto' }}>

                      {this.state.dataSource.map((item, key) => {
                        return (
                          <ListItem key={key} alignItems="flex-start" button onClick={() => this.onViewClick(item)}>
                            {this.props.selectedLanguage === "esp" ?
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography style={{ fontSize: 14, marginTop: 5, fontWeight: 'bold' }} component="span"
                                      className={this.props.classes.inline} color="textPrimary">
                                      {item.learning_type_name_spanish}
                                    </Typography>
                                  </React.Fragment>
                                } secondary={
                                  <React.Fragment>
                                    <Typography style={{ fontSize: 12, marginTop: 5 }} component="span"
                                      className={this.props.classes.inline} color="textPrimary">
                                      {item.grade_name} Año - {moment(item.date_created).format('MM/DD/YYYY')}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                              :
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography style={{ fontSize: 14, marginTop: 5, fontWeight: 'bold' }} component="span"
                                      className={this.props.classes.inline} color="textPrimary">
                                      {item.learning_type_name}
                                    </Typography>
                                  </React.Fragment>
                                } secondary={
                                  <React.Fragment>
                                    <Typography style={{ fontSize: 12, marginTop: 5 }} component="span"
                                      className={this.props.classes.inline} color="textPrimary">
                                      {item.grade_name} Year - {moment(item.date_created).format('MM/DD/YYYY')}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            }
                          </ListItem>
                        )
                      })}
                    </List>
                  </>
                }
              </CardContent>
            </Card>
          </Grid>

        </Grid>

      </div>
    );
  }
}

LearningScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    profileId: state.profile.profileId,
    gradeId: state.profile.gradeId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LearningScreen)));