export default {
    buttonContainer:{
        margin:'auto', 
        width:'60%', 
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-around',
        marginTop:25 
    },
    downloadButtonContainer: {
        margin:'auto', 
        alignItems:'center',
        display:'flex', 
        flexDirection:'column', 
        marginTop:25 
    },
    buttonStyle:{
        backgroundColor:'#203446', 
        width:'20%'
    },
    downloadButtonStyle:{
        backgroundColor:'#395976', 
        width:'20%'
    },
    buttonTextColor:{
        color:'#fff'
    },
    downloadButton:{
        backgroundColor:'#203446', 
        width:'20%', 
        marginBottom:35
    }
}