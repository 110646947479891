import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class ServiceScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      newNameValid: true,
      editNameValid: true,
      serviceTypes: [],
      leadershipRoles: [],
      leadership_role_id: 0,
      service_type_id: 0,
      selectedItem: {}
    }

    this.createService = this.createService.bind(this);
    this.saveService = this.saveService.bind(this);
    this.onNewOpen = this.onNewOpen.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onNewClick = this.onNewClick.bind(this);
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {

    this.getData(this.props.profileId);

    fetch('https://api.tasselpathway.com/profile_service_type', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            serviceTypes: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/profile_leadership_role', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            leadershipRoles: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  getData(personId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_service/person/' + personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  createService() {
    fetch('https://api.tasselpathway.com/profile_service/', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId,
        sponsor_coach: this.state.sponsor_coach,
        service_location: this.state.service_location,
        credit_hours: this.state.credit_hours,
        leadership_role_id: this.state.leadership_role_id,
        service_description: this.state.service_description,
        service_type_id: this.state.service_type_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.getData(this.props.personId);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  saveService() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_service', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        sponsor_coach: this.state.selectedItem.sponsor_coach,
        service_location: this.state.selectedItem.service_location,
        credit_hours: this.state.selectedItem.credit_hours,
        leadership_role_id: this.state.selectedItem.leadership_role_id,
        service_description: this.state.selectedItem.service_description,
        service_type_id: this.state.selectedItem.service_type_id,
        service_id: this.state.selectedItem.service_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          this.getData(this.props.personId);

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  deleteService() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_service', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        service_id: this.state.selectedItem.service_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.personId);

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderServices() {

    var content = this.state.dataSource.map((item, key) => {
      return (
        <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
          onClick={() => this.onEditOpen(item)}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                  className={this.props.classes.inline} color="textPrimary">
                  {item.service_location} - {item.credit_hours} hours
                </Typography>
                <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.leadership_role_name} - {item.service_type_name}</Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    })

    return content;
  }

  onNewOpen() {
    this.setState({
      service_id: 0,
      sponsor_coach: '',
      location: '',
      credit_hours: 0,
      leadership_role_id: 0,
      service_description: '',
      service_type_id: 0,
      isNewOpen: true
    })
  }

  onNewClose() {

    this.setState({
      isNewOpen: false
    })
  }

  onNewClick() {

    this.createService();

    this.setState({
      isNewOpen: false
    })

  }

  onEditClose() {

    this.setState({
      isEditOpen: false
    })
  }

  onEditOpen(item) {
    if (this.props.personId === this.props.profileId) {

      this.setState({
        selectedItem: item,
        isEditOpen: true
      })
    }
  }

  onEditClick() {

    var nameValid = true;

    if (this.state.selectedItem.service_name === "") {
      nameValid = false;
    }

    this.setState({
      editServiceNameValid: nameValid
    })

    if (nameValid) {
      this.saveService();

      this.setState({
        isEditOpen: false
      })
    }
  }

  onDeleteClick() {
    this.deleteService();

    this.setState({
      isEditOpen: false
    })
  }

  onRemoveClick() {

    this.setState({
      isNewOpen: false
    })
  }

  onLocationChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.location = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onSponsorChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.sponsor_coach = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onCreditChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.credit_hours = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDescriptionChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.service_description = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onRoleChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.leadership_role_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onTypeChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.service_type_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  renderRoles() {

    var content = this.state.leadershipRoles.map((item, key) => {
      return (
        <MenuItem key={key} value={item.leadership_role_id}>{item.leadership_role_name}</MenuItem>
      )
    })

    return content;
  }

  renderServiceType() {

    var content = this.state.serviceTypes.map((item, key) => {
      return (
        <MenuItem key={key} value={item.service_type_id}>{item.service_type_name}</MenuItem>
      )
    })

    return content;
  }


  render() {

    const { classes } = this.props;

    return (
      <div>

        <div className={classes.root}>
          <Dialog open={this.state.isNewOpen}
            onClose={this.onNewClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
              {i18n.t("SERVICE_LEARNING_NEW")}
            </DialogTitle>

            <DialogContent>

              <div>

                <Grid container>

                  <Grid item xs={12}>

                    <FormControl className={classes.formControl}
                      fullWidth>
                      <InputLabel htmlFor="goal-type">{i18n.t("SERVICE_LEARNING_SERVICE_TYPE")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.service_type_id}
                        onChange={(event) => this.setState({ service_type_id: event.target.value })}
                        inputProps={{
                          name: 'goalType',
                          id: 'goal-type',
                        }}
                      >
                        <MenuItem disabled value={0}>{i18n.t("SERVICE_LEARNING_SERVICE_TYPE")}</MenuItem>
                        {this.renderServiceType()}
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid container>

                    <Grid item xs={8}>

                      <FormControl className={classes.formControl} style={{ marginTop: 15 }}
                        fullWidth>
                        <InputLabel htmlFor="leadership_role_id">{i18n.t("SERVICE_LEARNING_LEADERSHIP")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.leadership_role_id}
                          onChange={(event) => this.setState({ leadership_role_id: event.target.value })}
                          inputProps={{
                            name: 'leadership_role_id',
                            id: 'leadership_role_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("SERVICE_LEARNING_LEADERSHIP")}</MenuItem>
                          {this.renderRoles()}
                        </Select>
                      </FormControl>

                    </Grid>

                    <Grid item xs={4}>

                      <FormControl fullWidth style={{ marginTop: 15 }}>
                        <InputLabel htmlFor="credit">{i18n.t("SERVICE_LEARNING_CREDIT_HOURS")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.credit_hours}
                          onChange={(event) => this.setState({ credit_hours: event.target.value })}
                          id="credit" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>


                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="service_location">{i18n.t("SERVICE_LEARNING_PLACE")}</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.service_location}
                        onChange={(event) => this.setState({ service_location: event.target.value })}
                        id="service_location" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="sponsor">{i18n.t("SERVICE_LEARNING_SPONSOR")}</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.sponsor_coach}
                        onChange={(event) => this.setState({ sponsor_coach: event.target.value })}
                        id="sponsor" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="description">{i18n.t("SERVICE_LEARNING_REFLECTION")}</InputLabel>
                      <Input variant="outline" fullWidth multiline rows={4}
                        value={this.state.service_description}
                        onChange={(event) => this.setState({ service_description: event.target.value })}
                        id="description" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                </Grid>

              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained"
                style={{ color: '#FFF', fontWeight: 'bold', marginBottom: 20, marginRight: 20 }}
                onClick={this.onNewClick} color="primary">
                {i18n.t("SERVICE_LEARNING_ADD_BUTTON")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isEditOpen} className={classes.popup}
            onClose={this.onEditClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title"
              onClose={this.onEditClose}>
              {i18n.t("SERVICE_LEARNING_EDIT")}
            </DialogTitle>

            <DialogContent>

              <div>

                <Grid container>

                  <Grid item xs={12}>

                    <FormControl className={classes.formControl}
                      fullWidth>
                      <InputLabel htmlFor="goal-type">{i18n.t("SERVICE_LEARNING_SERVICE_TYPE")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.selectedItem.service_type_id}
                        onChange={(event) => this.onTypeChange(event)}
                        inputProps={{
                          name: 'goalType',
                          id: 'goal-type',
                        }}
                      >
                        <MenuItem disabled value={0}>{i18n.t("SERVICE_LEARNING_SERVICE_TYPE")}</MenuItem>
                        {this.renderServiceType()}
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid container>

                    <Grid item xs={8}>

                      <FormControl className={classes.formControl} style={{ marginTop: 15 }}
                        fullWidth>
                        <InputLabel htmlFor="leadership_role_id">{i18n.t("SERVICE_LEARNING_LEADERSHIP")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.selectedItem.leadership_role_id}
                          onChange={(event) => this.onRoleChange(event)}
                          inputProps={{
                            name: 'leadership_role_id',
                            id: 'leadership_role_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("SERVICE_LEARNING_LEADERSHIP")}</MenuItem>
                          {this.renderRoles()}
                        </Select>
                      </FormControl>

                    </Grid>

                    <Grid item xs={4}>

                      <FormControl fullWidth style={{ marginTop: 15 }}>
                        <InputLabel htmlFor="credit">{i18n.t("SERVICE_LEARNING_CREDIT_HOURS")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.credit_hours}
                          onChange={(event) => this.onCreditChange(event)}
                          id="credit" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>


                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="service_location">{i18n.t("SERVICE_LEARNING_PLACE")}</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.selectedItem.service_location}
                        onChange={(event) => this.onLocationChange(event)}
                        id="service_location" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="sponsor">{i18n.t("SERVICE_LEARNING_SPONSOR")}</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.selectedItem.sponsor_coach}
                        onChange={(event) => this.onSponsorChange(event)}
                        id="sponsor" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="description">{i18n.t("SERVICE_LEARNING_REFLECTION")}</InputLabel>
                      <Input variant="outline" fullWidth multiline rows={4}
                        value={this.state.selectedItem.service_description}
                        onChange={(event) => this.onDescriptionChange(event)}
                        id="description" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                </Grid>

              </div>

            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.onDeleteClick}
                style={{ color: '#FFF', fontWeight: 'bold' }} color="secondary">
                {i18n.t("SERVICE_LEARNING_DELETE")}
              </Button>
              <Button variant="contained" onClick={this.onEditClick}
                style={{ marginRight: 20, color: '#FFF', fontWeight: 'bold' }} color="primary">
                {i18n.t("SERVICE_LEARNING_SAVE")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={10}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("PROFILE_SERVICE_LEARNING_TILE")}</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.renderServices()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

ServiceScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceScreen)));