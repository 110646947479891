import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import styles from './style';

class ReportScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            favorites: [],
            reports: []
        }
    }

    componentDidMount() {
    }

    onReportClick(path) {

        this.props.history.push({
            pathname: path
        })
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>

                <Grid container style={styles.itemContainerStyle}>

                    <Typography style={{ fontSize: 24, fontWeight: 'bold', marginLeft: 100, marginTop: 30, marginBottom: 30 }}>Pathway Reports</Typography>

                    <Grid container spacing={2} style={{ marginLeft: 100, marginRight: 100 }}>

                        <Grid container>

                            <Grid item xs={4}>

                                <Card raised={this.state['card_1']}
                                    onClick={() => this.onReportClick('/report1')}
                                    onMouseOut={() => this.setState({ card_1: false })}
                                    onMouseOver={() => this.setState({ card_1: true })}
                                    style={{ margin: 10, cursor: 'pointer', minHeight: 125 }}>

                                    <CardContent>
                                        <FontAwesomeIcon style={{ fontSize: 44, marginBottom: '20', marginTop: 10, marginRight: 20, float: 'left', color: '#25D192' }} icon={faReceipt} />

                                        <Typography style={{ textAlign: 'left', fontWeight: 'bold', color: '#4D4D4F', fontSize: 16 }}>Interest Inventory Report</Typography>
                                        <Typography style={{ textAlign: 'left', fontSize: '10' }}>Tracks Students Completion Of Interest Inventory Test</Typography>

                                    </CardContent>

                                </Card>

                            </Grid>

                            <Grid item xs={4}>

                                <Card raised={this.state['card_2']}
                                    onClick={() => this.onReportClick('/report2')}
                                    onMouseOut={() => this.setState({ card_2: false })}
                                    onMouseOver={() => this.setState({ card_2: true })}
                                    style={{ margin: 10, cursor: 'pointer', minHeight: 125 }}>

                                    <CardContent>
                                        <FontAwesomeIcon style={{ fontSize: 44, marginBottom: '20', marginTop: 10, marginRight: 20, float: 'left', color: '#25D192' }} icon={faReceipt} />

                                        <Typography style={{ textAlign: 'left', fontWeight: 'bold', color: '#4D4D4F', fontSize: 16 }}>Favorite Careers</Typography>
                                        <Typography style={{ textAlign: 'left', fontSize: '10' }}>Students Favorite Careers Based On Their Profile</Typography>

                                    </CardContent>

                                </Card>

                            </Grid>

                            <Grid item xs={4}>

                                <Card raised={this.state['card_3']}
                                    onClick={() => this.onReportClick('/report3')}
                                    onMouseOut={() => this.setState({ card_3: false })}
                                    onMouseOver={() => this.setState({ card_3: true })}
                                    style={{ margin: 10, cursor: 'pointer', minHeight: 125 }}>

                                    <CardContent>
                                        <FontAwesomeIcon style={{ fontSize: 44, marginBottom: '20', marginTop: 10, marginRight: 20, float: 'left', color: '#25D192' }} icon={faReceipt} />

                                        <Typography style={{ textAlign: 'left', fontWeight: 'bold', color: '#4D4D4F', fontSize: 16 }}>Favorite Colleges</Typography>
                                        <Typography style={{ textAlign: 'left', fontSize: '10' }}>Students Favorite Colleges Based On Their Profile</Typography>

                                    </CardContent>

                                </Card>

                            </Grid>

                            {/*

                                <Grid item xs={4}>

                                    <Card raised={this.state['card_4']} 
                                        onClick={() => this.onReportClick('/report4')}
                                        onMouseOut={() => this.setState({card_4 : false})} 
                                        onMouseOver={() => this.setState({card_4 : true})} 
                                        style={{margin:10, cursor:'pointer'}}>

                                        <CardContent>
                                            <FontAwesomeIcon style={{fontSize:44, marginBottom:'20', marginTop:10, marginRight:20, float:'left', color:'#25D192'}} icon={faReceipt} />
  
                                            <Typography style={{textAlign:'left', fontWeight:'bold', color:'#4D4D4F', fontSize:16}}>Work Ethics Report</Typography>
                                            <Typography style={{textAlign:'left', fontSize:'10'}}>This is a report of</Typography>

                                        </CardContent>

                                    </Card>

                                </Grid>

                                */}
                            {/*
                                <Grid item xs={4}>

                                    <Card raised={this.state['card_5']}
                                        onClick={() => this.onReportClick('/report5')}
                                        onMouseOut={() => this.setState({ card_5: false })}
                                        onMouseOver={() => this.setState({ card_5: true })}
                                        style={{ margin: 10, cursor: 'pointer', minHeight: 125 }}>

                                        <CardContent>
                                            <FontAwesomeIcon style={{ fontSize: 44, marginBottom: '20', marginTop: 10, marginRight: 20, float: 'left', color: '#25D192' }} icon={faReceipt} />

                                            <Typography style={{ textAlign: 'left', fontWeight: 'bold', color: '#4D4D4F', fontSize: 16 }}>Pathway Report</Typography>
                                            <Typography style={{ textAlign: 'left', fontSize: '10' }}>Shows A Breakdown Of Students Pathways</Typography>

                                        </CardContent>

                                    </Card>

                                </Grid>

                                */}

                            <Grid item xs={4}>

                                <Card raised={this.state['card_6']}
                                    onClick={() => this.onReportClick('/report6')}
                                    onMouseOut={() => this.setState({ card_6: false })}
                                    onMouseOver={() => this.setState({ card_6: true })}
                                    style={{ margin: 10, cursor: 'pointer', minHeight: 125 }}>

                                    <CardContent>
                                        <FontAwesomeIcon style={{ fontSize: 44, marginBottom: '20', marginTop: 10, marginRight: 20, float: 'left', color: '#25D192' }} icon={faReceipt} />

                                        <Typography style={{ textAlign: 'left', fontWeight: 'bold', color: '#4D4D4F', fontSize: 16 }}>Learning Style Report</Typography>
                                        <Typography style={{ textAlign: 'left', fontSize: '10' }}>Students Learning Styles Based On Their Profile</Typography>

                                    </CardContent>

                                </Card>

                            </Grid>

                            <Grid item xs={4}>

                                <Card raised={this.state['card_7']}
                                    onClick={() => this.onReportClick('/report7')}
                                    onMouseOut={() => this.setState({ card_7: false })}
                                    onMouseOver={() => this.setState({ card_7: true })}
                                    style={{ margin: 10, cursor: 'pointer', minHeight: 125 }}>

                                    <CardContent>
                                        <FontAwesomeIcon style={{ fontSize: 44, marginBottom: '20', marginTop: 10, marginRight: 20, float: 'left', color: '#25D192' }} icon={faReceipt} />

                                        <Typography style={{ textAlign: 'left', fontWeight: 'bold', color: '#4D4D4F', fontSize: 16 }}>ILP Progress Report</Typography>
                                        <Typography style={{ textAlign: 'left', fontSize: '10' }}>Student Progress Towards Completing Their ILP</Typography>

                                    </CardContent>

                                </Card>

                            </Grid>

                            <Grid item xs={4}>

                                <Card raised={this.state['card_8']}
                                    onClick={() => this.onReportClick('/report8')}
                                    onMouseOut={() => this.setState({ card_8: false })}
                                    onMouseOver={() => this.setState({ card_8: true })}
                                    style={{ margin: 10, cursor: 'pointer', minHeight: 125 }}>

                                    <CardContent>
                                        <FontAwesomeIcon style={{ fontSize: 44, marginBottom: '20', marginTop: 10, marginRight: 20, float: 'left', color: '#25D192' }} icon={faReceipt} />

                                        <Typography style={{ textAlign: 'left', fontWeight: 'bold', color: '#4D4D4F', fontSize: 16 }}>ILP Progress Report By Classroom</Typography>
                                        <Typography style={{ textAlign: 'left', fontSize: '10' }}>Student Progress Towards Completing Their ILP Broken Down By Classroom</Typography>

                                    </CardContent>

                                </Card>

                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>

            </div>
        );
    }
}

ReportScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        token: state.auth.token,
        userRoleId: state.auth.userRoleId
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReportScreen));