import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';

class OverviewScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: []
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/majors/' + this.props.majorId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            majorId: responseJson.results[0].major_id,
            majorName: responseJson.results[0].major_name,
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#1F3545', marginBottom: 10 }}>{i18n.t('COLLEGE_PROFILE_OVERVIEW')}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

OverviewScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OverviewScreen)));