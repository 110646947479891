import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Button,
  Card, CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton, List,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class SkillScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      dataSource: [],
      newSkill: '',
      suggestions: [],
      inputSkill: ""
    }

    this.onNewOpen = this.onNewOpen.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onDeleteSkill = this.onDeleteSkill.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {
    this.getData(this.props.profileId);

    fetch('https://api.tasselpathway.com/profile_suggest/2', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            allSuggestions: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }


  getData(profileId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_skill/person/' + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
          this.props.onSkillLoaded(responseJson.results)
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  onDeleteSkill(skillId) {

    fetch('https://api.tasselpathway.com/profile_skill', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        skill_id: skillId
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.profileId)
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onSearch(event) {
    fetch('https://api.tasselpathway.com/profile_suggest/3?search=' + event.target.value, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            suggestions: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    this.setState({
      inputSkill: event.target.value
    })
  }


  onNewOpen() {
    this.setState({
      isNewOpen: true
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  renderSkills() {

    if (this.props.personId === this.props.profileId) {

      var content = this.state.dataSource.map((item, key) => {

        return (
          <Chip key={key} label={item.skill_name} onClick={this.onEditSkill}
            style={{ margin: 5 }} onDelete={this.onDeleteSkill.bind(this, item.skill_id)} />
        )
      })
    } else {
      content = this.state.dataSource.map((item, key) => {

        return (
          <Chip key={key} label={item.skill_name}
            style={{ margin: 5 }} />
        )
      })
    }

    return content;
  }


  onAddSkill() {

    if (this.state.newSkill !== "") {
      fetch('https://api.tasselpathway.com/profile_skill', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit',

        body: JSON.stringify({
          person_id: this.props.personId,
          skill_name: this.state.newSkill
        })
      })
        .then((response) => response.json())
        .then(responseJson => {

          this.setState({
            isNewOpen: false,
            newSkill: ""
          })

          this.getData(this.props.profileId);

        }).catch((error) => {
          this.setState({
            isLoading: false
          })
        });
    } else {
      fetch('https://api.tasselpathway.com/profile_skill', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit',

        body: JSON.stringify({
          person_id: this.props.personId,
          skill_name: this.state.inputSkill
        })
      })
        .then((response) => response.json())
        .then(responseJson => {

          this.setState({
            isNewOpen: false,
            newSkill: ""
          })

          this.getData(this.props.profileId);

        }).catch((error) => {
          this.setState({
            isLoading: false
          })
        });
    }
  }
  onChange(value) {
    this.setState({
      newSkill: value
    });
  };

  renderTranslatedSuggest() {
    const { classes } = this.props;
    if (this.props.selectedLanguage === "esp") {
      return (
        <Autocomplete
          options={this.state.suggestions}
          classes={{
            option: classes.option
          }}
          freeSolo
          style={{ width: '100%' }}
          value={this.state.newSkill}
          disableClearable
          onChange={(e) => this.onChange(e.target.textContent)}
          getOptionLabel={option => this.state.newSkill}
          renderOption={option => (
            <React.Fragment>
              <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
            </React.Fragment>
          )}
          renderInput={params => (
            <TextField variant='standard'
              {...params} onChange={(event) => this.onSearch(event)}
              placeholder="Nombre de la destreza"
              style={{
                paddingTop: 5, width: '100%', paddingRight: 5,
                backgroundColor: '#FFF', fontSize: 15
              }}
              margin="normal"

              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      )
    } else {
      return (
        <Autocomplete
          options={this.state.suggestions}
          classes={{
            option: classes.option
          }}
          freeSolo
          style={{ width: '100%' }}
          value={this.state.newSkill}
          disableClearable
          onChange={(e) => this.onChange(e.target.textContent)}
          getOptionLabel={option => this.state.newSkill}
          renderOption={option => (
            <React.Fragment>
              <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
            </React.Fragment>
          )}
          renderInput={params => (
            <TextField variant='standard'
              {...params} onChange={(event) => this.onSearch(event)}
              placeholder="Skill Name"
              style={{
                paddingTop: 5, width: '100%', paddingRight: 5,
                backgroundColor: '#FFF', fontSize: 15
              }}
              margin="normal"

              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      )
    }
  }


  render() {
    const { classes } = this.props;

    return (
      <div>

        <div className={classes.root}>

          <Dialog open={this.state.isNewOpen}
            onClose={this.onNewClose} maxWidth="sm"
            fullWidth={true}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
              {i18n.t("SKILLS_TILE_NEW")}
            </DialogTitle>

            <DialogContent>

              {this.renderTranslatedSuggest()}
            </DialogContent>

            <DialogActions>
              <Button variant="contained"
                onClick={() => this.onAddSkill()}
                style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }} color="primary">
                {i18n.t("SKILLS_ADD_BUTTON")}
              </Button>
            </DialogActions>

          </Dialog>
        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{
                    fontSize: 18, fontWeight: 'bold', marginTop: 10,
                    color: '#4D4D4F', marginBottom: 20
                  }}>{i18n.t("TEACHER_PROFILE_SKILLS_TILE")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 300, overflow: 'auto' }}>
                    {this.renderSkills()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

SkillScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SkillScreen)));