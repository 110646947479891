import { Grid, List, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';
class CareerVideoScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: []
    }
  }

  componentDidMount() {
    this.getData(this.props.careerId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.careerId);
  }

  getData(careerId) {

    this.setState({
      isLoading: true,
      videoCode: ''
    })

    fetch('https://api.tasselpathway.com/careers/media/' + careerId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>
          <Grid item xs={12}>
            {this.state.dataSource.length > 0 ?
              <>
                <Typography style={{ fontSize: 18, fontWeight: 'bold', color: '#4D4D4F' }}>{i18n.t("CAREER_VIDEO_TITLE")}</Typography>
                <List>
                  {this.state.dataSource.map((item, key) => {

                    var url = "https://cdn.careeronestop.org/OccVids/OccupationVideos/" + item.onet_code + ".mp4";


                    if (item.onet_code !== "" && item.onet_code != null) {

                      return (
                        <ReactPlayer width="100%" height="240px"
                          key={key} url={url} controls />
                      )

                    }
                  })}
                </List>
              </>
              : null}
          </Grid>
        </Grid>
      </div>

    );
  }
}

CareerVideoScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    careerId: state.career.careerId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerVideoScreen)));