import {
  Card, CardContent,
  Chip,
  Grid,
  List,
  Popover,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';

class CareerSkillScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      skillDescription: '',
      isSkillOpen: false,
    }

    this.onSkillOpen = this.onSkillOpen.bind(this);
    this.onSkillClose = this.onSkillClose.bind(this);
  }

  componentDidMount() {
    this.getData(this.props.careerId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.careerId);
  }

  getData(careerId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/careers/skills/' + careerId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onSkillOpen(event, item) {
    event.preventDefault();

    if (item.skillDescription !== "") {

      this.setState({
        skillAnchor: event.currentTarget,
        isSkillOpen: true,
        skillDescription: item.skill_description
      })
    }
  }

  onSkillClose() {
    this.setState({
      isSkillOpen: false,
      skillAnchor: null,
      skillDescription: ''
    })
  }


  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%' }}>
            <CardContent>

              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#1F3545', marginBottom: 10 }}>{i18n.t("CAREER_SKILLS")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 300, overflow: 'auto' }}>
                    {this.state.dataSource.map((item, key) => {
                      return (
                        <Chip key={key} onClick={(event) => this.onSkillOpen(event, item)}
                          label={item.skill_name} style={{ margin: 5 }} />
                      )
                    })}
                  </List>
                </Grid>
              </Grid>

              <Popover
                id={'simple-popover'}
                open={this.state.isSkillOpen}
                anchorEl={this.state.skillAnchor}
                onClose={this.onSkillClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <Typography style={{ padding: 20 }}>{this.state.skillDescription}</Typography>
              </Popover>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

CareerSkillScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    careerId: state.career.careerId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerSkillScreen));