import {
  Button,
  Card, CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem, ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class AssessmentScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      types: [],
      grades: [],
      assessment_name: '',
      assessment_type_id: 0,
      grade_id: 0,
      composite_score: 0,
      composite_level_id: 0,
      reading_score: 0,
      reading_level_id: 0,
      writing_score: 0,
      writing_level_id: 0,
      english_score: 0,
      english_level_id: 0,
      math_score: 0,
      math_level_id: 0,
      algebra_score: 0,
      algebra_level_id: 0,
      technical_math_score: 0,
      technical_math_level_id: 0,
      science_score: 0,
      science_level_id: 0,
      social_studies_score: 0,
      social_studies_level_id: 0,
      selectedItem: {}
    }

    this.createAssessment = this.createAssessment.bind(this);
    this.onNewOpen = this.onNewOpen.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onNewClick = this.onNewClick.bind(this);
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {

    this.getData(this.props.profileId);

    fetch("https://api.tasselpathway.com/assessment_type", {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content_Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {

        this.setState({
          types: responseJson.results
        })
      })

    fetch("https://api.tasselpathway.com/assessment_level", {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content_Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          levels: responseJson.results
        })
      })

    fetch("https://api.tasselpathway.com/grades", {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content_Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          grades: responseJson.results
        })
      })
  }

  getData(profileId) {

    this.setState({
      isLoading: true
    })

    fetch("https://api.tasselpathway.com/profile_assessment/person/" + profileId, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit"
    })
      .then(res => res.json())
      .then(responseJson => {
        this.setState({
          dataSource: responseJson.results
        })
        this.props.onAssessmentsLoaded(responseJson.results)
      })
  }

  createAssessment() {
    this.setState({
      isLoading: true
    })

    fetch("https://api.tasselpathway.com/profile_assessment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
      body: JSON.stringify({
        assessment_name: this.state.assessment_name,
        assessment_type_id: this.state.assessment_type_id,
        person_id: this.props.personId,
        grade_id: this.state.grade_id,
        composite_score: this.state.composite_score,
        composite_level_id: this.state.composite_level_id,
        reading_score: this.state.reading_score,
        reading_level_id: this.state.reading_level_id,
        writing_score: this.state.writing_score,
        writing_level_id: this.state.writing_level_id,
        english_score: this.state.english_score,
        english_level_id: this.state.english_level_id,
        math_score: this.state.math_score,
        math_level_id: this.state.math_level_id,
        algebra_score: this.state.algebra_score,
        algebra_level_id: this.state.algebra_level_id,
        technical_math_score: this.state.technical_math_score,
        technical_math_level_id: this.state.technical_math_level_id,
        science_score: this.state.science_score,
        science_level_id: this.state.science_level_id,
        social_studies_score: this.state.social_studies_score,
        social_studies_level_id: this.state.social_studies_level_id
      })
    })
      .then(res => res.json())
      .then(responseJson => {
        this.getData(this.props.profileId);
      })
      .catch((error) => {

      });
  }

  deleteAssessment() {

    this.setState({
      isLoading: true
    })

    fetch("https://api.tasselpathway.com/profile_assessment", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
      body: JSON.stringify({
        assessment_id: this.state.selectedItem.assessment_id
      })
    })
      .then(responseJson => {
        this.getData(this.props.profileId);
      })
      .catch((error) => {

      });
  }

  editAssessment() {

    fetch("https://api.tasselpathway.com/profile_assessment", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
      body: JSON.stringify({
        assessment_name: this.state.selectedItem.assessment_name,
        assessment_type_id: this.state.selectedItem.assessment_type_id,
        person_id: this.props.personId,
        grade_id: this.state.selectedItem.grade_id,
        composite_score: this.state.selectedItem.composite_score,
        composite_level_id: this.state.selectedItem.composite_level_id,
        reading_score: this.state.selectedItem.reading_score,
        reading_level_id: this.state.selectedItem.reading_level_id,
        writing_score: this.state.selectedItem.writing_score,
        writing_level_id: this.state.selectedItem.writing_level_id,
        english_score: this.state.selectedItem.english_score,
        english_level_id: this.state.selectedItem.english_level_id,
        math_score: this.state.selectedItem.math_score,
        math_level_id: this.state.selectedItem.math_level_id,
        algebra_score: this.state.selectedItem.algebra_score,
        algebra_level_id: this.state.selectedItem.algebra_level_id,
        technical_math_score: this.state.selectedItem.technical_math_score,
        technical_math_level_id: this.state.selectedItem.technical_math_level_id,
        science_score: this.state.selectedItem.science_score,
        science_level_id: this.state.selectedItem.science_level_id,
        social_studies_score: this.state.selectedItem.social_studies_score,
        social_studies_level_id: this.state.selectedItem.social_studies_level_id,
        assessment_id: this.state.selectedItem.assessment_id
      })
    })
      .then(res => res.json())
      .then(responseJson => {
        this.getData(this.props.profileId);
      })
      .catch((error) => {

      });
  }

  renderStudentAssessments() {

    var content = this.state.dataSource.map((item, key) => {
      return (
        <ListItem key={key} button alignItems="flex-start"
          onClick={() => this.onEditOpen(item)}>
          <ListItemText
            primary={
              <React.Fragment>
                {item.assessment_type_id === 5 ?
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                    className={this.props.classes.inline} color="textPrimary">
                    {item.assessment_name} - {item.grade_name}
                  </Typography>
                  :
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                    className={this.props.classes.inline} color="textPrimary">
                    {item.assessment_type_name} - {item.grade_name}
                  </Typography>
                }
                <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.project_description}</Typography>
              </React.Fragment>
            } secondary={
              <React.Fragment>
                <Grid container style={{ marginTop: 10, flexDirection: 'row' }}>

                  {item.assessment_type_id === 1 ?
                    <div>
                      {item.reading_level_name !== null ?
                        <Chip label={item.reading_level_name.substring(0, 1)}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.math_level_name !== null ?
                        <Chip label={item.math_level_name.substring(0, 1)}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.writing_level_name !== null ?
                        <Chip label={item.writing_level_name.substring(0, 1)}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.science_level_name !== null ?
                        <Chip label={item.science_level_name.substring(0, 1)}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.social_studies_level_name !== null ?
                        <Chip label={item.social_studies_level_name}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}
                    </div>
                    : null}

                  {item.assessment_type_id === 2 || item.assessment_type_id === 6
                    || item.assessment_type_id === 7 || item.assessment_type_id === 8
                    || item.assessment_type_id === 9 || item.assessment_type_id === 10
                    || item.assessment_type_id === 11 || item.assessment_type_id === 12 ?
                    <div>
                      <Chip label={item.composite_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.math_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.science_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.english_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.reading_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />
                    </div>
                    : null}

                  {item.assessment_type_id === 3 ?
                    <div>
                      <Chip label={item.composite_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.math_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.reading_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.writing_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />
                    </div>
                    : null}

                  {item.assessment_type_id === 4 ?
                    <div>
                      <Chip label={item.math_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.technical_math_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.algebra_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.reading_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />

                      <Chip label={item.writing_score}
                        style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                        className={this.props.classes.chip} />
                    </div>
                    : null}

                  {item.assessment_type_id === 5 ?
                    <div>
                      {item.composite_score !== 0 ?
                        <Chip label={item.composite_score}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}
                      {item.science_score !== 0 ?
                        <Chip label={item.science_score}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.english_score !== 0 ?
                        <Chip label={item.english_score}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.math_score !== 0 ?
                        <Chip label={item.math_score}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.technical_math_score !== 0 ?
                        <Chip label={item.technical_math_score}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.algebra_score !== 0 ?
                        <Chip label={item.algebra_score}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.reading_score !== 0 ?
                        <Chip label={item.reading_score}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}

                      {item.writing_score !== 0 ?
                        <Chip label={item.writing_score}
                          style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
                          className={this.props.classes.chip} />
                        : null}
                    </div>
                    : null}

                </Grid>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    })
    return content;
  }

  onNewOpen() {
    this.setState({
      isNewOpen: true
    })
  }

  onNewClose() {

    this.setState({
      isNewOpen: false
    })
  }

  onNewClick() {

    this.createAssessment();

    this.setState({
      isNewOpen: false
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onEditOpen(item) {

    this.setState({
      isEditOpen: true,
      selectedItem: item
    })
  }

  onEditClick() {
    this.editAssessment();

    this.setState({
      isEditOpen: false
    })
  }

  onDeleteClick() {
    this.deleteAssessment();

    this.setState({
      isEditOpen: false
    })
  }

  onRemoveClick() {

    this.setState({
      isNewOpen: false
    })
  }

  onAssessmentNameChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.assessment_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onAssessmentTypeChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.assessment_type_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onGradeChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.grade_id = event.target.value;
    this.setState({
      selectedItem: selectedItem
    })
  }

  onCompositeScoreChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.composite_score = event.target.value
    this.setState({
      selectedItem: selectedItem
    })
  }

  onMathScoreChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.math_score = event.target.value
    this.setState({
      selectedItem: selectedItem
    })
  }

  onScienceScoreChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.science_score = event.target.value
    this.setState({
      selectedItem: selectedItem
    })
  }

  onSocialStudiesScore(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.social_studies_score = event.target.value
    this.setState({
      selectedItem: selectedItem
    })
  }

  onEnglishScoreChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.english_score = event.target.value
    this.setState({
      selectedItem: selectedItem
    })
  }

  onReadingScoreChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.reading_score = event.target.value
    this.setState({
      selectedItem: selectedItem
    })
  }

  onWritingScoreChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.writing_score = event.target.value
    this.setState({
      selectedItem: selectedItem
    })
  }

  renderTypes() {

    var content = this.state.types.map((item, key) => {
      return (
        <MenuItem key={key} value={item.assessment_type_id}>{item.assessment_type_name}</MenuItem>
      )
    })

    return content;
  }

  renderLevels() {
    var content = this.state.levels.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {
        return (
          <MenuItem key={key} value={item.assessment_level_id}>{item.assessment_level_name_spanish}</MenuItem>
        )
      } else {
        return (
          <MenuItem key={key} value={item.assessment_level_id}>{item.assessment_level_name}</MenuItem>
        )
      }
    })

    return content;
  }

  renderGrades() {

    var content = this.state.grades.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {
        return (
          <MenuItem key={key} value={item.grade_id}>{item.grade_name_spanish}</MenuItem>
        )
      } else {
        return (
          <MenuItem key={key} value={item.grade_id}>{item.grade_name}</MenuItem>
        )
      }
    })

    return content;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <div className={classes.root}>
          <Dialog open={this.state.isNewOpen}
            onClose={this.onNewClose} fullWidth
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
              {i18n.t("ASSESSMENT_NEW")}
            </DialogTitle>

            <DialogContent>

              <div>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                  <Grid item xs={6}>

                    <FormControl className={classes.formControl}
                      fullWidth>
                      <InputLabel htmlFor="assessment_type_id">{i18n.t("ASSESSMENT_TYPE")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.assessment_type_id}
                        onChange={(event) => this.setState({ assessment_type_id: event.target.value })}
                        inputProps={{
                          name: 'assessment_type_id',
                          id: 'assessment_type_id',
                        }}>
                        <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_TYPE")}</MenuItem>
                        {this.renderTypes()}
                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={6}>

                    <FormControl className={classes.formControl}
                      fullWidth>
                      <InputLabel htmlFor="grade_id">{i18n.t("ASSESSMENT_YEAR")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.grade_id}
                        onChange={(event) => this.setState({ grade_id: event.target.value })}
                        inputProps={{
                          name: 'grade_id',
                          id: 'grade_id',
                        }}>
                        <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_YEAR")}</MenuItem>
                        {this.renderGrades()}
                      </Select>
                    </FormControl>

                  </Grid>
                </Grid>

                {this.state.assessment_type_id === 5 ?
                  <Grid container spacing={2}>
                    <Grid item xs={12}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="assessment_name">{i18n.t("ASSESSMENT_NAME")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.assessment_name}
                          onChange={(event) => this.setState({ assessment_name: event.target.value })}
                          id="assessment_name" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>
                  </Grid>
                  : null}

                <Grid container spacing={2}>

                  {this.state.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="reading_level_id">{i18n.t("ASSESSMENT_READING")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.reading_level_id}
                          onChange={(event) => this.setState({ reading_level_id: event.target.value })}
                          inputProps={{
                            name: 'reading_level_id',
                            id: 'reading_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_READING")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="math_level_id">{i18n.t("ASSESSMENT_SELECT_MATH")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.math_level_id}
                          onChange={(event) => this.setState({ math_level_id: event.target.value })}
                          inputProps={{
                            name: 'math_level_id',
                            id: 'math_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_SELECT_MATH")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="writing_level_id">{i18n.t("ASSESSMENT_WRITING")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.writing_level_id}
                          onChange={(event) => this.setState({ writing_level_id: event.target.value })}
                          inputProps={{
                            name: 'writing_level_id',
                            id: 'writing_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_WRITING")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="science_level_id">{i18n.t("ASSESSMENT_SCIENCE")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.science_level_id}
                          onChange={(event) => this.setState({ science_level_id: event.target.value })}
                          inputProps={{
                            name: 'science_level_id',
                            id: 'science_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_SCIENCE")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="social_studies_level_id">{i18n.t("ASSESSMENT_SOCIAL")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.social_studies_level_id}
                          onChange={(event) => this.setState({ social_studies_level_id: event.target.value })}
                          inputProps={{
                            name: 'social_studies_level_id',
                            id: 'social_studies_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_SOCIAL")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 2
                    || this.state.assessment_type_id === 3
                    || this.state.assessment_type_id === 5
                    || this.state.assessment_type_id === 6
                    || this.state.assessment_type_id === 7
                    || this.state.assessment_type_id === 8
                    || this.state.assessment_type_id === 9
                    || this.state.assessment_type_id === 10
                    || this.state.assessment_type_id === 11
                    || this.state.assessment_type_id === 12 ?
                    <Grid item xs={4}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="composite_score">{i18n.t("ASSESSMENT_COMPOSITE")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.composite_score}
                          onChange={(event) => this.setState({ composite_score: event.target.value })}
                          id="composite_score" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 2
                    || this.state.assessment_type_id === 3
                    || this.state.assessment_type_id === 4
                    || this.state.assessment_type_id === 5
                    || this.state.assessment_type_id === 6
                    || this.state.assessment_type_id === 7
                    || this.state.assessment_type_id === 8
                    || this.state.assessment_type_id === 9
                    || this.state.assessment_type_id === 10
                    || this.state.assessment_type_id === 11
                    || this.state.assessment_type_id === 12 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="math_score">{i18n.t("ASSESSMENT_MATH")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.math_score}
                          onChange={(event) => this.setState({ math_score: event.target.value })}
                          id="math_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 4
                    || this.state.assessment_type_id === 5 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="technical_math_score">{i18n.t("ASSESSMENT_TECHNICAL")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.technical_math_score}
                          onChange={(event) => this.setState({ technical_math_score: event.target.value })}
                          id="technical_math_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 4
                    || this.state.assessment_type_id === 5 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="algebra_score">{i18n.t("ASSESSMENT_ALGEBRA")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.algebra_score}
                          onChange={(event) => this.setState({ algebra_score: event.target.value })}
                          id="algebra_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 2
                    || this.state.assessment_type_id === 5
                    || this.state.assessment_type_id === 6
                    || this.state.assessment_type_id === 7
                    || this.state.assessment_type_id === 8
                    || this.state.assessment_type_id === 9
                    || this.state.assessment_type_id === 10
                    || this.state.assessment_type_id === 11
                    || this.state.assessment_type_id === 12 ?
                    <Grid item xs={4}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="science_score">{i18n.t("ASSESSMENT_SCIENCE")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.science_score}
                          onChange={(event) => this.setState({ science_score: event.target.value })}
                          id="science_score" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 2
                    || this.state.assessment_type_id === 5
                    || this.state.assessment_type_id === 6
                    || this.state.assessment_type_id === 7
                    || this.state.assessment_type_id === 8
                    || this.state.assessment_type_id === 9
                    || this.state.assessment_type_id === 10
                    || this.state.assessment_type_id === 11
                    || this.state.assessment_type_id === 12 ?
                    <Grid item xs={4}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="english_score">{i18n.t("ASSESSMENT_ENGLISH")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.english_score}
                          onChange={(event) => this.setState({ english_score: event.target.value })}
                          id="english_score" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 2
                    || this.state.assessment_type_id === 3
                    || this.state.assessment_type_id === 4
                    || this.state.assessment_type_id === 5
                    || this.state.assessment_type_id === 6
                    || this.state.assessment_type_id === 7
                    || this.state.assessment_type_id === 8
                    || this.state.assessment_type_id === 9
                    || this.state.assessment_type_id === 10
                    || this.state.assessment_type_id === 11
                    || this.state.assessment_type_id === 12 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="reading_score">{i18n.t("ASSESSMENT_READING")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.reading_score}
                          onChange={(event) => this.setState({ reading_score: event.target.value })}
                          id="reading_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.assessment_type_id === 4
                    || this.state.assessment_type_id === 3
                    || this.state.assessment_type_id === 5 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="writing_score">{i18n.t("ASSESSMENT_WRITING_SCORE")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.writing_score}
                          onChange={(event) => this.setState({ writing_score: event.target.value })}
                          id="writing_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                </Grid>

              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => this.onNewClick()} color="primary"
                style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }}>
                {i18n.t("ASSESSMENT_ADD_BUTTON")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isEditOpen} className={classes.popup}
            onClose={this.onEditClose} fullWidth
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title"
              onClose={this.onEditClose}>
              {i18n.t("ASSESSMENT_EDIT")}
            </DialogTitle>

            <DialogContent>

              <div>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                  <Grid item xs={6}>

                    <FormControl className={classes.formControl}
                      fullWidth>
                      <InputLabel htmlFor="assessment_type_id">{i18n.t("ASSESSMENT_TYPE")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.selectedItem.assessment_type_id}
                        onChange={(event) => this.onAssessmentTypeChange(event)}
                        inputProps={{
                          name: 'assessment_type_id',
                          id: 'assessment_type_id',
                        }}>
                        <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_TYPE")}</MenuItem>
                        {this.renderTypes()}
                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={6}>

                    <FormControl className={classes.formControl}
                      fullWidth>
                      <InputLabel htmlFor="grade_id">{i18n.t("ASSESSMENT_YEAR")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.selectedItem.grade_id}
                        onChange={(event) => this.onGradeChange(event)}
                        inputProps={{
                          name: 'grade_id',
                          id: 'grade_id',
                        }}>
                        <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_YEAR")}</MenuItem>
                        {this.renderGrades()}
                      </Select>
                    </FormControl>

                  </Grid>
                </Grid>

                {this.state.selectedItem.assessment_type_id === 5 ?
                  <Grid container spacing={2}>
                    <Grid item xs={12}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="assessment_name">{i18n.t("ASSESSMENT_NAME")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.assessment_name}
                          onChange={(event) => this.onAssessmentNameChange(event)}
                          id="assessment_name" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>
                  </Grid>
                  : null}

                <Grid container spacing={2}>



                  {this.state.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="reading_level_id">{i18n.t("ASSESSMENT_READING")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.selectedItem.reading_level_id}
                          onChange={(event) => this.setState({ reading_level_id: event.target.value })}
                          inputProps={{
                            name: 'reading_level_id',
                            id: 'reading_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_READING")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="math_level_id">{i18n.t("ASSESSMENT_SELECT_MATH")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.selectedItem.math_level_id}
                          onChange={(event) => this.setState({ math_level_id: event.target.value })}
                          inputProps={{
                            name: 'math_level_id',
                            id: 'math_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_SELECT_MATH")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="writing_level_id">{i18n.t("ASSESSMENT_WRITING")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.selectedItem.writing_level_id}
                          onChange={(event) => this.setState({ writing_level_id: event.target.value })}
                          inputProps={{
                            name: 'writing_level_id',
                            id: 'writing_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_WRITING")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="science_level_id">{i18n.t("ASSESSMENT_SCIENCE")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.selectedItem.science_level_id}
                          onChange={(event) => this.setState({ science_level_id: event.target.value })}
                          inputProps={{
                            name: 'science_level_id',
                            id: 'science_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_SCIENCE")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 1 ?
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl} style={{ marginTop: 10 }}
                        fullWidth>
                        <InputLabel htmlFor="social_studies_level_id">{i18n.t("ASSESSMENT_SOCIAL")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.selectedItem.social_studies_level_id}
                          onChange={(event) => this.setState({ social_studies_level_id: event.target.value })}
                          inputProps={{
                            name: 'social_studies_level_id',
                            id: 'social_studies_level_id',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("ASSESSMENT_SOCIAL")}</MenuItem>
                          {this.renderLevels()}
                        </Select>
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 2
                    || this.state.selectedItem.assessment_type_id === 3
                    || this.state.selectedItem.assessment_type_id === 5
                    || this.state.selectedItem.assessment_type_id === 6
                    || this.state.selectedItem.assessment_type_id === 7
                    || this.state.selectedItem.assessment_type_id === 8
                    || this.state.selectedItem.assessment_type_id === 9
                    || this.state.selectedItem.assessment_type_id === 10
                    || this.state.selectedItem.assessment_type_id === 11
                    || this.state.selectedItem.assessment_type_id === 12 ?
                    <Grid item xs={4}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="composite_score">{i18n.t("ASSESSMENT_COMPOSITE")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.composite_score}
                          onChange={(event) => this.onCompositeScoreChange(event)}
                          id="composite_score" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 2
                    || this.state.selectedItem.assessment_type_id === 3
                    || this.state.selectedItem.assessment_type_id === 4
                    || this.state.selectedItem.assessment_type_id === 5
                    || this.state.selectedItem.assessment_type_id === 6
                    || this.state.selectedItem.assessment_type_id === 7
                    || this.state.selectedItem.assessment_type_id === 8
                    || this.state.selectedItem.assessment_type_id === 9
                    || this.state.selectedItem.assessment_type_id === 10
                    || this.state.selectedItem.assessment_type_id === 11
                    || this.state.selectedItem.assessment_type_id === 12 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="math_score">{i18n.t("ASSESSMENT_MATH")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.math_score}
                          onChange={(event) => this.onMathScoreChange(event)}
                          id="math_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 4
                    || this.state.selectedItem.assessment_type_id === 5 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="technical_math_score">{i18n.t("ASSESSMENT_TECHNICAL")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.technical_math_score}
                          onChange={(event) => this.onTechnicalScoreChange(event)}
                          id="technical_math_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 4
                    || this.state.selectedItem.assessment_type_id === 5 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="algebra_score">{i18n.t("ASSESSMENT_ALGEBRA")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.algebra_score}
                          onChange={(event) => this.onAlgebraScoreChange(event)}
                          id="algebra_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 2
                    || this.state.selectedItem.assessment_type_id === 5
                    || this.state.selectedItem.assessment_type_id === 6
                    || this.state.selectedItem.assessment_type_id === 7
                    || this.state.selectedItem.assessment_type_id === 8
                    || this.state.selectedItem.assessment_type_id === 9
                    || this.state.selectedItem.assessment_type_id === 10
                    || this.state.selectedItem.assessment_type_id === 11
                    || this.state.selectedItem.assessment_type_id === 12 ?
                    <Grid item xs={4}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="science_score">{i18n.t("ASSESSMENT_SCIENCE")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.science_score}
                          onChange={(event) => this.onScienceScoreChange(event)}
                          id="science_score" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 2
                    || this.state.selectedItem.assessment_type_id === 5
                    || this.state.selectedItem.assessment_type_id === 6
                    || this.state.selectedItem.assessment_type_id === 7
                    || this.state.selectedItem.assessment_type_id === 8
                    || this.state.selectedItem.assessment_type_id === 9
                    || this.state.selectedItem.assessment_type_id === 10
                    || this.state.selectedItem.assessment_type_id === 11
                    || this.state.selectedItem.assessment_type_id === 12 ?
                    <Grid item xs={4}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="english_score">{i18n.t("ASSESSMENT_ENGLISH")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.english_score}
                          onChange={(event) => this.onEnglishScoreChange(event)}
                          id="english_score" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 2
                    || this.state.selectedItem.assessment_type_id === 3
                    || this.state.selectedItem.assessment_type_id === 4
                    || this.state.selectedItem.assessment_type_id === 5
                    || this.state.selectedItem.assessment_type_id === 6
                    || this.state.selectedItem.assessment_type_id === 7
                    || this.state.selectedItem.assessment_type_id === 8
                    || this.state.selectedItem.assessment_type_id === 9
                    || this.state.selectedItem.assessment_type_id === 10
                    || this.state.selectedItem.assessment_type_id === 11
                    || this.state.selectedItem.assessment_type_id === 12 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="reading_score">{i18n.t("ASSESSMENT_READING")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.reading_score}
                          onChange={(event) => this.onReadingScoreChange(event)}
                          id="reading_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                  {this.state.selectedItem.assessment_type_id === 4
                    || this.state.selectedItem.assessment_type_id === 3
                    || this.state.selectedItem.assessment_type_id === 5 ?
                    <Grid item xs={4}>
                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="writing_score">{i18n.t("ASSESSMENT_WRITING_SCORE")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.writing_score}
                          onChange={(event) => this.onWritingScoreChange(event)}
                          id="writing_score" aria-describedby="my-helper-text" />
                      </FormControl>
                    </Grid>
                    : null}

                </Grid>

              </div>

            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => this.onDeleteClick()}
                style={{ color: '#FFF', fontWeight: 'bold' }} color="secondary">
                {i18n.t("ASSESSMENT_DELETE")}
              </Button>
              <Button variant="contained" onClick={() => this.onEditClick()}
                style={{ marginRight: 20, color: '#FFF', fontWeight: 'bold' }} color="primary">
                {i18n.t("ASSESSMENT_SAVE")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("ASSESSMENTS")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.state.dataSource !== [] ? this.renderStudentAssessments() : <Typography>{i18n.t("NONE")}</Typography>}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

AssessmentScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssessmentScreen));