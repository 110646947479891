import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { login, loginFailure, loginSuccess } from '../../../redux/actions/auth';
import { setLanguage } from '../../../redux/actions/language';
import { setPerson } from '../../../redux/actions/person';
import { setTask } from '../../../redux/actions/task';

class CleverScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };
  }

  componentDidMount() {

    let params = queryString.parse(this.props.location.search)

    var code = params.code;

    fetch('https://api.tasselpathway.com/authenticate_clever', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code: code
      }),
      credentials: 'omit'
    })
      .then(response => {
        return response.json()
      })
      .then((responseJson) => {
        if (responseJson.authenticated === true) {

          this.props.onLoginSuccess(responseJson.user_name, '', responseJson.person_id, responseJson.grade_id,
            responseJson.grade_name, responseJson.first_name, responseJson.last_name, responseJson.token,
            responseJson.user_role_id, responseJson.user_right_id, responseJson.has_multi_district);

          this.props.onSetTask(0, '', '', '', 0, 0, '');

          this.props.onSetPerson(responseJson.person_person_id, responseJson.person_first_name, responseJson.person_last_name);

          this.props.history.push('/');

        } else {
          this.props.onLoginFailure(true);
          this.props.history.push('/');
        }
      })
      .catch((e) => {

      });
  }

  userLogin(e, click) {
    if (e.charCode === 13 || click) {
      this.props.onLoginFailure(this.state.username, this.state.password, this.props.selectedLanguage);
    }
  }

  render() {

    return (
      <div></div>
    )
  }
}

CleverScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    language: state.language.selectedLanguage,
    hasError: state.auth.hasError,
    errorMessage: state.auth.errorMessage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (username, password, language) => { dispatch(login(username, password, language)); },
    onSetLanguage: (selectedLanguage) => { dispatch(setLanguage(selectedLanguage)); },
    onLoginFailure: () => { dispatch(loginFailure(true)); },
    onLoginSuccess: (userName, password, personId, gradeId, gradeName, firstName, lastName, token,
      userRoleId, userRightId, hasMultiDistrict) => {
        dispatch(loginSuccess(userName, password, personId,
          gradeId, gradeName, firstName, lastName, token, userRoleId, userRightId, hasMultiDistrict));
    },
    onSetTask: (taskId, taskName, taskDescription, estimatedTime, totalPoints, autoGrade, answerText, taskUpdated) => { dispatch(setTask(taskId, taskName, taskDescription, estimatedTime, totalPoints, autoGrade, answerText, taskUpdated)); },
    onSetPerson: (personId, firstName, lastName) => { dispatch(setPerson(personId, firstName, lastName)); }

  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(CleverScreen)));