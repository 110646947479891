import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Step, StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { logout } from '../../../../redux/actions/auth';
import { setProgress } from '../../../../redux/actions/progress';
import styles from './style';

class CreateMessageScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      activeStep: 0,
      message: '',
      chosenAudience: '',
      smsOrPhone: '',
      phoneNumbers: [],
      selectedTarget: '',
      grades: [],
      schools: [],
      schoolCheckList: [],
      gradeList: [],
      newSchoolDetails: [],
      schoolNameList: [],
      newDetails: [],
      checkList: [],
      gradeNameList: [],
      startDate: new Date(),
      endDate: new Date(),
      isSMSDefinitionOpen: false,
      isPhoneDefinitionOpen: false,
      isBothDefinitionOpen: false,
      pastCalls: []
    }
  }

  componentDidMount() {
    this.setState({
      activeStep: 0
    })

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            schools: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/make_call', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            pastCalls: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onNewSchoolChange(event, schoolName) {

    var newSchoolDetails = this.state.newSchoolDetails;
    var schoolCheckList = this.state.schoolCheckList;
    var schoolId = event.target.value;
    var i = 0;
    var schoolNameArray = this.state.schoolNameList

    if (event.target.checked) {
      newSchoolDetails.push({ school_id: schoolId })
      schoolCheckList['checked_' + schoolId] = true;
      schoolNameArray.push(schoolName)
    } else {
      newSchoolDetails.map((item, key) => {
        if (item.school_id === schoolId) {
          newSchoolDetails.splice(i, 1);
          schoolCheckList['checked_' + schoolId] = false
          const index = schoolNameArray.indexOf(schoolName);
          if (index > -1) {
            schoolNameArray.splice(index, 1);
          }
        }
        i++;
      })
    }
    this.setState({
      checkList: schoolCheckList,
      newSchoolDetails: newSchoolDetails,
      schoolNameList: this.state.schoolNameList
    })
  }
  onNewGradeChange(event, gradeName) {

    var details = this.state.newDetails;
    var checkList = this.state.checkList;
    var gradeId = event.target.value;
    var i = 0;
    var gradeNameArray = this.state.gradeNameList

    if (event.target.checked) {
      details.push({ grade_id: gradeId })
      checkList['checked_' + gradeId] = true;
      gradeNameArray.push(gradeName)
    } else {
      details.map((item, key) => {
        if (item.grade_id === gradeId) {
          details.splice(i, 1);
          checkList['checked_' + gradeId] = false
          const index = gradeNameArray.indexOf(gradeName);
          if (index > -1) {
            gradeNameArray.splice(index, 1);
          }
        }
        i++;
      })
    }
    this.setState({
      checkList: checkList,
      newDetails: details,
      gradeNameList: gradeNameArray
    })
  }

  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };

  sendText(message) {
    fetch('https://api.tasselpathway.com/send_sms/' + message, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            phoneNumbers: responseJson.result
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        })
      })
  }

  callPhone(message) {
    fetch('https://api.tasselpathway.com/make_call/' + message, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            phoneNumbers: responseJson.result
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      })
  }

  onSend() {

    let message = this.state.message
    let gradeList = this.state.newDetails
    let gradeListArray = []

    let schoolList = this.state.newSchoolDetails
    let schoolListArray = []

    if (gradeList.length > 0) {
      for (var i = 0; i < gradeList.length; i++) {
        gradeListArray.push(gradeList[i].grade_id)
      }
    } else {
      gradeListArray = 0
    }

    if (schoolList.length > 0) {
      for (var j = 0; j < schoolList.length; j++) {
        schoolListArray.push(schoolList[j].school_id)
      }
    } else {
      schoolListArray = 0
    }

    var selectedTarget = []
    if (this.state.selectedTarget === 'District') {
      selectedTarget.push(this.state.selectedTarget)
    } else {
      selectedTarget.push('Empty')
    }

    if (this.state.smsOrPhone === 'Phone Message') {
      fetch('https://api.tasselpathway.com/make_call/' + message + '/' + gradeListArray + '/' + schoolListArray + '/' + selectedTarget, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              phoneNumbers: responseJson.result
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });

      fetch('https://api.tasselpathway.com/make_call', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          phone_call_message: message,
          phone_call_audience: this.state.selectedTarget
        })
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.props.history.push('/admin');
        })

    } else if (this.state.smsOrPhone === 'Text (SMS) Message') {
      fetch('https://api.tasselpathway.com/send_sms/' + message + '/' + gradeListArray + '/' + schoolListArray + '/' + selectedTarget, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              phoneNumbers: responseJson.result
            })
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          })
        });

      fetch('https://api.tasselpathway.com/send_sms', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          text_message_message: message,
          text_message_audience: this.state.selectedTarget
        })
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.props.history.push('/admin');
        })
    }
    else if (this.state.smsOrPhone === 'Both') {
      this.sendText(message)

      setTimeout(
        function () {
          this.callPhone(message)
        }.bind(this), 5000);
    }
  }

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1
    })
    if (this.state.activeStep === 3) {
      this.onSend()
    }
  };

  onSMSDefinitionOpen = () => {
    this.setState({
      isSMSDefinitionOpen: true
    })
  };

  onSMSDefinitionClose = () => {
    this.setState({
      isSMSDefinitionOpen: false
    })
  };

  onPhoneDefinitionOpen = () => {
    this.setState({
      isPhoneDefinitionOpen: true
    })
  };

  onPhoneDefinitionClose = () => {
    this.setState({
      isPhoneDefinitionOpen: false
    })
  };

  onBothDefinitionOpen = () => {
    this.setState({
      isBothDefinitionOpen: true
    })
  };

  onBothDefinitionClose = () => {
    this.setState({
      isBothDefinitionOpen: false
    })
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    })
  };

  handleChange = (event) => {
    this.setState({
      chosenAudience: event.target.value
    })
  };

  handleRadioButtons = (event) => {
    this.setState({
      smsOrPhone: event.target.value
    })
  }

  selectAudience = (event) => {
    this.setState({
      selectedTarget: event.target.value,
      gradeNameList: [],
      schoolNameList: [],
    })
  }

  handleBackToCommunications = () => {
    this.props.history.push('/admin');
  }

  renderTo() {

    if (this.state.schoolNameList.length > 0) {
      var content = this.state.schoolNameList.map((label) => {
        return (
          <Typography style={{ fontSize: 14 }}>{label}</Typography>)
      })
      return content;
    } else if (this.state.gradeNameList.length > 0) {

      content = this.state.gradeNameList.map((label) => {
        return (
          <Typography style={{ fontSize: 14 }}>{label}</Typography>)
      })
      return content;
    } else if (this.state.selectedTarget === "Teachers Only") {

      return (
        <Typography style={{ fontSize: 14 }}>Teachers Only</Typography>
      )
    } else if (this.state.selectedTarget === "District") {

      return (
        <Typography style={{ fontSize: 14 }}>District</Typography>
      )
    } else {
      return (
        <Typography style={{ fontSize: 14 }}>All</Typography>
      )
    }
  }

  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Popover
              id={'simple-popover'}
              open={this.state.isSMSDefinitionOpen}
              anchorEl={this.state.definitionAnchor}
              onClose={this.onSMSDefinitionClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <Typography style={{ padding: 20 }}>This will send your message as a text to the audience you choose.</Typography>
            </Popover>

            <Popover
              id={'simple-popover'}
              open={this.state.isPhoneDefinitionOpen}
              anchorEl={this.state.definitionAnchor}
              onClose={this.onPhoneDefinitionClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <Typography style={{ padding: 20 }}>This will send your message as an automated voice call to the audience you choose.</Typography>
            </Popover>

            <Popover
              id={'simple-popover'}
              open={this.state.isBothDefinitionOpen}
              anchorEl={this.state.definitionAnchor}
              onClose={this.onBothDefinitionClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <Typography style={{ padding: 20 }}>This will send both an automated voice call as well as a text message to the audience you choose.</Typography>
            </Popover>


            <Typography style={{}}>
              Type your message below.
            </Typography>

            <TextField
              style={styles.messageField}
              placeholder="Start typing here"
              multiline
              rows={1}
              rowsMax={120}
              onChange={(event) => this.setState({ message: event.target.value })}
            />

            <FormControl style={{ paddingTop: 60 }}>
              <FormLabel style={{ color: 'black' }}>What type of message would you like to send?</FormLabel>
              <RadioGroup aria-label="smsOrPhone" name="smsOrPhone" value={this.state.smsOrPhone} onChange={(event) => this.handleRadioButtons(event)}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel value="Text (SMS) Message" control={<Radio />} label="Text (SMS) Message" />
                  <Typography style={{ verticalAlign: 'middle', marginTop: 8 }} onClick={(event) => this.onSMSDefinitionOpen(event)}>
                    <InfoIcon style={{ color: '#25D192', marginLeft: -5, fontSize: 20, verticalAlign: 'middle' }} /></Typography>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel value="Phone Message" control={<Radio />} label="Phone Call / Voicemail Message" />
                  <Typography style={{ verticalAlign: 'middle', marginTop: 8 }} onClick={(event) => this.onPhoneDefinitionOpen(event)}>
                    <InfoIcon style={{ color: '#25D192', marginLeft: -5, fontSize: 20, verticalAlign: 'middle' }} /></Typography>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel value="Both" control={<Radio />} label="Both" />
                  <Typography style={{ verticalAlign: 'middle', marginTop: 8 }} onClick={(event) => this.onBothDefinitionOpen(event)}>
                    <InfoIcon style={{ color: '#25D192', marginLeft: -5, fontSize: 20, verticalAlign: 'middle' }} /></Typography>
                </div>

              </RadioGroup>
            </FormControl>
          </div>
        )
      case 1:
        return (
          <div>
            <Typography style={{}}>
              Your message says:
            </Typography>

            <Typography style={{ marginTop: 20 }}>
              "{this.state.message}"
            </Typography>

            {/* {this.state.smsOrPhone === 'Both' ?
              <div>
                <div style={{display:"flex", justifyContent:'row', marginTop: 20 }}>
                <Typography style={{ marginRight:10 }}>
                  Start Date:
                </Typography>

                <DatePicker
                  style={{ marginTop:13}}
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                />
                </div>

                <div style={{display:"flex", justifyContent:'row', marginTop: 20 }}>
                <Typography style={{ marginRight:10 }}>
                  End Date:
                </Typography>

                <DatePicker
                  style={{ marginTop:13}}
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                />
                </div>
              </div>
              :
              <Typography style={{ marginTop: 20 }}>
                Press next to proceed
              </Typography>} */}
          </div>
        )
      case 2:
        return (
          <div>
            <Typography style={{ marginBottom: 30 }}>
              Who would you like to recieve this message?
            </Typography>
            <FormControl>

              <Grid container>

                <Grid item xs={6}>

                  <FormControl style={{ marginTop: 10, minWidth: 400 }}>
                    <InputLabel htmlFor="newFrequencyId">Choose</InputLabel>
                    <Select
                      value={this.state.selectedTarget}
                      onChange={(event) => this.selectAudience(event)}
                      inputProps={{
                        name: 'newFrequencyId',
                        id: 'newFrequencyId',
                      }}>
                      <MenuItem disabled value={0}>Choose</MenuItem>
                      <MenuItem value={'District'}>District</MenuItem>
                      <MenuItem value={'School'}>School</MenuItem>
                      <MenuItem value={'Grades'}>Grades</MenuItem>
                    </Select>
                  </FormControl>

                </Grid>

              </Grid>

              {this.state.selectedTarget === 'Grades' ?

                <>
                  <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Grades</Typography>

                  <FormGroup row style={{ marginLeft: 20 }}>

                    {this.state.grades.map((item, key) => {
                      return (
                        <FormControlLabel key={key} onChange={(event) => this.onNewGradeChange(event, item.grade_name)}
                          control={
                            <Checkbox value={item.grade_id} checked={this.state.checkList['checked_' + item.grade_id] ? true : false} />
                          }
                          label={item.grade_name}
                        />
                      )
                    })}

                  </FormGroup>
                </>

                : null}

              {this.state.selectedTarget === 'School' ?

                <>
                  <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select School(s)</Typography>

                  <FormGroup row style={{ marginLeft: 20 }}>

                    {this.state.schools.map((item, key) => {
                      return (
                        <FormControlLabel key={key} onChange={(event) => this.onNewSchoolChange(event, item.school_name)}
                          control={
                            <Checkbox value={item.school_id} checked={this.state.schoolCheckList['checked_' + item.school_id] ? true : false} />
                          }
                          label={item.school_name}
                        />
                      )
                    })}

                  </FormGroup>
                </>

                : null}

            </FormControl>
          </div>
        )
      case 3:
        return (
          <div>
            Are you ready to send your message: <br></br> <br></br>"<strong></strong>{this.state.message}<strong></strong>"<br></br> <br></br>To: {this.renderTo()} as a(n) "{this.state.smsOrPhone}"?
          </div>
        )
      default:
        return (
          <div>
            Your message has been sent.
          </div>
        )
    }
  }

  render() {
    let steps = ["Compose Message", "Review Message", "Choose Audience", "Send"]

    return (
      <div style={{ marginTop: 0 }}>
        <Button
          onClick={this.handleBackToCommunications}
          style={{ marginTop: 25, marginBottom: 13, marginLeft: '10%' }}
        >
          Back
        </Button>
        <div style={styles.container}>
          <Stepper activeStep={this.state.activeStep}
            alternativeLabel
            style={{ marginBottom: 50 }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <div>
            <div
            // className={classes.instructions}
            >{this.getStepContent(this.state.activeStep)}</div>
            <div>
              <Button
                disabled={this.state.activeStep === 4 || this.state.activeStep === 0}
                onClick={this.handleBack}
                style={{ marginTop: 50, marginRight: 10 }}
              >
                Back
              </Button>
              <Button
                disabled={this.state.activeStep === 4}
                variant="contained"
                color="primary"
                style={{ marginTop: 50 }}
                onClick={this.handleNext}>
                {this.state.activeStep === steps.length - 1 ? 'Send' : 'Next'}
              </Button>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

CreateMessageScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage,
    personId: state.auth.personId,
    schoolId: state.profile.schoolId,
    schoolName: state.profile.schoolName,
    gradeId: state.profile.gradeId,
    gradeName: state.profile.gradeName
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(CreateMessageScreen))));
