import { SET_OBJECTIVE , SET_RESUME, SET_RESUME_TEMPLATE, SET_SECTIONS, SET_IS_EDITING_RESUME, CLEAR_RESUME_DATA } from "../actions";

export const defaultState = {   
    isEditing:false,
    resumeId:0,
    resumeTemplate:"",
    objectiveText:"",
    skills:[],
    workExperience:[],
    honorsAndAwards:[],
    workBasedLearning:[],
    serviceLearning:[],
    schoolName:"",
    resumeSections:[],
    objectiveSelected:false,
    skillsSelected:false,
    workExperienceSelected:false,
    honorsSelected:false,
    workSelected:false,
    serviceSelected:false
};
 
export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case SET_IS_EDITING_RESUME:
             return Object.assign({}, state,{
                 isEditing:action.isEditing,
                 resumeId:action.resumeId
             })
         case SET_RESUME_TEMPLATE:
             return Object.assign({}, state, {
                 resumeTemplate:action.resumeTemplate
             })
         case SET_OBJECTIVE:
             return Object.assign({}, state, {
                objectiveText: action.objectiveText
             });
        case SET_SECTIONS:
             return Object.assign({}, state, {
                resumeSections: action.resumeSections,
                objectiveSelected:action.objectiveSelected,
                skillsSelected: action.skillsSelected,
                workExperienceSelected: action.workExperienceSelected,
                honorsSelected: action.achievementsSelected,
                workSelected: action.workSelected,
                serviceSelected: action.serviceSelected,
             })
        case SET_RESUME:
            return Object.assign({}, state, {
                objectiveTest: action.objectiveText,
                skills: action.skills,
                workExperience: action.workExperience,
                honorsAndAwards: action.honorsAndAwards,
                workBasedLearning: action.workBasedLearning,
                serviceLearning:action.serviceLearning,
                schoolName:action.schoolName
            })
        case CLEAR_RESUME_DATA:
            return Object.assign({}, state, {
                isEditing:false,
                resumeId:0,
                resumeTemplate:"",
                objectiveText:"",
                skills:[],
                workExperience:[],
                honorsAndAwards:[],
                workBasedLearning:[],
                serviceLearning:[],
                schoolName:"",
                resumeSections:[],
                objectiveSelected:false,
                skillsSelected:false,
                workExperienceSelected:false,
                honorsSelected:false,
                workSelected:false,
                serviceSelected:false
            })
         default:
             return state
     }
 }  