import { faRibbon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';

class InfoScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: []
    }
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#499CB3', marginBottom: 20 }}>Overview</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>

              <br />

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>

                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <FontAwesomeIcon icon={faRibbon} style={{ color: '#25D192', marginTop: 15, fontSize: 24 }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 12, marginTop: 5, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                              Classroom / School Award
                            </Typography>
                          </React.Fragment>
                        } secondary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 10, marginTop: 5, }} component="span" className={classes.inline} color="textPrimary">
                              Description of Award
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <FontAwesomeIcon icon={faRibbon} style={{ color: '#25D192', marginTop: 15, fontSize: 24 }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 12, marginTop: 5, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                              Honor's Day Award
                            </Typography>
                          </React.Fragment>
                        } secondary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 10, marginTop: 5, }} component="span" className={classes.inline} color="textPrimary">
                              Description of Award
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <FontAwesomeIcon icon={faRibbon} style={{ color: '#25D192', marginTop: 15, fontSize: 24 }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 12, marginTop: 5, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                              K-Prep Award
                            </Typography>
                          </React.Fragment>
                        } secondary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 10, marginTop: 5, }} component="span" className={classes.inline} color="textPrimary">
                              Description of Award
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <FontAwesomeIcon icon={faRibbon} style={{ color: '#25D192', marginTop: 15, fontSize: 24 }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 12, marginTop: 5, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                              Sports Banquet Award
                            </Typography>
                          </React.Fragment>
                        } secondary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 10, marginTop: 5, }} component="span" className={classes.inline} color="textPrimary">
                              Description of Award
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <FontAwesomeIcon icon={faRibbon} style={{ color: '#25D192', marginTop: 15, fontSize: 24 }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 12, marginTop: 5, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                              Club Banquet Award
                            </Typography>
                          </React.Fragment>
                        } secondary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 10, marginTop: 5, }} component="span" className={classes.inline} color="textPrimary">
                              Description of Award
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <FontAwesomeIcon icon={faRibbon} style={{ color: '#25D192', marginTop: 15, fontSize: 24 }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 12, marginTop: 5, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                              Career / Technical Award
                            </Typography>
                          </React.Fragment>
                        } secondary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 10, marginTop: 5, }} component="span" className={classes.inline} color="textPrimary">
                              Description of Award
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <FontAwesomeIcon icon={faRibbon} style={{ color: '#25D192', marginTop: 15, fontSize: 24 }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 12, marginTop: 5, fontWeight: 'bold' }} component="span" className={classes.inline} color="textPrimary">
                              Community Award
                            </Typography>
                          </React.Fragment>
                        } secondary={
                          <React.Fragment>
                            <Typography style={{ fontSize: 10, marginTop: 5, }} component="span" className={classes.inline} color="textPrimary">
                              Description of Award
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>

      </div>
    );
  }
}

InfoScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InfoScreen));