import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { login } from '../../redux/actions/auth';
import styles from './style.js';

class RegisterScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            userName: '',
            gradeId: 0,
            grades: [],
            dataSource: [],
            invalidToken: false,
            invalidUserName: false,
            firstNameValid: true,
            lastNameValid: true,
            userNameValid: true,
            inviteTokenValid: true,
            passwordMatch: false,
            passwordLength: false,
            passwordCapital: false,
            passwordNumber: false,
            passwordSpecial: false,
            passwordValid: false
        };
    }

    componentDidMount() {
        fetch('https://api.tasselpathway.com/grades', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        grades: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    register() {

        fetch('https://api.tasselpathway.com/register_student', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                grade_id: this.state.gradeId,
                email_address: this.state.userName,
                password: this.state.password,
                invite_token: this.state.inviteToken
            })
        })
            .then(response => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.error === false) {

                    this.setState({
                        invalidToken: false,
                        invalidUserName: false
                    })

                    this.props.history.replace('/');
                    this.props.onLogin(this.state.userName, this.state.password, this.props.selectedLanguage);

                } else if (responseJson.message === "Person Email Already Registered!") {
                    this.setState({
                        invalidToken: false,
                        invalidUserName: true
                    })
                } else {
                    this.setState({
                        invalidToken: true,
                        invalidUserName: false
                    })
                }
            })
            .catch((e) => {
                console.warn(e);
            });
    }

    onRegisterClick() {
        var firstNameValid = true;
        var lastNameValid = true;
        var userNameValid = true;
        var inviteTokenValid = true;
        var passwordValid = this.state.passwordValid;
        if (this.state.firstName === "") {
            firstNameValid = false;
        }

        if (this.state.lastName === "") {
            lastNameValid = false;
        }

        if (this.state.userName === "") {
            userNameValid = false;
        }

        if (this.state.inviteToken === "") {
            inviteTokenValid = false;
        }

        this.setState({
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            userNameValid: userNameValid,
            inviteTokenValid: inviteTokenValid
        })

        if (firstNameValid && lastNameValid && userNameValid && inviteTokenValid && passwordValid) {
            this.setState({
                hasError: false
            })

            this.register();

        } else {
            this.setState({
                hasError: true
            })
        }
    }

    onChangePassword(event) {

        this.validatePassword(this.state.confirmPassword, event.target.value);

        this.setState({
            password: event.target.value
        })
    }

    onChangeConfirmPassword(event) {

        this.validatePassword(event.target.value, this.state.password)

        this.setState({
            confirmPassword: event.target.value
        })
    }

    validatePassword(confirmPassword, password) {

        var isValid = true;

        if (confirmPassword === password && password !== "" && confirmPassword !== "") {
            this.setState({
                passwordMatch: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordMatch: false
            })
        }

        if (password.length >= 8) {
            this.setState({
                passwordLength: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordLength: false
            })
        }

        var capital = /[A-Z]/;
        var number = /[0-9]/;
        var special = /[!@#$%\^&*)(+=._-]/;

        if (password.match(capital)) {
            this.setState({
                passwordCapital: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordCapital: false
            })
        }

        if (password.match(number)) {
            this.setState({
                passwordNumber: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordNumber: false
            })
        }

        if (password.match(special)) {
            this.setState({
                passwordSpecial: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordSpecial: false
            })
        }

        if (isValid) {
            this.setState({
                passwordValid: true
            })
        } else {
            this.setState({
                passwordValid: false
            })
        }
    }

    renderGrades() {

        var content = this.state.grades.map((item, key) => {
            return (
                <MenuItem key={key} value={item.grade_id}>{item.grade_name}</MenuItem>
            )
        })

        return content;
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    render() {

        const { classes } = this.props;

        return (

            <Grid container spacing={0}
                direction="column" alignItems="center"
                justify="center" style={{ backgroundColor: '#1F3545' }}>

                <Grid style={styles.container}>

                    <img width="100" src={require('../../assets/logo_icon.png')} alt="Tassel" />

                    <h3 style={{ color: '#2A2042', marginTop: 20, fontSize: 24 }}>Register Your Tassel Account</h3>

                    {this.state.invalidUserName ?

                        <Grid container style={{ backgroundColor: '#D55648', padding: 10, color: '#FFF' }}>
                            <Grid item xs={1}>
                                <WarningIcon style={{ marginTop: 10 }} />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>There was an issue registering your account, <br /> It looks you already have an account!</Typography>
                            </Grid>
                        </Grid>

                        : null
                    }

                    {this.state.invalidToken ?

                        <Grid container style={{ backgroundColor: '#D55648', padding: 10, color: '#FFF' }}>
                            <Grid item xs={1}>
                                <WarningIcon style={{ marginTop: 10 }} />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>There was an issue registering your account, <br />It looks that token is invalid!</Typography>
                            </Grid>
                        </Grid>

                        : null
                    }

                    <form style={{ marginTop: 40 }}>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl style={{ width: '100%' }} error={!this.state.firstNameValid}>
                                    <TextField id="input-with-icon-adornment" label="First Name"
                                        style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                        autoFocus={true}
                                        value={this.state.firstName}
                                        error={!this.state.firstNameValid}
                                        className={classes.textField}
                                        onChange={this.handleChange('firstName')} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl style={{ width: '100%' }} error={!this.state.lastNameValid}>
                                    <TextField id="input-with-icon-adornment" label="Last Name"
                                        style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                        value={this.state.lastName}
                                        error={!this.state.lastNameValid}
                                        className={classes.textField}
                                        onChange={this.handleChange('lastName')} />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>

                                <FormControl style={{ width: '100%', marginTop: -18 }} error={!this.state.inviteTokenValid}>
                                    <TextField id="input-with-icon-adornment" label="Invite Token"
                                        style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                        value={this.state.inviteToken}
                                        error={!this.state.inviteTokenValid}
                                        className={classes.textField}
                                        onChange={this.handleChange('inviteToken')} />
                                </FormControl>

                            </Grid>

                            <Grid item xs={6}>

                                <FormControl className={classes.formControl}
                                    fullWidth style={{ marginBottom: 10, marginTop: -2 }}>
                                    <Select
                                        value={this.state.gradeId}
                                        style={{ width: '100%', textAlign: 'left', color: '#777' }}
                                        onChange={(event) => this.setState({ gradeId: event.target.value })}
                                        required={true}
                                        inputProps={{
                                            name: 'gradeId',
                                            id: 'gradeId',
                                        }}>
                                        <MenuItem disabled value={0}>Select Current Grade</MenuItem>
                                        {this.renderGrades()}
                                    </Select>
                                </FormControl>

                            </Grid>
                        </Grid>

                        <FormControl style={{ width: '100%' }} error={!this.state.userNameValid}>
                            <TextField id="input-with-icon-adornment" label="Email Address"
                                style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                value={this.state.userName}
                                error={!this.state.userNameValid}
                                className={classes.textField}
                                onChange={this.handleChange('userName')} />
                        </FormControl>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>

                                <FormControl style={{ width: '100%' }}>
                                    <TextField id="input-with-icon-adornment" label="Password"
                                        style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                        type="password"
                                        className={classes.textField}
                                        onChange={(event) => this.onChangePassword(event)} />
                                </FormControl>

                            </Grid>

                            <Grid item xs={6}>

                                <FormControl style={{ width: '100%' }}>
                                    <TextField id="input-with-icon-adornment" label="Confirm Password"
                                        style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                        type="password"
                                        className={classes.textField}
                                        onChange={(event) => this.onChangeConfirmPassword(event)} />
                                </FormControl>

                            </Grid>

                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <Grid container>
                                    {this.state.passwordCapital ?
                                        <CheckIcon style={{ color: '#25D192' }} /> :
                                        <CloseIcon style={{ color: '#D55648' }} />}
                                    <Typography style={{ marginLeft: 10, fontSize: 16 }}>Capital Letter</Typography>
                                </Grid>
                                <Grid container>
                                    {this.state.passwordNumber ?
                                        <CheckIcon style={{ color: '#25D192' }} /> :
                                        <CloseIcon style={{ color: '#D55648' }} />}
                                    <Typography style={{ marginLeft: 10, fontSize: 16 }}>Number</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    {this.state.passwordSpecial ?
                                        <CheckIcon style={{ color: '#25D192' }} /> :
                                        <CloseIcon style={{ color: '#D55648' }} />}
                                    <Typography style={{ marginLeft: 10, fontSize: 16 }}>Special Character</Typography>
                                </Grid>
                                <Grid container>
                                    {this.state.passwordLength ?
                                        <CheckIcon style={{ color: '#25D192' }} /> :
                                        <CloseIcon style={{ color: '#D55648' }} />}
                                    <Typography style={{ marginLeft: 10, fontSize: 16 }}>8+ Characters</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <Grid container>
                                    {this.state.passwordMatch ?
                                        <CheckIcon style={{ color: '#25D192' }} /> :
                                        <CloseIcon style={{ color: '#D55648' }} />}
                                    <Typography style={{ marginLeft: 10, fontSize: 16 }}>Passwords Match</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                        </Grid>

                        <div style={{ marginTop: 20, textAlign: 'center' }} >
                            {this.state.gradeId === 0 ?
                                <Button disabled style={styles.loginButton}>
                                    Create Account
                                </Button>
                                :
                                <Button style={styles.registrationComplete} onClick={() => this.onRegisterClick()}>
                                    Create Account
                                </Button>
                            }
                        </div>

                    </form>

                </Grid>

                <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, fontWeight: 'bold', color: '#FFF' }}>
                    © 2019 Tassel, LLC | <a style={{ color: '#FFF' }} target="_blank" rel="noopener noreferrer" href="https://www.tasselpathway.com/terms">Terms of Use</a>
                </Grid>
            </Grid>
        )
    }
}

RegisterScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        language: state.language.selectedLanguage,
        hasError: state.auth.hasError,
        errorMessage: state.auth.errorMessage
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (username, password, language) => { dispatch(login(username, password, language)); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(RegisterScreen)));