import { GET_LANGUAGE, SET_LANGUAGE  } from "../actions";

export const defaultState = {
    selectedLanguage: 'en'
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case GET_LANGUAGE:
            return Object.assign({}, state, {
            });
        case SET_LANGUAGE:
            return Object.assign({}, state, {
                selectedLanguage: action.selectedLanguage
            });
        default:
            return state
    }
}