import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';
class AdmissionScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      collegeId: 0
    }
  }

  componentDidMount() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/college_admission/' + this.props.collegeId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          var acceptanceRate = 0

          if (responseJson.results[0].acceptance_rate > 0) {
            acceptanceRate = (responseJson.results[0].acceptance_rate * 100).toFixed(0);
          }
          this.setState({
            isLoading: false,
            admissionId: responseJson.results[0].admission_id,
            collegeId: responseJson.results[0].college_id,
            collegeName: responseJson.results[0].college_name,
            collegeTypeName: responseJson.results[0].college_type_name,
            collegeSettingName: responseJson.results[0].college_setting_name,
            totalApplicant: responseJson.results[0].total_applicant,
            acceptanceRate: acceptanceRate,
            averageACTScore: responseJson.results[0].average_act_score,
            averageGPA: responseJson.results[0].average_gpa,
            applicationFee: responseJson.results[0].application_fee,
            earlyDeadlineOne: responseJson.results[0].early_deadline_one,
            earlyDeadlineTwo: responseJson.results[0].early_deadline_two,
            regularDeadline: responseJson.results[0].regular_deadline
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#1F3545', marginBottom: 10 }}>{i18n.t("COLLEGE_PROFILE_ADMISSIONS")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 30 }}>
                {this.state.collegeTypeName !== undefined ?
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ADMISSIONS_TYPE")}</Typography>
                    <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{this.state.collegeTypeName}</Typography>
                  </Grid>
                  :
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ADMISSIONS_TYPE")}</Typography>
                    <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>N/A</Typography>
                  </Grid>
                }

                {this.state.collegeLevelName !== undefined ?
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ADMISSIONS_INSTITUTION_LEVEL")}</Typography>
                    <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{this.state.collegeLevelName}</Typography>
                  </Grid>
                  :
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ADMISSIONS_INSTITUTION_LEVEL")}</Typography>
                    <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>N/A</Typography>
                  </Grid>
                }

                {this.state.collegeSettingName !== undefined ?
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ADMISSIONS_CAMPUS")}</Typography>
                    <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{this.state.collegeSettingName}</Typography>
                  </Grid>
                  :
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ADMISSIONS_CAMPUS")}</Typography>
                    <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>N/A</Typography>
                  </Grid>
                }
              </Grid>

              <Grid container style={{ marginTop: 50, marginBottom: 0, paddingBottom: 0 }}>
                {this.state.totalApplicant !== 0 ?
                  <Grid item xs={4} style={{ textAlign: 'center', marginTop: 30 }}>
                    <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ADMISSIONS_STUDENTS")}</Typography>
                    <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{this.state.totalApplicant}</Typography>
                  </Grid>
                  :
                  <Grid item xs={4} style={{ textAlign: 'center', marginTop: 30 }}>
                    <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ADMISSIONS_STUDENTS")}</Typography>
                    <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>N/A</Typography>
                  </Grid>
                }

                {this.state.acceptanceRate !== 0 ?
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <div style={{ marginLeft: 45, width: 150 }}>
                      <CircularProgressbar text={`${this.state.acceptanceRate}%`}
                        value={this.state.acceptanceRate} circleRatio={0.5}
                        styles={buildStyles({
                          rotation: 0.75, strokeLinecap: 'butt',
                          textSize: '20px', pathTransitionDuration: 0.5,
                          pathColor: `#25D192`,
                          textColor: '#000',
                          trailColor: '#d6d6d6',
                          backgroundColor: '#000'
                        })} />
                      <Typography style={{ marginTop: -60, fontWeight: 'bold' }}>{i18n.t("COLLEGE_ADMISSIONS_ACCEPTANCE")}</Typography>
                    </div>
                  </Grid>
                  : null}

                <Grid item xs={4}>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

AdmissionScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdmissionScreen));