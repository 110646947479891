import {
  Avatar,
  Grid,
  List, ListItem, ListItemAvatar,
  ListItemText, Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';

class MajorRelatedScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: []
    }
  }

  componentDidMount() {
    this.getData(this.props.majorId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.majorId);
  }

  getData(majorId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/majors/related/' + majorId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onMajorClick(value) {
    this.props.props.history.push('/major/' + value);
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Grid item xs={12}>

            <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', marginTop: 20, color: '#4D4D4F' }}>{i18n.t("MAJORS_RELATED")}</Typography>

            <List style={{ overflow: 'auto', maxHeight: 300 }}>

              {this.state.dataSource.map((item, key) => {
                return (

                  <ListItem key={key} button onClick={() => this.onMajorClick(item.major_id)}>
                    <ListItemAvatar>
                      <Avatar alt="MSU" style={{ height: 55, width: 55 }} src={item.logo_image_url} />
                    </ListItemAvatar>
                    <ListItemText style={{ fontSize: 16, marginLeft: 15 }} primary={item.major_name} />
                  </ListItem>

                )
              })}

            </List>

          </Grid>
        </Grid>

      </div>
    );
  }
}

MajorRelatedScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    majorId: state.major.majorId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MajorRelatedScreen)));