import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase, ListItem, ListItemAvatar, ListItemText,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';
import './styles.css';

var timeout = null;

class CareerListScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      searchText: '',
      dataSource: [],
      clusterList: [],
      filterList: [],
      pathwayList: [],
      clusters: [],
      pathways: [],
      clusteredExpanded: false,
      card: [],
      totalRows: 0,
      perPage: 50,
      pageNumber: 0,
      checkboxList: []
    }

    this.onSearch = this.onSearch.bind(this);
    this.onClusterCheck = this.onClusterCheck.bind(this);
    this.onPathwayCheck = this.onPathwayCheck.bind(this);
  }

  updateDimensions() {
    let update_height = window.innerHeight - 200;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    var storedClusters = JSON.parse(localStorage.getItem("localStorageFilters"));
    if (this.props.history.location.state !== undefined && storedClusters !== null) {
      if (storedClusters !== '' || storedClusters !== null || storedClusters.length > 0) {
        var clusterList = storedClusters;
        clusterList.push(this.props.history.location.state.clusterId);
        this.setState({
          clusterList: clusterList
        })
      }
      else {
        clusterList = this.state.clusterList;
        clusterList.push(this.props.history.location.state.clusterId);
        this.setState({
          clusterList: clusterList
        })
      }
    }

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.updateDimensions();

    this.setState({
      isLoading: true
    })

    this.props.setProgress(0);

    fetch('https://api.tasselpathway.com/clusters', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            clusters: responseJson.results
          })
        }

        this.props.setProgress(25);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/pathway', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            pathways: responseJson.results
          })
        }

        this.props.setProgress(25);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
    this.getData(this.state.searchText, this.state.clusterList, this.state.pathwayList, this.state.perPage, this.state.pageNumber)
  }

  getData(searchText, clusterList, pathwayList, perPage, pageNumber) {

    var storedClusters = JSON.parse(localStorage.getItem("localStorageFilters"));
    var storedPathway = JSON.parse(localStorage.getItem("localStorageFiltersPathways"));

    if (storedClusters !== null && storedPathway !== null) {

      fetch('https://api.tasselpathway.com/careers?search=' + searchText + '&cluster='
        + storedClusters.join() + '&pathway=' + storedPathway.join() + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })
        });
    }
    else if (storedClusters !== null) {
      fetch('https://api.tasselpathway.com/careers?search=' + searchText + '&cluster='
        + storedClusters.join() + '&pathway=' + pathwayList.join() + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })
        });
    }
    else if (storedPathway !== null) {
      fetch('https://api.tasselpathway.com/careers?search=' + searchText + '&cluster='
        + clusterList.join() + '&pathway=' + storedPathway.join() + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })
        });
    }

    else if (this.props.location.state !== undefined) {

      const clickedCLuster = this.props.location.state.clusterId

      fetch('https://api.tasselpathway.com/careers?search=' + searchText + '&cluster='
        + clickedCLuster + '&pathway=' + pathwayList.join() + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })
        });
    }

    else {
      fetch('https://api.tasselpathway.com/careers?search=' + searchText + '&cluster='
        + clusterList.join() + '&pathway=' + pathwayList.join() + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })
        });
    }
  }

  onClusterCheck = (event, clusterId) => {
    if (event.target.checked !== undefined) {
      var clusterList = this.state.clusterList;
      var filterList = this.state.filterList;

      if (event.target.checked) {
        clusterList['choice_' + clusterId] = 1;

        filterList.push(clusterId)

      } else {
        clusterList['choice_' + clusterId] = 0;

        for (var i = 0; i < filterList.length; i++) {
          if (filterList[i] === clusterId) {
            filterList.splice(i, 1);
          }
        }
      }

      this.setState({
        clusterList: clusterList,
        filterList: filterList,
        pageNumber: 0
      })

      localStorage.setItem('localStorageFilters', JSON.stringify(filterList));

      this.getData(this.state.searchText, filterList, this.state.pathwayList, this.state.perPage, 0);
    }
  }


  onPathwayCheck(event, pathwayId) {
    if (event.target.checked !== undefined) {
      var pathwayList = this.state.pathwayList;
      var filterList = this.state.filterList;

      if (event.target.checked) {
        pathwayList['choice_' + pathwayId] = 1;

        filterList.push(pathwayId)

      } else {
        pathwayList['choice_' + pathwayId] = 0;

        for (var i = 0; i < filterList.length; i++) {
          if (filterList[i] === pathwayId) {
            filterList.splice(i, 1);
          }
        }
      }

      this.setState({
        pathwayList: pathwayList,
        filterList: filterList,
        pageNumber: 0
      })

      localStorage.setItem('localStorageFiltersPathways', JSON.stringify(filterList));

      this.getData(this.state.searchText, this.state.clusterList, filterList, this.state.perPage, 0);
    }

  }

  onSearch(event) {

    var searchText = event.target.value;

    this.setState({
      searchText: searchText,
      pageNumber: 0
    })

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.getData(searchText, scope.state.clusterList, scope.state.pathwayList, scope.state.perPage, scope.state.pageNumber)
    }, 1000);

  }

  onPreviousClick() {
    var currentPage = this.state.pageNumber;

    if (currentPage > 0) {

      currentPage = currentPage - 1;

      this.setState({
        pageNumber: currentPage
      })
    }

    this.getData(this.state.searchText, this.state.clusterList, this.state.pathwayList, this.state.perPage, currentPage);
  }

  onNextClick() {
    var currentPage = this.state.pageNumber;

    currentPage = currentPage + 1;

    this.setState({
      pageNumber: currentPage
    })

    this.getData(this.state.searchText, this.state.clusterList, this.state.pathwayList, this.state.perPage, currentPage);
  }

  render() {
    const { classes } = this.props;
    const storedClusters = JSON.parse(localStorage.getItem("localStorageFilters"));
    const storedPathway = JSON.parse(localStorage.getItem("localStorageFiltersPathways"));

    return (
      <div className={classes.root}>

        <Grid container style={styles.itemContainerStyle}>
          <Grid item xs={12} style={{ marginLeft: 100, marginRight: 100 }}>

            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 24, color: '#333', fontWeight: 'bold' }}>{i18n.t("CAREERS_TITLE")}</Typography>
              </Grid>
              <Grid item xs={3}>
                {((this.state.pageNumber * this.state.perPage) + 1) > (this.state.totalRows - 50) ?
                  <Typography style={{ fontSize: 14, color: '#333', marginLeft: 20, marginTop: 30, fontWeight: 'bold' }}>
                    {i18n.t("CAREERS_SHOWING")} {(this.state.pageNumber * this.state.perPage) + 1} - {this.state.totalRows} {i18n.t("CAREERS_OF")}  {this.state.totalRows} {i18n.t("CAREERS_RESULTS")}</Typography>
                  :
                  <Typography style={{ fontSize: 14, color: '#333', marginLeft: 20, marginTop: 30, fontWeight: 'bold' }}>
                    {i18n.t("CAREERS_SHOWING")} {(this.state.pageNumber * this.state.perPage) + 1} - {(this.state.pageNumber + 1) *
                      this.state.dataSource.length}  {i18n.t("CAREERS_OF")} {this.state.totalRows} {i18n.t("CAREERS_RESULTS")}</Typography>
                }
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                {this.props.selectedLanguage === "esp" ?
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={this.onSearch} style={{ width: '100%' }}
                      placeholder="Buscar"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                  :
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={this.onSearch} style={{ width: '100%' }}
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                }
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'right', marginTop: 20 }}>
                <IconButton
                  disabled={this.state.pageNumber > 0 ? false : true}
                  onClick={() => this.onPreviousClick()}>
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton style={{ marginRight: 5 }}
                  disabled={(((this.state.pageNumber * this.state.perPage) + 1) > (this.state.totalRows - 50)) ? true : false}
                  onClick={() => this.onNextClick()}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container>

              <Grid item xs={2}>

                <Accordion square expanded={this.state.clusteredExpanded} classes={{ expanded: classes.expanded }}
                  onChange={() => this.setState({ clusteredExpanded: !this.state.clusteredExpanded })}
                  style={{ marginLeft: -25, backgroundColor: '#eaeaea', boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>{i18n.t("CAREER_CLUSTERS")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                    <FormControl component="fieldset" className={classes.formControl}
                      style={{
                        height: 400, borderBottom: '1px solid #eaeaea', marginLeft: 5, marginBottom: 20,
                        paddingLeft: 10, width: '100%', overflow: 'auto'
                      }}>
                      <FormGroup>

                        {storedClusters !== null ?
                          this.state.clusters.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onChange={(event) => this.onClusterCheck(event, item.cluster_id)}
                                  control={
                                    <Checkbox checked={storedClusters.includes(item.cluster_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.cluster_name_spanish}</Typography>}
                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onChange={(event) => this.onClusterCheck(event, item.cluster_id)}
                                  control={
                                    <Checkbox checked={storedClusters.includes(item.cluster_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.cluster_name}</Typography>}
                                />
                              )
                            }
                          })
                          :
                          this.state.clusters.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onChange={(event) => this.onClusterCheck(event, item.cluster_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.cluster_name_spanish}</Typography>}
                                />
                              )
                            }
                            else {
                              return (
                                <FormControlLabel key={key} onChange={(event) => this.onClusterCheck(event, item.cluster_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.cluster_name}</Typography>}
                                />
                              )
                            }
                          })

                        }

                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                <Accordion square expanded={this.state.pathwayExpanded} classes={{ expanded: classes.expanded }}
                  onChange={() => this.setState({ pathwayExpanded: !this.state.pathwayExpanded })}
                  style={{ marginLeft: -25, backgroundColor: '#eaeaea', boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>{i18n.t("CAREER_PATHWAYS")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                    <FormControl component="fieldset" className={classes.formControl}
                      style={{
                        height: 400, borderBottom: '1px solid #eaeaea', marginLeft: 5, marginBottom: 20,
                        paddingLeft: 10, width: '100%', overflow: 'auto'
                      }}>
                      <FormGroup>

                        {storedPathway !== null ?
                          this.state.pathways.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onChange={(event) => this.onPathwayCheck(event, item.pathway_id)}
                                  control={
                                    <Checkbox checked={storedPathway.includes(item.pathway_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.pathway_name_spanish}</Typography>}
                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onChange={(event) => this.onPathwayCheck(event, item.pathway_id)}
                                  control={
                                    <Checkbox checked={storedPathway.includes(item.pathway_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.pathway_name}</Typography>}
                                />
                              )
                            }
                          })
                          :
                          this.state.pathways.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onChange={(event) => this.onPathwayCheck(event, item.pathway_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.pathway_name_spanish}</Typography>}
                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onChange={(event) => this.onPathwayCheck(event, item.pathway_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.pathway_name}</Typography>}
                                />
                              )
                            }
                          })}

                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

              </Grid>

              <Grid item xs={10}>

                <Grid container style={{ overflowX: 'auto', height: this.state.height, paddingLeft: 20, paddingRight: 20 }}>

                  {this.state.dataSource.map((item, key) => {

                    var link = "/career/" + item.career_id;

                    return (

                      <Grid item md={6} lg={4} xl={4} sm={6} xs={6} key={key}>
                        <Card style={{ margin: 5, height: 140, borderRadius: 3 }} className='hovered'>
                          <ListItem alignItems="flex-start" component={Link} to={link}>
                            <ListItemAvatar>
                              <Avatar alt="MSU" style={{ height: 75, width: 75, marginTop: 18 }} src={item.logo_image_url} />
                            </ListItemAvatar>
                            <ListItemText
                              style={{ marginTop: 20, marginLeft: 15 }}
                              primary={
                                <React.Fragment>
                                  {item.career_name.length > 30 ?
                                    <Typography component="span"
                                      style={{ fontSize: 13, fontWeight: 'bold', color: '#1F3545' }}>
                                      {item.career_name}
                                    </Typography>
                                    :
                                    <Typography component="span"
                                      style={{ fontSize: 15, fontWeight: 'bold', color: '#1F3545' }}>
                                      {item.career_name}
                                    </Typography>
                                  }
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  {item.cluster_name.length > 30 || item.career_name.length > 30 ?
                                    <Typography component="span" color="textPrimary"
                                      style={{ fontSize: 13 }}>
                                      {item.cluster_name}
                                    </Typography>
                                    :
                                    <Typography component="span" color="textPrimary"
                                      style={{ fontSize: 15 }}>
                                      {item.cluster_name}
                                    </Typography>
                                  }
                                </React.Fragment>
                              }
                            />

                          </ListItem>
                        </Card>
                      </Grid>
                    )
                  })}

                </Grid>
                {this.props.selectedLanguage === "esp" ?
                  <Typography style={{ textAlign: 'center', marginTop: 10, fontSize: 8 }}>
                    Esta página incluye información del <a href="https://www.onetcenter.org/"> O * NET Resource Center </a>
                    por el Departamento de Trabajo, Administración de Empleo y Capacitación de los Estados Unidos (USDOL / ETA). Usado bajo el
                    <a href="https://creativecommons.org/licenses/by/4.0/"> licencia CC BY 4.0 </a>.
                  </Typography>
                  :
                  <Typography style={{ textAlign: 'center', marginTop: 10, fontSize: 8 }}>
                    This page includes information from <a href="https://www.onetcenter.org/">O*NET Resource Center</a>
                    by the U.S. Department of Labor, Employment and Training Administration (USDOL/ETA). Used under the
                    <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a> license.
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CareerListScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerListScreen));