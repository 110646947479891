import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class MyGradeBookScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      filteredDataSource: [],
      tasks: [],
      classes: [],
      selectedItem: {},
      searchText: '',
      selectedClass: 0,
      selectedTask: 0,
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      schoolYear: '',
      schoolYearDataSource: [],
      sortDirection: 'desc'
    }

    this.onGradeClose = this.onGradeClose.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);

  }

  updateDimensions() {
    let update_height = window.innerHeight - 300;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {
    var schoolYear = ''
    window.addEventListener("resize", this.updateDimensions.bind(this));

    fetch('https://api.tasselpathway.com/dashboard/years', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.authenticated) {
          for (var i = 0; i < responseJson.years.length; i++) {

            if (responseJson.years[i].year_value === parseInt(responseJson.selected_year)) {
              schoolYear = responseJson.years[i].year_name
            }
          }
          this.setState({
            schoolYear: schoolYear,
            schoolYearDataSource: responseJson.years.reverse()
          });
        }
        this.getData(this.state.searchText, this.state.selectedTask, this.state.selectedClass, schoolYear);

      })


    fetch('https://api.tasselpathway.com/grade_book/tasks/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            tasks: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch("https://api.tasselpathway.com/custom_groups/" + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            classes: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    this.updateDimensions();
  }

  getData(searchText, taskId, classId, schoolYear) {

    var split = schoolYear.split('-')
    var start = split[0].split(' ')
    var end = split[1].split(' ')

    fetch(`https://api.tasselpathway.com/grade_book/${parseInt(start[0])}/${parseInt(end[1])}?person_id=` + this.props.personId + '&search=' + searchText + '&task_id=' + taskId + '&class_id=' + classId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
            filteredDataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onIncorrectClick(row) {

    fetch('https://api.tasselpathway.com/profile_tasks', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        status_id: 2,
        educator_text: row.educator_text,
        profile_task_id: row.profile_task_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false
          })
          this.getData(this.state.searchText, this.state.selectedTask, this.state.selectedClass, this.state.schoolYear);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onCorrectClick(row) {

    fetch('https://api.tasselpathway.com/profile_tasks', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        status_id: 3,
        educator_text: row.educator_text,
        profile_task_id: row.profile_task_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false
          })
          this.getData(this.state.searchText, this.state.selectedTask, this.state.selectedClass, this.state.schoolYear);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onSaveGrade(status_id) {

    var selectedItem = this.state.selectedItem;

    fetch('https://api.tasselpathway.com/profile_tasks', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        status_id: status_id,
        educator_text: selectedItem.educator_text,
        profile_task_id: selectedItem.profile_task_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            isGradeOpen: false
          })
          this.getData(this.state.searchText, this.state.selectedTask, this.state.selectedClass, this.state.schoolYear);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isGradeOpen: false
        })
      });
  }

  onSearch(searchText) {
    this.setState({
      searchText: searchText,
      currentPage: 0
    })

    this.getData(searchText, this.state.selectedTask, this.state.selectedClass, this.state.schoolYear)
  }

  onTaskChange(taskId) {

    this.setState({
      selectedTask: taskId,
      currentPage: 0
    })

    this.getData(this.state.searchText, taskId, this.state.selectedClass, this.state.schoolYear)
  }

  onClassChange(classId) {

    this.setState({
      selectedClass: classId,
      currentPage: 0
    })

    this.getData(this.state.searchText, this.state.selectedTask, classId, this.state.schoolYear)
  }

  onSchoolYearChange = (schoolYear) => {

    this.setState({
      schoolYear: schoolYear,
      currentPage: 0
    })

    this.getData(this.state.searchText, this.state.selectedTask, this.state.selectedClass, schoolYear)
  }

  onGradeClick(item) {
    this.setState({
      isGradeOpen: true,
      selectedItem: item
    })
  }

  onGradeClose() {
    this.setState({
      isGradeOpen: false
    })
  }

  onSortClick(col) {
    var sortBy = col;
    var sortDirection = this.state.sortBy === col ? invertSort[this.state.sortDirection] : 'desc';

    this.setState({
      sortBy: sortBy,
      sortDirection: sortDirection
    })
  }

  onEducatorTextChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.educator_text = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  render() {
    const {
      schoolYear
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Grid container style={styles.itemContainerStyle}>

          <Dialog open={this.state.isGradeOpen}
            onClose={this.onGradeClose} maxWidth="sm"
            fullWidth={true}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onGradeClose}>
              {i18n.t("TASKS_GRADE_QUESTION")}
            </DialogTitle>

            <DialogContent>

              <FormControl disabled fullWidth style={{ marginTop: 10 }}>
                <InputLabel htmlFor="first_name">{i18n.t("TASKS_STUDENT_NAME")}</InputLabel>
                <Input variant="outline" fullWidth disabled
                  value={this.state.selectedItem.first_name + " " + this.state.selectedItem.last_name}
                  id="first_name" aria-describedby="my-helper-text" />
              </FormControl>

              <FormControl disabled fullWidth style={{ marginTop: 10 }}>
                <InputLabel htmlFor="first_name">{i18n.t("TASKS_TABLE_TASK_NAME")}</InputLabel>
                <Input variant="outline" fullWidth disabled
                  value={this.state.selectedItem.task_name}
                  id="first_name" aria-describedby="my-helper-text" />
              </FormControl>

              <FormControl disabled fullWidth style={{ marginTop: 10 }}>
                <InputLabel htmlFor="answer_text">{i18n.t("TASKS_TITLE")}</InputLabel>
                <Input variant="outline" fullWidth disabled rows={4} multiline
                  value={this.state.selectedItem.task_description}
                  id="answer_text" aria-describedby="my-helper-text" />
              </FormControl>

              <FormControl disabled fullWidth style={{ marginTop: 10 }}>
                <InputLabel htmlFor="answer_text">{i18n.t("TASKS_STUDENT_ANSWER")}</InputLabel>
                <Input variant="outline" fullWidth disabled rows={4} multiline
                  value={this.state.selectedItem.answer_text}
                  id="answer_text" aria-describedby="my-helper-text" />
              </FormControl>

              <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel htmlFor="educator_text">{i18n.t("TASKS_EDUCATOR_RESPONSE")}</InputLabel>
                <Input variant="outline" fullWidth multiline rows={4}
                  value={this.state.selectedItem.educator_text}
                  onChange={(event) => this.onEducatorTextChange(event)}
                  id="educator_text" aria-describedby="my-helper-text" />
              </FormControl>

            </DialogContent>

            <DialogActions>
              <Button variant="contained" onClick={() => this.onSaveGrade(2)}
                style={{ color: '#FFF', marginRight: 20, fontWeight: 'bold', borderRadius: 0 }} color="secondary">
                {i18n.t("TASKS_INCORRECT")}
              </Button>
              <Button variant="contained" onClick={() => this.onSaveGrade(3)}
                style={{ color: '#FFF', marginRight: 20, fontWeight: 'bold', borderRadius: 0 }} color="primary">
                {i18n.t("TASKS_CORRECT")}
              </Button>
            </DialogActions>
          </Dialog>

          <Grid item xs={12}>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="className">School Year</InputLabel>

                  <Select style={{ fontSize: 16, marginTop: 25 }}
                    value={schoolYear}
                    onChange={(evt) => this.onSchoolYearChange(evt.target.value)}
                    placeholder="Select school year"
                    inputProps={{
                      name: 'className',
                      id: 'className',
                    }}>

                    {this.state.schoolYearDataSource.map((item, key) => {
                      return (
                        <MenuItem value={item.year_name}>{item.year_name}</MenuItem>
                      )
                    })}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="taskName">Task Name</InputLabel>

                  <Select style={{ fontSize: 16, marginTop: 25 }}
                    value={this.state.selectedTask}
                    onChange={(evt) => this.onTaskChange(evt.target.value)}
                    placeholder="Select Task"
                    inputProps={{
                      name: 'taskName',
                      id: 'taskName',
                    }}>

                    <MenuItem value={0}>All Tasks</MenuItem>

                    {this.state.tasks.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.task_id}>{item.task_name}</MenuItem>
                      )
                    })}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ marginLeft: 10 }} className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="className">Class Name</InputLabel>

                  <Select style={{ fontSize: 16, marginTop: 25 }}
                    value={this.state.selectedClass}
                    onChange={(evt) => this.onClassChange(evt.target.value)}
                    placeholder="Select Class"
                    inputProps={{
                      name: 'className',
                      id: 'className',
                    }}>

                    <MenuItem value={0}>All Classes</MenuItem>

                    {this.state.classes.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.custom_group_id}>{item.name}</MenuItem>
                      )
                    })}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'right' }}>
                {this.props.selectedLanguage === "esp" ?
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={(evt) => this.onSearch(evt.target.value)} style={{ width: '100%' }}
                      placeholder="Buscar"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                  :
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={(evt) => this.onSearch(evt.target.value)} style={{ width: '100%' }}
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                }
              </Grid>
            </Grid>

            <Paper square style={{ marginTop: 20, borderRadius: 0 }}>
              <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                <Table className={classes.table} padding="dense">
                  <TableHead>
                    <TableRow>
                      <TableCell width={75} className={classes.head}>
                        <Grid container style={{ width: 200 }}>
                          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("first_name")}>
                            {this.state.sortBy === "first_name" ? (
                              <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>
                                <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                              </div>
                            ) :
                              <div style={{ marginTop: 10, width: 60 }}>
                                <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                              </div>
                            }
                            {this.state.sortBy === "first_name" ? (
                              this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                            ) : null}
                          </div>
                          {this.state.sortBy === "first_name" ?
                            <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                            : (this.state.sortBy === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                              : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                          }
                          <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("last_name")}>
                            {this.state.sortBy === "last_name" ? (
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                              </div>
                            ) :
                              <div style={{ marginTop: 10 }}>
                                <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                              </div>
                            }
                            {this.state.sortBy === "last_name" ? (
                              this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                            ) : null}
                          </div>
                        </Grid>
                      </TableCell>

                      <TableCell width={150} className={classes.head}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("task_name")}>
                          <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("TASKS_TABLE_TASK_NAME")}</Typography>
                          </div>
                          {this.state.sortBy === "task_name" ? (
                            this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                          ) : null}
                        </div>
                      </TableCell>

                      <TableCell className={classes.head}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("total_points")}>
                          <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("TASKS_TABLE_TOTAL_POINTS")}</Typography>
                          </div>
                          {this.state.sortBy === "total_points" ? (
                            this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                          ) : null}
                        </div>
                      </TableCell>

                      <TableCell className={classes.head}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("TASKS_TABLE_ANSWER")}</Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.head} style={{ zIndex: 1, width: 5 }}></TableCell>
                      <TableCell className={classes.head} style={{ zIndex: 1, width: 5 }}></TableCell>
                      <TableCell className={classes.head} style={{ zIndex: 1, width: 5 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                      .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                      .map((row, index) => {

                        return (
                          <TableRow style={{ cursor: 'pointer' }} hover key={index}>
                            <TableCell style={{ fontSize: 12 }}><Typography style={{ fontSize: 12 }}>{row.first_name} {row.last_name}</Typography></TableCell>
                            <TableCell style={{ fontSize: 12 }}><Typography style={{ fontSize: 12 }}>{row.task_name}</Typography></TableCell>
                            <TableCell style={{ fontSize: 12 }}>{row.total_points}</TableCell>
                            <TableCell style={{ fontSize: 12 }}>{row.answer_text}</TableCell>
                            {row.status_id === 1 ?
                              <TableCell style={{ width: 5, textAlign: 'center' }}>
                                <IconButton onClick={() => this.onCorrectClick(row)}>
                                  <CheckIcon className={this.props.classes.extendedIcon} style={{ color: '#203445' }} />
                                </IconButton>
                              </TableCell>
                              : null}
                            {row.status_id === 1 ?
                              <TableCell style={{ width: 5, textAlign: 'center' }}>
                                <IconButton onClick={() => this.onIncorrectClick(row)}>
                                  <CloseIcon className={this.props.classes.extendedIcon} style={{ color: '#203445' }} />
                                </IconButton>
                              </TableCell>
                              : null}
                            {row.status_id === 1 ?
                              <TableCell style={{ width: 5, textAlign: 'center' }}>
                                <IconButton onClick={() => this.onGradeClick(row)}>
                                  <ModeCommentIcon className={this.props.classes.extendedIcon}
                                    style={{ color: '#203445' }} />
                                </IconButton>
                              </TableCell>
                              : null}
                            {row.status_id === 2 ?
                              <TableCell style={{ width: 50, textAlign: 'center' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>Incorrect</Typography>
                              </TableCell>
                              : null}
                            {row.status_id === 2 ?
                              <TableCell style={{ width: 50, textAlign: 'center' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }}></Typography>
                              </TableCell>
                              : null}
                            {row.status_id === 2 ?
                              <TableCell style={{ width: 5, textAlign: 'center' }}>
                                <IconButton onClick={() => this.onGradeClick(row)}>
                                  <ModeCommentIcon className={this.props.classes.extendedIcon}
                                    style={{ color: '#203445' }} />
                                </IconButton>
                              </TableCell>
                              : null}
                            {row.status_id === 3 ?
                              <TableCell style={{ width: 50, textAlign: 'center' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>Passed</Typography>
                              </TableCell>
                              : null}
                            {row.status_id === 3 ?
                              <TableCell style={{ width: 50, textAlign: 'center' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }}></Typography>
                              </TableCell>
                              : null}
                            {row.status_id === 3 ?
                              <TableCell style={{ width: 5, textAlign: 'center' }}>
                                <IconButton onClick={() => this.onGradeClick(row)}>
                                  <ModeCommentIcon className={this.props.classes.extendedIcon}
                                    style={{ color: '#203445' }} />
                                </IconButton>
                              </TableCell>
                              : null}
                          </TableRow>
                        );
                      })}

                  </TableBody>
                </Table>

              </div>
              <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.currentPage}
                count={this.state.filteredDataSource.length}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />

            </Paper>

          </Grid>
        </Grid>
      </div>
    );
  }
}

MyGradeBookScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage,
    profileId: state.profile.profileId,
    schoolId: state.profile.schoolId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyGradeBookScreen)));
