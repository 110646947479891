import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class GoalScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      grades: [],

      isAchievedOpen: false,

      newGoalName: '',
      newGoalCategoryId: 0,
      newGradeId: 0,
      newGoalDescription: '',
      newDateModified: '',
      newGoalAchieved: 0,

      editName: '',
      editCategoryId: '',
      editDescription: '',

      newNameValid: true,
      newCategoryIdValid: true,
      newDescriptionValid: true,

      editNameValid: true,
      editCategoryIdValid: true,
      editDescriptionValid: true,

      selectedItem: {},
      categories: []
    }

    this.createGoal = this.createGoal.bind(this);
    this.saveGoal = this.saveGoal.bind(this);

    this.onNewClose = this.onNewClose.bind(this);
    this.onNewClick = this.onNewClick.bind(this);

    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onAchievedClose = this.onAchievedClose.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {
    this.getData(this.props.profileId);

    fetch('https://api.tasselpathway.com/profile_goal_category/', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            categories: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          })
        }

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  getData(personId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_goal/person/' + personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  createGoal() {

    fetch('https://api.tasselpathway.com/profile_goal', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId,
        goal_name: this.state.newGoalName,
        goal_category_id: this.state.newGoalCategoryId,
        goal_description: this.state.newGoalDescription,
        goal_achieved: this.state.newGoalAchieved,
        grade_id: this.state.newGradeId,
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.getData(this.props.personId);

        this.setState({
          isLoading: false
        })

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  saveGoal() {

    this.setState({
      isAchievedOpen: false
    })

    fetch('https://api.tasselpathway.com/profile_goal', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId,
        goal_name: this.state.selectedItem.goal_name,
        goal_description: this.state.selectedItem.goal_description,
        goal_category_id: this.state.selectedItem.goal_category_id,
        goal_achieved: this.state.selectedItem.goal_achieved,
        grade_id: this.state.selectedItem.grade_id,
        goal_id: this.state.selectedItem.goal_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.getData(this.props.personId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  deleteGoal() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_goal', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        goal_id: this.state.selectedItem.goal_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.personId);

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderGoals() {

    var content = this.state.dataSource.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {
        return (
          <ListItem key={key} alignItems="flex-start"
            button onClick={() => this.onEditOpen(item)}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                    className={this.props.classes.inline} color="textPrimary">
                    {item.goal_name} - {item.goal_category_name_spanish}
                  </Typography>
                  <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.goal_description}</Typography>
                </React.Fragment>
              }
            />
            {item.goal_achieved === 1 ?
              <ListItemSecondaryAction>
                <div style={{ marginRight: 15 }}>
                  <FontAwesomeIcon icon={faCheck} style={{ color: '#25D192', fontSize: 18 }} />
                </div>
              </ListItemSecondaryAction>
              :
              <ListItemSecondaryAction>
                <div style={{ marginRight: 15 }}>
                  <FontAwesomeIcon icon={faCheck} style={{ color: '#EAEAEA', fontSize: 18 }} />
                </div>
              </ListItemSecondaryAction>
            }
          </ListItem>
        )
      } else {
        return (
          <ListItem key={key} alignItems="flex-start"
            button onClick={() => this.onEditOpen(item)}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                    className={this.props.classes.inline} color="textPrimary">
                    {item.goal_name} - {item.goal_category_name}
                  </Typography>
                  <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.goal_description}</Typography>
                </React.Fragment>
              }
            />
            {item.goal_achieved === 1 ?
              <ListItemSecondaryAction>
                <div style={{ marginRight: 15 }}>
                  <FontAwesomeIcon icon={faCheck} style={{ color: '#25D192', fontSize: 18 }} />
                </div>
              </ListItemSecondaryAction>
              :
              <ListItemSecondaryAction>
                <div style={{ marginRight: 15 }}>
                  <FontAwesomeIcon icon={faCheck} style={{ color: '#EAEAEA', fontSize: 18 }} />
                </div>
              </ListItemSecondaryAction>
            }
          </ListItem>
        )
      }
    })

    return content;
  }

  onNewOpen() {
    this.setState({
      newGoalName: '',
      newGoalDescription: '',
      newGoalCategoryId: 0,
      newGoalAchieved: false,
      newGradeId: 0,
      newDate: Date(),
      isNewOpen: true
    })
  }

  onAchievedOpen(item) {
    this.setState({
      isAchievedOpen: true, selectedItem: item
    })
  }

  onAchievedClose() {
    this.setState({
      isAchievedOpen: false
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onNewClick() {
    this.createGoal();

    this.setState({
      isNewOpen: false
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onEditOpen(item) {
    this.setState({
      selectedItem: item,
      isEditOpen: true
    })
  }

  onEditClick() {
    this.saveGoal();

    this.setState({
      isEditOpen: false
    })
  }

  onDeleteClick() {
    this.deleteGoal();

    this.setState({
      isEditOpen: false
    })
  }

  onNameChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.goal_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onCategoryChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.goal_category_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onAchievedChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.goal_achieved = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDescriptionChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.goal_description = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDateChange(day) {
    this.setState({
      date: day
    })
  }

  renderCategories() {
    var content = this.state.categories.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {
        return (
          <MenuItem key={key} value={item.goal_category_id}>{item.goal_category_name_spanish}</MenuItem>
        )
      } else {
        return (
          <MenuItem key={key} value={item.goal_category_id}>{item.goal_category_name}</MenuItem>
        )
      }

    })

    return content;
  }

  handleChange(newDate) {
    this.setState({
      date: newDate
    });
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            {i18n.t("GOAL_NEW")}
          </DialogTitle>

          <DialogContent>

            <div>

              <Grid container>
                <Grid item xs={12}>

                  <Grid container>

                    <Grid item xs={8}>

                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="newGoalName">{i18n.t("GOAL_NAME")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.newGoalName}
                          onChange={(event) => this.setState({ newGoalName: event.target.value })}
                          id="newGoalName" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>

                    <Grid item xs={4}>

                      <FormControl className={classes.formControl}
                        fullWidth>
                        <InputLabel htmlFor="newGradeId">{i18n.t("GOAL_GRADE")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.newGradeId}
                          onChange={(event) => this.setState({ newGradeId: event.target.value })}
                          inputProps={{
                            name: 'newGradeId',
                            id: 'newGradeId',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("GOAL_SELECT_GRADE")}</MenuItem>
                          {this.state.grades.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <MenuItem key={key} value={item.grade_id}>{item.grade_name_spanish}</MenuItem>
                              )
                            } else {
                              return (
                                <MenuItem key={key} value={item.grade_id}>{item.grade_name}</MenuItem>
                              )
                            }

                          })}
                        </Select>
                      </FormControl>

                    </Grid>
                  </Grid>

                  <Grid container style={{ marginTop: 10 }}>

                    <Grid item xs={8}>
                      <FormControl className={classes.formControl}
                        fullWidth>
                        <InputLabel htmlFor="newGoalCategoryId">{i18n.t("GOAL_TYPE")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.newGoalCategoryId}
                          onChange={(event) => this.setState({ newGoalCategoryId: event.target.value })}
                          inputProps={{
                            name: 'newGoalCategoryId',
                            id: 'newGoalCategoryId',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("GOAL_SELECT_GOAL_TYPE")}</MenuItem>
                          {this.renderCategories()}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl className={classes.formControl}
                        fullWidth>
                        <InputLabel htmlFor="newGoalAchieved">{i18n.t("GOAL_ACHIEVED")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.newGoalAchieved}
                          onChange={(event) => this.setState({ newGoalAchieved: event.target.value })}
                          inputProps={{
                            name: 'newGoalAchieved',
                            id: 'newGoalAchieved',
                          }}>
                          <MenuItem value={0}>No</MenuItem>
                          <MenuItem value={1}>{i18n.t("YES")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel htmlFor="newGoalDescription">{i18n.t("GOAL_DESCRIPTION")}</InputLabel>
                    <Input variant="outline" multiline={true} rows={4} fullWidth
                      value={this.state.newGoalDescription}
                      onChange={(event) => this.setState({ newGoalDescription: event.target.value })}
                      id="newGoalDescription" aria-describedby="my-helper-text" />
                  </FormControl>

                </Grid>

              </Grid>

            </div>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 20, fontWeight: 'bold', color: '#FFF' }}
              onClick={this.onNewClick} color="primary">
              {i18n.t("ADD")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onEditClose}>
            {i18n.t("GOAL_EDIT")}
          </DialogTitle>

          <DialogContent>

            <div>

              <Grid container>
                <Grid item xs={12}>

                  <Grid container>
                    <Grid item xs={8}>

                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="goalName">{i18n.t("GOAL_NAME")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.goal_name}
                          onChange={(event) => this.onNameChange(event)}
                          id="goalName" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>

                    <Grid item xs={4}>

                      <FormControl className={classes.formControl}
                        fullWidth>
                        <InputLabel htmlFor="gradeId">{i18n.t("GOAL_GRADE")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.selectedItem.grade_id}
                          onChange={(event) => this.onGradeChange(event)}
                          inputProps={{
                            name: 'gradeId',
                            id: 'gradeId',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("GOAL_SELECT_GRADE")}</MenuItem>
                          {this.state.grades.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {

                              return (
                                <MenuItem key={key} value={item.grade_id}>{item.grade_name_spanish}</MenuItem>
                              )
                            } else {
                              return (
                                <MenuItem key={key} value={item.grade_id}>{item.grade_name}</MenuItem>
                              )
                            }
                          })}
                        </Select>
                      </FormControl>

                    </Grid>
                  </Grid>

                  <Grid container style={{ marginTop: 10 }}>

                    <Grid item xs={8}>
                      <FormControl className={classes.formControl}
                        fullWidth>
                        <InputLabel htmlFor="goalCategoryId">{i18n.t("GOAL_TYPE")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.selectedItem.goal_category_id}
                          onChange={(event) => this.onCategoryChange(event)}
                          inputProps={{
                            name: 'goalCategoryId',
                            id: 'goalCategoryId',
                          }}>
                          <MenuItem disabled value={0}>{i18n.t("GOAL_SELECT_GOAL_TYPE")}</MenuItem>
                          {this.renderCategories()}
                        </Select>
                      </FormControl>

                    </Grid>
                    <Grid item xs={4}>

                      <FormControl className={classes.formControl}
                        fullWidth>
                        <InputLabel htmlFor="goalAchieved">{i18n.t("GOAL_ACHIEVED")}</InputLabel>
                        <Select variant='standard'
                          value={this.state.selectedItem.goal_achieved}
                          onChange={(event) => this.onAchievedChange(event)}
                          inputProps={{
                            name: 'goalAchieved',
                            id: 'goalAchieved',
                          }}>
                          <MenuItem value={0}>No</MenuItem>
                          <MenuItem value={1}>{i18n.t("YES")}</MenuItem>
                        </Select>
                      </FormControl>

                    </Grid>
                  </Grid>

                  <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel htmlFor="goalDescription">{i18n.t("GOAL_DESCRIPTION")}</InputLabel>
                    <Input variant="outline" multiline={true} rows={4} fullWidth
                      value={this.state.selectedItem.goal_description}
                      onChange={(event) => this.onDescriptionChange(event)}
                      id="goalDescription" aria-describedby="my-helper-text" />
                  </FormControl>

                </Grid>
              </Grid>
            </div>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.onDeleteClick}
              style={{ color: '#FFF', fontWeight: 'bold' }} color="secondary">
              {i18n.t("DELETE")}
            </Button>
            <Button variant="contained" onClick={this.onEditClick}
              style={{ marginRight: 20, color: '#FFF', fontWeight: 'bold' }} color="primary">
              {i18n.t("SAVE")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isAchievedOpen}
          maxWidth="sm"
          fullWidth={true}>
          <DialogTitle id="customized-dialog-title">
            {i18n.t("DID_YOU")}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {i18n.t("GOAL_MARK_DONE")}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.onAchievedClose} color="primary">
              {i18n.t("CANCEL")}
            </Button>
            <Button onClick={this.saveGoal} color="primary">
              {i18n.t("YES")}
            </Button>
          </DialogActions>

        </Dialog>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("GOALS")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.renderGoals()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

GoalScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GoalScreen));