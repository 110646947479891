import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import SchoolIcon from '@mui/icons-material/School';
import SearchIcon from '@mui/icons-material/Search';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase, ListItem, ListItemAvatar, ListItemText,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

var timeout = null;

const CollegePin = (props) => {
  return (
    <div>
      <SchoolIcon onClick={props.handlePinClick}
        style={{ color: '#1F3545' }} />
    </div>
  )
}

class CollegeListScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      states: [],
      levels: [],
      types: [],
      degrees: [],
      programs: [],
      searchText: '',
      card: [],
      filterList: [],
      filterListInstitution: [],
      filterListLevel: [],
      filterListDegree: [],
      filterListProgram: [],
      stateList: [],
      collegeLevelList: [],
      collegeTypeList: [],
      collegeDegreeList: [],
      collegeProgramList: [],
      locationExpanded: false,
      typeExpanded: false,
      center: {
        lat: 36.310730,
        lng: -86.597680
      },
      zoom: 8,
      currentZoom: 8,
      onPopupClose: false,
      selectedSchool: {},
      sortBy: '',
      totalRows: 0,
      perPage: 50,
      pageNumber: 0,
      sortDirection: 'desc',
      view: 1
    }

    this.onSearch = this.onSearch.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
  }

  updateDimensions() {
    let update_height = window.innerHeight - 200;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.updateDimensions();

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/states', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            states: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/college_levels', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            levels: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })

      });

    fetch('https://api.tasselpathway.com/college_programs', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            programs: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })

      });

    fetch('https://api.tasselpathway.com/college_degrees', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            degrees: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })

      });

    fetch('https://api.tasselpathway.com/college_types', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            types: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    this.getData(this.state.searchText, this.state.stateList, this.state.collegeLevelList, this.state.collegeTypeList,
      this.state.collegeDegreeList, this.state.collegeProgramList, this.state.perPage, this.state.pageNumber);

  }

  getData(searchText, stateList, collegeLevelList, collegeTypeList, collegeDegreeList, collegeProgramList, perPage, pageNumber) {

    var storedLocation = JSON.parse(localStorage.getItem("localStorageFiltersLocation"));
    var storedInstitution = JSON.parse(localStorage.getItem("localStorageFiltersInstitution"));
    var storedLevel = JSON.parse(localStorage.getItem("localStorageFiltersLevel"));
    var storedDegree = JSON.parse(localStorage.getItem("localStorageFiltersDegree"));
    var storedProgram = JSON.parse(localStorage.getItem("localStorageFiltersProgram"));

    if (storedLocation !== null && storedInstitution !== null && storedLevel !== null && storedDegree !== null && storedProgram !== null) {
      fetch('https://api.tasselpathway.com/colleges?search=' + searchText + '&state=' + storedLocation.join() + '&level=' +
        storedLevel.join() + '&type=' + storedInstitution.join() + '&degree=' + storedDegree.join() + '&program=' +
        storedProgram.join() + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false && responseJson.total_results !== 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          } else if (responseJson.error === false && responseJson.total_results === 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.results.length
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })

        });
    }

    else if (storedLevel !== null) {
      fetch('https://api.tasselpathway.com/colleges?search=' + searchText + '&state=' + stateList.join() + '&level=' +
        storedLevel.join() + '&type=' + collegeTypeList.join() + '&degree=' + collegeDegreeList.join() + '&program=' +
        collegeProgramList + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false && responseJson.total_results !== 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          } else if (responseJson.error === false && responseJson.total_results === 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.results.length
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })

        });
    }

    else if (storedProgram !== null) {
      fetch('https://api.tasselpathway.com/colleges?search=' + searchText + '&state=' + stateList.join() + '&level=' +
        collegeLevelList.join() + '&type=' + collegeTypeList.join() + '&degree=' + collegeDegreeList.join() + '&program=' +
        storedProgram.join() + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false && responseJson.total_results !== 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          } else if (responseJson.error === false && responseJson.total_results === 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.results.length
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })

        });
    }

    else if (storedDegree !== null) {
      fetch('https://api.tasselpathway.com/colleges?search=' + searchText + '&state=' + stateList.join() + '&level=' +
        collegeLevelList.join() + '&type=' + collegeTypeList.join() + '&degree=' + storedDegree.join() + '&program=' +
        collegeProgramList + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false && responseJson.total_results !== 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          } else if (responseJson.error === false && responseJson.total_results === 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.results.length
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })

        });
    }

    else if (storedInstitution !== null) {
      fetch('https://api.tasselpathway.com/colleges?search=' + searchText + '&state=' + stateList.join() + '&level=' +
        collegeLevelList.join() + '&type=' + storedInstitution.join() + '&degree=' + collegeDegreeList.join() + '&program=' +
        collegeProgramList + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false && responseJson.total_results !== 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          } else if (responseJson.error === false && responseJson.total_results === 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.results.length
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })

        });
    }

    else if (storedLocation !== null) {
      fetch('https://api.tasselpathway.com/colleges?search=' + searchText + '&state=' + storedLocation.join() + '&level=' +
        collegeLevelList.join() + '&type=' + collegeTypeList.join() + '&degree=' + collegeDegreeList.join() + '&program=' +
        collegeProgramList + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false && responseJson.total_results !== 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          } else if (responseJson.error === false && responseJson.total_results === 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.results.length
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })

        });
    }

    else {
      fetch('https://api.tasselpathway.com/colleges?search=' + searchText + '&state=' + stateList.join() + '&level=' +
        collegeLevelList.join() + '&type=' + collegeTypeList.join() + '&degree=' + collegeDegreeList.join() + '&program=' +
        collegeProgramList + '&per_page=' + perPage + '&page_number=' + pageNumber, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false && responseJson.total_results !== 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.total_results
            })
          } else if (responseJson.error === false && responseJson.total_results === 0) {
            this.setState({
              isLoading: false,
              dataSource: responseJson.results,
              totalRows: responseJson.results.length
            })
          }

          this.props.setProgress(100);
        })
        .catch((error) => {
          this.props.setProgress(100);
          this.setState({
            isLoading: false
          })

        });
    }
  }

  onSearch(event) {

    var searchText = event.target.value;

    this.setState({
      searchText: searchText,
      pageNumber: 0
    })

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.getData(searchText, scope.state.stateList, scope.state.collegeLevelList, scope.state.collegeTypeList,
        scope.state.collegeDegreeList, scope.state.collegeProgramList, scope.state.perPage, scope.state.pageNumber)
    }, 1000);
  }

  onStateClick = (event, stateId) => {
    if (event.target.checked !== undefined) {
      var stateList = this.state.stateList;
      var filterList = this.state.filterList;

      if (event.target.checked) {
        stateList['choice_' + stateId] = 1;

        filterList.push(stateId)

      } else {
        stateList['choice_' + stateId] = 0;

        for (var i = 0; i < filterList.length; i++) {
          if (filterList[i] === stateId) {
            filterList.splice(i, 1);
          }
        }
      }

      this.setState({
        stateList: stateList,
        filterList: filterList,
        pageNumber: 0,
        currentZoom: 5
      })

      localStorage.setItem('localStorageFiltersLocation', JSON.stringify(filterList));

      this.getData(this.state.searchText, filterList, this.state.collegeLevelList, this.state.collegeTypeList,
        this.state.collegeDegreeList, this.state.collegeProgramList, this.state.perPage, 0);
    }
  }

  onCollegeLevelClick(event, levelId) {
    if (event.target.checked !== undefined) {
      var collegeLevelList = this.state.collegeLevelList;
      var filterList = this.state.filterListLevel;

      if (event.target.checked) {
        collegeLevelList['choice_' + levelId] = 1;

        filterList.push(levelId)

      } else {
        collegeLevelList['choice_' + levelId] = 0;

        for (var i = 0; i < filterList.length; i++) {
          if (filterList[i] === levelId) {
            filterList.splice(i, 1);
          }
        }
      }

      this.setState({
        collegeLevelList: collegeLevelList,
        filterList: filterList,
        pageNumber: 0,
      })

      localStorage.setItem('localStorageFiltersLevel', JSON.stringify(filterList));

      this.getData(this.state.searchText, this.state.collegeLevelList, filterList, this.state.collegeTypeList,
        this.state.collegeDegreeList, this.state.collegeProgramList, this.state.perPage, 0);
    }
  }

  onCollegeTypeClick(event, typeId) {
    if (event.target.checked !== undefined) {
      var collegeTypeList = this.state.collegeTypeList;
      var filterList = this.state.filterListInstitution;

      if (event.target.checked) {
        collegeTypeList['choice_' + typeId] = 1;

        filterList.push(typeId)

      } else {
        collegeTypeList['choice_' + typeId] = 0;

        for (var i = 0; i < filterList.length; i++) {
          if (filterList[i] === typeId) {
            filterList.splice(i, 1);
          }
        }
      }

      this.setState({
        collegeTypeList: collegeTypeList,
        filterList: filterList,
        pageNumber: 0,
      })

      localStorage.setItem('localStorageFiltersInstitution', JSON.stringify(filterList));

      this.getData(this.state.searchText, this.state.stateList, this.state.collegeLevelList, collegeTypeList,
        this.state.collegeDegreeList, this.state.collegeProgramList, this.state.perPage, 0);
    }
  }

  onCollegeDegreeClick(event, degreeId) {
    if (event.target.checked !== undefined) {
      var collegeDegreeList = this.state.collegeDegreeList;
      var filterList = this.state.filterListDegree;

      if (event.target.checked) {
        collegeDegreeList['choice_' + degreeId] = 1;

        filterList.push(degreeId)

      } else {
        collegeDegreeList['choice_' + degreeId] = 0;

        for (var i = 0; i < filterList.length; i++) {
          if (filterList[i] === degreeId) {
            filterList.splice(i, 1);
          }
        }
      }

      this.setState({
        collegeDegreeList: collegeDegreeList,
        filterList: filterList,
        pageNumber: 0,
      })

      localStorage.setItem('localStorageFiltersDegree', JSON.stringify(filterList));

      this.getData(this.state.searchText, this.state.stateList, this.state.collegeLevelList, this.state.collegeTypeList,
        collegeDegreeList, this.state.collegeProgramList, this.state.perPage, 0);
    }
  }

  onCollegeProgramClick(event, programId) {
    if (event.target.checked !== undefined) {
      var collegeProgramList = this.state.collegeProgramList;
      var filterList = this.state.filterListDegree;

      if (event.target.checked) {
        collegeProgramList['choice_' + programId] = 1;

        filterList.push(programId)

      } else {
        collegeProgramList['choice_' + programId] = 0;

        for (var i = 0; i < filterList.length; i++) {
          if (filterList[i] === programId) {
            filterList.splice(i, 1);
          }
        }
      }

      this.setState({
        collegeProgramList: collegeProgramList,
        filterList: filterList,
        pageNumber: 0,
      })

      localStorage.setItem('localStorageFiltersProgram', JSON.stringify(filterList));

      this.getData(this.state.searchText, this.state.stateList, this.state.collegeLevelList, this.state.collegeTypeList,
        this.state.collegeDegreeList, collegeProgramList, this.state.perPage, 0);
    }
  }

  onPreviousClick() {
    var currentPage = this.state.pageNumber;

    if (currentPage > 0) {

      currentPage = currentPage - 1;

      this.setState({
        pageNumber: currentPage
      })
    }

    this.getData(this.state.searchText, this.state.stateList, this.state.collegeLevelList, this.state.collegeTypeList,
      this.state.collegeDegreeList, this.state.collegeProgramList, this.state.perPage, currentPage);
  }

  onNextClick() {
    var currentPage = this.state.pageNumber;

    currentPage = currentPage + 1;

    this.setState({
      pageNumber: currentPage
    })

    this.getData(this.state.searchText, this.state.stateList, this.state.collegeLevelList, this.state.collegeTypeList,
      this.state.collegeDegreeList, this.state.collegeProgramList, this.state.perPage, currentPage);
  }

  onPinClick(item) {
    this.setState({
      center: {
        lat: item.latitude,
        lng: item.longitude
      },
      showPopup: true,
      selectedSchool: item
    })
  }

  onPopupClose() {
    this.setState({
      showPopup: false
    })
  }

  onSchoolClick() {

    this.props.history.push('/college/' + this.state.selectedSchool.college_id);
  }

  render() {
    const { classes } = this.props;
    const setZoom = this.state.currentZoom === "" || this.state.currentZoom === undefined ? (this.state.zoom) : (this.state.currentZoom)
    const storedLocation = JSON.parse(localStorage.getItem("localStorageFiltersLocation"));
    const storedInstitution = JSON.parse(localStorage.getItem("localStorageFiltersInstitution"));
    const storedLevel = JSON.parse(localStorage.getItem("localStorageFiltersLevel"));
    const storedDegree = JSON.parse(localStorage.getItem("localStorageFiltersDegree"));
    const storedProgram = JSON.parse(localStorage.getItem("localStorageFiltersProgram"));


    return (
      <div className={classes.root}>

        <Dialog open={this.state.showPopup}
          onClose={this.onPopupClose} maxWidth="sm"
          fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onPopupClose}>

          </DialogTitle>
          <DialogContent>
            <div>

              <Grid container spacing={2}>

                <Grid item xs={4}>
                  <Avatar alt="MSU" style={{ height: 125, width: 125 }} src={this.state.selectedSchool.logo_image_url} />
                </Grid>
                <Grid item xs={8} style={{ marginTop: 10 }}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', color: '#1F3545' }}>{this.state.selectedSchool.college_name}</Typography>
                  <Typography>{this.state.selectedSchool.city}, {this.state.selectedSchool.state_name}<br />
                    <i>{this.state.selectedSchool.distance ? this.state.selectedSchool.distance.toFixed(2) : ""} {i18n.t("COLLEGE_MILES_AWAY")}</i></Typography>
                  <Button variant="contained" onClick={() => this.onSchoolClick()}
                    color="primary" style={{ marginTop: 20 }}>
                    <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>{i18n.t("COLLEGE_EXPLORE")}</Typography>
                  </Button>
                </Grid>
              </Grid>

            </div>
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>

        <Grid container style={styles.itemContainerStyle}>
          <Grid item xs={12} style={{ marginLeft: 100, marginRight: 100 }}>

            <Grid container style={{ marginBottom: 10, marginTop: 20 }}>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 24, color: '#333', fontWeight: 'bold' }}>{i18n.t("COLLEGE_PAGE_TITLE")}</Typography>
              </Grid>
              <Grid item xs={3}>
                {((this.state.pageNumber * this.state.perPage) + 1) > (this.state.totalRows - 50) ?
                  <Typography style={{ fontSize: 14, color: '#333', marginLeft: 20, marginTop: 30, fontWeight: 'bold' }}>
                    {i18n.t("COLLEGE_SHOWING")} {(this.state.pageNumber * this.state.perPage) + 1} - {this.state.totalRows} {i18n.t("COLLEGE_OF")} {this.state.totalRows} {i18n.t("COLLEGE_RESULTS")}</Typography>
                  :
                  <Typography style={{ fontSize: 14, color: '#333', marginLeft: 20, marginTop: 30, fontWeight: 'bold' }}>
                    {i18n.t("COLLEGE_SHOWING")} {(this.state.pageNumber * this.state.perPage) + 1} - {(this.state.pageNumber + 1) *
                      this.state.dataSource.length} {i18n.t("COLLEGE_OF")} {this.state.totalRows} {i18n.t("COLLEGE_RESULTS")}</Typography>
                }
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                {this.props.selectedLanguage === "esp" ?
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={this.onSearch} style={{ width: '100%' }}
                      placeholder="Buscar"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                  :
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={this.onSearch} style={{ width: '100%' }}
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                }
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'right', marginTop: 20 }}>
                {this.state.view === 1 ?
                  <IconButton style={{ marginRight: 20 }}
                    onClick={() => this.setState({ view: 2 })}>
                    <PublicIcon />
                  </IconButton>
                  :
                  <IconButton style={{ marginRight: 20 }}
                    onClick={() => this.setState({ view: 1 })}>
                    <ViewModuleIcon />
                  </IconButton>
                }

                <IconButton
                  disabled={this.state.pageNumber > 0 ? false : true}
                  onClick={() => this.onPreviousClick()}>
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton style={{ marginRight: 5 }}
                  disabled={(((this.state.pageNumber * this.state.perPage) + 1) > (this.state.totalRows - 50)) ? true : false}
                  onClick={() => this.onNextClick()}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container>

              <Grid item xs={2} style={{ marginTop: 20 }}>

                <Accordion square expanded={this.state.locationExpanded} classes={{ expanded: classes.expanded }}
                  onChange={() => this.setState({ locationExpanded: !this.state.locationExpanded })}
                  style={{ marginLeft: -25, backgroundColor: '#eaeaea', boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>{i18n.t("COLLEGE_LOCATION_STATE")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                    <FormControl className={classes.formControl}
                      style={{
                        height: 300, borderBottom: '1px solid #eaeaea', marginLeft: 5,
                        paddingLeft: 10, width: '100%', overflow: 'auto'
                      }}>
                      <FormGroup>

                        {storedLocation !== null ?
                          this.state.states.map((item, key) => {
                            return (
                              <FormControlLabel key={key} onChange={(event) => this.onStateClick(event, item.state_id)}
                                control={
                                  <Checkbox checked={storedLocation.includes(item.state_id)} />
                                }
                                label={<Typography style={{ fontSize: 11 }}>{item.state_name}</Typography>}
                              />
                            )
                          }
                          )
                          :
                          this.state.states.map((item, key) => {
                            return (
                              <FormControlLabel key={key} onClick={(event) => this.onStateClick(event, item.state_id)}
                                control={
                                  <Checkbox />
                                }
                                label={<Typography style={{ fontSize: 11 }}>{item.state_name}</Typography>}
                              />
                            )
                          })}

                      </FormGroup>

                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                <Accordion square expanded={this.state.typeExpanded} classes={{ expanded: classes.expanded }}
                  onChange={() => this.setState({ typeExpanded: !this.state.typeExpanded })}
                  style={{ marginLeft: -25, backgroundColor: '#eaeaea', boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>{i18n.t("COLLEGE_INSTITUTION_TYPE")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                    <FormControl className={classes.formControl}
                      style={{
                        height: 125, borderBottom: '1px solid #eaeaea', marginLeft: 5,
                        paddingLeft: 10, width: '100%', overflow: 'auto'
                      }}>
                      <FormGroup>


                        {storedInstitution !== null ?
                          this.state.types.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeTypeClick(event, item.college_type_id)}
                                  control={
                                    <Checkbox checked={storedInstitution.includes(item.college_type_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.college_type_name_spanish}</Typography>}

                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeTypeClick(event, item.college_type_id)}
                                  control={
                                    <Checkbox checked={storedInstitution.includes(item.college_type_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.college_type_name}</Typography>}

                                />
                              )
                            }
                          }
                          )
                          :
                          this.state.types.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeTypeClick(event, item.college_type_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.college_type_name_spanish}</Typography>}

                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeTypeClick(event, item.college_type_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.college_type_name}</Typography>}

                                />
                              )
                            }
                          })}


                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                <Accordion square classes={{ expanded: classes.expanded }}
                  style={{ marginLeft: -25, backgroundColor: '#eaeaea', boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>{i18n.t("COLLEGE_LEVEL_OF_INSTITUTION")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                    <FormControl className={classes.formControl}
                      style={{
                        height: 200, borderBottom: '1px solid #eaeaea', marginLeft: 5,
                        paddingLeft: 10, width: '100%', overflow: 'auto'
                      }}>
                      <FormGroup>

                        {storedLevel !== null ?
                          this.state.levels.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeLevelClick(event, item.college_level_id)}
                                  control={
                                    <Checkbox checked={storedLevel.includes(item.college_level_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.college_level_name_spanish}</Typography>}

                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeLevelClick(event, item.college_level_id)}
                                  control={
                                    <Checkbox checked={storedLevel.includes(item.college_level_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.college_level_name}</Typography>}

                                />
                              )
                            }
                          }
                          )
                          :
                          this.state.levels.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeLevelClick(event, item.college_level_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.college_level_name_spanish}</Typography>}

                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeLevelClick(event, item.college_level_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.college_level_name}</Typography>}

                                />
                              )
                            }
                          })}

                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                <Accordion square classes={{ expanded: classes.expanded }}
                  style={{ marginLeft: -25, backgroundColor: '#eaeaea', boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>{i18n.t("COLLEGE_LEVEL_OF_DEGREE")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                    <FormControl className={classes.formControl}
                      style={{
                        height: 200, borderBottom: '1px solid #eaeaea', marginLeft: 5,
                        paddingLeft: 10, width: '100%', overflow: 'auto'
                      }}>
                      <FormGroup>

                        {storedDegree !== null ?
                          this.state.degrees.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeDegreeClick(event, item.degree_id)}
                                  control={
                                    <Checkbox checked={storedDegree.includes(item.degree_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.degree_name_spanish}</Typography>}

                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeDegreeClick(event, item.degree_id)}
                                  control={
                                    <Checkbox checked={storedDegree.includes(item.degree_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.degree_name}</Typography>}

                                />
                              )
                            }
                          }
                          )
                          :
                          this.state.degrees.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeDegreeClick(event, item.degree_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.degree_name_spanish}</Typography>}
                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeDegreeClick(event, item.degree_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.degree_name}</Typography>}
                                />
                              )
                            }
                          })}

                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                <Accordion square classes={{ expanded: classes.expanded }}
                  style={{ marginLeft: -25, backgroundColor: '#eaeaea', boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>{i18n.t("COLLEGE_PROGRAM")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                    <FormControl className={classes.formControl}
                      style={{
                        height: 400, borderBottom: '1px solid #eaeaea', marginLeft: 5,
                        paddingLeft: 10, width: '100%', overflow: 'auto'
                      }}>
                      <FormGroup>

                        {/* {this.state.programs.map((item, key) => {
                          if(this.props.selectedLanguage === "esp"){
                            return (
                              <FormControlLabel key={key} onClick={(event) => this.onCollegeProgramClick(event, item.program_id)}
                                control={
                                  <Checkbox  />
                                }
                                label={<Typography style={{fontSize:11}}>{item.program_name_spanish}</Typography>}
                              />
                            )
                          }else{
                            return (
                              <FormControlLabel key={key} onClick={(event) => this.onCollegeProgramClick(event, item.program_id)}
                                control={
                                  <Checkbox  />
                                }
                                label={<Typography style={{fontSize:11}}>{item.program_name}</Typography>}
                              />
                            )
                          }
                        })} */}



                        {storedProgram !== null ?
                          this.state.programs.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeProgramClick(event, item.program_id)}
                                  control={
                                    <Checkbox checked={storedProgram.includes(item.program_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.program_name_spanish}</Typography>}

                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeProgramClick(event, item.program_id)}
                                  control={
                                    <Checkbox checked={storedProgram.includes(item.program_id)} />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.program_name}</Typography>}

                                />
                              )
                            }
                          }
                          )
                          :
                          this.state.programs.map((item, key) => {
                            if (this.props.selectedLanguage === "esp") {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeProgramClick(event, item.program_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.program_name_spanish}</Typography>}
                                />
                              )
                            } else {
                              return (
                                <FormControlLabel key={key} onClick={(event) => this.onCollegeProgramClick(event, item.program_id)}
                                  control={
                                    <Checkbox />
                                  }
                                  label={<Typography style={{ fontSize: 11 }}>{item.program_name}</Typography>}
                                />
                              )
                            }
                          })}





                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

              </Grid>

              <Grid item xs={10} style={{ marginTop: 20 }}>

                {this.state.view === 1 ?

                  <Grid container style={{ overflowX: 'auto', height: this.state.height, paddingLeft: 20, paddingRight: 20 }}>

                    {this.state.dataSource.map((item, key) => {

                      var link = "/college/" + item.college_id;

                      return (

                        <Grid item md={6} lg={4} xl={4} sm={6} xs={6} key={key}>
                          <Card style={{ margin: 5, height: 135, borderRadius: 3 }} className='hovered'>
                            <ListItem alignItems="flex-start" component={Link} to={link}>
                              <ListItemAvatar>
                                <Avatar alt="MSU" style={{ height: 75, width: 75, marginTop: 20 }} src={item.logo_image_url} />
                              </ListItemAvatar>
                              <ListItemText
                                style={{ marginTop: 20, marginLeft: 15 }}
                                primary={
                                  <React.Fragment>
                                    {item.college_name.length > 30 ?
                                      <Typography component="span"
                                        style={{ fontSize: 13, fontWeight: 'bold', color: '#1F3545' }}>
                                        {item.college_name}
                                      </Typography>
                                      :
                                      <Typography component="span"
                                        style={{ fontSize: 15, fontWeight: 'bold', color: '#1F3545' }}>
                                        {item.college_name}
                                      </Typography>
                                    }
                                  </React.Fragment>
                                }
                                secondary={
                                  <React.Fragment>
                                    {item.college_name.length > 30 ?
                                      <Typography component="span" style={{ fontSize: 11 }} color="textPrimary">
                                        {item.city}, {item.state_name}<br /><i>{item.distance.toFixed(2)} {i18n.t("COLLEGE_MILES_AWAY")}</i>
                                      </Typography>
                                      :
                                      <Typography component="span" style={{ fontSize: 11 }} color="textPrimary">
                                        {item.city}, {item.state_name}<br /><i>{item.distance.toFixed(2)} {i18n.t("COLLEGE_MILES_AWAY")}</i>
                                      </Typography>
                                    }
                                  </React.Fragment>
                                }
                              />

                            </ListItem>
                          </Card>
                        </Grid>
                      )
                    })}

                  </Grid>

                  :

                  <div style={{ height: this.state.height, width: '100%' }}>

                    <GoogleMapReact
                      bootstrapURLKeys={{ key: 'AIzaSyD4ew6H42OLeUHhX7efgnKyzsdA2MB8sy0' }}
                      defaultCenter={this.state.center}
                      center={this.state.center}
                      zoom={setZoom}
                      defaultZoom={this.state.zoom}>

                      {this.state.dataSource.map((item, key) => {
                        return (
                          <CollegePin key={key}
                            lat={item.latitude}
                            lng={item.longitude}
                            onChildMouseEnter={this.onChildMouseEnter}
                            onChildMouseLeave={this.onChildMouseLeave}
                            handlePinClick={() => this.onPinClick(item)}
                            hover={this.state.hover}
                            text={item.college_name}
                          />
                        )

                      })}
                    </GoogleMapReact>
                  </div>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CollegeListScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation("translate"))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CollegeListScreen)));