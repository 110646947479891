
export default {
    
    container: {
      backgroundColor:'#FFF',
      padding: 30,
      maxWidth:550,
      alignSelf:'center', 
      textAlign:'center',
      alignContent:'center'
    },
    loginText: {
      borderColor: '#FFF',
      marginBottom: 20,
      padding: 10,
      borderWidth: 5
    },
    resetButton: {
      backgroundColor: '#25D192', 
      width:'100%',
      height:50, 
      fontSize:18,
      color:'#FFF', 
      borderRadius:0, 
      fontWeight:'bold', 
      textAlign:'center'
    },
    inputStyle: {
      color:'#333',
      borderRadius: 4
    }
  };