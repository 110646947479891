import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);


class StudentScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      collegeId: 0,
      studentData: [],
      studentOptions: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100
            }
          }]
        }
      },
      genderData: {
        labels: ['Hombres', 'Mujeres'],
        datasets: [{
          data: [
            0, 0
          ],
          backgroundColor: ['#25D192', '#1F3545'],
          hoverBackgroundColor: ['#25D192', '#1F3545']
        }]
      },
      genderOptions: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: true, position: 'bottom' },
        },

        responsive: true
      },

      actData: {
        labels: ['AVG ACT Compuesto'],
        datasets: [{
          data: [
            0
          ],
          backgroundColor: ['#1F3545'],
          hoverBackgroundColor: ['#1F3545']
        }]
      },
      actOptions: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },

        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              min: 0,
              max: 36,
              maxTicksLimit: 6,
              stepSize: 6
            }
          }]
        }
      },
      satData: {
        labels: ['AVG SAT Compuesto'],
        datasets: [{
          data: [
            0
          ],
          backgroundColor: ['#25D192'],
          hoverBackgroundColor: ['#25D192']
        }]
      },
      satOptions: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
        },
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              min: 0,
              max: 1600,
              stepSize: 400
            }
          }]
        }
      }
    }
  }

  componentDidMount() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/college_student/' + this.props.collegeId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          if (this.props.selectedLanguage === "esp") {
            this.setState({
              isLoading: false,
              admissionId: responseJson.results[0].admission_id,
              collegeId: responseJson.results[0].college_id,
              collegeName: responseJson.results[0].college_name,
              studentData: {
                labels: ['Estudiantes del estado', 'Estudiantes fuera del estado'],
                datasets: [{
                  data: [
                    responseJson.results[0].in_state_percent,
                    responseJson.results[0].out_of_state_percent
                  ],
                  backgroundColor: ['#25D192', '#1F3545'],
                  hoverBackgroundColor: ['#25D192', '#1F3545']
                }]
              },
              genderData: {
                labels: ['Hombres', 'Mujeres'],
                datasets: [{
                  data: [
                    responseJson.results[0].percent_male,
                    responseJson.results[0].percent_female
                  ],
                  backgroundColor: ['#25D192', '#1F3545'],
                  hoverBackgroundColor: ['#25D192', '#1F3545']
                }]
              },
              actData: {
                labels: ['AVG ACT Compuesto'],
                datasets: [{
                  data: [
                    responseJson.results[0].average_act_score,
                  ],
                  backgroundColor: ['#1F3545'],
                  hoverBackgroundColor: ['#1F3545']
                }]
              },
              satData: {
                labels: ['AVG SAT Compuesto'],
                datasets: [{
                  data: [
                    responseJson.results[0].average_sat_score
                  ],
                  backgroundColor: ['#25D192'],
                  hoverBackgroundColor: ['#25D192']
                }]
              }
            })
          } else {
            this.setState({
              isLoading: false,
              admissionId: responseJson.results[0].admission_id,
              collegeId: responseJson.results[0].college_id,
              collegeName: responseJson.results[0].college_name,
              studentData: {
                labels: ['In-State Students', 'Out-of-State Students'],
                datasets: [{
                  data: [
                    responseJson.results[0].in_state_percent,
                    responseJson.results[0].out_of_state_percent
                  ],
                  backgroundColor: ['#25D192', '#1F3545'],
                  hoverBackgroundColor: ['#25D192', '#1F3545']
                }]
              },
              genderData: {
                labels: ['Male', 'Female'],
                datasets: [{
                  data: [
                    responseJson.results[0].percent_male,
                    responseJson.results[0].percent_female
                  ],
                  backgroundColor: ['#25D192', '#1F3545'],
                  hoverBackgroundColor: ['#25D192', '#1F3545']
                }]
              },
              actData: {
                labels: ['AVG ACT Composite'],
                datasets: [{
                  data: [
                    responseJson.results[0].average_act_score,
                  ],
                  backgroundColor: ['#1F3545'],
                  hoverBackgroundColor: ['#1F3545']
                }]
              },
              satData: {
                labels: ['AVG SAT Composite'],
                datasets: [{
                  data: [
                    responseJson.results[0].average_sat_score
                  ],
                  backgroundColor: ['#25D192'],
                  hoverBackgroundColor: ['#25D192']
                }]
              }
            })
          }
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#1F3545', marginBottom: 10 }}>{i18n.t("COLLEGE_STUDENT_STATS")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>

              {this.state.studentData === undefined ?
                <Grid container style={{ marginTop: 30 }}>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>

                    <Bar data={this.state.studentData}
                      options={this.state.studentOptions} />

                  </Grid>
                </Grid>
                : null
              }

              <Grid container style={{ marginTop: 30 }}>
                <Grid item xs={4}>
                  <Doughnut data={this.state.genderData} height={80}
                    options={this.state.genderOptions} />

                </Grid>
                <Grid item xs={8}>
                  <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      {this.state.actData === undefined ?
                        <Bar data={this.state.actData} height={80}
                          options={this.state.actOptions} />
                        : null}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>

                      <Bar data={this.state.satData} height={80}
                        options={this.state.satOptions} />

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

StudentScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StudentScreen)));