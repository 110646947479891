import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Card, CardContent, CircularProgress, Dialog, DialogContent, Grid, IconButton, InputBase, MenuItem, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const invertSort = {
  asc: "desc",
  desc: "asc"
}

var timeout = null;

class Report8Screen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      reports: [],
      dataSource: [],
      reportId: 0,
      selectedGroup: 0,
      selectedGrade: 0,
      groups: [],
      grades: [],
      height: 0,
      searchText: '',
      perPage: 25,
      pageNumber: 0,
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc',
      reportName: '',
      totalRows: 0
    }

    this.onSearch = this.onSearch.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
  }

  updateDimensions() {
    let update_height = window.innerHeight - 260
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    var group = this.state.selectedGroup;
    var grade = this.state.selectedGrade;

    window.addEventListener("resize", this.updateDimensions.bind(this));

    fetch('https://api.tasselpathway.com/custom_groups/all', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            groups: responseJson.results
          });

          this.getData(this.state.searchText, group, grade, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
        }

      })
      .catch((error) => {

      });

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          });
        }

      })
      .catch((error) => {

      });

  }

  getData(searchText, group, grade, perPage, pageNumber, sortBy, sortDirection) {

    this.setState({
      isSavingOpen: true
    })

    fetch('https://api.tasselpathway.com/report8?search=' + searchText + '&custom_group_id=' + group
      + '&grade_id=' + grade + '&per_page=' + perPage + '&page_number=' + pageNumber + '&sort_by=' + sortBy
      + '&sort_direction=' + sortDirection, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            isSavingOpen: false,
            dataSource: responseJson.results,
            totalRows: responseJson.total_results
          });
        } else {
          this.setState({
            isSavingOpen: false
          })
        }

        this.updateDimensions();

      })
      .catch((error) => {

        this.setState({
          isSavingOpen: false
        })

      });
  }

  onPageClick(event, newPage) {

    if (newPage > this.state.pageNumber) {
      this.onNextClick();
    } else {
      this.onPreviousClick();
    }
  }

  onPreviousClick() {
    var currentPage = this.state.pageNumber;

    if (currentPage > 0) {

      currentPage = currentPage - 1;

      this.setState({
        pageNumber: currentPage
      })
    }

    this.getData(this.state.searchText, this.state.selectedGroup, this.state.selectedGrade, this.state.perPage, currentPage, this.state.sortBy, this.state.sortDirection);
  }

  onNextClick() {
    var currentPage = this.state.pageNumber;

    currentPage = currentPage + 1;

    this.setState({
      pageNumber: currentPage
    })

    this.getData(this.state.searchText, this.state.selectedGroup, this.state.selectedGrade, this.state.perPage, currentPage, this.state.sortBy, this.state.sortDirection);
  }

  onPrintClick() {

    fetch('https://api.tasselpathway.com/report8/html?search=' + this.state.searchText + '&custom_group_id=' + this.state.selectedGroup
      + '&grade_id=' + this.state.selectedGrade + '&per_page=' + this.state.perPage + '&page_number=' + this.state.pageNumber
      + '&sort_by=' + this.state.sortBy + '&sort_direction=' + this.state.sortDirection, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token,
        'selectedGroup': this.state.selectedGroup
      },
      credentials: 'omit'
    })
      .then((response) => response.text())
      .then((responseJson) => {

        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(responseJson);
        pri.document.close();
        pri.focus();
        //pri.print();

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
      });
  }

  downloadFile({ data, fileName, fileType }) {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  onExportClick(evt) {

    evt.preventDefault();

    this.setState({
      isSavingOpen: true
    })

    fetch('https://api.tasselpathway.com/report8?search=' + this.state.searchText + '&custom_group_id=' + this.state.selectedGroup
      + '&grade_id=' + this.state.selectedGrade + '&per_page=' + 50000 + '&page_number=' + 0
      + '&sort_by=' + this.state.sortBy + '&sort_direction=' + this.state.sortDirection, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          let headers = ['Classroom,First Name,Last Name,Grade,Has Pathway,Interest Inventory,Learning Style,Colleges,Careers,Skills,Goals,Clubs & Sports,Honors & Awards,Resume,Assessments,Work Based Learning,% of Completion'];

          let reportCsv = responseJson.results.reduce((acc, ilp) => {
            const { classroom_name, first_name, last_name, grade_name, has_pathway, interest_inventory, learning_style, colleges, careers, skills, goals, clubs, honors, has_resume, assessments, work_based_learning, completion_percent } = ilp
            acc.push([classroom_name, first_name, last_name, grade_name, has_pathway, interest_inventory, learning_style, colleges, careers, skills, goals, clubs, honors, has_resume, assessments, work_based_learning, completion_percent].join(','))
            return acc
          }, [])

          this.downloadFile({
            data: [...headers, ...reportCsv].join('\n'),
            fileName: 'ILPReport.csv',
            fileType: 'text/csv',
          })

          this.setState({
            isLoading: false,
            isSavingOpen: false
          });

        } else {
          this.setState({
            isSavingOpen: false
          })
        }

      })
      .catch((error) => {

        this.setState({
          isSavingOpen: false
        })

      });
  }

  onGroupChange(event) {

    this.getData(this.state.searchText, event.target.value, this.state.selectedGrade, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
    this.setState({ selectedGroup: event.target.value })
  }

  onGradeChange(event) {

    this.getData(this.state.searchText, this.state.selectedGroup, event.target.value, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
    this.setState({ selectedGrade: event.target.value })
  }

  scrollToTop() {
    const report = this.refs.report;
    if (report) {
      report.scrollTo(300, 900);
    }
  }

  onBackClick() {
    this.props.history.push('/reports');
  }

  onSearch(event) {

    var searchText = event.target.value;

    this.setState({
      searchText: searchText,
      pageNumber: 0
    })

    clearTimeout(timeout);

    var scope = this;
    timeout = setTimeout(function () {
      scope.getData(searchText, scope.state.selectedGroup, scope.state.selectedGrade, scope.state.perPage, scope.state.pageNumber, scope.state.sortBy, scope.state.sortDirection)
    }, 1000);
  }

  onSortClick(col) {

    var sortBy = col;
    var sortDirection = this.state.sortBy === col ? invertSort[this.state.sortDirection] : 'asc';

    this.setState({
      sortBy: sortBy,
      sortDirection: sortDirection
    })

    this.getData(this.state.searchText, this.state.selectedGroup, this.state.selectedGrade, this.state.perPage, this.state.pageNumber, sortBy, sortDirection);
  }

  render() {

    const { classes } = this.props;
    const numberOfStudents = this.state.totalRows
    const correctedNumberOfStudents = numberOfStudents

    moment('en');

    return (
      <div className={classes.root} ref={`report`}>

        <Grid id="report_7" style={styles.itemContainerStyle}>

          <Dialog open={this.state.isSavingOpen}
            onClose={this.onSavingClose}
            maxWidth="md"
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none'
              },
            }}>

            <DialogContent>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress style={{ color: '#25D192' }} />
              </div>
            </DialogContent>
          </Dialog>

          <Grid container>

            <Grid item xs={3}>
              <Typography style={{ fontSize: 24, textAlign: 'left', marginTop: 15 }}>ILP Report</Typography>
            </Grid>

            <Grid item xs={3} style={{ paddingRight: 30 }}>
              {this.props.selectedLanguage === "esp" ?
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    onChange={this.onSearch} style={{ width: '100%' }}
                    placeholder="Buscar"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
                :
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    onChange={this.onSearch} style={{ width: '100%' }}
                    placeholder="Search"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
              }
            </Grid>

            <Grid item xs={4} style={{ textAlign: 'right', marginTop: 18 }}>

              <Select style={{ fontSize: 18 }} disableUnderline={true}
                value={this.state.selectedGroup}
                placeholder="Select Group"
                onChange={(event) => this.onGroupChange(event)}
                inputProps={{
                  name: 'year',
                  id: 'year',
                }}>

                <MenuItem value={0}>All Classrooms</MenuItem>

                {this.state.groups.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.custom_group_id}>{item.name}</MenuItem>
                  )
                })
                }
              </Select>

              <Select style={{ fontSize: 18, marginLeft: 10 }} disableUnderline={true}
                value={this.state.selectedGrade}
                placeholder="Select Grade"
                onChange={(event) => this.onGradeChange(event)}
                inputProps={{
                  name: 'year',
                  id: 'year',
                }}>

                <MenuItem value={0}>All Grades</MenuItem>

                {this.state.grades.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.grade_id}>{item.grade_name}</MenuItem>
                  )
                })
                }
              </Select>

            </Grid>

            <Grid item xs={2} style={{ alignItems: 'right', textAlign: 'right' }}>
              <div>
                <Button variant="contained" style={{ marginTop: 16 }}
                  color="primary" aria-label="Create" className={this.props.classes.margin}
                  onClick={(evt) => this.onExportClick(evt)}>
                  <SaveAltIcon className={this.props.classes.extendedIcon} style={{ color: '#FFF' }} />
                </Button>
                <Button variant="contained" style={{ marginTop: 16, marginLeft: 10 }}
                  color="primary" aria-label="Create" className={this.props.classes.margin}
                  onClick={() => this.onPrintClick()}>
                  <PrintIcon className={this.props.classes.extendedIcon} style={{ color: '#FFF' }} />
                </Button>
              </div>
            </Grid>

          </Grid>

          <Grid item xs={12}>

            <div style={{ marginTop: 10, marginBottom: 10 }}>

              <Card>
                <CardContent>
                  <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                    <Table className={classes.table}>
                      <TableHead>

                        <TableRow>

                          <TableCell className={classes.head}>
                            <Grid container style={{ width: 200 }}>
                              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => this.onSortClick("classroom_name")}>
                                <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                  <Typography style={{ fontSize: 12 }}>Classroom</Typography>
                                </div>
                                {this.state.sortBy === "classroom_name" ? (
                                  this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                                ) : null}
                              </div>
                            </Grid>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <Grid container style={{ width: 200 }}>
                              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("first_name")}>
                                {this.state.sortBy === "first_name" ? (
                                  <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>
                                    <Typography style={{ fontSize: 12 }}>First Name</Typography>
                                  </div>
                                ) :
                                  <div style={{ marginTop: 10, width: 60 }}>
                                    <Typography style={{ fontSize: 12 }}>First Name</Typography>
                                  </div>
                                }
                                {this.state.sortBy === "first_name" ? (
                                  this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                                ) : null}
                              </div>
                              {this.state.sortBy === "first_name" ?
                                <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                                : (this.state.sortBy === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                                  : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                              }
                              <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("last_name")}>
                                {this.state.sortBy === "last_name" ? (
                                  <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                    <Typography style={{ fontSize: 12 }}>Last Name</Typography>
                                  </div>
                                ) :
                                  <div style={{ marginTop: 10 }}>
                                    <Typography style={{ fontSize: 12 }}>Last Name</Typography>
                                  </div>
                                }
                                {this.state.sortBy === "last_name" ? (
                                  this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                                ) : null}
                              </div>
                            </Grid>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("grade_id")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Grade</Typography>
                              </div>
                              {this.state.sortBy === "grade_id" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("has_pathway")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Has Pathway</Typography>
                              </div>
                              {this.state.sortBy === "has_pathway" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("interest_inventory")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Interest Inventory</Typography>
                              </div>
                              {this.state.sortBy === "interest_inventory" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("learning_style")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Learning Style Inventory</Typography>
                              </div>
                              {this.state.sortBy === "learning_style" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("skills")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Skills</Typography>
                              </div>
                              {this.state.sortBy === "skills" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("goals")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Goals</Typography>
                              </div>
                              {this.state.sortBy === "goals" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("clubs")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Club & Sports</Typography>
                              </div>
                              {this.state.sortBy === "clubs" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("honors")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Honors & Awards</Typography>
                              </div>
                              {this.state.sortBy === "honors" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("has_resume")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Resume</Typography>
                              </div>
                              {this.state.sortBy === "has_resume" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("assessments")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Assessments</Typography>
                              </div>
                              {this.state.sortBy === "assessments" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("work_based_learning")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>Work Based Learning</Typography>
                              </div>
                              {this.state.sortBy === "work_based_learning" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                          <TableCell className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("completion_percent")}>
                              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 12 }}>% of Completion</Typography>
                              </div>
                              {this.state.sortBy === "completion_percent" ? (
                                this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                              ) : null}
                            </div>
                          </TableCell>

                        </TableRow>
                      </TableHead>

                      <TableBody>

                        {this.state.dataSource.map((row, index) => {

                          return (
                            <TableRow style={{ cursor: 'pointer' }} hover tabIndex={-1} key={index}>
                              <TableCell>{row.classroom_name}</TableCell>
                              <TableCell>{row.first_name} {row.last_name}</TableCell>
                              <TableCell>{row.grade_name}</TableCell>
                              <TableCell>{row.has_pathway}</TableCell>
                              <TableCell>{row.interest_inventory}</TableCell>
                              <TableCell>{row.learning_style}</TableCell>
                              <TableCell>{row.skills}</TableCell>
                              <TableCell>{row.goals}</TableCell>
                              <TableCell>{row.clubs}</TableCell>
                              <TableCell>{row.honors}</TableCell>
                              <TableCell>{row.has_resume}</TableCell>
                              <TableCell>{row.assessments}</TableCell>
                              <TableCell>{row.work_based_learning}</TableCell>
                              <TableCell>{row.completion_percent}%</TableCell>
                            </TableRow>
                          )
                        })}

                      </TableBody>

                    </Table>
                  </div>

                  <TablePagination
                    rowsPerPageOptions={[25]}
                    component="div"
                    rowsPerPage={this.state.perPage}
                    page={this.state.pageNumber}
                    count={correctedNumberOfStudents}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    onPageChange={this.onPageClick}
                  />

                </CardContent>
              </Card>

            </div>
          </Grid>

        </Grid>
        <iframe id="ifmcontentstoprint" style={{ display: 'none' }}></iframe>
      </div>
    );
  }
}

Report8Screen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    personId: state.auth.personId,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Report8Screen));