import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import BallotIcon from '@mui/icons-material/Ballot';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopy from '@mui/icons-material/FileCopy';
import SearchIcon from '@mui/icons-material/Search';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Avatar,
  Button,
  Checkbox,
  Dialog, DialogActions, DialogContent, DialogContentText,
  FormControl, FormControlLabel, FormGroup,
  Grid,
  IconButton,
  InputBase,
  Paper, Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class TestLibraryScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isCopyOpen: false,
      isDeleteOpen: false,
      isAssignOpen: false,
      gradeExpanded: true,
      searchText: '',
      height: 100,
      rowsPerPage: 25,
      currentPage: 0,
      perPage: 25,
      pageNumber: 0,
      students: [],
      grades: [],
      gradeList: [],
      dataSource: [],
      filteredDataSource: [],
      studentChoices: [],
      studentFilter: [],
      totalRows: [],
      selectedTest: 0,
      sortBy: '',
      sortDirection: 'desc'
    };

    this.onSearch = this.onSearch.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.onAssignClose = this.onAssignClose.bind(this);
    this.onCopyClose = this.onCopyClose.bind(this);
    this.onDeleteClose = this.onDeleteClose.bind(this);
    this.onNewClick = this.onNewClick.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  updateDimensions() {
    let update_height = window.innerHeight - 320;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.getData(this.state.searchText, this.state.gradeList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });


    this.updateDimensions();
  }

  getData(searchText, gradeList, studentFilter, perPage, pageNumber, sortBy, sortDirection) {

    var progress = 0;
    this.props.setProgress(progress);

    fetch('https://api.tasselpathway.com/tests', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            dataSource: responseJson.tests,
            filteredDataSource: responseJson.tests
          });
        } else {
          this.setState({
            isLoading: false,
            dataSource: [],
            filteredDataSource: []
          });
        }

      })
      .catch((error) => {
        this.props.onLogout();
      });

    fetch('https://api.tasselpathway.com/students?search=' + searchText + '&grade=' + gradeList.join() + '&student=' + studentFilter.join()
      + '&per_page=' + perPage + '&page_number=' + pageNumber + '&sort_by=' + sortBy
      + '&sort_direction=' + sortDirection, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
            totalRows: responseJson.total_results
          })
        }

        progress = progress + 25;
        this.props.setProgress(progress);
      })
      .catch((error) => {

        progress = progress + 25;
        this.props.setProgress(progress);

        this.setState({
          isLoading: false
        })
      });
  }

  onNewClick() {

    fetch('https://api.tasselpathway.com/tests', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId
      })
    })
      .then(response => {
        return response.json()
      })
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.props.history.push('/edit_test/' + responseJson.result[0].test_id);
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  onSubmitClick() {

    var student = []

    for (let i = 0; i < this.state.dataSource.length; i++) {
      if (this.state.studentChoices['choice_' + this.state.dataSource[i].person_id] === 1) {
        student.push(
          {
            person_id: this.state.dataSource[i].person_id,
            grade_id: this.state.dataSource[i].grade_id
          }
        );
      }
    }

    fetch("https://api.tasselpathway.com/profile_tests", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        test_id: this.state.selectedTest,
        person_id: this.props.personId,
        students: student
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isAssignOpen: false,
            isLoading: false
          })
        }
        this.getData(this.state.searchText, [], this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
      })
      .catch((error) => {

        this.setState({
          isAssignOpen: false,
          isLoading: false
        })
      });
  }

  onRowClick(event, item) {

    if (this.props.userRoleId >= 4) {

      this.props.history.push('/view_test/' + item.test_id);

    } else {

      this.props.history.push('/edit_test/' + item.test_id);
    }
  }

  copyTest() {
    fetch('https://api.tasselpathway.com/tests/copy', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        test_id: this.state.selectedTest,
        person_id: this.props.personId
      })
    })
      .then(response => {
        return response.json()
      })
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.props.history.push('/edit_test/' + responseJson.result[0].test_id);
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  deleteTest() {

    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/tests', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        district_id: this.props.districtId,
        test_id: this.state.selectedTest,
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isDeleteOpen: false,
          selectedTest: 0
        });

        this.getData();

      })
      .catch((error) => {
        this.setState({
          isDeleteOpen: false,
          selectedTest: 0
        })
      });
  }

  onCopyClick(testId) {

    this.setState({
      isCopyOpen: true,
      selectedTest: testId
    })
  }

  onAssignClick(testId) {
    this.setState({
      isAssignOpen: true,
      selectedTest: testId
    })
  }

  onAssignClose() {
    this.setState({
      isAssignOpen: false
    })
  }

  onCopyClose() {
    this.setState({
      isCopyOpen: false
    })
  }

  onDeleteClick(testId) {
    this.setState({
      isDeleteOpen: true,
      selectedTest: testId
    })
  }

  onDeleteClose() {
    this.setState({
      isDeleteOpen: false
    })
  }

  onSearch(event) {

    const newData = this.state.dataSource.filter(function (item) {
      const itemData = item.test_name.toUpperCase()
      const textData = event.target.value.toUpperCase()
      return itemData.indexOf(textData) > -1
    })
    this.setState({
      filteredDataSource: newData,
      searchText: event.target.value
    })
  }

  onGradeClick(event, gradeId) {

    if (event.target.checked !== undefined) {

      var gradeList = this.state.gradeList;

      if (event.target.checked) {
        gradeList.push(gradeId);
      } else {
        for (var i = 0; i < gradeList.length; i++) {
          if (gradeList[i] === gradeId) {
            gradeList.splice(i, 1);
          }
        }
      }

      this.setState({
        gradeList: gradeList,
        pageNumber: 0
      })

      if (this.state.sortBy !== '' && this.state.sortDirection !== '') {
        this.getData(this.state.searchText, this.state.gradeList, this.state.studentFilter, this.state.perPage, 0, this.state.sortBy, this.state.sortDirection);
      } else {
        this.getData(this.state.searchText, this.state.gradeList, this.state.studentFilter, this.state.perPage, 0, 'last_name', 'asc');
      }
    }

  }

  onSortClick(col) {

    var sortBy = col;
    var sortDirection = this.state.sortBy === col ? invertSort[this.state.sortDirection] : 'asc';

    this.setState({
      sortBy: sortBy,
      sortDirection: sortDirection
    })

    this.getData(this.state.searchText, this.state.gradeList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, sortBy, sortDirection);
  }

  onStudentCheck = (event, personId) => {

    var choices = this.state.studentChoices

    for (let i = 0; i < this.state.dataSource.length; i++) {
      if (this.state.dataSource[i].person_id === personId && choices['choice_' + this.state.dataSource[i].person_id] === 1) {
        choices['choice_' + this.state.dataSource[i].person_id] = 0;
      }
      else if (this.state.dataSource[i].person_id === personId) {
        choices['choice_' + this.state.dataSource[i].person_id] = 1;
      }
    }

    this.setState({
      studentChoices: choices
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  onPageClick(event, newPage) {

    if (newPage > this.state.pageNumber) {
      this.onNextClick();
    } else {
      this.onPreviousClick();
    }
  }

  onPreviousClick() {
    var currentPage = this.state.pageNumber;

    if (currentPage > 0) {

      currentPage = currentPage - 1;

      this.setState({
        pageNumber: currentPage
      })
    }

    this.getData(this.state.searchText, this.state.gradeList, this.state.studentFilter, this.state.perPage, currentPage, this.state.sortBy, this.state.sortDirection);
  }

  onNextClick() {
    var currentPage = this.state.pageNumber;

    currentPage = currentPage + 1;

    this.setState({
      pageNumber: currentPage
    })

    this.getData(this.state.searchText, this.state.gradeList, this.state.studentFilter, this.state.perPage, currentPage, this.state.sortBy, this.state.sortDirection);
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.filteredDataSource.length - this.state.currentPage * this.state.rowsPerPage);

    const numberOfStudents = this.state.totalRows
    const correctedNumberOfStudents = numberOfStudents

    return (

      <div className={classes.root}>

        <Dialog open={this.state.isAssignOpen}
          onClose={this.onAssignClose} maxWidth="lg"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onAssignClose}>
            Assign Test
          </DialogTitle>

          <DialogContent>

            <Grid container style={{ overflow: 'auto' }}>
              <Grid item xs={2}>
                <Accordion square expanded={this.state.gradeExpanded} classes={{ expanded: classes.expanded }}
                  onChange={() => this.setState({ gradeExpanded: !this.state.gradeExpanded })}
                  style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                    <FormControl className={classes.formControl}
                      style={{
                        height: 300, borderBottom: '1px solid #eaeaea', marginLeft: 5,
                        paddingLeft: 10, width: '100%', overflow: 'auto'
                      }}>
                      <FormGroup>

                        {this.state.grades.map((item, key) => {

                          return (
                            <FormControlLabel key={key} onClick={(event) => this.onGradeClick(event, item.grade_id)}
                              control={
                                <Checkbox />
                              }
                              label={<Typography style={{ fontSize: 11 }}>{item.grade_name}</Typography>}
                            />
                          )

                        })}

                      </FormGroup>

                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={10}>

                <Paper square style={{ marginLeft: 20, marginRight: 20, marginTop: 20, borderRadius: 0 }}>
                  <div className={classes.tableWrapper} style={{ maxHeight: this.state.height, }}>
                    <Table className={classes.table}>
                      <TableHead >
                        <TableRow>
                          <TableCell className={classes.head}>&nbsp;</TableCell>
                          <TableCell className={classes.head}>
                            <Grid container style={{ width: 200, zIndex: 1 }}>
                              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("first_name")}>
                                {this.state.sortBy === "first_name" ? (
                                  <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>
                                    <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                  </div>
                                ) :
                                  <div style={{ marginTop: 10, width: 60 }}>
                                    <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                  </div>
                                }
                                {this.state.sortBy === "first_name" ? (
                                  this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                                ) : null}
                              </div>
                              {this.state.sortBy === "first_name" ?
                                <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                                : (this.state.sortBy === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                                  : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                              }
                              <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("last_name")}>
                                {this.state.sortBy === "last_name" ? (
                                  <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                    <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                  </div>
                                ) :
                                  <div style={{ marginTop: 10 }}>
                                    <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                  </div>
                                }
                                {this.state.sortBy === "last_name" ? (
                                  this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                                ) : null}
                              </div>
                            </Grid>
                          </TableCell>
                          <TableCell style={{ zIndex: 1, }} className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("grade_name")}>
                              {this.state.sortBy === "grade_name" ? (
                                <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                  <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                </div>
                              ) :
                                <div style={{ marginTop: 10 }}>
                                  <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                </div>
                              }
                              {this.state.sortBy === "grade_name" ? (
                                this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell style={{ zIndex: 1, }} className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("email_address")}>
                              {this.state.sortBy === "email_address" ? (
                                <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                  <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                </div>
                              ) :
                                <div style={{ marginTop: 10 }}>
                                  <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                </div>
                              }
                              {this.state.sortBy === "email_address" ? (
                                this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell style={{ zIndex: 1, }} className={classes.head}>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("school_name")}>
                              {this.state.sortBy === "school_name" ? (
                                <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                  <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                </div>
                              ) :
                                <div style={{ marginTop: 10 }}>
                                  <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                </div>
                              }
                              {this.state.sortBy === "school_name" ? (
                                this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell style={{ zIndex: 1, }} className={classes.head}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {this.state.dataSource.map((row, index) => {

                          return (
                            <TableRow style={{ cursor: 'pointer' }} hover
                              key={index}>
                              <TableCell style={{ width: 25 }}>
                                {row.logo_image_url === "" ?
                                  <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center', zIndex: 0 }}
                                    src={require('../../../assets/default_avatar.png')} />
                                  :
                                  <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center', zIndex: 0 }}
                                    src={row.logo_image_url} />
                                }
                              </TableCell>
                              <TableCell style={{ width: 150 }}><Typography style={{ fontSize: 12 }}>{row.first_name} {row.last_name}</Typography></TableCell>
                              <TableCell style={{ width: 100 }}><Typography style={{ fontSize: 12 }}>{row.grade_name}</Typography></TableCell>
                              <TableCell style={{ width: 50 }}><Typography style={{ fontSize: 12 }}>{row.email_address}</Typography></TableCell>
                              <TableCell><Typography style={{ fontSize: 12 }}>{row.school_name}</Typography></TableCell>
                              <TableCell >
                                <FormControlLabel onClick={(event) => this.onStudentCheck(event, row.person_id)}
                                  control={<Checkbox color='default' checked={this.state.studentChoices['choice_' + row.person_id]} />}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                      </TableBody>
                    </Table>

                  </div>

                  <TablePagination
                    rowsPerPageOptions={[25]}
                    component="div"
                    rowsPerPage={this.state.perPage}
                    page={this.state.pageNumber}
                    count={correctedNumberOfStudents}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    onPageChange={this.onPageClick}
                  />

                </Paper>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 35, marginTop: 10, borderRadius: 0, marginBottom: 10 }}
              onClick={() => this.onSubmitClick()}
              color="primary">
              <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Assign Test</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isCopyOpen}
          onClose={this.onCopyClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm" fullWidth={true}>
          <DialogTitle id="customized-dialog-title"
            onClose={this.onCopyClose}>
            Copy Test
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>Are you sure you want to copy this test?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.copyTemplate()} variant="contained" style={{ backgroundColor: '#D9534F' }} >
              <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>Yes</Typography>
            </Button>
            <Button onClick={() => this.onCopyClose()} variant="contained" color="primary" autoFocus>
              <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>No</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isDeleteOpen}
          onClose={() => this.onDeleteClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title"
            onClose={this.onDeleteClose}>
            Delete Test
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>Are you sure you want to delete this test?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.deleteTest()} variant="contained" style={{ backgroundColor: '#D9534F' }} >
              <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>Yes</Typography>
            </Button>
            <Button onClick={() => this.onDeleteClose()} variant="contained" color="primary" autoFocus>
              <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>No</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container>
          <Grid item xs={2}>
            {this.props.userRoleId === 1 ?
              <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 0 }}
                onClick={() => this.onNewClick()}>
                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>{i18n.t("ADMIN_TESTS_NEW_TEST")}</Typography>
              </Button>
              : null}
          </Grid>
          <Grid item xs={6}>

          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            {this.props.selectedLanguage === "esp" ?
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={this.onSearch} style={{ width: '100%' }}
                  placeholder="Buscar"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              :
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={this.onSearch} style={{ width: '100%' }}
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            }
          </Grid>
        </Grid>

        {this.state.dataSource.length === 0 && this.props.userRoleId <= 5 ?

          <Grid container spacing={2} style={{ justifyContent: 'center', textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>

            <Grid item xs={12} style={{ marginLeft: 20, marginTop: 40, marginRight: 20 }}>

              <BallotIcon style={{ color: '#25D192', fontSize: 150 }} />

              <Typography style={{ fontSize: 24 }}>It looks like you haven't created any tests yet!</Typography>

              <Typography style={{ fontSize: 16, marginLeft: 50, marginRight: 50, marginTop: 20 }}>This is the place to create all of your district tests.<br /> You can create your own from scratch,
                or start with one of our pre-made tests. The choice is yours!</Typography>

              <Button variant="contained" color="primary" style={{ fontSize: 20, borderRadius: 0 }}
                onClick={() => this.onNewClick()}>
                <Typography style={{ fontWeight: 'bold', fontSize: 18, color: '#FFF' }}>Create A New Test</Typography>
              </Button>

            </Grid>

          </Grid>

          :

          <Paper style={{ marginTop: 10, orderRadius: 0 }}>
            <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
              <Table className={classes.table} style={{ height: this.state.height }}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("test_name")}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TESTS_TEST_NAME")}</Typography>
                        {this.state.sortBy === "test_name" ? (
                          this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("test_type_name")}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TESTS_TEST_TYPE")}</Typography>
                        {this.state.sortBy === "test_type_name" ? (
                          this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("visibility_name")}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TESTS_TEST_VISIBILITY")}</Typography>
                        {this.state.sortBy === "visibility_name" ? (
                          this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("first_name")}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TESTS_TEST_OWNER")}</Typography>
                        {this.state.sortBy === "first_name" ? (
                          this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("date_modified")}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TESTS_TEST_DATE_MODIFIED")}</Typography>
                        {this.state.sortBy === "date_modified" ? (
                          this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell className={classes.head}>&nbsp;</TableCell>
                    <TableCell className={classes.head}>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                    .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={index} classes={classes.tableRow}
                          hover style={{ cursor: 'pointer' }}>
                          <TableCell onClick={event => this.onRowClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.test_name}</Typography></TableCell>
                          <TableCell onClick={event => this.onRowClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.test_type_name}</Typography></TableCell>
                          <TableCell onClick={event => this.onRowClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.visibility_name}</Typography></TableCell>
                          <TableCell onClick={event => this.onRowClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.first_name} {row.last_name}</Typography></TableCell>
                          <TableCell onClick={event => this.onRowClick(event, row)}><Typography style={{ fontSize: 12 }}>{moment(row.date_modified).format('MM/DD/YYYY')}</Typography></TableCell>
                          <TableCell>
                            {this.props.userRoleId <= 3 ?
                              <Button variant="contained" color="primary" style={{ fontSize: 20, borderRadius: 0 }}
                                onClick={() => this.onAssignClick(row.test_id)}>
                                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>{i18n.t("ADMIN_TESTS_ASSIGN_TEST")}</Typography>
                              </Button> : null
                            }
                          </TableCell>
                          <TableCell>
                            {this.props.userRoleId <= 3 ?
                              <IconButton color="primary" variant="contained" onClick={() => this.onCopyClick(row.test_id)}>
                                <FileCopy />
                              </IconButton> : null
                            }
                            {this.props.userRoleId <= 3 && row.person_id === this.props.personId ?
                              <IconButton color="primary" variant="contained" onClick={() => this.onDeleteClick(row.test_id)}>
                                <DeleteIcon />
                              </IconButton> : null
                            }
                          </TableCell>

                        </TableRow>
                      )
                    }
                    )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 50 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.currentPage}
              count={this.state.filteredDataSource.length}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />

          </Paper>
        }
      </div>
    )
  }
}

TestLibraryScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(TestLibraryScreen)));