import {
  Avatar, List, ListItem, ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText, Typography
} from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class DistrictLeaderboard extends Component {
  render() {
    return (
      <div>
        <List style={{ overflow: 'auto', maxHeight: 300 }}>

          {this.props.district_leaders.map((row, key) => {
            return (

              <ListItem key={key} button={false}>
                <ListItemAvatar>
                  {row.logo_image_url === "" ?
                    <Avatar alt="MSU" style={{ height: 35, width: 35 }} src={require('../../../assets/default_avatar.png')} />
                    :
                    <Avatar alt="MSU" style={{ height: 35, width: 35 }} src={row.logo_image_url} />
                  }
                </ListItemAvatar>
                <ListItemText style={{ fontSize: 14 }} primary={row.first_name + " " + row.last_name} />
                <ListItemSecondaryAction>
                  <Typography style={{ fontSize: 14 }}>{row.total_points}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })
          }

        </List>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    personId: state.auth.personId,
    userRoleId: state.auth.userRoleId,
    districtId: state.profile.districtId,
    taskId: state.task.taskId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default (withTranslation("translate"))(connect(mapStateToProps, mapDispatchToProps)(DistrictLeaderboard));