import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import {
  Button,
  Card, CardContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

class Report6Screen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      reports: [],
      dataSource: [],
      reportId: 0,
      selectedSchool: 0,
      schools: [],
      height: 0,
      reportName: '',
      rowsPerPage: 10,
      currentPage: 0
    }
  }

  updateDimensions() {
    let update_height = window.innerHeight - 200
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    var school = this.state.selectedSchool;

    window.addEventListener("resize", this.updateDimensions.bind(this));

    fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            schools: responseJson.results
          });

          this.getData(school);
        }

      })
      .catch((error) => {

      });

  }

  getData(school) {

    fetch('https://api.tasselpathway.com/report6/learning_style/' + school, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          });
        }

        this.updateDimensions();

      })
      .catch((error) => {

      });
  }

  renderRows() {

    var content = this.state.dataSource.map((item, key) => {
      return (
        <TableRow style={{ cursor: 'pointer' }} hover tabIndex={-1} key={key}>
          <TableCell>{item.first_name}</TableCell>
          <TableCell>{item.last_name}</TableCell>
          <TableCell>{item.school_name}</TableCell>
          <TableCell>{item.grade_name}</TableCell>
          <TableCell>{item.learning_style_name}</TableCell>
          <TableCell>{item.percentage * 100}%</TableCell>
        </TableRow>
      )

    });

    return content;
  }

  onPrintClick() {

    fetch('https://api.tasselpathway.com/report6/' + this.state.selectedSchool + '/html', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token,
        'selectedSchool': this.state.selectedSchool
      },
      credentials: 'omit'
    })
      .then((response) => response.text())
      .then((responseJson) => {

        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(responseJson);
        pri.document.close();
        pri.focus();
        pri.print();

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
      });
  }

  onSchoolChange(event) {

    this.getData(event.target.value);
    this.setState({ selectedSchool: event.target.value })
  }

  scrollToTop() {
    const report = this.refs.report;
    if (report) {
      report.scrollTo(300, 900);
    }
  }

  onBackClick() {
    this.props.history.push('/reports');
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.dataSource.length - this.state.currentPage * this.state.rowsPerPage);

    moment('en');

    return (
      <div className={classes.root} ref={`report`}>


        <Grid id="report_6" style={styles.itemContainerStyle}>


          <Grid container>

            <Grid item xs={1}>
              <IconButton style={{ color: '#1F3545', marginTop: 10, fontSize: 28 }} onClick={() => this.onBackClick()}>
                <ArrowBackIcon style={{ fontSize: 28 }} />
              </IconButton>
            </Grid>

            <Grid item xs={3}>
              <Typography style={{ fontSize: 24, marginTop: 15 }}>Learning Style Report</Typography>
            </Grid>

            <Grid item xs={4} style={{ textAlign: 'right', marginTop: 18 }}>

              <Select style={{ fontSize: 18 }} disableUnderline={true}
                value={this.state.selectedSchool}
                placeholder="Select School"
                onChange={(event) => this.onSchoolChange(event)}
                inputProps={{
                  name: 'year',
                  id: 'year',
                }}>

                <MenuItem value={0}>All Schools</MenuItem>

                {this.state.schools.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
                  )
                })
                }
              </Select>

            </Grid>

            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <div>
                {/*
              <Button variant="contained" style={{marginTop:16}}
                color="primary" aria-label="Create" className={this.props.classes.margin}
                onClick={() => this.onExportClick()}>
                <SaveAltIcon className={this.props.classes.extendedIcon} style={{color:'#FFF'}} />
              </Button> */}
                <Button variant="contained" style={{ marginTop: 16, marginLeft: 10 }}
                  color="primary" aria-label="Create" className={this.props.classes.margin}
                  onClick={() => this.onPrintClick()}>
                  <PrintIcon className={this.props.classes.extendedIcon} style={{ color: '#FFF' }} />
                </Button>
              </div>
            </Grid>

          </Grid>

          <Grid item xs={12}>

            <div style={{ marginTop: 10, marginBottom: 10 }}>

              <Card>
                <CardContent>
                  <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>School</TableCell>
                          <TableCell>Grade Taken</TableCell>
                          <TableCell>Learning Style</TableCell>
                          <TableCell>Percentage</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>

                        {this.renderRows()}

                        {emptyRows > 0 && (
                          <TableRow style={{ height: 50 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}

                      </TableBody>

                    </Table>
                  </div>
                </CardContent>
              </Card>

            </div>
          </Grid>

        </Grid>
        <iframe id="ifmcontentstoprint" style={{ display: 'none' }}></iframe>
      </div>
    );
  }
}

Report6Screen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    personId: state.auth.personId,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Report6Screen));