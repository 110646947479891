import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ListItem,
  Radio, RadioGroup,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';
import './styles.css';
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class CivicScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      value: 0,
      civic: [],
      hasPassed: 0,
      dataSource: [],
      showConfetti: false,
      selectedItem: {},
      totalPages: 0,
      totalCorrect: 0,
      gradeCompleted: '',
      dateCompleted: '',
      currentPage: 0,
      viewCurrentQuestion: 0,
      choices: [],
      totals: [],
      isNewOpen: false,
      isResultOpen: false,
      civicsBadgeSrc: ''
    }

    this.onNewClose = this.onNewClose.bind(this);
    this.onResultClose = this.onResultClose.bind(this);
  }

  componentDidMount() {

    fetch('https://api.tasselpathway.com/civic/1', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            civic: responseJson.results,
            totalPages: responseJson.total_pages
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    this.getData(this.props.profileId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(profileId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_civic/person/' + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          if (responseJson.results.length > 0) {
            this.setState({
              isLoading: false,
              hasPassed: 1,
              totalCorrect: responseJson.results[0].total_correct,
              gradeCompleted: responseJson.results[0].grade_name,
              dateCompleted: responseJson.results[0].date_completed,
              logoUrl: responseJson.results[0].image_url
            })
          } else {
            this.setState({
              isLoading: false,
              hasPassed: 0,
            })
          }
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onPreviousClick() {
    if (this.state.currentPage > 0) {
      this.setState({
        currentPage: this.state.currentPage - 1
      })
    }
  }

  onNextClick() {

    if (this.state.currentPage + 1 < this.state.totalPages) {
      this.setState({
        currentPage: this.state.currentPage + 1
      })
    }
  }

  onNewClick() {
    this.setState({
      isNewOpen: true,
      totalCorrect: 0
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onResultClose() {
    this.setState({
      isResultOpen: false
    })
  }

  handleChange = (event, value) => {
    this.setState({
      value
    });
  }

  onFinishClick() {
    var totalCorrect = 0;

    this.state.civic.map((question, key) => {
      question.choices.map((choice, key2) => {

        if (choice.is_correct === 1 && this.state.choices['choice_' + choice.choice_id] === 1) {
          totalCorrect++;
        }
      })
    })

    if (totalCorrect > 60) {
      this.confetti()
      fetch('https://api.tasselpathway.com/profile_civic', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          person_id: this.props.profileId,
          civic_type_id: this.state.civic[0].civic_type_id,
          grade_id: this.props.gradeId,
          total_correct: totalCorrect
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.error === false) {
            this.setState({
              isNewOpen: false,
              isResultOpen: true,
              hasPassed: 1,
              totalCorrect: totalCorrect
            })
          }
        })
        .catch((error) => {
          this.setState({
            isNewOpen: false,
            isLoading: false
          })
        });
    } else {
      this.setState({
        isNewOpen: false,
        isResultOpen: true,
        hasPassed: 0,
        currentPage: 0,
        totalCorrect: totalCorrect
      })
    }
  }

  onChoiceSelect(e, item, choice) {

    if (e != null)
      e.preventDefault();

    var choices = this.state.choices;

    for (var j = 0; j < item.choices.length; j++) {
      if (item.choices[j].choice_id === choice) {
        if (choices['choice_' + item.choices[j].choice_id] === 1) {
          choices['choice_' + item.choices[j].choice_id] = 0;
        } else {
          choices['choice_' + item.choices[j].choice_id] = 1;
        }
      } else {
        choices['choice_' + item.choices[j].choice_id] = 0;
      }
    }

    this.setState({
      choices: choices
    })

  }


  confetti = () => {

    this.setState({
      showConfetti: true
    })

    var canvas = this.refs.confetti;
    var ctx = canvas.getContext("2d");


    var W = window.innerWidth;
    var H = window.innerHeight;
    canvas.width = 400;
    canvas.height = 400;


    var mp = 170;
    var types = ['circle', 'line', 'line'];
    var colors = [
      [238, 96, 169],
      [68, 213, 217],
      [245, 187, 152],
      [144, 148, 188],
      [235, 234, 77]
    ];
    var angles = [
      [4, 0, 4, 4],
      [2, 2, 0, 4],
      [0, 4, 2, 2],
      [0, 4, 4, 4]
    ]
    var particles = [];
    for (var i = 0; i < mp; i++) {
      particles.push({
        x: Math.random() * W,
        y: Math.random() * H,
        r: Math.random() * 4 + 1,
        d: Math.random() * mp,
        l: Math.floor(Math.random() * 65 + -30),
        a: angles[Math.floor(Math.random() * angles.length)],
        c: colors[Math.floor(Math.random() * colors.length)],
        t: types[Math.floor(Math.random() * types.length)]
      })
    }

    function draw() {
      ctx.clearRect(0, 0, W, H);


      for (var i = 0; i < mp; i++) {
        var p = particles[i];
        var op = (p.r <= 3) ? 0.4 : 0.8;

        if (p.t === 'circle') {
          ctx.fillStyle = "rgba(" + p.c + ", " + op + ")";
          ctx.beginPath();
          ctx.moveTo(p.x, p.y);
          ctx.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
          ctx.fill();
        } else if (p.t === 'triangle') {
          ctx.fillStyle = "rgba(" + p.c + ", " + op + ")";
          ctx.beginPath();
          ctx.moveTo(p.x, p.y);
          ctx.lineTo(p.x + (p.r * p.a[0]), p.y + (p.r * p.a[1]));
          ctx.lineTo(p.x + (p.r * p.a[2]), p.y + (p.r * p.a[3]));
          ctx.closePath();
          ctx.fill();
        } else if (p.t === 'line') {
          ctx.strokeStyle = "rgba(" + p.c + "," + op + ")";
          ctx.beginPath();
          ctx.moveTo(p.x, p.y);
          ctx.lineTo(p.x + p.l, p.y + (p.r * 5));
          ctx.lineWidth = 2;
          ctx.stroke();
        }

      }
      update();
    }



    function update() {

      for (var i = 0; i < mp; i++) {
        var p = particles[i];
        p.y += Math.cos(p.d) + 1 + p.r / 2;
        p.x += Math.sin(0) * 2;

        if (p.x > W + 5 || p.x < -5 || p.y > H) {
          particles[i] = {
            x: Math.random() * W,
            y: -10,
            r: p.r,
            d: p.d,
            l: p.l,
            a: p.a,
            c: p.c,
            t: p.t
          };
        }
      }
    }

    var startTime = new Date().getTime();
    setInterval(draw, 16);
    var interval = setInterval(function () {
      if (new Date().getTime() - startTime > 6000) {
        canvas.className += "hideMe";
        clearInterval(interval);

        this.setState({
          showConfetti: false
        });

        return;
      }
    }, 10);
  }


  renderQuestions() {

    var content = this.state.civic.map((item, key) => {

      if (item.page_number === this.state.currentPage + 1) {
        if (this.props.selectedLanguage === "esp") {
          return (
            <ListItem key={key}>

              <Grid container style={{ padding: 20 }}>

                <Typography style={{ fontSize: 18, marginBottom: 20, color: '#25D192', fontWeight: 'bold' }}>
                  {item.question_name_spanish}
                </Typography>

                <RadioGroup aria-label="position" name="position"
                  value={this.state.choices['choice_' + item.choices[0].choice_id]} row>

                  {
                    item.choices.map((choice, key2) => {
                      return (

                        <Grid key={key2} item xs={12}>
                          <FormControlLabel
                            onClick={(event) => this.onChoiceSelect(event, item, choice.choice_id)}
                            control={
                              <Radio checked={this.state.choices['choice_' + choice.choice_id] ? true : false} />
                            }
                            label={choice.choice_name_spanish}
                          />
                        </Grid>
                      )
                    })
                  }

                </RadioGroup>
              </Grid>

            </ListItem>

          )
        } else {
          return (
            <ListItem key={key}>

              <Grid container style={{ padding: 20 }}>

                <Typography style={{ fontSize: 18, marginBottom: 20, color: '#25D192', fontWeight: 'bold' }}>
                  {item.question_name}
                </Typography>

                <RadioGroup aria-label="position" name="position"
                  value={this.state.choices['choice_' + item.choices[0].choice_id]} row>

                  {
                    item.choices.map((choice, key2) => {
                      return (

                        <Grid key={key2} item xs={12}>
                          <FormControlLabel
                            onClick={(event) => this.onChoiceSelect(event, item, choice.choice_id)}
                            control={
                              <Radio checked={this.state.choices['choice_' + choice.choice_id] ? true : false} />
                            }
                            label={choice.choice_name}
                          />
                        </Grid>
                      )
                    })
                  }

                </RadioGroup>
              </Grid>

            </ListItem>

          )
        }
      }
    })

    return content;
  }

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    return (
      <div className={classes.root} ref="confettiContainer">

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            {i18n.t("CIVICS_EXAM_TILE")}
          </DialogTitle>

          <DialogContent>
            <ImageList spacing={10} cols={2} cellHeight={275}>
              {this.renderQuestions()}
            </ImageList>
          </DialogContent>

          <DialogActions>

            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>

              <Grid item xs={2}>

                {this.state.currentPage === 0 ? null :
                  <Button onClick={() => this.onPreviousClick()}
                    style={{ backgroundColor: '#25D192', marginLeft: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("CIVICS_BACK")}</Typography>
                  </Button>
                }

              </Grid>

              <Grid item xs={8} style={{ textAlign: 'center' }}>
                <Typography style={{ fontSize: 16, marginTop: 10, fontWeight: 'bold' }}>{this.state.currentPage + 1} / {this.state.totalPages}</Typography>
              </Grid>

              <Grid item xs={2} style={{ textAlign: 'right' }}>

                {this.state.currentPage + 1 === this.state.totalPages ?
                  <Button onClick={() => this.onFinishClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("CIVICS_FINISH")}</Typography>
                  </Button>
                  :
                  <Button onClick={() => this.onNextClick()} style={{ backgroundColor: '#25D192', marginRight: 10 }}>
                    <Typography style={{ color: '#FFF', fontSize: 16, fontWeight: 'bold' }}>{i18n.t("CIVICS_NEXT")}</Typography>
                  </Button>
                }

              </Grid>

            </Grid>

          </DialogActions>

        </Dialog>

        {this.state.showConfetti === true ?
          <div style={{ display: "flex", justifyContent: 'center' }}>
            <canvas id="confetti" ref="confetti"></canvas>
          </div>
          : null}

        <Dialog open={this.state.isResultOpen}
          onClose={this.onResultClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onResultClose}>
            {i18n.t("CIVICS_EXAM_RESULTS")}
          </DialogTitle>

          <DialogContent>
            {this.state.hasPassed === 1 ?
              <div>

                <Grid contained>

                  <Typography><strong>{i18n.t("CIVICS_AWESOME")}</strong></Typography>
                  <Typography>{i18n.t("CIVICS_YOU_SCORED")} {this.state.totalCorrect}% {i18n.t("CIVICS_OF_TOTAL")}</Typography>
                </Grid>
              </div>
              :
              <div>
                <Grid contained>

                  <Typography><strong>{i18n.t("CIVICS_SORRY")}</strong></Typography>
                  <Typography>{i18n.t("CIVICS_YOU_SCORED")} {this.state.totalCorrect}% {i18n.t("CIVICS_OF_TOTAL")}.</Typography>
                  <Typography>{i18n.t("CIVICS_RETRY")}</Typography>
                </Grid>
              </div>
            }

          </DialogContent>

          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => this.onResultClose()}
              style={{ marginRight: 20 }}>
              <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>{i18n.t("CIVICS_OK")}</Typography>
            </Button>

          </DialogActions>

        </Dialog>

        <Grid container spacing={12}>

          <Grid item xs={12}>

            <Card style={{ width: '100%', height: 300, borderRadius: 3 }}>
              <CardContent>

                <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                  <Grid item xs={10}>
                    <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                      {i18n.t("CIVICS_EXAM_TILE")}
                    </Typography>
                  </Grid>
                </Grid>

                {this.state.hasPassed === 0 ?

                  <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ marginTop: 75 }}>
                    {this.state.hasPassed === 0 && this.props.profileId === this.props.personId ?
                      <Button color="primary" variant="contained" onClick={() => this.onNewClick()}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 16, color: '#FFF' }}>{i18n.t("CIVICS_EXAM_START")}</Typography>
                      </Button>
                      : null}
                  </Grid>

                  :
                  <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ marginTop: 25 }}>
                    <img src={this.state.logoUrl} height='105' alt="Civics" />
                    <Typography style={{ textAlign: 'center', fontWeight: 'bold', marginTop: 20 }}>{i18n.t("CIVICS_PASSED")}{this.state.dateCompleted === '' ? moment(Date.now()).format('MM/DD/YYYY') : moment(this.state.dateCompleted).format('MM/DD/YYYY')}</Typography>
                  </Grid>
                }

              </CardContent>
            </Card>
          </Grid>

        </Grid>

      </div>
    );
  }
}

CivicScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    profileId: state.profile.profileId,
    gradeId: state.profile.gradeId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CivicScreen)));