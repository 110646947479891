import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import { setProgress } from '../../../../redux/actions/progress';
import styles from './style';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

class BuiltInTaskScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      filteredDataSource: [],
      isNewOpen: false,
      selectedItem: {},
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc'
    }

    this.handleChangePage = this.handleChangePage.bind(this);

  }

  updateDimensions() {
    let update_height = window.innerHeight - 300;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.getData();

    this.updateDimensions();
  }

  getData() {
    fetch('https://api.tasselpathway.com/system_task', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
            filteredDataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onAddClick(event, row) {

    fetch('https://api.tasselpathway.com/tasks', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        category_id: row.category_id,
        task_name: row.task_name,
        task_name_spanish: row.task_name_spanish,
        task_description: row.task_description,
        task_description_spanish: row.task_description_spanish,
        estimated_time: row.estimated_time,
        total_points: row.total_points,
        visibility_id: 3,
        person_id: this.props.personId,
        schools: []
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false
        })
        this.props.onAddClose();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isNewOpen: false
        })
      })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Grid container style={styles.itemContainerStyle}>

          <Grid item xs={12}>

            <Paper square style={{ marginLeft: 20, marginRight: 20, marginTop: 20, borderRadius: 0 }}>
              <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.head}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("TASKS_TABLE_TASK_NAME")}</Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.head}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("TASKS_TABLE_TASK_CATEGORY")}</Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.head}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("TASKS_TABLE_TASK_DESCRIPTION")}</Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.head}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("TASKS_ESTIMATED_TIME")}</Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.head}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                          <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                            <Typography style={{ fontSize: 12 }}>{i18n.t("TASKS_POINTS")}</Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                      .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                      .map((row, index) => {
                        if (this.props.selectedLanguage === "esp") {
                          return (
                            <TableRow style={{ cursor: 'pointer' }} hover key={index}>
                              <TableCell style={{ width: 300 }}><Typography style={{ fontSize: 12 }}>{row.task_name}</Typography></TableCell>
                              <TableCell style={{ width: 200, fontSize: 12 }}>{row.category_name}</TableCell>
                              <TableCell style={{ width: 500, fontSize: 12 }}><div dangerouslySetInnerHTML={{ __html: row.task_description }} /></TableCell>
                              <TableCell style={{ fontSize: 12 }}>{row.estimated_time}</TableCell>
                              <TableCell style={{ fontSize: 12 }}>{row.total_points}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                <Button variant="contained" color="secondary"
                                  onClick={(event) => this.onAddClick(event, row)} style={{ borderRadius: 3 }}>
                                  <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>Add</Typography>
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        } else {
                          return (
                            <TableRow style={{ cursor: 'pointer' }} hover key={index}>
                              <TableCell style={{ width: 200 }}><Typography style={{ fontSize: 12 }}>{row.task_name}</Typography></TableCell>
                              <TableCell style={{ width: 200, fontSize: 12 }}>{row.category_name}</TableCell>
                              <TableCell style={{ width: 400, fontSize: 12 }}><div dangerouslySetInnerHTML={{ __html: row.task_description }} /></TableCell>
                              <TableCell style={{ fontSize: 12 }}>{row.estimated_time}</TableCell>
                              <TableCell style={{ fontSize: 12 }}>{row.total_points}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                <Button variant="contained" color="secondary"
                                  onClick={(event) => this.onAddClick(event, row)} style={{ borderRadius: 3 }}>
                                  <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>Add</Typography>
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}

                  </TableBody>
                </Table>

              </div>
              <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.currentPage}
                count={this.state.filteredDataSource.length}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />

            </Paper>

          </Grid>
        </Grid>
      </div>
    );
  }
}

BuiltInTaskScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage,
    profileId: state.profile.profileId,
    schoolId: state.profile.schoolId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BuiltInTaskScreen)));
