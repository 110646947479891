export default {

    containerStyle: {
        backgroundColor: '#eaeaea',
        height: '100%'
    },
    itemContainerStyle: {
        padding: 5,
        height: '100%',
        flexGrow: 1,
        marginTop: 10,
        marginLeft: 100,
        marginRight: 100,
        textAlign: 'center'
    },
    headerStyle: {
        backgroundColor: '#25D192'
    },
    optionsView: {
        flexDirection: 'row',
        width: '100%',
        paddingBottom: 10,
    },
    optionsFlex: {
        flex: 9
    },
    optionsText: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#4D4D4F'
    },
    borderStyle: {
        flexDirection: 'row',
        paddingBottom: 10,
        marginTop: 10,
        width: '100%',
        borderBottomColor: '#eaeaea',
        borderBottomWidth: 1,
    },
    dateFlex: {
        flex: 5,
    },
    dateText: {
        textAlign: 'right',
    },
    emptyView: {
        flex: 1,
    },
    editText: {
        fontWeight: 'bold',
        color: '#2A2042',
    },
    tableWrapper: {
        overflow: 'auto'

    },
    search: {
        position: 'relative',
        borderRadius: 5,
        backgroundColor: '#25D192',
        '&:hover': {
            backgroundColor: '#78E5C9',
        },
        marginTop: 18,
        marginRight: 30,
        marginLeft: 10,
        width: '100%',
    },
    searchIcon: {
        width: 10,
        marginLeft: 15,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: 10,
        width: '100%',
        marginLeft: 35
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    }
};