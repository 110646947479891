import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import i18n from '../../../../i18n';
import { logout } from '../../../../redux/actions/auth';
import styles from './style';

class AcadmicScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      collegeId: 0
    }
  }

  componentDidMount() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/college_academic/' + this.props.collegeId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            academicId: responseJson.results[0].academic_id,
            collegeId: responseJson.results[0].college_id,
            collegeName: responseJson.results[0].college_name,
            collegeLevelName: responseJson.results[0].college_level_name,
            totalEnrollment: responseJson.results[0].total_graduate + responseJson.results[0].total_undergraduate,
            totalUndergraduate: responseJson.results[0].total_undergraduate,
            graduateInFour: responseJson.results[0].graduate_in_four,
            graduateInSix: responseJson.results[0].graduate_in_six,
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  render() {

    const { classes } = this.props;


    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%' }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#1F3545', marginBottom: 10 }}>{i18n.t("COLLEGE_ACADEMICS")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>

                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Grid container style={{ marginTop: 30 }}>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                      <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ACADEMICS_HIGHEST")}</Typography>
                      <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{this.state.collegeLevelName}</Typography>
                    </Grid>

                    {this.state.totalEnrollment !== 0 ?
                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ACADEMICS_TOTAL_STUDENTS")}</Typography>
                        <NumberFormat style={{ fontSize: 24, fontWeight: 'bold' }} value={this.state.totalEnrollment} displayType={'text'} thousandSeparator={true} />
                      </Grid>
                      : null}

                    {this.state.totalUndergraduate !== 0 ?
                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: 18 }}>{i18n.t("COLLEGE_ACADEMICS_NUMBER_GRADS")}</Typography>
                        <NumberFormat style={{ fontSize: 24, fontWeight: 'bold' }} value={this.state.totalUndergraduate} displayType={'text'} thousandSeparator={true} />
                      </Grid>
                      : null}

                  </Grid>
                  <Grid container style={{ marginTop: 50 }}>
                    {this.state.graduateInFour !== 0 ?
                      <Grid item xs={6} style={{ textAlign: 'center' }}>
                        <div style={{ width: 150, marginLeft: 75, textAlign: 'center' }}>
                          <CircularProgressbar text={`${this.state.graduateInFour}%`} value={this.state.graduateInFour} circleRatio={0.5}
                            styles={buildStyles({
                              rotation: 0.75, strokeLinecap: 'butt',
                              textSize: '20px', pathTransitionDuration: 0.5,
                              pathColor: `#25D192`,
                              textColor: '#000',
                              trailColor: '#d6d6d6',
                              backgroundColor: '#000'
                            })} />
                          <Typography style={{ marginTop: -60, fontWeight: 'bold' }}>{i18n.t("COLLEGE_ACADEMICS_FOUR_YEAR")}</Typography>
                        </div>
                      </Grid>
                      : null}

                    {this.state.graduateInSix !== 0 ?
                      <Grid item xs={6} style={{ textAlign: 'center' }}>
                        <div style={{ width: 150, marginLeft: 75, textAlign: 'center' }}>
                          <CircularProgressbar text={`${this.state.graduateInSix}%`} value={this.state.graduateInSix} circleRatio={0.5}
                            styles={buildStyles({
                              rotation: 0.75, strokeLinecap: 'butt',
                              textSize: '20px', pathTransitionDuration: 0.5,
                              pathColor: `#25D192`,
                              textColor: '#000',
                              trailColor: '#d6d6d6',
                              backgroundColor: '#000'
                            })} />
                          <Typography style={{ marginTop: -60, fontWeight: 'bold' }}>{i18n.t("COLLEGE_ACADEMICS_GRAD_SIX")}</Typography>
                        </div>
                      </Grid>
                      : null}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>


      </div>
    );
  }
}

AcadmicScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AcadmicScreen)));