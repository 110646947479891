import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Input, InputLabel, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tabs, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import BuiltInTask from './builtin/index';
import DistrictTask from './district/index';
import styles from './style';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class MyTaskScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      onAssignClose: false,
      isArchiveOpen: false,
      showLibrary: false,
      showCustom: false,
      dataSource: [],
      grades: [],
      gradeCheckList: [],
      schools: [],
      systemTasks: [],
      schoolCheckList: [],
      newSchoolCheckList: [],
      editSchoolCheckList: [],
      customGroups: [],
      classCheckList: [],
      filteredDataSource: [],
      categories: [],
      selectedItem: {},
      newItem: {},
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc',
      onAddClose: null,
    }

    this.handleChangePage = this.handleChangePage.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onAddClose = this.onAddClose.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onAssignClose = this.onAssignClose.bind(this);
    this.onArchiveClose = this.onArchiveClose.bind(this);

  }

  updateDimensions() {
    let update_height = window.innerHeight - 300;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.getData();

    this.updateDimensions();
  }

  getData() {
    fetch('https://api.tasselpathway.com/tasks/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
            filteredDataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            schools: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/custom_groups/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            customGroups: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/task_category', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            categories: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onAssignTask() {
    this.setState({
      isLoading: true
    })

    if (this.state.selectedTarget === 'District') {

      fetch('https://api.tasselpathway.com/tasks/assign/district', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          task_id: this.state.selectedItem.task_id,
          person_id: this.props.personId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
          this.getData();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
        })
    } else if (this.state.selectedTarget === 'School') {

      var schools = [];

      for (let i = 0; i < this.state.schools.length; i++) {
        if (this.state.schoolCheckList['checked_' + this.state.schools[i].school_id] === true) {
          schools.push({ "school_id": this.state.schools[i].school_id });
        }
      }

      fetch('https://api.tasselpathway.com/tasks/assign/school', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          task_id: this.state.selectedItem.task_id,
          person_id: this.props.personId,
          schools: schools
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error === false) {
            this.setState({
              isLoading: false,
              isAssignOpen: false
            })
            this.getData();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
        })

    } else if (this.state.selectedTarget === 'Grades') {

      var grades = [];

      for (let i = 0; i < this.state.grades.length; i++) {
        if (this.state.gradeCheckList['checked_' + this.state.grades[i].grade_id] === true) {
          grades.push({ "grade_id": this.state.grades[i].grade_id });
        }
      }

      fetch('https://api.tasselpathway.com/tasks/assign/grade', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          task_id: this.state.selectedItem.task_id,
          person_id: this.props.personId,
          grades: grades
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
          this.getData();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
        })

    } else if (this.state.selectedTarget === 'Classes') {

      var classes = [];

      for (let i = 0; i < this.state.customGroups.length; i++) {
        if (this.state.classCheckList['checked_' + this.state.customGroups[i].custom_group_id] === true) {
          classes.push({ "custom_group_id": this.state.customGroups[i].custom_group_id });
        }
      }

      fetch('https://api.tasselpathway.com/tasks/assign/class', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          task_id: this.state.selectedItem.task_id,
          person_id: this.props.personId,
          classes: classes
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
          this.getData();
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isAssignOpen: false
          })
        })
    }
  }

  onAddClose() {
    this.setState({
      newSchoolCheckList: [],
      isNewOpen: false,
    })

    this.getData();
  }

  onArchiveClick(event, item) {
    this.setState({
      selectedItem: item,
      isArchiveOpen: true
    })
  }

  onArchiveClose() {
    this.setState({
      selectedItem: {},
      isArchiveOpen: false
    })
  }

  onArchiveTask() {

    var item = this.state.selectedItem;
    item.is_active = 0;

    this.setState({
      selectedItem: item
    })

    fetch('https://api.tasselpathway.com/tasks', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        category_id: this.state.selectedItem.category_id,
        task_name: this.state.selectedItem.task_name,
        task_name_spanish: this.state.selectedItem.task_name_spanish,
        task_description: this.state.selectedItem.task_description,
        task_description_spanish: this.state.selectedItem.task_description_spanish,
        estimated_time: this.state.selectedItem.estimated_time,
        total_points: this.state.selectedItem.total_points,
        visibility_id: this.state.selectedItem.visibility_id,
        is_active: this.state.selectedItem.is_active,
        task_id: this.state.selectedItem.task_id,
        schools: [],
        person_id: this.props.personId
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          isArchiveOpen: false
        })
        this.getData();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isArchiveOpen: false
        })

      });
  }

  onSaveTask() {

    this.setState({
      isLoading: true
    })

    var schools = [];

    for (let i = 0; i < this.state.schools.length; i++) {
      if (this.state.editSchoolCheckList['checked_' + this.state.schools[i].school_id] === true) {
        schools.push({ "school_id": this.state.schools[i].school_id });
      }
    }

    fetch('https://api.tasselpathway.com/tasks', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        category_id: this.state.selectedItem.category_id,
        task_name: this.state.selectedItem.task_name,
        task_name_spanish: this.state.selectedItem.task_name_spanish,
        task_description: this.state.selectedItem.task_description,
        task_description_spanish: this.state.selectedItem.task_description_spanish,
        estimated_time: this.state.selectedItem.estimated_time,
        total_points: this.state.selectedItem.total_points,
        visibility_id: this.state.selectedItem.visibility_id,
        is_active: this.state.selectedItem.is_active,
        task_id: this.state.selectedItem.task_id,
        schools: schools,
        person_id: this.props.personId
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          isEditOpen: false
        })
        this.getData();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isEditOpen: false
        })
      });
  }

  onEditClick(event, item) {

    var editSchoolCheckList = [];

    for (let i = 0; i < item.schools.length; i++) {
      editSchoolCheckList['checked_' + item.schools[i].school_id] = true;
    }

    this.setState({
      selectedItem: item,
      editSchoolCheckList: editSchoolCheckList,
      isEditOpen: true
    })

  }

  onEditClose() {
    this.setState({
      selectedItem: {},
      isEditOpen: false
    })
  }

  onNewCategoryChange(event) {
    var newItem = this.state.newItem;
    newItem.category_id = event.target.value;

    this.setState({
      newItem: newItem
    })
  }

  onCategoryChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.category_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onNewVisibilityChange(event) {
    var newItem = this.state.newItem;
    newItem.visibility_id = event.target.value;

    this.setState({
      newItem: newItem
    })
  }

  onVisibilityChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.visibility_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onAddTask() {

    var newItem = this.state.newItem;

    var schools = [];

    for (let i = 0; i < this.state.schools.length; i++) {
      if (this.state.newSchoolCheckList['checked_' + this.state.schools[i].school_id] === true) {
        schools.push({ "school_id": this.state.schools[i].school_id });
      }
    }

    fetch('https://api.tasselpathway.com/tasks', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        category_id: newItem.category_id,
        task_name: newItem.task_name,
        task_name_spanish: newItem.task_name_spanish,
        task_description: newItem.task_description,
        task_description_spanish: newItem.task_description_spanish,
        estimated_time: newItem.estimated_time === undefined ? 0 : newItem.estimated_time,
        total_points: newItem.total_points === undefined ? 0 : newItem.total_points,
        visibility_id: newItem.visibility_id,
        schools: schools,
        person_id: this.props.personId
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          isNewOpen: false
        })

        this.getData();

      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isNewOpen: false
        })
      })
  }

  onTaskNameChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.task_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onNewTaskNameChange(event) {

    var newItem = this.state.newItem;
    newItem.task_name = event.target.value;

    this.setState({
      newItem: newItem
    })
  }

  onTaskDescriptionChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.task_description = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onNewTaskDescriptionChange(event) {

    var newItem = this.state.newItem;
    newItem.task_description = event.target.value;

    this.setState({
      newItem: newItem
    })
  }

  onEstimatedTimeChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.estimated_time = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onNewEstimatedTimeChange(event) {

    var newItem = this.state.newItem;
    newItem.estimated_time = event.target.value;

    this.setState({
      newItem: newItem
    })
  }

  onTotalPointsChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.total_points = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onNewTotalPointsChange(event) {

    var newItem = this.state.newItem;
    newItem.total_points = event.target.value;

    this.setState({
      newItem: newItem
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  renderCategories() {
    var content = this.state.categories.map((item, key) => {
      return (
        <MenuItem key={key} value={item.category_id}>{item.category_name}</MenuItem>
      )
    })

    return content;
  }

  onGradeChange(event) {

    var gradeId = event.target.value;
    var gradeCheckList = this.state.gradeCheckList;
    var i = 0;

    if (event.target.checked) {
      gradeCheckList['checked_' + gradeId] = true;
    } else {
      gradeCheckList['checked_' + gradeId] = false;
    }

    this.setState({
      gradeCheckList: gradeCheckList
    })
  }

  onSchoolChange(event) {

    var schoolId = event.target.value;
    var schoolCheckList = this.state.schoolCheckList;

    if (event.target.checked) {
      schoolCheckList['checked_' + schoolId] = true;
    } else {
      schoolCheckList['checked_' + schoolId] = false;
    }

    this.setState({
      schoolCheckList: schoolCheckList
    })
  }

  onNewSchoolChange(event) {

    var schoolId = event.target.value;
    var newSchoolCheckList = this.state.newSchoolCheckList;

    if (event.target.checked) {
      newSchoolCheckList['checked_' + schoolId] = true;
    } else {
      newSchoolCheckList['checked_' + schoolId] = false;
    }

    this.setState({
      newSchoolCheckList: newSchoolCheckList
    })
  }

  onEditSchoolChange(event) {

    var schoolId = event.target.value;
    var editSchoolCheckList = this.state.editSchoolCheckList;

    if (event.target.checked) {
      editSchoolCheckList['checked_' + schoolId] = true;
    } else {
      editSchoolCheckList['checked_' + schoolId] = false;
    }

    this.setState({
      editSchoolCheckList: editSchoolCheckList
    })
  }

  onClassChange(event) {

    var classId = event.target.value;
    var classCheckList = this.state.classCheckList;

    if (event.target.checked) {
      classCheckList['checked_' + classId] = true;
    } else {
      classCheckList['checked_' + classId] = false;
    }

    this.setState({
      classCheckList: classCheckList
    })
  }

  onNewClick() {

    this.setState({
      isNewOpen: true,
      showLibrary: false,
      showCustom: false,
      newSchoolCheckList: [],
      newItem: {}
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onAssignClick(event, item) {
    this.setState({
      selectedItem: item,
      isAssignOpen: true
    })
  }

  onAssignClose() {
    this.setState({
      selectedItem: {},
      isAssignOpen: false
    })
  }

  onLibraryClick() {
    this.setState({
      showLibrary: true
    })
  }

  onCustomClick() {
    this.setState({
      showCustom: true
    })
  }

  handleChange = (event, value) => {
    this.setState({
      value
    });
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.filteredDataSource.length - this.state.currentPage * this.state.rowsPerPage);

    return (
      <div className={classes.root}>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} maxWidth={!this.state.showLibrary ? "sm" : "xl"}
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
            New Task
          </DialogTitle>

          <DialogContent>

            {!this.state.showLibrary && !this.state.showCustom ?
              <Grid container spacing={4}>

                <Grid item xs={6}>
                  <Card style={{ cursor: 'pointer' }} onClick={() => this.onLibraryClick()}
                    raised={this.state['cards_' + 1]}
                    onMouseOut={() => this.setState({ ['cards_' + 1]: false })}
                    onMouseOver={() => this.setState({ ['cards_' + 1]: true })} >
                    <CardContent style={{ textAlign: 'center' }}>
                      <FontAwesomeIcon style={{ fontSize: 44, marginBottom: 30, color: '#25D192' }} icon={faTasks} />
                      <Typography style={{ fontSize: 18 }}>Select From Task Library</Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card style={{ cursor: 'pointer' }} onClick={() => this.onCustomClick()}
                    raised={this.state['cards_' + 2]}
                    onMouseOut={() => this.setState({ ['cards_' + 2]: false })}
                    onMouseOver={() => this.setState({ ['cards_' + 2]: true })} >
                    <CardContent style={{ textAlign: 'center' }}>
                      <FontAwesomeIcon style={{ fontSize: 44, marginBottom: 30, color: '#25D192' }} icon={faTasks} />
                      <Typography style={{ fontSize: 18 }}>Create Custom Task</Typography>
                    </CardContent>
                  </Card>
                </Grid>

              </Grid>

              : null}

            {this.state.showLibrary ?

              <Grid container>


                <Grid item xs={6}>
                  <Tabs value={this.state.value} onChange={this.handleChange}>
                    <Tab label="BUILT-IN TASKS" />
                    <Tab label="DISTRICT TASKS" />
                  </Tabs>
                </Grid>

              </Grid>
              : null}

            {this.state.value === 0 && this.state.showLibrary ?
              <BuiltInTask onAddClose={this.onAddClose} />
              : null}

            {this.state.value === 1 && this.state.showLibrary ?
              <DistrictTask onAddClose={this.onAddClose} />
              : null}

            {this.state.showCustom ?

              <Grid container>

                <Grid item xs={12}>

                  <FormControl fullWidth>
                    <InputLabel htmlFor="editTaskName">{i18n.t("ADMIN_TASKS_TASKS_NAME")}</InputLabel>
                    <Input variant="outline" fullWidth
                      value={this.state.newItem.task_name}
                      onChange={(event) => this.onNewTaskNameChange(event)}
                      id="editTaskName" aria-describedby="my-helper-text" />
                  </FormControl>

                </Grid>

                <Grid item xs={6}>
                  <FormControl style={{ marginTop: 10 }} className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="editCategoryId">{i18n.t("ADMIN_TASKS_SELECT_CATEGORY")}</InputLabel>
                    <Select
                      value={this.state.newItem.category_id}
                      onChange={(event) => this.onNewCategoryChange(event)}
                      inputProps={{
                        name: 'editCategoryId',
                        id: 'editCategoryId',
                      }}>
                      <MenuItem disabled value={0}>{i18n.t("ADMIN_TASKS_SELECT_CATEGORY")}</MenuItem>
                      {this.renderCategories()}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl style={{ marginTop: 10 }} className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="editVisibilityId">{i18n.t("ADMIN_TASKS_SELECT_VISIBILITY")}</InputLabel>
                    <Select
                      value={this.state.newItem.visibility_id}
                      onChange={(event) => this.onNewVisibilityChange(event)}
                      inputProps={{
                        name: 'editVisibilityId',
                        id: 'editVisibilityId',
                      }}>
                      <MenuItem disabled value={0}>{i18n.t("ADMIN_TASKS_SELECT_VISIBILITY")}</MenuItem>

                      {this.props.userRoleId <= 1 ?
                        <MenuItem value={1}>District</MenuItem>
                        : null}

                      <MenuItem value={2}>School</MenuItem>
                      <MenuItem value={3}>Just Me</MenuItem>

                    </Select>
                  </FormControl>
                </Grid>

                {this.state.newItem.visibility_id === 2 ?

                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select School(s)</Typography>

                    <FormGroup row style={{ marginLeft: 20 }}>

                      {this.state.schools.map((item, key) => {
                        return (
                          <FormControlLabel key={key} onChange={(event) => this.onNewSchoolChange(event)}
                            control={
                              <Checkbox value={item.school_id} checked={this.state.newSchoolCheckList['checked_' + item.school_id] ? true : false} />
                            }
                            label={item.school_name}
                          />
                        )
                      })}

                    </FormGroup>
                  </Grid>
                  : null}

                <Grid item xs={12}>

                  <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel htmlFor="editTaskDescription">{i18n.t("ADMIN_TASKS_TASK_DESCRIPTION")}</InputLabel>
                    <Input variant="outline" fullWidth multiline rows={6}
                      value={this.state.newItem.task_description}
                      onChange={(event) => this.onNewTaskDescriptionChange(event)}
                      id="editTaskDescription" aria-describedby="my-helper-text" />
                  </FormControl>

                </Grid>

                <Grid item xs={6}>

                  <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel htmlFor="editEstimatedTime">{i18n.t("ADMIN_TASKS_ESTIMATED_TIME")}</InputLabel>
                    <Input variant="outline" fullWidth
                      value={this.state.newItem.estimated_time}
                      onChange={(event) => this.onNewEstimatedTimeChange(event)}
                      id="editEstimatedTime" aria-describedby="my-helper-text" />
                  </FormControl>

                </Grid>

                <Grid item xs={6}>

                  <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel htmlFor="editTotalPoints">{i18n.t("ADMIN_TASKS_TOTAL_POINT")}</InputLabel>
                    <Input variant="outline" fullWidth
                      value={this.state.newItem.total_points}
                      onChange={(event) => this.onNewTotalPointsChange(event)}
                      id="editTotalPoints" aria-describedby="my-helper-text" />
                  </FormControl>

                </Grid>

                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button variant="contained" onClick={() => this.onAddTask()}
                    style={{ color: '#FFF', float: 'right', marginTop: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
                    {i18n.t("ADMIN_TASKS_SAVE")}
                  </Button>
                </Grid>

              </Grid>



              : null}

          </DialogContent>

        </Dialog>

        <Dialog open={this.state.isArchiveOpen}
          onClose={this.onArchiveClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onArchiveClose}>
            {i18n.t("ADMIN_TASKS_ARCHIVE_TASK")}
          </DialogTitle>

          <DialogContent>
            <Typography>Are you sure you want to archive this task?</Typography>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onArchiveClose()}
              style={{ color: '#FFF', borderRadius: 3, fontWeight: 'bold' }} color="secondary">
              {i18n.t("NO")}
            </Button>
            <Button variant="contained" onClick={() => this.onArchiveTask()}
              style={{ color: '#FFF', marginLeft: 10, marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
              {i18n.t("YES")}
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            {i18n.t("ADMIN_TASKS_EDIT_TASK")}
          </DialogTitle>

          <DialogContent>

            <Grid container>
              <Grid item xs={12}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="editTaskName">{i18n.t("ADMIN_TASKS_TASKS_NAME")}</InputLabel>
                  <Input variant="outline" fullWidth
                    value={this.state.selectedItem.task_name}
                    onChange={(event) => this.onTaskNameChange(event)}
                    id="editTaskName" aria-describedby="my-helper-text" />
                </FormControl>

              </Grid>

              <Grid item xs={6}>
                <FormControl style={{ marginTop: 10 }} className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="editCategoryId">{i18n.t("ADMIN_TASKS_SELECT_CATEGORY")}</InputLabel>
                  <Select
                    value={this.state.selectedItem.category_id}
                    onChange={(event) => this.onCategoryChange(event)}
                    inputProps={{
                      name: 'editCategoryId',
                      id: 'editCategoryId',
                    }}>
                    <MenuItem disabled value={0}>{i18n.t("ADMIN_TASKS_SELECT_CATEGORY")}</MenuItem>
                    {this.renderCategories()}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl style={{ marginTop: 10 }} className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="editVisibilityId">{i18n.t("ADMIN_TASKS_SELECT_VISIBILITY")}</InputLabel>
                  <Select
                    value={this.state.selectedItem.visibility_id}
                    onChange={(event) => this.onVisibilityChange(event)}
                    inputProps={{
                      name: 'editVisibilityId',
                      id: 'editVisibilityId',
                    }}>
                    <MenuItem disabled value={0}>{i18n.t("ADMIN_TASKS_SELECT_VISIBILITY")}</MenuItem>

                    {this.props.userRoleId <= 1 ?
                      <MenuItem value={1}>District</MenuItem>
                      : null}

                    <MenuItem value={2}>School</MenuItem>
                    <MenuItem value={3}>Just Me</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {this.state.selectedItem.visibility_id === 2 ?

                <Grid item xs={12}>
                  <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select School(s)</Typography>

                  <FormGroup row style={{ marginLeft: 20 }}>

                    {this.state.schools.map((item, key) => {
                      return (
                        <FormControlLabel key={key} onChange={(event) => this.onEditSchoolChange(event)}
                          control={
                            <Checkbox value={item.school_id} checked={this.state.editSchoolCheckList['checked_' + item.school_id] ? true : false} />
                          }
                          label={item.school_name}
                        />
                      )
                    })}

                  </FormGroup>
                </Grid>
                : null}

              <Grid item xs={12}>

                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="editTaskDescription">{i18n.t("ADMIN_TASKS_TASK_DESCRIPTION")}</InputLabel>
                  <Input variant="outline" fullWidth multiline rows={6}
                    value={this.state.selectedItem.task_description}
                    onChange={(event) => this.onTaskDescriptionChange(event)}
                    id="editTaskDescription" aria-describedby="my-helper-text" />
                </FormControl>

              </Grid>

              <Grid item xs={6}>

                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="editEstimatedTime">{i18n.t("ADMIN_TASKS_ESTIMATED_TIME")}</InputLabel>
                  <Input variant="outline" fullWidth
                    value={this.state.selectedItem.estimated_time}
                    onChange={(event) => this.onEstimatedTimeChange(event)}
                    id="editEstimatedTime" aria-describedby="my-helper-text" />
                </FormControl>

              </Grid>

              <Grid item xs={6}>

                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="editTotalPoints">{i18n.t("ADMIN_TASKS_TOTAL_POINT")}</InputLabel>
                  <Input variant="outline" fullWidth
                    value={this.state.selectedItem.total_points}
                    onChange={(event) => this.onTotalPointsChange(event)}
                    id="editTotalPoints" aria-describedby="my-helper-text" />
                </FormControl>

              </Grid>

            </Grid>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onSaveTask()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_TASKS_SAVE")}
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isAssignOpen}
          onClose={this.onAssignClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">

          <DialogTitle id="customized-dialog-title" onClose={this.onAssignClose}>
            {i18n.t("ADMIN_TASKS_ASSIGN_TASK")}
          </DialogTitle>

          <DialogContent>

            <Grid container>

              <Grid item xs={12}>

                <FormControl style={{ marginTop: 10 }} className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="newFrequencyId">Assign to</InputLabel>
                  <Select fullWidth
                    value={this.state.selectedTarget}
                    onChange={(event) => this.setState({ selectedTarget: event.target.value })}
                    inputProps={{
                      name: 'newFrequencyId',
                      id: 'newFrequencyId',
                    }}>
                    <MenuItem disabled value={0}>Assign to</MenuItem>
                    <MenuItem value={'District'}>District</MenuItem>
                    <MenuItem value={'School'}>School</MenuItem>
                    <MenuItem value={'Grades'}>Grades</MenuItem>
                    <MenuItem value={'Classes'}>Classes</MenuItem>
                  </Select>
                </FormControl>

              </Grid>

              {this.state.selectedTarget === 'Grades' ?

                <Grid item xs={12}>
                  <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>{i18n.t("ADMIN_TASKS_YEARS_ACTIVE")}</Typography>

                  <FormGroup row style={{ marginLeft: 20 }}>

                    {this.state.grades.map((item, key) => {
                      return (
                        <FormControlLabel key={key} onChange={(event) => this.onGradeChange(event)}
                          control={
                            <Checkbox value={item.grade_id} checked={this.state.gradeCheckList['checked_' + item.grade_id] ? true : false} />
                          }
                          label={item.grade_name}
                        />
                      )
                    })}

                  </FormGroup>
                </Grid>

                : null}

              {this.state.selectedTarget === 'School' ?

                <Grid item xs={12}>
                  <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select School(s)</Typography>

                  <FormGroup row style={{ marginLeft: 20 }}>

                    {this.state.schools.map((item, key) => {
                      return (
                        <FormControlLabel key={key} onChange={(event) => this.onSchoolChange(event)}
                          control={
                            <Checkbox value={item.school_id} checked={this.state.schoolCheckList['checked_' + item.school_id] ? true : false} />
                          }
                          label={item.school_name}
                        />
                      )
                    })}

                  </FormGroup>
                </Grid>

                : null}

              {this.state.selectedTarget === 'Classes' ?

                <Grid item xs={12}>
                  <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select Classroom(s)</Typography>

                  <FormGroup row style={{ marginLeft: 20 }}>

                    {this.state.customGroups.map((item, key) => {
                      return (
                        <FormControlLabel key={key} onChange={(event) => this.onClassChange(event)}
                          control={
                            <Checkbox value={item.custom_group_id} checked={this.state.classCheckList['checked_' + item.custom_group_id] ? true : false} />
                          }
                          label={item.name}
                        />
                      )
                    })}

                  </FormGroup>
                </Grid>

                : null}

            </Grid>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" onClick={() => this.onAssignTask()}
              style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
              {i18n.t("ADMIN_TASKS_SAVE")}
            </Button>
          </DialogActions>

        </Dialog>

        <Grid container style={styles.itemContainerStyle}>
          <Grid container>
            <Grid item xs={2}>
              <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 3 }}
                onClick={() => this.onNewClick()}>
                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>{i18n.t("ADMIN_TASKS_NEW_TASK_BUTTON")}</Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>

            </Grid>
          </Grid>

          <Paper style={{ width: '100%', marginTop: 10, orderRadius: 0 }}>
            <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
              <Table className={classes.table} style={{ height: this.state.height }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 100 }} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("category_name")}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TASKS_CATEGORY_NAME")}</Typography>
                        {this.state.sortBy === "category_name" ? (
                          this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell style={{ width: 250 }} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("task_name")}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TASKS_TASKS_NAME")}</Typography>
                        {this.state.sortBy === "task_name" ? (
                          this.state.sortDirection === 'asc' ? <UpArrow /> : <DownArrow />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell style={{ width: 500 }} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TASKS_TASK_DESCRIPTION")}</Typography>
                      </div>
                    </TableCell>
                    <TableCell style={{ width: 100 }} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TASKS_TIME")}</Typography>
                      </div>
                    </TableCell>
                    <TableCell style={{ width: 50 }} className={classes.head}>
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                        <Typography style={{ fontSize: 12 }}>{i18n.t("ADMIN_TASKS_POINTS")}</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                    <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                    .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={index} classes={classes.tableRow}
                          hover style={{ cursor: 'pointer' }}>
                          <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.category_name}</Typography></TableCell>
                          <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.task_name}</Typography></TableCell>
                          <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}><div dangerouslySetInnerHTML={{ __html: row.task_description }} /></Typography></TableCell>
                          <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.estimated_time}</Typography></TableCell>
                          <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.total_points}</Typography></TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Button variant="contained" color="secondary"
                              onClick={(event) => this.onAssignClick(event, row)} style={{ borderRadius: 3 }}>
                              <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>Assign</Typography>
                            </Button>
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Button variant="contained" color="secondary"
                              onClick={(event) => this.onArchiveClick(event, row)} style={{ borderRadius: 3 }}>
                              <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>Archive</Typography>
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    }
                    )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 25 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.currentPage}
              count={this.state.filteredDataSource.length}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </div>
    );
  }
}

MyTaskScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage,
    profileId: state.profile.profileId,
    schoolId: state.profile.schoolId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTaskScreen)));
