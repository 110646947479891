import { GET_TASK, SET_TASK } from "../actions";

export const getTask = () => {
   return {
       type: GET_TASK
   }
};

export const setTask = (taskId, taskName, taskDescription, estimatedTime, totalPoints, autoGrade, answerText, taskUpdated) => {
    return {
        type: SET_TASK,
        taskId: taskId,
        taskName: taskName,
        taskDescription: taskDescription, 
        estimatedTime: estimatedTime, 
        totalPoints: totalPoints,
        autoGrade: autoGrade,
        answerText: answerText,
        taskUpdated: taskUpdated,
    }
 };
