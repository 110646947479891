import {SET_ALERT, GET_ALERT} from "../actions";

export const defaultState = {
    announcementAlert: 0,
    urgentAlert: 0
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case SET_ALERT:
            return Object.assign({}, state, {
                announcementAlert: action.announcementAlert,
                urgentAlert: action.urgentAlert
            });
            case GET_ALERT:
             return Object.assign({}, state, {
             });
        default:
            return state
    }
}