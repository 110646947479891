import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Paper, Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import { setTask } from '../../../redux/actions/task';
import styles from './style';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class ActiveScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isEditOpen: false,
      selectedItem: {},
      schoolYear: 2020,
      dataSource: [],
      filteredDataSource: [],
      rowsPerPage: 25,
      currentPage: 0,
      sortBy: '',
      sortDirection: 'desc'
    }

    this.handleChangePage = this.handleChangePage.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
  }

  updateDimensions() {
    let update_height = window.innerHeight - 300;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }


  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.getData();

    this.updateDimensions();
  }

  getData() {
    fetch('https://api.tasselpathway.com/profile_tasks/active/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
            filteredDataSource: responseJson.results,
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onRetryClick(item) {

    fetch('https://api.tasselpathway.com/profile_tasks', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        profile_task_id: item.profile_task_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
    this.props.onSetTask(item.task_id, item.task_name, item.task_description,
      item.estimated_time, item.total_points, item.auto_grade, '', 0);
  }

  onSubmitClick() {

    fetch('https://api.tasselpathway.com/profile_tasks', {
      method: "PATCH",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        answer_text: this.state.selectedItem.answer_text,
        profile_task_id: this.state.selectedItem.profile_task_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            isEditOpen: false
          })
          this.getData();
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isEditOpen: false
        })
      });
  }

  onChangeAnswer(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.answer_text = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onEditClick(item) {
    this.setState({
      isEditOpen: true,
      selectedItem: item
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onStartClick(item) {
    this.props.onSetTask(item.task_id, item.task_name, item.task_description,
      item.estimated_time, item.total_points, item.auto_grade, '', 0);
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy: col,
      sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
    }));
  }

  render() {

    const { classes } = this.props;
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.filteredDataSource.length - this.state.currentPage * this.state.rowsPerPage);

    return (
      <div className={classes.root}>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">

          <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
            {this.state.selectedItem.task_name}
          </DialogTitle>

          <DialogContent>

            <Typography style={{ marginBottom: 10 }}><div dangerouslySetInnerHTML={{ __html: this.state.selectedItem.task_description }} /></Typography>

            <FormControl fullWidth>
              <InputLabel htmlFor="skillName">Answer</InputLabel>
              <Input variant="outline" value={this.state.selectedItem.answer_text}
                fullWidth multiline rows={6} autoFocus
                onChange={(event) => this.onChangeAnswer(event)}
                id="answer" aria-describedby="my-helper-text" />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ borderRadius: 0, marginRight: 20 }}
              onClick={() => this.onSubmitClick()} color="secondary">
              <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>Submit Answer</Typography>
            </Button>
          </DialogActions>

        </Dialog>

        <Grid container style={styles.itemContainerStyle}>

          <Paper square style={{ marginTop: 20, borderRadius: 0, width: '100%' }}>
            <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
              <Table className={classes.table} padding="dense">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head}>{i18n.t("TASKS_LESSON_NAME")}</TableCell>
                    <TableCell className={classes.head}>Assigned By</TableCell>
                    <TableCell className={classes.head}>{i18n.t("TASKS_ESTIMATED_TIME")}</TableCell>
                    <TableCell className={classes.head}>{i18n.t("TASKS_POINTS")}</TableCell>
                    <TableCell className={classes.head}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                    .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {
                      if (this.props.selectedLanguage === "esp") {
                        return (
                          <>
                            <TableRow style={index % 2 ? { background: "#fdffe0" } : { background: "white" }} hover key={index}>
                              <TableCell><Typography>{row.task_name_spanish}</Typography></TableCell>
                              <TableCell>{row.teacher_first_name + ' ' + row.teacher_last_name}</TableCell>
                              <TableCell>{row.estimated_time}</TableCell>
                              <TableCell>{row.total_points}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                {
                                  row.status_id === 2 ?
                                    <Button variant="contained" size='small' onClick={() => this.onRetryClick(row)}
                                      color="secondary" style={{ borderRadius: 3 }}>
                                      <Typography style={{ fontSize: 13, color: '#FFF' }}>{i18n.t("TASKS_RETRY")}</Typography>
                                    </Button>
                                    :
                                    row.status_id === 1 ?
                                      <Button variant="contained" size='small' onClick={() => this.onEditClick(row)}
                                        color="secondary" style={{ borderRadius: 3 }}>
                                        <Typography style={{ fontSize: 13, color: '#FFF' }}>{i18n.t("TASKS_EDIT")}</Typography>
                                      </Button>
                                      :
                                      <Button variant="contained" onClick={() => this.onStartClick(row)}
                                        color="secondary" style={{ borderRadius: 0 }}>
                                        <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>{i18n.t("TASKS_START")}</Typography>
                                      </Button>
                                }
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell><b>Answer: </b>{row.answer_text}</TableCell>
                              <TableCell colSpan="2"><b>Response: </b>{row.educator_text}</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </>
                        );
                      } else {
                        return (

                          <>

                            <TableRow style={index % 2 ? { background: "#fdffe0" } : { background: "white" }} hover key={index}>
                              <TableCell style={{ width: 450 }}><Typography>{row.task_name}</Typography></TableCell>
                              <TableCell style={{ width: 150 }}>{row.teacher_first_name + ' ' + row.teacher_last_name}</TableCell>
                              <TableCell>{row.estimated_time}</TableCell>
                              <TableCell>{row.total_points}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                {
                                  row.status_id === 2 ?
                                    <Button variant="contained" onClick={() => this.onRetryClick(row)}
                                      color="secondary" style={{ borderRadius: 3 }}>
                                      <Typography style={{ fontSize: 13, color: '#FFF' }}>{i18n.t("TASKS_RETRY")}</Typography>
                                    </Button>
                                    :
                                    row.status_id === 1 ?
                                      <Button variant="contained" onClick={() => this.onEditClick(row)}
                                        color="secondary" style={{ borderRadius: 3 }}>
                                        <Typography style={{ fontSize: 13, color: '#FFF' }}>{i18n.t("TASKS_EDIT")}</Typography>
                                      </Button>
                                      :
                                      <Button variant="contained" onClick={() => this.onStartClick(row)}
                                        color="secondary" style={{ borderRadius: 3 }}>
                                        <Typography style={{ fontSize: 13, color: '#FFF' }}>{i18n.t("TASKS_START")}</Typography>
                                      </Button>
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow style={index % 2 ? { background: "#fdffe0" } : { background: "white" }}>
                              <TableCell colSpan="5"><b>Description: </b><div dangerouslySetInnerHTML={{ __html: row.task_description }} /></TableCell>
                            </TableRow>
                            <TableRow style={index % 2 ? { background: "#fdffe0" } : { background: "white" }}>
                              <TableCell colSpan="5" style={{ textAlign: 'left' }}><b>Answer: </b>{row.answer_text}</TableCell>
                            </TableRow>
                            <TableRow style={index % 2 ? { background: "#fdffe0" } : { background: "white" }}>
                              <TableCell colSpan="5" style={{ textAlign: 'left' }}><b>Response: </b>{row.educator_text}</TableCell>
                            </TableRow>
                          </>
                        );
                      }
                    })}
                </TableBody>
              </Table>

            </div>

            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.currentPage}
              count={this.state.filteredDataSource.length}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />

          </Paper>
        </Grid>
      </div>
    );
  }
}

ActiveScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage,
    profileId: state.profile.profileId,
    schoolId: state.profile.schoolId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetTask: (taskId, taskName, taskDescription, estimatedTime, totalPoints, autoGrade, answerText, taskUpdated) => { dispatch(setTask(taskId, taskName, taskDescription, estimatedTime, totalPoints, autoGrade, answerText, taskUpdated)); },
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActiveScreen)));
