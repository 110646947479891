import {
  Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardActions, CardContent, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, IconButton,
  InputBase, InputLabel, MenuItem, Paper, Select,
  Snackbar, SnackbarContent, Tab, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tabs, TextField, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Document, Image, Page, PDFViewer, Text, View } from '@react-pdf/renderer';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import MuiDialogTitle from '@mui/material/DialogTitle';
import orderBy from 'lodash/orderBy';
import { setProfile } from '../../redux/actions/profile';
import { setProgress } from '../../redux/actions/progress';

import { faEye, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownArrow from '@mui/icons-material/ArrowDropDown';
import UpArrow from '@mui/icons-material/ArrowDropUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { logout } from '../../redux/actions/auth';
import styles from './style';

const invertSort = {
  asc: "desc",
  desc: "asc"
}

var timeout = null;

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class StudentScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      withdrawnStudents: [],
      isPrintProfileOpen: false,
      allRequests: [],
      filteredRequests: [],
      printStudents: [],
      schools: [],
      allSchools: [],
      gradeList: [],
      classRoomList: [],
      studentList: [],
      card: [],
      students: [],
      allStudents: [],
      availableStudents: [],
      availTotalRows: 0,
      grades: [],
      choices: [],
      value: 0,
      pastPosts: [],
      searchText: '',
      gradeExpanded: true,
      classRoomExpanded: true,
      showMessage: false,
      isTokenOpen: false,
      alertMessage: '',
      availSearchText: '',
      availPerPage: 18,
      availPageNumber: 0,
      perPage: 25,
      pageNumber: 0,
      sortBy: '',
      sortDirection: 'desc',
      sortDirection2: 'desc',
      sortBy2: '',
      rowsPerPage: 25,
      currentPage: 0,
      isStudentEditOpen: false,
      selectedStudentId: 0,
      selectedStudentFirstName: '',
      selectedStudentLastName: '',
      selectedStudentEmailAddress: '',
      selectedStudentPassword: '',
      selectedStudentIsActive: true,
      selectedStudentEarlyGraduationProgram: false,
      selectedStudentDistrictId: 0,
      showPassword: false,
      isNewClassroomOpen: false,
      groupName: "",
      customGroups: [],
      customGroupsStudents: [],
      studentFilter: [],
      isCustomStudentEditOpen: false,
      selectedItem: {},
      isStudentListOpen: false,
      studentNewList: [],
      isRequestTransferOpen: false,
      isDeleteCustomGroupOpen: false,
      selectedStudent: [],
      allStudentRows: [],
      withdrawnStudentRows: [],
      totalRows: [],
      allTransferRequests: [],
      selectAll: false,
      selectAllStudents: false,
      isPickSchoolOpen: false,
      schoolCheckList: [],
      newSchoolDetails: [],
      schoolNameList: [],
      selectedSchoolId: [],
      studentsToChangeSchools: [],
      teachers: [],
      teacherList: [],
      isAddTeacherOpen: false,
      schoolGroupList: [],
      studentToRemove: {},
      batchToAdd: [],
      isAddToClassroomOpen: false,
      isCoTeacherOpen: false,
      isAddStudentOpen: false
    }

    this.onSearch = this.onSearch.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.onTokenClose = this.onTokenClose.bind(this);
    this.onSchoolClose = this.onSchoolClose.bind(this);
    this.onCloseMessage = this.onCloseMessage.bind(this);
    this.onStudentEditClose = this.onStudentEditClose.bind(this);
    this.onPromoteClose = this.onPromoteClose.bind(this);
  }

  componentDidMount() {

    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.setState({
      isLoading: true
    })

    this.updateDimensions();

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/schools', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          allSchools: responseJson.schools
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            schools: responseJson.results,
          })
        }
        var schoolGroupList = []
        responseJson.results.map((item, key) => {
          schoolGroupList.push(item.school_id)
        })
        this.setState({
          schoolGroupList: schoolGroupList
        })

        fetch('https://api.tasselpathway.com/allTeachers?school=' + schoolGroupList.join(), {
          method: "GET",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': this.props.token
          },
          credentials: 'omit'
        })
          .then((response) => response.json())
          .then((responseJson) => {

            if (responseJson.error === false) {

              var teacherList = [];
              var coTeachers = this.state.selectedItem.coTeachers;

              if (coTeachers !== undefined) {
                for (var i = 0; i < coTeachers.length; i++) {
                  teacherList[i] = coTeachers[i].person_id;
                }
              }

              this.setState({
                isLoading: false,
                teacherList: teacherList,
                teachers: responseJson.results
              })

            }
          })
          .catch((error) => {
            this.setState({
              isLoading: false
            })
          });

        this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    this.getClassrooms();
  }

  updateDimensions() {
    let update_height = window.innerHeight - 300;
    this.setState({ height: update_height });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  getData(value, searchText, gradeList, classRoomList, studentFilter, perPage, pageNumber, sortBy, sortDirection) {
    if (value === 0) {
      this.getCurrent(searchText, gradeList, classRoomList, studentFilter, perPage, pageNumber, sortBy, sortDirection, 1)
    } else if (value === 1) {
      this.getWithdrawn(searchText, gradeList, classRoomList, studentFilter, perPage, pageNumber, sortBy, sortDirection, 0)
    } else if (value === 2) {
      this.getRequests()
    } else if (value === 3) {
      this.getAllStudents(searchText, gradeList, classRoomList, studentFilter, perPage, pageNumber, sortBy, sortDirection)
    } else if (value === 4) {
      this.getTransferRequests()
    }
  }

  getCurrent(searchText, gradeList, classRoomList, studentFilter, perPage, pageNumber, sortBy, sortDirection, isActive) {

    var progress = 0;
    this.props.setProgress(progress);

    fetch('https://api.tasselpathway.com/students?search=' + searchText + '&grade=' + gradeList.join() + '&custom_group=' + classRoomList.join() + '&student=' + studentFilter.join()
      + '&per_page=' + perPage + '&page_number=' + pageNumber + '&sort_by=' + sortBy
      + '&sort_direction=' + sortDirection + '&is_active=' + isActive, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results,
            totalRows: responseJson.total_results
          })
        }

        progress = 100;
        this.props.setProgress(progress);
      })
      .catch((error) => {

        progress = 100;
        this.props.setProgress(progress);

        this.setState({
          isLoading: false
        })
      });
  }

  getWithdrawn(searchText, gradeList, classRoomList, studentFilter, perPage, pageNumber, sortBy, sortDirection, isActive) {

    var progress = 0;
    this.props.setProgress(progress);

    fetch('https://api.tasselpathway.com/students?search=' + searchText + '&grade=' + gradeList.join() + '&custom_group=' + classRoomList.join() + '&student=' + studentFilter.join()
      + '&per_page=' + perPage + '&page_number=' + pageNumber + '&sort_by=' + sortBy
      + '&sort_direction=' + sortDirection + '&is_active=' + isActive, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            withdrawnStudents: responseJson.results,
            withdrawnStudentRows: responseJson.total_results
          })
        }

        progress = 100;
        this.props.setProgress(progress);
      })
      .catch((error) => {

        progress = 100;
        this.props.setProgress(progress);

        this.setState({
          isLoading: false
        })
      });
  }

  getAllStudents(searchText, gradeList, classRoomList, studentFilter, perPage, pageNumber, sortBy, sortDirection) {

    var progress = 0;
    this.props.setProgress(progress);

    fetch('https://api.tasselpathway.com/allStudents?search=' + searchText + '&grade=' + gradeList.join() + '&student=' + studentFilter.join()
      + '&per_page=' + perPage + '&page_number=' + pageNumber + '&sort_by=' + sortBy
      + '&sort_direction=' + sortDirection, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            allStudents: responseJson.results,
            allStudentRows: responseJson.total_results
          })
        }

        progress = 100;
        this.props.setProgress(progress);
      })
      .catch((error) => {

        progress = 100;
        this.props.setProgress(progress);

        this.setState({
          isLoading: false
        })
      });
  }

  getRequests() {

    var progress = 0;
    this.props.setProgress(progress);

    fetch('https://api.tasselpathway.com/students/requests/', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            allRequests: responseJson.results,
            filteredRequests: responseJson.results
          })

          var choices = [];
          var selectAll = false;

          if (responseJson.results !== undefined) {

            for (let i = 0; i < responseJson.results.length; i++) {
              for (let j = 0; j < this.state.students.length; j++) {

                if (this.state.students[j].person_id === responseJson.results[i].person_id) {
                  choices['choice_' + responseJson.results[i].person_id] = 1;
                }
              }
            }
          }

          this.setState({
            selectAll: selectAll,
            choices: choices
          })

        }

        progress = 100
        this.props.setProgress(progress);

      })
      .catch((error) => {

        progress = 100;
        this.props.setProgress(progress);

        this.setState({
          isLoading: false
        })
      });
  }

  getTransferRequests() {

    var progress = 0;
    this.props.setProgress(progress);

    fetch('https://api.tasselpathway.com/transfer_requests', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          allTransferRequests: responseJson.results
        });

        progress = 100
        this.props.setProgress(progress);
      })
      .catch((error) => {

        progress = 100;
        this.props.setProgress(progress);

        this.setState({
          isLoading: false
        })
      });
  }

  getClassroomStudents(custom_group_id) {

    fetch('https://api.tasselpathway.com/custom_groups/students/' + custom_group_id, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            customGroupsStudents: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  getAvailableClassroomStudents(searchText, perPage, pageNumber) {

    fetch('https://api.tasselpathway.com/students/available?custom_group_id=' + this.state.selectedItem.custom_group_id +
      '&search=' + searchText + '&per_page=' + perPage + '&page_number=' + pageNumber, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            availableStudents: responseJson.results,
            availTotalRows: responseJson.total_results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onSearch(event) {

    var searchText = event.target.value;

    this.setState({
      searchText: searchText,
      pageNumber: 0
    })

    clearTimeout(timeout);

    var scope = this;
    timeout = setTimeout(function () {
      scope.getData(scope.state.value, searchText, scope.state.gradeList, scope.state.classRoomList, scope.state.studentFilter, scope.state.perPage, scope.state.pageNumber, scope.state.sortBy, scope.state.sortDirection)
    }, 1000);
  }

  onAvailSearch(event) {

    var availSearchText = event.target.value;

    this.setState({
      availSearchText: availSearchText,
      availPageNumber: 0
    })

    clearTimeout(timeout);

    var scope = this;
    timeout = setTimeout(function () {
      scope.getAvailableClassroomStudents(availSearchText, scope.state.availPerPage, scope.state.availPageNumber)
    }, 1000);
  }

  onRowClick(event, person) {

    this.props.onSetProfile(
      person.person_id,
      person.first_name,
      person.last_name,
      person.grade_id,
      person.grade_name,
      person.district_id,
      person.district_name,
      person.school_id,
      person.school_name,
      person.role_id,
      person.early_graduation_program
    );

    this.props.history.push('/profile/' + person.person_id);
  }

  onStudentEditOpen = (event, person) => {

    let studentNumber = person.phone

    if (studentNumber !== "0") {
      this.setState({
        isStudentEditOpen: true,
        selectedStudentId: person.person_id,
        selectedStudentFirstName: person.first_name,
        selectedStudentLastName: person.last_name,
        selectedStudentEmailAddress: person.email_address,
        selectedStudentPassword: '',
        selectedStudentDistrictId: person.district_id,
        selectedStudentGradeId: person.grade_id,
        selectedStudentSchoolId: person.school_id,
        selectedStudentPhoneNumber: person.phone,
        selectedStudentEarlyGraduationProgram: person.early_graduation_program,
        selectedStudentIsActive: person.is_active
      })

    } else {
      this.setState({
        isStudentEditOpen: true,
        selectedStudentId: person.person_id,
        selectedStudentFirstName: person.first_name,
        selectedStudentLastName: person.last_name,
        selectedStudentEmailAddress: person.email_address,
        selectedStudentDistrictId: person.district_id,
        selectedStudentGradeId: person.grade_id,
        selectedStudentSchoolId: person.school_id,
        selectedStudentPhoneNumber: " ",
        selectedStudentEarlyGraduationProgram: person.early_graduation_program,
        selectedStudentIsActive: person.is_active
      })
    }
  }

  getClassrooms() {
    fetch('https://api.tasselpathway.com/custom_groups/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            customGroups: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onStudentEditClose() {
    this.setState({
      isStudentEditOpen: false
    })
  }

  addStudent(item) {
    fetch("https://api.tasselpathway.com/students", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        person_id: item.person_id,
        school_id: item.school_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            showMessage: true,
            alertMessage: 'Student Added'
          })
        }

        this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  removeStudent(item) {

    fetch("https://api.tasselpathway.com/students", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        person_id: item.person_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            showMessage: true,
            alertMessage: 'Student Removed'
          })
        }

        this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onCloseMessage() {
    this.setState({
      showMessage: false
    })
  }

  handleChange = (event, value) => {

    this.getData(value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);

    this.setState({
      value
    });
  }

  generateToken(schoolId) {

    fetch("https://api.tasselpathway.com/students/generate", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        school_id: schoolId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            isTokenOpen: true,
            isSchoolOpen: false,
            inviteToken: responseJson.invite_token
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onInviteClick() {

    fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            schools: responseJson.results
          })

          if (responseJson.results.length === 1) {
            this.generateToken(responseJson.results[0].school_id)
          } else {
            this.setState({
              isSchoolOpen: true
            })
          }

        }
      })
      .catch((error) => {

        this.setState({
          isLoading: false
        })
      });
  }

  requestTransfer(row) {

    fetch('https://api.tasselpathway.com/schools/person/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            schools: responseJson.results,
            isRequestTransferOpen: true,
            selectedStudentFirstName: row.first_name,
            selectedStudentLastName: row.last_name,
            selectedStudentId: row.person_id
          })
        }
      })
      .catch((error) => {

        this.setState({
          isLoading: false
        })
      });
  }

  createClassroom() {

    fetch("https://api.tasselpathway.com/custom_groups", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        name: this.state.groupName,
        owner_id: this.props.personId,
        teachers: this.state.teacherList
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            isNewClassroomOpen: false,
            studentFilter: [],
            studentList: []
          })

          this.getClassrooms();

          alert('Your classroom has been created. Use the invite token to allow students to join your classroom!')
        }



      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderGrades() {

    var content = this.state.grades.map((item, key) => {
      return (
        <MenuItem key={key} value={item.grade_id}>{item.grade_name}</MenuItem>
      )
    })

    return content;
  }

  renderSchools() {

    var content = this.state.schools.map((item, key) => {
      return (
        <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
      )
    })

    return content;
  }

  onSchoolClose() {
    this.setState({
      isSchoolOpen: false
    })
  }

  handleChangePage(event, newPage) {
    this.setState({
      currentPage: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSort = (col) => {
    this.setState(state => ({
      sortBy2: col,
      sortDirection2: state.sortBy2 === col ? invertSort[state.sortDirection2] : 'asc'
    }));
  }

  onSortClick(col) {

    var sortBy = col;
    var sortDirection = this.state.sortBy === col ? invertSort[this.state.sortDirection] : 'asc';

    this.setState({
      sortBy: sortBy,
      sortDirection: sortDirection
    })

    this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, sortBy, sortDirection);
  }

  onTokenClose() {
    this.setState({
      isTokenOpen: false
    })
  }

  onGradeClick(event, gradeId) {

    if (event.target.checked !== undefined) {

      var gradeList = this.state.gradeList;

      if (event.target.checked) {
        gradeList.push(gradeId);
      } else {
        for (var i = 0; i < gradeList.length; i++) {
          if (gradeList[i] === gradeId) {
            gradeList.splice(i, 1);
          }
        }
      }

      this.setState({
        gradeList: gradeList,
        pageNumber: 0
      })

      if (this.state.sortBy !== '' && this.state.sortDirection !== '') {
        this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, 0, this.state.sortBy, this.state.sortDirection);
      } else {
        this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, 0, 'last_name', 'asc');
      }
    }

  }

  onClassroomClick(event, classRoomId) {

    if (event.target.checked !== undefined) {

      var classRoomList = this.state.classRoomList;

      if (event.target.checked) {
        classRoomList.push(classRoomId);
      } else {
        for (var i = 0; i < classRoomList.length; i++) {
          if (classRoomList[i] === classRoomId) {
            classRoomList.splice(i, 1);
          }
        }
      }

      this.setState({
        classRoomList: classRoomList,
        pageNumber: 0
      })

      if (this.state.sortBy !== '' && this.state.sortDirection !== '') {
        this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, 0, this.state.sortBy, this.state.sortDirection);
      } else {
        this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, 0, 'last_name', 'asc');
      }
    }
  }

  onPageClick(event, newPage) {

    if (newPage > this.state.pageNumber) {
      this.onNextClick();
    } else {
      this.onPreviousClick();
    }
  }

  onPreviousClick() {
    var currentPage = this.state.pageNumber;

    if (currentPage > 0) {

      currentPage = currentPage - 1;

      this.setState({
        pageNumber: currentPage
      })
    }

    this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList,
      this.state.studentFilter, this.state.perPage, currentPage, this.state.sortBy, this.state.sortDirection);
  }

  onNextClick() {
    var currentPage = this.state.pageNumber;

    currentPage = currentPage + 1;

    this.setState({
      pageNumber: currentPage
    })

    this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList,
      this.state.studentFilter, this.state.perPage, currentPage, this.state.sortBy, this.state.sortDirection);
  }

  onAvailPreviousClick() {
    var currentPage = this.state.availPageNumber;

    if (currentPage > 0) {

      currentPage = currentPage - 1;

      this.setState({
        availPageNumber: currentPage
      })
    }

    this.getAvailableClassroomStudents(this.state.availSearchText, this.state.availPerPage, currentPage);
  }

  onAvailNextClick() {
    var currentPage = this.state.availPageNumber;

    currentPage = currentPage + 1;

    this.setState({
      availPageNumber: currentPage
    })

    this.getAvailableClassroomStudents(this.state.availSearchText, this.state.availPerPage, currentPage);
  }

  onPersonClick(personId) {

    this.setState({
      selectAll: false
    })

    var choices = this.state.choices;

    for (let i = 0; i < this.state.allRequests.length; i++) {
      if (this.state.allRequests[i].person_id === personId) {
        if (choices['choice_' + this.state.allRequests[i].person_id] === 0 ||
          choices['choice_' + this.state.allRequests[i].person_id] === undefined) {
          choices['choice_' + this.state.allRequests[i].person_id] = 1;
        } else {
          choices['choice_' + this.state.allRequests[i].person_id] = 0;
        }
      }
    }

    this.setState({
      choices: choices
    });
  }

  onSelectAllStudents() {

    var selectAllStudents = !this.state.selectAllStudents;
    var batchToAdd = this.state.batchToAdd;

    var studentList = this.state.studentList;

    for (let i = 0; i < this.state.dataSource.length; i++) {

      if (selectAllStudents) {
        studentList.push(this.state.dataSource[i].person_id);
        batchToAdd['student_' + this.state.dataSource[i].person_id] = 1;
      } else {
        batchToAdd['student_' + this.state.dataSource[i].person_id] = 0;
        studentList = [];
      }
    }

    this.setState({
      selectAllStudents: selectAllStudents,
      studentList: studentList,
      batchToAdd: batchToAdd
    })
  }


  onSelectAll() {

    var selectAll = !this.state.selectAll;
    var choices = this.state.choices;

    for (let i = 0; i < this.state.allRequests.length; i++) {

      if (selectAll) {
        choices['choice_' + this.state.allRequests[i].person_id] = 1;
      } else {
        choices['choice_' + this.state.allRequests[i].person_id] = 0;
      }
    }

    this.setState({
      selectAll: selectAll,
      choices: choices
    })
  }

  onAcceptAll() {

    for (let i = 0; i < this.state.allRequests.length; i++) {
      if (this.state.choices['choice_' + this.state.allRequests[i].person_id] === 1) {

        fetch("https://api.tasselpathway.com/students", {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "token": this.props.token
          },
          body: JSON.stringify({
            person_id: this.state.allRequests[i].person_id,
            school_id: this.state.allRequests[i].school_id
          })
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.error === false) {
              this.setState({
                isLoading: false,
                showMessage: true,
                alertMessage: 'Student Added'
              })
            }
            this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
          })
          .catch((error) => {
            this.setState({
              isLoading: false
            })
          });
      }
    }

  }

  onRejectAll() {

    for (let i = 0; i < this.state.allRequests.length; i++) {
      if (this.state.choices['choice_' + this.state.allRequests[i].person_id] === 1) {

        fetch("https://api.tasselpathway.com/students", {
          method: "DELETE",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "token": this.props.token
          },
          body: JSON.stringify({
            person_id: this.state.allRequests[i].person_id
          })
        })
          .then((response) => response.json())
          .then((responseJson) => {

            if (responseJson.error === false) {
              this.setState({
                isLoading: false,
                showMessage: true,
                alertMessage: 'Student Removed'
              })
            }
            this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
          })
          .catch((error) => {
            this.setState({
              isLoading: false
            })
          });
      }
    }
  }

  onSaveStudentEdits(firstName, lastName, grade, schoolId, isActive, emailAddress, password, districtId, personId, phoneNumber, earlyGraduationProgram) {

    var props = this.props;

    if (password !== "") {

      fetch("https://api.tasselpathway.com/persons/student_edit_password", {
        method: "PATCH",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "token": props.token
        },
        credentials: "omit",
        body: JSON.stringify({
          person_id: personId,
          password: password
        })
      })
        .then(res => res.json())
        .then(responseJson => {
        })
    }

    fetch('https://api.tasselpathway.com/persons/student_edit', {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": 'application/json',
        "token": props.token
      },
      credentials: "omit",
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        grade_id: grade,
        school_id: schoolId,
        is_active: isActive,
        email_address: emailAddress,
        password: password,
        phone: phoneNumber,
        early_graduation_program: earlyGraduationProgram,
        person_id: personId,
        district_id: districtId

      })
    })
      .then(res => res.json())
      .then(responseJson => {
      })
      .then(() => fetch('https://api.tasselpathway.com/users/username', {
        method: "PATCH",
        headers: {
          "Accept": "application/json",
          "Content-Type": 'application/json',
          "token": props.token
        },
        credentials: "omit",
        body: JSON.stringify({
          email_address: emailAddress,
          person_id: personId
        })
      })
        .then(res => res.json())
        .then((responseJson) => {

          this.setState({
            isStudentEditOpen: false
          })

          this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection)
        })
      )
  }

  onDeleteCustomGroupClick() {

    this.setState({
      isDeleteCustomGroupOpen: true
    })

  }

  onDeleteTeacherClick() {

    fetch("https://api.tasselpathway.com/custom_teacher_group", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        custom_group_id: this.state.selectedItem.custom_group_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.onDeleteCustomGroupListClick()
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  onDeleteCustomGroupListClick() {
    fetch("https://api.tasselpathway.com/custom_groups", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        custom_group_id: this.state.selectedItem.custom_group_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isCustomStudentEditOpen: false,
            isStudentListOpen: false,
            isLoading: false,
          })
        }
        this.getData(this.state.value, this.state.searchText, [], [], [], this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onEditStudentListClick() {

    fetch("https://api.tasselpathway.com/custom_student_group", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        custom_group_id: this.state.selectedItem.custom_group_id,
        students: this.state.studentList
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isCustomStudentEditOpen: false,
            isStudentListOpen: false,
            studentList: [],
            isLoading: false,
          })
        }
        this.getData(this.state.value, this.state.searchText, [], [], [], this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onEditCustomGroupsClick() {
    fetch("https://api.tasselpathway.com/custom_groups", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        name: this.state.selectedItem.name,
        custom_group_id: this.state.selectedItem.custom_group_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isCustomStudentEditOpen: false,
            isLoading: false,
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onEditPersonsDistrict(row) {

    fetch("https://api.tasselpathway.com/persons/district", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        district_id: row.district_id,
        person_id: row.person_id,
        school_id: row.school_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isCustomStudentEditOpen: false,
            isLoading: false,
          })
          this.onEditStudentsSchool(row)
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onEditStudentsSchool(row) {
    fetch("https://api.tasselpathway.com/students/school", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        school_id: row.school_id,
        person_id: row.person_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isCustomStudentEditOpen: false,
            isLoading: false,
          })
          this.onDeleteTransferRequests(row)
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onDeleteTransferRequests(row) {
    fetch("https://api.tasselpathway.com/transfer_requests", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        transfer_request_id: row.transfer_request_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.getData(this.state.value, this.state.searchText, [], [], this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onRequestTransfer() {

    fetch("https://api.tasselpathway.com/transfer_requests", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        person_id: this.state.selectedStudentId,
        school_id: this.state.school_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isRequestTransferOpen: false,
          })
          alert("Your transfer request has been submitted, if approved the student will appear in your current student list")
        }
        this.getData(this.state.value, this.state.searchText, [], [], this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onRequestTransferClose = () => {
    this.setState({
      isRequestTransferOpen: false
    })
  }

  onNewClassRoomClick(event) {
    this.setState({
      isNewClassroomOpen: true,
      classRoomExpanded: true,
      studentList: [],
      teacherList: [],
      selectedItem: {}
    })
  }

  onNewClassRoomClose = () => {
    this.setState({
      isNewClassroomOpen: false
    })
    this.getData(this.state.value, this.state.searchText, [], [], this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
  }

  onNewTeacherGroupClose = () => {
    this.setState({
      isAddTeacherOpen: false
    })
  }

  onEditTeacherGroupClose = () => {
    this.setState({
      isEditTeacherOpen: false
    })
  }

  onClassRoomEditClick = (item) => {
    this.setState({
      pageNumber: 0,
      isCustomStudentEditOpen: true,
      selectedItem: item
    })

    this.getClassroomStudents(item.custom_group_id);
  }

  onCustomStudentEditClose = () => {
    this.setState({
      isCustomStudentEditOpen: false,
    })
  }

  onEditGroupChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  deletePastPosts(studentId) {
    fetch('https://api.tasselpathway.com/profile_feed/grade_change/' + studentId, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      }
    })
      .then(res => res.json())
      .then(responseJson => {

      })
  }

  onStudentSchoolChange(e) {

    let schoolId = e.target.value;

    this.setState({
      selectedStudentSchoolId: schoolId
    })
  }

  onStudentGradeChange(e) {

    let gradeId = e.target.value;

    this.setState({
      selectedStudentGradeId: gradeId
    })

    if (gradeId !== 0) {

      this.deletePastPosts(this.state.selectedStudentId);

      fetch('https://api.tasselpathway.com/feed/pastPosts/' + gradeId, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "token": this.props.token
        }
      })
        .then(res => res.json())
        .then(responseJson => {

          responseJson.results !== undefined ?
            responseJson.results.forEach((item) => {
              fetch("https://api.tasselpathway.com/profile_feed", {
                method: "POST",
                headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",
                  "token": this.props.token
                },
                credentials: 'omit',
                body: JSON.stringify({
                  feed_id: item.feed_id,
                  person_id: this.state.selectedStudentId,
                  reaction_id: 1
                })
              })
                .then(res => res.json())
                .then(responseJson => {
                  if (responseJson.error === false) {
                    console.log("Operation completed successfully")
                  }
                })
            })
            :
            console.log('Operation failed')
        })
    }
  }

  onNewSchoolChange(event, schoolName) {

    var newSchoolDetails = this.state.newSchoolDetails;
    var schoolCheckList = this.state.schoolCheckList;
    var schoolId = event.target.value;
    var i = 0;
    var schoolNameArray = this.state.schoolNameList
    var selectedSchoolId = this.state.selectedSchoolId

    if (event.target.checked) {
      newSchoolDetails.push({ school_id: schoolId })
      schoolCheckList['checked_' + schoolId] = true;
      selectedSchoolId.push(schoolId)
    } else {
      newSchoolDetails.map((item, key) => {
        if (item.school_id === schoolId) {
          newSchoolDetails.splice(i, 1);
          selectedSchoolId.splice(i, 1);
          schoolCheckList['checked_' + schoolId] = false
          const index = schoolNameArray.indexOf(schoolName);
          if (index > -1) {
            schoolNameArray.splice(index, 1);
          }
        }
        i++;
      })
    }
    this.setState({
      checkList: schoolCheckList,
      newSchoolDetails: newSchoolDetails,
      schoolNameList: this.state.schoolNameList,
      selectedSchoolId: selectedSchoolId
    })
  }

  onStudentChange(event, item) {

    if (event.target.checked !== undefined) {

      var studentList = this.state.studentList;

      if (event.target.checked) {

        studentList.push(item.person_id);

      } else {
        for (var i = 0; i < studentList.length; i++) {
          if (studentList[i] === item.person_id) {
            studentList.splice(i, 1);
          }
        }
      }

      this.setState({
        studentList: studentList
      })
    }
  }

  onPersonClickAddToBatch(event, item) {

    if (event.target.checked !== undefined) {

      var studentList = this.state.studentList;
      var batchToAdd = this.state.batchToAdd;

      if (event.target.checked) {

        studentList.push(item.person_id);
        batchToAdd['student_' + item.person_id] = 1;

      } else {

        batchToAdd['student_' + item.person_id] = 0;

        for (var i = 0; i < studentList.length; i++) {
          if (studentList[i] === item.person_id) {
            studentList.splice(i, 1);
          }
        }
      }

      this.setState({
        studentList: studentList,
        batchToAdd: batchToAdd
      })
    }
  }

  onTeacherChange(event, item) {

    if (event.target.checked !== undefined) {

      var teacherList = this.state.teacherList;
      var selectedItem = this.state.selectedItem;

      if (event.target.checked) {

        teacherList.push(item.person_id);

        if (selectedItem.coTeachers === undefined) {
          selectedItem.coTeachers = [];
        }

        selectedItem.coTeachers.push(item);

      } else {
        for (var i = 0; i < teacherList.length; i++) {
          if (teacherList[i] === item.person_id) {
            teacherList.splice(i, 1);
          }
        }

        for (var i = 0; i < selectedItem.coTeachers.length; i++) {
          if (selectedItem.coTeachers[i].person_id === item.person_id) {
            selectedItem.coTeachers.splice(i, 1);
          }
        }
      }

      this.setState({
        selectedItem: selectedItem,
        teacherList: teacherList
      })
    }
  }

  onAddCoTeachersClick = () => {

    var teacherList = [];
    var coTeachers = this.state.selectedItem.coTeachers;

    if (coTeachers !== undefined) {
      for (var i = 0; i < coTeachers.length; i++) {
        teacherList[i] = coTeachers[i].person_id;
      }
    }

    this.setState({
      isLoading: false,
      teacherList: teacherList,
      isAddTeacherOpen: true
    })
  }

  onEditCoTeachersClick = () => {

    var teacherList = [];
    var coTeachers = this.state.selectedItem.coTeachers;

    if (coTeachers !== undefined) {
      for (var i = 0; i < coTeachers.length; i++) {
        teacherList[i] = coTeachers[i].person_id;
      }
    }

    this.setState({
      isLoading: false,
      teacherList: teacherList,
      isEditTeacherOpen: true
    })
  }

  editGroupTeachers = () => {

    fetch("https://api.tasselpathway.com/custom_groups/teacher", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        custom_group_id: this.state.selectedItem.custom_group_id,
        teachers: this.state.teacherList
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isLoading: false,
          isAddTeacherOpen: false,
          isEditTeacherOpen: false
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onPickSchoolClose = () => {
    this.setState({
      isPickSchoolOpen: false
    })
  }

  onDeleteCustomGroupClose = () => {
    this.setState({
      isDeleteCustomGroupOpen: false
    })

  }

  deleteCustomGroup = () => {

    fetch("https://api.tasselpathway.com/custom_groups", {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        custom_group_id: this.state.selectedItem.custom_group_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isDeleteCustomGroupOpen: false,
          isCustomStudentEditOpen: false
        })

        this.getClassrooms();
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  editGroupStudents = () => {

    fetch("https://api.tasselpathway.com/custom_student_group", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        custom_group_id: this.state.selectedItem.custom_group_id,
        students: this.state.studentList
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isAddStudentOpen: false,
            studentList: [],
            isLoading: false,
          })
        }

        this.getClassroomStudents(this.state.selectedItem.custom_group_id);
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onRemoveStudentClick = () => {

    if (this.state.studentList.length > 0) {

      fetch("https://api.tasselpathway.com/custom_groups/student", {
        method: "DELETE",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "token": this.props.token
        },
        body: JSON.stringify({
          custom_group_id: this.state.selectedItem.custom_group_id,
          students: this.state.studentList
        })
      })
        .then((response) => response.json())
        .then((responseJson) => {

          this.setState({
            onDeleteCustomGroupClose: false,
            studentList: [],
          })

          this.getClassroomStudents(this.state.selectedItem.custom_group_id);

        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });
    }
  }

  onAddStudentClick() {

    this.getAvailableClassroomStudents(this.state.availSearchText, this.state.availPerPage, this.state.availPageNumber);

    this.setState({
      isAddStudentOpen: true
    })
  }

  onAddStudentClose = () => {
    this.setState({
      isAddStudentOpen: false
    })
  }

  addStudentsToClassroom = (item) => {

    fetch("https://api.tasselpathway.com/custom_student_group", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
      body: JSON.stringify({
        custom_group_id: item.custom_group_id,
        students: this.state.studentList
      })
    })
      .then(res => res.json())
      .then(responseJson => {

        this.setState({
          isAddToClassroomOpen: false,
          studentList: [],
          batchToAdd: []
        })
      })
  }

  openAddToClassroomDialog = () => {
    this.setState({
      isAddToClassroomOpen: true
    })
  }

  closeAddToClassroomDialog = () => {
    this.setState({
      isAddToClassroomOpen: false
    })
  }

  onPromoteClick() {
    this.setState({
      isPromoteOpen: true
    })
  }

  onPromoteClose() {
    this.setState({
      isPromoteOpen: false
    })
  }

  promoteStudents() {

    fetch("https://api.tasselpathway.com/students/promote", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      body: JSON.stringify({
        grade_id: this.state.grade_id,
        school_id: this.state.school_id,
        students: this.state.studentList
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.setState({
            isPromoteOpen: false,
            studentList: [],
            isLoading: false,
          })

          this.getData(this.state.value, this.state.searchText, this.state.gradeList, this.state.classRoomList, this.state.studentFilter, this.state.perPage, this.state.pageNumber, this.state.sortBy, this.state.sortDirection);
        }

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onPrintProfileClick() {

    fetch("https://api.tasselpathway.com/students/batch_print", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "token": this.props.token
      },
      credentials: "omit",
      body: JSON.stringify({
        students: this.state.studentList
      })
    })
      .then(res => res.json())
      .then(responseJson => {

        this.setState({
          isPrintProfileOpen: true,
          printStudents: responseJson.results
        })
      })
  }

  printProfileClose = () => {
    this.setState({
      isPrintProfileOpen: false
    })
  }

  printProfile() {

    return (
      <Document>

        {this.state.printStudents.map((item, key) => {
          return (
            <>
              <Page key={key} size="A4" style={styles.page}>
                <View style={styles.section}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={styles.headerText}>
                      {item.first_name} {item.last_name}
                    </Text>
                  </View>
                  {this.state.school_name !== null ?
                    <View style={{ width: 250 }}>
                      <Text style={styles.subHeaderText}>{item.grade_name}, {item.school_name}</Text>
                      <Text style={styles.pathwayText}>Pathway: {item.pathway_name}</Text>
                    </View>
                    :
                    <View style={{ width: 250 }}>
                      <Text style={styles.subHeaderText}>{item.grade_name}</Text>
                      <Text style={styles.pathwayText}>Pathway: {item.pathway_name}</Text>
                    </View>
                  }

                  <Text style={styles.sectionHeaderTextLeft}>FAVORITE SUBJECTS</Text>
                  {this.renderSubjects(item.subjects)}

                  <Text style={styles.sectionHeaderTextLeft}>PERSONALITY TRAITS</Text>
                  {this.renderTraits(item.traits)}

                  <Text style={styles.sectionHeaderTextLeft}>LEARNING STYLE</Text>
                  {this.renderLearning(item.learning)}

                  <Text style={styles.sectionHeaderTextLeft}>SKILLS</Text>
                  {this.renderSkills(item.skills)}

                </View>

                <View style={styles.section2}>
                  <Image src={require('../../assets/logoBlack.png')} style={{
                    marginVertical: -40,
                    marginHorizontal: 100, height: 100
                  }} alt="" />

                  <Text style={styles.sectionHeaderTextRight}>HONORS & AWARDS</Text>
                  {this.renderAwards(item.awards)}

                  <Text style={styles.sectionHeaderTextRightSecond}>CLUBS & SPORTS</Text>
                  {this.renderClubs(item.clubs)}
                </View>
              </Page>

              <Page size="A4" style={styles.page}>
                <View style={styles.section}>

                  <Text style={styles.sectionHeaderTextLeft}>ASSESSMENTS</Text>
                  {this.renderAssessments(item.assessments)}

                  <Text style={styles.sectionHeaderTextLeft}>TOP COLLEGES</Text>
                  {this.renderColleges(item.colleges)}

                </View>

                <View style={styles.section2}>
                  <Image src={require('../../assets/logoBlack.png')} style={{
                    marginVertical: -40,
                    marginHorizontal: 100, height: 100
                  }} alt="" />

                  <Text style={styles.sectionHeaderTextRightPage2}>PROJECTS</Text>
                  {this.renderProjects(item.projects)}

                  <Text style={styles.sectionHeaderTextRightSecondTopCareers}>TOP CAREERS</Text>
                  <View style={{ marginTop: -25 }}>
                    {this.renderCareers(item.careers)}
                  </View>
                </View>
              </Page>
            </>
          )
        })}

      </Document>
    )
  }

  renderTraits(traits) {
    let allTraits = traits;

    if (traits !== undefined) {
      let topFiveTraits = allTraits.slice(0, 5)
      var content = topFiveTraits.map((item, key) => {
        return (
          <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.cluster_name} </Text>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  renderSubjects(subjects) {

    let allSubjects = subjects;

    if (subjects !== undefined) {
      let topFiveSubjects = allSubjects.slice(0, 5)
      var content = topFiveSubjects.map((item, key) => {
        return (
          <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.group_name} </Text>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  renderLearning(learningStyle) {

    let allLearningStyle = learningStyle;

    if (learningStyle !== undefined) {
      let topLearningStyle = allLearningStyle.slice(0, 3);
      var content = topLearningStyle.map((item, key) => {
        return (
          <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.learning_style_name}: {item.percentage}% </Text>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  renderSkills(skills) {
    if (skills !== undefined) {
      let allSkills = skills;
      let topFiveSkills = allSkills.slice(0, 5);
      var content = topFiveSkills.map((item, key) => {
        return (
          <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.skill_name}</Text>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  renderAwards(awards) {
    if (awards !== undefined) {
      let allAwards = awards;
      let topFiveAwards = allAwards.slice(0, 5);
      var content = topFiveAwards.map((item, key) => {
        return (
          <>
            <Text key={key} style={styles.bulletPointHeaderTextRight}>• {item.achievement_name.toUpperCase()}</Text>
            <Text key={key} style={styles.bulletPointTextRight}>{item.achievement_type}</Text>
            <Text key={key} style={styles.bulletPointTextRight}>{item.achievement_description}</Text>
          </>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  renderClubs(clubs) {
    if (clubs !== undefined) {
      let allClubs = clubs;
      let topFiveClubs = allClubs.slice(0, 5);
      var content = topFiveClubs.map((item, key) => {
        return (
          <>
            <Text key={key} style={styles.bulletPointHeaderTextRight}>• {item.activity_name.toUpperCase()}</Text>
            <Text key={key} style={styles.bulletPointTextRight}>{item.activity_role}</Text>
          </>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  renderAssessments(assessments) {
    if (assessments !== undefined) {
      let allAssessments = assessments;
      let topFiveAssessments = allAssessments.slice(0, 5);
      var content = topFiveAssessments.map((item, key) => {
        return (
          <>
            <Text key={key} style={styles.bulletPointHeaderTextLeft}>• {item.assessment_type_name.toUpperCase()}</Text>
            <Text key={key} style={styles.bulletPointTextLeft}>Year taken: {item.grade_name}</Text>
            {item.composite_score > 0 ? <Text key={key} style={styles.bulletPointTextLeft}>Composite Score: {item.composite_score}</Text> : null}
            {item.assessment_type_id === 2 ?
              <>
                <Text key={key} style={styles.bulletPointTextLeft}>Math: {item.math_score}</Text>
                <Text key={key} style={styles.bulletPointTextLeft}>Science: {item.science_score}</Text>
                <Text key={key} style={styles.bulletPointTextLeft}>English: {item.english_score}</Text>
                <Text key={key} style={styles.bulletPointTextLeft}>Reading: {item.reading_score}</Text>
              </>
              : null
            }
            {item.assessment_type_id === 3 ?
              <>
                <Text key={key} style={styles.bulletPointTextLeft}>Reading: {item.reading_score}</Text>
                <Text key={key} style={styles.bulletPointTextLeft}>Writing: {item.writing_score}</Text>
                <Text key={key} style={styles.bulletPointTextLeft}>Math: {item.math_score}</Text>
              </>
              : null
            }

            {item.assessment_type_id === 5 ?
              <>
                <Text key={key} style={styles.bulletPointTextLeft}>Test Name: {item.assessment_name}</Text>
                <Text key={key} style={styles.bulletPointTextLeft}>Composite Score:  {item.composite_score}</Text>
              </>
              : null
            }


          </>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  renderProjects(projects) {
    if (projects !== undefined) {
      let allProjects = projects;
      let topFiveProjects = allProjects.slice(0, 5);
      var content = topFiveProjects.map((item, key) => {
        return (
          <>
            <Text key={key} style={styles.bulletPointHeaderTextRight}>• {item.project_name.toUpperCase()}</Text>
            <Text key={key} style={styles.bulletPointTextRight}>{item.project_description}</Text>
          </>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  renderColleges(colleges) {
    if (colleges !== undefined) {
      let allColleges = colleges;
      let topFiveColleges = allColleges.slice(0, 5);
      var content = topFiveColleges.map((item, key) => {
        return (
          <View key={key} style={{ display: 'flex', flexDirection: 'row' }}>
            <Image src={item.logo_image_url}
              style={{ height: 20, width: 20, marginLeft: 30, marginTop: 4.5, borderRadius: 10 }}
              source={{
                header: {
                  'Access-Control-Allow-Origin': '*'
                }
              }}
              alt="collegeIcon" />
            <Text style={styles.bulletPointHeaderTextRight}>{item.college_name.toUpperCase()}</Text>
          </View>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  renderCareers(careers) {
    if (careers !== undefined) {
      let allCareers = careers;
      let topFiveCareers = allCareers.slice(0, 5);
      var content = topFiveCareers.map((item, key) => {
        return (
          <View key={key} style={{ display: 'flex', flexDirection: 'row', width: 180, flexWrap: 'wrap' }}>
            <Image src={item.logo_image_url} style={{ height: 20, width: 20, marginLeft: 0, marginTop: 4.5, borderRadius: 10 }}
              source={{
                header: {
                  'Access-Control-Allow-Origin': '*'
                }
              }}
              alt="collegeIcon" />
            <Text style={styles.bulletPointHeaderTextRightCareers}>{item.career_name.toUpperCase()}</Text>
          </View>
        )
      })
      return content;
    } else {
      return null;
    }
  }

  render() {

    const { classes } = this.props;
    const numberOfStudents = this.state.totalRows;
    const numberOfWidthdrawn = this.state.withdrawnStudentRows;

    return (
      <div className={classes.root}>

        <Dialog
          fullScreen
          open={this.state.isPrintProfileOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle onClose={this.printProfileClose} id="alert-dialog-title"
            titleStyle={{ textAlign: "center", marginBottom: 10 }}>Print or download profile below.</DialogTitle>
          <PDFViewer style={{ minHeight: '100%' }}>
            {this.printProfile()}
          </PDFViewer>
        </Dialog>

        <Dialog open={this.state.isPromoteOpen}
          onClose={this.onPromoteClose}
          disableBackdropClick={true} maxWidth="sm"
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onPromoteClose}>
            Promote Students
          </DialogTitle>

          <DialogContent style={{ width: 700 }}>
            <Grid container spacing={2}>

              <FormControl className={classes.formControl}
                style={{ marginTop: 10 }} fullWidth>
                <InputLabel>Grade</InputLabel>
                <Select
                  value={this.state.grade_id}
                  onChange={(event) => this.setState({ grade_id: event.target.value })}
                  inputProps={{
                    name: 'grade_id',
                    id: 'grade_id',
                  }}>
                  <MenuItem disabled value={0}>{i18n.t("STUDENTS_SELECT_GRADE")}</MenuItem>
                  {this.renderGrades()}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}
                style={{ marginTop: 10 }} fullWidth>
                <InputLabel>School</InputLabel>
                <Select
                  value={this.state.school_id}
                  onChange={(event) => this.setState({ school_id: event.target.value })}
                  inputProps={{
                    name: 'school_id',
                    id: 'school_id',
                  }}>
                  <MenuItem disabled value={0}>{i18n.t("STUDENTS_SELECT_SCHOOL")}</MenuItem>
                  {this.renderSchools()}
                </Select>
              </FormControl>


            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" size="small" onClick={() => this.onPromoteClose()} color="primary"
              style={{ color: '#FFF', fontWeight: 'bold', marginRight: 10, marginTop: 20, }}>
              Cancel
            </Button>

            <Button variant="contained" size="small" onClick={() => this.promoteStudents()} color="primary"
              style={{ color: '#FFF', fontWeight: 'bold', marginRight: 10, marginTop: 20, }}>
              Promote
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isAddToClassroomOpen}
          onClose={this.closeAddToClassroomDialog}
          disableBackdropClick={true} maxWidth="md"
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.closeAddToClassroomDialog}>
            Add To Classroom
          </DialogTitle>

          <DialogContent style={{ width: 700 }}>
            <Grid container spacing={2}>
              {this.state.customGroups.map((item, key) => {
                return (
                  <Grid key={key} item xs={6}>
                    <Card className={classes.root} style={{ borderRadius: 5 }} variant="outlined">
                      <CardContent>
                        <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
                          {item.name} ({item.invite_token})
                        </Typography>
                        <br />
                        <Typography variant="body2" component="p">
                          <strong>Created By: </strong>{item.ownerFirstName} {item.ownerLastName}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button variant="contained" color="primary" onClick={_ => this.addStudentsToClassroom(item)}>
                          <Typography style={{ fontSize: 12, fontWeight: 'bold', color: "white" }}>Add to Classroom</Typography>
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.isPickSchoolOpen}
          onClose={this.onPickSchoolClose} maxWidth="md"
          fullWidth
          disableBackdropClick={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onPickSchoolClose}>
            Please choose a school for the student(s).
          </DialogTitle>

          <DialogContent>
            <>
              <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>Select School</Typography>

              <FormGroup row style={{ marginLeft: 20 }}>

                {this.state.schools.map((item, key) => {
                  return (
                    <FormControlLabel key={key} onChange={(event) => this.onNewSchoolChange(event, item.school_name)}
                      control={
                        <Checkbox value={item.school_id} checked={this.state.schoolCheckList['checked_' + item.school_id] ? true : false} />
                      }
                      label={item.school_name}
                    />
                  )
                })}

              </FormGroup>
            </>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" size="small" onClick={() => this.onPickSchoolClose()} color="primary"
              style={{ color: '#FFF', fontWeight: 'bold', marginRight: 10, marginTop: 20, }}>
              Cancel
            </Button>

            <Button variant="contained" size="small" onClick={() => this.chooseSchool()} color="primary"
              style={{ color: '#FFF', fontWeight: 'bold', marginRight: 10, marginTop: 20, }}>
              Choose
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isSchoolOpen}
          onClose={this.onSchoolClose} maxWidth="xs"
          fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onSchoolClose}>
            {i18n.t("STUDENTS_CHOOSE_SCHOOL")}
          </DialogTitle>

          <DialogContent>

            <div>
              <FormControl className={classes.formControl}
                style={{ marginTop: 10 }} fullWidth>
                <Select
                  value={this.state.school_id}
                  onChange={(event) => this.setState({ school_id: event.target.value })}
                  inputProps={{
                    name: 'school_id',
                    id: 'school_id',
                  }}>
                  <MenuItem disabled value={0}>{i18n.t("STUDENTS_SELECT_SCHOOL")}</MenuItem>
                  {this.renderSchools()}
                </Select>
              </FormControl>

            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => this.generateToken(this.state.school_id)} color="primary"
              style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }}>
              {i18n.t("STUDENTS_GENERATE_BUTTON")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isTokenOpen}
          onClose={this.onTokenClose} maxWidth="xs"
          fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onTokenClose}>
            {i18n.t("STUDENTS_NEW_INVITE_TOKEN")}
          </DialogTitle>

          <DialogContent>

            <div>
              <Typography style={{ color: '#555', marginTop: 10, textAlign: 'center', fontSize: 64 }}>{this.state.inviteToken}</Typography>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.isStudentEditOpen}
          onClose={this.onStudentEditClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onStudentEditClose}>
            Edit Student Information
          </DialogTitle>

          <DialogContent>
            <Grid container style={{ marginTop: 15 }}>

              <Grid item xs={6}>

                <FormControl className={classes.formControl} style={{ width: '95%' }}>
                  <TextField variant='outlined'
                    fullwidth label="First Name"
                    onChange={(e) => this.setState({ selectedStudentFirstName: e.target.value })}
                    value={this.state.selectedStudentFirstName} />
                </FormControl>

              </Grid>

              <Grid item xs={6}>

                <FormControl className={classes.formControl} style={{ width: "100%" }}>
                  <TextField variant='outlined'
                    fullWidth label="Last Name"
                    onChange={(e) => this.setState({ selectedStudentLastName: e.target.value })}
                    value={this.state.selectedStudentLastName}
                  />
                </FormControl>

              </Grid>
            </Grid>
            <Grid container>

              <Grid item xs={6}>

                <FormControl className={classes.formControl} style={{ marginTop: 15, width: '95%' }}>
                  <InputLabel>Grade</InputLabel>

                  <Select style={{ fontSize: 16 }}
                    value={this.state.selectedStudentGradeId}
                    inputProps={{
                      name: 'newGradeId',
                      id: 'newGradeId',
                    }}
                    onChange={(e) => this.onStudentGradeChange(e)}
                  >

                    {this.state.grades.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.grade_id}>{item.grade_name}</MenuItem>
                      )
                    })
                    }
                  </Select>
                </FormControl>

              </Grid>

              <Grid item xs={6}>

                <FormControl className={classes.formControl} style={{ marginTop: 15, width: "100%" }}>
                  <TextField type='tel'
                    fullWidth label="Phone Number"
                    onChange={(e) => this.setState({ selectedStudentPhoneNumber: e.target.value })}
                    value={this.state.selectedStudentPhoneNumber}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={12}>

                <FormControl className={classes.formControl} style={{ marginTop: 15, width: '100%' }}>
                  <InputLabel>School</InputLabel>

                  <Select style={{ fontSize: 16 }}
                    value={this.state.selectedStudentSchoolId}
                    inputProps={{
                      name: 'newSchoolId',
                      id: 'newSchoolId',
                    }}
                    onChange={(e) => this.onStudentSchoolChange(e)}
                  >

                    {this.state.schools.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.school_id}>{item.school_name}</MenuItem>
                      )
                    })
                    }
                  </Select>
                </FormControl>

              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} style={{ width: '100%' }}>
                  <TextField
                    style={{ width: '100%' }}
                    id="standard-name"
                    label="Tassel Email"
                    onChange={(e) => this.setState({ selectedStudentEmailAddress: e.target.value })}
                    value={this.state.selectedStudentEmailAddress}
                    margin="normal"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl} style={{ width: '100%' }}>
                  {
                    this.state.showPassword === true ?
                      <TextField
                        style={{ width: '100%' }}
                        id="standard-name"
                        label="Change student password"
                        onChange={(e) => this.setState({ selectedStudentPassword: e.target.value })}
                        value={this.state.selectedStudentPassword}
                        margin="normal"
                      />
                      :
                      <TextField
                        style={{ width: '100%' }}
                        id="standard-name"
                        label="Change student password"
                        onChange={(e) => this.setState({ selectedStudentPassword: e.target.value })}
                        value={this.state.selectedStudentPassword}
                        type="password"
                        margin="normal"
                      />
                  }
                  <FontAwesomeIcon icon={faEye}
                    style={{ color: '#25D192', position: "absolute", top: '55%', right: '5%', fontSize: '16' }}
                    onClick={() => this.setState({ showPassword: !this.state.showPassword })} />
                </FormControl>
              </Grid>

              <Grid item xs={6}>

                <FormControl className={classes.formControl} style={{ marginTop: 20, marginLeft: 15, width: "95%" }}>
                  <FormControlLabel control={<Checkbox onChange={(e) => this.setState({ selectedStudentEarlyGraduationProgram: !this.state.selectedStudentEarlyGraduationProgram })}
                    checked={this.state.selectedStudentEarlyGraduationProgram} />} label="Early Graduation Program" />
                </FormControl>

              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>

            <Grid container>

              <Grid item xs={8}>
                {this.state.selectedStudentIsActive === 1 ?
                  <Button variant="contained" style={{ marginLeft: 15, marginBottom: 10 }}
                    onClick={() => this.onSaveStudentEdits(this.state.selectedStudentFirstName, this.state.selectedStudentLastName,
                      this.state.selectedStudentGradeId, this.state.selectedStudentSchoolId, 0, this.state.selectedStudentEmailAddress, this.state.selectedStudentPassword,
                      this.state.selectedStudentDistrictId, this.state.selectedStudentId, this.state.selectedStudentPhoneNumber, this.state.selectedStudentEarlyGraduationProgram)}
                    color="secondary">
                    <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>De-Activate Student</Typography>
                  </Button>
                  :
                  <Button variant="contained" style={{ marginLeft: 15, marginBottom: 10 }}
                    onClick={() => this.onSaveStudentEdits(this.state.selectedStudentFirstName, this.state.selectedStudentLastName,
                      this.state.selectedStudentGradeId, this.state.selectedStudentSchoolId, 1, this.state.selectedStudentEmailAddress, this.state.selectedStudentPassword,
                      this.state.selectedStudentDistrictId, this.state.selectedStudentId, this.state.selectedStudentPhoneNumber, this.state.selectedStudentEarlyGraduationProgram)}
                    color="secondary">
                    <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Re-Activate Student</Typography>
                  </Button>
                }
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right', alignItems: 'right' }}>
                <Button variant="contained" style={{ marginRight: 15, marginBottom: 10 }}
                  onClick={() => this.onSaveStudentEdits(this.state.selectedStudentFirstName, this.state.selectedStudentLastName,
                    this.state.selectedStudentGradeId, this.state.selectedStudentSchoolId, this.state.selectedStudentIsActive, this.state.selectedStudentEmailAddress,
                    this.state.selectedStudentPassword, this.state.selectedStudentDistrictId, this.state.selectedStudentId,
                    this.state.selectedStudentPhoneNumber, this.state.selectedStudentEarlyGraduationProgram)}
                  color="primary">
                  <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Save Changes</Typography>
                </Button>
              </Grid>

            </Grid>

          </DialogActions>
        </Dialog>


        <Dialog open={this.state.isNewClassroomOpen}
          onClose={this.onNewClassRoomClose} maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onNewClassRoomClose}>
            Create Classroom
          </DialogTitle>

          <DialogContent style={{ display: 'flex' }}>

            <Grid container style={{ flexDirection: 'row', margin: 0, padding: 0 }}>
              <Grid item xs={6} style={{ margin: 0, padding: 0 }}>
                <TextField fullWidth
                  id="standard-name"
                  label="Name of Classroom"
                  onChange={(e) => this.setState({ groupName: e.target.value })}
                  value={this.state.groupName}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography style={{
                      fontSize: 16, marginTop: 10, marginBottom: 20, fontWeight: 'bold',
                      paddingTop: 10
                    }}>
                      Select Any Co-Teachers For This Class
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ overflow: 'auto', height: 300 }}>

                {this.state.teachers.map((item, key) => {
                  return (
                    <Grid item xs={4} style={{ margin: 0, padding: 0 }}>
                      <FormControl className={classes.formControl} style={{ width: '95%' }}>
                        <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                          <FormGroup>
                            <FormControlLabel style={{ display: 'flex' }} key={key} onChange={(event) => this.onTeacherChange(event, item)}
                              control={
                                <Checkbox value={item.person_id} checked={this.state.teacherList.includes(item.person_id)} />
                              }
                              label={item.first_name + ' ' + item.last_name}
                            />
                          </FormGroup>
                        </FormControl>
                      </FormControl>
                    </Grid>
                  )
                })
                }
              </Grid>


            </Grid>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 20, marginBottom: 10 }}
              onClick={() => this.createClassroom()}
              color="primary">
              <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Add</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isAddTeacherOpen}
          onClose={this.onNewTeacherGroupClose} maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onNewTeacherGroupClose}>
            Add Co-Teachers
          </DialogTitle>

          <DialogContent style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flexGrow: 1 }}>
                <Typography style={{
                  fontSize: 16, marginTop: 10, marginBottom: 20, fontWeight: 'bold',
                  paddingTop: 10
                }}>
                  Select Any Co-Teachers For This Class
                </Typography>
                <Grid container spacing={3} style={{ height: 300, overflow: 'auto' }}>

                  {this.state.teachers.map((item, key) => {
                    return (
                      <Grid item xs={4} style={{ margin: 0, padding: 0 }}>
                        <FormControl className={classes.formControl} style={{ width: '95%' }}>
                          <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                            <FormGroup>
                              <FormControlLabel style={{ display: 'flex' }} key={key} onChange={(event) => this.onTeacherChange(event, item)}
                                control={
                                  <Checkbox value={item.person_id} checked={this.state.teacherList.includes(item.person_id)} />
                                }
                                label={item.first_name + ' ' + item.last_name}
                              />
                            </FormGroup>
                          </FormControl>
                        </FormControl>
                      </Grid>
                    )
                  })
                  }
                </Grid>
              </div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 20, marginBottom: 10 }}
              onClick={() => this.editGroupTeachers()}
              color="primary">
              <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Add</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isAddStudentOpen}
          onClose={this.onAddStudentClose} maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onAddStudentClose}>
            Add Students
          </DialogTitle>

          <DialogContent style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ width: '100%', flexGrow: 1 }}>

                <Grid container style={{ width: '100%', marginBottom: 20 }}>
                  <Grid item xs={5}>
                    <Typography style={{
                      fontSize: 16, fontWeight: 'bold',
                      paddingTop: 10
                    }}>
                      Select Any Students For This Class
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField style={{ marginTop: -10 }}
                      id="standard-name" fullWidth
                      label="Search" variant='standard'
                      onChange={(event) => this.onAvailSearch(event)}
                      value={this.state.availSearchText}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={3} style={{ alignItems: 'right', textAlign: 'right' }}>
                    <IconButton
                      disabled={this.state.availPageNumber > 0 ? false : true} style={{ marginLeft: 20 }}
                      onClick={() => this.onAvailPreviousClick()}>
                      <ArrowBackIosIcon style={{ fontSize: 18 }} />
                    </IconButton>
                    <IconButton style={{ marginRight: 5 }}
                      disabled={(((this.state.availPageNumber * this.state.availPerPage) + 1) > (this.state.availTotalRows - 20)) ? true : false}
                      onClick={() => this.onAvailNextClick()}>
                      <ArrowForwardIosIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ height: 250, overflow: 'auto' }}>

                  {this.state.availableStudents.map((item, key) => {
                    return (
                      <Grid item xs={4} style={{ margin: 0, padding: 0 }}>
                        <FormControl className={classes.formControl} style={{ width: '95%' }}>
                          <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                            <FormGroup>
                              <FormControlLabel style={{ display: 'flex' }} key={key} onChange={(event) => this.onStudentChange(event, item)}
                                control={
                                  <Checkbox value={item.person_id} checked={this.state.studentList.includes(item.person_id)} />
                                }
                                label={item.first_name + ' ' + item.last_name}
                              />
                            </FormGroup>
                          </FormControl>
                        </FormControl>
                      </Grid>
                    )
                  })
                  }
                </Grid>
              </div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 20, marginBottom: 10 }}
              onClick={() => this.editGroupStudents()}
              color="primary">
              <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Add</Typography>
            </Button>

          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isEditTeacherOpen}
          onClose={this.onEditTeacherGroupClose} maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onEditTeacherGroupClose}>
            Edit Co-Teachers
          </DialogTitle>

          <DialogContent style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flexGrow: 1 }}>
                <Typography style={{
                  fontSize: 16, marginTop: 10, marginBottom: 20, fontWeight: 'bold',
                  paddingTop: 10
                }}>
                  Select Any Co-Teachers For This Class
                </Typography>
                <Grid container spacing={3} style={{ height: 300, overflow: 'auto' }}>

                  {this.state.teachers.map((item, key) => {
                    return (
                      <Grid item xs={4} style={{ margin: 0, padding: 0 }}>
                        <FormControl className={classes.formControl} style={{ width: '95%' }}>
                          <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                            <FormGroup>
                              <FormControlLabel style={{ display: 'flex' }} key={key} onChange={(event) => this.onTeacherChange(event, item)}
                                control={
                                  <Checkbox value={item.person_id} checked={this.state.teacherList.includes(item.person_id)} />
                                }
                                label={item.first_name + ' ' + item.last_name}
                              />
                            </FormGroup>
                          </FormControl>
                        </FormControl>
                      </Grid>
                    )
                  })
                  }
                </Grid>
              </div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ marginRight: 20, marginBottom: 10 }}
              onClick={() => this.editGroupTeachers()}
              color="primary">
              <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Save</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isDeleteCustomGroupOpen}
          onClose={this.onDeleteCustomGroupClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">

          <DialogTitle id="customized-dialog-title"
            onClose={this.onDeleteCustomGroupClose}>
            Delete Classroom
          </DialogTitle>

          <DialogContent>

            <Grid container style={{ textAlign: 'center' }}>
              <Typography>Are you sure you want to delete this classroom?</Typography>
            </Grid>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" style={{ backgroundColor: '#EA605B', marginRight: 20 }}
              onClick={() => this.deleteCustomGroup()}>
              <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Delete</Typography>
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.isCustomStudentEditOpen}
          onClose={this.onCustomStudentEditClose} maxWidth="md"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onCustomStudentEditClose}>
            Edit Classroom ({this.state.selectedItem.invite_token})
          </DialogTitle>

          <DialogContent style={{ height: 400 }}>

            <div style={{ display: 'flex' }}>
              <Grid item xs={4}>
                <TextField fullWidth
                  id="standard-name" variant='standard'
                  label="Name of Classroom"
                  onChange={(event) => this.onEditGroupChange(event)}
                  value={this.state.selectedItem.name}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={2}>
                <TextField fullWidth
                  id="standard-name" variant='standard'
                  label="Classroom Token"

                  value={this.state.selectedItem.invite_token}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={6} style={{ marginTop: 15, color: '#757575' }}>

                <Typography style={{ fontSize: 11 }}>Co-Teachers</Typography>

                {(this.state.selectedItem.coTeachers !== undefined && this.state.selectedItem.coTeachers.length > 0) ?

                  this.state.selectedItem.coTeachers.map((item, key) => {
                    if (key > 0) {
                      return (
                        <Typography key={key} style={{
                          display: 'inline-block', fontSize: 14,
                          marginTop: 5, color: '#25D192', fontWeight: 'bold'
                        }}>
                          {', ' + item.first_name + ' ' + item.last_name}
                        </Typography>
                      )
                    } else {
                      return (
                        <Typography key={key} style={{
                          display: 'inline-block', fontSize: 14,
                          marginTop: 5, color: '#25D192', fontWeight: 'bold'
                        }}>
                          {item.first_name + ' ' + item.last_name}
                        </Typography>
                      )
                    }
                  })
                  :
                  null
                }
              </Grid>
            </div>

            <Grid container spacing={3} style={{ maxHeight: 245, marginTop: 20, overflow: 'auto' }}>

              {this.state.customGroupsStudents.map((item, key) => {
                return (
                  <Grid item xs={4} style={{ margin: 0, padding: 0 }}>
                    <FormControl className={classes.formControl} style={{ width: '95%' }}>
                      <FormControl component="fieldset" style={{ marginLeft: 30 }}>
                        <FormGroup>
                          <FormControlLabel style={{ display: 'flex' }} key={key} onChange={(event) => this.onStudentChange(event, item)}
                            control={
                              <Checkbox value={item.person_id} checked={this.state.studentList.includes(item.person_id)} />
                            }
                            label={item.first_name + ' ' + item.last_name}
                          />
                        </FormGroup>
                      </FormControl>
                    </FormControl>
                  </Grid>
                )
              })}
            </Grid>

          </DialogContent>
          <DialogActions>

            <Grid container>

              <Grid item xs={2}>
                <Button variant="contained" style={{ marginBottom: 10, marginLeft: 10 }}
                  onClick={() => this.onDeleteCustomGroupClick()} size="small"
                  color="primary">
                  <Typography style={{ color: '#FFF', fontWeight: 'bold', fontSize: 14 }}>Delete Classroom</Typography>
                </Button>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={8} style={{ textAlign: 'right' }}>
                <Button variant="contained" color="primary" style={{ marginBottom: 10, marginRight: 10 }}
                  onClick={() => this.onAddStudentClick()} size="small">
                  <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>Add Students</Typography>
                </Button>
                <Button variant="contained" color="primary" style={{ marginBottom: 10, marginRight: 10 }}
                  onClick={() => this.onRemoveStudentClick()} size="small">
                  <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>Remove Students</Typography>
                </Button>
                {(this.state.selectedItem.coTeachers !== undefined && this.state.selectedItem.coTeachers.length > 0) ?
                  <Button variant="contained" color="primary" style={{ marginBottom: 10, marginRight: 10 }}
                    onClick={() => this.onEditCoTeachersClick()} size="small">
                    <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>Edit Co-Teachers</Typography>
                  </Button>
                  :
                  <Button variant="contained" color="primary" style={{ marginBottom: 10, marginRight: 10 }}
                    onClick={() => this.onAddCoTeachersClick()} size="small">
                    <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>Add Co-Teachers</Typography>
                  </Button>
                }

                <Button variant="contained" style={{ marginBottom: 10, marginRight: 10 }}
                  onClick={() => this.onEditCustomGroupsClick()} size="small"
                  color="primary">
                  <Typography style={{ color: '#FFF', fontWeight: 'bold', fontSize: 14 }}>Save Classroom</Typography>
                </Button>
              </Grid>


            </Grid>

          </DialogActions>
        </Dialog>


        {/* Transfer Request dialogue */}
        <Dialog open={this.state.isRequestTransferOpen}
          onClose={this.onRequestTransferClose} maxWidth="sm"
          fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onRequestTransferClose}>
            Request Transfer for Student
          </DialogTitle>

          <DialogContent>

            <Grid container style={{ textAlign: 'center' }}>
              <Typography>Request transfer for <strong>{this.state.selectedStudentFirstName} {this.state.selectedStudentLastName}</strong> to?</Typography>
            </Grid>

            <FormControl className={classes.formControl}
              style={{ marginTop: 10 }} fullWidth>
              <Select
                value={this.state.school_id}
                onChange={(event) => this.setState({ school_id: event.target.value })}
                inputProps={{
                  name: 'school_id',
                  id: 'school_id',
                }}>
                <MenuItem disabled value={0}>{i18n.t("STUDENTS_SELECT_SCHOOL")}</MenuItem>
                {this.renderSchools()}
              </Select>
            </FormControl>

          </DialogContent>

          <DialogActions>
            <Button variant="contained" style={{ backgroundColor: '#EA605B', marginRight: 20 }}
              onClick={() => this.onRequestTransfer(this.state.selectedStudentId)}>
              <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>REQUEST TRANSFER</Typography>
            </Button>
          </DialogActions>

        </Dialog>

        <Snackbar
          classes={{
            root: classes.message
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          autoHideDuration={5000}
          onClose={this.onCloseMessage}
          open={this.state.showMessage}>
          <SnackbarContent
            message={
              <span style={styles.message}>
                {this.state.alertMessage}
              </span>
            }
            classes={{
              root: classes.error
            }}
            action={
              <IconButton key="close" onClick={this.onCloseMessage} className={classes.content}>
                <CloseIcon style={{ color: '#FFF' }} />
              </IconButton>
            }>
          </SnackbarContent>
        </Snackbar>

        <Grid container style={styles.itemContainerStyle}>
          <Grid item xs={12} style={{ marginLeft: 100, marginRight: 100 }}>

            <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 24, color: '#333', fontWeight: 'bold' }}>Students</Typography>
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 30 }}>
                {this.props.selectedLanguage === "esp" ?
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={this.onSearch} style={{ width: '100%' }}
                      placeholder="Buscar"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                  :
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      onChange={this.onSearch} style={{ width: '100%' }}
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </div>
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid container>

            <Grid item xs={2} style={{ marginTop: 20 }}>

              <Accordion square expanded={this.state.gradeExpanded} classes={{ expanded: classes.expanded }}
                onChange={() => this.setState({ gradeExpanded: !this.state.gradeExpanded })}
                style={{ backgroundColor: '#eaeaea', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                  <FormControl className={classes.formControl}
                    style={{
                      height: 200, borderBottom: '1px solid #eaeaea', marginLeft: 5,
                      paddingLeft: 10, width: '100%', overflow: 'auto'
                    }}>
                    <FormGroup>

                      {this.state.grades.map((item, key) => {

                        return (
                          <FormControlLabel key={key} onClick={(event) => this.onGradeClick(event, item.grade_id)}
                            control={
                              <Checkbox />
                            }
                            label={<Typography style={{ fontSize: 11 }}>{item.grade_name}</Typography>}
                          />
                        )

                      })}

                    </FormGroup>

                  </FormControl>
                </AccordionDetails>
              </Accordion>


              <Accordion square expanded={this.state.classRoomExpanded} classes={{ expanded: classes.expanded }}
                onChange={() => this.setState({ classRoomExpanded: !this.state.classRoomExpanded })}
                style={{ backgroundColor: '#eaeaea', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{ fontSize: 14, color: '#25D192', fontWeight: 'bold' }}>My Classrooms
                    <IconButton onClick={() => this.onNewClassRoomClick()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0, marginLeft: 15 }}>
                  <FormControl className={classes.formControl}
                    style={{
                      height: 200, borderBottom: '1px solid #eaeaea', marginLeft: 5,
                      paddingLeft: 10, width: '100%', overflow: 'auto', paddingBottom: 60
                    }}>
                    <FormGroup>

                      {this.state.customGroups.map((item, key) => {
                        return (
                          <div>
                            <FormControlLabel key={key} onClick={(event) => this.onClassroomClick(event, item.custom_group_id)}
                              control={
                                <Checkbox />
                              }
                              label={<div><Typography style={{ fontSize: 11 }}>{item.name}</Typography><Typography style={{ fontSize: 11, fontWeight: 'bold' }}>{item.invite_token}</Typography></div>}
                            />

                            <IconButton style={{ position: 'relative', right: 0 }} onClick={(event) => this.onClassRoomEditClick(item)} >
                              <FontAwesomeIcon style={{ color: '#25D192', fontSize: 14 }} icon={faPencilAlt} />
                            </IconButton>
                          </div>
                        )

                      })}

                    </FormGroup>

                  </FormControl>
                </AccordionDetails>
              </Accordion>

            </Grid>

            <Grid item xs={10}>
              {this.props.selectedLanguage === "esp" ?
                <Tabs value={this.state.value}
                  onChange={this.handleChange}>
                  <Tab label="PRESENTE" />
                  <Tab label="RETIRADA" />
                  <Tab label="PETICIONES" />
                  <Tab label="TRANSFER" />
                  <Tab label="TRANSFER REQUESTS" />
                </Tabs>
                :
                <Tabs value={this.state.value}
                  onChange={this.handleChange}>
                  <Tab label="CURRENT" />
                  <Tab label="WITHDRAWN" />
                  <Tab label="REQUESTS" />
                  <Tab label="TRANSFER" />
                  <Tab label="TRANSFER REQUESTS" />
                </Tabs>
              }

              {(this.props.userRoleId < 8) && this.state.value === 0 ?
                <>
                  <Button variant="contained" color="primary"
                    onClick={() => this.onInviteClick()}
                    style={{ position: 'absolute', top: 170, right: 30, borderRadius: 4 }}>
                    <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>{i18n.t("STUDENTS_GENERATE_INVITE")}</Typography>
                  </Button>

                  {this.state.studentList.length > 0 ?
                    <Button variant="contained" color="primary"
                      style={{ position: 'absolute', top: 170, right: 467, borderRadius: 4 }}
                      onClick={() => this.onPromoteClick()}>
                      <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>Promote</Typography>
                    </Button>
                    :
                    null
                  }

                  {this.state.studentList.length > 0 ?
                    <Button variant="contained" color="primary"
                      style={{ position: 'absolute', top: 170, right: 175, borderRadius: 4 }}
                      onClick={() => this.openAddToClassroomDialog()}>
                      <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>Add To Classroom</Typography>
                    </Button>
                    :
                    null
                  }

                  {this.state.studentList.length > 0 ?
                    <Button variant="contained" color="primary"
                      style={{ position: 'absolute', top: 170, right: 343, borderRadius: 4 }}
                      onClick={() => this.onPrintProfileClick()}>
                      <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>Print Profile</Typography>
                    </Button>
                    :
                    null
                  }
                </>
                : null}

              {(this.props.userRoleId < 8) && this.state.value === 2 ?
                <>

                  <Button variant="contained" color="primary"
                    onClick={() => this.onAcceptAll()}
                    style={{ position: 'absolute', top: 170, right: 115, borderRadius: 4 }}>
                    <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>{i18n.t("STUDENTS_ACCEPT_BUTTON")}</Typography>
                  </Button>

                  <Button variant="contained" color="secondary"
                    onClick={() => this.onRejectAll()}
                    style={{ position: 'absolute', top: 170, right: 30, borderRadius: 4 }}>
                    <Typography style={{ color: '#FFF', fontSize: 14, fontWeight: 'bold' }}>{i18n.t("STUDENTS_REJECT_BUTTON")}</Typography>
                  </Button>
                </>
                : null}

              <Grid container style={{ overflow: 'auto' }}>

                {this.state.value === 0 &&

                  <Grid item xs={12}>

                    <Paper square style={{ marginLeft: 20, marginRight: 20, marginTop: 20, borderRadius: 0 }}>
                      <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox" className={classes.head} style={{ zIndex: 1 }} >
                                <Checkbox onClick={() => this.onSelectAllStudents()} checked={this.state.selectAllStudents} />
                              </TableCell>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}>
                              </TableCell>
                              <TableCell className={classes.head}>
                                <Grid container style={{ width: 200, zIndex: 1 }}>
                                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("first_name")}>
                                    {this.state.sortBy === "first_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10, width: 60 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy === "first_name" ? (
                                      this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                                    ) : null}
                                  </div>
                                  {this.state.sortBy === "first_name" ?
                                    <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                                    : (this.state.sortBy === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                                      : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                                  }
                                  <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("last_name")}>
                                    {this.state.sortBy === "last_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy === "last_name" ? (
                                      this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                                    ) : null}
                                  </div>
                                </Grid>
                              </TableCell>
                              <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("grade_name")}>
                                  {this.state.sortBy === "grade_name" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy === "grade_name" ? (
                                    this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("email_address")}>
                                  {this.state.sortBy === "email_address" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>}
                                  {this.state.sortBy === "email_address" ? (
                                    this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("school_name")}>
                                  {this.state.sortBy === "school_name" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy === "school_name" ? (
                                    this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {this.state.dataSource.map((row, index) => {

                              return (
                                <TableRow style={{ cursor: 'pointer' }} hover key={index}>
                                  <TableCell padding="checkbox">
                                    <Checkbox onClick={(event) => this.onPersonClickAddToBatch(event, row)}
                                      checked={this.state.batchToAdd['student_' + row.person_id] === 1 ? true : false} />
                                  </TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 25 }}>
                                    {row.logo_image_url === "" ?
                                      <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center' }}
                                        src={require('../../assets/default_avatar.png')} />
                                      :
                                      <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center' }}
                                        src={row.logo_image_url} />
                                    }
                                  </TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 150 }}><Typography style={{ fontSize: 12 }}>{row.first_name} {row.last_name}</Typography></TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 100 }}><Typography style={{ fontSize: 12 }}>{row.grade_name}</Typography></TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 50 }}><Typography style={{ fontSize: 12 }}>{row.email_address}</Typography></TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.school_name}</Typography></TableCell>
                                  <TableCell>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                      <IconButton onClick={event => this.onStudentEditOpen(event, row)} >
                                        <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPencilAlt} />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}



                          </TableBody>
                        </Table>

                      </div>

                      <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        rowsPerPage={this.state.perPage}
                        page={this.state.pageNumber}
                        count={numberOfStudents}
                        backIconButtonProps={{
                          'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                          'aria-label': 'Next Page',
                        }}
                        onPageChange={this.onPageClick}
                      />

                    </Paper>
                  </Grid>

                }


                {this.state.value === 1 &&

                  <Grid item xs={12}>

                    <Paper square style={{ marginLeft: 20, marginRight: 20, marginTop: 20, borderRadius: 0 }}>
                      <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}>
                              </TableCell>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}>
                              </TableCell>
                              <TableCell className={classes.head}>
                                <Grid container style={{ width: 200, zIndex: 1 }}>
                                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("first_name")}>
                                    {this.state.sortBy === "first_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10, width: 60 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy === "first_name" ? (
                                      this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                                    ) : null}
                                  </div>
                                  {this.state.sortBy === "first_name" ?
                                    <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                                    : (this.state.sortBy === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                                      : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                                  }
                                  <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("last_name")}>
                                    {this.state.sortBy === "last_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy === "last_name" ? (
                                      this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                                    ) : null}
                                  </div>
                                </Grid>
                              </TableCell>
                              <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("grade_name")}>
                                  {this.state.sortBy === "grade_name" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy === "grade_name" ? (
                                    this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("email_address")}>
                                  {this.state.sortBy === "email_address" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>}
                                  {this.state.sortBy === "email_address" ? (
                                    this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("school_name")}>
                                  {this.state.sortBy === "school_name" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy === "school_name" ? (
                                    this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {this.state.withdrawnStudents.map((row, index) => {

                              return (
                                <TableRow style={{ cursor: 'pointer' }} hover key={index}>
                                  <TableCell padding="checkbox">
                                    <Checkbox onClick={(event) => this.onPersonClickAddToBatch(event, row)}
                                      checked={this.state.batchToAdd['student_' + row.person_id] === 1 ? true : false} />
                                  </TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 25 }}>
                                    {row.logo_image_url === "" ?
                                      <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center' }}
                                        src={require('../../assets/default_avatar.png')} />
                                      :
                                      <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center' }}
                                        src={row.logo_image_url} />
                                    }
                                  </TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 150 }}><Typography style={{ fontSize: 12 }}>{row.first_name} {row.last_name}</Typography></TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 100 }}><Typography style={{ fontSize: 12 }}>{row.grade_name}</Typography></TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 50 }}><Typography style={{ fontSize: 12 }}>{row.email_address}</Typography></TableCell>
                                  <TableCell onClick={event => this.onRowClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.school_name}</Typography></TableCell>
                                  <TableCell>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                      <IconButton onClick={event => this.onStudentEditOpen(event, row)} >
                                        <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPencilAlt} />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}



                          </TableBody>
                        </Table>

                      </div>

                      <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        rowsPerPage={this.state.perPage}
                        page={this.state.pageNumber}
                        count={numberOfWidthdrawn}
                        backIconButtonProps={{
                          'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                          'aria-label': 'Next Page',
                        }}
                        onPageChange={this.onPageClick}
                      />

                    </Paper>
                  </Grid>

                }


                {this.state.value === 2 &&


                  <Grid item xs={12}>

                    <Paper square style={{ marginLeft: 20, marginRight: 20, marginTop: 20, borderRadius: 0 }}>
                      <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox" className={classes.head} style={{ zIndex: 1 }} >
                                <Checkbox onClick={() => this.onSelectAll()} checked={this.state.selectAll} />
                              </TableCell>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                              <TableCell className={classes.head}>
                                <Grid container style={{ width: 200, zIndex: 1 }}>
                                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("first_name")}>
                                    {this.state.sortBy2 === "first_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10, width: 60 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy2 === "first_name" ? (
                                      this.state.sortDirection2 === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                                    ) : null}
                                  </div>
                                  {this.state.sortBy2 === "first_name" ?
                                    <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                                    : (this.state.sortBy2 === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                                      : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                                  }
                                  <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("last_name")}>
                                    {this.state.sortBy2 === "last_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy2 === "last_name" ? (
                                      this.state.sortDirection2 === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                                    ) : null}
                                  </div>
                                </Grid>
                              </TableCell>
                              <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("grade_name")}>
                                  {this.state.sortBy2 === "grade_name" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy2 === "grade_name" ? (
                                    this.state.sortDirection2 === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell style={{ zIndex: 1, }} className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("email_address")}>
                                  {this.state.sortBy2 === "email_address" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy2 === "email_address" ? (
                                    this.state.sortDirection2 === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}>&nbsp;</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {orderBy(this.state.filteredRequests, this.state.sortBy2, this.state.sortDirection2)
                              .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                              .map((row, index) => {

                                return (
                                  <TableRow style={{ cursor: 'pointer' }} hover
                                    key={index}>
                                    <TableCell padding="checkbox">
                                      <Checkbox onClick={() => this.onPersonClick(row.person_id)}
                                        checked={this.state.choices['choice_' + row.person_id] === 1 ? true : false} />
                                    </TableCell>
                                    <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 5 }}>
                                      {row.logo_image_url === "" ?
                                        <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center', zIndex: 0 }}
                                          src={require('../../assets/default_avatar.png')} />
                                        :
                                        <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center', zIndex: 0 }}
                                          src={row.logo_image_url} />
                                      }
                                    </TableCell>
                                    <TableCell onClick={event => this.onRowClick(event, row)} ><Typography style={{ fontSize: 12 }}>{row.first_name} {row.last_name}</Typography></TableCell>
                                    <TableCell onClick={event => this.onRowClick(event, row)} ><Typography style={{ fontSize: 12 }}>{row.grade_name}</Typography></TableCell>
                                    <TableCell onClick={event => this.onRowClick(event, row)} ><Typography style={{ fontSize: 12 }}>{row.email_address}</Typography></TableCell>
                                    <TableCell>
                                      <Grid container>
                                        <Grid item xs={6}>
                                          <Button fullWidth variant="contained" color="primary"
                                            onClick={() => this.addStudent(row)}
                                            style={{ borderRadius: 4 }}>
                                            <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>{i18n.t("STUDENTS_ACCEPT_BUTTON")}</Typography>
                                          </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Button fullWidth variant="contained" color="secondary"
                                            onClick={() => this.removeStudent(row)}
                                            style={{ borderRadius: 4, marginLeft: 10 }}>
                                            <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>{i18n.t("STUDENTS_REJECT_BUTTON")}</Typography>
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>

                                );
                              })}

                          </TableBody>
                        </Table>

                      </div>

                      <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.currentPage}
                        count={this.state.filteredRequests.length}
                        backIconButtonProps={{
                          'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                          'aria-label': 'Next Page',
                        }}
                        onPageChange={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />

                    </Paper>
                  </Grid>
                }

                {this.state.value === 3 &&

                  <Grid item xs={12}>

                    <Paper square style={{ marginLeft: 20, marginRight: 20, marginTop: 20, borderRadius: 0 }}>
                      <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}>
                              </TableCell>
                              <TableCell className={classes.head}>
                                <Grid container style={{ width: 200, zIndex: 1 }}>
                                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("first_name")}>
                                    {this.state.sortBy === "first_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10, width: 60 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy === "first_name" ? (
                                      this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                                    ) : null}
                                  </div>
                                  {this.state.sortBy === "first_name" ?
                                    <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                                    : (this.state.sortBy === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                                      : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                                  }
                                  <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("last_name")}>
                                    {this.state.sortBy === "last_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy === "last_name" ? (
                                      this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                                    ) : null}
                                  </div>
                                </Grid>
                              </TableCell>
                              <TableCell className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("grade_name")}>
                                  {this.state.sortBy === "grade_name" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy === "grade_name" ? (
                                    this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("email_address")}>
                                  {this.state.sortBy === "email_address" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>}
                                  {this.state.sortBy === "email_address" ? (
                                    this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.onSortClick("school_name")}>
                                  {this.state.sortBy === "school_name" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy === "school_name" ? (
                                    this.state.sortDirection === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {this.state.allStudents.map((row, index) => {

                              return (
                                <TableRow style={{ cursor: 'pointer' }} hover
                                  key={index}>
                                  <TableCell style={{ width: 25 }}>
                                    {row.logo_image_url === "" ?
                                      <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center' }}
                                        src={require('../../assets/default_avatar.png')} />
                                      :
                                      <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center' }}
                                        src={row.logo_image_url} />
                                    }
                                  </TableCell>
                                  <TableCell style={{ width: 150 }}><Typography style={{ fontSize: 12 }}>{row.first_name} {row.last_name}</Typography></TableCell>
                                  <TableCell style={{ width: 100 }}><Typography style={{ fontSize: 12 }}>{row.grade_name}</Typography></TableCell>
                                  <TableCell style={{ width: 50 }}><Typography style={{ fontSize: 12 }}>{row.email_address}</Typography></TableCell>
                                  <TableCell><Typography style={{ fontSize: 12 }}>{row.school_name}</Typography></TableCell>
                                  <TableCell >
                                    <Grid container>
                                      <Grid item xs={6}>
                                        <Button style={{ padding: 12, borderRadius: 4 }} variant="contained" color="primary"
                                          onClick={() => this.requestTransfer(row)}>
                                          <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>Request Transfer</Typography>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              );
                            })}



                          </TableBody>
                        </Table>

                      </div>

                      <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        rowsPerPage={this.state.perPage}
                        page={this.state.pageNumber}
                        count={this.state.allStudentRows}
                        backIconButtonProps={{
                          'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                          'aria-label': 'Next Page',
                        }}
                        onPageChange={this.onPageClick}
                      />

                    </Paper>
                  </Grid>

                }

                {this.state.value === 4 &&

                  <Grid item xs={12}>

                    <Paper square style={{ marginLeft: 20, marginRight: 20, marginTop: 20, borderRadius: 0 }}>
                      <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox" className={classes.head} style={{ zIndex: 1 }}>
                                <Checkbox onClick={() => this.onSelectAll()} checked={this.state.selectAll} />
                              </TableCell>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}></TableCell>
                              <TableCell className={classes.head}>
                                <Grid container style={{ width: 200, zIndex: 1 }}>
                                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("first_name")}>
                                    {this.state.sortBy2 === "first_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold', width: 63 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10, width: 60 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_FIRST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy2 === "first_name" ? (
                                      this.state.sortDirection2 === 'asc' ? <div style={{ height: 15, width: 10 }}> <UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /></div>
                                    ) : null}
                                  </div>
                                  {this.state.sortBy2 === "first_name" ?
                                    <Typography style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>/</Typography>
                                    : (this.state.sortBy2 === "last_name" ? <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>
                                      : <Typography style={{ marginTop: 10, marginLeft: 5, marginRight: 5 }}>/</Typography>)
                                  }
                                  <div style={{ display: 'flex', width: 90, alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("last_name")}>
                                    {this.state.sortBy2 === "last_name" ? (
                                      <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    ) :
                                      <div style={{ marginTop: 10 }}>
                                        <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_LAST_NAME")}</Typography>
                                      </div>
                                    }
                                    {this.state.sortBy2 === "last_name" ? (
                                      this.state.sortDirection2 === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /> </div> : <div style={{ height: 15, width: 10 }}> <DownArrow /> </div>
                                    ) : null}
                                  </div>
                                </Grid>
                              </TableCell>
                              <TableCell className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("grade_name")}>
                                  {this.state.sortBy2 === "grade_name" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_GRADES")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy2 === "grade_name" ? (
                                    this.state.sortDirection2 === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("email_address")}>
                                  {this.state.sortBy2 === "email_address" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_EMAIL_ADDRESS")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy2 === "email_address" ? (
                                    this.state.sortDirection2 === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell className={classes.head}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleSort("school_name")}>
                                  {this.state.sortBy2 === "school_name" ? (
                                    <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                    </div>
                                  ) :
                                    <div style={{ marginTop: 10 }}>
                                      <Typography style={{ fontSize: 12 }}>{i18n.t("STUDENTS_TABLE_SCHOOL_COLUMN")}</Typography>
                                    </div>
                                  }
                                  {this.state.sortBy2 === "school_name" ? (
                                    this.state.sortDirection2 === 'asc' ? <div style={{ height: 15, width: 10 }}><UpArrow /></div> : <div style={{ height: 15, width: 10 }}><DownArrow /></div>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell className={classes.head} style={{ zIndex: 1 }}>&nbsp;</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {this.state.allTransferRequests
                              .map((row, index) => {

                                return (
                                  <TableRow style={{ cursor: 'pointer' }} hover
                                    key={index}>
                                    <TableCell padding="checkbox">
                                      <Checkbox onClick={() => this.onPersonClick(row.person_id)}
                                        checked={this.state.choices['choice_' + row.person_id] === 1 ? true : false} />
                                    </TableCell>
                                    <TableCell onClick={event => this.onRowClick(event, row)} style={{ width: 5 }}>
                                      {row.logo_image_url === "" ?
                                        <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center', zIndex: 0 }}
                                          src={require('../../assets/default_avatar.png')} />
                                        :
                                        <Avatar style={{ height: 50, width: 50, cursor: 'pointer', alignSelf: 'center', zIndex: 0 }}
                                          src={row.logo_image_url} />
                                      }
                                    </TableCell>
                                    <TableCell onClick={event => this.onRowClick(event, row)} ><Typography style={{ fontSize: 12 }}>{row.first_name} {row.last_name}</Typography></TableCell>
                                    <TableCell onClick={event => this.onRowClick(event, row)} ><Typography style={{ fontSize: 12 }}>{row.grade_name}</Typography></TableCell>
                                    <TableCell onClick={event => this.onRowClick(event, row)} ><Typography style={{ fontSize: 12 }}>{row.email_address}</Typography></TableCell>
                                    <TableCell onClick={event => this.onRowClick(event, row)} ><Typography style={{ fontSize: 12 }}>{row.school_name}</Typography></TableCell>
                                    <TableCell>
                                      <Grid container>
                                        <Grid item xs={6}>
                                          <Button fullWidth variant="contained" color="primary"
                                            onClick={() => this.onEditPersonsDistrict(row)}
                                            style={{ borderRadius: 4 }}>
                                            <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>{i18n.t("STUDENTS_ACCEPT_BUTTON")}</Typography>
                                          </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Button fullWidth variant="contained" color="secondary"
                                            onClick={() => this.onDeleteTransferRequests(row)}
                                            style={{ borderRadius: 4, marginLeft: 10 }}>
                                            <Typography style={{ color: '#FFF', fontSize: 12, fontWeight: 'bold' }}>{i18n.t("STUDENTS_REJECT_BUTTON")}</Typography>
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>

                                );
                              })}

                          </TableBody>
                        </Table>

                      </div>

                      <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.currentPage}
                        count={this.state.filteredRequests.length}
                        backIconButtonProps={{
                          'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                          'aria-label': 'Next Page',
                        }}
                        onPageChange={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />

                    </Paper>
                  </Grid>

                }

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

StudentScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage,
    districtId: state.profile.districtId,
    schoolId: state.profile.schoolId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetProfile: (profileId, firstName, lastName, gradeId, gradeName, districtId, districtName, schoolId, schoolName, profileRoleId, earlyGraduationProgram) => { dispatch(setProfile(profileId, firstName, lastName, gradeId, gradeName, districtId, districtName, schoolId, schoolName, profileRoleId, earlyGraduationProgram)); },
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}
export default (withTranslation("translate"))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StudentScreen)));