import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { parseInt } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import { setTask } from '../../../redux/actions/task';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class TaskBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAnswerOpen: false,
      selectedYear: '',
      years: [],
      schoolYear: ''

    }

    this.onAnswerClose = this.onAnswerClose.bind(this);
  }

  componentDidMount() {
    var schoolYear = ''

    fetch('https://api.tasselpathway.com/dashboard/years', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.authenticated) {
          for (var i = 0; i < responseJson.years.length; i++) {
            if (responseJson.years[i].year_value === parseInt(responseJson.selected_year)) {
              schoolYear = responseJson.years[i].year_name
            }
          }
          this.setState({
            isLoading: false,
            years: responseJson.years,
            selectedYear: responseJson.selected_year,
            schoolYear: schoolYear
          });
          this.getData();
        }
      })
      .catch((error) => {
      });
  }

  onChangeAnswer(event) {

    this.props.onSetTask(
      this.props.taskId,
      this.props.taskName,
      this.props.taskDescription,
      this.props.estimatedTime,
      this.props.totalPoints,
      this.props.autoGrade,
      event.target.value,
      this.props.taskUpdated);
  }

  onAnswerClick() {
    this.setState({
      isAnswerOpen: true
    })
  }

  onAnswerClose() {
    this.setState({
      isAnswerOpen: false
    })
  }

  onCompleteClick() {
    const {
      schoolYear
    } = this.state;

    fetch('https://api.tasselpathway.com/profile_tasks', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        person_id: this.props.personId,
        grade_id: this.props.gradeId,
        task_id: this.props.taskId,
        status_id: 3,
        answer_text: this.props.answerText,
        school_year: schoolYear
      })
    })
      .then((response) => response.json())
      .then(responseJson => {

        this.setState({
          isAnswerOpen: false
        })

        this.props.onSetTask(0, '', '', '', 0, 0, '', 1);

      }).catch((error) => {
        this.setState({
          isAnswerOpen: false
        })
      });
  }

  onSubmitClick() {
    const {
      schoolYear
    } = this.state;

    fetch('https://api.tasselpathway.com/profile_tasks', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',

      body: JSON.stringify({
        person_id: this.props.personId,
        grade_id: this.props.gradeId,
        task_id: this.props.taskId,
        status_id: 1,
        answer_text: this.props.answerText,
        school_year: schoolYear
      })
    })
      .then((response) => response.json())
      .then(responseJson => {
        this.setState({
          isAnswerOpen: false
        })

        this.props.onSetTask(0, '', '', '', 0, 0, '', 1);

        window.location.reload();

      }).catch((error) => {
        this.setState({
          isAnswerOpen: false
        })
      });
  }

  render() {

    return (

      <div>

        <Dialog onClose={this.onAnswerClose} fullWidth
          aria-labelledby="customized-dialog-title"
          open={this.state.isAnswerOpen}>
          <DialogTitle id='dialog-modal-title'
            onClose={this.onAnswerClose}>
            <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
              {this.props.taskName}
            </Typography>
          </DialogTitle>
          <DialogContent>

            <Typography style={{ marginBottom: 10 }}>{this.props.taskDescription}</Typography>

            <FormControl fullWidth>
              <InputLabel htmlFor="skillName">Answer</InputLabel>
              <Input variant="outline" value={this.props.answerText}
                fullWidth multiline rows={6} autoFocus
                onChange={(event) => this.onChangeAnswer(event)}
                id="answer" aria-describedby="my-helper-text" />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ borderRadius: 0, marginRight: 20 }}
              onClick={() => this.onSubmitClick()} color="secondary">
              <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>Submit Answer</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container style={{ backgroundColor: '#777', height: 60, position: 'fixed' }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <Typography style={{ fontSize: 14, fontWeight: 'bold', marginTop: 15, color: '#FFF' }}>Task: {this.props.taskName}</Typography>
          </Grid>
          <Grid item xs={7} style={{ verticalAlign: 'middle' }}>
            <Typography style={{ fontSize: 11, fontWeight: 'bold', paddingTop: 5, color: '#FFF' }}>{this.props.taskDescription}</Typography>
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right', paddingTop: 12 }}>
            {this.props.autoGrade === 1 ?

              <Button variant="contained" color="primary" onClick={() => this.onCompleteClick()}
                style={{ borderRadius: 0 }}>
                <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Complete</Typography>
              </Button>

              :
              <Button variant="contained" color="primary" onClick={() => this.onAnswerClick()}
                style={{ borderRadius: 0 }}>
                <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>Answer</Typography>
              </Button>
            }
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>

      </div>
    )
  }
}
TaskBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    password: state.auth.password,
    personId: state.auth.personId,
    gradeId: state.auth.gradeId,
    token: state.auth.token,
    taskId: state.task.taskId,
    taskName: state.task.taskName,
    taskDescription: state.task.taskDescription,
    estimatedTime: state.task.estimatedTime,
    totalPoints: state.task.totalPoints,
    autoGrade: state.task.autoGrade,
    answerText: state.task.answerText,
    taskUpdated: state.task.taskUpdated
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetTask: (taskId, taskName, taskDescription, estimatedTime, totalPoints, autoGrade, answerText, taskUpdated) => { dispatch(setTask(taskId, taskName, taskDescription, estimatedTime, totalPoints, autoGrade, answerText, taskUpdated)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskBar));