import { CssBaseline, LinearProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PropTypes from 'prop-types';
import React from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import HomeScreen from "./screens/home/index";
import ReportScreen from "./screens/report/index";
import ScoreScreen from "./screens/score/index";

import CleverScreen from "./screens/sso/clever/index";

import Report1Screen from "./screens/report/report_1/index";
import Report2Screen from "./screens/report/report_2/index";
import Report3Screen from "./screens/report/report_3/index";
import Report4Screen from "./screens/report/report_4/index";
import Report5Screen from "./screens/report/report_5/index";
import Report6Screen from "./screens/report/report_6/index";
import Report7Screen from "./screens/report/report_7/index";
import Report8Screen from "./screens/report/report_8/index";

import AdminScreen from "./screens/admin/index";
import ProfileScreen from "./screens/profile/index";
import TaskScreen from "./screens/task/index";
import EditTestScreen from "./screens/test/edit/index";
import TestScreen from "./screens/test/index";

import ConnectionScreen from "./screens/connection/index";
import ExtracurricularScreen from "./screens/profile/extracurricular/index";
import CareerScreen from "./screens/research/career/index";
import CareerListScreen from "./screens/research/careers/index";
import CollegeScreen from "./screens/research/college/index";
import CollegeListScreen from "./screens/research/colleges/index";
import EmployerScreen from "./screens/research/employer/index";
import EmployerListScreen from "./screens/research/employers/index";
import ResearchScreen from "./screens/research/index";
import MajorScreen from "./screens/research/major/index";
import MajorListScreen from "./screens/research/majors/index";
import StudentScreen from "./screens/student/index";

import CreateAnnouncementOrAlertScreen from './screens/admin/communications/createAnnouncementOrAlert/index';
import CreateMessageScreen from './screens/admin/communications/createMessage/index';
import ManageMessageScreen from './screens/admin/communications/manageMessages/index';
import ForgotScreen from './screens/forgot/index';
import LoginScreen from './screens/login/index';
import RegisterScreen from './screens/register/index';
import ResetScreen from './screens/reset/index';
import HeaderBar from './screens/shared/headerbar/index';
import TaskBar from './screens/shared/taskbar/index';
import NewUserScreen from './screens/user/new/index';

import ResumeCustomizationScreen from './screens/profile/resume_builder/customize/index';
import ResumeDownloadScreen from './screens/profile/resume_builder/download/index';
import ResumeObjectiveScreen from './screens/profile/resume_builder/objective/index';
import ResumeRequirementScreen from './screens/profile/resume_builder/requirements/index';

import { logout } from './redux/actions/auth';

const clientId = '710299978698-h37id8aivohbrq8upu68sqvsac9vf1hv.apps.googleusercontent.com';

let theme = createTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#78E5C9',
      main: '#25D192',
      dark: '#4D4D50',
    },
    secondary: {
      main: '#2A2042',
      dark: '#4D4D50'
    },
  },
  shape: {
    borderRadius: 8,
  }
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#1F3545',
        borderRadius: 4
      },
    },
    MuiButton: {
      root: {
        borderRadius: 4,
      },
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 4
      }
    },
    MuiAccordion: {
      root: {
        square: true
      },
      rounded: {
        borderRadius: 0
      }
    },
    MuiPaper: {
      root: {
        square: true,
        borderRadius: 0
      },
      rounded: {
        borderRadius: 4
      }
    },
    MuiMenuItem: {
      root: {
        background: 'transparent',
        '&$selected': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(0),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: '#2A2042',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      },
      labelContainer: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
      }
    },
    MuiLinearProgress: {

      determinate: {
        light: '#25D192',
        main: '#25D192',
        dark: '#51B936',
      }
    },
    MuiInput: {
      root: {

        '&$disabled': {
          color: '#000'
        }
      }
    },
    MuiRadio: {
      root: {
        '&$disabled': {
          color: '#000'
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: '#000'
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1.2),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },

  },

  props: {
    MuiTab: {
      disableRipple: false,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const drawerWidth = 200;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }
};

class Application extends React.Component {

  constructor(props) {
    super(props);
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)

    this.state = {
      mobileOpen: false,
    }

  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  componentWillReceiveProps(newProps) {
    this.loadChat();
  }

  componentDidMount() {
    this.loadChat();
  }

  loadChat() {

    if (this.props.userRoleId < 8 && this.props.userRoleId !== 0) {

      const script = document.createElement("script");
      script.type = 'text/javascript';

      script.innerHTML = `
            var Tawk_API=Tawk_API||{};
            Tawk_API.visitor = {
              name : '` + this.props.firstName + ` ` + this.props.lastName + `',
              email : '` + this.props.username + `'
              };
            Tawk_LoadStart=new Date();
            (function(){
              var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
              s1.async=true;
              s1.src='https://embed.tawk.to/5d265d287a48df6da243f1cb/default';
              s1.charset='UTF-8';
              s1.setAttribute('crossorigin','*');
              s0.parentNode.insertBefore(s1,s0);
            })
          ();`;

      script.async = true;
      document.body.appendChild(script);
    }
  }

  _onIdle(e) {
    this.props.onLogout();
    localStorage.clear();
  }

  render() {
    const { classes } = this.props;

    if (this.props.isLoggedIn && this.props.userRoleId === 8) {
      return (

        <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onIdle={this.onIdle}
            debounce={250}
            timeout={1000 * 60 * 15} />
          <BrowserRouter>

            <ThemeProvider theme={theme}>
              <div>
                <LinearProgress variant="determinate" style={{ color: '#FEFF79' }} value={this.props.progress} />
              </div>

              <HeaderBar props={this.props} />

              <div className={classes.root}>

                <CssBaseline />

                <div className={classes.appContent} style={{ backgroundColor: '#eaeaea', marginTop: 60 }}>

                  {this.props.taskId === 0 ? null :
                    <>
                      <TaskBar props={this.props} />
                      <div style={{ marginTop: 50 }}></div>
                    </>
                  }

                  <Switch>
                    <Route exact path="/" component={HomeScreen} />
                    <Route path="/profile/:profileId" component={ProfileScreen} />
                    <Route path="/connections" component={ConnectionScreen} />
                    <Route path="/score" component={ScoreScreen} />
                    <Route path="/research" component={ResearchScreen} />
                    <Route path="/career/:careerId" component={CareerScreen} />
                    <Route path="/careers" component={CareerListScreen} />
                    <Route path="/tasks" component={TaskScreen} />
                    <Route path="/college/:collegeId" component={CollegeScreen} />
                    <Route path="/colleges" component={CollegeListScreen} />
                    <Route path="/major/:majorId" component={MajorScreen} />
                    <Route path="/majors" component={MajorListScreen} />
                    <Route path="/employer/:employerId" component={EmployerScreen} />
                    <Route path="/group/:groupId" component={ExtracurricularScreen} />
                    <Route path="/employers" component={EmployerListScreen} />
                    <Route path='/resume_builder_objectives' component={ResumeObjectiveScreen} />
                    <Route path='/resume_builder_requirements' component={ResumeRequirementScreen} />
                    <Route path='/resume_builder_customize' component={ResumeCustomizationScreen} />
                    <Route path='/resume_builder_download' component={ResumeDownloadScreen} />
                  </Switch>
                </div>
              </div>
            </ThemeProvider>
          </BrowserRouter>
        </div>
      );
    } else if (this.props.isLoggedIn && this.props.userRoleId < 8) {
      return (

        <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 15} />
          <BrowserRouter>

            <ThemeProvider theme={theme}>
              <div>
                <LinearProgress variant="determinate" style={{ color: '#FEFF79' }} value={this.props.progress} />
              </div>
              <HeaderBar props={this.props} />

              <div className={classes.root}>

                <CssBaseline />

                <div className={classes.appContent} style={{ backgroundColor: '#eaeaea', marginTop: 50 }}>

                  <Switch>
                    <Route exact path="/" component={HomeScreen} />
                    <Route path="/report" component={ReportScreen} />

                    <Route path="/report1" component={Report1Screen} />
                    <Route path="/report2" component={Report2Screen} />
                    <Route path="/report3" component={Report3Screen} />
                    <Route path="/report4" component={Report4Screen} />
                    <Route path="/report5" component={Report5Screen} />
                    <Route path="/report6" component={Report6Screen} />
                    <Route path="/report7" component={Report7Screen} />
                    <Route path="/report8" component={Report8Screen} />

                    <Route path="/admin" component={AdminScreen} />
                    <Route path="/profile/:profileId" component={ProfileScreen} />
                    <Route path="/students" component={StudentScreen} />
                    <Route path="/reports" component={ReportScreen} />
                    <Route path="/score" component={ScoreScreen} />
                    <Route path="/research" component={ResearchScreen} />
                    <Route path="/career/:careerId" component={CareerScreen} />
                    <Route path="/careers" component={CareerListScreen} />
                    <Route path="/major/:majorId" component={MajorScreen} />
                    <Route path="/majors" component={MajorListScreen} />
                    <Route path="/tasks" component={TaskScreen} />
                    <Route path="/tests" component={TestScreen} />
                    <Route path="/edit_test/:testId" component={EditTestScreen} />
                    <Route path="/college/:collegeId" component={CollegeScreen} />
                    <Route path="/colleges" component={CollegeListScreen} />
                    <Route path="/employer/:employerId" component={EmployerScreen} />
                    <Route path="/employers" component={EmployerListScreen} />
                    <Route path="/group/:groupId" component={ExtracurricularScreen} />
                    <Route path="/createMessage" component={CreateMessageScreen} />
                    <Route path="/createAnnouncementOrAlertScreen" component={CreateAnnouncementOrAlertScreen} />
                    <Route path='/manageMessages' component={ManageMessageScreen} />
                  </Switch>
                </div>

              </div>
            </ThemeProvider>
          </BrowserRouter>
        </div>
      );
    } else {
      return (
        <div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 15} />
          <GoogleOAuthProvider clientId={clientId}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <div className={classes.root}>
                  <Switch>
                    <Route exact path="/" component={LoginScreen} />
                    <Route path="/reset/:token" component={ResetScreen} />
                    <Route path="/forgot" component={ForgotScreen} />
                    <Route path="/register" component={RegisterScreen} />
                    <Route path="/new_user/:token" component={NewUserScreen} />
                    <Route path="/clever" component={CleverScreen} />
                    <Route component={LoginScreen} />
                  </Switch>
                </div>
              </ThemeProvider>
            </BrowserRouter>
          </GoogleOAuthProvider>
        </div>
      )
    }
  }
}

Application.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    progress: state.progress.progress,
    isLoggedIn: state.auth.isLoggedIn,
    userRoleId: state.auth.userRoleId,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    username: state.auth.username,
    taskId: state.task.taskId,
    announcementAlert: state.alert.announcementAlert,
    urgentAlert: state.alert.urgentAlert
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Application));