import {
    Button, Dialog, DialogActions, DialogContent,
    FormControl,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell, TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';
const invertSort = {
    asc: "desc",
    desc: "asc"
}

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class PathwayScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isNewOpen: false,
            isCreateOpen: false,
            isEditOpen: false,
            diplomas: [],
            dataSource: [],
            filteredDataSource: [],
            createPathwayName: '',
            selectedItem: {},
            height: 400,
            rowsPerPage: 25,
            currentPage: 0,
            sortBy: '',
            sortDirection: 'desc',
            pathways: []
        }

        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }

    updateDimensions() {
        let update_height = 0;

        if (this.props.announcementAlert !== '') {
            update_height = window.innerHeight - 350;
        } else {
            update_height = window.innerHeight - 260;
        }

        this.setState({ height: update_height });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentDidMount() {

        window.addEventListener("resize", this.updateDimensions.bind(this));

        this.setState({
            isLoading: true
        })

        this.getData();
        this.updateDimensions();
    }

    getData() {
        fetch('https://api.tasselpathway.com/pathway_district', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        dataSource: responseJson.results,
                        filteredDataSource: responseJson.results
                    })
                }
            })

        fetch('https://api.tasselpathway.com/pathway', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        pathways: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    onCreatePathway() {
        if (this.state.createPathwayName !== '') {

            fetch('https://api.tasselpathway.com/pathway', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': this.props.token
                },
                body: JSON.stringify({
                    pathway_name: this.state.createPathwayName
                }),
                credentials: 'omit'
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    this.setState({
                        isLoading: false,
                        isCreateOpen: false
                    })

                    this.getData();

                })
                .catch((error) => {
                    this.setState({
                        isLoading: false,
                        isCreateOpen: false
                    })
                });
        }
    }

    onAddPathway() {

        this.setState({
            isLoading: true
        })

        fetch('https://api.tasselpathway.com/pathway_district', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                pathway_id: this.state.selectedItem.pathway_id,
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    isNewOpen: false
                })

                this.getData();

            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    isNewOpen: false
                })
            });
    }

    onSavePathway() {

        this.setState({
            isLoading: true
        })

        fetch('https://api.tasselpathway.com/pathway_district', {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            body: JSON.stringify({
                pathway_id: this.state.selectedItem.pathway_id,
                pathway_district_id: this.state.selectedItem.pathway_district_id
            }),
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        isEditOpen: false
                    })

                    this.getData();
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    isEditOpen: false
                })
            });
    }

    onCreateClick() {
        this.setState({
            isCreateOpen: true
        })
    }

    onCreateClose = () => {
        this.setState({
            isCreateOpen: false
        })
    }

    onNewClick() {
        this.setState({
            isNewOpen: true
        })
    }

    onNewClose = () => {
        this.setState({
            isNewOpen: false
        })
    }

    onEditClick(event, item) {
        this.setState({
            isEditOpen: true,
            selectedItem: item
        })
    }

    onEditClose = () => {
        this.setState({
            isEditOpen: false
        })
    }

    handleChangePage(event, newPage) {
        this.setState({
            currentPage: newPage
        })
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: event.target.value
        })
    }

    handleSort = (col) => {
        this.setState(state => ({
            sortBy: col,
            sortDirection: state.sortBy === col ? invertSort[state.sortDirection] : 'asc'
        }));
    }

    onRowClick(event, item) {

        this.setState({
            selectedCourse: item,
            isEditCourseOpen: false
        })
    }

    onPathwaySelect(event) {

        var selectedItem = this.state.selectedItem;
        selectedItem.pathway_id = event.target.value;

        this.setState({
            selectedItem: selectedItem
        })
    }

    render() {

        const { classes } = this.props;
        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.filteredDataSource.length - this.state.currentPage * this.state.rowsPerPage);

        return (
            <div className={classes.root}>

                <Dialog open={this.state.isCreateOpen}
                    onClose={this.onCreateClose} maxWidth="sm"
                    fullWidth={true}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={this.onCreateClose}>
                        Create New Pathway
                    </DialogTitle>

                    <DialogContent>

                        <FormControl fullWidth className={classes.formControl} style={{ marginTop: 10 }}>

                            <TextField id="pathway" label="Pathway"
                                onChange={(evt) => this.setState({ createPathwayName: evt.target.value })} />
                        </FormControl>

                    </DialogContent>

                    <DialogActions>
                        <Button variant="contained" onClick={() => this.onCreatePathway()}
                            style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
                            Create
                        </Button>
                    </DialogActions>

                </Dialog>

                <Dialog open={this.state.isNewOpen}
                    onClose={this.onNewClose} maxWidth="sm"
                    fullWidth={true}
                    aria-labelledby="form-dialog-title">

                    <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
                        New Pathway
                    </DialogTitle>

                    <DialogContent>

                        <FormControl fullWidth className={classes.formControl} style={{ marginTop: 10 }}>
                            <InputLabel htmlFor="editDiplomaYear">Pathway</InputLabel>
                            <Select
                                value={this.state.selectedItem.pathway_id}
                                onChange={(event) => this.onPathwaySelect(event)}
                                inputProps={{
                                    name: 'editDiplomaYear',
                                    id: 'editDiplomaYear',
                                }}>
                                <MenuItem disabled value={0}>Select Pathway</MenuItem>
                                {this.state.pathways.map((item, key) => {
                                    return (
                                        <MenuItem value={item.pathway_id}>{item.pathway_name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                    </DialogContent>

                    <DialogActions>
                        <Grid container>
                            <Grid item xs={6}>
                                <Button variant="contained" onClick={() => this.onCreateClick()}
                                    style={{ color: '#FFF', marginLeft: 10, borderRadius: 3, fontWeight: 'bold' }} color="primary">
                                    Create New Pathway
                                </Button>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <Button variant="contained" onClick={() => this.onAddPathway()}
                                    style={{ color: '#FFF', marginRight: 10, borderRadius: 3, fontWeight: 'bold' }} color="primary">
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>

                </Dialog>

                <Dialog open={this.state.isEditOpen}
                    onClose={this.onEditClose} maxWidth="sm"
                    fullWidth={true}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={this.onEditClose}>
                        Edit Pathway
                    </DialogTitle>

                    <DialogContent>
                        <FormControl fullWidth className={classes.formControl} style={{ marginTop: 10 }}>
                            <InputLabel htmlFor="editDiplomaYear">Pathway</InputLabel>
                            <Select
                                value={this.state.selectedItem.pathway_id}
                                onChange={(event) => this.onPathwaySelect(event)}
                                inputProps={{
                                    name: 'editDiplomaYear',
                                    id: 'editDiplomaYear',
                                }}>
                                <MenuItem disabled value={0}>Select Pathway</MenuItem>
                                {this.state.pathways.map((item, key) => {
                                    return (
                                        <MenuItem value={item.pathway_id}>{item.pathway_name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                    </DialogContent>

                    <DialogActions>
                        <Button variant="contained" onClick={() => this.onSavePathway()}
                            style={{ color: '#FFF', marginRight: 20, borderRadius: 3, fontWeight: 'bold' }} color="primary">
                            {i18n.t("ADMIN_SAVE_DIPLOMA")}
                        </Button>
                    </DialogActions>

                </Dialog>

                <div>

                    <Grid container>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" style={{ fontSize: 20, marginTop: 25, borderRadius: 3 }}
                                onClick={() => this.onNewClick()}>
                                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>New Pathway</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>

                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            {this.props.selectedLanguage === "esp" ?
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        onChange={this.onSearch} style={{ width: '100%' }}
                                        placeholder="Buscar"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </div>
                                :
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        onChange={this.onSearch} style={{ width: '100%' }}
                                        placeholder="Search"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </div>
                            }
                        </Grid>
                    </Grid>

                    <Paper style={{ borderRadius: 0, marginTop: 10 }}>

                        <div className={classes.tableWrapper} style={{ maxHeight: this.state.height }}>
                            <Table className={classes.table} style={{ height: this.state.height }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.head}>
                                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                <Typography style={{ fontSize: 12 }}>Pathway Name</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderBy(this.state.filteredDataSource, this.state.sortBy, this.state.sortDirection)
                                        .slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={index} classes={classes.tableRow} hover style={{ cursor: 'pointer' }}>
                                                    <TableCell onClick={(event) => this.onEditClick(event, row)}><Typography style={{ fontSize: 12 }}>{row.pathway_name}</Typography></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            )
                                        }
                                        )}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 50 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[25]}
                            component="div"
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.currentPage}
                            count={this.state.filteredDataSource.length}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onPageChange={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>
        );
    }
}

PathwayScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        token: state.auth.token,
        userRoleId: state.auth.userRoleId,
        selectedLanguage: state.language.selectedLanguage
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setProgress: (progress) => { dispatch(setProgress(progress)); },
        onLogout: () => { dispatch(logout()); }
    }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PathwayScreen)));