import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Chip,
  Dialog, DialogContent,
  Grid, IconButton,
  LinearProgress, Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n.js';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class ILPProgress extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasPathway: 0,
      interestInventory: 0,
      learningStyle: 0,
      goals: 0,
      skills: 0,
      clubs: 0,
      honors: 0,
      hasResume: 0,
      completionPercent: 0,
      assessments: 0,
      workBasedLearning: 0,
      gradeId: 0,
      isProgressOpen: false
    }

    this.onProgressOpen = this.onProgressOpen.bind(this);
    this.onProgressClose = this.onProgressClose.bind(this);

  }

  componentDidMount() {

    fetch('https://api.tasselpathway.com/ilp/' + this.props.profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            hasPathway: responseJson.results[0].has_pathway,
            hasPathwayRequired: responseJson.results[0].has_pathway_required,
            interestInventory: responseJson.results[0].interest_inventory,
            interestInventoryRequired: responseJson.results[0].interest_inventory_required,
            learningStyle: responseJson.results[0].learning_style,
            learningStyleRequired: responseJson.results[0].learning_style_required,
            colleges: responseJson.results[0].colleges,
            collegesRequired: responseJson.results[0].colleges_required,
            careers: responseJson.results[0].careers,
            careersRequired: responseJson.results[0].careers_required,
            gradeId: responseJson.results[0].grade_id,
            goals: responseJson.results[0].goals,
            goalsRequired: responseJson.results[0].goals_required,
            skills: responseJson.results[0].skills,
            skillsRequired: responseJson.results[0].skills_required,
            clubs: responseJson.results[0].clubs,
            clubsRequired: responseJson.results[0].clubs_required,
            honors: responseJson.results[0].honors,
            honorsRequired: responseJson.results[0].honors_required,
            hasResume: responseJson.results[0].has_resume,
            hasResumeRequired: responseJson.results[0].has_resume_required,
            assessments: responseJson.results[0].assessments,
            assessmentsRequired: responseJson.results[0].assessments_required,
            workBasedLearning: responseJson.results[0].work_based_learning,
            workBasedLearningRequired: responseJson.results[0].work_based_learning_required,
            completionPercent: responseJson.results[0].completion_percent
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      })
  }

  onProgressOpen() {
    this.setState({
      isProgressOpen: true
    })
  }

  onProgressClose() {
    this.setState({
      isProgressOpen: false
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <Dialog open={this.state.isProgressOpen}
          onClose={this.onProgressClose} fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title" onClose={this.onProgressClose}>
            Progress Tracker - {this.state.completionPercent}%
          </DialogTitle>

          <DialogContent>

            <Grid container>

              <Grid item xs={12}>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>Identify Your Pathway:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.hasPathwayRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.hasPathway === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>Complete Interest Inventory:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.interestInventoryRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.interestInventory === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>Complete Learning Style Inventory:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.learningStyleRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.learningStyle === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>Identify Your Favorite College:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.collegesRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.colleges === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>Identify Your Favorite Careers:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.careersRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.careers === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>Identify Three Skills You Have:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.skillsRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.skills === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>List Three Goals:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.goalsRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.goals === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>List Three Clubs or Sports You Are Involved In:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.clubsRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.clubs === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>List One Honor or Award You Have Earned:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.honorsRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.honors === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>List One Assessment You Have Completed:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.assessmentsRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.assessments === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>List One Work Based Learning You Have Completed:</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.workBasedLearningRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.workBasedLearning === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={9}>
                    <Typography>Upload or Build Resume (Required For Seniors):</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    {this.state.gradeId >= 13 && this.state.hasResumeRequired ?
                      <Chip label="Required"
                        style={{
                          fontSize: 10, backgroundColor: '#F8A19E',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      : null}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    {this.state.hasResume === 0 ?

                      <Chip label="No"
                        style={{
                          fontSize: 10, backgroundColor: '#E0E0E0',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                      :
                      <Chip label="Yes"
                        style={{
                          fontSize: 10, backgroundColor: '#25D192',
                          color: '#FFF', fontWeight: 'bold', marginRight: 5
                        }} />
                    }
                  </Grid>
                </Grid>

              </Grid>

            </Grid>

          </DialogContent>
        </Dialog>

        <Grid container>
          <Grid item xs={10}>
            <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', color: '#4D4D4F' }}>
              {i18n.t("HOME_ILP_PROGRESS_BOARD")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <IconButton style={{ marginTop: 15 }} onClick={() => this.onProgressOpen()}>
              <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faInfo} />
            </IconButton>
          </Grid>
        </Grid>
        <LinearProgress variant="determinate" value={this.state.completionPercent} />
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    personId: state.auth.personId,
    userRoleId: state.auth.userRoleId,
    districtId: state.profile.districtId,
    taskId: state.task.taskId,
    profileId: state.profile.profileId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default (withTranslation("translate"))(connect(mapStateToProps, mapDispatchToProps)(ILPProgress));