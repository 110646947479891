import { SET_OBJECTIVE, SET_RESUME, SET_RESUME_TEMPLATE, SET_SECTIONS, SET_IS_EDITING_RESUME, CLEAR_RESUME_DATA } from '../actions'

export const setSelectedResumeTemplate = (template) => {
    return{
        type:SET_RESUME_TEMPLATE,
        resumeTemplate:template
    }
}
export const setIsEditingResume = (val, resumeId) => {
    return{
        type:SET_IS_EDITING_RESUME,
        isEditing: val,
        resumeId:resumeId
    }
}
export const setObjective = (objectiveText) => {
    return{
        type:SET_OBJECTIVE,
        objectiveText: objectiveText
    }
}

export const setResume = (objectiveText, skills, workExperience,honorsAndAwards, workBasedLearning, serviceLearning, schoolName) => {
    return{
        type:SET_RESUME,
        objectiveText:objectiveText,
        skills: skills,
        workExperience: workExperience,
        honorsAndAwards: honorsAndAwards,
        workBasedLearning: workBasedLearning,
        serviceLearning: serviceLearning,
        schoolName:schoolName
    }
}


export const setResumeSections = (sections, objectiveSelected, skillsSelected, workExperienceSelected ,achievementsSelected, workSelected, serviceSelected) => {
    return{
        type:SET_SECTIONS,
        resumeSections:sections,
        objectiveSelected:objectiveSelected,
        skillsSelected: skillsSelected,
        workExperienceSelected: workExperienceSelected,
        achievementsSelected: achievementsSelected,
        workSelected: workSelected,
        serviceSelected: serviceSelected,
    }
}   

export const clearResumeData = () => {
    return {
        type:CLEAR_RESUME_DATA
    }
}