import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
    Checkbox,
    Dialog, DialogContent,
    Grid, IconButton,
    LinearProgress, Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n.js';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" onClick={onClose}
                    sx={{ position: 'absolute', right: 10, top: 20 }} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class ILPChecklist extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            completionPercent: 0,
            gradeId: 0,
            item1: false,
            item2: false,
            item3: false,
            item4: false,
            item5: false,
            item6: false,
            item7: false,
            isChecklistOpen: false
        }

        this.onChecklistOpen = this.onChecklistOpen.bind(this);
        this.onChecklistClose = this.onChecklistClose.bind(this);

    }

    componentDidMount() {

        this.getData()
    }

    getData() {
        fetch('https://api.tasselpathway.com/profile_checklist/' + this.props.profileId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        item1: responseJson.results[0].item_1,
                        item2: responseJson.results[0].item_2,
                        item3: responseJson.results[0].item_3,
                        item4: responseJson.results[0].item_4,
                        item5: responseJson.results[0].item_5,
                        item6: responseJson.results[0].item_6,
                        item7: responseJson.results[0].item_7,
                        completionPercent: responseJson.results[0].completion_percent
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            })
    }

    onChecklistOpen() {
        this.setState({
            isChecklistOpen: true
        })
    }

    onChecklistClose() {
        this.setState({
            isChecklistOpen: false
        })
    }

    onChecklistChange(evt, option) {

        var item1 = this.state.item1;
        var item2 = this.state.item2;
        var item3 = this.state.item3;
        var item4 = this.state.item4;
        var item5 = this.state.item5;
        var item6 = this.state.item6;
        var item7 = this.state.item7;

        if (option === "item1") {

            item1 = !item1;

            this.setState({
                item1: item1
            })
        } else if (option === "item2") {

            item2 = !item2

            this.setState({
                item2: item2
            })
        } else if (option === "item3") {

            item3 = !item3

            this.setState({
                item3: item3
            })
        } else if (option === "item4") {

            item4 = !item4

            this.setState({
                item4: item4
            })
        } else if (option === "item5") {

            item5 = !item5

            this.setState({
                item5: item5
            })
        } else if (option === "item6") {

            item6 = !item6

            this.setState({
                item6: item6
            })
        } else if (option === "item7") {

            item7 = !item7

            this.setState({
                item7: item7
            })
        }

        fetch('https://api.tasselpathway.com/profile_checklist', {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit',
            body: JSON.stringify({
                item_1: item1,
                item_2: item2,
                item_3: item3,
                item_4: item4,
                item_5: item5,
                item_6: item6,
                item_7: item7,
                person_id: this.props.profileId
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {



                this.getData()

            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            })

    }

    render() {
        const { classes } = this.props;
        return (
            <div>

                <Dialog open={this.state.isChecklistOpen}
                    onClose={this.onChecklistClose} fullWidth>
                    <DialogTitle onClose={this.onChecklistClose}>
                        Graduation Checklist - {this.state.completionPercent}%
                    </DialogTitle>

                    <DialogContent>

                        <Grid container>

                            <Grid item xs={12}>

                                <Grid container style={{ marginBottom: 10 }}>
                                    <Grid item xs={9}>
                                        <Typography>10 Foundational Credits:</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                                        <Checkbox checked={this.state.item1} onChange={(evt) => this.onChecklistChange(evt, 'item1')} />
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: 10 }}>
                                    <Grid item xs={9}>
                                        <Typography>Complete 1 or more courses that meet the school's financial literacy requirement:</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                                        <Checkbox checked={this.state.item2} onChange={(evt) => this.onChecklistChange(evt, 'item2')} />
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: 10 }}>
                                    <Grid item xs={9}>
                                        <Typography>Passing Grade on the Civics Exam:</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                                        <Checkbox checked={this.state.item3} onChange={(evt) => this.onChecklistChange(evt, 'item3')} />
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: 10 }}>
                                    <Grid item xs={9}>
                                        <Typography>Complete a professional Resume:</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                                        <Checkbox checked={this.state.item4} onChange={(evt) => this.onChecklistChange(evt, 'item4')} />
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: 10 }}>
                                    <Grid item xs={9}>
                                        <Typography>Complete one post secondary admissions application to a Kentucky public two-year community and technical college, or a Ketnucky four-year public of nonprofit independent institution:</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                                        <Checkbox checked={this.state.item5} onChange={(evt) => this.onChecklistChange(evt, 'item5')} />
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: 10 }}>
                                    <Grid item xs={9}>
                                        <Typography>Attainment of college entrance exam benchmarks:</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                                        <Checkbox checked={this.state.item6} onChange={(evt) => this.onChecklistChange(evt, 'item6')} />
                                    </Grid>
                                </Grid>

                                <Grid container style={{ marginBottom: 10 }}>
                                    <Grid item xs={9}>
                                        <Typography>Performance Based Requirement:</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                                        <Checkbox checked={this.state.item7} onChange={(evt) => this.onChecklistChange(evt, 'item7')} />
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                    </DialogContent>
                </Dialog>

                <Grid container>
                    <Grid item xs={10}>
                        <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', color: '#4D4D4F' }}>
                            {i18n.t("HOME_GRADUATION_CHECKLIST")}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton style={{ marginTop: 15 }} onClick={() => this.onChecklistOpen()}>
                            <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faInfo} />
                        </IconButton>
                    </Grid>
                </Grid>

                <LinearProgress variant="determinate" style={{ marginBottom: 10 }} value={this.state.completionPercent} />
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        token: state.auth.token,
        personId: state.auth.personId,
        userRoleId: state.auth.userRoleId,
        districtId: state.profile.districtId,
        taskId: state.task.taskId,
        profileId: state.profile.profileId,
        selectedLanguage: state.language.selectedLanguage
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default (withTranslation("translate"))(connect(mapStateToProps, mapDispatchToProps)(ILPChecklist));