import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Button,
  Card, CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton, List,
  ListItem,
  ListItemAvatar,
  ListItemText, Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class AdaptabilityScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isEditOpen: false,
      dataSource: [],
      isComplete: 0,
      problemSolving: [],
      problemSolvingName: '',
      choices: [],
      adaptability: [],
      adaptabilityName: '',
      skills: [],
      skillsName: '',
      pageNumber: 1
    }

    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
  }

  componentDidMount() {
    this.getData(this.props.profileId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(profileId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/work_ready_cluster/' + profileId + '/1', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            isComplete: responseJson.results[0].is_complete
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  getQuestions() {

    fetch('https://api.tasselpathway.com/work_ready_question/' + this.props.profileId + '/1', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          var choices = this.state.choices;

          for (var i = 0; i < responseJson.results.length; i++) {

            if (responseJson.results[i].is_checked === 1) {
              choices['choice_' + responseJson.results[i].choice_id] = 1;
            } else {
              choices['choice_' + responseJson.results[i].choice_id] = 0;
            }
          }

          this.setState({
            isLoading: false,
            problemSolving: responseJson.results,
            problemSolvingName: responseJson.results[0].question_name,
            choices: choices
          })
        }
      })
      .catch((error) => {

        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/work_ready_question/' + this.props.profileId + '/2', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          var choices = this.state.choices;

          for (var i = 0; i < responseJson.results.length; i++) {
            if (responseJson.results[i].is_checked === 1) {
              choices['choice_' + responseJson.results[i].choice_id] = 1;
            } else {
              choices['choice_' + responseJson.results[i].choice_id] = 0;
            }
          }

          this.setState({
            isLoading: false,
            adaptability: responseJson.results,
            adaptabilityName: responseJson.results[0].question_name,
            choices: choices
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/work_ready_question/' + this.props.profileId + '/3', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          var choices = this.state.choices;

          for (var i = 0; i < responseJson.results.length; i++) {
            if (responseJson.results[i].is_checked === 1) {
              choices['choice_' + responseJson.results[i].choice_id] = 1;
            } else {
              choices['choice_' + responseJson.results[i].choice_id] = 0;
            }
          }

          this.setState({
            isLoading: false,
            skills: responseJson.results,
            skillsName: responseJson.results[0].question_name,
            choices: choices
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderAdaptability() {

    var content = this.state.dataSource.map((item, key) => {
      return (
        <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false} alignItems="flex-start"
          onClick={() => this.onEditOpen(item)}>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                  className={this.props.classes.inline} color="textPrimary">
                  {item.project_name}
                </Typography>
                <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.project_description}</Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    })

    return content;
  }

  onEditClick() {

    this.getQuestions();

    this.setState({
      isEditOpen: true
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onChoiceSelect(e, item, choice) {

    var choices = this.state.choices;

    if (item.choice_id === choice) {
      if (choices['choice_' + item.choice_id] === 1) {
        choices['choice_' + item.choice_id] = 0;
      } else {
        choices['choice_' + item.choice_id] = 1;
      }
    } else {
      choices['choice_' + item.choice_id] = 0;
    }

    this.setState({
      choices: choices
    })

  }

  onBackClick() {
    var pageNumber = this.state.pageNumber;

    this.setState({
      pageNumber: pageNumber - 1
    })
  }

  onNextClick() {
    var pageNumber = this.state.pageNumber;

    this.setState({
      pageNumber: pageNumber + 1
    })
  }

  render() {

    const { i18n } = this.props;
    const { classes } = this.props;

    return (
      <div>

        <div className={classes.root}>

          <Dialog open={this.state.isEditOpen}
            onClose={this.onEditClose} maxWidth="sm"
            fullWidth={true}>
            <DialogTitle onClose={this.onEditClose}>
              {i18n.t("PROFILE_ADAPTABILITY_TILE")}
            </DialogTitle>

            <DialogContent>

              <div>

                {this.state.pageNumber === 1 ?

                  <>
                    <Typography>{this.state.problemSolvingName} - (Check All That Apply)</Typography>

                    <FormGroup row style={{ marginLeft: 20 }}>

                      {this.state.problemSolving.map((item, key) => {
                        return (
                          <FormControlLabel key={key} onChange={(event) => this.onChoiceSelect(event, item, item.choice_id)}
                            control={
                              <Checkbox value={item.choice_id} checked={this.state.choices['choice_' + item.choice_id] ? true : false} />
                            }
                            label={item.choice_name}
                          />
                        )
                      })}

                    </FormGroup>
                  </>

                  : null}

                {this.state.pageNumber === 2 ?

                  <>
                    <Typography>{this.state.adaptabilityName} - (Check All That Apply)</Typography>

                    <FormGroup row style={{ marginLeft: 20 }}>

                      {this.state.adaptability.map((item, key) => {
                        return (
                          <FormControlLabel key={key} onChange={(event) => this.onChoiceSelect(event, item, item.choice_id)}
                            control={
                              <Checkbox value={item.choice_id} checked={this.state.choices['choice_' + item.choice_id] ? true : false} />
                            }
                            label={item.choice_name}
                          />
                        )
                      })}

                    </FormGroup>
                  </>

                  : null}

                {this.state.pageNumber === 3 ?

                  <>
                    <Typography>{this.state.skillsName} - (Check All That Apply)</Typography>

                    <FormGroup row style={{ marginLeft: 20 }}>

                      {this.state.skills.map((item, key) => {
                        return (
                          <FormControlLabel key={key} onChange={(event) => this.onChoiceSelect(event, item, item.choice_id)}
                            control={
                              <Checkbox value={item.choice_id} checked={this.state.choices['choice_' + item.choice_id] ? true : false} />
                            }
                            label={item.choice_name}
                          />
                        )
                      })}

                    </FormGroup>
                  </>

                  : null}

              </div>
            </DialogContent>
            <DialogActions>

              <Grid container style={{ marginBottom: 20, marginTop: 20 }}>

                <Grid item xs={2}>

                  {this.state.pageNumber !== 1 ?
                    <Button variant="contained" onClick={() => this.onBackClick()} color="primary"
                      style={{ color: '#FFF', fontWeight: 'bold', marginLeft: 10 }}>
                      {i18n.t("BACK")}
                    </Button>
                    : null}
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  {this.state.pageNumber !== 3 ?
                    <Button variant="contained" onClick={() => this.onNextClick()} color="primary"
                      style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }}>
                      {i18n.t("NEXT")}
                    </Button>
                    :
                    <Button variant="contained" onClick={this.createProject} color="primary"
                      style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }}>
                      {i18n.t("FINISH")}
                    </Button>
                  }
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("PROFILE_ADAPTABILITY_TILE")}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right', marginTop: 5, alignItems: 'right' }}>
                  {this.props.profileId === this.props.personId ?
                    <Button color="primary" variant="contained" onClick={() => this.onEditClick()}>
                      <Typography style={{ fontWeight: 'bold', fontSize: 16, color: '#FFF' }}>{this.state.isComplete === 0 ? 'Start' : 'Edit'}</Typography>
                    </Button>
                    : null}
                </Grid>
              </Grid>

              {this.state.isComplete === 0 ?
                <Grid container spacing={0}
                  direction="column" alignItems="center" justify="center" style={{ height: 200 }}>

                  <Typography style={{ fontSize: 14 }}>
                    Adaptability includes an openness to learning new things and problem solving.
                    Being willing to find and/or embrace new ways of doing things.
                    It also involves learning how to think critical and see both sides of a situation.
                  </Typography>

                </Grid>
                :

                <Grid container>
                  <Grid item xs={12}>
                    <List style={{ height: 200, overflow: 'auto' }}>

                      <ListItem>
                        <ListItemAvatar>
                          <Avatar alt="MSU" style={{ height: 30, width: 30, backgroundColor: '#25D192' }}>
                            <CheckIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ fontSize: 16 }} primary="Problem Solving" />
                        <Avatar alt="MSU" style={{ height: 30, width: 30, backgroundColor: '#1F3545' }}>
                          <Typography>1</Typography>
                        </Avatar>
                      </ListItem>

                      <ListItem>
                        <ListItemAvatar>
                          <Avatar alt="MSU" style={{ height: 30, width: 30, backgroundColor: '#25D192' }}>
                            <CheckIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ fontSize: 16 }} primary="Adaptability" />
                        <Avatar alt="MSU" style={{ height: 30, width: 30, backgroundColor: '#1F3545' }}>
                          <Typography>0</Typography>
                        </Avatar>
                      </ListItem>

                      <ListItem>
                        <ListItemAvatar>
                          <Avatar alt="MSU" style={{ height: 30, width: 30, backgroundColor: '#25D192' }}>
                            <CheckIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ fontSize: 16 }} primary="New Skill Learned" />
                        <Avatar alt="MSU" style={{ height: 30, width: 30, backgroundColor: '#1F3545' }}>
                          <Typography>0</Typography>
                        </Avatar>
                      </ListItem>

                    </List>
                  </Grid>
                </Grid>
              }

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

AdaptabilityScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    profileId: state.profile.profileId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdaptabilityScreen)));