
export default {

  containerStyle: {
    backgroundColor:'#eaeaea',
    height:'100%',
    width:'100%'
  },
  itemContainerStyle: {
      padding:20,
      flexDirection:'row',
      height:'100%',
      justifyContent:'center'
  },    
  container: {
    backgroundColor:'#FFF',
    padding: 40,
    borderRadius:4,
    alignSelf:'center', 
    textAlign:'center',
    alignContent:'center'
  },
  loginText: {
    borderColor: '#FFF',
    marginBottom: 20,
    padding: 10,
    borderWidth: 5
  },
  loginButton: {
    width:100,
    fontSize:14,
    color:'#FFF', 
    borderRadius:0, 
    fontWeight:'bold', 
    textAlign:'center'
  },
  forgotButton: {
    width:200,
    marginLeft:20,
    fontSize:14,
    color:'#FFF', 
    borderRadius:0, 
    fontWeight:'bold', 
    textAlign:'center'
  },
  registerButton: {
    width:100,
    fontSize:14,
    color:'#FFF', 
    borderRadius:0, 
    fontWeight:'bold', 
    textAlign:'center'
  },
  inputStyle: {
    color:'#333',
    borderRadius: 3
  }
};