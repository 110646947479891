import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "../actions";

export const defaultState = {
    username: '',
    password: '',
    personId: 0,
    gradeId: 0,
    gradeName: '',
    firstName: '',
    lastName: '',
    token: '',
    userRoleId: 0,
    userRightId: 0,
    hasMultiDistrict: 0,
    isLoggedIn: false,
    hasError: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isLoggedIn: false,
                hasError: action.hasError
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isLoggedIn: true,
                hasError: false,
                username: action.username,
                password: action.password,
                personId: action.personId,
                gradeId: action.gradeId,
                gradeName: action.gradeName,
                firstName: action.firstName,
                lastName: action.lastName,
                userRoleId: action.userRoleId,
                userRightId: action.userRightId,
                hasMultiDistrict: action.hasMultiDistrict,
                token: action.token
            });
        case LOGOUT:
            return Object.assign({}, state, {
                isLoggedIn: false,
                hasError: false,
                username: '',
                password: '',
                personId: 0,
                gradeId: 0,
                gradeName: '',
                firstName: '',
                lastName: '',
                userRoleId: 0,
                userRightId: 0,
                hasMultiDistrict: 0,
                token: ''
            });
        default:
            return state
    }
}