export default {

    containerStyle: {
        backgroundColor:'#eaeaea',
        height:'100%', 
    },
    itemContainerStyle: {
        padding:20,
        height:'100%',
        justifyContent:'center',
    },
    headerStyle: {
      backgroundColor: '#25D192'  
    },
    avatar: {
        width:50,
        height:50
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
    optionsView: {
        flexDirection: 'row',
        width: '100%',
        paddingBottom: 10,
    },
    optionsFlex: {
        flex: 9
    },
    optionsText: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#499CB3'
    },
    borderStyle: {
        flexDirection: 'row',
        paddingBottom: 10,
        marginTop: 10,
        width: '100%',
        borderBottomColor: '#eaeaea',
        borderBottomWidth: 1,
    },
    dateFlex: {
        flex: 5,
    },
    dateText: {
        textAlign: 'right',
    },
    emptyView: {
        flex: 1,
    },
    editText: {
        fontWeight: 'bold',
        color: '#E68846',
    },
    topBar: {
      backgroundColor: '#FFF',
      paddingBottom:15,
      borderBottom:'1px solid #eaeaea'
    },
    postButton:{
        width:'99%', 
        padding:5, 
        backgroundColor:'#25D192', 
        fontWeight:'bold', 
        color:"#fff",
        fontSize:15, 
        border:0, 
        borderRadius:3,
        outline:'none',
        cursor:'pointer',
        marginTop:10
    },
    hr:{
        borderColor:'#efefef',
        borderStyle:'solid',
        borderWidth:1
    },
    tableWrapper: {
        overflow: 'auto'

    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    menuStyles:{
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
    }
  };

