import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button,
  Card, CardContent,
  Dialog, DialogActions, DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Snackbar, SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class PathwayScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      selectedItem: {},
      dataSource: [],
      courses: [],
      schools: [],
      diplomas: [],
      pathways: [],
      alertMessage: '',
      showMessage: false,
      isNewOpen: false,
      isEditOpen: false,
      isJoinOpen: false,
      isNewPathwayOpen: false,
      isEditPathwayOpen: false,
      isExpanded: false,

      newDiplomaId: 0,
      newPathwayId: 0,
      newCreditEarned: 0,
      newDualCreditEarned: 0,
      pathwayDistricts: []
    }

    this.onNewClose = this.onNewClose.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onJoinClose = this.onJoinClose.bind(this);
    this.onNewPathwayClose = this.onNewPathwayClose.bind(this);
    this.onEditPathwayClose = this.onEditPathwayClose.bind(this);
    this.onCloseMessage = this.onCloseMessage.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {

    fetch('https://api.tasselpathway.com/pathway', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            pathways: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    this.getData(this.props.profileId);
  }

  getData(personId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/diploma', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            diplomas: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/pathway_district', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            pathwayDistricts: responseJson.results
          })
        }
      })

    fetch('https://api.tasselpathway.com/profile_pathway/person/' + personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            schoolId: responseJson.results[0].school_id,
            schoolName: responseJson.results[0].school_name,
            diplomaId: responseJson.results[0].diploma_id,
            diplomaName: responseJson.results[0].diploma_name,
            diplomaNameSpanish: responseJson.results[0].diploma_name_spanish,
            diplomaYear: responseJson.results[0].diploma_year,
            pathwayId: responseJson.results[0].pathway_id,
            pathwayName: responseJson.results[0].pathway_name,
            pathwayNameSpanish: responseJson.results[0].pathway_name_spanish,
            totalCredit: responseJson.results[0].total_credit,
            totalCreditEarned: responseJson.results[0].total_credit_earned,
            totalDualCreditEarned: responseJson.results[0].total_dual_credit_earned,
            dataSource: responseJson.results[0]
          })
          this.props.onPathwayLoaded(this.state.pathwayName)
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  addCourse() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_pathway_schedule/', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.profileId,
        course_id: this.state.newCourseId,
        grade_id: this.state.newGradeId,
        credit_earned: this.state.newCreditEarned,
        dual_credit_earned: this.state.newDualCreditEarned
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          isNewOpen: false
        })

        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isNewOpen: false
        })
      });
  }

  saveCourse() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_pathway_schedule/', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        pathway_schedule_id: this.state.selectedItem.pathway_schedule_id,
        course_id: this.state.selectedItem.course_id,
        credit_earned: this.state.selectedItem.credit_earned,
        dual_credit_earned: this.state.selectedItem.dual_credit_earned
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          isEditOpen: false
        })

        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isEditOpen: false
        })
      });
  }

  deleteCourse() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_pathway_schedule/', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        pathway_schedule_id: this.state.selectedItem.pathway_schedule_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          isEditOpen: false
        })

        this.getData(this.props.profileId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isEditOpen: false
        })
      });
  }

  getSchools() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/schools/available/' + this.props.personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.setState({
            isLoading: false,
            schools: responseJson.results
          })

        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  joinSchool(districtId, schoolId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/students/requests', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId,
        district_id: districtId,
        school_id: schoolId
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isLoading: false,
          isJoinOpen: false,
          showMessage: true,
          alertMessage: 'Created Join Request'
        })

        this.getData(this.props.personId);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isJoinOpen: false
        })
      });
  }

  createPathway() {
    if (this.state.newDiplomaId !== 0 && this.state.newPathwayId !== 0) {

      this.setState({
        isLoading: true
      })



      fetch('https://api.tasselpathway.com/profile_pathway', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          person_id: this.props.personId,
          diploma_id: this.state.newDiplomaId,
          pathway_id: this.state.newPathwayId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          this.setState({
            isLoading: false,
            isNewPathwayOpen: false
          })

          this.getData(this.props.personId);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isNewPathwayOpen: false
          })
        });
    }
  }

  savePathway() {
    if (this.state.diplomaId !== 0 && this.state.pathwayId !== 0) {

      this.setState({
        isLoading: true
      })

      fetch('https://api.tasselpathway.com/profile_pathway', {
        method: "PUT",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          person_id: this.props.personId,
          diploma_id: this.state.diplomaId,
          pathway_id: this.state.pathwayId
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          this.setState({
            isLoading: false,
            isEditPathwayOpen: false
          })

          this.getData(this.props.personId);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isEditPathwayOpen: false
          })
        });
    }
  }

  onPathwayClick() {
    this.setState({
      isExpanded: !this.state.isExpanded
    })
  }

  onCourseClick(item) {

    var credit_hour = 0;
    var dual_credit_hour = 0;

    this.state.courses.map((item2, key) => {
      if (item.course_id === item2.course_id) {
        credit_hour = item2.credit_hour;
        dual_credit_hour = item2.dual_credit_hour;
      }
    })

    this.setState({
      isEditOpen: true,
      selectedItem: item,
      credit_hour: credit_hour,
      dual_credit_hour: dual_credit_hour
    })
  }

  onNewClick(gradeId) {

    fetch('https://api.tasselpathway.com/diploma_course/' + this.props.schoolId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            courses: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });


    this.setState({
      newGradeId: gradeId,
      newCourseId: 0,
      newCreditEarned: 0,
      newDualCreditEarned: 0,
      isNewOpen: true,

    })
  }

  onNewPathwayClick() {
    this.setState({
      isNewPathwayOpen: true
    })
  }

  onNewPathwayClose() {
    this.setState({
      isNewPathwayOpen: false
    })
  }

  onEditPathwayClick() {

    fetch('https://api.tasselpathway.com/diploma', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            diplomas: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    this.setState({
      isEditPathwayOpen: true
    })
  }

  onEditPathwayClose() {
    this.setState({
      isEditPathwayOpen: false
    })
  }

  onJoinSchoolClick() {

    this.getSchools();

    this.setState({
      isJoinOpen: true
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onJoinClose() {
    this.setState({
      isJoinOpen: false
    })
  }

  onCloseMessage() {
    this.setState({
      showMessage: false
    })
  }

  renderCourses(items) {

    var content = items.map((item, key) => {

      var color = '#25D192';

      if (item.is_cte === true) {
        color = '#1F3545';
      } else if (item.is_ap === true) {
        color = '#E68846';
      } else {
        color = '#25D192';
      }
      if (item.course_id === null) {
        return null
      }
      else {
        return (

          <div key={key} style={{ backgroundColor: color, padding: 10, cursor: 'pointer', marginTop: 15, color: '#FFF' }}
            onClick={() => this.onCourseClick(item)}>
            <Grid container >
              <Grid item xs={8}>

                <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>{item.course_name} ({item.credit_hour})</Typography>
                <Typography style={{ fontSize: 12, color: '#FFF', fontWeight: 'bold' }}>{item.subject_name}</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                {item.credit_earned > 0 ?
                  <Typography style={{ fontSize: 14, color: '#FFF', fontWeight: 'bold' }}>{item.credit_earned} {i18n.t("PATHWAY_CREDITS")}</Typography>
                  : null}
                {item.dual_credit_earned > 0 ?
                  <Typography style={{ fontSize: 12, color: '#FFF', fontWeight: 'bold' }}>{item.dual_credit_earned} {i18n.t("PATHWAY_DUAL_CREDITS")}</Typography>
                  : null}
              </Grid>
            </Grid>
          </div>
        )
      }
    })

    return content;
  }

  renderGrades() {

    if (this.state.dataSource.grades === undefined) return null;

    var content = this.state.dataSource.grades.map((item, key) => {

      var color = '#25D192';

      if (item.is_cte === true) {
        color = '#1F3545';
      } else if (item.is_ap === true) {
        color = '#E68846';
      } else {
        color = '#25D192';
      }
      if (this.props.selectedLanguage === "esp") {
        return (

          <Grid item xs={6} key={item.grade_id}>

            <div style={{ backgroundColor: color, padding: 10, color: '#FFF' }}>

              <Grid container >
                <Grid item xs={11}>
                  <b>{item.grade_name_spanish}</b>
                </Grid>
                <Grid item xs={1}>

                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 12, color: '#FFF' }}>{item.grade_credit_earned === null ? 0 : item.grade_credit_earned} {i18n.t("PATHWAY_CREDIT_EARNED")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 12, color: '#FFF' }}>{item.grade_dual_credit_earned === null ? 0 : item.grade_dual_credit_earned} {i18n.t("PATHWAY_DUAL_CRED_EARNED")}</Typography>
                </Grid>
              </Grid>
            </div>

          </Grid>
        )
      } else {
        return (

          <Grid item xs={6} key={item.grade_id}>

            <div style={{ backgroundColor: color, padding: 10, color: '#FFF' }}>

              <Grid container >
                <Grid item xs={11}>
                  <b>{item.grade_name}</b>
                </Grid>
                <Grid item xs={1}>

                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 12, color: '#FFF' }}>{item.grade_credit_earned === null ? 0 : item.grade_credit_earned} {i18n.t("PATHWAY_CREDIT_EARNED")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: 12, color: '#FFF' }}>{item.grade_dual_credit_earned === null ? 0 : item.grade_dual_credit_earned} {i18n.t("PATHWAY_DUAL_CRED_EARNED")}</Typography>
                </Grid>
              </Grid>
            </div>

          </Grid>
        )
      }
    })

    return content;
  }

  renderGradesExpand() {

    if (this.state.dataSource.grades === undefined) return null;
    var content = this.state.dataSource.grades.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {
        return (
          <Grid item xs={6} key={item.grade_id}>
            <div style={{ padding: 10 }}>
              <Grid container >
                <Grid item xs={11}>
                  <b>{item.grade_name_spanish}</b>
                </Grid>
                <Grid item xs={1}>

                </Grid>
              </Grid>

              <Typography style={{ fontSize: 12 }}>{item.grade_credit_earned} {i18n.t("PATHWAY_CREDIT_EARNED")}</Typography>

              {this.renderCourses(item.courses)}

              <br />

              <div style={{ backgroundColor: '#fff', border: '1px dashed #333', padding: 10, color: '#333', cursor: 'pointer' }}>
                <Grid container style={{ justifyContent: 'center' }} onClick={() => this.onNewClick(item.grade_id)}>
                  <FontAwesomeIcon style={{ marginTop: 2 }} icon={faPlus} />
                  <Typography style={{ fontSize: 14, marginLeft: 10, color: '#333', fontWeight: 'bold' }}>{i18n.t("PATHWAY_ADD_COURSE")}</Typography>
                </Grid>
              </div>
            </div>
          </Grid>
        )
      } else {
        return (
          <Grid item xs={6} key={item.grade_id}>
            <div style={{ padding: 10 }}>
              <Grid container >
                <Grid item xs={11}>
                  <b>{item.grade_name}</b>
                </Grid>
                <Grid item xs={1}>

                </Grid>
              </Grid>

              <Typography style={{ fontSize: 12 }}>{item.grade_credit_earned} {i18n.t("PATHWAY_CREDIT_EARNED")}</Typography>

              {this.renderCourses(item.courses)}

              <br />

              <div style={{ backgroundColor: '#fff', border: '1px dashed #333', padding: 10, color: '#333', cursor: 'pointer' }}>
                <Grid container style={{ justifyContent: 'center' }} onClick={() => this.onNewClick(item.grade_id)}>
                  <FontAwesomeIcon style={{ marginTop: 2 }} icon={faPlus} />
                  <Typography style={{ fontSize: 14, marginLeft: 10, color: '#333', fontWeight: 'bold' }}>{i18n.t("PATHWAY_ADD_COURSE")}</Typography>
                </Grid>
              </div>
            </div>
          </Grid>
        )
      }
    })

    return content;
  }

  onNewCourseChange(value) {

    var credit_hour = 0;
    var dual_credit_hour = 0;

    this.state.courses.map((item, key) => {
      if (item.course_id === value) {
        credit_hour = item.credit_hour;
        dual_credit_hour = item.dual_credit_hour;
      }
    })

    this.setState({
      newCourseId: value,
      credit_hour: credit_hour,
      dual_credit_hour: dual_credit_hour
    })
  }

  onEditCourseChange(value) {

    var credit_hour = 0;
    var dual_credit_hour = 0;

    var selectedItem = this.state.selectedItem;
    selectedItem.course_id = value;

    this.state.courses.map((item, key) => {
      if (item.course_id === value) {
        credit_hour = item.credit_hour;
        dual_credit_hour = item.dual_credit_hour;
      }
    })

    this.setState({
      selectedItem: selectedItem,
      credit_hour: credit_hour,
      dual_credit_hour: dual_credit_hour
    })
  }

  onCreditHourChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.credit_earned = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDualCreditHourChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.dual_credit_earned = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDiplomaChange(item, value) {
    this.setState({
      [item]: value
    })
  }

  onPathwayChange(item, value) {
    this.setState({
      [item]: value
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Dialog open={this.state.isNewPathwayOpen} className={classes.popup}
          onClose={this.onNewPathwayClose} maxWidth="sm"
          fullWidth={true} aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onNewPathwayClose}>
            {i18n.t("PATHWAY_NEW")}
          </DialogTitle>

          <DialogContent>

            <FormControl className={classes.formControl}
              style={{ marginTop: 15 }} fullWidth>
              <InputLabel htmlFor="newDiplomaId">{i18n.t("PATHWAY_DIPLOMA")}</InputLabel>
              <Select placeholder="Diploma" variant='standard'
                value={this.state.newDiplomaId}
                onChange={(event) => this.onDiplomaChange('newDiplomaId', event.target.value)}
                inputProps={{
                  name: 'newDiplomaId',
                  id: 'newDiplomaId',
                }}>
                <MenuItem disabled value={0}>{i18n.t("PATHWAY_SELECT_DIPLOMA")}</MenuItem>

                {this.state.diplomas.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.diploma_id}>{item.diploma_name} - {item.diploma_year}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}
              style={{ marginTop: 15 }} fullWidth>
              <InputLabel htmlFor="newPathwayId">{i18n.t("PATHWAY")}</InputLabel>
              <Select placeholder="Pathway" variant='standard'
                value={this.state.newPathwayId}
                onChange={(event) => this.onPathwayChange('newPathwayId', event.target.value)}
                inputProps={{
                  name: 'newPathwayId',
                  id: 'newPathwayId',
                }}>
                <MenuItem disabled value={0}>{i18n.t("PATHWAY_SELECT")}</MenuItem>
                {this.state.pathwayDistricts.length > 0 ?
                  this.state.pathwayDistricts.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.pathway_id}>{item.pathway_name}</MenuItem>
                    )
                  })
                  : this.state.pathways.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.pathway_id}>{item.pathway_name}</MenuItem>
                    )
                  })}
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => this.createPathway()}
              style={{ marginRight: 20 }}>
              <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>{i18n.t("PATHWAY_CREATE")}</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isEditPathwayOpen} className={classes.popup}
          onClose={this.onEditPathwayClose} maxWidth="sm"
          fullWidth={true} aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onEditPathwayClose}>
            {i18n.t("EDIT_PATHWAY")}
          </DialogTitle>

          <DialogContent>

            <FormControl className={classes.formControl}
              style={{ marginTop: 15 }} fullWidth>
              <InputLabel htmlFor="diplomaId">{i18n.t("PATHWAY_DIPLOMA")}</InputLabel>
              <Select placeholder="Diploma" variant='standard'
                value={this.state.diplomaId}
                onChange={(event) => this.onDiplomaChange('diplomaId', event.target.value)}
                inputProps={{
                  name: 'diplomaId',
                  id: 'diplomaId',
                }}>
                <MenuItem disabled value={0}>{i18n.t("PATHWAY_SELECT_DIPLOMA")}</MenuItem>

                {this.state.diplomas.map((item, key) => {
                  if (this.props.selectedLanguage === "esp") {

                    return (
                      <MenuItem key={key} value={item.diploma_id}>{item.diploma_name_spanish} - {item.diploma_year}</MenuItem>
                    )
                  } else {
                    return (
                      <MenuItem key={key} value={item.diploma_id}>{item.diploma_name} - {item.diploma_year}</MenuItem>
                    )
                  }
                })}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}
              style={{ marginTop: 15 }} fullWidth>
              <InputLabel htmlFor="pathwayId">{i18n.t("PATHWAY")}</InputLabel>
              <Select placeholder="Pathway" variant='standard'
                value={this.state.pathwayId}
                onChange={(event) => this.onPathwayChange('pathwayId', event.target.value)}
                inputProps={{
                  name: 'pathwayId',
                  id: 'pathwayId',
                }}>
                <MenuItem disabled value={0}>{i18n.t("PATHWAY_SELECT")}</MenuItem>

                {this.state.pathwayDistricts.length > 0 ?
                  this.state.pathwayDistricts.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.pathway_id}>{item.pathway_name}</MenuItem>
                    )
                  })
                  : this.state.pathways.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.pathway_id}>{item.pathway_name}</MenuItem>
                    )
                  })}


              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => this.savePathway()}
              style={{ marginRight: 20 }}>
              <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>{i18n.t("PATHWAY_SAVE")}</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isJoinOpen} className={classes.popup}
          onClose={this.onJoinClose} maxWidth="sm"
          fullWidth={true} aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onJoinClose}>
            {i18n.t("PATHWAY_JOIN_SCHOOL")}
          </DialogTitle>

          <DialogContent>

            <Table>

              <TableHead>
                <TableCell>{i18n.t("PATHWAY_SCHOOL_NAME")}</TableCell>
                <TableCell></TableCell>
              </TableHead>

              <TableBody>

                {this.state.schools.map((item, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{item.school_name}</TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => this.joinSchool(item.district_id, item.school_id)}
                          style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }} color="primary">
                          {i18n.t("PATHWAY_JOIN")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>

            </Table>

          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>

        <Snackbar
          classes={{
            root: classes.message
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          autoHideDuration={5000}
          onClose={this.onCloseMessage}
          open={this.state.showMessage}>
          <SnackbarContent
            message={
              <span style={styles.message}>
                {this.state.alertMessage}
              </span>
            }
            classes={{
              root: classes.error
            }}
            action={
              <IconButton key="close" onClick={this.onCloseMessage} className={classes.content}>
                <CloseIcon style={{ color: '#FFF' }} />
              </IconButton>
            }>
          </SnackbarContent>
        </Snackbar>

        <Dialog open={this.state.isNewOpen} className={classes.popup}
          onClose={this.onNewClose} maxWidth="sm"
          fullWidth={true} aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onNewClose}>
            {i18n.t("PATHWAY_ADD_COURSE")}
          </DialogTitle>

          <DialogContent>

            <div>

              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="newCourse">{i18n.t("PATHWAY_COURSE")}</InputLabel>
                <Select variant='standard'
                  value={this.state.newCourseId} placeholder="Course"
                  onChange={(event) => this.onNewCourseChange(event.target.value)}
                  inputProps={{
                    name: 'newCourse',
                    id: 'newCourse',
                  }}>
                  <MenuItem disabled value={0}>{i18n.t("PATHWAY_SELECT_COURSE")}</MenuItem>

                  {this.state.courses.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.course_id}>{item.course_name}</MenuItem>
                    )
                  })}

                </Select>
              </FormControl>

              <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="creditEarned">{i18n.t("PATHWAY_CREDIT_EARNED")}</InputLabel>
                    <Input id="creditEarned"
                      value={this.state.newCreditEarned}
                      onChange={(event) => this.setState({ newCreditEarned: event.target.value })}
                      aria-describedby="my-helper-text" />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ marginTop: 20, fontSize: 16 }}> / {this.state.credit_hour} {i18n.t("PATHWAY_CREDITS")}</Typography>
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="dualCreditEarned">{i18n.t("PATHWAY_DUAL_CRED_EARNED")}</InputLabel>
                    <Input id="dualCreditEarned"
                      value={this.state.newDualCreditEarned}
                      onChange={(event) => this.setState({ newDualCreditEarned: event.target.value })}
                      aria-describedby="my-helper-text" />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ marginTop: 20, fontSize: 16 }}> / {this.state.dual_credit_hour}  {i18n.t("PATHWAY_DUAL_CREDITS")}</Typography>
                </Grid>
              </Grid>

            </div>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => this.addCourse()}
              style={{ color: '#FFF', fontWeight: 'bold', marginRight: 20 }} color="primary">
              {i18n.t("ADD")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isEditOpen} className={classes.popup}
          onClose={this.onEditClose} maxWidth="sm"
          fullWidth={true} aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title"
            onClose={this.onEditClose}>
            {i18n.t("EDIT_COURSE")}
          </DialogTitle>

          <DialogContent>

            <div>

              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="newCourse">{i18n.t("PATHWAY_COURSE")}</InputLabel>
                <Select variant='standard'
                  value={this.state.selectedItem.course_id} placeholder="Course"
                  onChange={(event) => this.onEditCourseChange(event.target.value)}
                  inputProps={{
                    name: 'newCourse',
                    id: 'newCourse',
                  }}>
                  <MenuItem disabled value={0}>{i18n.t("PATHWAY_SELECT_COURSE")}</MenuItem>

                  {this.state.courses.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.course_id}>{item.course_name}</MenuItem>
                    )
                  })}

                </Select>
              </FormControl>

              <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="creditEarned">{i18n.t("PATHWAY_CREDIT_EARNED")}</InputLabel>
                    <Input id="creditEarned"
                      value={this.state.selectedItem.credit_earned}
                      onChange={(event) => this.onCreditHourChange(event)}
                      aria-describedby="my-helper-text" />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ marginTop: 20, fontSize: 16 }}> / {this.state.credit_hour} {i18n.t("PATHWAY_CREDITS")}</Typography>
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="dualCreditEarned">{i18n.t("PATHWAY_DUAL_CRED_EARNED")}</InputLabel>
                    <Input id="dualCreditEarned"
                      value={this.state.selectedItem.dual_credit_earned}
                      onChange={(event) => this.onDualCreditHourChange(event)}
                      aria-describedby="my-helper-text" />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ marginTop: 20, fontSize: 16 }}> / {this.state.dual_credit_hour} {i18n.t("PATHWAY_DUAL_CREDITS")}</Typography>
                </Grid>
              </Grid>

            </div>

          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={2}>
                <IconButton variant="contained" style={{ marginLeft: 10 }}
                  onClick={() => this.deleteCourse()}
                  color="secondary" aria-label="Delete" className={this.props.classes.margin}>
                  <DeleteIcon className={this.props.classes.extendedIcon} style={{ color: '#1F3545' }} />
                </IconButton>
              </Grid>
              <Grid item xs={8}>

              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" onClick={() => this.saveCourse()}
                  style={{ color: '#FFF', fontWeight: 'bold' }} color="primary">
                  {i18n.t("PATHWAY_SAVE")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>


        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>

              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={10}>
                  <Grid container style={{ width: '100%' }}>
                    <div style={{ float: 'left' }}>
                      {this.state.pathwayName === null ?
                        <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("PATHWAY")}</Typography>
                        :
                        <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("PATHWAY")} ({this.state.pathwayName})</Typography>
                      }
                    </div>
                    <div style={{ float: 'right' }}>
                      {this.props.districtId !== 14 && this.props.profileId === this.props.personId && this.state.diplomaId !== null ?
                        <IconButton style={{ marginTop: 5, marginLeft: 10 }} onClick={() => this.onEditPathwayClick()}>
                          <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 18, color: '#25D192' }} />
                        </IconButton>
                        :
                        null
                      }
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>

                  {this.props.districtId !== 14 && (this.props.userRoleId < 8 || this.props.profileId === this.props.personId) && this.state.diplomaId !== null ?
                    (this.state.isExpanded ?
                      <IconButton style={{ marginTop: 10 }} onClick={() => this.onPathwayClick()}>
                        <KeyboardArrowUpIcon />
                      </IconButton>
                      :
                      <IconButton style={{ marginTop: 10 }} onClick={() => this.onPathwayClick()}>
                        <KeyboardArrowDownIcon />
                      </IconButton>)
                    :

                    null

                  }
                </Grid>
              </Grid>

              <br />

              {this.props.districtId === 14 && this.props.profileId === this.props.personId ?

                this.state.schoolId === null ?

                  <Button color="primary" variant="contained" onClick={() => this.onJoinSchoolClick()}
                    style={{ marginLeft: 260 }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 18, color: '#FFF' }}>{i18n.t("PATHWAY_JOIN_SCHOOL")}</Typography>
                  </Button>

                  :

                  <Typography style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 18, color: '#1F3545' }}>
                    {i18n.t("PATHWAY_REQUEST_1")} {this.state.schoolName} {i18n.t("REQUEST_2")}
                  </Typography>
                :

                this.state.diplomaId === null && this.props.profileId === this.props.personId ?

                  <Grid container spacing={2} style={{ justifyContent: 'center' }}>

                    <Button variant="contained" color="primary" onClick={() => this.onNewPathwayClick()}>
                      <Typography style={{ color: '#FFF', fontWeight: 'bold' }}>{i18n.t("PATHWAY_CREATE")}</Typography>
                    </Button>

                  </Grid>

                  :

                  <Grid container spacing={2}>
                    {this.props.selectedLanguage === "esp" ?

                      <Grid item xs={6}>
                        <Typography style={{ fontSize: 12, color: '#333', fontWeight: 'bold' }}>{this.state.diplomaNameSpanish} ({this.state.diplomaYear})</Typography>
                        <Typography style={{ fontSize: 12, color: '#333' }}>{this.state.totalCreditEarned} {i18n.t("PATHWAY_CRED_HRS")}</Typography>
                      </Grid>
                      :
                      <Grid item xs={6}>

                        <Typography style={{ fontSize: 12, color: '#333', fontWeight: 'bold' }}>{this.state.diplomaName} ({this.state.diplomaYear})</Typography>
                        <Typography style={{ fontSize: 12, color: '#333' }}>{this.state.totalCreditEarned} {i18n.t("PATHWAY_CRED_HRS")}</Typography>
                      </Grid>
                    }
                    {this.props.selectedLanguage === "esp" ?

                      <Grid item xs={6}>
                        <Typography style={{ fontSize: 12, color: '#333', fontWeight: 'bold' }}>{this.state.pathwayNameSpanish} {i18n.t("PATHWAY")}</Typography>
                        <Typography style={{ fontSize: 12, color: '#333' }}>{this.state.totalDualCreditEarned} {i18n.t("PATHWAY_DUAL_CREDIT_HOURS")}</Typography>
                      </Grid>

                      :
                      <Grid item xs={6}>
                        <Typography style={{ fontSize: 12, color: '#333', fontWeight: 'bold' }}>{this.state.pathwayName} {i18n.t("PATHWAY")}</Typography>
                        <Typography style={{ fontSize: 12, color: '#333' }}>{this.state.totalDualCreditEarned} {i18n.t("PATHWAY_DUAL_CREDIT_HOURS")}</Typography>
                      </Grid>
                    }

                    {this.state.isExpanded ?
                      this.renderGradesExpand() :
                      this.renderGrades()}

                  </Grid>
              }

            </CardContent>
          </Card>

        </Grid>

      </div>
    );
  }
}

PathwayScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    personId: state.auth.personId,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId,
    firstName: state.profile.firstName,
    lastName: state.profile.lastName,
    gradeId: state.profile.gradeId,
    gradeName: state.profile.gradeName,
    districtId: state.profile.districtId,
    districtName: state.profile.districtName,
    schoolId: state.profile.schoolId,
    schoolName: state.profile.schoolName,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default withTranslation("translate")(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PathwayScreen)));