import { GET_COLLEGE, SET_COLLEGE  } from "../actions";

export const defaultState = {
     collegeId: 0,
     collegeName: '',
};
 
export default function reducer(state = defaultState, action) {
     switch (action.type) {
         case GET_COLLEGE:
             return Object.assign({}, state, {
             });
         case SET_COLLEGE:
             return Object.assign({}, state, {
                collegeId: action.collegeId,
                collegeName: action.collegeName
             });
         default:
             return state
     }
 }