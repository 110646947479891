import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton, List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


class WorkExperienceScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      selectedItem: {},
      placeOfWork: '',
      position: '',
      startDate: '',
      endDate: '',
      editPlaceOfWork: '',
      editPosition: '',
      editStartDate: '',
      editEndDate: ''
    }

    this.onNewOpen = this.onNewOpen.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {
    const {
      personId
    } = this.props;

    this.getData(personId)
  }

  getData(personId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/work_experience/' + personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  createWorkExperience = () => {
    const {
      position,
      placeOfWork,
      startDate,
      endDate
    } = this.state;

    const {
      personId
    } = this.props;

    fetch('https://api.tasselpathway.com/create_work_experience', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: personId,
        position: position,
        place_of_work: placeOfWork,
        start_date: startDate,
        end_date: endDate
      }),
      credentials: 'omit'
    })
      .then((response) => console.log(response))
      .then((responseJson) => {
        this.setState({
          isNewOpen: false
        })
        this.getData(personId);

      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isNewOpen: false
        })
      });
  }

  updateWorkExperience = () => {

    const {
      editWorkExperienceId,
      editPosition,
      editPlaceOfWork,
      editStartDate,
      editEndDate
    } = this.state;

    const {
      personId
    } = this.props

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/update_work_experience', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        personId: personId,
        place_of_work: editPlaceOfWork,
        position: editPosition,
        start_date: editStartDate,
        end_date: editEndDate,
        work_experience_id: editWorkExperienceId
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(personId);

          this.setState({
            isLoading: false,
            isEditOpen: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  deleteWorkExperience = () => {
    const {
      editWorkExperienceId
    } = this.state;

    const {
      personId
    } = this.props;

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/delete_work_experience/' + editWorkExperienceId, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(personId);

          this.setState({
            isLoading: false,
            isEditOpen: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderWorkExperience() {

    var content = this.state.dataSource.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {

        return (
          <ListItem key={key} button alignItems="flex-start"
            onClick={() => this.onEditOpen(item)}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                    className={this.props.classes.inline} color="textPrimary">
                    {item.place_of_work} - {moment(item.start_date).format('MM/DD/YYYY')} to {moment(item.end_date).format('MM/DD/YYYY')}
                  </Typography>
                  <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.position}</Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        )
      } else {
        return (
          <ListItem key={key} button alignItems="flex-start"
            onClick={() => this.onEditOpen(item)}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                    className={this.props.classes.inline} color="textPrimary">
                    {item.place_of_work}
                  </Typography>
                  <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.position} - {moment(item.start_date).format('MM/DD/YYYY')} to {moment(item.end_date).format('MM/DD/YYYY')}</Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        )
      }
    })

    return content;
  }

  onNewOpen() {
    this.setState({
      position: '',
      placeOfWork: '',
      startDate: new moment(),
      endDate: new moment(),
      isNewOpen: true
    })
  }

  onNewClose() {
    this.setState({
      isNewOpen: false
    })
  }

  onEditClose() {
    this.setState({
      isEditOpen: false
    })
  }

  onEditOpen(item) {
    this.setState({
      editWorkExperienceId: item.work_experience_id,
      editPlaceOfWork: item.place_of_work,
      editPosition: item.position,
      editEndDate: item.end_date,
      editStartDate: item.start_date,
      isEditOpen: true
    })
  }

  onEditClick() {
    this.setState({
      isEditOpen: false
    })
  }

  onDeleteClick() {
    this.deleteService();

    this.setState({
      isEditOpen: false
    })
  }

  onRemoveClick() {

    this.setState({
      isNewOpen: false
    })
  }

  handleClose = () => {
    this.setState({
      isNewOpen: false
    })
  }


  handleEditClose = () => {
    this.setState({
      isEditOpen: false
    })
  }

  render() {

    const { classes } = this.props;

    return (
      <div>

        <Dialog open={this.state.isNewOpen}
          onClose={this.onNewClose} fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" onClose={this.onNewClose}>
            Add Work Experience
          </DialogTitle>

          <DialogContent>
            <TextField
              autoFocus margin="" name="placeOfWork"
              id="placeOfWork" label="Place of Work" variant='standard'
              type="text" onChange={(evt) => this.setState({ placeOfWork: evt.target.value })}
              fullWidth

            />
            <TextField
              ame="position" margin="dense"
              id="name" label="Position" variant='standard'
              type="text" onChange={(evt) => this.setState({ position: evt.target.value })}
              fullWidth
            />
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <FormControl fullWidth
                  className={classes.formControl}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker error={false}
                      name="startDate"
                      label="Start Date"
                      format={"MM/DD/YYYY"}
                      value={this.state.startDate}
                      renderInput={(params) => <TextField variant='standard' {...params} />}
                      onChange={(evt) => this.setState({ startDate: evt })} />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth
                  className={classes.formControl}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker error={false}
                      name="endDate"
                      label="End Date"
                      format={"MM/DD/YYYY"}
                      value={this.state.endDate}
                      renderInput={(params) => <TextField variant='standard' {...params} />}
                      onChange={(evt) => this.setState({ endDate: evt })} />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.createWorkExperience}
              style={{ marginRight: 20, color: '#FFF', fontWeight: 'bold' }} color="primary">
              {i18n.t("ADD")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.isEditOpen}
          onClose={this.onEditClose} fullWidth
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" onClose={this.onEditClose}>
            Edit Work Experience
          </DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              margin="" name="placeOfWork"
              id="placeOfWork" label="Place of Work" variant='standard'
              type="text" value={this.state.editPlaceOfWork}
              onChange={(evt) => this.setState({ editPlaceOfWork: evt.target.value })}
              fullWidth
            />
            <TextField
              name="position" margin="dense"
              id="name" label="Position" variant='standard'
              type="text" value={this.state.editPosition}
              onChange={(evt) => this.setState({ editPosition: evt.target.value })}
              fullWidth
            />
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <FormControl fullWidth
                  className={classes.formControl}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker error={false}
                      name="endDate"
                      label="End Date"
                      format={"MM/DD/YYYY"}
                      value={this.state.editStartDate}
                      renderInput={(params) => <TextField variant='standard' {...params} />}
                      onChange={(evt) => this.setState({ editStartDate: evt })} />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth
                  className={classes.formControl}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker error={false}
                      name="endDate"
                      label="End Date"
                      format={"MM/DD/YYYY"}
                      value={this.state.editEndDate}
                      renderInput={(params) => <TextField variant='standard' {...params} />}
                      onChange={(evt) => this.setState({ editEndDate: evt })} />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.deleteWorkExperience} color="secondary"
              style={{ color: '#FFF', fontWeight: 'bold' }} >
              {i18n.t("DELETE")}
            </Button>
            <Button variant="contained" onClick={this.updateWorkExperience}
              style={{ marginRight: 20, color: '#FFF', fontWeight: 'bold' }} color="primary">
              {i18n.t("SAVE")}
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={10}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                    {i18n.t("WORK_EXPERIENCE")}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.renderWorkExperience()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

WorkExperienceScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorkExperienceScreen));