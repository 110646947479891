import { Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import CareerList from './careers/index';
import CollegeList from './colleges/index';
import EmployerList from './employers/index';
import MajorList from './majors/index';
import styles from './style';

class ResearchScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: [],
      value: 0
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  onCardClick() {

  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" centered>
          {this.props.selectedLanguage === "esp" ?
            <>
              <Tab label="Colegios" />
              <Tab label="Carreras" />
              <Tab label="Grandes Ligas" />
              <Tab label="Empleadores" />
            </>
            :
            <>
              <Tab label="Colleges" />
              <Tab label="Careers" />
              <Tab label="Majors" />
              <Tab label="Employers" />
            </>
          }
        </Tabs>

        {this.state.value === 0 && <CollegeList />}
        {this.state.value === 1 && <CareerList />}
        {this.state.value === 2 && <MajorList />}
        {this.state.value === 3 && <EmployerList />}

      </div>
    );
  }
}

ResearchScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResearchScreen));