export default {
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    },
    itemContainerStyle: {
        width:'100%',
        height:'100%'
    },
  };