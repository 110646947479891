import { faArrowsAltV, faChevronDown, faPencilAlt, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio, RadioGroup,
  Select,
  Snackbar, SnackbarContent,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import { setProgress } from '../../../redux/actions/progress';
import styles from './style';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  marginTop: 10,
  background: isDragging ? "#eaeaea" : "#FFF",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#eaeaea" : "#FFF"
});

var timeout = null;

class EditTestScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSaving: false,
      saveTimer: 0,
      value: 0,
      isOpen: false,
      isDeleteOpen: false,
      selectedItem: {},
      testName: '',
      direction: '',
      testTypeId: 1,
      testTypeName: '',
      visibilityId: 0,
      testTypes: [],
      testVisibility: [],
      is_editable: true,
      rationale_expand: [],
      questions: [],
      schools: [],
      group: {},
      sections: [],
      selectedSchools: [],
      showError: false
    }

    this.renderSelectedSchools = this.renderSelectedSchools.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
  }

  componentDidMount() {

    var progress = 0;

    this.props.setProgress(progress);

    fetch('https://api.tasselpathway.com/test_types', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          testTypes: responseJson.types,
          isLoading: false
        });

        progress = progress + 10;
        this.props.setProgress(progress);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        progress = progress + 10;
        this.props.setProgress(progress);
      });

    fetch('https://api.tasselpathway.com/schools/', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          schools: responseJson.results,
          isLoading: false
        });

        progress = progress + 10;
        this.props.setProgress(progress);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        progress = progress + 10;
        this.props.setProgress(progress);
      });

    fetch('https://api.tasselpathway.com/test/' + this.props.match.params.testId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.results.length > 0) {

          this.setState({
            questions: responseJson.results[0].questions,
            direction: responseJson.results[0].direction,
            testName: responseJson.results[0].test_name,
            ownerId: responseJson.results[0].person_id,
            testTypeId: responseJson.results[0].test_type_id,
            testTypeName: responseJson.results[0].test_type_name,
            visibilityId: responseJson.results[0].visibility_id,
            visibilityName: responseJson.results[0].visibility_name,
            isLoading: false
          });

          progress = progress + 20;
          this.props.setProgress(progress);

        } else {

          this.setState({
            isLoading: false,
            showError: true,
            errorMessage: 'Error Opening Test'
          });

          var props = this.props;

          setTimeout(function () { props.history.push('/tests'); }, 1500);

        }

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        progress = progress + 20;
        this.props.setProgress(progress);
      });

    fetch('https://api.tasselpathway.com/test_shares/' + this.props.match.params.testId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        var selectedSchools = []

        responseJson.results.map((item, key) => {
          return selectedSchools.push(item.school_id);
        });

        this.setState({
          selectedSchools: selectedSchools
        });

        progress = progress + 10;
        this.props.setProgress(progress);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
        progress = progress + 10;
        this.props.setProgress(progress);
      });


    fetch('https://api.tasselpathway.com/test_visibility', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.authenticated === false) {
          this.setState({
            isLoading: false,
            testVisibility: []
          });
          this.props.onLogout();
        } else {
          this.setState({
            isLoading: false,
            testVisibility: responseJson.results
          });
        }

        progress = progress + 20;
        this.props.setProgress(progress);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
        progress = progress + 20;
        this.props.setProgress(progress);
        this.props.onLogout();
      });

    fetch('https://api.tasselpathway.com/questions/groups', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.authenticated === false) {
          this.setState({
            isLoading: false,
            sections: [],
            group: {}
          });
          this.props.onLogout();
        } else {
          this.setState({
            isLoading: false,
            sections: responseJson.groups[0].sections,
            group: responseJson.groups[0]
          });
        }

        progress = progress + 30;
        this.props.setProgress(progress);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
        progress = progress + 30;
        this.props.setProgress(progress);
        this.props.onLogout();
      });
  }

  saveTest() {

    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/tests', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        test_id: this.props.match.params.testId,
        test_name: this.state.testName,
        direction: this.state.direction,
        test_type_id: this.state.testTypeId,
        visibility_id: this.state.visibilityId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  deleteTest() {

    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/tests', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        test_id: this.props.match.params.testId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isSaving: false
        });

        this.props.history.push('/tests');

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  addTestSection(question) {

    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/test_sections', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        section_type_id: question.section_type_id,
        choice_type_id: question.choice_type_id,
        sort_order: question.sort_order,
        title: question.title,
        has_multiple_answer: question.has_multiple_answer,
        has_rationale: question.has_rationale,
        likert_start: question.likert_start,
        likert_end: question.likert_end,
        choices: question.choices,
        test_id: this.props.match.params.testId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        question.test_section_id = responseJson.test_section_id;
        question.choices[0].test_choice_id = responseJson.test_choice_id;

        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  removeTestSection(question) {

    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/test_sections', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        test_section_id: question.test_section_id,
        test_id: this.props.match.params.testId
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {


        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  saveTestType(section) {

    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/test_sections/type', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        section_type_id: section.section_type_id,
        choice_type_id: section.choice_type_id,
        sort_order: section.sort_order,
        title: section.title,
        has_multiple_answer: section.has_multiple_answer,
        has_rationale: section.has_rationale,
        likert_start: section.likert_start,
        likert_end: section.likert_end,
        choices: section.choices,
        test_id: this.props.match.params.testId,
        test_section_id: section.test_section_id
      })
    })
      .then((response) => {
        response.json()
      })
      .then((responseJson) => {

        section.choices = responseJson.choices;

        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  saveTestSort(test_section_id, old_sort_order, new_sort_order) {

    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/test_sections/sort', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        test_id: this.props.match.params.testId,
        test_section_id: test_section_id,
        new_sort_order: new_sort_order,
        old_sort_order: old_sort_order
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  saveTestSection(question) {

    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/test_sections', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        section_type_id: question.section_type_id,
        choice_type_id: question.choice_type_id,
        sort_order: question.sort_order,
        title: question.title,
        has_multiple_answer: question.has_multiple_answer,
        has_rationale: question.has_rationale,
        likert_start: question.likert_start,
        likert_end: question.likert_end,
        test_id: this.props.match.params.testId,
        test_section_id: question.test_section_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  addTestOption(choice) {
    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/test_choices', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        test_section_id: choice.test_section_id,
        test_choice_name: choice.test_choice_name,
        sort_order: choice.sort_order
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        choice.test_choice_id = responseJson.test_choice_id;

        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  saveTestChoice(choice) {
    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/test_choices', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        test_choice_id: choice.test_choice_id,
        test_choice_name: choice.test_choice_name,
        sort_order: choice.sort_order
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  removeTestOption(choice) {
    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/test_choices', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        test_choice_id: choice.test_choice_id
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  onDragStart(result) {

    this.state.questions.map((item, key) => {
      if (item.test_section_id === result.draggableId) {
        this.setState({
          selectedItem: item
        })
      }
      return null;
    });
  }

  onDragEnd(result) {

    if (!result.destination) {
      return;
    }

    const questions = reorder(
      this.state.questions,
      result.source.index,
      result.destination.index
    );

    var selectedItem = this.state.selectedItem;

    selectedItem.sort_order = result.destination.index + 1;

    this.setState({
      selectedItem: selectedItem,
      questions: questions
    });

    this.saveTestSort(selectedItem.test_section_id, result.source.index + 1, result.destination.index + 1);
  }

  onRationaleClick(item) {

    var rationale_expand = this.state.rationale_expand;

    if (this.state.rationale_expand['rationale_' + item.test_section_id] === false ||
      this.state.rationale_expand['rationale_' + item.test_section_id] === undefined) {
      rationale_expand['rationale_' + item.test_section_id] = true;
    } else {
      rationale_expand['rationale_' + item.test_section_id] = false;
    }

    this.setState({
      rationale_expand: rationale_expand
    })
  }

  onAddQuestion() {

    var questions = this.state.questions;

    var new_sort_order = 0;

    if (this.state.selectedItem.sort_order !== undefined) {
      new_sort_order = this.state.selectedItem.sort_order + 1;
    }

    questions.map((item, key) => {

      if (item.sort_order >= new_sort_order) {
        item.sort_order = item.sort_order + 1;
      }

      item.is_editable = false;

    });

    var newQuestion = {};
    newQuestion.test_id = this.props.match.params.testId;
    newQuestion.group_type_id = 3;
    newQuestion.group_type_name = "Custom Questions";
    newQuestion.group_type_short_name = "Custom";
    newQuestion.test_section_id = 1;
    newQuestion.section_type_id = 2;
    newQuestion.section_type_name = "Multiple Choice";
    newQuestion.sort_order = new_sort_order;
    newQuestion.title = "";
    newQuestion.has_multiple_answer = 0;
    newQuestion.has_rationale = 1;
    newQuestion.likert_start = 1;
    newQuestion.likert_end = 5;
    newQuestion.choice_type_id = 2;
    newQuestion.choice_type_name = "Multiple Choice";

    var choices = [];

    var newChoice = {};
    newChoice.test_choice_id = 1;
    newChoice.test_choice_name = "Option 1";
    newChoice.sort_order = 1;
    choices.push(newChoice);

    newQuestion.choices = choices;

    questions.splice(new_sort_order, 0, newQuestion);

    this.setState({
      isOpen: true,
      selectedItem: newQuestion,
      questions: questions
    });

    this.addTestSection(newQuestion);
  }

  onDeleteTest() {
    this.setState({
      isDeleteOpen: true
    })
  }

  onChangeOptionName(choice, event) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      item.choices.map((item2, key2) => {
        if (item2.test_choice_id === choice.test_choice_id) {
          item2.test_choice_name = event.target.value;
        }
        return null;
      })
      return null;
    });

    this.setState({
      questions: questions
    });

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTestChoice(choice)
    }, 1000);

  }

  onRemoveOption(testId, choice) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      if (item.test_section_id === testId) {

        item.choices.map((item2, key2) => {
          if (item2.test_choice_id === choice.test_choice_id) {
            item.choices.splice(key2, 1);
            this.removeTestOption(choice);
          }
          return null;
        });
      }
      return null;
    });

    this.setState({
      questions: questions
    })
  }

  onRemoveQuestion(question) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      if (item.test_section_id === question.test_section_id) {
        questions.splice(key, 1);
      }
    });

    this.setState({
      questions: questions
    })

    this.removeTestSection(question);
  }

  onCloseClick() {
    this.setState({
      isOpen: false
    })
  }

  onDeleteCloseClick() {
    this.setState({
      isDeleteOpen: false
    })
  }

  onAddOption(question) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      if (item.test_section_id === question.test_section_id) {

        var newChoice = {};
        newChoice.test_section_id = item.test_section_id;
        newChoice.sort_order = item.choices.length + 1;
        newChoice.test_choice_name = "Option " + newChoice.sort_order.toString()

        item.choices.push(newChoice);

        this.addTestOption(newChoice);
      }
      return null;
    });

    this.setState({
      questions: questions
    })
  }

  onTopCardClick() {

    var questions = this.state.questions;

    questions.map((item, key) => {
      item.is_editable = false;
      return null;
    });

    this.setState({
      questions: questions,
      is_editable: true
    });
  }

  onCardClick(id) {

    var questions = this.state.questions;
    var selected_item = {};

    questions.map((item, key) => {
      if (item.test_section_id === id) {
        item.is_editable = true;
        selected_item = item;
      } else {
        item.is_editable = false;
      }
      return null;
    });

    this.setState({
      selectedItem: selected_item,
      questions: questions,
      is_editable: false
    });
  }

  onChangeTestName(testName) {
    this.setState({
      testName: testName
    });

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTest()
    }, 1000);
  }

  onChangeDirection(direction) {
    this.setState({
      direction: direction
    });

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTest()
    }, 1000);
  }

  onChangeTestType(event) {
    this.setState({
      testTypeId: event.target.value
    })

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTest()
    }, 1000);
  }

  saveTestShare() {

    this.setState({
      isSaving: true
    })

    fetch('https://api.tasselpathway.com/test_shares', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit',
      body: JSON.stringify({
        test_id: this.props.match.params.testId,
        schools: this.state.selectedSchools
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.setState({
          isSaving: false
        });

      })
      .catch((error) => {
        this.setState({
          isSaving: false
        })
      });
  }

  onSchoolChange(event) {

    var list = event.target.value.filter(function (el) {
      return el != null;
    });

    this.setState({ [event.target.name]: list });

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTestShare()
    }, 1000);
  }

  onChangeVisibility(event) {

    var visibilityName = "";

    this.state.testVisibility.map((item, key) => {
      if (event.target.value === item.visibility_id) {
        visibilityName = item.visibility_name
      }
    })

    this.setState({
      visibilityId: event.target.value,
      visibilityName: visibilityName
    })

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTest()
    }, 1000);
  }

  onChangeName(question, event) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      if (item.test_section_id === question.test_section_id) {
        item.title = event.target.value;
      }
      return null;
    });

    this.setState({
      questions: questions
    });

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTestSection(question)
    }, 1000);
  }

  onRationaleChange(question) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      if (item.test_section_id === question.test_section_id) {
        item.has_rationale = !item.has_rationale;
      }
      return null;
    });

    this.setState({
      questions: questions
    });

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTestSection(question)
    }, 1000);
  }

  onMultipleAnswerChange(question) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      if (item.test_section_id === question.test_section_id) {
        item.has_multiple_answer = !item.has_multiple_answer;
      }
      return null;
    });

    this.setState({
      questions: questions
    });

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTestSection(question)
    }, 1000);
  }

  onQuestionTypeClick(group, type) {

    var selectedItem = this.state.selectedItem;

    selectedItem.is_editable = true;
    selectedItem.group_type_id = group.group_type_id;
    selectedItem.group_type_name = group.group_type_name;
    selectedItem.group_type_short_name = group.group_type_short_name;
    selectedItem.question_group_id = group.question_group_id;
    selectedItem.question_group_name = group.question_group_name;
    selectedItem.section_type_id = type.section_type_id;
    selectedItem.section_type_name = type.section_type_name;
    selectedItem.choice_type_id = type.choice_type_id;
    selectedItem.choice_type_name = type.choice_type_name;
    selectedItem.has_rationale = 1;
    selectedItem.likert_start = 1;
    selectedItem.likert_end = 5;
    selectedItem.has_multiple_answer = 0;
    selectedItem.title = "Question";

    var choices = [];

    type.choices.map((item, key) => {

      var newChoice = {};
      newChoice.test_choice_id = item.choice_default_id;
      newChoice.test_choice_name = item.choice_name;
      newChoice.sort_order = item.sort_order;
      choices.push(newChoice);
      return null;
    })

    selectedItem.choices = choices;

    this.setState({
      selectedItem: selectedItem,
      isOpen: false
    });

    this.saveTestType(selectedItem);
  }

  onCardHover(id, status) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      if (item.test_section_id === id) {
        item.is_hover = status;
      } else {
        item.is_hover = false;
      }
      return null;
    });

    this.setState({
      questions: questions
    });
  }

  renderQuestions() {

    if (this.state.questions === undefined) return null;

    if (this.state.questions.length === 0) return null;

    var content = this.state.questions.map((item, key) => {
      return (
        <div key={key}>
          <Draggable draggableId={item.test_section_id.toString()} index={key}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <Card raised={item.is_editable} style={{ padding: 20, width: 800 }}
                  onMouseOver={() => this.onCardHover(item.test_section_id, true)}
                  onMouseOut={() => this.onCardHover(item.test_section_id, false)}
                  onClick={() => this.onCardClick(item.test_section_id)}>
                  <div style={{ textAlign: 'center', cursor: 'row-resize', padding: 0, margin: 0 }}>
                    {item.is_hover ?
                      <FontAwesomeIcon style={{ fontSize: 20, cursor: 'row-resize', color: '#25D192' }} icon={faArrowsAltV} />
                      :
                      <div style={{ height: 19 }}></div>
                    }
                  </div>

                  <CardContent>

                    <div>
                      {item.is_editable ?

                        <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
                          <Grid container>
                            <Grid item xs={8}>
                              <TextField style={{ width: '100%' }}
                                placeholder="Question"
                                onChange={(event) => this.onChangeName(item, event)}
                                value={item.title}
                                InputProps={{
                                  classes: {
                                    input: this.props.classes.questionStyle
                                  }
                                }}
                                autoFocus={true}
                                margin="normal" />
                            </Grid>
                            <Grid item xs={4}>
                              <Button style={{ marginTop: 20, textAlign: 'right', marginLeft: 20, cursor: 'pointer', backgroundColor: '#e0e0e0' }}
                                onClick={() => this.onChangeQuestionType(item)}>
                                {item.group_type_short_name + ": " + item.choice_type_name}
                                <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faChevronDown} />
                              </Button>
                            </Grid>
                          </Grid>

                        </div>

                        :

                        <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
                          <Grid container>
                            <Grid item xs={11}>
                              <Typography style={{ fontSize: 20, marginBottom: 20 }}>{item.title === "" ? "Question" : item.title}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton style={{ textAlign: 'right', marginTop: -67, marginLeft: 40 }}  >
                                <FontAwesomeIcon style={{ color: '#25D192', fontSize: 20 }} icon={faPencilAlt} />
                              </IconButton>
                            </Grid>
                          </Grid>

                        </div>

                      }

                      {this.renderChoices(item)}

                    </div>
                  </CardContent>
                  {item.has_rationale ?
                    <CardActions>
                      <Grid container>
                        <Grid style={{ borderTop: "1px solid #eaeaea" }} container spacing={2}>
                          <Grid item xs={11}>
                            <Button onClick={() => this.onRationaleClick(item)}>
                              <Typography style={{ color: '#2A2042', fontWeight: 'bold' }}>RATIONALE</Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton onClick={() => this.onRationaleClick(item)}>
                              {this.state.rationale_expand['rationale_' + item.test_section_id] === true ?
                                <KeyboardArrowUpIcon style={{ fontSize: 18, padding: 0 }} /> :
                                <KeyboardArrowDownIcon style={{ fontSize: 18, padding: 0 }} />
                              }
                            </IconButton>
                          </Grid>
                        </Grid>
                        {this.state.rationale_expand['rationale_' + item.test_section_id] === true ?
                          <Grid item xs={12} style={{ marginTop: 10, height: 120, marginBottom: 10, marginLeft: 2, marginRight: 2 }}>
                            <TextField
                              multiline={true}
                              rows={4} style={{ width: '100%', padding: 10, fontSize: 16, border: '1px solid #eaeaea' }}
                              InputProps={{ disableUnderline: true }} />
                          </Grid>
                          : null}
                      </Grid>
                    </CardActions> : null}
                  {item.is_editable ?
                    <CardActions style={{ borderTop: '1px solid #eaeaea' }} >
                      <Grid container>
                        <Grid item xs={3}>
                          {(item.choice_type_id === 2 && (item.group_type_id === 2 || item.group_type_id === 3)) ?
                            <FormControlLabel onClick={() => this.onMultipleAnswerChange(item)}
                              value="has_multiple_answer"
                              control={
                                <Switch color="primary" checked={item.has_multiple_answer} />
                              }
                              label="Multiple Answer"
                              labelPlacement="start"
                            />
                            : null}
                        </Grid>
                        <Grid item xs={9} style={{ justifyContent: 'flex-end', textAlign: 'right', alignContent: 'flex-end', alignItems: 'flex-end' }}>
                          <IconButton className={this.props.button} style={{ padding: 14 }} onClick={() => this.onRemoveQuestion(item)}>
                            <FontAwesomeIcon style={{ fontSize: 20, color: '#333' }}
                              icon={faTrash} />
                          </IconButton>
                          <FormControlLabel onClick={() => this.onRationaleChange(item)}
                            value="has_rationale"
                            control={
                              <Switch color="primary" checked={item.has_rationale} />
                            }
                            label="Add Rationale"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                    </CardActions> : null
                  }
                </Card>
              </div>
            )}
          </Draggable>
        </div>
      );

    });

    return content;
  }

  renderMultipleChoice(list) {

    var content = list.choices.map((item, key) => {
      return (
        <div key={key} style={styles.scoreView}>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={1}>
                {list.has_multiple_answer ?
                  <Checkbox disabled />
                  :
                  <Radio disabled />
                }
              </Grid>
              <Grid item xs={10}>
                {list.is_editable === true ?
                  <TextField style={{ width: '100%' }}
                    placeholder="Option 1" autoFocus={true}
                    onFocus={event => event.target.select()}
                    onChange={(event) => this.onChangeOptionName(item, event)}
                    value={item.test_choice_name} />
                  :
                  <Typography style={{ fontSize: 16, marginTop: 7 }}>{item.test_choice_name}</Typography>
                }
              </Grid>
              <Grid item xs={1}>
                {list.choices.length > 1 && list.is_editable === true ?
                  <FontAwesomeIcon onClick={() => this.onRemoveOption(list.test_section_id, item)}
                    style={{ color: '#e0e0e0', marginTop: 15, marginLeft: 10, fontSize: 20 }} icon={faTimes} />
                  : null
                }
              </Grid>
            </Grid>
          </Grid>
        </div>
      )
    })
    return content;
  }

  onLikertStartChange(e, question) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      if (item.test_section_id === question.test_section_id) {
        item.likert_start = e.target.value
      }
      return null;
    });

    this.setState({
      questions: questions
    });

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTestSection(question)
    }, 1000);
  }

  onLikertEndChange(e, question) {

    var questions = this.state.questions;

    questions.map((item, key) => {
      if (item.test_section_id === question.test_section_id) {
        item.likert_end = e.target.value
      }
      return null;
    });

    this.setState({
      questions: questions
    });

    clearTimeout(timeout);

    var scope = this;

    timeout = setTimeout(function () {
      scope.saveTestSection(question)
    }, 1000);
  }

  renderLikert(item) {

    var content = [];

    for (var i = item.likert_start; i <= item.likert_end; i++) {
      content.push(
        <FormControlLabel style={{ margin: 0, padding: 10 }}
          control={
            <Radio disabled />
          }
          label={i}
          labelPlacement="top" />
      )
    }

    return content;

  }

  renderChoices(item) {

    if (item.choice_type_id === 1) {
      // Likert Scale
      return (
        <div style={{
          flex: 1, flexDirection: 'row', marginTop: 20,
          paddingTop: 10, paddingBottom: 10, alignItems: 'center'
        }}>
          {item.is_editable === true ?
            <Grid container>
              <Grid item xs={1}>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={item.likert_start}
                    onChange={(event) => this.onLikertStartChange(event, item)}
                    style={{ width: '100%' }}
                    inputProps={{
                      name: 'likertStart',
                      id: 'likertStart',
                    }}>

                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'center', marginTop: 5 }}>
                <Typography>to</Typography>
              </Grid>
              <Grid item xs={1}>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    value={item.likert_end}
                    onChange={(event) => this.onLikertEndChange(event, item)}
                    style={{ width: '100%' }}
                    inputProps={{
                      name: 'likertEnd',
                      id: 'likertEnd',
                    }}>

                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9}>

              </Grid>
            </Grid> :

            <Grid container style={{ textAlign: 'center', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
              {this.renderLikert(item)}
            </Grid>
          }
        </div>
      );

    } else if (item.choice_type_id === 2) {
      // Multiple Choice - District Rubric & Custom
      return (
        <div style={styles.scoreView}>
          <div>
            {this.renderMultipleChoice(item)}
            {item.is_editable === true ?
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Grid container>
                  <Grid item xs={1}>
                    {item.has_multiple_answer ?
                      <Checkbox disabled /> :
                      <Radio disabled />}
                  </Grid>
                  <Grid item xs={10}>
                    <Typography onClick={() => this.onAddOption(item)}
                      style={{ color: '#e0e0e0', fontSize: 16, marginTop: 10 }}>Add option</Typography>
                  </Grid>
                  <Grid item xs={1}>

                  </Grid>
                </Grid>
              </Grid> : null}
          </div>
        </div>
      );
    } else if (item.choice_type_id === 3) {
      // True / False
      return (
        <div style={{ paddingTop: 10, marginTop: 10, marginLeft: 40, paddingBottom: 10, alignItems: 'center' }}>
          <RadioGroup >
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Radio disabled />
                  }
                  label={item.choices[0].test_choice_name}
                  labelPlacement="end" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Radio disabled />
                  }
                  label={item.choices[1].test_choice_name}
                  labelPlacement="end" />
              </Grid>
            </Grid>
          </RadioGroup>
        </div>
      );
    } else if (item.choice_type_id === 4) {
      // Open Ended
      return (
        <Grid style={{ marginBottom: 10, marginTop: 10 }}>
          <TextField
            multiline={true}
            placeholder={"Open Ended Question"}
            rows={4} style={{ width: '100%', padding: 10, fontSize: 16, border: '1px solid #eaeaea' }}
            InputProps={{ disableUnderline: true }} />
        </Grid>
      );
    }

    return null;
  }

  onChangeQuestionType(item) {
    this.setState({
      isOpen: true,
      selectedItem: item
    });
  }

  renderSelectedSchools() {

    if (this.state.selectedSchools === undefined || this.state.selectedSchools === []) return null;

    if (this.state.schools === undefined || this.state.schools === []) return null;

    const content = this.state.schools.map((item, key) => {

      if (this.state.selectedSchools.map(function (e) { return e; }).indexOf(item.school_id) > -1) {

        return (
          <Chip style={{ marginRight: 10 }} key={item.school_id} label={item.school_name} className={this.props.classes.chip} />
        )

      }
    })
    return content;

  }

  renderSchools() {

    if (this.state.schools === undefined) return null;

    const content = this.state.schools.map((item, key) => {
      return (
        <MenuItem key={key} value={item.school_id}>
          <Checkbox checked={this.state.selectedSchools.indexOf(item.school_id) > -1} />
          <ListItemText primary={item.school_name} />
        </MenuItem>
      )
    })
    return content;
  }

  renderSchoolList() {

    var schools = "";

    this.state.schools.map((item, key) => {
      if (this.state.selectedSchools.indexOf(item.school_id) > -1) {
        if (schools === "") {
          schools = schools + item.school_name;
        } else {
          schools = schools + ", " + item.school_name;
        }
      }
    })

    return schools;
  }

  render() {

    const { classes } = this.props;

    return (

      <div className={classes.root}>

        <Snackbar
          classes={{
            root: classes.error
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          autoHideDuration={5000}
          onClose={this.onCloseError}
          open={this.state.showError}>
          <SnackbarContent style={{ backgroundColor: '#D55648' }}
            message={
              <span style={styles.message}>
                {this.state.errorMessage}
              </span>
            }
            classes={{
              root: classes.error
            }}
            action={
              <IconButton key="close" onClick={this.onCloseError} className={classes.content}>
                <CloseIcon style={{ color: '#FFF' }} />
              </IconButton>
            }>
          </SnackbarContent>
        </Snackbar>

        <Dialog
          open={this.state.isDeleteOpen}
          onClose={() => this.onDeleteCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Delete Test"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>Are you sure you want to delete this test?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.deleteTest()} variant="contained" style={{ backgroundColor: '#D9534F' }} >
              <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>Yes</Typography>
            </Button>
            <Button onClick={() => this.onDeleteCloseClick()} variant="contained" color="primary" autoFocus>
              <Typography style={{ fontWeight: 'bold', color: '#FFF' }}>No</Typography>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.isOpen} fullWidth={true}
          onClose={() => this.onCloseClick()} maxWidth={'xs'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">

            {"Select Question Type"}

            <IconButton aria-label="Close" className={classes.closeButton} onClick={() => this.onCloseClick()}>
              <CloseIcon />
            </IconButton>

          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">

              <br />

              <Grid container>

                <Grid item xs={12}>
                  {this.state.sections.map((item, key) => {
                    return (
                      <ListItem key={key} role={undefined} dense button
                        onClick={() => this.onQuestionTypeClick(this.state.group, item)}
                        selected={item.section_type_id === this.state.selectedItem.section_type_id ? true : false}>
                        <ListItemIcon>
                          <Radio
                            edge="start"
                            checked={item.section_type_id === this.state.selectedItem.section_type_id ? true : false}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText>
                          {item.section_type_name}
                        </ListItemText>
                      </ListItem>
                    )
                  }
                  )}
                </Grid>

              </Grid>

            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Grid container>
          <Grid container justify="center">
            <Grid item xs={1}>

            </Grid>
            <Grid item xs={4} style={{ textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={2}>
                  <IconButton variant="contained" style={{ marginTop: 20, marginLeft: 10 }}
                    onClick={() => this.props.history.goBack()}
                    color="secondary" aria-label="Delete" className={this.props.classes.margin}>
                    <ArrowBackIcon className={this.props.classes.extendedIcon} style={{ color: '#1F3545' }} />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <Typography style={{ marginTop: 22, fontSize: 24 }}>{this.state.testName}</Typography>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={2}>
              {this.props.saving ?
                <Grid container>
                  <Grid item xs={2}>
                    <CircularProgress style={{ height: 20, width: 20, marginTop: 32 }} className={classes.progress} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography style={{ marginTop: 30, fontSize: 16, textOverflow: 'ellipsis' }} noWrap={true}>Saving Changes</Typography>
                  </Grid>
                </Grid>
                :
                <Grid container>
                  <Grid item xs={2}>

                  </Grid>
                  <Grid item xs={10}>
                    <Typography style={{ marginTop: 30, fontSize: 16, textOverflow: 'ellipsis' }} noWrap={true} >All Changes Saved</Typography>
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid item xs={2}>

              <IconButton variant="contained" style={{ marginTop: 20, marginLeft: 10 }}
                onClick={() => this.onAddQuestion()}
                color="secondary" aria-label="Add" className={this.props.classes.margin}>
                <AddIcon className={this.props.classes.extendedIcon} style={{ color: '#1F3545' }} />
              </IconButton>

              {this.props.personId === this.state.ownerId ?
                <IconButton variant="contained" style={{ marginTop: 20, marginLeft: 10 }}
                  onClick={() => this.onDeleteTest()}
                  color="secondary" aria-label="Delete" className={this.props.classes.margin}>
                  <DeleteIcon className={this.props.classes.extendedIcon} style={{ color: '#1F3545' }} />
                </IconButton> : null}
            </Grid>

          </Grid>

        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid>
                <div style={{ marginTop: 20, marginBottom: 10 }}>
                  <Card style={{ width: 800 }} onClick={() => this.onTopCardClick()}>
                    <CardContent>
                      <div style={styles.titleView}>
                        <div>
                          {this.state.is_editable === true ?
                            <FormControl className={classes.formControl} style={{ width: '100%' }}>
                              <TextField style={{ width: '100%' }}
                                placeholder="Test Name"
                                autoFocus={true}
                                InputProps={{
                                  classes: {
                                    input: classes.testNameStyle
                                  }
                                }}
                                onChange={(event) => this.onChangeTestName(event.target.value)}
                                value={this.state.testName} />
                            </FormControl>
                            :
                            <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
                              <Grid container>
                                <Grid item xs={11}>
                                  <Typography style={{ fontSize: 20, marginTop: 7 }}>{this.state.testName}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton style={{ textAlign: 'right', marginLeft: 30 }}  >
                                    <FontAwesomeIcon style={{ color: '#25D192', fontSize: 20 }} icon={faPencilAlt} />
                                  </IconButton>
                                </Grid>
                              </Grid>

                            </div>
                          }
                        </div>


                        <Grid container style={{ marginTop: 20 }}>
                          <Grid item xs={6}>
                            {this.state.is_editable === true ?
                              <FormControl className={classes.formControl} style={{ width: '100%' }}>
                                <InputLabel htmlFor="testType">Test Type</InputLabel>
                                <Select
                                  value={this.state.testTypeId}
                                  onChange={(event) => this.onChangeEvaluationType(event)}
                                  style={{ width: '100%', marginTop: 20 }}
                                  inputProps={{
                                    name: 'testType',
                                    id: 'testType',
                                  }}>
                                  {
                                    this.state.testTypes.map((item, key) => {
                                      return (<MenuItem key={key} value={item.test_type_id}>{item.test_type_name}</MenuItem>)
                                    })
                                  }
                                </Select>
                              </FormControl>
                              :
                              <Typography style={{ fontSize: 16, marginTop: 7 }}><Typography style={{ fontWeight: 'bold' }}>Test Type:</Typography> {this.state.testTypeName}</Typography>
                            }
                          </Grid>
                          <Grid item xs={6}>
                            {this.state.is_editable === true ?
                              <FormControl className={classes.formControl} fullWidth style={{ paddingLeft: 10 }}>
                                <InputLabel style={{ marginLeft: 10 }} htmlFor="visibilityId">Visibility</InputLabel>
                                <Select
                                  value={this.state.visibilityId}
                                  onChange={(event) => this.onChangeVisibility(event)}
                                  style={{ width: '100%', marginTop: 20 }}
                                  inputProps={{
                                    name: 'visibilityId',
                                    id: 'visibilityId',
                                  }}>
                                  {
                                    this.state.testVisibility.map((item, key) => {
                                      return (<MenuItem key={key} value={item.visibility_id}>{item.visibility_name}</MenuItem>)
                                    })
                                  }
                                </Select>
                              </FormControl>
                              :
                              <Typography style={{ fontSize: 16, marginTop: 7 }}><Typography style={{ fontWeight: 'bold' }}>Test Visibility:</Typography> {this.state.visibilityName}</Typography>
                            }
                          </Grid>
                        </Grid>
                        {this.state.visibilityId === 2 ?
                          <div style={styles.titleView}>
                            <div>
                              {this.state.is_editable === true ?
                                <FormControl fullWidth className={classes.formControl}>
                                  <InputLabel>School</InputLabel>
                                  <Select
                                    multiple
                                    value={this.state.selectedSchools}
                                    onChange={(evt) => this.onSchoolChange(evt)}
                                    input={<Input name="selectedSchools" id="selectedSchools" />}
                                    renderValue={this.renderSelectedSchools}>
                                    {this.renderSchools()}
                                  </Select>
                                </FormControl>
                                :
                                <Typography style={{ fontSize: 16, marginTop: 7 }}><Typography style={{ fontWeight: 'bold' }}>Schools:</Typography> {this.renderSchoolList()} </Typography>
                              }
                            </div>
                          </div> : null}
                        <div style={styles.titleView}>
                          <div>
                            {this.state.is_editable === true ?
                              <TextField multiline={true} rows={4}
                                placeholder={"Directions"}
                                onChange={(event) => this.onChangeDirection(event.target.value)}
                                value={this.state.direction}
                                style={{ width: '100%' }}
                                margin="normal" />
                              :
                              <Typography style={{ fontSize: 16, height: 50, marginTop: 7 }}><Typography style={{ fontWeight: 'bold' }}>Direction:</Typography>{this.state.direction}</Typography>
                            }
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                  {this.state.questions === undefined || this.state.questions.length === 0 ?

                    <Grid container spacing={2}
                      style={{ justifyContent: 'center', textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>

                      <Grid item xs={12} style={{ marginLeft: 20, marginTop: 40, marginRight: 20 }}>

                        <Button variant="contained" color="primary"
                          onClick={() => this.onAddQuestion()} style={{ marginTop: 20 }}>
                          <Typography style={{ fontWeight: 'bold', fontSize: 18, color: '#FFF' }}>Add New Question</Typography>
                        </Button>

                      </Grid>

                    </Grid>

                    :

                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>

                            {this.renderQuestions()}

                            <Grid container spacing={2}
                              style={{ justifyContent: 'center', textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>

                              <Grid item xs={12} style={{ marginLeft: 20, marginTop: 40, marginRight: 20 }}>

                                <Button variant="contained" color="primary"
                                  onClick={() => this.onAddQuestion()} style={{ marginTop: 20 }}>
                                  <Typography style={{ fontWeight: 'bold', fontSize: 18, color: '#FFF' }}>Add New Question</Typography>
                                </Button>

                              </Grid>

                            </Grid>

                            <div style={{ height: 50 }} />

                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                  }

                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

EditTestScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    personId: state.auth.personId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProgress: (progress) => { dispatch(setProgress(progress)); },
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditTestScreen));