import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { login } from '../../../redux/actions/auth';
import styles from './style.js';

class NewUserScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            confirmPassword: '',
            updatedPassword: false,
            isLoading: false,
            tokenExpired: false,
            tokenInvalid: false,
            passwordMatch: false,
            passwordLength: false,
            passwordCapital: false,
            passwordNumber: false,
            passwordSpecial: false,
            passwordValid: false
        };
    }

    componentDidMount() {

        fetch('https://api.tasselpathway.com/register/invite', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                invite_token: this.props.match.params.token
            }),
            credentials: 'omit'
        })
            .then(response => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        personId: responseJson.person_id,
                        districtId: responseJson.district_id,
                        roleId: responseJson.user_role_id,
                        firstName: responseJson.first_name,
                        lastName: responseJson.last_name,
                        username: responseJson.email_address,
                        isLoading: false,
                        tokenExpired: false,
                        tokenInvalid: false
                    });
                } else if (responseJson.message === 'Invite Token Expired!') {
                    this.setState({
                        isLoading: false,
                        tokenExpired: true,
                        tokenInvalid: false
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        tokenExpired: false,
                        tokenInvalid: true
                    });
                }
            })
            .catch((e) => {

            });
    }

    createAccount() {

        fetch('https://api.tasselpathway.com/register/user', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                invite_token: this.props.match.params.token,
                person_id: this.state.personId,
                district_id: this.state.districtId,
                role_id: this.state.roleId,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                user_name: this.state.username,
                password: this.state.password
            })
        })
            .then(response => {
                return response.json()
            })
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        createdUser: true,
                        isLoading: false,
                        tokenExpired: false,
                        tokenInvalid: false
                    });
                    this.props.history.replace('/');
                    this.props.onLogin(this.state.username, this.state.password, this.props.selectedLanguage);

                }
            })
            .catch((e) => {
                console.warn(e);
            });
    }

    getBackgroundImage() {

    }

    renderLogoImage() {
        return null;
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    onChangePassword(event) {

        this.validatePassword(this.state.confirmPassword, event.target.value);

        this.setState({
            password: event.target.value
        })
    }

    onChangeConfirmPassword(event) {

        this.validatePassword(event.target.value, this.state.password)

        this.setState({
            confirmPassword: event.target.value
        })
    }

    validatePassword(confirmPassword, password) {

        var isValid = true;

        if (confirmPassword === password && password !== "" && confirmPassword !== "") {
            this.setState({
                passwordMatch: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordMatch: false
            })
        }

        if (password.length >= 8) {
            this.setState({
                passwordLength: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordLength: false
            })
        }

        var capital = /[A-Z]/;
        var number = /[0-9]/;
        var special = /[!@#$%\^&*)(+=._-]/;

        if (password.match(capital)) {
            this.setState({
                passwordCapital: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordCapital: false
            })
        }

        if (password.match(number)) {
            this.setState({
                passwordNumber: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordNumber: false
            })
        }

        if (password.match(special)) {
            this.setState({
                passwordSpecial: true
            })
        } else {
            isValid = false;
            this.setState({
                passwordSpecial: false
            })
        }

        if (isValid) {
            this.setState({
                passwordValid: true
            })
        } else {
            this.setState({
                passwordValid: false
            })
        }
    }

    render() {

        const { classes } = this.props;

        if (this.state.tokenInvalid === true) {
            return (
                <Grid container spacing={0}
                    direction="column" alignItems="center"
                    justify="center" style={{ backgroundColor: '#F3F3F4' }}>

                    <Grid style={styles.container}>

                        <img width="100" src={require('../../../assets/logo_icon.png')} alt="Tassel" />

                        <h3 style={{ color: '#2A2042', fontSize: 24 }}>Invalid Token Provided!</h3>

                        <h4>The token you provided is no longer valid.</h4>

                    </Grid>

                    <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, color: '#25D192' }}>
                        © 2019 Owen Technologies, LLC
                    </Grid>

                </Grid>
            )
        }

        else if (this.state.tokenExpired === true) {
            return (
                <Grid container spacing={0}
                    direction="column" alignItems="center"
                    justify="center" style={{ backgroundColor: '#1F3545' }}>

                    <Grid style={styles.container}>

                        <img width="100" src={require('../../../assets/logo_icon.png')} alt="Tassel" />

                        <h3 style={{ color: '#2A2042', fontSize: 24 }}>Your Invite Link Has Expired!</h3>

                        <h4>The password reset link is no longer valid. Please request another invitation for your supervisor.</h4>

                    </Grid>

                    <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, fontWeight: 'bold', color: '#FFF' }}>
                        © 2019 Tassel, LLC | <a style={{ color: '#FFF' }} target="_blank" rel="noopener noreferrer" href="https://www.tasselpathway.com/terms">Terms of Use</a>
                    </Grid>

                </Grid>
            )
        } else {

            return (
                <Grid container spacing={0}
                    direction="column" alignItems="center"
                    justify="center" style={{ backgroundColor: '#1F3545' }}>

                    <Grid style={styles.container}>

                        <img width="100" src={require('../../../assets/logo_icon.png')} alt="Tassel" />

                        <h3 style={{ color: '#2A2042', fontSize: 24 }}>Create Tassel Account</h3>

                        <form style={{ marginTop: 40 }}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField id="input-with-icon-adornment" label="Username"
                                    style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                    value={this.state.username}
                                    variant="outlined"
                                    contentEditable={false}
                                    className={classes.textField} />
                            </FormControl>

                            <FormControl style={{ width: '100%' }}>
                                <TextField id="input-with-icon-adornment" label="Password"
                                    style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                    autoFocus={true}
                                    variant="outlined"
                                    type="password"
                                    className={classes.textField}
                                    onChange={(event) => this.onChangePassword(event)} />
                            </FormControl>

                            <FormControl style={{ width: '100%' }}>
                                <TextField id="input-with-icon-adornment" label="Confirm Password"
                                    style={{ width: '100%', paddingBottom: 10, marginBottom: 10 }}
                                    variant="outlined"
                                    type="password"
                                    className={classes.textField}
                                    onChange={(event) => this.onChangeConfirmPassword(event)} />
                            </FormControl>

                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid container>
                                        {this.state.passwordCapital ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>Capital Letter</Typography>
                                    </Grid>
                                    <Grid container>
                                        {this.state.passwordNumber ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>Number</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        {this.state.passwordSpecial ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>Special Character</Typography>
                                    </Grid>
                                    <Grid container>
                                        {this.state.passwordLength ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>8+ Characters</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid container>
                                        {this.state.passwordMatch ?
                                            <CheckIcon style={{ color: '#25D192' }} /> :
                                            <CloseIcon style={{ color: '#D55648' }} />}
                                        <Typography style={{ marginLeft: 10, fontSize: 16 }}>Passwords Match</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>

                                </Grid>
                            </Grid>

                            <Grid style={{ marginTop: 20, textAlign: 'center' }} >
                                <Button disabled={this.state.passwordValid !== true ? true : false}
                                    style={styles.resetButton} onClick={() => this.createAccount()}>
                                    Create Account
                                </Button>
                            </Grid>

                        </form>

                    </Grid>

                    <Grid style={{ textAlign: 'center', marginTop: 30, fontSize: 14, fontWeight: 'bold', color: '#FFF' }}>
                        © 2019 Tassel, LLC | <a style={{ color: '#FFF' }} target="_blank" rel="noopener noreferrer" href="https://www.tasselpathway.com/terms">Terms of Use</a>
                    </Grid>

                </Grid>

            )
        }
    }
}

NewUserScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        language: state.language.selectedLanguage,
        hasError: state.auth.hasError,
        errorMessage: state.auth.errorMessage
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (username, password, language) => { dispatch(login(username, password, language)); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(NewUserScreen)));