export default {
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 8
    },
    linkTag:{
        marginLeft:10
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 20
    }
}

