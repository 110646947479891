import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Card, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class WorkScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      newNameValid: true,
      editNameValid: true,
      workTypes: [],
      leadershipRoles: [],
      selectedItem: {},
      work_learning_date: new Date(),
      pathways: [],
      pathway_id: 0,
      type_id: 0
    }

    this.createWork = this.createWork.bind(this);
    this.saveWork = this.saveWork.bind(this);
    this.onNewOpen = this.onNewOpen.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onNewClick = this.onNewClick.bind(this);
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  componentDidMount() {
    this.getData(this.props.profileId);

    fetch('https://api.tasselpathway.com/profile_work_learning_type', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            workTypes: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/pathway', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            pathways: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

    fetch('https://api.tasselpathway.com/profile_leadership_role', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            leadershipRoles: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  getData(personId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_work_learning/person/' + personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  createWork() {
    fetch('https://api.tasselpathway.com/profile_work_learning/', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        person_id: this.props.personId,
        work_learning_type_id: this.state.work_learning_type_id,
        work_learning_description: this.state.work_learning_description,
        school_sponsor: this.state.school_sponsor,
        business_sponsor: this.state.business_sponsor,
        is_paid: this.state.is_paid,
        skills_learned: this.state.skills_learned,
        pathway_id: this.state.pathway_id,
        work_learning_date: this.state.work_learning_date,
        work_learning_location: this.state.work_learning_location,
        work_learning_hours: this.state.work_learning_hours
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.getData(this.props.personId);

      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  saveWork() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_work_learning', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        work_learning_type_id: this.state.selectedItem.work_learning_type_id,
        work_learning_description: this.state.selectedItem.work_learning_description,
        school_sponsor: this.state.selectedItem.school_sponsor,
        business_sponsor: this.state.selectedItem.business_sponsor,
        is_paid: this.state.selectedItem.is_paid,
        skills_learned: this.state.selectedItem.skills_learned,
        pathway_id: this.state.selectedItem.pathway_id,
        work_learning_date: this.state.selectedItem.work_learning_date,
        work_learning_location: this.state.selectedItem.work_learning_location,
        work_learning_hours: this.state.selectedItem.work_learning_hours,
        work_learning_id: this.state.selectedItem.work_learning_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.personId);

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  deleteService() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_work_learning', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        work_learning_id: this.state.selectedItem.work_learning_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.personId);

          this.setState({
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  renderServices() {

    var content = this.state.dataSource.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {

        return (
          <ListItem key={key} button alignItems="flex-start"
            onClick={() => this.onEditOpen(item)}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                    className={this.props.classes.inline} color="textPrimary">
                    {item.work_learning_location} - {moment(item.work_learning_date).format('MM/DD/YYYY')}
                  </Typography>
                  <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.work_learning_type} - {item.pathway_name_spanish}</Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        )
      } else {
        return (
          <ListItem key={key} button alignItems="flex-start"
            onClick={() => this.onEditOpen(item)}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }} component="span"
                    className={this.props.classes.inline} color="textPrimary">
                    {item.work_learning_location} - {moment(item.work_learning_date).format('MM/DD/YYYY')}
                  </Typography>
                  <Typography style={{ fontSize: 12, color: '#1F3545' }}>{item.work_learning_type} - {item.pathway_name}</Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        )
      }
    })

    return content;
  }

  onNewOpen() {
    this.setState({
      work_learning_id: 0,
      work_learning_type_id: 0,
      work_learning_description: '',
      school_sponsor: '',
      business_sponsor: '',
      is_paid: 0,
      skills_learned: '',
      pathway_id: 0,
      work_learning_date: '',
      work_learning_location: '',
      work_learning_hours: 0,
      isNewOpen: true
    })
  }

  onNewClose() {

    this.setState({
      isNewOpen: false
    })
  }

  onNewClick() {

    this.createWork();

    this.setState({
      isNewOpen: false
    })
  }

  onEditClose() {

    this.setState({
      isEditOpen: false
    })
  }

  onEditOpen(item) {
    this.setState({
      selectedItem: item,
      isEditOpen: true
    })
  }

  onEditClick() {

    this.saveWork();

    this.setState({
      isEditOpen: false
    })
  }

  onDeleteClick() {
    this.deleteService();

    this.setState({
      isEditOpen: false
    })
  }

  onRemoveClick() {

    this.setState({
      isNewOpen: false
    })
  }

  onWorkLearningTypeId(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.work_learning_type_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onLocationChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.work_learning_location = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onHoursChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.work_learning_hours = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onPathwayChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.pathway_id = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDateChange(date) {
    var selectedItem = this.state.selectedItem;
    selectedItem.work_learning_date = date;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onPaidChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.is_paid = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onSchoolSponsorChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.school_sponsor = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onBusinessSponsorChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.business_sponsor = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }


  onSkillsChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.skills_learned = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onDescriptionChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.work_learning_description = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  renderWorkTypes() {

    var content = this.state.workTypes.map((item, key) => {
      return (
        <MenuItem key={key} value={item.work_learning_type_id}>{item.work_learning_type}</MenuItem>
      )
    })

    return content;
  }

  renderPathways() {

    var content = this.state.pathways.map((item, key) => {
      if (this.props.selectedLanguage === "esp") {

        return (
          <MenuItem key={key} value={item.pathway_id}>{item.pathway_name_spanish}</MenuItem>
        )
      } else {
        return (
          <MenuItem key={key} value={item.pathway_id}>{item.pathway_name}</MenuItem>
        )
      }
    })

    return content;
  }

  render() {

    const { classes } = this.props;

    return (
      <div>

        <div className={classes.root}>
          <Dialog open={this.state.isNewOpen}>
            <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
              {i18n.t("WBL_NEW")}
            </DialogTitle>

            <DialogContent>

              <div>

                <Grid container>

                  <Grid item xs={12}>

                    <Grid container spacing={10}>

                      <Grid item xs={6}>

                        <FormControl className={classes.formControl}
                          fullWidth>
                          <InputLabel htmlFor="workType">{i18n.t("WBL_SELECT_TYPE")}</InputLabel>
                          <Select variant='standard'
                            value={this.state.work_learning_type_id}
                            onChange={(event) => this.setState({ work_learning_type_id: event.target.value })}
                            inputProps={{
                              name: 'workType',
                              id: 'workType',
                            }}>
                            <MenuItem disabled value={0}>{i18n.t("WBL_SELECT_TYPE")}</MenuItem>
                            {this.renderWorkTypes()}
                          </Select>
                        </FormControl>

                      </Grid>

                      <Grid item xs={6}>
                        {this.props.selectedLanguage === "esp" ?
                          <FormControl fullWidth
                            className={classes.formControl}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker error={false}
                                label="Fecha del evento"
                                format={"MM/DD/YYYY"}
                                value={this.state.date}
                                renderInput={(params) => <TextField variant='standard' {...params} />}
                                onChange={(date) => this.setState({ date: date })}
                                showTodayButton />
                            </LocalizationProvider>
                          </FormControl>
                          :
                          <FormControl fullWidth
                            className={classes.formControl}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker error={false}
                                label="WBL Event Date"
                                format={"MM/DD/YYYY"}
                                value={this.state.date}
                                renderInput={(params) => <TextField variant='standard' {...params} />}
                                onChange={(date) => this.setState({ date: date })}
                                showTodayButton />
                            </LocalizationProvider>
                          </FormControl>
                        }

                      </Grid>

                    </Grid>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl className={classes.formControl} style={{ marginTop: 15, marginBottom: 15 }}
                      fullWidth>
                      <InputLabel htmlFor="pathway">{i18n.t("WBL_PATHWAY")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.pathway_id}
                        onChange={(event) => this.setState({ pathway_id: event.target.value })}
                        inputProps={{
                          name: 'pathway',
                          id: 'pathway',
                        }}>
                        <MenuItem disabled value={0}>{i18n.t("WBL_PATHWAY")}</MenuItem>
                        {this.renderPathways()}
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={6}>

                    <FormControl>
                      <InputLabel htmlFor="work_learning_location">{i18n.t("WBL_PLACE")}</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.work_learning_location}
                        onChange={(event) => this.setState({ work_learning_location: event.target.value })}
                        id="work_learning_location" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid item xs={6}>

                    <FormControl>
                      <InputLabel htmlFor="work_learning_hours">Hours of work</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.work_learning_hours}
                        type={'number'}
                        onChange={(event) => this.setState({ work_learning_hours: event.target.value })}
                        id="work_learning_hours" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid container>

                    <Grid item xs={6}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="school_sponsor">{i18n.t("WBL_SPONSOR")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.school_sponsor}
                          onChange={(event) => this.setState({ school_sponsor: event.target.value })}
                          id="school_sponsor" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>

                    <Grid item xs={6}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="business_sponsor">{i18n.t("WBL_INDUSTRY_SPONSOR")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.business_sponsor}
                          onChange={(event) => this.setState({ business_sponsor: event.target.value })}
                          id="business_sponsor" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="skills">{i18n.t("WBL_SKILLS")}</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.skills_learned}
                        onChange={(event) => this.setState({ skills_learned: event.target.value })}
                        id="skills" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl className={classes.formControl}
                      style={{ marginTop: 10 }} fullWidth>
                      <InputLabel htmlFor="is_paid">{i18n.t("WBL_PAID")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.is_paid}
                        onChange={(event) => this.setState({ is_paid: event.target.value })}
                        inputProps={{
                          name: 'is_paid',
                          id: 'is_paid',
                        }}>
                        <MenuItem value={1}>{i18n.t("WBL_YES")}</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="description">{i18n.t("WBL_PERFORMED")}</InputLabel>
                      <Input disableUnderline={false}
                        fullWidth multiline rows={4}
                        value={this.state.work_learning_description}
                        onChange={(event) => this.setState({ work_learning_description: event.target.value })}
                        id="description" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                </Grid>

              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained"
                onClick={this.onNewClick}
                style={{ color: '#FFF', fontWeight: 'bold', marginBottom: 20, marginRight: 20 }} color="primary">
                {i18n.t("ADD")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isEditOpen} className={classes.popup}
            onClose={this.onEditClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title"
              onClose={this.onEditClose}>
              {i18n.t("WBL_EDIT")}
            </DialogTitle>

            <DialogContent>

              <div>

                <Grid container style={{ marginTop: 10, marginBottom: 20 }}>

                  <Grid item xs={12}>

                    <Grid container spacing={10}>

                      <Grid item xs={6}>

                        <FormControl className={classes.formControl}
                          fullWidth>
                          <InputLabel htmlFor="workType">{i18n.t("WBL_SELECT_TYPE")}</InputLabel>
                          <Select variant='standard'
                            value={this.state.selectedItem.work_learning_type_id}
                            onChange={(event) => this.onWorkLearningTypeId(event)}
                            inputProps={{
                              name: 'workType',
                              id: 'workType',
                            }}>
                            <MenuItem disabled value={0}>{i18n.t("WBL_SELECT_TYPE")}</MenuItem>
                            {this.renderWorkTypes()}
                          </Select>
                        </FormControl>

                      </Grid>

                      <Grid item xs={6}>
                        {this.props.selectedLanguage === "esp" ?
                          <FormControl fullWidth
                            className={classes.formControl}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker error={false}
                                label="WBL Event Date"
                                format={"MM/DD/YYYY"}
                                value={this.state.selectedItem.date}
                                renderInput={(params) => <TextField variant='standard' {...params} />}
                                onChange={(date) => this.onDateChange(date)}
                                showTodayButton />
                            </LocalizationProvider>
                          </FormControl>
                          :
                          <FormControl fullWidth
                            className={classes.formControl}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker error={false}
                                label="WBL Event Date"
                                format={"MM/DD/YYYY"}
                                value={this.state.selectedItem.date}
                                renderInput={(params) => <TextField variant='standard' {...params} />}
                                onChange={(date) => this.onDateChange(date)}
                                showTodayButton />
                            </LocalizationProvider>
                          </FormControl>
                        }

                      </Grid>

                    </Grid>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl className={classes.formControl} style={{ marginTop: 15, marginBottom: 15 }}
                      fullWidth>
                      <InputLabel htmlFor="pathway">{i18n.t("WBL_PATHWAY")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.selectedItem.pathway_id}
                        onChange={(event) => this.onPathwayChange(event)}
                        inputProps={{
                          name: 'pathway',
                          id: 'pathway',
                        }}>
                        <MenuItem disabled value={0}>{i18n.t("WBL_PATHWAY")}</MenuItem>
                        {this.renderPathways()}
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={6}>

                    <FormControl>
                      <InputLabel htmlFor="work_learning_location">{i18n.t("WBL_PLACE")}</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.selectedItem.work_learning_location}
                        onChange={(event) => this.onLocationChange(event)}
                        id="work_learning_location" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid item xs={6}>

                    <FormControl>
                      <InputLabel htmlFor="work_learning_hours">Hours of Work</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.selectedItem.work_learning_hours}
                        type={'number'}
                        onChange={(event) => this.onHoursChange(event)}
                        id="work_learning_hours" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid container>

                    <Grid item xs={6}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="school_sponsor">{i18n.t("WBL_SPONSOR")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.school_sponsor}
                          onChange={(event) => this.onSchoolSponsorChange(event)}
                          id="school_sponsor" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>

                    <Grid item xs={6}>

                      <FormControl fullWidth style={{ marginTop: 10 }}>
                        <InputLabel htmlFor="business_sponsor">{i18n.t("WBL_INDUSTRY_SPONSOR")}</InputLabel>
                        <Input variant="outline" fullWidth
                          value={this.state.selectedItem.business_sponsor}
                          onChange={(event) => this.onBusinessSponsorChange(event)}
                          id="business_sponsor" aria-describedby="my-helper-text" />
                      </FormControl>

                    </Grid>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="skills">{i18n.t("WBL_SKILLS")}</InputLabel>
                      <Input variant="outline" fullWidth
                        value={this.state.selectedItem.skills_learned}
                        onChange={(event) => this.onSkillsChange(event)}
                        id="skills" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl className={classes.formControl}
                      style={{ marginTop: 10 }} fullWidth>
                      <InputLabel htmlFor="is_paid">{i18n.t("WBL_PAID")}</InputLabel>
                      <Select variant='standard'
                        value={this.state.selectedItem.is_paid}
                        onChange={(event) => this.onPaidChange(event)}
                        inputProps={{
                          name: 'is_paid',
                          id: 'is_paid',
                        }}>
                        <MenuItem value={1}>{i18n.t("WBL_YES")}</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={12}>

                    <FormControl fullWidth style={{ marginTop: 10 }}>
                      <InputLabel htmlFor="description">{i18n.t("WBL_PERFORMED")}</InputLabel>
                      <Input disableUnderline={false}
                        fullWidth multiline rows={4}
                        value={this.state.selectedItem.work_learning_description}
                        onChange={(event) => this.onDescriptionChange(event)}
                        id="description" aria-describedby="my-helper-text" />
                    </FormControl>

                  </Grid>

                </Grid>

              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.onDeleteClick}
                style={{ color: '#FFF', fontWeight: 'bold' }} color="secondary">
                {i18n.t("DELETE")}
              </Button>
              <Button variant="contained" onClick={this.onEditClick}
                style={{ marginRight: 20, color: '#FFF', fontWeight: 'bold' }} color="primary">
                {i18n.t("SAVE")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={10}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>
                    {i18n.t("WBL")}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.renderServices()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

WorkScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorkScreen));