import {
    Button,
    Typography
} from '@mui/material'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Stepper from 'react-stepper-horizontal'
import AchievementScreen from '../../achievement/index'
import ServiceScreen from '../../service/index'
import SkillScreen from '../../skill/index'
import WorkScreen from '../../work/index'
import WorkExperienceScreen from '../../workexperience/index'
import styles from './style'


class ResumeCusomizationScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0
        }
    }

    handleStepChange = (steps) => {
        let dIdx = steps.findIndex((x) => x.title === "Download")

        if (this.state.activeStep + 1 === dIdx) {
            this.props.history.push("/resume_builder_download")
        } else {
            this.setState({
                activeStep: this.state.activeStep += 1
            })
        }
    }
    render() {
        let steps = []
        this.props.resumeSections.forEach(item => {
            let obj = {
                title: item.title
            }
            steps.push(obj)
        })
        steps.push({ title: 'Download' })

        return (
            <div>
                <Stepper
                    steps={steps}
                    activeColor={"#203446"}
                    completeColor={"#203446"}
                    activeStep={this.state.activeStep}
                />
                <div>
                    {
                        this.props.resumeSections[this.state.activeStep].title === "Skills" &&
                        (
                            <div style={{ width: '60%', margin: 'auto', marginTop: '5%' }}>
                                <SkillScreen />
                            </div>
                        )
                    }
                    {
                        this.props.resumeSections[this.state.activeStep].title === "Work Experience" &&
                        (
                            <div style={{ width: '60%', margin: 'auto', marginTop: '5%' }}>
                                <WorkExperienceScreen />
                            </div>
                        )
                    }
                    {
                        this.props.resumeSections[this.state.activeStep].title === "Achievements" &&
                        (
                            <div style={{ width: '60%', margin: 'auto', marginTop: '5%' }}>
                                <AchievementScreen />
                            </div>
                        )
                    }
                    {
                        this.props.resumeSections[this.state.activeStep].title === "Work" &&
                        (
                            <div style={{ width: '60%', margin: 'auto', marginTop: '5%' }}>
                                <WorkScreen />
                            </div>
                        )
                    }
                    {
                        this.props.resumeSections[this.state.activeStep].title === "Service Learning" &&
                        (
                            <div style={{ width: '60%', margin: 'auto', marginTop: '5%' }}>
                                <ServiceScreen />
                            </div>
                        )
                    }
                </div>

                <div style={styles.buttonContainer}>

                    <Button variant={"contained"} style={styles.buttonStyle} onClick={() => this.props.history.push("/resume_builder_objectives")}>
                        <Typography style={styles.buttonTextColor}>Back</Typography>
                    </Button>
                    <Button variant={"contained"} style={styles.buttonStyle} onClick={() => this.handleStepChange(steps)}>
                        <Typography style={styles.buttonTextColor}>Next</Typography>
                    </Button>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        firstName: state.auth.firstName,
        lastName: state.auth.lastName,
        objectiveText: state.resume_builder.objectiveText,
        skills: state.resume_builder.skills,
        workBasedLearning: state.resume_builder.workBasedLearning,
        serviceLearning: state.resume_builder.serviceLearning,
        honorsAndAwards: state.resume_builder.honorsAndAwards,
        workExperience: state.resume_builder.workExperience,
        token: state.auth.token,
        schoolName: state.profile.schoolName,
        selectedResumeTemplate: state.resume_builder.resumeTemplate,
        personId: state.auth.personId,
        resumeSections: state.resume_builder.resumeSections,
        objectiveSelected: state.resume_builder.objectiveSelected,
        skillsSelected: state.resume_builder.skillsSelected,
        workExperienceSelected: state.resume_builder.workExperienceSelected,
        honorsSelected: state.resume_builder.honorsSelected,
        workSelected: state.resume_builder.workSelected,
        serviceSelected: state.resume_builder.serviceSelected
    }
}

const mapDispatchToProps = () => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResumeCusomizationScreen))





