
import { GET_PROFILE, SET_PROFILE } from "../actions";

export const defaultState = {
    profileId: 0,
    firstName: '',
    lastName: '',
    gradeId: 0,
    gradeName: '',
    districtId: 0,
    districtName: '',
    schoolId: 0,
    schoolName: '',
    profileRoleId: 0,
    earlyGraduationProgram: false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case GET_PROFILE:
            return Object.assign({}, state, {
            });
        case SET_PROFILE:
            return Object.assign({}, state, {
                profileId: action.profileId,
                firstName: action.firstName,
                lastName: action.lastName,
                gradeId: action.gradeId,
                gradeName: action.gradeName,
                districtId: action.districtId,
                districtName: action.districtName,
                schoolId: action.schoolId,
                schoolName: action.schoolName,
                profileRoleId: action.profileRoleId,
                earlyGraduationProgram: action.earlyGraduationProgram
            });
        default:
            return state
    }
}