import LinkIcon from '@mui/icons-material/Link';
import {
    Avatar,
    Grid, List, ListItem, ListItemText, Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

class LinksScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dataSource: []
        }
    }

    componentWillReceiveProps(newProps) {
        this.getData();
    }

    componentDidMount() {
        this.getData();
    }

    getData() {

        this.setState({
            isLoading: true
        })

        fetch('https://api.tasselpathway.com/links/' + this.props.schoolId + '/' + this.props.gradeId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': this.props.token
            },
            credentials: 'omit'
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error === false) {
                    this.setState({
                        isLoading: false,
                        dataSource: responseJson.results
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false
                })
            });
    }

    renderLinks() {

        return (

            <Grid item xs={12}>

                <List style={{ overflow: 'auto', height: 350 }}>

                    {this.state.dataSource.map((item, key) => {
                        return (
                            <ListItem key={key} button onClick={() => this.onLinkClick(item.link_url)}>
                                <Avatar style={{ marginLeft: -15, backgroundColor: '#25D192' }}>
                                    <LinkIcon />
                                </Avatar>
                                <ListItemText style={{ marginLeft: 10 }} primary={item.link_name} />
                            </ListItem>
                        )
                    })}

                </List>

            </Grid>
        );
    }

    onLinkClick(url) {
        window.open(url);
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>

                <Grid container style={styles.itemContainerStyle}>

                    <Grid item xs={12}>
                        <Grid container>

                            <Grid item xs={12}>
                                <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', marginTop: 20, color: '#4D4D4F' }}>
                                    Links
                                </Typography>
                            </Grid>

                            {this.renderLinks()}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

LinksScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        username: state.auth.username,
        personId: state.auth.personId,
        token: state.auth.token,
        userRoleId: state.auth.userRoleId,
        gradeId: state.auth.gradeId,
        profileId: state.profile.profileId,
        selectedLanguage: state.language.selectedLanguage,
        gradeName: state.profile.gradeName,
        firstName: state.profile.firstName,
        lastName: state.profile.lastName,
        schoolId: state.profile.schoolId,
        schoolName: state.profile.schoolName
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => { dispatch(logout()); }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LinksScreen));