import {
  Avatar,
  Grid,
  List,
  ListItem, ListItemAvatar, ListItemText,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';

class MyClusterScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dataSource: []
    }
  }

  componentDidMount() {
    this.getData(this.props.profileId);
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(profileId) {
    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_interest_cluster/recent/' + profileId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
          this.props.onClustersLoaded(responseJson.results)
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onClusterClick(item) {
    this.props.props.history.push({
      pathname: '/careers',
      state: { clusterId: item.cluster_id }
    })
  }

  renderClusters() {
    var rank = 0;

    var content = this.state.dataSource.map((item, key) => {
      rank++;
      if (this.props.selectedLanguage === "esp") {
        return (

          <ListItem key={key} button style={{ height: 65, padding: 10 }}
            onClick={() => this.onClusterClick(item)}>
            <ListItemAvatar>
              <Avatar alt="MSU" style={{ height: 55, width: 55 }} src={item.logo_image_url} />
            </ListItemAvatar>
            <ListItemText style={{ fontSize: 16, marginLeft: 20, fontWeight: 'bold' }} primary={"#" + rank + " - " + item.cluster_name_spanish} />
          </ListItem>

        )
      } else {
        return (

          <ListItem key={key} button style={{ height: 65, padding: 10 }}
            onClick={() => this.onClusterClick(item)}>
            <ListItemAvatar>
              <Avatar alt="MSU" style={{ height: 55, width: 55 }} src={item.logo_image_url} />
            </ListItemAvatar>
            <ListItemText style={{ fontSize: 16, marginLeft: 20, fontWeight: 'bold' }} primary={"#" + rank + " - " + item.cluster_name} />
          </ListItem>

        )
      }
    })

    return content;
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container style={styles.itemContainerStyle}>

          <Grid item xs={12}>

            <Typography style={{ fontSize: 18, marginBottom: 10, fontWeight: 'bold', marginTop: 20, color: '#4D4D4F' }}>{i18n.t("CAREER_CLUSTERS")}</Typography>

            <List style={{ overflow: 'auto' }}>
              {this.renderClusters()}
            </List>

          </Grid>
        </Grid>

      </div>
    );
  }
}

MyClusterScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    token: state.auth.token,
    personId: state.auth.personId,
    userRoleId: state.auth.userRoleId,
    profileId: state.profile.profileId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyClusterScreen)));