import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Button,
  Card, CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { logout } from '../../../redux/actions/auth';
import styles from './style';
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 20 }} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class ActivityScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isNewOpen: false,
      isEditOpen: false,
      dataSource: [],
      activity_name: '',
      activity_role: '',
      activity_description: '',
      newDetails: [],
      newNameValid: true,
      editNameValid: true,
      selectedItem: {},
      checkList: [],
      suggestions: [],
      grades: [],
      suggestionList: [],
      inputActivity: ""
    }

    this.createActivity = this.createActivity.bind(this);
    this.saveActivity = this.saveActivity.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onNewClick = this.onNewClick.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onNewNameChange = this.onNewNameChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
  }

  componentDidMount() {
    this.getData(this.props.profileId);

    fetch('https://api.tasselpathway.com/grades', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            grades: responseJson.results
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  componentWillReceiveProps(newProps) {
    this.getData(newProps.profileId);
  }

  getData(personId) {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_activity/person/' + personId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            dataSource: responseJson.results
          })
          this.props.onClubsLoaded(responseJson.results)
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });

  }

  createActivity() {
    if (this.state.activity_name !== "") {
      fetch('https://api.tasselpathway.com/profile_activity/', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          person_id: this.props.personId,
          activity_name: this.state.activity_name,
          activity_role: this.state.activity_role,
          activity_description: this.state.activity_description,
          details: this.state.newDetails
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.getData(this.props.personId);

        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });
    } else {
      fetch('https://api.tasselpathway.com/profile_activity/', {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': this.props.token
        },
        body: JSON.stringify({
          person_id: this.props.personId,
          activity_name: this.state.inputActivity,
          activity_role: this.state.activity_role,
          activity_description: this.state.activity_description,
          details: this.state.newDetails
        }),
        credentials: 'omit'
      })
        .then((response) => response.json())
        .then((responseJson) => {

          this.getData(this.props.personId);

        })
        .catch((error) => {
          this.setState({
            isLoading: false
          })
        });
    }
  }

  saveActivity() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_activity', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        activity_id: this.state.selectedItem.activity_id,
        activity_name: this.state.selectedItem.activity_name,
        activity_role: this.state.selectedItem.activity_role,
        activity_description: this.state.selectedItem.activity_description,
        details: this.state.selectedItem.details
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        this.getData(this.props.personId);

        this.setState({
          isEditOpen: false,
          isLoading: false
        })

      })
      .catch((error) => {
        this.setState({
          isEditOpen: false,
          isLoading: false
        })
      });
  }

  deleteActivity() {

    this.setState({
      isLoading: true
    })

    fetch('https://api.tasselpathway.com/profile_activity', {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      body: JSON.stringify({
        activity_id: this.state.selectedItem.activity_id
      }),
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error === false) {

          this.getData(this.props.personId);

          this.setState({
            isEditOpen: false,
            isLoading: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          isEditOpen: false,
          isLoading: false
        })
      });
  }

  renderActivityDetails(item) {
    var content = item.map((row, key) => {
      if (row.grade_name !== undefined && row.grade_name !== "" && row.grade_name !== null) {
        return (
          <Chip key={key} label={row.grade_name.substring(0, 1)}
            style={{ fontSize: 10, backgroundColor: '#25D192', color: '#FFF', fontWeight: 'bold', marginRight: 5 }}
            className={this.props.classes.chip} />
        )
      } else {
        return null;
      }
    })

    return content;
  }

  renderActivities() {

    var content = this.state.dataSource.map((item, key) => {
      return (
        <ListItem key={key} button={this.props.personId === this.props.profileId ? true : false}
          onClick={() => this.onEditOpen(item)} alignItems="flex-start">
          <ListItemText
            primary={
              <React.Fragment>
                <Typography style={{ fontSize: 14, marginTop: 5, fontWeight: 'bold' }} component="span"
                  className={this.props.classes.inline} color="textPrimary">
                  {item.activity_name} - {item.activity_role}
                </Typography>
              </React.Fragment>
            } secondary={
              <React.Fragment>
                <Grid container style={{ marginTop: 10, flexDirection: 'row' }}>
                  {this.renderActivityDetails(item.details)}
                </Grid>
              </React.Fragment>
            }
          />
        </ListItem>
      )
    })

    return content;
  }

  onAutoNameChange(value) {

    var selectedItem = this.state.selectedItem;
    selectedItem.activity_name = value;

    this.setState({
      selectedItem: selectedItem
    })

  }

  onNameChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.activity_name = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })

    fetch('https://api.tasselpathway.com/profile_suggest/2?search=' + event.target.value, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            suggestions: responseJson.results,
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onNewNameChange(event) {

    this.setState({
      activity_name: event.target.value
    })

    fetch('https://api.tasselpathway.com/profile_suggest/2?search=' + event.target.value, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      },
      credentials: 'omit'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          this.setState({
            isLoading: false,
            suggestions: responseJson.results,
          })
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        })
      });
  }

  onNewOpen() {

    this.setState({
      checkList: [],
      activity_name: '',
      activity_role: '',
      activity_description: '',
      newDetails: [],
      isNewOpen: true
    })
  }

  onNewClose() {

    this.setState({
      isNewOpen: false
    })
  }

  onNewClick() {

    var nameValid = true;

    if (this.state.activity_name === "") {
      nameValid = false;
    }

    this.setState({
      newNameValid: nameValid
    })

    if (nameValid) {
      this.createActivity();

      this.setState({
        isNewOpen: false
      })
    }
  }

  onEditClose() {

    this.setState({
      checkList: [],
      isEditOpen: false
    })
  }

  onEditOpen(item) {

    if (this.props.personId === this.props.profileId) {

      var checkList = [];

      item.details.map((item, key) => {
        checkList['checked_' + item.grade_id] = true;
      })

      this.setState({
        selectedItem: item,
        checkList: checkList,
        isEditOpen: true
      })
    }
  }

  onEditClick() {

    var nameValid = true;

    if (this.state.selectedItem.activity_name === "") {
      nameValid = false;
    }

    this.setState({
      editNameValid: nameValid
    })

    if (nameValid) {
      this.saveActivity();

      this.setState({
        isEditOpen: false
      })
    }
  }

  onDeleteClick() {
    this.deleteActivity();
  }

  onNewGradeChange(event) {

    var details = this.state.newDetails;
    var checkList = this.state.checkList;
    var gradeId = event.target.value;
    var i = 0;

    if (event.target.checked) {
      details.push({ grade_id: gradeId })
      checkList['checked_' + gradeId] = true;
    } else {
      details.map((item, key) => {

        if (item.grade_id === gradeId) {
          details.slice(i, 1);
          checkList['checked_' + gradeId] = false
        }
        i++;
      })
    }

    this.setState({
      checkList: checkList,
      newDetails: details
    })
  }

  onGradeChange(event) {
    var selectedItem = this.state.selectedItem;
    var checkList = this.state.checkList;
    var gradeId = event.target.value;
    var details = selectedItem.details;
    var i = 0
    if (event.target.checked) {
      details.push({ grade_id: gradeId });
      checkList['checked_' + gradeId] = true;
    } else {
      details.map((item, key) => {
        if (item.grade_id === gradeId) {
          details.splice(i, 1);
          checkList['checked_' + item.grade_id] = false
        }
        i++
      })
    }
    this.setState({
      checkList: checkList,
      selectedItem: selectedItem
    })
  }

  onRoleChange(event) {

    var selectedItem = this.state.selectedItem;
    selectedItem.activity_role = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  onClubSportChange(event) {
    var selectedItem = this.state.selectedItem
    selectedItem.activity_name = event.target.textContent
    this.setState({
      selectedItem: selectedItem
    })
  }

  onDescriptionChange(event) {
    var selectedItem = this.state.selectedItem;
    selectedItem.activity_description = event.target.value;

    this.setState({
      selectedItem: selectedItem
    })
  }

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };


  renderTransaltedModalInputs() {
    const { classes } = this.props
    if (this.props.selectedLanguage === "esp") {
      return (
        <div>
          <Autocomplete
            options={this.state.suggestions}
            classes={{
              option: classes.option,
              popper: classes.popper
            }}
            freeSolo
            style={{ width: '100%' }}
            value={this.state.activity_name}
            disableClearable
            onChange={(e) => this.setState({ activity_name: e.target.textContent })}
            getOptionLabel={option => this.state.activity_name}
            renderOption={option => (
              <React.Fragment>
                <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
              </React.Fragment>
            )}
            renderInput={params => (
              <TextField variant='standard'
                {...params} onChange={(event) => this.onNewNameChange(event)}
                placeholder="Nuevo Clube o Deporte"
                style={{
                  paddingTop: 5, width: '100%', paddingRight: 5,
                  backgroundColor: '#FFF', fontSize: 15
                }}
                margin="normal"

                InputProps={{ ...params.InputProps, type: 'search' }}
              />
            )}
          />

          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel htmlFor="role">{i18n.t("ACTIVITY_ENTER_NEW")}</InputLabel>
            <Input fullWidth id="role" value={this.state.activity_role}
              onChange={(event) => this.setState({ activity_role: event.target.value })}
              aria-describedby="my-helper-text" />
          </FormControl>

          <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>{i18n.t("ACTIVITY_YEARS_ACTIVE")}</Typography>

          <FormGroup row style={{ marginLeft: 20 }}>

            {this.state.grades.map((item, key) => {
              return (
                <FormControlLabel key={key} onChange={(event) => this.onNewGradeChange(event)}
                  control={
                    <Checkbox value={item.grade_id}
                      checked={this.state.checkList['checked_' + item.grade_id] ? true : false} />
                  }
                  label={item.grade_name}
                />
              )
            })}

          </FormGroup>

          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel htmlFor="description">{i18n.t("ACTIVITY_HIGHLIGHTS")}</InputLabel>
            <Input variant="outline" multiline={true} rows={4} fullWidth
              value={this.state.activity_description}
              onChange={(event) => this.setState({ activity_description: event.target.value })}
              id="description" aria-describedby="my-helper-text" />
          </FormControl>

        </div>
      )
    } else {
      return (
        <div>
          <Autocomplete
            options={this.state.suggestions}
            classes={{
              option: classes.option,
              popper: classes.popper
            }}
            freeSolo
            style={{ width: '100%' }}
            value={this.state.activity_name}
            disableClearable
            onChange={(e) => this.setState({ activity_name: e.target.textContent })}
            getOptionLabel={option => this.state.activity_name}
            renderOption={option => (
              <React.Fragment>
                <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
              </React.Fragment>
            )
            }
            renderInput={params => (
              <TextField variant='standard'
                {...params} onChange={(event) => this.onNewNameChange(event)}
                placeholder="Enter Your Club or Sport"
                style={{
                  paddingTop: 5, width: '100%', paddingRight: 5,
                  backgroundColor: '#FFF', fontSize: 15
                }}
                margin="normal"

                InputProps={{ ...params.InputProps, type: 'search' }}
              />
            )}
          />

          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel htmlFor="role">{i18n.t("ACTIVITY_ROLE_POSITION")}</InputLabel>
            <Input fullWidth id="role" value={this.state.activity_role}
              onChange={(event) => this.setState({ activity_role: event.target.value })}
              aria-describedby="my-helper-text" />
          </FormControl>

          <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>{i18n.t("ACTIVITY_YEARS_ACTIVE")}</Typography>

          <FormGroup row style={{ marginLeft: 20 }}>

            {this.state.grades.map((item, key) => {
              return (
                <FormControlLabel key={key} onChange={(event) => this.onNewGradeChange(event)}
                  control={
                    <Checkbox value={item.grade_id}
                      checked={this.state.checkList['checked_' + item.grade_id] ? true : false} />
                  }
                  label={item.grade_name}
                />
              )
            })}

          </FormGroup>

          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel htmlFor="description">{i18n.t("ACTIVITY_HIGHLIGHTS")}</InputLabel>
            <Input variant="outline" multiline={true} rows={4} fullWidth
              value={this.state.activity_description}
              onChange={(event) => this.setState({ activity_description: event.target.value })}
              id="description" aria-describedby="my-helper-text" />
          </FormControl>

        </div>
      )
    }
  }

  renderTranslatedEditModal() {
    const { classes } = this.props
    if (this.props.selectedLanguage === "esp") {
      return (
        <div>
          <Autocomplete
            options={this.state.suggestions}
            classes={{
              option: classes.option,
              popper: classes.popper
            }}
            freeSolo
            style={{ width: '100%' }}
            value={this.state.selectedItem.activity_name}
            disableClearable
            onChange={(e) => this.setState({ selecteditem: e.target.value })}
            getOptionLabel={option => this.state.selectedItem.activity_name}
            renderOption={option => (
              <React.Fragment>
                <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
              </React.Fragment>
            )}
            renderInput={params => (
              <TextField variant='standard'
                {...params} onChange={(event) => this.onNameChange(event)}
                placeholder="Ingrese a su club o deporte"
                style={{
                  paddingTop: 5, width: '100%', paddingRight: 5,
                  backgroundColor: '#FFF', fontSize: 15
                }}
                margin="normal"

                InputProps={{ ...params.InputProps, type: 'search' }}
              />
            )}
          />

          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel htmlFor="role">{i18n.t("ACTIVITY_ROLE_POSITION")}</InputLabel>
            <Input fullWidth id="role" value={this.state.selectedItem.activity_role}
              onChange={(event) => this.onRoleChange(event)}
              aria-describedby="my-helper-text" />
          </FormControl>

          <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>{i18n.t("ACTIVITY_YEARS_ACTIVE")}</Typography>

          <FormGroup row style={{ marginLeft: 20 }}>

            {this.state.grades.map((item, key) => {
              return (
                <FormControlLabel key={key} onChange={(event) => this.onGradeChange(event)}
                  control={
                    <Checkbox value={item.grade_id}
                      checked={this.state.checkList['checked_' + item.grade_id] === true ? true : false} />
                  }
                  label={item.grade_name}
                />
              )
            })}

          </FormGroup>

          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel htmlFor="description">{i18n.t("ACTIVITY_HIGHLIGHTS")}</InputLabel>
            <Input variant="outline" multiline={true} rows={4} fullWidth
              value={this.state.selectedItem.activity_description}
              onChange={(event) => this.onDescriptionChange(event)}
              id="description" aria-describedby="my-helper-text" />
          </FormControl>

        </div>
      )
    } else {
      return (
        <div>

          <Autocomplete
            options={this.state.suggestions}
            classes={{
              option: classes.option,
              popper: classes.popper
            }}
            freeSolo
            style={{ width: '100%' }}
            value={this.state.selectedItem.activity_name}
            disableClearable
            onChange={(e) => this.onClubSportChange(e)}
            getOptionLabel={option => this.state.selectedItem.activity_name}
            renderOption={option => (
              <React.Fragment>
                <Typography style={{ color: '#777' }}>{option.suggest_name}</Typography>
              </React.Fragment>
            )}
            renderInput={params => (
              <TextField variant='standard'
                {...params} onChange={(event) => this.onNameChange(event)}
                placeholder="Enter Your Club or Sport"
                style={{
                  paddingTop: 5, width: '100%', paddingRight: 5,
                  backgroundColor: '#FFF', fontSize: 15
                }}
                margin="normal"

                InputProps={{ ...params.InputProps, type: 'search' }}
              />
            )}
          />

          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel htmlFor="role">Role / Position</InputLabel>
            <Input fullWidth id="role" value={this.state.selectedItem.activity_role}
              onChange={(event) => this.onRoleChange(event)}
              aria-describedby="my-helper-text" />
          </FormControl>

          <Typography style={{ marginTop: 15, marginBottom: 5, color: '#555', fontSize: 16 }}>{i18n.t("ACTIVITY_YEARS_ACTIVE")}</Typography>

          <FormGroup row style={{ marginLeft: 20 }}>

            {this.state.grades.map((item, key) => {
              return (
                <FormControlLabel key={key} onChange={(event) => this.onGradeChange(event)}
                  control={
                    <Checkbox value={item.grade_id}
                      checked={this.state.checkList['checked_' + item.grade_id] == true ? true : false} />
                  }
                  label={item.grade_name}
                />
              )
            })}

          </FormGroup>

          <FormControl fullWidth style={{ marginTop: 10 }}>
            <InputLabel htmlFor="description">{i18n.t("ACTIVITY_HIGHLIGHTS")}</InputLabel>
            <Input variant="outline" multiline={true} rows={4} fullWidth
              value={this.state.selectedItem.activity_description}
              onChange={(event) => this.onDescriptionChange(event)}
              id="description" aria-describedby="my-helper-text" />
          </FormControl>

        </div>
      )
    }
  }

  handleNewRequest = () => {
    this.setState({
      searchText: '',
    });
  };

  render() {
    const { classes } = this.props
    return (
      <div>

        <div className={classes.root}>
          <Dialog open={this.state.isNewOpen}
            onClose={this.onNewClose} maxWidth="sm"
            fullWidth
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title" onClose={this.onNewClose}>
              {i18n.t("ACTIVITY_NEW")}
            </DialogTitle>

            <DialogContent>
              {this.renderTransaltedModalInputs()}
            </DialogContent>
            <DialogActions>
              <Button variant="contained"
                style={{ marginRight: 20, marginBottom: 10, color: '#FFF', fontWeight: 'bold' }}
                onClick={this.onNewClick} color="primary">
                {i18n.t("ACTIVITY_ADD_BUTTON")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={this.state.isEditOpen} className={classes.popup}
            onClose={this.onEditClose} maxWidth="sm"
            aria-labelledby="form-dialog-title">
            <DialogTitle id="customized-dialog-title"
              onClose={this.onEditClose}>
              {i18n.t("ACTIVITY_EDIT_TITLE")}
            </DialogTitle>

            <DialogContent>
              {this.renderTranslatedEditModal()}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.onDeleteClick}
                style={{ color: '#FFF', fontWeight: 'bold' }} color="secondary">
                {i18n.t("ACTIVITY_DELETE_BUTTON")}
              </Button>
              <Button variant="contained" onClick={this.onEditClick}
                style={{ marginRight: 20, color: '#FFF', fontWeight: 'bold' }} color="primary">
                {i18n.t("ACTIVITY_EDIT_SAVE")}
              </Button>
            </DialogActions>
          </Dialog>

        </div>

        <Grid container style={styles.itemContainerStyle}>

          <Card style={{ width: '100%', borderRadius: 3 }}>
            <CardContent>
              <Grid container style={{ borderBottom: '1px solid #eaeaea' }}>
                <Grid item xs={10}>
                  <Typography style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: '#4D4D4F', marginBottom: 20 }}>{i18n.t("TEACHER_PROFILE_CLUBS_SPORTS_TILE")}</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  {this.props.personId === this.props.profileId ?
                    <IconButton style={{ marginTop: 10 }} onClick={() => this.onNewOpen()}>
                      <FontAwesomeIcon style={{ color: '#25D192', fontSize: 16 }} icon={faPlus} />
                    </IconButton>
                    : null}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <List style={{ height: 200, overflow: 'auto' }}>
                    {this.renderActivities()}
                  </List>
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

ActivityScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    username: state.auth.username,
    profileId: state.profile.profileId,
    personId: state.auth.personId,
    token: state.auth.token,
    userRoleId: state.auth.userRoleId,
    selectedLanguage: state.language.selectedLanguage
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => { dispatch(logout()); }
  }
}

export default (withTranslation('translate'))(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActivityScreen)));